import { has, isEmpty } from "lodash";
import { getAuthData } from "./AuthUtils";

export function clone(o) {
	return JSON.parse(JSON.stringify(o));
}

export const toFormData = (input) => {
	const form = new FormData();
	Object.keys(input).map((theKey) => {
		if (Array.isArray(input[theKey])) {
			for (let i = 0; i < input[theKey].length; i++) {
				form.append(theKey + "[]", input[theKey][i]);
			}
		} else {
			form.append(theKey, input[theKey]);
		}
		return form;
	});
	return form;
};

export const updateObject = (oldObject, updatedProperties) => {
	return {
		...oldObject,
		...updatedProperties,
	};
};

export const sortArrayOfObjectsByString = (array, fieldNameToSort) => {
	array.sort((a, b) => {
		const nameA = a[fieldNameToSort].toUpperCase();
		const nameB = b[fieldNameToSort].toUpperCase();

		let comparison = 0;
		if (nameA > nameB) {
			comparison = 1;
		} else if (nameA < nameB) {
			comparison = -1;
		}
		return comparison;
	});
};

export const sortArrayOfObjectsByNumbers = (array, fieldNameToSort, order) => {
	return array.sort((a, b) => {
		let comparison = 0;
		if (order === "desc") {
			if (a[fieldNameToSort] < b[fieldNameToSort]) {
				comparison = 1;
			} else if (a[fieldNameToSort] > b[fieldNameToSort]) {
				comparison = -1;
			}
		} else {
			if (a[fieldNameToSort] > b[fieldNameToSort]) {
				comparison = 1;
			} else if (a[fieldNameToSort] < b[fieldNameToSort]) {
				comparison = -1;
			}
		}
		return comparison;
	});
};

export const sortArrayOfObjectsByDate = (array, fieldNameToSort, order) => {
	if (order === "desc") return array.sort((a, b) => new Date(b[fieldNameToSort]).getTime() - new Date(a[fieldNameToSort]).getTime());
	else if (order === "asc") return array.sort((a, b) => new Date(a[fieldNameToSort]).getTime() - new Date(b[fieldNameToSort]).getTime());
};

export const getDbTableSuffix = () => {
	return getAuthData().schoolYear.original + "_" + getAuthData().period.original;
};

export const getDbTableForDisplay = () => {
	return getAuthData().period.display + " " + getAuthData().schoolYear.display;
};

export const getDbSuffixesForStudentResubscription = () => {
	const schoolYearPieces = getAuthData().schoolYear.original.split("_");
	const currentSchoolYearForDisplay = `20${schoolYearPieces[0]}-20${schoolYearPieces[1]}`;
	const previousSchoolYear = (Number(schoolYearPieces[0]) - 1).toString() + "_" + (Number(schoolYearPieces[1]) - 1).toString();
	const previousSchoolYearPieces = previousSchoolYear.split("_");
	const previousSchoolYearDisplay = `20${previousSchoolYearPieces[0]}-20${previousSchoolYearPieces[1]}`;
	return (() => {
		if (getAuthData().period.original === "xeim")
			return [
				{ label: `Θερινή ${currentSchoolYearForDisplay}`, value: `${getAuthData().schoolYear.original}_ther` },
				{ label: `Χειμερινή ${previousSchoolYearDisplay}`, value: `${previousSchoolYear}_xeim` },
			];
		else
			return [
				{ label: `Χειμερινή ${previousSchoolYearDisplay}`, value: `${previousSchoolYear}_xeim` },
				{ label: `Θερινή ${previousSchoolYearDisplay}`, value: `${previousSchoolYear}_ther` },
			];
	})();
};

export const copyToClipboard = (str) => {
	const el = document.createElement("textarea");
	el.value = str;
	document.body.appendChild(el);
	el.select();
	document.execCommand("copy");
	document.body.removeChild(el);
};

export const getDateDiffInDays = (startDate) => {
	var timeDiff = new Date() - new Date(startDate);
	return Math.floor(timeDiff / (1000 * 60 * 60 * 24));
};

export const isFormInvalid = (formData) => {
	const updatedFormData = clone(formData);
	let allFalse = false;
	for (let key in updatedFormData) {
		if (updatedFormData[key].required) {
			if (has(updatedFormData[key], "selected") && (updatedFormData[key].selected === null || updatedFormData[key].selected === undefined)) {
				allFalse = true;
			}
			if (updatedFormData[key].value === "" || updatedFormData[key].value === null) {
				allFalse = true;
			}
		}
	}
	return allFalse;
};

export const isFormikFormInvalid = (formData) => {
	let allFalse = false;
	formData.forEach((data) => {
		if (data.required && (data.value == null || data.value === "")) {
			allFalse = true;
		}
		if (data.minSelected && Array.isArray(data.value) && data.value.length < data.minSelected) {
			allFalse = true;
		}
	});
	return allFalse;
};

export const flattenFormData = (formData) => {
	const updatedFormData = clone(formData);
	for (let key in updatedFormData) {
		if (isObject(updatedFormData[key])) {
			updatedFormData[key] = updatedFormData[key].value;
		}
	}
	return updatedFormData;
};

export const isCheckboxFormEmpty = (checkboxFormData) => {
	const updatedCheckboxFormData = clone(checkboxFormData);
	let allFalse = true;
	for (let key in updatedCheckboxFormData) {
		if (updatedCheckboxFormData[key].isChecked) {
			allFalse = false;
		}
	}
	return allFalse;
};

export const paginationComponentOptions = {
	rowsPerPageText: "Εγγραφές ανά σελίδα",
	rangeSeparatorText: "από",
	selectAllRowsItem: true,
	selectAllRowsItemText: "Όλα",
};

const extractValueFromTableDefs = (tableDefs, tableRow) => {
	return tableDefs.map((colProp) => {
		const value = (() => {
			if (has(colProp, "format") && typeof colProp.format(tableRow) !== "object") {
				if (colProp.selector(tableRow) !== null) return '"' + colProp.format(tableRow) + '"';
				else return "";
			} else {
				if (colProp.selector(tableRow) !== null) return '"' + colProp.selector(tableRow) + '"';
				else return "";
			}
		})();
		return value;
	});
};

const convertArrayOfObjectsToCSV = (array, tableDefs) => {
	const dataArray = [
		tableDefs.map((colProp) => {
			return '"' + colProp.name + '"';
		}),
	].concat(
		array.map((row) => {
			return extractValueFromTableDefs(tableDefs, row);
		}),
	);
	let result = "";
	const lineDelimiter = "\n";
	dataArray.forEach((item) => {
		result += item.join(";").replace(/(\r\n|\n|\r)/gm, "") + lineDelimiter;
	});
	return result;
};

export const convertArrayOfObjectsToCSVTabSeperated = (array, tableDefs) => {
	const dataArray = [
		tableDefs.map((colProp) => {
			return colProp.name;
		}),
	].concat(
		array.map((row) => {
			return extractValueFromTableDefs(tableDefs, row);
		}),
	);
	let result = "";
	const lineDelimiter = "\n";
	dataArray.forEach((item) => {
		result += item.join("\t").replace(/(\r\n|\n|\r)/gm, "") + lineDelimiter;
	});
	return result;
};

export const downloadCSV = (array, filename, tableDefs) => {
	const link = document.createElement("a");
	let csv = convertArrayOfObjectsToCSV(array, tableDefs);
	if (csv == null) return;

	if (!csv.match(/^data:text\/csv/i)) {
		csv = `data:text/csv;charset=utf-8,${csv}`;
	}

	link.setAttribute("href", encodeURI(csv));
	link.setAttribute("download", filename + ".csv");
	link.click();
};

export const roundNumber = (num, prec) => {
	let temp = num * Math.pow(10, prec);
	temp = Math.round(temp);
	return temp / Math.pow(10, prec);
};

export const dataURLtoFile = (dataurl, filename) => {
	var arr = dataurl.split(","),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n);

	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}

	return new File([u8arr], filename, { type: mime });
};

export const isObject = (input) => {
	if (typeof input === "object" && !Array.isArray(input) && input !== null) {
		return true;
	}
	return false;
};

export function isNumber(o) {
	const rv1 = !isNaN(o);
	const rv2 = typeof o === typeof 0;
	if (rv1 === rv2) return rv1;
	else {
		console.error(o);
	}
}

export function isFiniteNumber(o) {
	return isNumber(o) && isFinite(o);
}

// https://stackoverflow.com/a/14794066/274677
export function isInt(v) {
	return !isNaN(v) && parseInt(Number(v)) === v && !isNaN(parseInt(v, 10));
}

export function uniqValues(xs) {
	//return new array with distinct values
	// assert.isTrue(Array.isArray(xs));
	function onlyUnique(value, index, self) {
		return self.indexOf(value) === index;
	}

	return xs.filter(onlyUnique);
}

export function hasOnlyUniqueValues(xs) {
	//return true if the input array has only unique values
	const uniq = uniqValues(xs);
	return uniq.length === xs.length;
}

// reports the elements in [bs] that do not appear in [as]
export function deepDiff(as, bs) {
	return bs.filter((x) => !as.some((item) => JSON.stringify(item) === JSON.stringify(x)));
}

export function compare_dates(d1, d2) {
	// d1 and d2 should be Date objects
	const t1 = d1.getTime();
	const t2 = d2.getTime();
	if (t1 === t2) return 0;
	else if (t1 < t2) return -1;
	else return 1;
}

export function objectContainsValue(obj, value) {
	return Object.values(obj).includes(value);
}

/*
 * num = the number to round
 * func = the Math function to apply
 * prec = the precision needed (in number)
 */
export const roundUsing = (num, func, prec) => {
	var temp = num * Math.pow(10, prec);
	temp = func(temp);
	return temp / Math.pow(10, prec);
};

export const floorRounding = (num, prec) => {
	return roundUsing(num, Math.floor, prec);
};

export const roundRounding = (num, prec) => {
	return roundUsing(num, Math.round, prec);
};

//https://stackoverflow.com/a/6566471
export const toQueryParamsString = (params) => {
	const qs = Object.keys(params)
		.map((key) => {
			if (params[key] !== null && params[key] !== undefined && params[key] !== "") {
				return `${key}=${encodeURIComponent(params[key])}`;
			} else {
				return null;
			}
		})
		.filter((x) => x !== null);
	if (!isEmpty(qs)) {
		return qs.join("&");
	} else {
		return null;
	}
};
