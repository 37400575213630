import axios from "axios";
import { deleteResource, getResource, postResource, putResource } from "../../BackendAPI/AbstractCRUDActions";
import { ActionKinds } from "../../BackendAPI/ActionKinds";
import { getCurrentTeacherStudentsUrl, getStudentLessonsUrl, getStudentsUrl, getStudentUserUrl } from "../../BackendAPI/BackendAPI";
import {
	ADD_NEW_STUDENT_FAIL,
	ADD_NEW_STUDENT_START,
	ADD_NEW_STUDENT_SUCCESS,
	DELETE_STUDENT_FAIL,
	DELETE_STUDENT_START,
	DELETE_STUDENT_SUCCESS,
	FETCH_ALL_STUDENTS_FAIL,
	FETCH_ALL_STUDENTS_START,
	FETCH_ALL_STUDENTS_SUCCESS,
	FETCH_CURRENT_TEACHER_STUDENTS_FAIL,
	FETCH_CURRENT_TEACHER_STUDENTS_START,
	FETCH_CURRENT_TEACHER_STUDENTS_SUCCESS,
	UPDATE_STUDENT_FAIL,
	UPDATE_STUDENT_START,
	UPDATE_STUDENT_SUCCESS,
} from "./actionTypes";

export const fetchStudentByUserId = (user_id) => {
	return (dispatch) => {
		dispatch(fetchAllStudentsStart());
		axios
			.all([getResource(getStudentUserUrl(ActionKinds.GET_ONE)), getResource(getStudentLessonsUrl(ActionKinds.GET))])
			.then(
				axios.spread((studentsData, studentLessonsData) => {
					dispatch(
						fetchAllStudentsSuccess({
							students: studentsData.data,
							studentLessons: studentLessonsData.data,
							user_id: user_id,
						}),
					);
				}),
			)
			.catch((error) => {
				dispatch(fetchAllStudentsFail(error));
			});
	};
};

export const fetchAllStudentsStart = () => {
	return {
		type: FETCH_ALL_STUDENTS_START,
	};
};

export const fetchAllStudentsSuccess = (studentsData) => {
	return {
		type: FETCH_ALL_STUDENTS_SUCCESS,
		payload: studentsData,
	};
};

export const fetchAllStudentsFail = (error) => {
	return {
		type: FETCH_ALL_STUDENTS_FAIL,
		payload: error,
	};
};

export const fetchAllStudents = () => {
	return (dispatch) => {
		dispatch(fetchAllStudentsStart());
		axios
			.all([getResource(getStudentsUrl(ActionKinds.GET)), getResource(getStudentLessonsUrl(ActionKinds.GET))])
			.then(
				axios.spread((studentsData, studentLessonsData) => {
					dispatch(
						fetchAllStudentsSuccess({
							students: studentsData.data,
							studentLessons: studentLessonsData.data,
						}),
					);
				}),
			)
			.catch((error) => {
				dispatch(fetchAllStudentsFail(error));
			});
	};
};

export const fetchCurrentTeacherStudentsStart = () => {
	return {
		type: FETCH_CURRENT_TEACHER_STUDENTS_START,
	};
};

export const fetchCurrentTeacherStudentsSuccess = (studentsData) => {
	return {
		type: FETCH_CURRENT_TEACHER_STUDENTS_SUCCESS,
		payload: studentsData,
	};
};

export const fetchCurrentTeacherStudentsFail = (error) => {
	return {
		type: FETCH_CURRENT_TEACHER_STUDENTS_FAIL,
		payload: error,
	};
};

export const fetchCurrentTeacherStudents = () => {
	return (dispatch) => {
		dispatch(fetchAllStudentsStart());
		axios
			.all([getResource(getCurrentTeacherStudentsUrl(ActionKinds.GET)), getResource(getStudentLessonsUrl(ActionKinds.GET))])
			.then(
				axios.spread((studentsData, studentLessonsData) => {
					dispatch(
						fetchAllStudentsSuccess({
							students: studentsData.data,
							studentLessons: studentLessonsData.data,
						}),
					);
				}),
			)
			.catch((error) => {
				dispatch(fetchAllStudentsFail(error));
			});
	};
};

export const addNewStudentStart = () => {
	return {
		type: ADD_NEW_STUDENT_START,
	};
};

export const addNewStudentSuccess = (newStudentData) => {
	return {
		type: ADD_NEW_STUDENT_SUCCESS,
		payload: newStudentData,
	};
};

export const addNewStudentFail = (error) => {
	return {
		type: ADD_NEW_STUDENT_FAIL,
		payload: error,
	};
};

export const addNewStudent = (newStudentData) => {
	return (dispatch) => {
		dispatch(addNewStudentStart());
		const url = getStudentsUrl(ActionKinds.POST);
		return postResource(url, newStudentData);
	};
};

export const updateStudentStart = () => {
	return {
		type: UPDATE_STUDENT_START,
	};
};

export const updateStudentSuccess = (studentData) => {
	return {
		type: UPDATE_STUDENT_SUCCESS,
		payload: studentData,
	};
};

export const updateStudentFail = (error) => {
	return {
		type: UPDATE_STUDENT_FAIL,
		payload: error,
	};
};

export const updateStudent = (studentData, studentId) => {
	return (dispatch) => {
		dispatch(updateStudentStart());
		const url = getStudentsUrl(ActionKinds.UPDATE, studentId);
		return putResource(url, studentData);
	};
};

export const deleteStudentStart = () => {
	return {
		type: DELETE_STUDENT_START,
	};
};

export const deleteStudentSuccess = (studentData) => {
	return {
		type: DELETE_STUDENT_SUCCESS,
		payload: studentData,
	};
};

export const deleteStudentFail = (error) => {
	return {
		type: DELETE_STUDENT_FAIL,
		payload: error,
	};
};

export const deleteStudent = (studentId) => {
	return (dispatch) => {
		dispatch(deleteStudentStart());
		const url = getStudentsUrl(ActionKinds.DELETE, studentId);
		return deleteResource(url);
	};
};
