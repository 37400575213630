import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";

import ChangePasswordForm from "../../Components/ChangePassword/ChangePasswordForm";
import Dashboard from "../../Components/Teacher/Dashboard/Dashboard";
import GradesManagement from "../../Components/Teacher/GradesManagement/GradesManagement";
import StudentView from "../../Components/Teacher/StudentView/StudentView";
import Spinner from "../../Components/UI/Spinner/Spinner";
import { ApiResponseShape } from "../../Models/ApiResponseShape";
import { PathEntry } from "../../navigation";
import { fetchUniverseCheckingPermissions, resetApiResponse } from "../../Redux/Actions/index";
import { allFetchingFinishedForTeacher } from "../../Redux/Selectors/universalSelectors";
import { getAuthData } from "../../Utils/AuthUtils";
import { renderAPIMessages } from "../../Utils/GuiUtils";
import { permissions } from "../../Utils/ParametricTablesBinds";
import Footer from "../Footer/Footer";
import PageHeader from "../PageHeader/PageHeader";
import TopBar from "../TopBar/TopBar";
import LeftSideBar from "./LeftSideBar/LeftSideBar";

const MainLayout = ({ allFetchingFinishedForTeacher, apiResponse, fetchUniverseCheckingPermissions, resetApiResponse }) => {
	const history = useHistory();

	useEffect(() => {
		if (Number(getAuthData().permission) !== permissions.TEACHER) {
			history.push(PathEntry.logout.path);
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (!allFetchingFinishedForTeacher) {
			fetchUniverseCheckingPermissions();
		}
	}, [allFetchingFinishedForTeacher, fetchUniverseCheckingPermissions]);

	return (
		<div className="wrapper">
			<TopBar />
			<LeftSideBar />
			<section
				className="section-container"
				style={{ minHeight: "calc(100vh - 114px)" }}
			>
				<div className="content-wrapper">
					{!allFetchingFinishedForTeacher ? (
						<div className="d-flex mt-3">
							<h5 className="mr-2">Φόρτωμα Εφαρμογής</h5>
							<Spinner type="ball-pulse-sync" />
						</div>
					) : (
						<>
							<PageHeader />
							{(apiResponse.info !== null || apiResponse.error !== null) &&
								renderAPIMessages(apiResponse, () => {
									resetApiResponse();
								})}
							{Number(getAuthData().permission) === permissions.TEACHER && (
								<Switch>
									<Route
										exact
										path={PathEntry.teacherDashboard.path}
									>
										<Dashboard />
									</Route>
									<Route
										exact
										path={PathEntry.teacherStudentView.path}
									>
										<StudentView />
									</Route>
									<Route
										exact
										path={PathEntry.teacherGradesManagement.path}
									>
										<GradesManagement />
									</Route>
								</Switch>
							)}
						</>
					)}
				</div>
			</section>
			{allFetchingFinishedForTeacher && <ChangePasswordForm />}
			<Footer />
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		allFetchingFinishedForTeacher: allFetchingFinishedForTeacher(state),
		apiResponse: state.apiResponse,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchUniverseCheckingPermissions: () => dispatch(fetchUniverseCheckingPermissions()),
		resetApiResponse: () => dispatch(resetApiResponse()),
	};
};

MainLayout.propTypes = {
	allFetchingFinishedForTeacher: PropTypes.bool,
	apiResponse: PropTypes.exact(ApiResponseShape),
	fetchUniverseCheckingPermissions: PropTypes.func,
	resetApiResponse: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
