import PropTypes from "prop-types";
import { ParametricShape } from "./ParametricShape";
import { LessonShape } from "./LessonShape";
import { DepartmentShape } from "./DepartmentShape";

export const UserFromReduxShape = {
	address: PropTypes.string,
	city: PropTypes.string,
	created_at: PropTypes.string,
	departments: PropTypes.arrayOf(PropTypes.number),
	email: PropTypes.string,
	firstname: PropTypes.string,
	id: PropTypes.number,
	last_activity: PropTypes.string,
	lastname: PropTypes.string,
	lessons: PropTypes.arrayOf(PropTypes.number),
	mobile_phone: PropTypes.string,
	name: PropTypes.string,
	permission: PropTypes.number,
	phone: PropTypes.string,
	status: PropTypes.number,
	student_id: PropTypes.number,
	username: PropTypes.string,
	zip: PropTypes.string,
};

export const UserFromSelectorShape = {
	address: PropTypes.string,
	city: PropTypes.string,
	created_at: PropTypes.string,
	departments: PropTypes.arrayOf(PropTypes.shape(DepartmentShape)),
	email: PropTypes.string,
	firstname: PropTypes.string,
	id: PropTypes.number,
	last_activity: PropTypes.string,
	lastname: PropTypes.string,
	lessons: PropTypes.arrayOf(PropTypes.shape(LessonShape)),
	mobile_phone: PropTypes.string,
	name: PropTypes.string,
	permission: PropTypes.shape(ParametricShape),
	phone: PropTypes.string,
	status: PropTypes.shape(ParametricShape),
	student_id: PropTypes.number,
	username: PropTypes.string,
	zip: PropTypes.string,
};
