import { isEmpty } from "lodash";
import { globalStore, listConstants } from "../../globalStore";
import { allDepartments } from "./departmentsSelectors";

export const allStudents = (state) => {
	if (isEmpty(state.departments.allDepartments) || !state.parametrics.finished) {
		return [];
	}

	const allStateDepartments = allDepartments(state);

	const newStudents = state.students.allStudents.map((student) => {
		const updatedStudent = { ...student };
		updatedStudent.department = allStateDepartments.find((department) => department.id === student.department) ?? null;
		updatedStudent.informed_by = globalStore?.informed_by?.find((item) => item.id === student.informed_by) ?? null;
		updatedStudent.status = listConstants?.status?.find((item) => item.id === student.status) ?? null;
		updatedStudent.educational_institution =
			globalStore?.educational_institutions?.find((item) => item.id === student.educational_institution) ?? null;
		const fullAddress = (() => {
			let fAddress = "";
			if (updatedStudent.address != null) fAddress += updatedStudent.address;
			if (updatedStudent.county != null) fAddress += ", " + updatedStudent.county;
			if (updatedStudent.zip != null) fAddress += ", " + updatedStudent.zip;
			return fAddress;
		})();
		updatedStudent.fullAddress = fullAddress;
		const updatedLessons = [];
		for (let i in updatedStudent.lessons) {
			updatedLessons.push(globalStore?.lessons?.filter((obj) => obj.id === updatedStudent.lessons[i])[0]);
		}
		updatedStudent.lessons = updatedLessons.filter((lesson) => lesson !== undefined);
		return updatedStudent;
	});

	return newStudents;
};
