import React, { Component } from "react";
import { uniqBy } from "lodash";
import PropTypes from "prop-types";

import { SelfTestChapterSelectorShape } from "../../../../Models/SelfTestChapterShape";
import SelfTestChapterFilterPanel from "./SelfTestChapterFilterPanel/SelfTestChapterFilterPanel";
import { clone } from "../../../../Utils/Utils";
import Card from "../../../UI/Card/Card";
import { Row, Col } from "../../../UI/Grid/Grid";
import BaseInput from "../../../UI/BaseInput/BaseInput";
import Button from "../../../UI/Button/Button";

class SelfTestListFilterPanel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterForm: {
				lesson: {
					label: "Μάθημα",
					name: "lesson",
					placeholder: "Επιλογή",
					options: this.getLessonsAsDropdown(),
					searchable: false,
					value: null,
				},
			},
		};
	}

	onChangeHandler = (selectedOption) => {
		const updatedForm = clone(this.state.filterForm);
		const updatedFormElement = clone(updatedForm.lesson);
		updatedFormElement.value = selectedOption;
		updatedForm.lesson = updatedFormElement;
		this.props.setChapterSelected(this.props.allSelfTestChapters.filter((stc) => stc.lesson_id === selectedOption.value).map((stc) => stc.id));
		this.setState({ filterForm: updatedForm });
	};

	getLessonsAsDropdown = () => {
		return uniqBy(
			this.props.allSelfTestChapters.map((chapter) => {
				return {
					value: chapter.lesson.id,
					label: chapter.lesson.description,
				};
			}),
			"value",
		);
	};

	resetFilter = () => {
		const updatedForm = clone(this.state.filterForm);
		const updatedFormElement = clone(updatedForm.lesson);
		updatedFormElement.value = null;
		updatedForm.lesson = updatedFormElement;
		this.setState({ filterForm: updatedForm });
	};

	render() {
		const formElementsArray = [];
		for (let key in this.state.filterForm) {
			formElementsArray.push({
				id: key,
				config: this.state.filterForm[key],
			});
		}

		return (
			<Card title="Φίλτρα Αναζήτησης">
				<Row classes={["px-2"]}>
					<Col classes={["mb-3", "px-2"]}>
						<label>
							{formElementsArray[0].config.label} {formElementsArray[0].config.required ? <span className="reqField">(*)</span> : null}
						</label>
						<BaseInput
							key={formElementsArray[0].config.name + formElementsArray[0].config.id}
							name={formElementsArray[0].config.name}
							placeholder={formElementsArray[0].config.placeholder}
							searchable={formElementsArray[0].config.searchable}
							options={formElementsArray[0].config.options}
							value={formElementsArray[0].config.value}
							onChange={(selectedOption) => {
								this.onChangeHandler(selectedOption);
							}}
						/>
					</Col>
					<Col classes={["mb-4", "px-2", "text-center"]}>
						<Button
							type="button"
							text="Εκκαθάριση"
							kind="primary"
							block
							disabled={this.state.filterForm.lesson.value === null}
							onClick={() => {
								this.props.setChapterSelected([]);
								this.resetFilter();
							}}
						/>
					</Col>
				</Row>
				{this.state.filterForm.lesson.value !== null && (
					<SelfTestChapterFilterPanel
						chaptersSelected={this.props.allSelfTestChapters.filter((stc) => stc.lesson_id === this.state.filterForm.lesson.value.value)}
						setChapterSelected={this.props.setChapterSelected}
					/>
				)}
			</Card>
		);
	}
}

SelfTestListFilterPanel.propTypes = {
	allSelfTestChapters: PropTypes.arrayOf(PropTypes.exact(SelfTestChapterSelectorShape)),
	setChapterSelected: PropTypes.func,
};

export default SelfTestListFilterPanel;
