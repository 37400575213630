import { NavLink } from "react-router-dom";

const menuItem = (props) => {
	return (
		<li className={props.item.active === props.item.to ? "active" : null}>
			<NavLink
				to={props.item.to}
				exact
				onClick={props.item.onClick}
				title={props.item.title}
			>
				{props.item.icon ? props.item.icon : null}
				<span>{props.item.title}</span>
			</NavLink>
		</li>
	);
};

export default menuItem;
