import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { isEmpty, uniq, reverse } from "lodash";

import { absenceKindIds } from "../../../../../Utils/ParametricTablesBinds";
import { DepartmentShape } from "../../../../../Models/DepartmentShape";
import { GradeShape } from "../../../../../Models/GradeShape";
import { StudentFromSelectorShape } from "../../../../../Models/StudentShape";
import { allStudents } from "../../../../../Redux/Selectors/studentsSelectors";
import { allDepartments } from "../../../../../Redux/Selectors/departmentsSelectors";
import { allGrades } from "../../../../../Redux/Selectors/gradesSelectors";
import { Col } from "../../../../UI/Grid/Grid";
import Card from "../../../../UI/Card/Card";
import CustomChart from "../../../../UI/Chart/CustomChart";

class StatisticsTab extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.chartBgColors = [
			"rgb(0, 128, 0)",
			"rgb(0, 0, 255)",
			"rgb(255, 0, 255)",
			"rgb(255, 225, 25)",
			"rgb(210, 245, 60)",
			"rgb(127, 255, 0)",
			"rgb(139, 0, 139)",
			"rgb(255, 215, 0)",
		];
		this.chartBorderColors = [
			"rgba(0, 128, 0, 0.7)",
			"rgba(0, 0, 255, 0.7)",
			"rgba(255, 0, 255, 0.7)",
			"rgba(255, 225, 25, 1, 0.7)",
			"rgba(210, 245, 60, 1, 0.7)",
			"rgba(127, 255, 0, 0.7)",
			"rgba(139, 0, 139, 0.7)",
			"rgba(255, 215, 0, 0.7)",
		];
	}

	getUniqueLessons = () => {
		//get uniq lesson id show i can seperate the grades per lesson
		const allLessonIds = uniq(this.props.allGrades.map((grade) => grade.lesson));
		if (!isEmpty(allLessonIds)) {
			let colorCounter = 0;
			return allLessonIds.map((lesson) => {
				const datasets = [];
				let labels = [];
				let maxLength = 0;
				const baseLineData = [];
				const lessonGrades = this.props.allGrades.filter((grade) => grade.lesson.id === lesson.id && grade.absence.id === absenceKindIds.PRESENT);
				if (lessonGrades.length > maxLength) {
					maxLength = lessonGrades.length;
				}
				datasets.push({
					data: reverse(lessonGrades.map((grade) => grade.grade).filter((grade) => grade !== null && grade !== undefined)),
					label: lesson.description,
					borderColor: this.chartBorderColors[colorCounter],
					backgroundColor: this.chartBgColors[colorCounter],
				});
				labels = reverse(
					lessonGrades.map((grade) => moment(grade.exam_date).format("ddd, DD/MM/YYYY")).filter((grade) => grade !== null && grade !== undefined),
				);
				colorCounter++;
				for (let i = 0; i < maxLength; i++) {
					baseLineData.push(50);
				}
				if (maxLength === 0) {
					return null;
				} else {
					datasets.push({
						data: baseLineData,
						borderColor: "#444444",
						backgroundColor: "#555555",
						label: "Βάση",
						pointRadius: 0,
					});
					return (
						<Col
							xl={6}
							key={lesson.id}
						>
							<Card
								title={`Πρόοδος ${lesson.description}`}
								headerSpannig={[7, 5]}
								id={`studentGradeChart${lesson.id}Card`}
								extraOnHeader={<i className="fas fa-chart-line fa-2x"></i>}
							>
								<div
									style={{ height: "400px", width: "100%" }}
									className="mb-3"
								>
									<CustomChart
										id={`studentGradeChart${lesson.id}`}
										type="line"
										legend={{
											legend: {
												display: true,
												position: "bottom",
												labels: {
													fontSize: 10,
													boxWidth: 28,
												},
											},
										}}
										data={{
											labels: labels,
											datasets: datasets,
										}}
										scales={{
											y: {
												suggestedMin: 0,
												suggestedMax: 100,
												ticks: {
													stepSize: 10,
												},
											},
										}}
									/>
								</div>
							</Card>
						</Col>
					);
				}
			});
		} else {
			return <p className="text-danger mx-auto mt-3 text-bold">Δεν βρέθηκαν βαθμολογίες. Δεν κατέστη δυνατόν να παραχθούν στατιστικά.</p>;
		}
	};

	render() {
		return this.getUniqueLessons();
	}
}

const mapStateToProps = (state) => {
	return {
		student: allStudents(state)[0],
		department: allDepartments(state)[0],
		allGrades: allGrades(state),
	};
};

StatisticsTab.propTypes = {
	department: PropTypes.exact(DepartmentShape),
	student: PropTypes.exact(StudentFromSelectorShape),
	allGrades: PropTypes.arrayOf(PropTypes.exact(GradeShape)),
};

export default connect(mapStateToProps)(StatisticsTab);
