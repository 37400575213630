import PropTypes from "prop-types";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";

import DepartmentAssignments from "../../Components/Admin/AppConfiguration/DepartmentAssignments/DepartmentAssignments";
import LessonAssignments from "../../Components/Admin/AppConfiguration/LessonAssignments/LessonAssignments";
import ParametricsManagement from "../../Components/Admin/AppConfiguration/ParametricsManagement/ParametricsManagement";
import ScientificFieldLessonsAssignments from "../../Components/Admin/AppConfiguration/ScientificFieldLessonsAssignments/ScientificFieldLessonsAssignments";
import ShortcutsManagement from "../../Components/Admin/AppConfiguration/ShortcutsManagement/ShortcutsManagement";
import Dashboard from "../../Components/Admin/Dashboard/Dashboard";
import DepartmentsManagement from "../../Components/Admin/DepartmentManagement/DepartmentsManagement";
import GradeManagement from "../../Components/Admin/GradeManagement/GradeManagement";
import LogsView from "../../Components/Admin/LogsView/LogsView";
import EducationalInstitutions from "../../Components/Admin/PanhellenicExaminations/EducationalInstitutions/EducationalInstitutions";
import EpalPanhellenicExaminations from "../../Components/Admin/PanhellenicExaminations/Epal/EpalPanhellenicExaminations";
import PanhellenicExaminations from "../../Components/Admin/PanhellenicExaminations/Gel/PanhellenicExaminations";
import SelfTestChaptersManagement from "../../Components/Admin/SelfTest/SelfTestChaptersManagement";
import SelfTestManagement from "../../Components/Admin/SelfTest/SelfTestManagement";
import SelfTestResultsView from "../../Components/Admin/SelfTest/SelfTestResultsView";
import SelfTestView from "../../Components/Admin/SelfTest/SelfTestView";
import ManageStudentFormWrapper from "../../Components/Admin/StudentManagement/Forms/ManageStudentFormWrapper";
import ResubscribeStudent from "../../Components/Admin/StudentManagement/ResubscribeStudent";
import StudentManagement from "../../Components/Admin/StudentManagement/StudentManagement";
import StudentView from "../../Components/Admin/StudentManagement/StudentView";
import UserManagement from "../../Components/Admin/UserManagement/UserManagement";
import ChangePasswordForm from "../../Components/ChangePassword/ChangePasswordForm";
import Spinner from "../../Components/UI/Spinner/Spinner";
import { ApiResponseShape } from "../../Models/ApiResponseShape";
import { PathEntry } from "../../navigation";
import { fetchUniverseCheckingPermissions, resetApiResponse } from "../../Redux/Actions/index";
import { allFetchingFinished } from "../../Redux/Selectors/universalSelectors";
import { getAuthData } from "../../Utils/AuthUtils";
import { renderAPIMessages } from "../../Utils/GuiUtils";
import { permissions } from "../../Utils/ParametricTablesBinds";
import Footer from "../Footer/Footer";
import PageHeader from "../PageHeader/PageHeader";
import TopBar from "../TopBar/TopBar";
import LeftSideBar from "./LeftSideBar/LeftSideBar";

const MainLayout = ({ allFetchingFinished, apiResponse, fetchUniverseCheckingPermissions, resetApiResponse }) => {
	const history = useHistory();

	useEffect(() => {
		if (Number(getAuthData().permission) !== permissions.ADMIN) {
			history.push(PathEntry.logout.path);
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (!allFetchingFinished) {
			fetchUniverseCheckingPermissions();
		}
	}, [allFetchingFinished, fetchUniverseCheckingPermissions]);
	console.log("MainLayout renders");

	return (
		<div className="wrapper">
			<TopBar />
			<LeftSideBar />
			<section
				className="section-container"
				style={{ minHeight: "calc(100vh - 114px)" }}
			>
				<div className="content-wrapper">
					{!allFetchingFinished ? (
						<div className="d-flex mt-3">
							<h5 className="mr-2">Φόρτωμα Εφαρμογής</h5>
							<Spinner type="ball-pulse-sync" />
						</div>
					) : (
						<>
							<PageHeader />
							{(apiResponse.info !== null || apiResponse.error !== null) &&
								renderAPIMessages(apiResponse, () => {
									resetApiResponse();
								})}
							{Number(getAuthData().permission) === permissions.ADMIN && (
								<Switch>
									<Route
										exact
										path={PathEntry.dashboard.path}
									>
										<Dashboard />
									</Route>
									<Route
										exact
										path={PathEntry.userManagement.path}
									>
										<UserManagement />
									</Route>
									<Route
										exact
										path={PathEntry.shortcutsManagement.path}
									>
										<ShortcutsManagement />
									</Route>
									<Route
										exact
										path={PathEntry.parametricsManagement.path}
									>
										<ParametricsManagement />
									</Route>
									<Route
										exact
										path={PathEntry.lessonAssignments.path}
									>
										<LessonAssignments />
									</Route>
									<Route
										exact
										path={PathEntry.departmentAssignments.path}
									>
										<DepartmentAssignments />
									</Route>
									<Route
										exact
										path={PathEntry.scientificFieldLessonsAssignments.path}
									>
										<ScientificFieldLessonsAssignments />
									</Route>
									<Route
										exact
										path={PathEntry.departmentsManagement.path}
									>
										<DepartmentsManagement />
									</Route>
									<Route
										exact
										path={PathEntry.studentsManagement.path}
									>
										<StudentManagement />
									</Route>
									<Route
										exact
										path={PathEntry.addStudent.path}
									>
										<ManageStudentFormWrapper />
									</Route>
									<Route
										exact
										path={PathEntry.resubscribeStudent.path}
									>
										<ResubscribeStudent />
									</Route>
									<Route
										exact
										path={PathEntry.editStudent.path}
									>
										<ManageStudentFormWrapper />
									</Route>
									<Route
										exact
										path={PathEntry.studentView.path}
									>
										<StudentView />
									</Route>
									<Route
										exact
										path={PathEntry.gradesManagement.path}
									>
										<GradeManagement />
									</Route>
									<Route
										exact
										path={PathEntry.gelPanhellenicExaminations.path}
									>
										<PanhellenicExaminations />
									</Route>
									<Route
										exact
										path={PathEntry.epalPanhellenicExaminations.path}
									>
										<EpalPanhellenicExaminations />
									</Route>
									<Route
										exact
										path={PathEntry.educationalInstitutionsPanhellenicExaminations.path}
									>
										<EducationalInstitutions />
									</Route>
									<Route
										exact
										path={PathEntry.selfTestManagement.path}
									>
										<SelfTestManagement />
									</Route>
									<Route
										exact
										path={PathEntry.selfTestChaptersManagement.path}
									>
										<SelfTestChaptersManagement />
									</Route>
									<Route
										exact
										path={PathEntry.selfTestView.path}
									>
										<SelfTestView />
									</Route>
									<Route
										exact
										path={PathEntry.selfTestResultsView.path}
									>
										<SelfTestResultsView />
									</Route>
									<Route
										exact
										path={PathEntry.logsView.path}
									>
										<LogsView />
									</Route>
								</Switch>
							)}
						</>
					)}
				</div>
			</section>
			{allFetchingFinished && <ChangePasswordForm />}
			<Footer />
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		allFetchingFinished: allFetchingFinished(state),
		apiResponse: state.apiResponse,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchUniverseCheckingPermissions: () => dispatch(fetchUniverseCheckingPermissions()),
		resetApiResponse: () => dispatch(resetApiResponse()),
	};
};

MainLayout.propTypes = {
	allFetchingFinished: PropTypes.bool,
	apiResponse: PropTypes.exact(ApiResponseShape),
	fetchUniverseCheckingPermissions: PropTypes.func,
	resetApiResponse: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
