import PropTypes from "prop-types";
import { memo } from "react";

const Card = memo(({ bodyClasses, cardClasses, children, extraOnHeader, footer, headerSpannig, id, subTitle, title, withLoader }) => {
	return (
		<div
			className={`card card-default ${cardClasses ? cardClasses.join(" ") : ""}`}
			id={id}
		>
			{title && (
				<div className="card-header border-bottom">
					<div className="row align-items-center">
						<div className={`col-lg-${headerSpannig[0]} my-auto col-md-12 text-center text-lg-left`}>
							<h4 className="mb-0">{title}</h4>
							{subTitle ? subTitle : null}
						</div>
						{headerSpannig[1] && extraOnHeader != null ? (
							<div className={`col-lg-${headerSpannig[1]} col-md-12 text-center text-lg-right`}>{extraOnHeader}</div>
						) : null}
					</div>
				</div>
			)}
			<div className={`card-body${withLoader ? " whirl standard" : ""} ${bodyClasses ? bodyClasses.join(" ") : ""}`}>{children}</div>
			{footer ? <div className="card-footer">{footer}</div> : null}
		</div>
	);
});

Card.defaultProps = {
	headerSpannig: [12],
};

Card.propTypes = {
	bodyClasses: PropTypes.arrayOf(PropTypes.string),
	cardClasses: PropTypes.arrayOf(PropTypes.string),
	children: PropTypes.any.isRequired,
	extraOnHeader: PropTypes.element,
	footer: PropTypes.element,
	headerSpannig: PropTypes.arrayOf(PropTypes.number),
	id: PropTypes.string,
	subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	title: PropTypes.string,
	withLoader: PropTypes.bool,
};

export default Card;
