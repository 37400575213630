import React, { Component } from "react";
import { connect } from "react-redux";
import { isEqual } from "lodash";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import { addNewSelfTestAnswer, addNewSelfTestAnswerSuccess, addNewSelfTestAnswerFail } from "../../../../Redux/Actions/index";
import { PathEntry } from "../../../../navigation";
import { SelfTestSelectorShape } from "../../../../Models/SelfTestShape";
import { SelfTestAnswersReduxShape } from "../../../../Models/SelfTestAnswersShape";
import { StudentFromSelectorShape } from "../../../../Models/StudentShape";
import { getSelfTestKind } from "../../../../Utils/GuiUtils";
import { clone } from "../../../../Utils/Utils";
import { Row, Col } from "../../../UI/Grid/Grid";
import BaseInput from "../../../UI/BaseInput/BaseInput";
import Button from "../../../UI/Button/Button";
import Card from "../../../UI/Card/Card";

class SelfTestForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selfTestForm: {},
			testFinished: false,
			finalGrade: 0,
		};
	}

	componentDidMount() {
		this.setState({ selfTestForm: this.initStateData() });
	}

	componentDidUpdate(prevProps) {
		if (!isEqual(prevProps.currentSelfTest, this.props.currentSelfTest)) {
			this.setState({ selfTestForm: this.initStateData() });
		}
	}

	componentWillUnmount() {
		if (this.props.currentSelfTestAnswers === null) {
			this.onFormSubmit();
		}
	}

	initStateData = () => {
		let option = [];
		if (getSelfTestKind(this.props.currentSelfTest.correct_answers) === "Σωστό - Λάθος") {
			option = this.getTrueFalseAnswerOptions();
		} else if (getSelfTestKind(this.props.currentSelfTest.correct_answers) === "Πολλαπλής Επιλογής") {
			option = this.getMultipleChoiceAnswerOptions();
		} else {
			option = this.getTrueFalseAnswerOptions().concat(this.getMultipleChoiceAnswerOptions());
		}
		const answers = {};
		const currentSelfTestAnswers = this.props.currentSelfTestAnswers?.self_test_answers ?? null;
		if (this.props.currentSelfTestAnswers !== null) {
			this.setState({ finalGrade: this.props.currentSelfTestAnswers.self_test_grade });
		}
		for (let i = 1; i < 11; i++) {
			answers[`q${i}`] = {
				label: `Ερώτηση ${i}`,
				name: `q${i}`,
				placeholder: "Επιλογή",
				options: option,
				searchable: false,
				required: true,
				value: currentSelfTestAnswers !== null ? { value: currentSelfTestAnswers[i - 1], label: currentSelfTestAnswers[i - 1] } : null,
				correctAnswer: this.props.currentSelfTest.correct_answers[i - 1],
			};
		}
		return answers;
	};

	getTrueFalseAnswerOptions = () => {
		return [
			{ value: "Σωστό", label: "Σωστό" },
			{ value: "Λάθος", label: "Λάθος" },
		];
	};

	getMultipleChoiceAnswerOptions = () => {
		return [
			{ value: "Α", label: "Α" },
			{ value: "Β", label: "Β" },
			{ value: "Γ", label: "Γ" },
			{ value: "Δ", label: "Δ" },
			{ value: "Ε", label: "Ε" },
		];
	};

	onChangeHandler = (selectedOption, inputId) => {
		const updatedForm = clone(this.state.selfTestForm);
		const updatedFormElement = clone(updatedForm[inputId]);
		updatedFormElement.value = selectedOption;
		updatedForm[inputId] = updatedFormElement;
		this.setState({ selfTestForm: updatedForm });
	};

	onFormSubmit = () => {
		const output = {};

		output.self_test_answers = [];
		output.self_test_id = this.props.currentSelfTest.id;
		output.student_id = this.props.currentStudent.id;
		for (let key in this.state.selfTestForm) {
			output.self_test_answers.push(this.state.selfTestForm[key].value?.value ?? null);
		}
		output.self_test_grade = this.calculateGrade(output.self_test_answers);
		this.props
			.addNewSelfTestAnswer(output)
			.then((response) => {
				this.setState({ testFinished: true, finalGrade: output.self_test_grade });
				this.props.addNewSelfTestAnswerSuccess(response.data);
			})
			.catch((error) => {
				this.setState({ testFinished: true, finalGrade: output.self_test_grade });
				this.props.addNewSelfTestAnswerFail(error);
			});
	};

	calculateGrade = (answers) => {
		let countCorrectAnswers = 0;
		answers.forEach((answer, idx) => {
			if (answer === this.props.currentSelfTest.correct_answers[idx]) {
				countCorrectAnswers++;
			}
		});
		return countCorrectAnswers * 10;
	};

	render() {
		const correctAnswersElementsArray = [];
		for (let key in this.state.selfTestForm) {
			correctAnswersElementsArray.push({
				id: key,
				config: this.state.selfTestForm[key],
			});
		}
		return (
			<Card title="Απαντήσεις">
				<Row classes={["px-2"]}>
					{correctAnswersElementsArray.map((element, idx) => {
						const isAnswerCorrect =
							(this.state.testFinished || this.props.currentSelfTestAnswers !== null) && element.config.correctAnswer === element.config.value?.value;
						return (
							<Col
								xl={6}
								classes={["mb-3", "px-2"]}
								key={"mainform" + idx}
							>
								<label>
									{element.config.label} {element.config.required ? <span className="reqField">(*)</span> : null}
								</label>
								<BaseInput
									key={element.config.name + element.config.id}
									name={element.config.name}
									placeholder={element.config.placeholder}
									searchable={element.config.searchable}
									options={element.config.options}
									type={element.config.inputType}
									value={element.config.value}
									disabled={this.state.testFinished || this.props.currentSelfTestAnswers !== null}
									onChange={(selectedOption) => {
										this.onChangeHandler(selectedOption, element.id);
									}}
									classes={
										this.state.testFinished || this.props.currentSelfTestAnswers !== null
											? isAnswerCorrect
												? ["select-is-valid"]
												: ["select-is-invalid"]
											: [""]
									}
								/>
								{(this.state.testFinished || this.props.currentSelfTestAnswers !== null) &&
									(element.config.correctAnswer === element.config.value?.value ? (
										<p className="mt-1 mb-0">
											<i className="fas fa-check text-success mr-1"></i> <b>{element.config.correctAnswer}</b>
										</p>
									) : (
										<p className="mt-1 mb-0">
											<i className="fas fa-times text-danger mr-1"></i> <b>{element.config.correctAnswer}</b>
										</p>
									))}
							</Col>
						);
					})}
					{!this.state.testFinished && this.props.currentSelfTestAnswers === null && (
						<Col classes={["text-center", "my-2"]}>
							<Button
								type="button"
								text="Ολοκλήρωση"
								kind="success"
								disabled={this.state.testFinished || this.props.currentSelfTestAnswers !== null}
								onClick={() => {
									this.props.countDownRef.current.pause();
									this.onFormSubmit();
								}}
								classes={["px-5"]}
							/>
						</Col>
					)}
				</Row>
				{(this.state.testFinished || this.props.currentSelfTestAnswers !== null) && (
					<Row>
						<Col
							style={{ fontSize: "220%" }}
							classes={["text-center"]}
						>
							Βαθμολογία:{" "}
							<span className={`text-bold ${this.state.finalGrade >= 50 ? "text-success" : "text-danger"}`}>{this.state.finalGrade}%</span>
						</Col>
						<Col classes={["text-center", "mt-2"]}>
							<Button
								type="button"
								kind="link"
								text="Διαθέσιμα Test"
								iconClass="fas fa-chevron-left mr-1"
								onClick={() => this.props.history.push(PathEntry.studentSelfTestList.path)}
							/>
							<Button
								type="button"
								kind="link"
								text="Αποτελέσματα"
								iconClass="fas fa-chevron-left mr-1"
								onClick={() => this.props.history.push(PathEntry.studentSelfTestResults.path)}
							/>
						</Col>
					</Row>
				)}
			</Card>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		addNewSelfTestAnswer: (newSelfTestAnswerData) => dispatch(addNewSelfTestAnswer(newSelfTestAnswerData)),
		addNewSelfTestAnswerSuccess: (newSelfTestAnswerData) => dispatch(addNewSelfTestAnswerSuccess(newSelfTestAnswerData)),
		addNewSelfTestAnswerFail: (error) => dispatch(addNewSelfTestAnswerFail(error)),
	};
};

SelfTestForm.propTypes = {
	currentSelfTest: PropTypes.exact(SelfTestSelectorShape),
	currentStudent: PropTypes.exact(StudentFromSelectorShape),
	currentSelfTestAnswers: PropTypes.exact(SelfTestAnswersReduxShape),
	countDownRef: PropTypes.object,
	addNewSelfTestAnswer: PropTypes.func,
	addNewSelfTestAnswerSuccess: PropTypes.func,
	addNewSelfTestAnswerFail: PropTypes.func,
};

export default withRouter(connect(null, mapDispatchToProps)(SelfTestForm));
