import { updateObject } from "../../Utils/Utils";
import {
	ADD_NEW_SELF_TEST_FAIL,
	ADD_NEW_SELF_TEST_START,
	ADD_NEW_SELF_TEST_SUCCESS,
	AUTH_LOGOUT,
	FETCH_ALL_SELF_TESTS_FAIL,
	FETCH_ALL_SELF_TESTS_START,
	FETCH_ALL_SELF_TESTS_SUCCESS,
	FETCH_CURRENT_STUDENT_SELF_TESTS_FAIL,
	FETCH_CURRENT_STUDENT_SELF_TESTS_START,
	FETCH_CURRENT_STUDENT_SELF_TESTS_SUCCESS,
	UPDATE_SELF_TEST_FAIL,
	UPDATE_SELF_TEST_START,
	UPDATE_SELF_TEST_SUCCESS,
} from "../Actions/actionTypes";

const initialState = {
	allSelfTests: [],
	error: null,
	finished: false,
	loading: false,
	success: false,
};

export const fetchSelfTestsStart = (state) => {
	return updateObject(state, {
		error: null,
		finished: false,
		loading: true,
		success: false,
	});
};

const fetchSelfTestsSuccess = (state, action) => {
	return updateObject(state, {
		allSelfTests: action.payload,
		error: null,
		finished: true,
		loading: false,
		success: false,
	});
};

const fetchSelfTestsFail = (state, action) => {
	return updateObject(state, {
		allSelfTests: [],
		error: action.payload,
		finished: false,
		loading: false,
		success: false,
	});
};

const addNewSelfTestStart = (state) => {
	return updateObject(state, {
		error: null,
		loading: true,
		success: false,
	});
};

const addNewSelfTestSuccess = (state, action) => {
	const updatedST = [...state.allSelfTests];
	const newST = { ...action.payload };
	updatedST.push(newST);
	return updateObject(state, {
		allSelfTests: updatedST,
		error: null,
		loading: false,
		success: true,
	});
};

const addNewSelfTestFail = (state, action) => {
	return updateObject(state, {
		error: action.payload,
		loading: false,
		success: false,
	});
};

const updateSelfTestStart = (state) => {
	return updateObject(state, {
		error: null,
		loading: true,
		success: false,
	});
};

const updateSelfTestSuccess = (state, action) => {
	const updatedSTS = state.allSelfTests.filter((stc) => stc.id !== action.payload.id);
	const updatedST = { ...action.payload };
	updatedSTS.push(updatedST);
	return updateObject(state, {
		allSelfTests: updatedSTS,
		error: null,
		loading: false,
		success: true,
	});
};

const updateSelfTestFail = (state, action) => {
	return updateObject(state, {
		error: action.payload,
		loading: false,
		success: false,
	});
};

const authLogout = (state) => {
	return updateObject(state, initialState);
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_ALL_SELF_TESTS_START:
			return fetchSelfTestsStart(state);
		case FETCH_ALL_SELF_TESTS_FAIL:
			return fetchSelfTestsFail(state, action);
		case FETCH_ALL_SELF_TESTS_SUCCESS:
			return fetchSelfTestsSuccess(state, action);
		case FETCH_CURRENT_STUDENT_SELF_TESTS_START:
			return fetchSelfTestsStart(state);
		case FETCH_CURRENT_STUDENT_SELF_TESTS_FAIL:
			return fetchSelfTestsFail(state, action);
		case FETCH_CURRENT_STUDENT_SELF_TESTS_SUCCESS:
			return fetchSelfTestsSuccess(state, action);
		case ADD_NEW_SELF_TEST_START:
			return addNewSelfTestStart(state);
		case ADD_NEW_SELF_TEST_FAIL:
			return addNewSelfTestFail(state, action);
		case ADD_NEW_SELF_TEST_SUCCESS:
			return addNewSelfTestSuccess(state, action);
		case UPDATE_SELF_TEST_START:
			return updateSelfTestStart(state);
		case UPDATE_SELF_TEST_FAIL:
			return updateSelfTestFail(state, action);
		case UPDATE_SELF_TEST_SUCCESS:
			return updateSelfTestSuccess(state, action);
		case AUTH_LOGOUT:
			return authLogout(state);
		default:
			return state;
	}
};

export default reducer;
