import { sortArrayOfObjectsByString, updateObject } from "../../Utils/Utils";
import {
	ADD_NEW_SELF_TEST_CHAPTER_FAIL,
	ADD_NEW_SELF_TEST_CHAPTER_START,
	ADD_NEW_SELF_TEST_CHAPTER_SUCCESS,
	AUTH_LOGOUT,
	DELETE_SELF_TEST_CHAPTER_FAIL,
	DELETE_SELF_TEST_CHAPTER_START,
	DELETE_SELF_TEST_CHAPTER_SUCCESS,
	FETCH_ALL_SELF_TEST_CHAPTERS_FAIL,
	FETCH_ALL_SELF_TEST_CHAPTERS_START,
	FETCH_ALL_SELF_TEST_CHAPTERS_SUCCESS,
	UPDATE_SELF_TEST_CHAPTERS_FAIL,
	UPDATE_SELF_TEST_CHAPTERS_START,
	UPDATE_SELF_TEST_CHAPTERS_SUCCESS,
} from "../Actions/actionTypes";

const initialState = {
	allSelfTestChapters: [],
	error: null,
	finished: false,
	loading: false,
	success: false,
};

export const fetchSelfTestChaptersStart = (state) => {
	return updateObject(state, {
		error: null,
		finished: false,
		loading: true,
		success: false,
	});
};

const fetchSelfTestChaptersSuccess = (state, action) => {
	return updateObject(state, {
		allSelfTestChapters: action.payload,
		error: null,
		finished: true,
		loading: false,
		success: false,
	});
};

const fetchSelfTestChaptersFail = (state, action) => {
	return updateObject(state, {
		allSelfTestChapters: [],
		error: action.payload,
		finished: false,
		loading: false,
		success: false,
	});
};

const addNewSelfTestChapterStart = (state) => {
	return updateObject(state, {
		error: null,
		loading: true,
		success: false,
	});
};

const addNewSelfTestChapterSuccess = (state, action) => {
	const updatedSTC = [...state.allSelfTestChapters];
	const newSTC = { ...action.payload };
	updatedSTC.push(newSTC);
	sortArrayOfObjectsByString(updatedSTC, "name");
	return updateObject(state, {
		allSelfTestChapters: updatedSTC,
		error: null,
		loading: false,
		success: true,
	});
};

const addNewSelfTestChapterFail = (state, action) => {
	return updateObject(state, {
		error: action.payload,
		loading: false,
		success: false,
	});
};

const updateSelfTestChapterStart = (state) => {
	return updateObject(state, {
		error: null,
		loading: true,
		success: false,
	});
};

const updateSelfTestChapterSuccess = (state, action) => {
	const updatedSTCS = state.allSelfTestChapters.filter((stc) => stc.id !== action.payload.id);
	const updatedSTC = { ...action.payload };
	updatedSTCS.push(updatedSTC);
	sortArrayOfObjectsByString(updatedSTCS, "name");
	return updateObject(state, {
		allSelfTestChapters: updatedSTCS,
		error: null,
		loading: false,
		success: true,
	});
};

const updateSelfTestChapterFail = (state, action) => {
	return updateObject(state, {
		error: action.payload,
		loading: false,
		success: false,
	});
};

const deleteSelfTestChapterStart = (state) => {
	return updateObject(state, {
		error: null,
		loading: true,
		success: false,
	});
};

const deleteSelfTestChapterSuccess = (state, action) => {
	const updatedSTCS = [...state.allSelfTestChapters].filter((obj) => obj.id !== action.payload.id);
	return updateObject(state, {
		allSelfTestChapters: updatedSTCS,
		error: null,
		loading: false,
		success: true,
	});
};

const deleteSelfTestChapterFail = (state, action) => {
	return updateObject(state, {
		error: action.payload,
		loading: false,
		success: false,
	});
};

const authLogout = (state) => {
	return updateObject(state, initialState);
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_ALL_SELF_TEST_CHAPTERS_START:
			return fetchSelfTestChaptersStart(state);
		case FETCH_ALL_SELF_TEST_CHAPTERS_FAIL:
			return fetchSelfTestChaptersFail(state, action);
		case FETCH_ALL_SELF_TEST_CHAPTERS_SUCCESS:
			return fetchSelfTestChaptersSuccess(state, action);
		case ADD_NEW_SELF_TEST_CHAPTER_START:
			return addNewSelfTestChapterStart(state);
		case ADD_NEW_SELF_TEST_CHAPTER_FAIL:
			return addNewSelfTestChapterFail(state, action);
		case ADD_NEW_SELF_TEST_CHAPTER_SUCCESS:
			return addNewSelfTestChapterSuccess(state, action);
		case UPDATE_SELF_TEST_CHAPTERS_START:
			return updateSelfTestChapterStart(state);
		case UPDATE_SELF_TEST_CHAPTERS_FAIL:
			return updateSelfTestChapterFail(state, action);
		case UPDATE_SELF_TEST_CHAPTERS_SUCCESS:
			return updateSelfTestChapterSuccess(state, action);
		case DELETE_SELF_TEST_CHAPTER_START:
			return deleteSelfTestChapterStart(state);
		case DELETE_SELF_TEST_CHAPTER_FAIL:
			return deleteSelfTestChapterFail(state, action);
		case DELETE_SELF_TEST_CHAPTER_SUCCESS:
			return deleteSelfTestChapterSuccess(state, action);
		case AUTH_LOGOUT:
			return authLogout(state);
		default:
			return state;
	}
};

export default reducer;
