import PropTypes from "prop-types";
import { ParametricShape } from "./ParametricShape";
import { LessonShape } from "./LessonShape";
import { UserFromReduxShape } from "./UserShape";
import { StudentFromSelectorShape } from "./StudentShape";

export const GradeShape = {
	absence: PropTypes.shape(ParametricShape),
	comments: PropTypes.string,
	created_at: PropTypes.string,
	exam_date: PropTypes.string,
	exam_kind: PropTypes.shape(ParametricShape),
	grade: PropTypes.number,
	id: PropTypes.number,
	lesson: PropTypes.shape(LessonShape),
	student: PropTypes.shape(StudentFromSelectorShape),
	lesson_id: PropTypes.number,
	student_id: PropTypes.number,
	teacher: PropTypes.shape(UserFromReduxShape),
	teacher_id: PropTypes.number,
	updated_at: PropTypes.string,
};
