import { ActionKinds } from "./ActionKinds";

export const getAuthUrl = (action) => {
	if (action === ActionKinds.POST) {
		return `/auth/login/`;
	} else {
		console.error(errorString(action, 7), [ActionKinds.POST]);
	}
};

export const getDepartmentsUrl = (action, id) => {
	switch (action) {
		case ActionKinds.GET:
		case ActionKinds.POST:
			return `/departments/`;
		case ActionKinds.GET_ONE:
		case ActionKinds.UPDATE:
		case ActionKinds.DELETE:
			return `/department/${id}/`;
		default:
			console.error(errorString(action, 21), Object.values(ActionKinds));
			break;
	}
};

export const getGradesUrl = (action, id) => {
	switch (action) {
		case ActionKinds.GET:
		case ActionKinds.POST:
			return `/grades/`;
		case ActionKinds.GET_ONE:
		case ActionKinds.UPDATE:
		case ActionKinds.DELETE:
			return `/grade/${id}/`;
		default:
			console.error(errorString(action, 36), Object.values(ActionKinds));
			break;
	}
};

export const getParametricsUrl = (action, id, table) => {
	switch (action) {
		case ActionKinds.GET:
		case ActionKinds.POST:
			return `/parametrics/${table}/`;
		case ActionKinds.GET_ONE:
		case ActionKinds.UPDATE:
			return `/parametrics/${table}/${id}/`;
		default:
			console.error(
				errorString(action, 66),
				Object.values(ActionKinds).filter((action) => action !== ActionKinds.DELETE),
			);
			break;
	}
};

export const getParametricsSummaryUrl = (action, id, table) => {
	switch (action) {
		case ActionKinds.GET:
			return `/parametrics/`;
		default:
			console.error(
				errorString(action, 66),
				Object.values(ActionKinds).filter((action) => action !== ActionKinds.DELETE),
			);
			break;
	}
};

export const getShortcutsUrl = (action, id) => {
	switch (action) {
		case ActionKinds.GET:
		case ActionKinds.POST:
			return `/shortcuts/`;
		case ActionKinds.GET_ONE:
		case ActionKinds.UPDATE:
		case ActionKinds.DELETE:
			return `/shortcut/${id}/`;
		default:
			console.error(errorString(action, 81), Object.values(ActionKinds));
			break;
	}
};

export const getShortcutLessonsUrl = (action) => {
	if (action === ActionKinds.GET) {
		return `/shortcut/lessons/`;
	} else {
		console.error(errorString(action, 90), [ActionKinds.GET]);
	}
};

export const getTeacherLessonsUrl = (action, teacherId) => {
	switch (action) {
		case ActionKinds.GET:
		case ActionKinds.POST:
			return `/teacher/lessons/`;
		case ActionKinds.GET_ONE:
			return `/teacher/lessons/${teacherId}/`;
		case ActionKinds.DELETE:
			return `/teacher/lessons/${teacherId}/`;
		default:
			console.error(
				errorString(action, 102),
				Object.values(ActionKinds).filter((action) => action !== ActionKinds.UPDATE),
			);
			break;
	}
};

export const getCurrentTeacherLessonsUrl = (action) => {
	switch (action) {
		case ActionKinds.GET:
			return `/current_teacher_lessons/`;
		default:
			console.error(
				errorString(action, 115),
				Object.values(ActionKinds).filter((action) => action !== ActionKinds.UPDATE),
			);
			break;
	}
};

export const getCurrentTeacherTeacherLessonMappingsUrl = (action) => {
	switch (action) {
		case ActionKinds.GET:
			return `/teacher/teacher_lessons/mappings/`;
		default:
			console.error(
				errorString(action, 115),
				Object.values(ActionKinds).filter((action) => action !== ActionKinds.UPDATE),
			);
			break;
	}
};

export const getTeacherDepartmentsUrl = (action, teacherId) => {
	switch (action) {
		case ActionKinds.GET:
		case ActionKinds.POST:
			return `/teacher_departments/`;
		case ActionKinds.GET_ONE:
			return `/teacher_departments/${teacherId}/`;
		case ActionKinds.DELETE:
			return `/teacher_departments/${teacherId}/`;
		default:
			console.error(
				errorString(action, 115),
				Object.values(ActionKinds).filter((action) => action !== ActionKinds.UPDATE),
			);
			break;
	}
};

export const getCurrentTeacherTeacherDepartmentMappingsUrl = (action) => {
	switch (action) {
		case ActionKinds.GET:
			return `/teacher/teacher_department/mappings/`;
		default:
			console.error(
				errorString(action, 115),
				Object.values(ActionKinds).filter((action) => action !== ActionKinds.UPDATE),
			);
			break;
	}
};

export const getCurrentTeacherDepartmentsUrl = (action) => {
	switch (action) {
		case ActionKinds.GET:
			return `/teacher/departments/`;
		default:
			console.error(
				errorString(action, 115),
				Object.values(ActionKinds).filter((action) => action !== ActionKinds.UPDATE),
			);
			break;
	}
};

export const getCurrentTeacherGradesUrl = (action) => {
	switch (action) {
		case ActionKinds.GET:
			return `/teacher/grades/`;
		default:
			console.error(
				errorString(action, 115),
				Object.values(ActionKinds).filter((action) => action !== ActionKinds.UPDATE),
			);
			break;
	}
};

export const getCurrentStudentUserGradesUrl = (action) => {
	switch (action) {
		case ActionKinds.GET:
			return `/student/user/grades/`;
		default:
			console.error(
				errorString(action, 164),
				Object.values(ActionKinds).filter((action) => action !== ActionKinds.UPDATE),
			);
			break;
	}
};

export const getCurrentStudentUserDepartmentsUrl = (action) => {
	switch (action) {
		case ActionKinds.GET_ONE:
			return `/student/user/department/`;
		default:
			console.error(
				errorString(action, 174),
				Object.values(ActionKinds).filter((action) => action !== ActionKinds.UPDATE),
			);
			break;
	}
};

export const getUserToStudentUrl = (action) => {
	switch (action) {
		case ActionKinds.GET:
		case ActionKinds.POST:
			return `/user/student/`;
		default:
			console.error(
				errorString(action, 184),
				Object.values(ActionKinds).filter((action) => action !== ActionKinds.UPDATE),
			);
			break;
	}
};

export const getUsersUrl = (action, id) => {
	switch (action) {
		case ActionKinds.GET:
		case ActionKinds.POST:
			return `/users/`;
		case ActionKinds.GET_ONE:
			return `/user/${id}/`;
		case ActionKinds.UPDATE:
		case ActionKinds.DELETE:
			return `/user/${id}/`;
		case "reset-password":
			return `/user/${id}/reset-password/`;
		default:
			console.error(
				errorString(action, 191),
				Object.values(ActionKinds).filter((action) => action !== ActionKinds.DELETE),
			);
			break;
	}
};

export const getStudentsUrl = (action, id) => {
	switch (action) {
		case ActionKinds.GET:
		case ActionKinds.POST:
			return `/students/`;
		case ActionKinds.GET_ONE:
		case ActionKinds.UPDATE:
		case ActionKinds.DELETE:
			return `/student/${id}/`;
		default:
			console.error(errorString(action, 147), Object.values(ActionKinds));
			break;
	}
};

export const getStudentLessonsUrl = (action) => {
	if (action === ActionKinds.GET) {
		return `/student/lessons/`;
	} else {
		console.error(errorString(action, 156), [ActionKinds.GET]);
	}
};

export const getStudentResubscriptionUrl = (action) => {
	if (action === ActionKinds.GET) {
		return `/student/resubscription/`;
	} else {
		console.error(errorString(action, 156), [ActionKinds.GET]);
	}
};

export const getStudentUserUrl = (action) => {
	if (action === ActionKinds.GET_ONE) {
		return `/student/user/`;
	} else {
		console.error(errorString(action, 211), [ActionKinds.GET]);
	}
};

export const getStudentSelfTestsUrl = (action) => {
	if (action === ActionKinds.GET_ONE) {
		return `/student/user/self-tests/`;
	} else {
		console.error(errorString(action, 250), [ActionKinds.GET_ONE]);
	}
};

export const getCurrentTeacherStudentsUrl = (action) => {
	switch (action) {
		case ActionKinds.GET:
			return `/teacher/students/`;
		default:
			console.error(
				errorString(action, 115),
				Object.values(ActionKinds).filter((action) => action !== ActionKinds.UPDATE),
			);
			break;
	}
};

export const getPanhelleniceExaminationsResultsUrl = (action, panhellenicExaminationId) => {
	switch (action) {
		case ActionKinds.GET:
		case ActionKinds.POST:
			return `/panhellenic_examinations/`;
		// case ActionKinds.GET_ONE:
		case ActionKinds.UPDATE:
		case ActionKinds.DELETE:
			return `/panhellenic_examination/${panhellenicExaminationId}/`;
		default:
			console.error(errorString(action, 147), Object.values(ActionKinds));
			break;
	}
};

export const getSelfTestChaptersUrl = (action, selfTestChapterId) => {
	switch (action) {
		case ActionKinds.GET:
		case ActionKinds.POST:
			return `/self_test_chapters/`;
		case ActionKinds.UPDATE:
		case ActionKinds.DELETE:
			return `/self_test_chapter/${selfTestChapterId}/`;
		default:
			console.error(errorString(action, 313), Object.values(ActionKinds));
			break;
	}
};

export const getSelfTestsUrl = (action, selfTestId) => {
	switch (action) {
		case ActionKinds.GET:
		case ActionKinds.POST:
			return `/self_tests/`;
		case ActionKinds.UPDATE:
			return `/self_test/${selfTestId}/`;
		default:
			console.error(errorString(action, 328), Object.values(ActionKinds));
			break;
	}
};

export const getSelfTestAnswersUrl = (action, selfTestId) => {
	switch (action) {
		case ActionKinds.GET:
			return `/self_test/answers/`;
		case ActionKinds.POST:
			return `/self_test/answer/`;
		default:
			console.error(errorString(action, 348), Object.values(ActionKinds));
			break;
	}
};

export const getSelfTestStudentAnswersUrl = (action, selfTestId) => {
	switch (action) {
		case ActionKinds.GET:
			return `/self_test/student/answers/`;
		default:
			console.error(errorString(action, 358), Object.values(ActionKinds));
			break;
	}
};

export const getLogsUrl = (action) => {
	switch (action) {
		case ActionKinds.GET:
			return `/logs/`;
		default:
			console.error(errorString(action, 147), Object.values(ActionKinds));
			break;
	}
};

export const getScientificFieldToLessonsUrl = (action, scientific_field_id) => {
	switch (action) {
		case ActionKinds.GET:
		case ActionKinds.POST:
			return `/scientific_field_lessons/`;
		case ActionKinds.DELETE:
			return `/scientific_field_lessons/${scientific_field_id}/`;
		default:
			console.error(errorString(action, 147), Object.values(ActionKinds));
			break;
	}
};

const errorString = (action, errorLine) => {
	return `Warning appear in BackendAPI.js line ${errorLine} | The propert action was ${action} and should have the one of the following: `;
};
