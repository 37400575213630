import { isEmpty, uniqBy } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import "react-notifications/lib/notifications.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import ReactTooltip from "react-tooltip";

import { GradeShape } from "../../../Models/GradeShape";
import { PanhellenicExaminationsShape } from "../../../Models/PanhellenicExaminationsShape";
import { StudentFromSelectorShape } from "../../../Models/StudentShape";
import { allGrades } from "../../../Redux/Selectors/gradesSelectors";
import { allPanhellenicExaminationsResults } from "../../../Redux/Selectors/panhellenicExaminationsSelector";
import { allStudents } from "../../../Redux/Selectors/studentsSelectors";
import { allFetchingFinished } from "../../../Redux/Selectors/universalSelectors";
import { CUSTOM_ERRORS } from "../../../Utils/CustomErrors";
import { getGradeCountersFooter, renderWait } from "../../../Utils/GuiUtils";
import { absenceKindIds, examKindIds } from "../../../Utils/ParametricTablesBinds";
import Badge from "../../UI/Badge/Badge";
import Button from "../../UI/Button/Button";
import Card from "../../UI/Card/Card";
import Datatable from "../../UI/Datatable/Datatable";
import { Col, Row } from "../../UI/Grid/Grid";
import LessonGradesStats from "./LessonGradesStats/LessonGradesStats";
import StudentDetailsRow from "./StudentDetailsRow";

const columns = [
	{
		name: "Α/Α",
		selector: (row) => row.id,
		sortable: true,
		width: "75px",
	},
	{
		name: "Ημ. Εξέτασης",
		selector: (row) => row.exam_date,
		format: (row) => (row.exam_date ? moment(row.exam_date).format("ddd, DD/MM/YYYY") : ""),
		sortable: true,
		wrap: true,
	},
	{
		name: "Καθηγητής",
		selector: (row) => row.teacher.lastname + " " + row.teacher.firstname,
		sortable: true,
		wrap: true,
	},
	{
		name: "Μαθητής",
		selector: (row) => row.student.lastname + " " + row.student.firstname,
		omit: true,
	},
	{
		name: "Μάθημα",
		selector: (row) => row.lesson.description,
		sortable: true,
		wrap: true,
	},
	{
		name: "Τμήμα",
		selector: (row) => row.student.department.name,
		omit: true,
	},
	{
		name: "Τάξη",
		selector: (row) => row.student.department.class.description,
		omit: true,
	},
	{
		name: "Είδος Εξέτασης",
		selector: (row) => row.exam_kind.description,
		format: (row) => {
			const badgeKind = (() => {
				if (row.exam_kind.id === examKindIds.EXAM) {
					return "purple";
				} else if (row.exam_kind.id === examKindIds.TEST) {
					return "primary";
				} else if (row.exam_kind.id === examKindIds.STUDY) {
					return "green";
				} else {
					CUSTOM_ERRORS.throwError("GradeManagement/GradeManagement.js | line 82", CUSTOM_ERRORS.UNEXPECTED_IF_STATEMENT, row.exam_kind);
				}
			})();
			return (
				<Badge
					kind={badgeKind}
					text={row.exam_kind.description}
				/>
			);
		},
		sortable: true,
	},
	{
		name: "Παρουσία",
		selector: (row) => row.absence.description,
		format: (row) => (
			<Badge
				kind={row.absence.id === absenceKindIds.NOT_PRESENT ? "danger" : "success"}
				text={row.absence.description}
			/>
		),
		sortable: true,
	},
	{
		name: "Βαθμός",
		selector: (row) => row.grade,
		sortable: true,
		maxWidth: "105px",
	},
	{
		name: "Καταχωρήθηκε",
		selector: (row) => row.created_at,
		format: (row) => (row.created_at ? moment(row.created_at).format("ddd, DD/MM/YYYY HH:mm:ss") : ""),
		sortable: true,
		wrap: true,
	},
	{
		name: "Σχόλια",
		selector: (row) => row.comments,
		format: (row) => (!isEmpty(row.comments) ? <i className="fas fa-check"></i> : ""),
		wrap: true,
	},
];

const panhellenicExaminationsColumns = [
	{
		name: "Μάθημα",
		selector: (row) => row.lesson.description,
		sortable: true,
		wrap: true,
	},
	{
		name: "Συντ. Βαρύτητας",
		selector: (row) => (row.calculation_weight !== undefined ? row.calculation_weight : ""),
		sortable: true,
		wrap: true,
	},
	{
		name: "Βαθμολογία",
		selector: (row) => row.grade,
		format: (row) => <span style={{ fontSize: "120%" }}>{row.grade}</span>,
		sortable: true,
		wrap: true,
	},
];

class StudentView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			student: null,
			filterText: "",
			filteredData: [],
			filterPEText: "",
			filteredPEData: [],
		};
	}

	componentDidMount() {
		document.title = process.env.REACT_APP_NAME + " :: Προβολή Μαθητή";
		if (this.props.allFetchingFinished) {
			const student = this.props.allStudents.find((student) => student.id === Number(this.props.match.params.student_id));
			this.setStudentInState(student);
		}
	}

	componentDidUpdate(prevProps, PrevState) {
		if (!prevProps.allFetchingFinished && this.props.allFetchingFinished) {
			const student = this.props.allStudents.find((student) => student.id === Number(this.props.match.params.student_id));
			this.setStudentInState(student);
			this.resetAllFilters();
			this.resetAllPEFilters();
		}
	}

	setStudentInState = (tmp) => {
		const student = {
			personal: {
				firstname: {
					label: "Όνομα:",
					value: tmp.firstname,
					notificationText: "Το Όνομα αντιγράφηκε",
				},
				lastname: {
					label: "Επώνυμο:",
					value: tmp.lastname,
					notificationText: "Το Επώνυμο αντιγράφηκε",
				},
				email: {
					label: "Email:",
					value: tmp.email,
					notificationText: "Το Email αντιγράφηκε",
					extraButtons:
						tmp.email !== null ? (
							<Button
								classes={["ml-1", "btn-sm"]}
								type="button"
								iconClass="far fa-envelope-open"
								kind="info"
								disabled={false}
								onClick={() => window.open(`mailto:${tmp.email}`)}
								data-tip="Αποστολή email"
							/>
						) : null,
				},
				mobile_phone: {
					label: "Κιν. Τηλέφωνο:",
					value: tmp.mobile_phone,
					notificationText: "Το κινητό τηλ. αντιγράφηκε",
				},
				landline: {
					label: "Σταθ. Τηλέφωνο:",
					value: tmp.landline,
					notificationText: "Το σταθερό τηλ. αντιγράφηκε",
				},
				address: {
					label: "Διεύθυνση:",
					value: tmp.address,
					notificationText: "Η διεύθυνση αντιγράφηκε",
					extraButtons:
						tmp.address !== null && tmp.zip !== null ? (
							<Button
								classes={["ml-1", "btn-sm"]}
								type="button"
								iconClass="fas fa-map-marker-alt"
								kind="info"
								disabled={false}
								onClick={() => window.open(`http://maps.google.com?q=${tmp.address},${tmp.county},${tmp.zip}`, "_blank")}
								data-tip="Google Maps"
							/>
						) : undefined,
				},
				country: {
					label: "Περιοχή:",
					value: tmp.county,
					notificationText: "Η περιοχή αντιγράφηκε",
				},
				zip: {
					label: "Ταχ. Κωδικός:",
					value: tmp.zip,
					notificationText: "Ο ταχ. κωδικός αντιγράφηκε",
				},
			},
			caretaker: {
				father_name: {
					label: "Όνομα Πατέρα:",
					value: tmp.father_name,
					notificationText: "Το Όνομα αντιγράφηκε",
				},
				mother_name: {
					label: "Όνομα Μητέρας:",
					value: tmp.mother_name,
					notificationText: "Το Όνομα αντιγράφηκε",
				},
				father_phone: {
					label: "Τηλέφωνο Πατέρα:",
					value: tmp.father_phone,
					notificationText: "Το Τηλέφωνο αντιγράφηκε",
				},
				mother_phone: {
					label: "Τηλέφωνο Μητέρας:",
					value: tmp.mother_phone,
					notificationText: "Το Τηλέφωνο αντιγράφηκε",
				},
				caretaker_name: {
					label: "Όνομα Κηδεμόνα:",
					value: tmp.caretaker_name,
					notificationText: "Το Όνομα αντιγράφηκε",
				},
				caretaker_email: {
					label: "Email Κηδεμόνα:",
					value: tmp.caretaker_email,
					notificationText: "Το Email αντιγράφηκε",
					extraButtons:
						tmp.caretaker_email !== null ? (
							<Button
								classes={["ml-1", "btn-sm"]}
								type="button"
								iconClass="far fa-envelope-open"
								kind="info"
								disabled={false}
								onClick={() => window.open(`mailto:${tmp.caretaker_email}`)}
								data-tip="Αποστολή email"
							/>
						) : null,
				},
				caretaker_phone: {
					label: "Τηλέφωνο Κηδεμόνα:",
					value: tmp.caretaker_phone,
					notificationText: "Το Τηλέφωνο αντιγράφηκε",
				},
			},
			studies: {
				submission_date: {
					label: "Ημ. Εγγραφής:",
					value: moment(tmp.submission_date).format("ddd, DD/MM/YYYY"),
					notificationText: "Η Ημ. Εγγραφής αντιγράφηκε",
				},
				fee: {
					label: "Δίδακτρα:",
					value: tmp.fee,
					notificationText: "Τα Δίδακτρα αντιγράφηκαν",
				},
				class: {
					label: "Τάξη:",
					value: tmp.department.class.description,
					notificationText: "Η Τάξη αντιγράφηκε",
				},
				department: {
					label: "Τμήμα:",
					value: tmp.department.name,
					notificationText: "Το Τμήμα αντιγράφηκαν",
				},
				informed_by: {
					label: "Ενημερώθηκε Από:",
					value: tmp.informed_by?.description ?? null,
					notificationText: "Η Ενημέρωση αντιγράφηκε",
				},
				lessons: {
					label: "Μαθήματα:",
					value: tmp.lessons?.map((lesson) => lesson.description).join(", "),
					notificationText: "Τα Μαθήματα αντιγράφηκαν",
				},
			},
			grades: this.props.allGrades.filter((grade) => grade.student_id === tmp.id),
			educationalInstitution: tmp.educational_institution,
			panhellenicExaminationResults: this.props.allPanhellenicExaminationsResults.find((pe) => pe.student_id === tmp.id),
		};
		this.setState({ student });
	};

	getStudentStats = () => {
		if (this.state.student !== null) {
			const studentLessons = this.props.allStudents.find((student) => student.id === Number(this.props.match.params.student_id)).lessons;
			const stats = [];
			studentLessons.forEach((lesson) => {
				const lessonGrades = this.state.student.grades.filter(
					(grade) => grade.lesson_id === lesson.id && grade.absence.id === absenceKindIds.PRESENT,
				);
				if (lessonGrades.length > 0) {
					const stat = {
						lesson: lesson,
						gradeCount: lessonGrades.length,
						maxGrade: maxGrade(lessonGrades.map((grade) => grade.grade)),
						minGrade: minGrade(lessonGrades.map((grade) => grade.grade)),
						testAvg: getAvgOfGrades(lessonGrades.filter((grade) => grade.exam_kind.id === examKindIds.TEST).map((grade) => grade.grade)),
						examAvg: getAvgOfGrades(lessonGrades.filter((grade) => grade.exam_kind.id === examKindIds.EXAM).map((grade) => grade.grade)),
						studiesAvg: getAvgOfGrades(lessonGrades.filter((grade) => grade.exam_kind.id === examKindIds.STUDY).map((grade) => grade.grade)),
						totalAvg: getAvgOfGrades(lessonGrades.map((grade) => grade.grade)).replace("%", ""),
						notPresent: this.state.student.grades.filter((grade) => grade.lesson_id === lesson.id && grade.absence.id === absenceKindIds.NOT_PRESENT)
							.length,
						latestCreatedAt: getLatestCreatedAtDate(lessonGrades),
						lessonGrades: lessonGrades,
					};
					stats.push(stat);
				}
			});
			return stats;
		}
		return null;
	};

	resetAllFilters = () => {
		this.setState({
			filterText: "",
			filteredData: [],
		});
	};

	resetAllPEFilters = () => {
		this.setState({
			filterPEText: "",
			filteredPEData: [],
		});
	};

	setFilter = (filterString) => {
		const filteredItems = this.state.student?.grades.filter((grade) =>
			(grade.teacher.lastname + " " + grade.teacher.firstname).toLowerCase().includes(filterString.toLowerCase()),
		);
		const filteredItems2 = this.state.student?.grades.filter((grade) => grade.lesson.description.toLowerCase().includes(filterString.toLowerCase()));
		const filteredItems3 = this.state.student?.grades.filter((grade) => grade.absence.description.toLowerCase().includes(filterString.toLowerCase()));
		const filteredItems4 = this.state.student?.grades.filter((grade) =>
			grade.exam_kind.description.toLowerCase().includes(filterString.toLowerCase()),
		);
		this.setState({
			filterText: filterString,
			filteredData: uniqBy(filteredItems.concat(filteredItems2, filteredItems3, filteredItems4), "id"),
		});
	};

	setPEFilter = (filterString) => {
		const filteredItems = this.state.student?.panhellenicExaminationResults?.grades.filter((grade) =>
			grade.lesson.description.toLowerCase().includes(filterString.toLowerCase()),
		);
		this.setState({
			filterPEText: filterString,
			filteredPEData: uniqBy(filteredItems, "lesson.id"),
		});
	};

	render() {
		if (this.state.student !== null && this.props.allFetchingFinished) {
			return this.renderSuccess();
		} else {
			return renderWait("Φόρτωση Στοιχείων Μαθητή");
		}
	}

	renderSuccess() {
		const currentStudentObject = this.props.allStudents.find((student) => student.id === Number(this.props.match.params.student_id));

		const personalFormElementsArray = [];
		for (let key in this.state.student.personal) {
			personalFormElementsArray.push({
				config: this.state.student.personal[key],
			});
		}

		const caretakerFormElementsArray = [];
		for (let key in this.state.student.caretaker) {
			caretakerFormElementsArray.push({
				config: this.state.student.caretaker[key],
			});
		}

		const studiesFormElementsArray = [];
		for (let key in this.state.student.studies) {
			studiesFormElementsArray.push({
				config: this.state.student.studies[key],
			});
		}

		const studentStats = this.getStudentStats();
		const panhellenicExaminationResultsData = this.state.student?.panhellenicExaminationResults?.grades.concat(
			this.state.student?.panhellenicExaminationResults?.designGrades,
			this.state.student?.panhellenicExaminationResults?.flGrades,
		);
		console.log(this.state.student?.grades);
		return (
			<>
				<Tabs>
					<TabList>
						<Tab>Προσωπικά Στοιχεία</Tab>
						<Tab>Στοιχεία Κηδεμόνων</Tab>
						<Tab>Στοιχεία Φοίτησης</Tab>
						<Tab>Βαθμολογίες</Tab>
						<Tab>Στατιστικά</Tab>
						<Tab>Πανελλαδικές Εξετάσεις</Tab>
					</TabList>
					<TabPanel>
						<ReactTooltip effect="solid" />
						<Card
							title="Προσωπικά Στοιχεία"
							headerSpannig={[9, 3]}
							extraOnHeader={
								<i
									className="fas fa-user"
									style={{ fontSize: "250%" }}
								></i>
							}
						>
							<Col
								xl={10}
								lg={10}
								md={12}
								sm={12}
								classes={["offset-md-2", "m-auto"]}
							>
								{personalFormElementsArray.map((data, idx) => {
									return (
										<StudentDetailsRow
											key={idx}
											label={data.config.label}
											value={data.config.value}
											name={idx + "_input"}
											notificationText={data.config.notificationText}
											extraButtons={data.config.extraButtons}
										/>
									);
								})}
							</Col>
						</Card>
					</TabPanel>
					<TabPanel>
						<ReactTooltip effect="solid" />
						<Card
							title="Στοιχεία Κηδεμόνων"
							headerSpannig={[9, 3]}
							extraOnHeader={
								<i
									className="fas fa-user-friends"
									style={{ fontSize: "250%" }}
								></i>
							}
						>
							<Col
								xl={10}
								lg={10}
								md={12}
								sm={12}
								classes={["offset-md-2", "m-auto"]}
							>
								{caretakerFormElementsArray.map((data, idx) => {
									return (
										<StudentDetailsRow
											key={idx}
											label={data.config.label}
											value={data.config.value}
											name={idx + "_input"}
											notificationText={data.config.notificationText}
											extraButtons={data.config.extraButtons}
										/>
									);
								})}
							</Col>
						</Card>
					</TabPanel>
					<TabPanel>
						<ReactTooltip effect="solid" />
						<Card
							title="Στοιχεία Φοίτησης"
							headerSpannig={[9, 3]}
							extraOnHeader={
								<i
									className="fas fa-user-graduate"
									style={{ fontSize: "250%" }}
								></i>
							}
						>
							<Col
								xl={10}
								lg={10}
								md={12}
								sm={12}
								classes={["offset-md-2", "m-auto"]}
							>
								{studiesFormElementsArray.map((data, idx) => {
									return (
										<StudentDetailsRow
											key={idx}
											label={data.config.label}
											value={data.config.value}
											name={idx + "_input"}
											notificationText={data.config.notificationText}
											extraButtons={data.config.extraButtons}
										/>
									);
								})}
							</Col>
						</Card>
					</TabPanel>
					<TabPanel>
						<Card
							title="Βαθμολογίες"
							subTitle={<small>Αριθμός Καταχωρήσεων: {this.state.student?.grades.length}</small>}
							id="studentGradesCard"
							headerSpannig={[9, 3]}
							extraOnHeader={
								<i
									className="far fa-star"
									style={{ fontSize: "250%" }}
								></i>
							}
							footer={
								!isEmpty(this.state.student.grades)
									? !isEmpty(this.state.filterText)
										? getGradeCountersFooter(this.state.filteredData)
										: getGradeCountersFooter(this.state.student.grades)
									: null
							}
						>
							{!isEmpty(this.state.student.grades) ? (
								<Datatable
									columns={columns}
									defaultSortFieldId={2}
									defaultSortAsc={false}
									exportCsv
									exportFilename={`Πίνακας Βαθμολογιών ${
										currentStudentObject.lastname + " " + currentStudentObject.firstname
									} Μαθητολογίου Πολύτροπο`}
									filteredData={this.state.filteredData}
									filterText={this.state.filterText}
									onClear={() => {
										this.setState({ filterText: "", filteredData: [] });
									}}
									onFilter={(e) => {
										this.setFilter(e.target.value);
									}}
									rowData={isEmpty(this.state.filterText) ? this.state.student?.grades : this.state.filteredData}
								/>
							) : (
								<p className="text-danger text-bold text-center mt-2">Δεν έχουν καταχωρηθεί βαθμολογίες</p>
							)}
						</Card>
					</TabPanel>
					<TabPanel>
						{!isEmpty(studentStats) ? (
							<Row>
								{studentStats.map((stat, idx) => {
									return (
										<LessonGradesStats
											stats={stat}
											key={idx}
											cardIndex={idx}
										/>
									);
								})}
							</Row>
						) : (
							<p className="text-danger text-bold text-center mt-2">Δεν έχουν καταχωρηθεί βαθμολογίες - Δεν είναι δυνατό να παραχθούν στατιστικά</p>
						)}
					</TabPanel>
					<TabPanel>
						{this.state.student?.educationalInstitution !== null ? (
							<div
								className="border border-info rounded p-3 shadow-sm mb-3 bg-white"
								style={{ fontSize: "110%" }}
							>
								<b>Εκπαιδευτικό Ίδρυμα Εισαγωγής: </b> {this.state.student.educationalInstitution.description}
							</div>
						) : null}
						{this.state.student.panhellenicExaminationResults ? (
							<Card
								title="Βαθμολογίες Πανελλαδικών Εξετάσεων"
								id="panhellenicExaminationGradesCard"
								headerSpannig={[9, 3]}
								extraOnHeader={
									<i
										className="far fa-star"
										style={{ fontSize: "250%" }}
									></i>
								}
								footer={
									<Row>
										<Col classes={["text-bold"]}>
											{this.state.student?.panhellenicExaminationResults.scientific_field_id ? "Μόρια" : "Κοινή Ομάδα"}:{" "}
											<span
												className="text-green mr-2 br pr-2"
												style={{ fontSize: "120%" }}
											>
												{this.state.student?.panhellenicExaminationResults.final_result}
											</span>
											Μέσο Όρο:{" "}
											<span
												className="text-green mr-2 br pr-2"
												style={{ fontSize: "120%" }}
											>
												{this.state.student?.panhellenicExaminationResults.average}
											</span>
											{this.state.student?.panhellenicExaminationResults.final_result_with_special_lessons && (
												<>
													{this.state.student?.panhellenicExaminationResults.scientific_field_id ? "Ειδικό Μάθημα" : "Τομέας"}:{" "}
													<span
														className="text-green mr-2 br pr-2"
														style={{ fontSize: "120%" }}
													>
														{this.state.student?.panhellenicExaminationResults.final_result_with_special_lessons}
													</span>
												</>
											)}
											{this.state.student?.panhellenicExaminationResults.final_result_with_factor_2 && (
												<>
													Συντ.2:{" "}
													<span
														className="text-green"
														style={{ fontSize: "120%" }}
													>
														{this.state.student?.panhellenicExaminationResults.final_result_with_factor_2}
													</span>
												</>
											)}
										</Col>
									</Row>
								}
							>
								<Datatable
									columns={panhellenicExaminationsColumns}
									defaultSortFieldId={1}
									exportCsv
									exportFilename={`Πίνακας Βαθμολογιών Πανελλαδικών ${
										currentStudentObject.lastname + " " + currentStudentObject.firstname
									} Μαθητολογίου Πολύτροπο`}
									filteredData={this.state.filteredPEData}
									filterText={this.state.filterPEText}
									onClear={() => {
										this.setState({ filterPEText: "", filteredPEData: [] });
									}}
									onFilter={(e) => {
										this.setPEFilter(e.target.value);
									}}
									rowData={isEmpty(this.state.filterPEText) ? panhellenicExaminationResultsData : this.state.filteredPEData}
								/>
							</Card>
						) : (
							<p className="text-danger text-bold text-center mt-2">Δεν έχουν καταχωρηθεί βαθμολογίες Πανελλαδικών Εξετάσεων</p>
						)}
					</TabPanel>
				</Tabs>
			</>
		);
	}
}

const maxGrade = (grades) => {
	let max = 0;
	grades.forEach((grade) => {
		if (grade > max && grade !== null) max = grade;
	});
	return max + "%";
};

const minGrade = (grades) => {
	let min = 100;
	grades.forEach((grade) => {
		if (grade < min && grade !== null) min = grade;
	});
	return min + "%";
};

const getAvgOfGrades = (grades) => {
	if (grades.length === 0) return "-";
	let sum = 0;
	grades.forEach((grade) => {
		sum += grade;
	});
	return (sum / grades.length).toFixed(0) + "%";
};

const getLatestCreatedAtDate = (grades) => {
	grades.sort((a, b) => new Date(a.exam_date).getTime() - new Date(b.exam_date).getTime());
	return moment(grades[grades.length - 1].created_at).format("ddd, DD/MM/YYYY");
};

const mapStateToProps = (state) => {
	return {
		allStudents: allStudents(state),
		allGrades: allGrades(state),
		allPanhellenicExaminationsResults: allPanhellenicExaminationsResults(state),
		allFetchingFinished: allFetchingFinished(state),
	};
};

StudentView.propTypes = {
	allStudents: PropTypes.arrayOf(PropTypes.exact(StudentFromSelectorShape)),
	allGrades: PropTypes.arrayOf(PropTypes.exact(GradeShape)),
	allPanhellenicExaminationsResults: PropTypes.arrayOf(PropTypes.exact(PanhellenicExaminationsShape)),
	allFetchingFinished: PropTypes.bool,
};

export default withRouter(connect(mapStateToProps)(StudentView));
