import { deleteResource, getResource, postResource, putResource } from "../../BackendAPI/AbstractCRUDActions";
import { ActionKinds } from "../../BackendAPI/ActionKinds";
import { getSelfTestChaptersUrl } from "../../BackendAPI/BackendAPI";
import {
	ADD_NEW_SELF_TEST_CHAPTER_FAIL,
	ADD_NEW_SELF_TEST_CHAPTER_START,
	ADD_NEW_SELF_TEST_CHAPTER_SUCCESS,
	DELETE_SELF_TEST_CHAPTER_FAIL,
	DELETE_SELF_TEST_CHAPTER_START,
	DELETE_SELF_TEST_CHAPTER_SUCCESS,
	FETCH_ALL_SELF_TEST_CHAPTERS_FAIL,
	FETCH_ALL_SELF_TEST_CHAPTERS_START,
	FETCH_ALL_SELF_TEST_CHAPTERS_SUCCESS,
	UPDATE_SELF_TEST_CHAPTERS_FAIL,
	UPDATE_SELF_TEST_CHAPTERS_START,
	UPDATE_SELF_TEST_CHAPTERS_SUCCESS,
} from "./actionTypes";

export const fetchAllSelfTestChaptersStart = () => {
	return {
		type: FETCH_ALL_SELF_TEST_CHAPTERS_START,
	};
};

export const fetchAllSelfTestChaptersSuccess = (selfTestChaptersData) => {
	return {
		type: FETCH_ALL_SELF_TEST_CHAPTERS_SUCCESS,
		payload: selfTestChaptersData,
	};
};

export const fetchAllSelfTestChaptersFail = (error) => {
	return {
		type: FETCH_ALL_SELF_TEST_CHAPTERS_FAIL,
		payload: error,
	};
};

export const fetchAllSelfTestChapters = () => {
	return (dispatch) => {
		dispatch(fetchAllSelfTestChaptersStart());
		const url = getSelfTestChaptersUrl(ActionKinds.GET);
		getResource(url)
			.then((response) => {
				dispatch(fetchAllSelfTestChaptersSuccess(response.data));
			})
			.catch((error) => {
				dispatch(fetchAllSelfTestChaptersFail(error));
			});
	};
};

export const addNewSelfTestChapterStart = () => {
	return {
		type: ADD_NEW_SELF_TEST_CHAPTER_START,
	};
};
export const addNewSelfTestChapterSuccess = (newSelfTestChapterData) => {
	return {
		type: ADD_NEW_SELF_TEST_CHAPTER_SUCCESS,
		payload: newSelfTestChapterData,
	};
};

export const addNewSelfTestChapterFail = (error) => {
	return {
		type: ADD_NEW_SELF_TEST_CHAPTER_FAIL,
		payload: error,
	};
};

export const addNewSelfTestChapter = (newSelfTestChapterData) => {
	return (dispatch) => {
		dispatch(addNewSelfTestChapterStart());
		const url = getSelfTestChaptersUrl(ActionKinds.POST);
		return postResource(url, newSelfTestChapterData);
	};
};

export const updateSelfTestChapterStart = () => {
	return {
		type: UPDATE_SELF_TEST_CHAPTERS_START,
	};
};

export const updateSelfTestChapterSuccess = (selfTestChapterData) => {
	return {
		type: UPDATE_SELF_TEST_CHAPTERS_SUCCESS,
		payload: selfTestChapterData,
	};
};

export const updateSelfTestChapterFail = (error) => {
	return {
		type: UPDATE_SELF_TEST_CHAPTERS_FAIL,
		payload: error,
	};
};

export const updateSelfTestChapter = (selfTestChapterData, selfTestChapterId) => {
	return (dispatch) => {
		dispatch(updateSelfTestChapterStart());
		const url = getSelfTestChaptersUrl(ActionKinds.UPDATE, selfTestChapterId);
		return putResource(url, selfTestChapterData);
	};
};

export const deleteSelfTestChapterStart = () => {
	return {
		type: DELETE_SELF_TEST_CHAPTER_START,
	};
};

export const deleteSelfTestChapterSuccess = (selfTestChapterData) => {
	return {
		type: DELETE_SELF_TEST_CHAPTER_SUCCESS,
		payload: selfTestChapterData,
	};
};

export const deleteSelfTestChapterFail = (error) => {
	return {
		type: DELETE_SELF_TEST_CHAPTER_FAIL,
		payload: error,
	};
};

export const deleteSelfTestChapter = (selfTestChapterId) => {
	return (dispatch) => {
		dispatch(deleteSelfTestChapterStart());
		const url = getSelfTestChaptersUrl(ActionKinds.DELETE, selfTestChapterId);
		return deleteResource(url);
	};
};
