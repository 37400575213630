import { isEmpty, uniqBy } from "lodash";
import moment from "moment";
import React, { Component } from "react";

import { getResource } from "../../../BackendAPI/AbstractCRUDActions";
import { ActionKinds } from "../../../BackendAPI/ActionKinds";
import { getLogsUrl } from "../../../BackendAPI/BackendAPI";
import { renderWait } from "../../../Utils/GuiUtils";
import Card from "../../UI/Card/Card";
import Datatable from "../../UI/Datatable/Datatable";

const errorTableColumns = [
	{
		name: "Α/Α",
		selector: (row) => row.id,
		sortable: true,
		maxWidth: "100px",
	},
	{
		name: "Ημερομηνία",
		selector: (row) => row.date,
		format: (row) => (row.date ? moment(row.date).format("ddd, DD/MM/YYYY HH:mm:ss") : ""),
		sortable: true,
		wrap: true,
	},
	{
		name: "ID Χρήστη",
		selector: (row) => row.user.userId,
		sortable: true,
	},
	{
		name: "Χρήστης",
		selector: (row) => row.user.username,
		sortable: true,
		wrap: true,
	},
	{
		name: "Error Code",
		selector: (row) => row.error_code,
		sortable: true,
	},
	{
		name: "Error Message",
		selector: (row) => row.error_message,
		sortable: true,
		wrap: true,
		grow: 2,
	},
	{
		name: "SQL State",
		selector: (row) => row.sql_state,
		sortable: true,
	},
];

class LogsView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			error_logs: [],
			finished: false,
			error: null,
			filterText: "",
			filteredData: [],
		};
		this.toggledClearRows = false;
	}

	componentDidMount() {
		const url = getLogsUrl(ActionKinds.GET);
		getResource(url)
			.then((response) => {
				let id = 1;
				const newData = response.data.map((row) => {
					row.id = id;
					id++;
					return row;
				});
				this.setState({
					error_logs: newData,
					finished: true,
				});
			})
			.catch((error) => {
				this.setState({ error });
			});
	}

	setFilter = (filterString) => {
		const filteredItems = this.state.error_logs.filter((log) => log.user.username?.toLowerCase().includes(filterString.toLowerCase()));
		const filteredItems2 = this.state.error_logs.filter((log) => log.error_code === Number(filterString));
		this.setState({
			filterText: filterString,
			filteredData: uniqBy(filteredItems.concat(filteredItems2), "id"),
		});
	};

	render() {
		if (this.state.finished || this.state.error !== null) {
			return this.renderSuccess();
		} else {
			return renderWait("Φόρτωση Logs");
		}
	}

	renderSuccess() {
		return (
			<Card
				title="Προβολή Error Logs"
				subTitle={<div className="text-sm">Αριθμός Καταχωρήσεων: {isEmpty(this.state.error_logs) ? 0 : this.state.error_logs.length}</div>}
				id="errorLogCard"
			>
				<Datatable
					columns={errorTableColumns}
					defaultSortFieldId={2}
					defaultSortAsc={false}
					exportCsv
					exportFilename="Πίνακας Logs Μαθητολογίου Πολύτροπο"
					filteredData={this.state.filteredData}
					filterText={this.state.filterText}
					onClear={() => {
						this.setState({ filterText: "", filteredData: [] });
						this.toggledClearRows = !this.toggledClearRows;
					}}
					onFilter={(e) => {
						this.setFilter(e.target.value);
						this.toggledClearRows = !this.toggledClearRows;
					}}
					rowData={isEmpty(this.state.filterText) ? this.state.error_logs : this.state.filteredData}
					toggledClearRows={this.toggledClearRows}
				/>
			</Card>
		);
	}
}

export default LogsView;
