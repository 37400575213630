import { updateObject } from "../../Utils/Utils";
import { AUTH_LOGOUT, RESET_API_RESPONSE, SET_API_RESPONSE } from "../Actions/actionTypes";

const initialState = {
	info: null,
	error: null,
};

const setApiResponse = (state, action) => {
	const updatedObj = {
		info: action.payload.info,
		error: action.payload.error,
	};
	return updateObject(state, updatedObj);
};

const resetApiResponse = (state) => {
	return updateObject(state, initialState);
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_API_RESPONSE:
			return setApiResponse(state, action);
		case RESET_API_RESPONSE:
			return resetApiResponse(state);
		case AUTH_LOGOUT:
			return resetApiResponse(state);
		default:
			return state;
	}
};

export default reducer;
