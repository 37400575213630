import PropTypes from "prop-types";

const Tag = ({ children, classes, kind }) => {
	return <span className={`tag${kind ? ` tag-${kind}` : ""} ${classes ? classes.join(" ") : ""}`}>{children}</span>;
};

Tag.propTypes = {
	children: PropTypes.any,
	classes: PropTypes.array,
	kind: PropTypes.oneOf(["magenta", "red", "orange", "green", "blue", "geekblue", "purple"]),
};

export default Tag;
