import $ from "jquery";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { PathEntry, STUDENT_APP_SELF_TEST_PATHS } from "../../../navigation";
import { resetApiResponse } from "../../../Redux/Actions";
import MenuItem from "./MenuItems/MenuItem/MenuItem";
import MenuItems from "./MenuItems/MenuItems";

class LeftSideBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activePath: this.props.location.pathname,
			hoverLink: null,
			menuItems: {
				dashboard: {
					items: [
						{
							active: this.props.location.pathname,
							icon: <em className="fas fa-tachometer-alt" />,
							onClick: () => this.setActivePath(PathEntry.studentDashboard.path),
							title: "Dashboard",
							to: PathEntry.studentDashboard.path,
						},
					],
				},
				gradesView: {
					items: [
						{
							active: this.props.location.pathname,
							icon: <em className="far fa-star" />,
							onClick: () => this.setActivePath(PathEntry.studentGradesView.path),
							title: "Προβολή Βαθμολογιών",
							to: PathEntry.studentGradesView.path,
						},
					],
				},
				selfTests: {
					headerTitle: "Test Αυτοαξιολόγησης",
					containerId: "selfTestContainer",
					items: [
						{
							active: this.props.location.pathname,
							onClick: () => this.setActivePath(PathEntry.studentSelfTestList.path),
							title: "Προβολή Test",
							to: PathEntry.studentSelfTestList.path,
						},
						{
							active: this.props.location.pathname,
							onClick: () => this.setActivePath(PathEntry.studentSelfTestResults.path),
							title: "Προβολή Αποτελεσμάτων",
							to: PathEntry.studentSelfTestResults.path,
						},
					],
				},
			},
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.location.pathname !== this.props.location.pathname) {
			const updatedMenuItems = { ...this.state.menuItems };
			for (let key in updatedMenuItems) {
				const items = [...updatedMenuItems[key].items];
				for (let itemKey in items) {
					const updatedItem = { ...items[itemKey] };
					updatedItem.active = this.props.location.pathname;
					items[itemKey] = updatedItem;
				}
				updatedMenuItems[key].items = items;
			}
			this.setState({ menuItems: updatedMenuItems });
			if (this.props.apiResponse.info !== null || this.props.apiResponse.error !== null) {
				this.props.resetApiResponse();
			}
		}
	}

	setActivePath = (path) => {
		this.setState({ activePath: path });
	};

	remainOpen = (id) => {
		this.setState({ hoverLink: id });
	};

	onHover = (id) => {
		this.setState({ hoverLink: id });
	};

	onLeave = () => {
		this.setState({ hoverLink: null });
	};

	renderPopup = () => {
		if ($("body").hasClass("aside-collapsed")) {
			const coord = document.querySelector("#" + this.state.menuItems[this.state.hoverLink].containerId).getBoundingClientRect();
			return (
				<MenuItems
					headerTitle={this.state.menuItems[this.state.hoverLink].headerTitle}
					classes={["show", "popupMenu", "nav-floating"]}
					id={this.state.hoverLink}
					onMouseEnter={() => this.remainOpen(this.state.hoverLink)}
					onMouseLeave={this.onLeave}
					style={{ position: "absolute", top: coord.top, bottom: "auto" }}
					items={this.state.menuItems[this.state.hoverLink].items}
				/>
			);
		}
	};

	render() {
		return (
			<aside className="aside-container lowZindex">
				<div className="aside-inner">
					<nav
						className="sidebar"
						data-sidebar-anyclick-close=""
					>
						<ul className="sidebar-nav">
							<li className="nav-heading ">
								<span data-localize="sidebar.heading.COMPONENTS">Main Navigation</span>
							</li>
							<MenuItem item={this.state.menuItems.dashboard.items[0]} />
							<MenuItem item={this.state.menuItems.gradesView.items[0]} />
							<li
								className={STUDENT_APP_SELF_TEST_PATHS.includes(this.state.activePath) ? "active" : null}
								onMouseEnter={() => this.onHover("studentAppSelfTests")}
								onMouseLeave={this.onLeave}
							>
								<a
									href="#studentAppSelfTests"
									title={this.state.menuItems.selfTests.headerTitle}
									id={this.state.menuItems.selfTests.containerId}
									data-toggle="collapse"
								>
									<em className="fas fa-tasks"></em>
									<span>{this.state.menuItems.selfTests.headerTitle}</span>
								</a>
								<MenuItems
									headerTitle={this.state.menuItems.selfTests.headerTitle}
									id="studentAppSelfTests"
									items={this.state.menuItems.selfTests.items}
								/>
							</li>
						</ul>
					</nav>
				</div>
				{this.state.hoverLink != null ? this.renderPopup() : null}
			</aside>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		apiResponse: state.apiResponse,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		resetApiResponse: () => dispatch(resetApiResponse()),
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeftSideBar));
