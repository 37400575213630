import React, { Component } from "react";
import { isEqual, isEmpty, has } from "lodash";
import PropTypes from "prop-types";

import { clone, sortArrayOfObjectsByNumbers } from "../../../../../../Utils/Utils";
import { StudentFromSelectorShape } from "../../../../../../Models/StudentShape";
import BaseInput from "../../../../../UI/BaseInput/BaseInput";
import { Col } from "../../../../../UI/Grid/Grid";

class StandardLessonsForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			standardLessonForm: null,
		};
	}

	componentDidMount() {
		this.getLessonFields();
	}

	componentDidUpdate(prevProps, prevState) {
		if (!isEqual(prevProps.selectedResult, this.props.selectedResult)) {
			this.getLessonFields();
		}
		if (!prevProps.executeReset && this.props.executeReset) {
			this.getLessonFields();
			this.props.resetExecuteResetToggle();
		}
	}

	getLessonFields = () => {
		const output = {};
		output.student_id = {
			label: "Μαθητής",
			name: "student_id",
			options: this.getStudentsDropdown(),
			placeholder: "Μαθητής",
			required: true,
			value: this.props.selectedResult
				? {
						value: this.props.selectedResult.student.id,
						label: this.props.selectedResult.student.lastname + " " + this.props.selectedResult.student.firstname,
				  }
				: "",
			colSpan: 12,
		};
		this.props.scientificFieldLessons.forEach((sfLesson) => {
			output[sfLesson.lesson.description] = {
				label: sfLesson.lesson.description,
				name: sfLesson.lesson.description,
				placeholder: "Εισάγετε βαθμό",
				calculation_weight: sfLesson.calculation_weight,
				lesson_id: sfLesson.lesson_id,
				required: true,
				isNumber: true,
				value: this.props.selectedResult ? this.props.selectedResult.grades.find((grade) => grade.lesson.id === sfLesson.lesson_id).grade : "",
				inputType: "number",
				colSpan: 3,
				mdColSpan: 6,
			};
		});
		this.setState({ standardLessonForm: output });
	};

	getStudentsDropdown = () => {
		const updatedStudents = this.props.allStudents.map((student) => {
			return {
				value: student.id,
				label: student.lastname + " " + student.firstname,
			};
		});
		return updatedStudents;
	};

	getGrade = () => {
		const grades = [];
		const form = clone(this.state.standardLessonForm);
		if (has(form, "student_id")) delete form.student_id;
		Object.keys(form).forEach((key) => {
			if (form[key].value !== "")
				grades.push({
					lesson_id: form[key].lesson_id,
					grade: form[key].value,
				});
		});
		return grades;
	};

	onChangeHandler = (selectedOption, inputId) => {
		const updatedForm = clone(this.state.standardLessonForm);
		const updatedFormElement = clone(updatedForm[inputId]);

		if (has(updatedFormElement, "options")) {
			updatedFormElement.value = selectedOption;
		} else {
			updatedFormElement.value = isEmpty(selectedOption.target.value) ? selectedOption.target.value : Number(selectedOption.target.value);
		}

		updatedForm[inputId] = updatedFormElement;
		this.setState({ standardLessonForm: updatedForm }, () => {
			this.calculateResult();
			this.props.setIsFormInvalid(this.isFormInvalid(), "StandardLessonsForm");
		});
	};

	isFormInvalid = () => {
		let allFalse = false;
		for (let key in this.state.standardLessonForm) {
			if (this.state.standardLessonForm[key].required) {
				if (this.state.standardLessonForm[key].value === "" || this.state.standardLessonForm[key].value === null) {
					allFalse = true;
				}
			}
		}
		return allFalse;
	};

	areGradesValid = (grades) => {
		let output = true;
		grades.forEach((grade) => {
			if (grade > 20) {
				this.props.setGradesError("Βρέθηκε βαθμός > 20 σε βασικό μάθημα. Παρακαλώ διορθώστε.", "StandardLessonsForm");
				output = false;
			} else if (grade < 0) {
				this.props.setGradesError("Βρέθηκε βαθμός < 0 σε βασικό μάθημα. Παρακαλώ διορθώστε.", "StandardLessonsForm");
				output = false;
			}
		});
		if (output) {
			this.props.setGradesError(null, "StandardLessonsForm");
		}
		return output;
	};

	calculateResult = () => {
		let counter = 0;
		const tmp = [];
		for (let key in this.state.standardLessonForm) {
			if (this.state.standardLessonForm[key].lesson_id !== undefined) {
				if (this.state.standardLessonForm[key].value !== "") {
					tmp.push({
						lesson_id: this.state.standardLessonForm[key].lesson_id,
						calculation_weight: this.state.standardLessonForm[key].calculation_weight,
						grade: this.state.standardLessonForm[key].value,
					});
					counter++;
				}
			}
		}
		if (counter === 4 && tmp.length === 4) {
			if (this.areGradesValid(tmp.map((item) => item.grade))) {
				sortArrayOfObjectsByNumbers(tmp, "calculation_weight", "desc");
				const gradeWithBiggerWeight = tmp[0].grade * tmp[0].calculation_weight;
				const gradeWithSmallerWeight = tmp[1].grade * tmp[1].calculation_weight;
				const result = Math.round(
					((tmp[0].grade + tmp[1].grade + tmp[2].grade + tmp[3].grade) * 2 + gradeWithBiggerWeight + gradeWithSmallerWeight) * 100,
				);
				this.props.setStandardLessons(result, this.state.standardLessonForm.student_id.value, this.getGrade());
			} else {
				return;
			}
		}
	};

	render() {
		const formElementsArray = [];
		for (let key in this.state.standardLessonForm) {
			formElementsArray.push({
				id: key,
				config: this.state.standardLessonForm[key],
			});
		}

		return (
			<>
				{formElementsArray.map((element, idx) => {
					return (
						<Col
							xl={element.config.colSpan}
							lg={element.config.colSpan}
							md={element.config.mdColSpan}
							sm={element.config.mdColSpan}
							classes={["mb-4", "px-2"]}
							key={idx}
						>
							<label>
								{element.config.label} {element.config.required ? <span className="reqField">(*)</span> : null}
							</label>
							<BaseInput
								key={element.config.name + element.config.id}
								name={element.config.name}
								placeholder={element.config.placeholder}
								options={element.config.options}
								type={element.config.inputType}
								value={element.config.value}
								disabled={this.props.modalReadonly}
								onChange={(selectedOption) => {
									this.onChangeHandler(selectedOption, element.id);
								}}
							/>
						</Col>
					);
				})}
			</>
		);
	}
}

StandardLessonsForm.propTypes = {
	scientificFieldLessons: PropTypes.arrayOf(PropTypes.object),
	allStudents: PropTypes.arrayOf(PropTypes.exact(StudentFromSelectorShape)),
	selectedResult: PropTypes.object,
	setStandardLessons: PropTypes.func,
	setGradesError: PropTypes.func,
	setIsFormInvalid: PropTypes.func,
	resetExecuteResetToggle: PropTypes.func,
	executeReset: PropTypes.bool,
	modalReadonly: PropTypes.bool,
};

export default StandardLessonsForm;
