import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";

import { globalStore } from "../../../../../globalStore";
import { ParametricShape } from "../../../../../Models/ParametricShape";
import {
	addNewParametric,
	addNewParametricFail,
	addNewParametricSuccess,
	apiResponse,
	updateParametric,
	updateParametricFail,
	updateParametricSuccess,
} from "../../../../../Redux/Actions/index";
import { flattenFormData, isFormikFormInvalid } from "../../../../../Utils/Utils";
import Button from "../../../../UI/Button/Button";
import FormColumnField from "../../../../UI/FormColumnField/FormColumnField";
import { Row } from "../../../../UI/Grid/Grid";
import Modal from "../../../../UI/Modal/Modal";

const field_names = {
	PARAMETRIC_DB_TABLE: "parametric_db_table",
	PARAMETRIC_DESCRIPTION: "parametric_description",
};

const initialFormValues = {
	parametric_db_table: "",
	parametric_description: "",
};

const ManageParametricForm = ({
	addNewParametric,
	addNewParametricFail,
	addNewParametricSuccess,
	closeModal,
	isModalOpen,
	parametric,
	resetGridFilters,
	selectedParametricTable,
	setApiResponse,
	updateParametric,
	updateParametricFail,
	updateParametricSuccess,
}) => {
	const [loading, setLoading] = useState(false);

	const getInitialValues = () => {
		if (parametric === null)
			return {
				...initialFormValues,
				parametric_db_table: globalStore.parametricsGreekNameMapping[selectedParametricTable?.value] ?? "",
			};
		return {
			parametric_db_table: globalStore.parametricsGreekNameMapping[selectedParametricTable.value],
			parametric_description: parametric?.description ?? "",
		};
	};

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: getInitialValues(),
		onSubmit: (values) => {
			onFormSubmit(flattenFormData(values));
		},
	});

	const onFormSubmit = (formData) => {
		setLoading(true);
		delete formData[field_names.PARAMETRIC_DB_TABLE];
		const formSubmissionPromise = (() => {
			if (parametric === null) {
				return addNewParametric(formData, selectedParametricTable.value);
			} else {
				return updateParametric(formData, selectedParametricTable.value, parametric.id);
			}
		})();

		formSubmissionPromise
			.then((response) => {
				const apiResponseMsg = {
					error: null,
					info: {
						message: (
							<>
								Το στοιχείο <b>{response.data.description}</b> {parametric === null ? "προστέθηκε " : "ενημερώθηκε"} με επιτυχία.
							</>
						),
						code: response.status,
					},
				};
				setApiResponse(apiResponseMsg);
				globalStore[selectedParametricTable.value].push(response.data);
				if (parametric === null) addNewParametricSuccess(response.data, selectedParametricTable.value);
				else updateParametricSuccess(response.data, selectedParametricTable.value);
			})
			.catch((error) => {
				const apiResponseMsg = {
					error: error,
					info: null,
				};
				setApiResponse(apiResponseMsg);
				if (parametric === null) addNewParametricFail(error);
				else updateParametricFail(error);
			})
			.finally(() => {
				setLoading(false);
				formik.resetForm();
				resetGridFilters();
			});
	};

	const isFormInvalid = () => {
		return isFormikFormInvalid([
			{
				value: formik.values[field_names.PARAMETRIC_DESCRIPTION],
				required: true,
			},
		]);
	};

	const footer = (() => {
		if (parametric === null) {
			return (
				<>
					<Button
						type="button"
						kind="success"
						onClick={formik.handleSubmit}
						disabled={isFormInvalid()}
						text="Αποθήκευση"
					/>
					<Button
						type="button"
						kind="secondary"
						onClick={() => {
							formik.resetForm();
							closeModal();
						}}
						text="Κλείσιμο"
					/>
				</>
			);
		} else {
			return (
				<>
					<Button
						type="button"
						kind="success"
						onClick={formik.handleSubmit}
						disabled={isFormInvalid() || !formik.dirty}
						text="Αποθήκευση"
					/>
					<Button
						type="button"
						kind="primary"
						onClick={formik.resetForm}
						disabled={!formik.dirty}
						text="Επαναφορά"
					/>
					<Button
						type="button"
						kind="secondary"
						onClick={() => {
							formik.resetForm();
							closeModal();
						}}
						text="Κλείσιμο"
					/>
				</>
			);
		}
	})();

	const headerBg = () => {
		if (parametric === null) return "primary";
		else return "info";
	};

	const headerText = () => {
		if (parametric === null) return "Προσθήκη Στοιχείου";
		else return "Επεξεργασία Στοιχείου";
	};

	const headerIcon = () => {
		if (parametric === null) return "fas fa-plus";
		else return "fas fa-pencil-alt";
	};

	return (
		<Modal
			isOpen={isModalOpen}
			header={
				<>
					<em className={`fas ${headerIcon()} float-left fa-1_2x mr-3`} />
					{headerText()}
				</>
			}
			headerBg={headerBg()}
			loading={loading}
			footer={footer}
			onClose={() => closeModal()}
		>
			<form>
				<Row classes={["px-2"]}>
					<FormColumnField
						classes={["px-2", "mb-3"]}
						disabled={true}
						label="Όνομα παραμετρικού πίνακα"
						required
						name={field_names.PARAMETRIC_DB_TABLE}
						value={formik.values[field_names.PARAMETRIC_DB_TABLE]}
					/>
					<FormColumnField
						classes={["px-2", "mb-3"]}
						label="Όνομα νέου στοιχείου"
						required
						name={field_names.PARAMETRIC_DESCRIPTION}
						placeholder="Όνομα νέου στοιχείου"
						value={formik.values[field_names.PARAMETRIC_DESCRIPTION]}
						onChange={formik.handleChange}
					/>
				</Row>
			</form>
		</Modal>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		addNewParametric: (newParametricData, dbTable) => dispatch(addNewParametric(newParametricData, dbTable)),
		addNewParametricFail: (error) => dispatch(addNewParametricFail(error)),
		addNewParametricSuccess: (newParametricData, dbTable) => dispatch(addNewParametricSuccess(newParametricData, dbTable)),
		setApiResponse: (theApiResponse) => dispatch(apiResponse(theApiResponse)),
		updateParametric: (parametricDescription, dbTable, parametricId) => dispatch(updateParametric(parametricDescription, dbTable, parametricId)),
		updateParametricFail: (error) => dispatch(updateParametricFail(error)),
		updateParametricSuccess: (parametricDescription, dbTable) => dispatch(updateParametricSuccess(parametricDescription, dbTable)),
	};
};

ManageParametricForm.propTypes = {
	addNewParametric: PropTypes.func,
	addNewParametricFail: PropTypes.func,
	addNewParametricSuccess: PropTypes.func,
	closeModal: PropTypes.func,
	isModalOpen: PropTypes.bool,
	parametric: PropTypes.exact(ParametricShape),
	resetGridFilters: PropTypes.func,
	selectedParametric: PropTypes.object,
	setApiResponse: PropTypes.func,
	updateParametric: PropTypes.func,
	updateParametricFail: PropTypes.func,
	updateParametricSuccess: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(ManageParametricForm);
