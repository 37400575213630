import React, { Component } from "react";
import { isEmpty } from "lodash";

import Card from "../../../UI/Card/Card";
import CustomChart from "../../../UI/Chart/CustomChart";
import Datatable from "../../../UI/Datatable/Datatable";

const studentPerCountyColumns = [
	{
		name: "Περιοχή",
		selector: (row) => row.county,
		sortable: true,
		wrap: true,
	},
	{
		name: "Αριθμός Μαθητών",
		selector: (row) => row.students,
		format: (row) => row.students.length,
		sortable: true,
	},
	{
		name: "Ποσοστό",
		selector: (row) => Number(row.percentage.replace("%", "")),
		format: (row) => row.percentage,
		sortable: true,
	},
];

class StudentsPerCountyCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterText: "",
			filteredData: [],
		};
		this.toggledClearRows = false;
	}

	setFilter = (filterString) => {
		const filteredItems = this.props.studentCounties.filter((item) => item.county?.toLowerCase().includes(filterString.toLowerCase()));
		this.setState({
			filterText: filterString,
			filteredData: filteredItems,
		});
	};

	render() {
		return (
			<Card
				title="Μαθητές / Περιοχή"
				headerSpannig={[8, 4]}
				extraOnHeader={
					<i
						className="fas fa-map-marked-alt"
						style={{ fontSize: "250%" }}
					></i>
				}
			>
				<div
					style={{ height: "250px", width: "100%" }}
					className="mb-3"
				>
					<CustomChart
						id="studentsPerCountyChart"
						type="pie"
						legend={{
							legend: {
								display: true,
								position: "right",
								labels: {
									fontSize: 10,
									boxWidth: 28,
								},
							},
						}}
						data={{
							labels: this.props.studentCounties.map((stat) => stat.county),
							datasets: [
								{
									data: this.props.studentCounties.map((stat) => stat.students.length),
								},
							],
						}}
					/>
				</div>
				<Datatable
					columns={studentPerCountyColumns}
					defaultSortFieldId={1}
					exportCsv
					exportFilename="Πίνακας Μαθητών ανά Περιοχή Μαθητολογίου Πολύτροπο"
					fixedHeaderScrollHeight="25vh"
					filteredData={this.state.filteredData}
					filterText={this.state.filterText}
					onClear={() => {
						this.setState({ filterText: "", filteredData: [] });
						this.toggledClearRows = !this.toggledClearRows;
					}}
					onFilter={(e) => {
						this.setFilter(e.target.value);
						this.toggledClearRows = !this.toggledClearRows;
					}}
					paginationPerPage={this.props.studentCounties.length}
					paginationRowsPerPageOptions={[]}
					rowData={isEmpty(this.state.filterText) ? this.props.studentCounties : this.state.filteredData}
					toggledClearRows={this.toggledClearRows}
				/>
			</Card>
		);
	}
}

export default StudentsPerCountyCard;
