import { isEmpty, startsWith, uniqBy } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import { SelfTestSelectorShape } from "../../../Models/SelfTestShape";
import { getViewSelfTestPath } from "../../../navigation";
import { allSelfTest } from "../../../Redux/Selectors/selfTestSelectors";
import { allFetchingFinished } from "../../../Redux/Selectors/universalSelectors";
import { STATUSES } from "../../../Utils/ParametricTablesBinds";
import Badge from "../../UI/Badge/Badge";
import Button from "../../UI/Button/Button";
import Card from "../../UI/Card/Card";
import Datatable from "../../UI/Datatable/Datatable";
import AddSelfTestForm from "./Forms/AddSelfTestForm";
import EditSelfTestForm from "./Forms/EditSelfTestForm";
import FilterSelfTestForm from "./Forms/FilterSelfTestForm";

const columns = [
	{
		name: "Α/Α",
		selector: (row) => row.id,
		sortable: true,
		width: "75px",
	},
	{
		name: "Μάθημα",
		selector: (row) => row.chapter.lesson.description,
		sortable: true,
		wrap: true,
	},
	{
		name: "Ενότητα",
		selector: (row) => row.chapter.name,
		sortable: true,
		wrap: true,
	},
	{
		name: "Τάξη",
		selector: (row) => row.class.description,
		sortable: true,
		wrap: true,
	},
	{
		name: "Καθηγητής",
		selector: (row) => row.user.lastname + " " + row.user.firstname,
		sortable: true,
		wrap: true,
	},
	{
		name: "Διάρκεια",
		selector: (row) => row.duration,
		format: (row) => row.duration + "'",
		sortable: true,
		width: "120px",
	},
	{
		name: "Κατάσταση",
		selector: (row) => row.status.description,
		format: (row) => (
			<Badge
				kind={row.status.id === STATUSES.DISABLED ? "danger" : "success"}
				text={row.status.description.substring(0, row.status.description.length - 1)}
			/>
		),
		sortable: true,
		width: "135px",
	},
	{
		name: "Δημ.",
		selector: (row) => row.created_at,
		format: (row) => (row.created_at ? moment(row.created_at).format("ddd, DD/MM/YYYY HH:mm:ss") : ""),
		sortable: true,
		wrap: true,
	},
	{
		name: "Τροπ.",
		selector: (row) => row.updated_at,
		format: (row) => (row.updated_at ? moment(row.updated_at).format("ddd, DD/MM/YYYY HH:mm:ss") : ""),
		sortable: true,
		wrap: true,
	},
];

class SelfTestManagement extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterText: "",
			filteredData: [],
			isAddSelfTestModalOpen: false,
			isDataFiltered: false,
			isEditSelfTestModalOpen: false,
			isFilterSelfTestsModalOpen: false,
			loading: false,
			selectedRow: [],
		};
		this.toggledClearRows = false;
	}

	componentDidMount() {
		document.title = process.env.REACT_APP_NAME + " :: Διαχείριση Τμημάτων";
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.allFetchingFinished && this.props.allFetchingFinished) {
			this.resetAllFilters();
		}
	}

	selectedRow = (data) => {
		this.setState({ selectedRow: data.selectedRows });
	};

	clearSelectedRows = () => {
		this.toggledClearRows = !this.toggledClearRows;
		this.setState({ selectedRow: [] });
	};

	setFilteredData = (filteredData, isDataFiltered) => {
		isEmpty(filteredData) ? this.setState({ filteredData: [], isDataFiltered }) : this.setState({ filteredData, isDataFiltered });
	};

	setFilter = (filterString) => {
		const filteredItems = this.props.allSelfTest.filter((st) => st.chapter.lesson.description.toLowerCase().includes(filterString.toLowerCase()));
		const filteredItems2 = this.props.allSelfTest.filter((st) => st.chapter.name.toLowerCase().includes(filterString.toLowerCase()));
		const filteredItems3 = this.props.allSelfTest.filter((st) => {
			return (
				`${st.user.lastname} ${st.user.firstname}`.toLowerCase().includes(filterString.toLowerCase()) ||
				`${st.user.firstname} ${st.user.lastname}`.toLowerCase().includes(filterString.toLowerCase())
			);
		});
		const filteredItems4 = this.props.allSelfTest.filter((st) => startsWith(st.status.description.toLowerCase(), filterString.toLowerCase()));
		const filteredItems5 = this.props.allSelfTest.filter((st) => st.class.description.toLowerCase().includes(filterString.toLowerCase()));
		this.setState({
			filterText: filterString,
			filteredData: uniqBy(filteredItems.concat(filteredItems2, filteredItems3, filteredItems4, filteredItems5), "id"),
		});
	};

	resetAllFilters = () => {
		this.setState({
			filterText: "",
			filteredData: [],
			isDataFiltered: false,
		});
		this.clearSelectedRows();
	};

	render() {
		const tableData = (() => {
			if (this.state.isDataFiltered) {
				return this.state.filteredData;
			} else {
				return this.props.allSelfTest;
			}
		})();
		return (
			<Card
				title="Προβολή Καταχωρημένων Test Αυτοαξιολόγησης"
				headerSpannig={[8, 4]}
				subTitle={<div className="text-sm">Αριθμός Καταχωρήσεων: {this.props.allSelfTest.length}</div>}
				extraOnHeader={
					<>
						<Button
							oval
							type="button"
							iconClass="fas fa-plus"
							kind="primary"
							onClick={() => this.setState({ isAddSelfTestModalOpen: true })}
							id="btn"
							classes={["m-1"]}
							data-tip="Προσθήκη Test"
						/>
						<Button
							oval
							type="button"
							iconClass="fas fa-pencil-alt"
							kind="info"
							onClick={() => this.setState({ isEditSelfTestModalOpen: true })}
							id="btn"
							classes={["m-1"]}
							disabled={this.state.selectedRow.length !== 1}
							data-tip="Επεξεργασία Test"
						/>
						<Button
							oval
							type="button"
							iconClass="fas fa-eye"
							kind="green"
							onClick={() => this.props.history.push(getViewSelfTestPath(this.state.selectedRow[0].id))}
							id="btn"
							classes={["m-1"]}
							disabled={this.state.selectedRow.length === 0}
							data-tip="Προβολή Test"
						/>
						<Button
							oval
							type="button"
							iconClass="fas fa-filter"
							kind="purple"
							onClick={() => {
								this.setState({ isFilterSelfTestsModalOpen: true });
								this.clearSelectedRows();
							}}
							id="btn"
							classes={["m-1"]}
							disabled={isEmpty(this.props.allSelfTest) || !isEmpty(this.state.filterText)}
							data-place="left"
							data-tip="Φίλτρα Αναζήτησης"
						/>
						<Button
							oval
							type="button"
							iconClass="fas fa-eraser"
							kind="warning"
							onClick={() => this.resetAllFilters()}
							id="btn"
							classes={["m-1", this.state.isDataFiltered ? "d-inline-block" : "d-none"]}
							data-place="left"
							data-tip="Απαλοιφή Φίλτρων"
						/>
					</>
				}
				id="selfTestCard"
			>
				<Datatable
					columns={columns}
					defaultSortFieldId={2}
					filteredData={this.state.filteredData}
					filterText={this.state.filterText}
					onClear={() => {
						this.setState({ filterText: "", filteredData: [] });
						this.clearSelectedRows();
					}}
					onFilter={(e) => {
						this.setFilter(e.target.value);
						this.clearSelectedRows();
					}}
					rowData={isEmpty(this.state.filterText) ? tableData : this.state.filteredData}
					selectable
					selectedRows={this.state.selectedRow}
					setSelectedRow={this.selectedRow}
					toggledClearRows={this.toggledClearRows}
				/>
				<AddSelfTestForm
					closeModal={() => this.setState({ isAddSelfTestModalOpen: false })}
					isModalOpen={this.state.isAddSelfTestModalOpen}
					resetAllFilters={this.resetAllFilters}
				/>
				<FilterSelfTestForm
					closeModal={() => this.setState({ isFilterSelfTestsModalOpen: false })}
					isDataFiltered={this.state.isDataFiltered}
					isModalOpen={this.state.isFilterSelfTestsModalOpen}
					setFilteredData={this.setFilteredData}
				/>
				{!isEmpty(this.state.selectedRow) && (
					<>
						<EditSelfTestForm
							closeModal={() => this.setState({ isEditSelfTestModalOpen: false })}
							currentSelfTest={!isEmpty(this.state.selectedRow) ? this.state.selectedRow[0] : null}
							isModalOpen={this.state.isEditSelfTestModalOpen}
							resetAllFilters={this.resetAllFilters}
						/>
					</>
				)}
				<ReactTooltip effect="solid" />
			</Card>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		allFetchingFinished: allFetchingFinished(state),
		allSelfTest: allSelfTest(state),
	};
};

SelfTestManagement.propTypes = {
	allFetchingFinished: PropTypes.bool,
	allSelfTest: PropTypes.arrayOf(PropTypes.exact(SelfTestSelectorShape)),
};

export default withRouter(connect(mapStateToProps)(SelfTestManagement));
