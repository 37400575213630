import moment from "moment/moment";
import React from "react";
import Logo from "../../assets/images/politropo_logo.png";

import LoginForm from "./Form/LoginForm";

const Login = () => {
	return (
		<div className="wrapper">
			<div
				className="block-center mt-5"
				style={{ maxWidth: "400px" }}
			>
				<div className="card card-flat shadow-sm border p-3">
					<h3 className="text-center">{process.env.REACT_APP_NAME}</h3>
					<div className="card-header text-center">
						<img
							src={Logo}
							alt="AppLogo"
							style={{ width: "250px" }}
						/>
					</div>
					<div className="card-body pb-0">
						<p className="text-center py-2 h4">ΣΥΝΔΕΣΗ ΧΡΗΣΤΗ</p>
						<LoginForm />
					</div>
				</div>
				<div className="p-3 text-center">
					<span className="mr-1">
						<i className="far fa-copyright"></i>
					</span>
					<span>{moment().format("YYYY")}</span>
					<span className="mx-2">-</span>
					<span>{process.env.REACT_APP_ORGANIZATION_NAME}</span>
					<br />
					<span>
						Developed by{" "}
						<a
							href={process.env.REACT_APP_AUTHOR_WEBSITE}
							target="_blank"
							rel="noreferrer"
						>
							{process.env.REACT_APP_AUTHOR_NAME}
						</a>
					</span>
					<br />
					<small>App version: {process.env.REACT_APP_VERSION}</small>
				</div>
			</div>
		</div>
	);
};

export default Login;
