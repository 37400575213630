import {
	fetchAllDepartments,
	fetchAllGrades,
	fetchAllPanhellenicExaminationsResults,
	fetchAllScientificFieldLessons,
	fetchAllSelfTestAnswers,
	fetchAllSelfTestChapters,
	fetchAllSelfTests,
	fetchAllShortcuts,
	fetchAllStudents,
	fetchAllUsers,
	fetchCurrentStudentSelfTestAnswers,
	fetchCurrentStudentSelfTests,
	fetchCurrentStudentUserDepartments,
	fetchCurrentStudentUserGrades,
	fetchCurrentTeacherDepartments,
	fetchCurrentTeacherGrades,
	fetchCurrentTeacherStudents,
	fetchParametrics,
	fetchStudentByUserId,
	fetchTeacherUser,
} from "../../Redux/Actions/index";
import { getAuthData } from "../../Utils/AuthUtils";
import { permissions } from "../../Utils/ParametricTablesBinds";
import { FETCH_UNIVERSE_FINISHED, FETCH_UNIVERSE_START } from "./actionTypes";

const fetchUniverseStart = () => {
	return {
		type: FETCH_UNIVERSE_START,
	};
};

const fetchUniverseFinished = () => {
	return {
		type: FETCH_UNIVERSE_FINISHED,
	};
};

const fetchUniverseForAdministrator = () => {
	return (dispatch) => {
		dispatch(fetchAllUsers());
		dispatch(fetchAllShortcuts());
		dispatch(fetchAllDepartments());
		dispatch(fetchAllStudents());
		dispatch(fetchAllGrades());
		dispatch(fetchAllScientificFieldLessons());
		dispatch(fetchAllPanhellenicExaminationsResults());
		dispatch(fetchAllSelfTestChapters());
		dispatch(fetchAllSelfTests());
		dispatch(fetchAllSelfTestAnswers());
	};
};

const fetchUniverseForTeacher = () => {
	return (dispatch) => {
		dispatch(fetchTeacherUser(Number(getAuthData().user_id)));
		dispatch(fetchCurrentTeacherDepartments());
		dispatch(fetchCurrentTeacherStudents());
		dispatch(fetchCurrentTeacherGrades());
	};
};

const fetchUniverseForStudent = () => {
	return (dispatch) => {
		dispatch(fetchAllUsers());
		dispatch(fetchStudentByUserId(Number(getAuthData().user_id)));
		dispatch(fetchCurrentStudentUserGrades());
		dispatch(fetchCurrentStudentUserDepartments());
		dispatch(fetchAllSelfTestChapters());
		dispatch(fetchCurrentStudentSelfTests());
		dispatch(fetchCurrentStudentSelfTestAnswers());
	};
};

export const fetchUniverseCheckingPermissions = () => {
	if (Number(getAuthData().permission) === permissions.ADMIN) {
		return (dispatch) => {
			dispatch(fetchUniverseStart());
			dispatch(fetchParametrics());
			dispatch(fetchUniverseForAdministrator());
			setTimeout(() => {
				dispatch(fetchUniverseFinished());
			}, 500);
		};
	} else if (Number(getAuthData().permission) === permissions.TEACHER) {
		return (dispatch) => {
			dispatch(fetchUniverseStart());
			dispatch(fetchParametrics());
			dispatch(fetchUniverseForTeacher());
			setTimeout(() => {
				dispatch(fetchUniverseFinished());
			}, 500);
		};
	} else if (Number(getAuthData().permission) === permissions.STUDENT) {
		return (dispatch) => {
			dispatch(fetchUniverseStart());
			dispatch(fetchParametrics());
			dispatch(fetchUniverseForStudent());
			setTimeout(() => {
				dispatch(fetchUniverseFinished());
			}, 500);
		};
	}
};
