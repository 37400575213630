import { has, isEmpty, isEqual } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import { globalStore } from "../../../../globalStore";
import { DepartmentShape } from "../../../../Models/DepartmentShape";
import { GradeShape } from "../../../../Models/GradeShape";
import { StudentFromSelectorShape } from "../../../../Models/StudentShape";
import { apiResponse, updateGrade, updateGradeFail, updateGradeSuccess } from "../../../../Redux/Actions/index";
import { allDepartments } from "../../../../Redux/Selectors/departmentsSelectors";
import { allStudents } from "../../../../Redux/Selectors/studentsSelectors";
import { getAuthData } from "../../../../Utils/AuthUtils";
import { DATE_DATA_TYPES, getPropsToStateData, prepareDataBeforeSend } from "../../../../Utils/ComponentsUtils";
import { getParametricAsOptions } from "../../../../Utils/GuiUtils";
import { nullifyEmptyStrings } from "../../../../Utils/NullConversionUtils";
import { absenceKindIds, PARAMETRIC_TABLES } from "../../../../Utils/ParametricTablesBinds";
import { clone, isFormInvalid } from "../../../../Utils/Utils";
import BaseInput from "../../../UI/BaseInput/BaseInput";
import Button from "../../../UI/Button/Button";
import { Col, Row } from "../../../UI/Grid/Grid";
import { MODAL_SIZE } from "../../../UI/Modal/constants/ModalSize";
import Modal from "../../../UI/Modal/Modal";

class EditGradeForm extends Component {
	loggedUserId = Number(getAuthData().user_id);

	constructor(props) {
		super(props);
		this.state = {
			editGradeForm: {
				student_id: {
					label: "Μαθητής/τρια",
					name: "student_id",
					options: this.getStudentDropdown(),
					required: true,
					placeholder: "Μαθητής/τρια",
					value: null,
					disabled: true,
					colSpan: 4,
					extraClasses: 6,
				},
				class: {
					label: "Τάξη",
					name: "class",
					placeholder: "Τάξη",
					options: getParametricAsOptions(PARAMETRIC_TABLES.CLASSES),
					required: true,
					disabled: true,
					value: null,
					colSpan: 3,
					extraClasses: 6,
				},
				department: {
					label: "Τμήμα",
					name: "department",
					placeholder: "Τμήμα",
					options: this.getDepartmentDropdown(),
					required: true,
					disabled: true,
					value: null,
					colSpan: 2,
					extraClasses: 6,
				},
				lesson_id: {
					label: "Μάθημα",
					name: "lesson_id",
					placeholder: "Μάθημα",
					options: this.getLessonsDropdown(),
					required: true,
					disabled: true,
					value: null,
					colSpan: 3,
					extraClasses: 6,
				},
				absence: {
					label: "Παρουσία",
					name: "absence",
					placeholder: "Παρουσία",
					options: getParametricAsOptions(PARAMETRIC_TABLES.ABSENCE_KIND),
					required: true,
					searchable: false,
					value: null,
					colSpan: 3,
					extraClasses: 6,
				},
				exam_kind: {
					label: "Είδος Εξέτασης",
					name: "exam_kind",
					placeholder: "Είδος Εξέτασης",
					options: getParametricAsOptions(PARAMETRIC_TABLES.EXAM_KIND),
					required: true,
					searchable: false,
					value: null,
					colSpan: 3,
					extraClasses: 6,
				},
				grade: {
					label: "Βαθμός (%)",
					name: "grade",
					required: true,
					value: "",
					inputType: "number",
					isNumber: true,
					colSpan: 3,
					extraClasses: 6,
				},
				exam_date: {
					label: "Ημ. Εξέτασης",
					name: "exam_date",
					placeholder: "Ημ. Εξέτασης",
					datepicker: true,
					colSpan: 3,
					selected: new Date(),
					required: true,
					extraClasses: 6,
				},
				comments: {
					label: "Σχόλια",
					inputType: null,
					name: "comments",
					placeholder: "Σχόλια",
					colSpan: 12,
					maxLength: 300,
					value: "",
					extraClasses: 12,
				},
			},
			loading: false,
		};
	}

	componentDidMount() {
		this.setState({ editGradeForm: this.getInitData() });
	}

	componentDidUpdate(prevProps, prevState) {
		if (!isEqual(this.props.grade, prevProps.grade) && this.props.grade !== null) {
			this.setState({ editGradeForm: this.getInitData() });
		}
	}

	getInitData = () => {
		const initData = getPropsToStateData(this.state.editGradeForm, this.props.grade);
		if (initData.absence.value.value === absenceKindIds.NOT_PRESENT) {
			initData.grade.value = "";
			initData.grade.required = false;
			initData.grade.disabled = true;
		} else {
			initData.grade.disabled = false;
		}
		initData.class.value = { value: this.props.grade.student.department.class.id, label: this.props.grade.student.department.class.description };
		initData.department.value = { value: this.props.grade.student.department.id, label: this.props.grade.student.department.name };
		return initData;
	};

	getStudentDropdown = () => {
		const loggedUserStudents = this.props.allStudents.map((student) => {
			return {
				label: student.lastname + " " + student.firstname,
				value: student.id,
			};
		});
		return loggedUserStudents;
	};

	getDepartmentDropdown = () => {
		const updatedDepartments = clone(this.props.allDepartments);
		return updatedDepartments.map((department) => {
			return {
				value: department.id,
				label: department.name,
			};
		});
	};

	getLessonsDropdown = () => {
		return globalStore.lessons.map((lesson) => {
			return {
				value: lesson.id,
				label: lesson.description,
			};
		});
	};

	onChangeHandler = (selectedOption, inputId) => {
		const updatedForm = clone(this.state.editGradeForm);
		const updatedFormElement = clone(updatedForm[inputId]);
		const updatedFormDateKeys = Object.values(updatedForm)
			.filter((item) => item.datepicker)
			.map((item) => item.name);
		updatedFormDateKeys.forEach((element) => {
			updatedForm[element].selected = new Date(updatedForm[element].selected);
		});

		if (has(updatedFormElement, "options")) {
			updatedFormElement.value = selectedOption;
		} else {
			if (updatedFormElement.isNumber)
				updatedFormElement.value = isEmpty(selectedOption.target.value) ? selectedOption.target.value : Number(selectedOption.target.value);
			else if (updatedFormElement.datepicker) updatedFormElement.selected = selectedOption;
			else updatedFormElement.value = selectedOption.target.value;
		}

		if (inputId === "student_id") {
			const department = this.getDepartmentOfStudent(selectedOption.value);
			const the_class = this.getClassOfStudent(selectedOption.value);
			updatedForm.department.value = { value: department.id, label: department.name };
			updatedForm.class.value = { value: the_class.id, label: the_class.description };
		}

		if (inputId === "absence") {
			if (Number(selectedOption.value) === absenceKindIds.NOT_PRESENT) {
				updatedForm.grade.value = "";
				updatedForm.grade.disabled = true;
				updatedForm.grade.required = false;
			} else {
				updatedForm.grade.disabled = false;
				updatedForm.grade.required = true;
			}
		}

		updatedForm[inputId] = updatedFormElement;
		this.setState({ editGradeForm: updatedForm });
	};

	getDepartmentOfStudent = (studentId) => {
		const student = this.props.allStudents.find((student) => student.id === studentId);
		if (student) {
			return student.department;
		}
		return "";
	};

	getClassOfStudent = (studentId) => {
		const student = this.props.allStudents.find((student) => student.id === studentId);
		if (student) {
			return student.department.class;
		}
		return "";
	};

	isDirty = () => {
		const updatedState = this.state.editGradeForm;
		const updatedProp = getPropsToStateData(this.state.editGradeForm, this.props.grade);
		updatedProp.class.value = { value: this.props.grade.student.department.class.id, label: this.props.grade.student.department.class.description };
		updatedProp.department.value = { value: this.props.grade.student.department.id, label: this.props.grade.student.department.name };
		return !isEqual(updatedState, updatedProp);
	};

	onReset = () => {
		this.setState({ editGradeForm: this.getInitData() });
	};

	onFormSubmit = () => {
		this.setState({ loading: true });
		const dataToSend = nullifyEmptyStrings(prepareDataBeforeSend(this.state.editGradeForm, DATE_DATA_TYPES.DATE));
		delete dataToSend.department;
		delete dataToSend.class;
		this.props
			.updateGrade(dataToSend, this.props.grade.id)
			.then((response) => {
				const studentFullname = (() => {
					const student = this.props.allStudents.filter((student) => student.id === response.data.student_id)[0];
					return student.lastname + " " + student.firstname;
				})();
				const apiResponseMsg = {
					error: null,
					info: {
						message: (
							<>
								Η βαθμολογία του μαθητή <b>{studentFullname}</b> στις <b>{moment(response.data.exam_date).format("DD/MM/YYYY")}</b> ενημερώθηκε με
								επιτυχία.
							</>
						),
					},
				};
				this.props.setApiResponse(apiResponseMsg);
				this.props.updateGradeSuccess(response.data);
				this.props.setFilteredData(null, false);
			})
			.catch((error) => {
				const apiResponseMsg = {
					error: error,
					info: null,
				};
				this.props.setApiResponse(apiResponseMsg);
				this.props.updateGradeFail(error);
			})
			.finally(() => {
				this.setState({ loading: false });
				this.emptyAllFormFields();
				this.props.resetGradeFiltersAndClearSelection();
				this.props.closeModal();
			});
	};

	emptyAllFormFields = () => {
		const updatedForm = clone(this.state.editGradeForm);
		for (let key in updatedForm) {
			const updatedFormElement = clone(updatedForm[key]);
			if (has(updatedFormElement, "options")) {
				updatedFormElement.value = null;
			} else if (!updatedFormElement.datepicker) {
				updatedFormElement.value = "";
			}

			if (updatedFormElement.name === "exam_date") updatedFormElement.selected = new Date();

			updatedForm[key] = updatedFormElement;
		}
		this.setState({ editGradeForm: updatedForm });
	};

	onCloseModal = () => {
		this.onReset();
		this.props.closeModal();
	};

	render() {
		const formElementsArray = [];
		for (let key in this.state.editGradeForm) {
			formElementsArray.push({
				id: key,
				config: this.state.editGradeForm[key],
			});
		}
		return (
			<Modal
				isOpen={this.props.isModalOpen}
				header={
					<>
						<em className="fas fa-pencil-alt float-left fa-1_2x mr-3" />
						Επεξεργασία Βαθμολογίας
					</>
				}
				size={MODAL_SIZE.lg}
				headerBg="info"
				loading={this.state.loading}
				footer={
					<>
						<>
							<Button
								type="button"
								kind="success"
								onClick={this.onFormSubmit}
								disabled={isFormInvalid(this.state.editGradeForm) || !this.isDirty()}
								text="Αποθήκευση"
							/>
							<Button
								type="button"
								kind="primary"
								onClick={this.onReset}
								disabled={!this.isDirty()}
								text="Επαναφορά"
							/>
							<Button
								type="button"
								kind="secondary"
								text="Κλείσιμο"
								onClick={this.onCloseModal}
							/>
						</>
					</>
				}
				onClose={() => this.onCloseModal()}
			>
				<form ref={this.formRef}>
					<Row classes={["px-2"]}>
						{formElementsArray.map((element, idx) => {
							return (
								<Col
									xl={element.config.colSpan}
									lg={element.config.colSpan}
									md={element.config.extraClasses}
									sm={element.config.extraClasses}
									classes={["mb-4", "px-2"]}
									key={idx}
								>
									<label>
										{element.config.label} {element.config.required ? <span className="reqField">(*)</span> : null}
									</label>
									{element.config.datepicker ? (
										<BaseInput
											key={element.config.name + element.config.id}
											name={element.config.name}
											onChange={(selectedOption) => {
												this.onChangeHandler(selectedOption, element.id);
											}}
											datepicker={element.config.datepicker}
											selected={element.config.selected}
											required={element.config.required}
										/>
									) : (
										<BaseInput
											key={element.config.name + element.config.id}
											maxLength={element.config.maxLength}
											name={element.config.name}
											placeholder={element.config.placeholder}
											disabled={element.config.disabled}
											type={element.config.inputType}
											value={element.config.value}
											options={element.config.options}
											searchable={element.config.searchable}
											onChange={(selectedOption) => {
												this.onChangeHandler(selectedOption, element.id);
											}}
											required={element.config.required}
										/>
									)}
								</Col>
							);
						})}
					</Row>
				</form>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		allDepartments: allDepartments(state),
		allStudents: allStudents(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setApiResponse: (theApiResponse) => dispatch(apiResponse(theApiResponse)),
		updateGrade: (gradeData, grade_id) => dispatch(updateGrade(gradeData, grade_id)),
		updateGradeFail: (error) => dispatch(updateGradeFail(error)),
		updateGradeSuccess: (gradeData) => dispatch(updateGradeSuccess(gradeData)),
	};
};

EditGradeForm.propTypes = {
	allDepartments: PropTypes.arrayOf(PropTypes.exact(DepartmentShape)),
	allStudents: PropTypes.arrayOf(PropTypes.exact(StudentFromSelectorShape)),
	closeModal: PropTypes.func,
	grade: PropTypes.exact(GradeShape),
	isModalOpen: PropTypes.bool,
	resetGradeFiltersAndClearSelection: PropTypes.func,
	setApiResponse: PropTypes.func,
	setFilteredData: PropTypes.func,
	updateGrade: PropTypes.func,
	updateGradeFail: PropTypes.func,
	updateGradeSuccess: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditGradeForm);
