import { isEmpty, isEqual } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { SelfTestSelectorShape } from "../../../Models/SelfTestShape";
import { allSelfTest } from "../../../Redux/Selectors/selfTestSelectors";
import { allFetchingFinished } from "../../../Redux/Selectors/universalSelectors";
import { renderWait } from "../../../Utils/GuiUtils";
import { STATUSES } from "../../../Utils/ParametricTablesBinds";
import { dataURLtoFile, roundNumber } from "../../../Utils/Utils";
import Badge from "../../UI/Badge/Badge";
import Card from "../../UI/Card/Card";
import { Col, Row } from "../../UI/Grid/Grid";
import PdfViewer from "../../UI/PdfViewer/PdfViewer";

class SelfTestView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentSelfTest: null,
		};
	}

	componentDidMount() {
		document.title = process.env.REACT_APP_NAME + " :: Προβολή Test Αυτοαξιολόγησης";
		if (this.props.allFetchingFinished) {
			this.setState({
				currentSelfTest: this.props.allSelfTest.find((st) => st.id === Number(this.props.match.params.self_test_id)),
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!isEqual(this.props.allSelfTest, prevProps.allSelfTest) && !isEmpty(this.props.allSelfTest)) {
			this.setState({
				currentSelfTest: this.props.allSelfTest.find((st) => st.id === Number(this.props.match.params.self_test_id)),
			});
		}
	}

	render() {
		if (this.props.allFetchingFinished && this.state.currentSelfTest !== null) {
			return this.renderSuccess();
		} else {
			return renderWait("Φόρτωση Test Αυτοαξιολόγησης");
		}
	}

	renderSuccess() {
		return (
			<>
				<Row>
					<Col
						xl={6}
						lg={6}
					>
						<Card
							title="Πληροφορίες Test"
							id="selfTestInfoCard"
							headerSpannig={[8, 4]}
							extraOnHeader={<i className="fas fa-list-ol fa-2x"></i>}
						>
							<div className="table-responsive">
								<table className="table">
									<tbody>
										<tr>
											<td style={{ borderTop: 0 }}>
												<b>Καθηγητής</b>
											</td>
											<td style={{ borderTop: 0 }}>{this.state.currentSelfTest.user.lastname + " " + this.state.currentSelfTest.user.firstname}</td>
										</tr>
										<tr>
											<td>
												<b>Τάξη</b>
											</td>
											<td>{this.state.currentSelfTest.class.description}</td>
										</tr>
										<tr>
											<td>
												<b>Ενότητα</b>
											</td>
											<td>{this.state.currentSelfTest.chapter.name}</td>
										</tr>
										<tr>
											<td>
												<b>Μάθημα</b>
											</td>
											<td>{this.state.currentSelfTest.chapter.lesson.description}</td>
										</tr>
										<tr>
											<td>
												<b>Κατάσταση</b>
											</td>
											<td>
												{
													<Badge
														kind={this.state.currentSelfTest.status.id === STATUSES.DISABLED ? "danger" : "success"}
														text={this.state.currentSelfTest.status.description.substring(
															0,
															this.state.currentSelfTest.status.description.length - 1,
														)}
													/>
												}
											</td>
										</tr>
										<tr>
											<td>
												<b>Όνομα Αρχείου Test</b>
											</td>
											<td>{this.state.currentSelfTest.self_test_filename}</td>
										</tr>
										<tr>
											<td>
												<b>Μέγεθος Αρχείου Test</b>
											</td>
											<td>{roundNumber(dataURLtoFile(this.state.currentSelfTest.self_test_file).size / 1000000, 2) + " Mb"}</td>
										</tr>
										<tr>
											<td>
												<b>Διάρκεια Test</b>
											</td>
											<td>{this.state.currentSelfTest.duration + " λεπτά"}</td>
										</tr>
										<tr>
											<td>
												<b>Καταχωρήθηκε</b>
											</td>
											<td>{moment(this.state.currentSelfTest.created_at).format("ddd, DD/MM/YYYY HH:mm:ss")}</td>
										</tr>
										<tr>
											<td>
												<b>Τελευταία Τροποποίηση</b>
											</td>
											<td>
												{this.state.currentSelfTest.updated_at !== null
													? moment(this.state.currentSelfTest.updated_at).format("ddd, DD/MM/YYYY HH:mm:ss")
													: "Ποτέ"}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</Card>
					</Col>
					<Col
						xl={6}
						lg={6}
					>
						<Card
							title="Σωστές Απαντήσεις"
							id="correctAnswersCard"
							headerSpannig={[8, 4]}
							extraOnHeader={<i className="fas fa-tasks fa-2x"></i>}
						>
							<div className="table-responsive">
								<table className="table">
									<tbody>
										{this.state.currentSelfTest.correct_answers.map((answer, idx) => {
											return (
												<tr key={"tr" + idx}>
													<td style={idx === 0 ? { width: "200px", borderTop: 0 } : { width: "200px" }}>
														<b>Ερώτηση {idx + 1}.</b>
													</td>
													<td style={idx === 0 ? { borderTop: 0 } : {}}>{answer}</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col classes={["unselectable"]}>
						<Accordion
							allowMultipleExpanded={true}
							allowZeroExpanded={true}
						>
							<AccordionItem key={"acc1"}>
								<AccordionItemHeading>
									<AccordionItemButton>
										<span className="h4">Προβολή Αρχείου Test</span>
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel>
									<PdfViewer
										file={this.state.currentSelfTest.self_test_file}
										filename={this.state.currentSelfTest.self_test_filename}
									/>
								</AccordionItemPanel>
							</AccordionItem>
							{this.state.currentSelfTest.self_test_answers_file !== null && (
								<AccordionItem key={"acc2"}>
									<AccordionItemHeading>
										<AccordionItemButton>
											<span className="h4">Προβολή Αρχείου Απαντήσεων Test</span>
										</AccordionItemButton>
									</AccordionItemHeading>
									<AccordionItemPanel>
										<PdfViewer
											file={this.state.currentSelfTest.self_test_answers_file}
											filename={this.state.currentSelfTest.self_test_answers_filename}
										/>
									</AccordionItemPanel>
								</AccordionItem>
							)}
						</Accordion>
					</Col>
				</Row>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		allSelfTest: allSelfTest(state),
		allFetchingFinished: allFetchingFinished(state),
	};
};

SelfTestView.propTypes = {
	allSelfTest: PropTypes.arrayOf(PropTypes.exact(SelfTestSelectorShape)),
	allFetchingFinished: PropTypes.bool,
};

export default withRouter(connect(mapStateToProps)(SelfTestView));
