import el from "date-fns/locale/el";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
registerLocale("el", el);

const BaseInput = React.memo((props) => {
	if (props.options != null) {
		return createSelect(props);
	} else if (props.datepicker) {
		return createDatePicker(props);
	} else if (props.type === "file") {
		return createFileText(props);
	} else if (props.type != null) {
		return createInput(props);
	} else {
		return createTextArea(props);
	}
});

const createDatePicker = (props) => {
	return (
		<DatePicker
			todayButton="Σήμερα"
			className={`form-control ${!isEmpty(props.classes) ? props.classes.join(" ") : ""}`}
			peekNextMonth
			showMonthDropdown
			showYearDropdown
			dropdownMode="select"
			dateFormat="dd/MM/yyyy"
			locale="el"
			{...props}
		/>
	);
};

const createInput = (props) => {
	if (props.type === "checkbox") {
		const { label, ...rest } = props;
		return (
			<div className={`checkbox c-checkbox unselectable ${!isEmpty(props.classes) ? props.classes.join(" ") : ""}`}>
				<label
					className="mb-0"
					style={{ fontWeight: "400" }}
				>
					<input {...rest} />
					<span className="fa fa-check"></span> {label}
				</label>
			</div>
		);
	} else if (props.type === "radio") {
		const { label, ...rest } = props;
		return (
			<div className={`c-radio unselectable ${!isEmpty(props.classes) ? props.classes.join(" ") : ""}`}>
				<label
					className="mb-0"
					style={{ fontWeight: "400" }}
				>
					<input {...rest} />
					<span className="fa fa-check"></span> {label}
				</label>
			</div>
		);
	}
	const { classes, ...rest } = props;
	return (
		<input
			className={`form-control ${!isEmpty(classes) ? classes.join(" ") : ""}`}
			{...standardProps}
			{...rest}
		/>
	);
};

const createTextArea = (props) => {
	return (
		<textarea
			className="form-control"
			{...standardProps}
			{...props}
		/>
	);
};

const createFileText = (props) => {
	return (
		<input
			className="d-block"
			type="file"
			title=""
			{...standardProps}
			{...props}
		/>
	);
};

const createSelect = (props) => {
	const { options, disabled, searchable, clearable, classes, menuPlacement, select_multiple, ...rest } = props;
	return (
		<Select
			className={!isEmpty(classes) ? classes.join(" ") : ""}
			classNamePrefix="inner_select"
			options={options}
			isDisabled={disabled}
			isSearchable={searchable}
			isClearable={clearable}
			isMulti={select_multiple}
			closeMenuOnSelect={!select_multiple}
			menuPlacement={menuPlacement ?? "bottom"}
			{...rest}
		/>
	);
};

const standardProps = {
	autoComplete: "off",
};

BaseInput.defaultProps = {
	type: "text",
};

BaseInput.propTypes = {
	classes: PropTypes.array,
	clearable: PropTypes.bool,
	disabled: PropTypes.bool,
	maxLength: PropTypes.number,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	options: PropTypes.array,
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	searchable: PropTypes.bool,
	select_multiple: PropTypes.bool,
	selected: PropTypes.instanceOf(Date),
	small: PropTypes.bool,
	type: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.array]),
};

export default BaseInput;
