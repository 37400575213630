import { deleteResource, getResource, postResource } from "../../BackendAPI/AbstractCRUDActions";
import { ActionKinds } from "../../BackendAPI/ActionKinds";
import { getScientificFieldToLessonsUrl } from "../../BackendAPI/BackendAPI";
import {
	ADD_NEW_SCIENTIFIC_FIELD_LESSONS_FAIL,
	ADD_NEW_SCIENTIFIC_FIELD_LESSONS_START,
	ADD_NEW_SCIENTIFIC_FIELD_LESSONS_SUCCESS,
	DELETE_SCIENTIFIC_FIELD_LESSONS_FAIL,
	DELETE_SCIENTIFIC_FIELD_LESSONS_START,
	DELETE_SCIENTIFIC_FIELD_LESSONS_SUCCESS,
	FETCH_ALL_SCIENTIFIC_FIELD_LESSONS_FAIL,
	FETCH_ALL_SCIENTIFIC_FIELD_LESSONS_START,
	FETCH_ALL_SCIENTIFIC_FIELD_LESSONS_SUCCESS,
} from "./actionTypes";

export const fetchAllScientificFieldLessonsStart = () => {
	return {
		type: FETCH_ALL_SCIENTIFIC_FIELD_LESSONS_START,
	};
};

export const fetchAllScientificFieldLessonsSuccess = (scientificFieldLessonsData) => {
	return {
		type: FETCH_ALL_SCIENTIFIC_FIELD_LESSONS_SUCCESS,
		payload: scientificFieldLessonsData,
	};
};

export const fetchAllScientificFieldLessonsFail = (error) => {
	return {
		type: FETCH_ALL_SCIENTIFIC_FIELD_LESSONS_FAIL,
		payload: error,
	};
};

export const fetchAllScientificFieldLessons = () => {
	return (dispatch) => {
		dispatch(fetchAllScientificFieldLessonsStart());
		const url = getScientificFieldToLessonsUrl(ActionKinds.GET);
		getResource(url)
			.then((response) => {
				dispatch(fetchAllScientificFieldLessonsSuccess(response.data));
			})
			.catch((error) => {
				dispatch(fetchAllScientificFieldLessonsFail(error));
			});
	};
};

export const addNewScientificFieldLessonsStart = () => {
	return {
		type: ADD_NEW_SCIENTIFIC_FIELD_LESSONS_START,
	};
};
export const addNewScientificFieldLessonsSuccess = (newScientificFieldData) => {
	return {
		type: ADD_NEW_SCIENTIFIC_FIELD_LESSONS_SUCCESS,
		payload: newScientificFieldData,
	};
};

export const addNewScientificFieldLessonsFail = (error) => {
	return {
		type: ADD_NEW_SCIENTIFIC_FIELD_LESSONS_FAIL,
		payload: error,
	};
};

export const addNewScientificFieldLessons = (newScientificFieldData) => {
	return (dispatch) => {
		dispatch(addNewScientificFieldLessonsStart());
		const url = getScientificFieldToLessonsUrl(ActionKinds.POST);
		return postResource(url, newScientificFieldData);
	};
};

export const deleteScientificFieldLessonsStart = () => {
	return {
		type: DELETE_SCIENTIFIC_FIELD_LESSONS_START,
	};
};

export const deleteScientificFieldLessonsSuccess = (scientificFieldLessonsData) => {
	return {
		type: DELETE_SCIENTIFIC_FIELD_LESSONS_SUCCESS,
		payload: scientificFieldLessonsData,
	};
};

export const deleteScientificFieldLessonsFail = (error) => {
	return {
		type: DELETE_SCIENTIFIC_FIELD_LESSONS_FAIL,
		payload: error,
	};
};

export const deleteScientificFieldLessons = (scientific_field_id) => {
	return (dispatch) => {
		dispatch(deleteScientificFieldLessonsStart());
		const url = getScientificFieldToLessonsUrl(ActionKinds.DELETE, scientific_field_id);
		return deleteResource(url);
	};
};
