import { isEmpty, uniqBy } from "lodash";
import moment from "moment";
import "moment/locale/el";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";

import { ShortcutShape } from "../../../../Models/ShortcutShape";
import { apiResponse, deleteShortcut, deleteShortcutFail, deleteShortcutSuccess } from "../../../../Redux/Actions/index";
import { allShortcuts } from "../../../../Redux/Selectors/shortcutsSelectors";
import { allFetchingFinished } from "../../../../Redux/Selectors/universalSelectors";
import { CUSTOM_ERRORS } from "../../../../Utils/CustomErrors";
import Button from "../../../UI/Button/Button";
import Card from "../../../UI/Card/Card";
import Datatable from "../../../UI/Datatable/Datatable";
import Modal from "../../../UI/Modal/Modal";
import ManageShortcutForm from "./Forms/ManageShortcutForm";

const columns = [
	{
		name: "Α/Α",
		selector: (row) => row.id,
		sortable: true,
		width: "75px",
	},
	{
		name: "Όνομα Συντόμευσης",
		selector: (row) => row.name,
		sortable: true,
	},
	{
		name: "Μαθήματα",
		selector: (row) => row.lessons,
		format: (row) => {
			let str = [];
			for (let i in row.lessons) {
				str.push(
					<span
						className="tag my-1"
						key={i}
					>
						{row.lessons[i].description}
					</span>,
				);
			}
			return <>{str}</>;
		},
		sortable: true,
		grow: 2,
		wrap: true,
	},
	{
		name: "Δημιουργήθηκε",
		selector: (row) => row.created_at,
		format: (row) => (row.created_at ? moment(row.created_at).format("ddd, DD/MM/YYYY HH:mm:ss") : ""),
		sortable: true,
		wrap: true,
	},
	{
		name: "Τροποποιήθηκε",
		selector: (row) => row.updated_at,
		format: (row) => (row.updated_at ? moment(row.updated_at).format("ddd, DD/MM/YYYY HH:mm:ss") : ""),
		sortable: true,
		wrap: true,
	},
];

class ShortcutsManagement extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterText: "",
			filteredData: [],
			isDeleteShortcutModalOpen: false,
			isModalOpen: false,
			loading: false,
			selectedRow: [],
		};
		this.toggledClearRows = false;
	}

	componentDidMount() {
		document.title = process.env.REACT_APP_NAME + " :: Διαχείριση Συντομεύσεων";
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.allFetchingFinished && this.props.allFetchingFinished) {
			this.resetAllFilters();
		}
	}

	selectedRow = (data) => {
		this.setState({ selectedRow: data.selectedRows });
	};

	clearSelectedRows = () => {
		this.toggledClearRows = !this.toggledClearRows;
		this.setState({ selectedRow: [] });
	};

	resetAllFilters = () => {
		this.setState({
			filterText: "",
			filteredData: [],
		});
		this.clearSelectedRows();
	};

	setFilter = (filterString) => {
		const filteredItems = this.props.allShortcuts.filter((shortcut) => shortcut.name.toLowerCase().includes(filterString.toLowerCase()));
		this.setState({
			filterText: filterString,
			filteredData: uniqBy(filteredItems, "name"),
		});
	};

	onDeleteHandler = () => {
		this.setState({ loading: true });
		const shortcut_id = !isEmpty(this.state.selectedRow) ? this.state.selectedRow[0].id : null;
		if (shortcut_id === null) {
			CUSTOM_ERRORS.throwError("ShortcutsManagement/ShortcutsManagement.js | line 123", CUSTOM_ERRORS.UNEXPECTED_IF_STATEMENT, shortcut_id);
			return;
		}

		this.props
			.deleteShortcut(!isEmpty(this.state.selectedRow) ? this.state.selectedRow[0].id : null)
			.then((response) => {
				this.setState({ loading: false });
				this.props.setApiResponse({
					error: null,
					info: {
						message: (
							<>
								Η συντόμευση <b>{this.state.selectedRow[0].name}</b> διαγράφηκε με επιτυχία.
							</>
						),
						code: response.status,
					},
				});
				this.props.deleteShortcutSuccess({ id: shortcut_id });
			})
			.catch((error) => {
				this.setState({ loading: false });
				this.props.setApiResponse({
					error: error,
					info: null,
				});
				this.props.deleteShortcutFail(error);
			})
			.finally(() => {
				this.setState({ isDeleteShortcutModalOpen: false });
				this.resetAllFilters();
			});
	};

	render() {
		return (
			<>
				<Card
					title="Προβολή Καταχωρημένων Συντομεύσεων"
					headerSpannig={[8, 4]}
					subTitle={<div className="text-sm">Αριθμός Καταχωρήσεων: {this.props.allShortcuts.length}</div>}
					extraOnHeader={
						<>
							<Button
								oval
								type="button"
								iconClass="fas fa-plus"
								kind="primary"
								onClick={() => this.setState({ isModalOpen: true })}
								id="btn"
								classes={["m-1"]}
								disabled={!isEmpty(this.state.selectedRow)}
								data-tip="Προσθήκη Συντόμευσης"
							/>
							<Button
								oval
								type="button"
								iconClass="fas fa-pencil-alt"
								kind="info"
								onClick={() => this.setState({ isModalOpen: true })}
								id="btn"
								classes={["m-1"]}
								disabled={this.state.selectedRow.length === 0}
								data-tip="Επεξεργασία Συντόμευσης"
							/>
							<Button
								oval
								type="button"
								iconClass="fas fa-trash"
								kind="danger"
								onClick={() => this.setState({ isDeleteShortcutModalOpen: true })}
								id="btn"
								classes={["m-1"]}
								disabled={this.state.selectedRow.length === 0}
								data-place="left"
								data-tip="Διαγραφή Συντόμευσης"
							/>
						</>
					}
					id="shortcutsCard"
				>
					<Datatable
						columns={columns}
						defaultSortFieldId={2}
						filteredData={this.state.filteredData}
						filterText={this.state.filterText}
						onClear={() => {
							this.setState({ filterText: "", filteredData: [] });
							this.clearSelectedRows();
						}}
						onFilter={(e) => {
							this.setFilter(e.target.value);
							this.clearSelectedRows();
						}}
						rowData={isEmpty(this.state.filterText) ? this.props.allShortcuts : this.state.filteredData}
						selectable
						selectedRows={this.state.selectedRow}
						setSelectedRow={this.selectedRow}
						toggledClearRows={this.toggledClearRows}
					/>
					<ManageShortcutForm
						shortcut={!isEmpty(this.state.selectedRow) ? this.state.selectedRow[0] : null}
						resetGridFilters={this.resetAllFilters}
						isModalOpen={this.state.isModalOpen}
						closeModal={() => this.setState({ isModalOpen: false })}
					/>
					{!isEmpty(this.state.selectedRow) && (
						<Modal
							isOpen={this.state.isDeleteShortcutModalOpen}
							header={
								<>
									<em className="fas fa-trash float-left fa-1_2x mr-3" />
									Επιβεβαίωση Διαγραφής
								</>
							}
							headerBg="danger"
							loading={this.state.loading}
							onClose={() => this.setState({ isDeleteShortcutModalOpen: false })}
							footer={
								<>
									<Button
										text="Επιβεβαίωση"
										type="button"
										kind="success"
										onClick={this.onDeleteHandler}
									/>
									<Button
										text="Κλείσιμο"
										kind="secondary"
										type="button"
										onClick={() => this.setState({ isDeleteShortcutModalOpen: false })}
									/>
								</>
							}
						>
							<div>
								<strong>Είστε σίγουρος ότι επιθυμείτε να προβείτε στη διαγραφή της συντόμευσης;</strong>
								<p className="mt-2">
									{" "}
									<i className="fas fa-exclamation-circle mr-1 text-primary fa-120x"></i>Προσοχή: Η ενέργεια δεν θα μπορεί να αναιρεθεί.
								</p>
							</div>
						</Modal>
					)}
					<ReactTooltip effect="solid" />
				</Card>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		allShortcuts: allShortcuts(state),
		allFetchingFinished: allFetchingFinished(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		deleteShortcut: (shortcutId) => dispatch(deleteShortcut(shortcutId)),
		deleteShortcutSuccess: (shortcutData) => dispatch(deleteShortcutSuccess(shortcutData)),
		deleteShortcutFail: (error) => dispatch(deleteShortcutFail(error)),
		setApiResponse: (theApiResponse) => dispatch(apiResponse(theApiResponse)),
	};
};

ShortcutsManagement.propTypes = {
	allShortcuts: PropTypes.arrayOf(PropTypes.exact(ShortcutShape)),
	allFetchingFinished: PropTypes.bool,
	deleteShortcut: PropTypes.func,
	deleteShortcutSuccess: PropTypes.func,
	deleteShortcutFail: PropTypes.func,
	setApiResponse: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShortcutsManagement);
