import { deleteResource, getResource, postResource, putResource } from "../../BackendAPI/AbstractCRUDActions";
import { ActionKinds } from "../../BackendAPI/ActionKinds";
import { getCurrentStudentUserGradesUrl, getCurrentTeacherGradesUrl, getGradesUrl } from "../../BackendAPI/BackendAPI";
import {
	ADD_NEW_GRADES_FAIL,
	ADD_NEW_GRADES_START,
	ADD_NEW_GRADES_SUCCESS,
	DELETE_GRADE_FAIL,
	DELETE_GRADE_START,
	DELETE_GRADE_SUCCESS,
	FETCH_ALL_GRADES_FAIL,
	FETCH_ALL_GRADES_START,
	FETCH_ALL_GRADES_SUCCESS,
	FETCH_CURRENT_STUDENT_USER_GRADES_FAIL,
	FETCH_CURRENT_STUDENT_USER_GRADES_START,
	FETCH_CURRENT_STUDENT_USER_GRADES_SUCCESS,
	FETCH_CURRENT_TEACHER_GRADES_FAIL,
	FETCH_CURRENT_TEACHER_GRADES_START,
	FETCH_CURRENT_TEACHER_GRADES_SUCCESS,
	UPDATE_GRADE_FAIL,
	UPDATE_GRADE_START,
	UPDATE_GRADE_SUCCESS,
} from "./actionTypes";

export const fetchAllGradesStart = () => {
	return {
		type: FETCH_ALL_GRADES_START,
	};
};

export const fetchAllGradesSuccess = (lessonsData) => {
	return {
		type: FETCH_ALL_GRADES_SUCCESS,
		payload: lessonsData,
	};
};

export const fetchAllGradesFail = (error) => {
	return {
		type: FETCH_ALL_GRADES_FAIL,
		payload: error,
	};
};

export const fetchAllGrades = () => {
	return (dispatch) => {
		dispatch(fetchAllGradesStart());
		const url = getGradesUrl(ActionKinds.GET);
		getResource(url)
			.then((response) => {
				dispatch(fetchAllGradesSuccess(response.data));
			})
			.catch((error) => {
				dispatch(fetchAllGradesFail(error));
			});
	};
};

export const fetchCurrentTeacherGradesStart = () => {
	return {
		type: FETCH_CURRENT_TEACHER_GRADES_START,
	};
};

export const fetchCurrentTeacherGradesSuccess = (gradesData) => {
	return {
		type: FETCH_CURRENT_TEACHER_GRADES_SUCCESS,
		payload: gradesData,
	};
};

export const fetchCurrentTeacherGradesFail = (error) => {
	return {
		type: FETCH_CURRENT_TEACHER_GRADES_FAIL,
		payload: error,
	};
};

export const fetchCurrentTeacherGrades = () => {
	return (dispatch) => {
		dispatch(fetchCurrentTeacherGradesStart());
		const url = getCurrentTeacherGradesUrl(ActionKinds.GET);
		getResource(url)
			.then((response) => {
				dispatch(fetchCurrentTeacherGradesSuccess(response.data));
			})
			.catch((error) => {
				dispatch(fetchCurrentTeacherGradesFail(error));
			});
	};
};

export const fetchCurrentStudentUserGradesStart = () => {
	return {
		type: FETCH_CURRENT_STUDENT_USER_GRADES_START,
	};
};

export const fetchCurrentStudentUserGradesSuccess = (gradesData) => {
	return {
		type: FETCH_CURRENT_STUDENT_USER_GRADES_SUCCESS,
		payload: gradesData,
	};
};

export const fetchCurrentStudentUserGradesFail = (error) => {
	return {
		type: FETCH_CURRENT_STUDENT_USER_GRADES_FAIL,
		payload: error,
	};
};

export const fetchCurrentStudentUserGrades = () => {
	return (dispatch) => {
		dispatch(fetchCurrentStudentUserGradesStart());
		const url = getCurrentStudentUserGradesUrl(ActionKinds.GET);
		getResource(url)
			.then((response) => {
				dispatch(fetchCurrentStudentUserGradesSuccess(response.data));
			})
			.catch((error) => {
				dispatch(fetchCurrentStudentUserGradesFail(error));
			});
	};
};

export const addNewGradeStart = () => {
	return {
		type: ADD_NEW_GRADES_START,
	};
};

export const addNewGradeSuccess = (newGradeData) => {
	return {
		type: ADD_NEW_GRADES_SUCCESS,
		payload: newGradeData,
	};
};

export const addNewGradeFail = (error) => {
	return {
		type: ADD_NEW_GRADES_FAIL,
		payload: error,
	};
};

export const addNewGrade = (newGradeData) => {
	return (dispatch) => {
		dispatch(addNewGradeStart());
		const url = getGradesUrl(ActionKinds.POST);
		return postResource(url, newGradeData);
	};
};

export const updateGradeStart = () => {
	return {
		type: UPDATE_GRADE_START,
	};
};

export const updateGradeSuccess = (gradeData) => {
	return {
		type: UPDATE_GRADE_SUCCESS,
		payload: gradeData,
	};
};

export const updateGradeFail = (error) => {
	return {
		type: UPDATE_GRADE_FAIL,
		payload: error,
	};
};

export const updateGrade = (gradeData, gradeId) => {
	return (dispatch) => {
		dispatch(updateGradeStart());
		const url = getGradesUrl(ActionKinds.UPDATE, gradeId);
		return putResource(url, gradeData);
	};
};

export const deleteGradeStart = () => {
	return {
		type: DELETE_GRADE_START,
	};
};

export const deleteGradeSuccess = (gradeData) => {
	return {
		type: DELETE_GRADE_SUCCESS,
		payload: gradeData,
	};
};

export const deleteGradeFail = (error) => {
	return {
		type: DELETE_GRADE_FAIL,
		payload: error,
	};
};

export const deleteGrade = (gradeId) => {
	return (dispatch) => {
		dispatch(deleteGradeStart());
		const url = getGradesUrl(ActionKinds.DELETE, gradeId);
		return deleteResource(url);
	};
};
