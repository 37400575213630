import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { logout } from "../../Redux/Actions/index";

const Logout = ({ logout }) => {
	useEffect(() => {
		logout();
	}, [logout]);

	return <Redirect to="/login" />;
};

const mapDispatchToProps = (dispatch) => {
	return {
		logout: () => dispatch(logout()),
	};
};

Logout.propTypes = {
	logout: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(Logout);
