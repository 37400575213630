import { globalStore } from "../../globalStore";

export const allScientificFieldLessons = (state) => {
	if (!state.parametrics.finished) {
		return [];
	}

	const newSfLessons = state.scientificFieldLessons.allScientificFieldLessons.map((sfLesson) => {
		const updatedSfLessons = { ...sfLesson };
		updatedSfLessons.lesson = globalStore?.lessons?.find((lesson) => lesson.id === updatedSfLessons.lesson_id);
		updatedSfLessons.scientific_field = globalStore?.scientific_fields?.find((sf) => sf.id === updatedSfLessons.scientific_field_id);
		return updatedSfLessons;
	});
	return newSfLessons;
};
