import { has, isEmpty, isEqual } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";

import { postResource } from "../../../../BackendAPI/AbstractCRUDActions";
import { ActionKinds } from "../../../../BackendAPI/ActionKinds";
import { getUserToStudentUrl } from "../../../../BackendAPI/BackendAPI";
import { globalStore } from "../../../../globalStore";
import { DepartmentShape } from "../../../../Models/DepartmentShape";
import { ShortcutShape } from "../../../../Models/ShortcutShape";
import { addNewStudent, addNewStudentFail, addNewStudentSuccess, apiResponse, fetchAllUsers } from "../../../../Redux/Actions/index";
import { allDepartments } from "../../../../Redux/Selectors/departmentsSelectors";
import { allShortcuts } from "../../../../Redux/Selectors/shortcutsSelectors";
import { prepareDataBeforeSend } from "../../../../Utils/ComponentsUtils";
import { getLessonsAsCheckboxesGroup } from "../../../../Utils/GuiUtils";
import { nullifyEmptyStrings } from "../../../../Utils/NullConversionUtils";
import { clone } from "../../../../Utils/Utils";
import BaseInput from "../../../UI/BaseInput/BaseInput";
import Button from "../../../UI/Button/Button";
import { Col, Row } from "../../../UI/Grid/Grid";
import { MODAL_SIZE } from "../../../UI/Modal/constants/ModalSize";
import Modal from "../../../UI/Modal/Modal";
import Tag from "../../../UI/Tag/Tag";

class StudentResubscriptionForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			studentResubscriptionForm: {
				department: {
					label: "Τμήμα",
					name: "department",
					options: this.getDepartmentsAsDropdown(),
					placeholder: "Τμήμα",
					required: true,
					value: null,
					colSpan: 7,
				},
				fee: {
					label: "Δίδακτρα",
					name: "fee",
					required: false,
					value: "",
					inputType: "number",
					isNumber: true,
					colSpan: 5,
				},
			},
			studentLessonsData: null,
			loading: false,
			isStudentLessonsModalOpen: false,
		};
	}

	componentDidMount() {
		this.setState({
			studentLessonsData: getLessonsAsCheckboxesGroup(globalStore.lessons),
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (!isEqual(this.props.student, prevProps.student)) {
			this.emptyAllFormFields();
		}
	}

	isFormValid = () => {
		let valid = true;
		const tmp = {
			...this.state.studentResubscriptionForm,
		};
		const keys = Object.keys(tmp);
		for (let i = 0; i < keys.length; i++) {
			if (tmp[keys[i]].required) {
				if (has(tmp[keys[i]], "selected") && isEmpty(tmp[keys[i]].selected?.toString())) {
					valid = false;
					break;
				}
				if (has(tmp[keys[i]], "value") && (isEmpty(tmp[keys[i]].value) || tmp[keys[i]].value === null)) {
					valid = false;
					break;
				}
			}
		}
		return valid && !this.isLessonsFormInvalid();
	};

	onChangeHandler = (selectedOption, inputId) => {
		const updatedForm = clone(this.state.studentResubscriptionForm);
		const updatedFormElement = clone(updatedForm[inputId]);

		if (has(updatedFormElement, "options")) {
			updatedFormElement.value = selectedOption;
		} else {
			if (updatedFormElement.isNumber)
				updatedFormElement.value = isEmpty(selectedOption.target.value) ? selectedOption.target.value : Number(selectedOption.target.value);
			else updatedFormElement.value = selectedOption.target.value;
		}

		updatedForm[inputId] = updatedFormElement;
		this.setState({ studentResubscriptionForm: updatedForm });
	};

	onChangeCheckboxHandler = (event, inputId) => {
		const updatedForm = clone(this.state.studentLessonsData);
		const updatedFormElement = clone(updatedForm[inputId]);

		if (this.state.studentLessonsData[inputId].value === Number(event.target.value)) {
			updatedFormElement.isChecked = !updatedFormElement.isChecked;
			updatedForm[inputId] = updatedFormElement;
			this.setState({ studentLessonsData: updatedForm });
		}
	};

	onLessonCheckboxesReset = () => {
		this.setState({ studentLessonsData: getLessonsAsCheckboxesGroup(globalStore.lessons) });
		ReactTooltip.hide();
	};

	onFormSubmit = () => {
		this.setState({ loading: true });
		const outputStudent = clone(this.props.student);
		const lessons = [];
		for (let outerKey in this.state) {
			for (let key in this.state[outerKey]) {
				if (outerKey === "studentLessonsData") {
					if (this.state[outerKey][key].isChecked) lessons.push(this.state[outerKey][key].value);
				} else {
					Object.assign(outputStudent, prepareDataBeforeSend(this.state[outerKey]));
				}
			}
		}
		outputStudent.studentLessons = lessons;
		outputStudent.submission_date = moment().format("YYYY-MM-DD");

		this.props
			.addNewStudent(nullifyEmptyStrings(outputStudent))
			.then((studentResponse) => {
				if (this.props.student.user_to_student != null) {
					const url = getUserToStudentUrl(ActionKinds.POST);
					const payloadData = {
						user_id: this.props.student.user_to_student.user_id,
						student_id: studentResponse.data.id,
					};
					postResource(url, payloadData)
						.then((userToStudentResponse) => {
							const student = {
								...studentResponse.data,
								lessons: outputStudent.studentLessons,
							};
							const apiResponseMsg = {
								error: null,
								info: {
									message: (
										<>
											Ο μαθητής{" "}
											<b>
												{studentResponse.data.lastname} {studentResponse.data.firstname}
											</b>{" "}
											προστέθηκε με επιτυχία.
										</>
									),
								},
							};
							this.props.setApiResponse(apiResponseMsg);
							this.props.addNewStudentSuccess(student);
							this.props.fetchAllUsers();
						})
						.catch((error) => {
							const apiResponseMsg = {
								error: error,
								info: null,
							};
							this.props.setApiResponse(apiResponseMsg);
						})
						.finally(() => {
							this.setState({ loading: false });
							this.onCloseStudentResubModal();
						});
				} else {
					const student = {
						...studentResponse.data,
						lessons: outputStudent.studentLessons,
					};
					const apiResponseMsg = {
						error: null,
						info: {
							message: (
								<>
									Ο μαθητής{" "}
									<b>
										{studentResponse.data.lastname} {studentResponse.data.firstname}
									</b>{" "}
									προστέθηκε με επιτυχία.
								</>
							),
						},
					};
					this.props.setApiResponse(apiResponseMsg);
					this.props.addNewStudentSuccess(student);
				}
			})
			.catch((error) => {
				const apiResponseMsg = {
					error: error,
					info: null,
				};
				this.props.setApiResponse(apiResponseMsg);
				this.props.addNewStudentFail(error);
			})
			.finally(() => {
				this.setState({ loading: false });
				this.onCloseStudentResubModal();
			});
	};

	emptyAllFormFields = () => {
		const updatedForm = clone(this.state.studentResubscriptionForm);
		const updatedFormElement = clone(updatedForm.department);
		updatedFormElement.value = null;
		updatedForm.department = updatedFormElement;
		this.setState({ studentResubscriptionForm: updatedForm });
		this.onLessonCheckboxesReset();
	};

	getDepartmentsAsDropdown = () => {
		const output = this.props.allDepartments.map((department) => {
			return { label: department.name, value: department.id };
		});
		return output;
	};

	getShortcutsButton = () => {
		const addSelectedLessonsToState = (lessons) => {
			const updatedStateLessons = clone(this.state.studentLessonsData);
			const keys = Object.keys(updatedStateLessons);
			keys.forEach((state_key) => {
				const lesson = lessons.find((less) => less.description === state_key);
				if (lesson) updatedStateLessons[state_key].isChecked = true;
				else updatedStateLessons[state_key].isChecked = false;
			});
			this.setState({ studentLessonsData: updatedStateLessons });
		};
		return (
			<>
				{this.props.allShortcuts.map((shortcut, idx) => {
					return (
						<Button
							type="button"
							text={shortcut.name}
							kind="purple"
							classes={["mr-1 my-1 btn-sm"]}
							onClick={() => addSelectedLessonsToState(shortcut.lessons)}
							key={idx}
						/>
					);
				})}
			</>
		);
	};

	isLessonsFormInvalid = () => {
		let allFalse = true;
		for (let key in this.state.studentLessonsData) {
			if (this.state.studentLessonsData[key].isChecked) {
				allFalse = false;
				break;
			}
		}
		return allFalse;
	};

	onCloseStudentResubModal = () => {
		this.emptyAllFormFields();
		this.props.closeModal();
	};

	render() {
		const formElementsArray = [];

		for (let key in this.state.studentResubscriptionForm) {
			formElementsArray.push({
				id: key,
				config: this.state.studentResubscriptionForm[key],
			});
		}

		const lessonsElementsArray = [];
		for (let key in this.state.studentLessonsData) {
			lessonsElementsArray.push({
				id: key,
				config: this.state.studentLessonsData[key],
			});
		}

		const footer = (
			<>
				<Button
					type="button"
					kind="success"
					onClick={this.onFormSubmit}
					disabled={!this.isFormValid()}
					text="Αποθήκευση"
				/>
				<Button
					type="button"
					kind="secondary"
					text="Κλείσιμο"
					onClick={() => this.onCloseStudentResubModal()}
				/>
			</>
		);

		return (
			<>
				<Modal
					isOpen={this.props.isModalOpen}
					header={
						<>
							<em className="fas fa-plus float-left fa-1_2x mr-3" />
							Προσθήκη Στοιχείων Φοίτησης
						</>
					}
					headerBg="primary"
					loading={this.state.loading}
					footer={footer}
					onClose={() => this.onCloseStudentResubModal()}
				>
					<form>
						<Row classes={["px-2"]}>
							{formElementsArray.map((element, idx) => {
								return (
									<Col
										xl={element.config.colSpan}
										lg={element.config.colSpan}
										classes={["mb-4", "px-2"]}
										key={idx}
									>
										<label>
											{element.config.label} {element.config.required ? <span className="reqField">(*)</span> : null}
										</label>
										<BaseInput
											key={element.config.name + element.config.id}
											name={element.config.name}
											placeholder={element.config.placeholder}
											options={element.config.options}
											type={element.config.inputType}
											value={element.config.value}
											onChange={(selectedOption) => {
												this.onChangeHandler(selectedOption, element.id);
											}}
										/>
									</Col>
								);
							})}
						</Row>
						<Row>
							<Col>
								<label>
									Μαθήματα <span className="reqField">(*)</span>
								</label>
								<table className="table table-sm">
									<tbody>
										<tr>
											<td
												className="p-0"
												style={{ width: "76px", border: "none" }}
											>
												<Button
													classes={["mr-1", "btn-sm"]}
													type="button"
													iconClass={this.isLessonsFormInvalid() ? "fas fa-plus" : "fas fa-pencil-alt"}
													kind="primary"
													onClick={() => this.setState({ isStudentLessonsModalOpen: true })}
													data-tip="Προσθήκη Μαθημάτων"
												/>
												<Button
													classes={["btn-sm"]}
													type="button"
													iconClass="fas fa-eraser"
													kind="warning"
													data-tip="Εκκαθάριση Μαθημάτων"
													onClick={this.onLessonCheckboxesReset}
													disabled={this.isLessonsFormInvalid()}
												/>
											</td>
											<td
												className="bg-light pl-2"
												style={{ border: "1px solid #eee" }}
											>
												{lessonsElementsArray.map((element, idx) => {
													return element.config.isChecked ? <Tag key={idx}>{element.config.name}</Tag> : null;
												})}
											</td>
										</tr>
									</tbody>
								</table>
							</Col>
						</Row>
						<ReactTooltip effect="solid" />
					</form>
				</Modal>
				<Modal
					isOpen={this.state.isStudentLessonsModalOpen}
					size={MODAL_SIZE.xl}
					header={
						<>
							<em className="fas fa-plus float-left fa-1_2x mr-3" />
							Προσθήκη Μαθημάτων
						</>
					}
					footer={
						<>
							<Button
								text="Αποθήκευση & Κλείσιμο"
								type="button"
								kind="success"
								onClick={() => this.setState({ isStudentLessonsModalOpen: false })}
							/>
						</>
					}
					headerBg="primary"
					onClose={() => this.setState({ isStudentLessonsModalOpen: false })}
				>
					<Row classes={["px-2"]}>
						<Col classes={["mb-2", "px-2"]}>
							<h5 className="mb-0">
								Επιλογή Μαθημάτων <span className="text-warning text-bold">(*)</span>
							</h5>
							<small>Πρέπει να επιλεχθεί τουλάχιστον ένα μάθημα</small>
						</Col>
						<Col classes={["mb-3", "px-2", "text-center"]}>{this.getShortcutsButton()}</Col>
						{lessonsElementsArray.map((element, idx) => {
							return (
								<Col
									xl={element.config.colSpan}
									lg={element.config.colSpan}
									md={4}
									sm={6}
									classes={["mb-3", "px-2"]}
									key={idx}
								>
									<BaseInput
										key={element.config.name + element.config.id}
										name={element.config.name}
										type={element.config.inputType}
										value={element.config.value}
										label={element.config.label}
										placeholder={element.config.placeholder}
										checked={element.config.isChecked}
										onChange={(event) => {
											this.onChangeCheckboxHandler(event, element.id);
										}}
									/>
								</Col>
							);
						})}
					</Row>
				</Modal>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		allDepartments: allDepartments(state),
		allShortcuts: allShortcuts(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		addNewStudent: (studentData) => dispatch(addNewStudent(studentData)),
		addNewStudentFail: (error) => dispatch(addNewStudentFail(error)),
		addNewStudentSuccess: (studentData) => dispatch(addNewStudentSuccess(studentData)),
		fetchAllUsers: () => dispatch(fetchAllUsers()),
		setApiResponse: (theApiResponse) => dispatch(apiResponse(theApiResponse)),
	};
};

StudentResubscriptionForm.propTypes = {
	addNewStudent: PropTypes.func,
	addNewStudentFail: PropTypes.func,
	addNewStudentSuccess: PropTypes.func,
	allDepartments: PropTypes.arrayOf(PropTypes.exact(DepartmentShape)),
	allShortcuts: PropTypes.arrayOf(PropTypes.exact(ShortcutShape)),
	closeModal: PropTypes.func,
	fetchAllUsers: PropTypes.func,
	isModalOpen: PropTypes.bool,
	setApiResponse: PropTypes.func,
	student: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentResubscriptionForm);
