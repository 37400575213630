import { updateObject } from "../../Utils/Utils";
import {
	ADD_NEW_PANHELLENIC_EXAMINATION_RESULT_FAIL,
	ADD_NEW_PANHELLENIC_EXAMINATION_RESULT_START,
	ADD_NEW_PANHELLENIC_EXAMINATION_RESULT_SUCCESS,
	AUTH_LOGOUT,
	DELETE_PANHELLENIC_EXAMINATION_RESULT_FAIL,
	DELETE_PANHELLENIC_EXAMINATION_RESULT_START,
	DELETE_PANHELLENIC_EXAMINATION_RESULT_SUCCESS,
	FETCH_ALL_PANHELLENIC_EXAMINATIONS_RESULTS_FAIL,
	FETCH_ALL_PANHELLENIC_EXAMINATIONS_RESULTS_START,
	FETCH_ALL_PANHELLENIC_EXAMINATIONS_RESULTS_SUCCESS,
	UPDATE_PANHELLENIC_EXAMINATION_RESULT_FAIL,
	UPDATE_PANHELLENIC_EXAMINATION_RESULT_START,
	UPDATE_PANHELLENIC_EXAMINATION_RESULT_SUCCESS,
} from "../Actions/actionTypes";

const initialState = {
	allPanhellenicExaminationsResults: [],
	error: null,
	finished: false,
	loading: false,
	success: false,
};

export const fetchPanhellenicExaminationsResultsStart = (state) => {
	return updateObject(state, {
		error: null,
		finished: false,
		loading: true,
		success: false,
	});
};

const fetchPanhellenicExaminationsResultsSuccess = (state, action) => {
	const updatedResults = action.payload.results.map((result, idx) => {
		const updatedResult = { ...result };
		updatedResult.grades = [];
		updatedResult.flGrades = [];
		updatedResult.designGrades = [];
		const updatedResultGrades = action.payload.resultGrades.filter((obj) => obj.panhellenic_examinations_result_id === updatedResult.id);
		const updatedResultFLGrades = action.payload.resultFLGrades.filter((obj) => obj.panhellenic_examinations_result_id === updatedResult.id);
		const updatedResultDesignGrades = action.payload.resultDesignGradesData.filter(
			(obj) => obj.panhellenic_examinations_result_id === updatedResult.id,
		);
		for (let i in updatedResultGrades) {
			if (updatedResultGrades[i].panhellenic_examinations_result_id === updatedResult.id) {
				updatedResult.grades.push({
					lesson_id: updatedResultGrades[i].lesson_id,
					grade: updatedResultGrades[i].grade,
				});
			}
		}
		for (let i in updatedResultFLGrades) {
			if (updatedResultFLGrades[i].panhellenic_examinations_result_id === updatedResult.id) {
				updatedResult.flGrades.push({
					lesson_id: updatedResultFLGrades[i].lesson_id,
					grade: updatedResultFLGrades[i].grade,
				});
			}
		}
		for (let i in updatedResultDesignGrades) {
			if (updatedResultDesignGrades[i].panhellenic_examinations_result_id === updatedResult.id) {
				updatedResult.designGrades.push({
					lesson_id: updatedResultDesignGrades[i].lesson_id,
					grade: updatedResultDesignGrades[i].grade,
				});
			}
		}
		return updatedResult;
	});
	return updateObject(state, {
		allPanhellenicExaminationsResults: updatedResults,
		error: null,
		finished: true,
		loading: false,
		success: false,
	});
};

const fetchPanhellenicExaminationsResultsFail = (state, action) => {
	return updateObject(state, {
		allPanhellenicExaminationsResults: [],
		error: action.payload,
		finished: false,
		loading: false,
		success: false,
	});
};

const addNewPanhellenicExaminationResultStart = (state) => {
	return updateObject(state, {
		error: null,
		loading: true,
		success: false,
	});
};

const addNewPanhellenicExaminationResultSuccess = (state, action) => {
	const updatedPanhellenicExaminationResults = [...state.allPanhellenicExaminationsResults];
	const newPanhellenicExaminationResult = { ...action.payload };
	updatedPanhellenicExaminationResults.push(newPanhellenicExaminationResult);
	return updateObject(state, {
		allPanhellenicExaminationsResults: updatedPanhellenicExaminationResults,
		error: null,
		loading: false,
		success: true,
	});
};

const addNewPanhellenicExaminationResultFail = (state, action) => {
	return updateObject(state, {
		error: action.payload,
		loading: false,
		success: false,
	});
};

const updatePanhellenicExaminationResultStart = (state) => {
	return updateObject(state, {
		error: null,
		loading: true,
		success: false,
	});
};

const updatePanhellenicExaminationResultSuccess = (state, action) => {
	const updatedPanhellenicExaminationResults = [...state.allPanhellenicExaminationsResults].filter((obj) => obj.id !== action.payload.id);
	const newPanhellenicExaminationResult = { ...action.payload };
	updatedPanhellenicExaminationResults.push(newPanhellenicExaminationResult);
	// sortArrayOfObjectsByString(updatedStudents, 'lastname');
	return updateObject(state, {
		allPanhellenicExaminationsResults: updatedPanhellenicExaminationResults,
		error: null,
		loading: false,
		success: true,
	});
};

const updatePanhellenicExaminationResultFail = (state, action) => {
	return updateObject(state, {
		error: action.payload,
		loading: false,
		success: false,
	});
};

const deletePanhellenicExaminationResultStart = (state) => {
	return updateObject(state, {
		error: null,
		loading: true,
		success: false,
	});
};

const deletePanhellenicExaminationResultSuccess = (state, action) => {
	const updatedPanhellenicExaminationsResults = [...state.allPanhellenicExaminationsResults].filter((obj) => obj.id !== action.payload.id);
	return updateObject(state, {
		allPanhellenicExaminationsResults: updatedPanhellenicExaminationsResults,
		error: null,
		loading: false,
		success: true,
	});
};

const deletePanhellenicExaminationResultFail = (state, action) => {
	return updateObject(state, {
		error: action.payload,
		loading: false,
		success: false,
	});
};

const authLogout = (state) => {
	return updateObject(state, initialState);
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_ALL_PANHELLENIC_EXAMINATIONS_RESULTS_START:
			return fetchPanhellenicExaminationsResultsStart(state);
		case FETCH_ALL_PANHELLENIC_EXAMINATIONS_RESULTS_FAIL:
			return fetchPanhellenicExaminationsResultsFail(state, action);
		case FETCH_ALL_PANHELLENIC_EXAMINATIONS_RESULTS_SUCCESS:
			return fetchPanhellenicExaminationsResultsSuccess(state, action);
		case ADD_NEW_PANHELLENIC_EXAMINATION_RESULT_START:
			return addNewPanhellenicExaminationResultStart(state);
		case ADD_NEW_PANHELLENIC_EXAMINATION_RESULT_FAIL:
			return addNewPanhellenicExaminationResultFail(state, action);
		case ADD_NEW_PANHELLENIC_EXAMINATION_RESULT_SUCCESS:
			return addNewPanhellenicExaminationResultSuccess(state, action);
		case UPDATE_PANHELLENIC_EXAMINATION_RESULT_START:
			return updatePanhellenicExaminationResultStart(state);
		case UPDATE_PANHELLENIC_EXAMINATION_RESULT_FAIL:
			return updatePanhellenicExaminationResultFail(state, action);
		case UPDATE_PANHELLENIC_EXAMINATION_RESULT_SUCCESS:
			return updatePanhellenicExaminationResultSuccess(state, action);
		case DELETE_PANHELLENIC_EXAMINATION_RESULT_START:
			return deletePanhellenicExaminationResultStart(state);
		case DELETE_PANHELLENIC_EXAMINATION_RESULT_FAIL:
			return deletePanhellenicExaminationResultFail(state, action);
		case DELETE_PANHELLENIC_EXAMINATION_RESULT_SUCCESS:
			return deletePanhellenicExaminationResultSuccess(state, action);
		case AUTH_LOGOUT:
			return authLogout(state);
		default:
			return state;
	}
};

export default reducer;
