import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { DepartmentShape } from "../../../Models/DepartmentShape";
import { GradeShape } from "../../../Models/GradeShape";
import { StudentFromSelectorShape } from "../../../Models/StudentShape";
import { UserFromSelectorShape } from "../../../Models/UserShape";
import { allDepartments } from "../../../Redux/Selectors/departmentsSelectors";
import { allGrades } from "../../../Redux/Selectors/gradesSelectors";
import { gradeGeneralStatsPerStudentByTeacherId } from "../../../Redux/Selectors/statsSelectors";
import { allStudents } from "../../../Redux/Selectors/studentsSelectors";
import { allUsers } from "../../../Redux/Selectors/userSelectors";
import { examKindIds } from "../../../Utils/ParametricTablesBinds";
import { sortArrayOfObjectsByDate } from "../../../Utils/Utils";
import CounterCard from "../../UI/CounterCard/CounterCard";
import { Col, Row } from "../../UI/Grid/Grid";
import LatestGradesView from "./LatestGradesView/LatestGradesView";
import LessonGradesStats from "./LessonGradesStats/LessonGradesStats";
import StudentGradesStatsTable from "./StudentGradesStatsTable/StudentGradesStatsTable";

class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			examKindSelectedId: -1,
		};
	}

	componentDidMount() {
		document.title = process.env.REACT_APP_NAME;
	}

	render() {
		const counterCardsConfig = [
			{
				bgColor: "bg-green",
				iconClass: "fas fa-user-graduate",
				subtitle: "ΜΑΘΗΤΕΣ",
				title: this.props.allStudents.length,
			},
			{
				bgColor: "bg-primary",
				iconClass: "fas fa-chalkboard",
				subtitle: "ΤΜΗΜΑΤΑ",
				title: this.props.allDepartments.length,
			},
			{
				bgColor: "bg-purple",
				iconClass: "far fa-star",
				subtitle: "ΒΑΘΜΟΛΟΓΙΕΣ",
				title: this.props.allGrades.length,
			},
			{
				bgColor: "bg-info",
				iconClass: "fas fa-book",
				subtitle: "ΜΑΘΗΜΑΤΑ",
				title: this.props.currentUser.lessons.length,
			},
		];

		return (
			<>
				<Row>
					{counterCardsConfig.map((card, idx) => {
						return (
							<Col
								key={idx}
								lg={6}
								xl={3}
							>
								<CounterCard
									bgColor={card.bgColor}
									iconClass={card.iconClass}
									subtitle={card.subtitle}
									title={card.title}
								/>
							</Col>
						);
					})}
				</Row>
				<Row>
					<Col xl={9}>
						<LessonGradesStats
							allGrades={this.props.allGrades}
							allLessons={this.props.currentUser.lessons}
						/>
						<StudentGradesStatsTable
							allLessons={this.props.currentUser.lessons}
							disabled={isEmpty(this.props.allGrades)}
							gradeGeneralStatsPerStudentByTeacherId={this.props.gradeGeneralStatsPerStudentByTeacherId}
						/>
					</Col>
					<Col xl={3}>
						<LatestGradesView
							grades={sortArrayOfObjectsByDate(
								this.props.allGrades.filter((grade) => grade.exam_kind.id === examKindIds.TEST),
								"created_at",
								"desc",
							).filter((_, idx) => idx <= 6)}
							title="Τελευταία Test"
						/>
						<LatestGradesView
							grades={sortArrayOfObjectsByDate(
								this.props.allGrades.filter((grade) => grade.exam_kind.id === examKindIds.EXAM),
								"created_at",
								"desc",
							).filter((_, idx) => idx <= 6)}
							title="Τελευταία Διαγωνίσματα"
						/>
						<LatestGradesView
							grades={sortArrayOfObjectsByDate(
								this.props.allGrades.filter((grade) => grade.exam_kind.id === examKindIds.STUDY),
								"created_at",
								"desc",
							).filter((_, idx) => idx <= 6)}
							title="Τελευταία Εργασίες"
						/>
					</Col>
				</Row>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		allDepartments: allDepartments(state),
		allGrades: allGrades(state),
		allStudents: allStudents(state),
		currentUser: allUsers(state)[0],
		gradeGeneralStatsPerStudentByTeacherId: (lesson_id, exam_kind_id) => gradeGeneralStatsPerStudentByTeacherId(state, lesson_id, exam_kind_id),
	};
};

Dashboard.propTypes = {
	allDepartments: PropTypes.arrayOf(PropTypes.exact(DepartmentShape)),
	allGrades: PropTypes.arrayOf(PropTypes.exact(GradeShape)),
	allStudents: PropTypes.arrayOf(PropTypes.exact(StudentFromSelectorShape)),
	currentUser: PropTypes.exact(UserFromSelectorShape),
	gradeGeneralStatsPerStudentByTeacherId: PropTypes.func,
};

export default withRouter(connect(mapStateToProps)(Dashboard));
