import { isEmpty, uniqBy } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import { GradeShape } from "../../../Models/GradeShape";
import { getViewStudentPath } from "../../../navigation";
import { apiResponse, deleteGrade, deleteGradeFail, deleteGradeSuccess } from "../../../Redux/Actions/index";
import { allGrades } from "../../../Redux/Selectors/gradesSelectors";
import { gradeAvgPerLesson, gradeGeneralStatsPerLesson, gradesCountPerLesson } from "../../../Redux/Selectors/statsSelectors";
import { allFetchingFinished } from "../../../Redux/Selectors/universalSelectors";
import { CUSTOM_ERRORS } from "../../../Utils/CustomErrors";
import { getGradeCountersFooter } from "../../../Utils/GuiUtils";
import { absenceKindIds, examKindIds } from "../../../Utils/ParametricTablesBinds";
import Badge from "../../UI/Badge/Badge";
import Button from "../../UI/Button/Button";
import Card from "../../UI/Card/Card";
import CustomChart from "../../UI/Chart/CustomChart";
import Datatable from "../../UI/Datatable/Datatable";
import { Col, Row } from "../../UI/Grid/Grid";
import Modal from "../../UI/Modal/Modal";
import Tag from "../../UI/Tag/Tag";
import AddGradeForm from "./Forms/AddGradeForm";
import EditGradeForm from "./Forms/EditGradeForm";
import FilterGradesForm from "./Forms/FilterGradesForm";
import PrintReportForm from "./Forms/PrintReportForm";

const gradeGeneralStatsPerLessonColumns = [
	{
		name: "Μάθημα",
		selector: (row) => row.lesson,
		sortable: true,
		wrap: true,
	},
	{
		name: "Απουσίες",
		selector: (row) => row.absence,
		sortable: true,
		wrap: true,
	},
	{
		name: "Πλήθος Βαθμολογιών",
		selector: (row) => row.cnt,
		sortable: true,
	},
	{
		name: "Μέσος Όρος /100",
		selector: (row) => Number(row.avg100.replace("%", "")),
		format: (row) => row.avg100,
		sortable: true,
	},
	{
		name: "Μέσος Όρος /20",
		selector: (row) => Number(row.avg20),
		format: (row) => row.avg20,
		sortable: true,
	},
	{
		name: "Υψηλότερη",
		selector: (row) => Number(row.maxGrade.replace("%", "")),
		format: (row) => row.maxGrade,
		sortable: true,
	},
	{
		name: "Χαμηλότερη",
		selector: (row) => Number(row.minGrade.replace("%", "")),
		format: (row) => row.minGrade,
		sortable: true,
	},
];

const GradeManagement = ({
	allGrades,
	deleteGrade,
	deleteGradeFail,
	deleteGradeSuccess,
	gradeAvgPerLesson,
	gradeGeneralStatsPerLesson,
	gradesCountPerLesson,
	setApiResponse,
}) => {
	const [filterStatisticsText, setFilterStatisticsText] = useState("");
	const [filterText, setFilterText] = useState("");
	const [filteredData, setFilteredData] = useState([]);
	const [filteredStatisticsData, setFilteredStatisticsData] = useState([]);
	const [isDataFiltered, setIsDataFiltered] = useState(false);
	const [loading, setLoading] = useState(false);
	const [selectedRow, setSelectedRow] = useState([]);
	const [isDeleteGradeModalOpen, setIsDeleteGradeModalOpen] = useState(false);
	const [isCommentsModalOpen, setIsCommentsModalOpen] = useState(false);
	const [isAddGradeModalOpen, setIsAddGradeModalOpen] = useState(false);
	const [isEditGradeModalOpen, setIsEditGradeModalOpen] = useState(false);
	const [isFilterGradesModalOpen, setIsFilterGradesModalOpen] = useState(false);
	const [isPrintReportModalOpen, setIsPrintReportModalOpen] = useState(false);
	const toggledClearRows = useRef(false);
	const history = useHistory();
	const columns = [
		{
			name: "Α/Α",
			selector: (row) => row.id,
			sortable: true,
			width: "75px",
		},
		{
			name: "Ημ. Εξέτασης",
			selector: (row) => row.exam_date,
			format: (row) => (row.exam_date ? moment(row.exam_date).format("ddd, DD/MM/YYYY") : ""),
			sortable: true,
			wrap: true,
		},
		{
			name: "Καθηγητής",
			selector: (row) => row.teacher.lastname + " " + row.teacher.firstname,
			sortable: true,
			wrap: true,
		},
		{
			name: "Μαθητής",
			selector: (row) => row.student.lastname + " " + row.student.firstname,
			format: (row) => (
				<a
					href={getViewStudentPath(row.student.id)}
					onClick={(e) => {
						e.preventDefault();
						history.push(getViewStudentPath(row.student.id));
					}}
				>
					{row.student.lastname + " " + row.student.firstname}
				</a>
			),
			sortable: true,
			wrap: true,
		},
		{
			name: "Μάθημα",
			selector: (row) => row.lesson.description,
			sortable: true,
			wrap: true,
		},
		{
			name: "Τμήμα",
			selector: (row) => row.student.department.name,
			sortable: true,
			wrap: true,
			maxWidth: "105px",
		},
		{
			name: "Τάξη",
			selector: (row) => row.student.department.class.description,
			sortable: true,
			wrap: true,
			omit: true,
		},
		{
			name: "Είδος Εξέτασης",
			selector: (row) => row.exam_kind.description,
			format: (row) => {
				const badgeKind = (() => {
					if (row.exam_kind.id === examKindIds.EXAM) {
						return "purple";
					} else if (row.exam_kind.id === examKindIds.TEST) {
						return "primary";
					} else if (row.exam_kind.id === examKindIds.STUDY) {
						return "green";
					} else {
						CUSTOM_ERRORS.throwError("GradeManagement/GradeManagement.js | line 82", CUSTOM_ERRORS.UNEXPECTED_IF_STATEMENT, row.exam_kind);
					}
				})();
				return (
					<Badge
						kind={badgeKind}
						text={row.exam_kind.description}
					/>
				);
			},
			sortable: true,
			minWidth: "122px",
		},
		{
			name: "Παρουσία",
			selector: (row) => row.absence.description,
			format: (row) => (
				<Badge
					kind={row.absence.id === absenceKindIds.NOT_PRESENT ? "danger" : "success"}
					text={row.absence.description}
				/>
			),
			sortable: true,
		},
		{
			name: "Βαθμός",
			selector: (row) => row.grade,
			sortable: true,
			maxWidth: "105px",
		},
		{
			name: "Σχόλια",
			selector: (row) => row.comments,
			format: (row) => (!isEmpty(row.comments) ? <i className="fas fa-check"></i> : ""),
			sortable: true,
			maxWidth: "95px",
		},
		{
			name: "Καταχωρήθηκε",
			selector: (row) => row.created_at,
			format: (row) => (row.created_at ? moment(row.created_at).format("ddd, DD/MM/YYYY HH:mm:ss") : ""),
			sortable: true,
			wrap: true,
			minWidth: "150px",
		},
	];

	console.log("GradeManagement render");

	const clearSelectedRows = useCallback(() => {
		setSelectedRow([]);
		toggledClearRows.current = !toggledClearRows.current;
	}, [toggledClearRows]);

	const resetAllFilters = useCallback(() => {
		setFilterText("");
		setFilteredData([]);
		setIsDataFiltered(false);
		clearSelectedRows();
	}, [setFilterText, setFilteredData, setIsDataFiltered, clearSelectedRows]);

	useEffect(() => {
		document.title = process.env.REACT_APP_NAME + " :: Διαχείριση Βαθμολογιών";
	}, []);

	const setFilter = (filterString) => {
		const filteredItems = allGrades.filter((grade) => {
			return (
				`${grade.teacher.lastname} ${grade.teacher.firstname}`.toLowerCase().includes(filterString.toLowerCase()) ||
				`${grade.teacher.firstname} ${grade.teacher.lastname}`.toLowerCase().includes(filterString.toLowerCase())
			);
		});
		const filteredItems2 = allGrades.filter((grade) => {
			return (
				`${grade.student.lastname} ${grade.student.firstname}`.toLowerCase().includes(filterString.toLowerCase()) ||
				`${grade.student.firstname} ${grade.student.lastname}`.toLowerCase().includes(filterString.toLowerCase())
			);
		});
		const filteredItems3 = allGrades.filter((grade) => grade.lesson.description.toLowerCase().includes(filterString.toLowerCase()));
		const filteredItems4 = allGrades.filter((grade) => grade.student.department.name.toLowerCase().includes(filterString.toLowerCase()));
		const filteredItems5 = allGrades.filter((grade) => grade.absence.description.toLowerCase().includes(filterString.toLowerCase()));
		const filteredItems6 = allGrades.filter((grade) => grade.exam_kind.description.toLowerCase().includes(filterString.toLowerCase()));
		setFilterText(filterString);
		setFilteredData(uniqBy(filteredItems.concat(filteredItems2, filteredItems3, filteredItems4, filteredItems5, filteredItems6), "id"));
	};

	const setStatisticsTableFilter = (filterString) => {
		const filteredItems = gradeGeneralStatsPerLesson.filter((row) => row.lesson.toLowerCase().includes(filterString.toLowerCase()));
		setFilterStatisticsText(filterString);
		setFilteredStatisticsData(filteredItems);
	};

	const selectedGridRow = (data) => {
		setSelectedRow(data.selectedRows);
	};

	const setGridFilteredData = (filteredData, isDataFiltered) => {
		if (isEmpty(filteredData)) {
			setFilteredData([]);
		} else {
			setFilteredData(filteredData);
		}
		setIsDataFiltered(isDataFiltered);
	};

	const onDeleteGradeHandler = () => {
		setLoading(true);
		deleteGrade(!isEmpty(selectedRow) ? selectedRow[0].id : null)
			.then((response) => {
				setApiResponse({
					error: null,
					info: {
						message: (
							<>
								Η βαθμολογία του μαθητή{" "}
								<b>
									{selectedRow[0].student.lastname} {selectedRow[0].student.firstname}
								</b>{" "}
								στις <b>{moment(selectedRow[0].exam_date).format("DD/MM/YYYY")}</b> διαγράφηκε με επιτυχία.
							</>
						),
					},
				});
				deleteGradeSuccess(response.data);
			})
			.catch((error) => {
				setApiResponse({
					error: error,
					info: null,
				});
				deleteGradeFail(error);
			})
			.finally(() => {
				setLoading(false);
				setIsDeleteGradeModalOpen(false);
				resetAllFilters();
			});
	};

	const tableData = (() => {
		if (isDataFiltered) {
			return filteredData;
		} else {
			return allGrades;
		}
	})();

	return (
		<>
			<Card
				extraOnHeader={
					<>
						<Button
							classes={["m-1"]}
							data-tip="Προσθήκη Βαθμολογίας"
							iconClass="fas fa-plus"
							id="btn"
							kind="primary"
							onClick={() => setIsAddGradeModalOpen(true)}
							oval
							type="button"
						/>
						<Button
							classes={["m-1"]}
							data-tip="Επεξεργασία Βαθμολογίας"
							disabled={selectedRow.length === 0}
							iconClass="fas fa-pencil-alt"
							id="btn"
							kind="info"
							onClick={() => setIsEditGradeModalOpen(true)}
							oval
							type="button"
						/>
						<Button
							classes={["m-1"]}
							data-tip="Διαγραφή Βαθμολογίας"
							disabled={selectedRow.length === 0}
							iconClass="fas fa-trash"
							id="btn"
							kind="danger"
							onClick={() => setIsDeleteGradeModalOpen(true)}
							oval
							type="button"
						/>
						<Button
							classes={["m-1"]}
							data-multiline={true}
							data-tip="Προβολή Σχολίων<br/>Βαθμολογίας"
							disabled={selectedRow.length === 0 || isEmpty(selectedRow[0].comments)}
							iconClass="far fa-comments"
							id="btn"
							kind="muted"
							onClick={() => setIsCommentsModalOpen(true)}
							oval
							type="button"
						/>
						<Button
							classes={["m-1"]}
							data-place="left"
							data-tip="Εκτύπωση Αναφοράς Βαθμολογιών"
							iconClass="fas fa-print"
							id="btn"
							kind="green"
							onClick={() => setIsPrintReportModalOpen(true)}
							oval
							type="button"
						/>
						<Button
							classes={["m-1"]}
							data-place="left"
							data-tip="Φίλτρα Αναζήτησης"
							disabled={isEmpty(allGrades) || !isEmpty(filterText)}
							iconClass="fas fa-filter"
							id="btn"
							kind="purple"
							onClick={() => setIsFilterGradesModalOpen(true)}
							oval
							type="button"
						/>
						<Button
							classes={["m-1", isDataFiltered ? "d-inline-block" : "d-none"]}
							data-place="left"
							data-tip="Απαλοιφή Φίλτρων"
							iconClass="fas fa-eraser"
							id="btn"
							kind="warning"
							onClick={() => resetAllFilters()}
							oval
							type="button"
						/>
					</>
				}
				footer={isDataFiltered || !isEmpty(filterText) ? getGradeCountersFooter(filteredData) : getGradeCountersFooter(allGrades)}
				title="Προβολή Καταχωρημένων Βαθμολογιών"
				headerSpannig={[7, 5]}
				id="gradesCard"
				subTitle={<div className="text-sm">Αριθμός Καταχωρήσεων: {tableData.length}</div>}
			>
				<Datatable
					columns={columns}
					defaultSortAsc={false}
					defaultSortFieldId={2}
					exportCsv
					exportFilename="Πίνακας Βαθμολογιών Μαθητολογίου Πολύτροπο"
					filterText={filterText}
					filteredData={filteredData}
					onClear={() => {
						setFilterText("");
						setFilteredData([]);
						clearSelectedRows();
					}}
					onFilter={(e) => {
						setFilter(e.target.value);
						clearSelectedRows();
					}}
					rowData={isEmpty(filterText) ? tableData : filteredData}
					searchDisabled={isDataFiltered}
					selectable
					selectedRows={selectedRow}
					setSelectedRow={selectedGridRow}
					toggledClearRows={toggledClearRows.current}
				/>
				<FilterGradesForm
					setFilteredData={setGridFilteredData}
					closeModal={() => setIsFilterGradesModalOpen(false)}
					isModalOpen={isFilterGradesModalOpen}
					isDataFiltered={isDataFiltered}
				/>
				<PrintReportForm
					closeModal={() => setIsPrintReportModalOpen(false)}
					isModalOpen={isPrintReportModalOpen}
				/>
				<AddGradeForm
					closeModal={() => setIsAddGradeModalOpen(false)}
					isModalOpen={isAddGradeModalOpen}
					resetGradeFiltersAndClearSelection={resetAllFilters}
					setFilteredData={setGridFilteredData}
				/>
				{!isEmpty(selectedRow) && (
					<>
						<EditGradeForm
							closeModal={() => setIsEditGradeModalOpen(false)}
							grade={!isEmpty(selectedRow) ? selectedRow[0] : null}
							isModalOpen={isEditGradeModalOpen}
							resetGradeFiltersAndClearSelection={resetAllFilters}
							setFilteredData={setGridFilteredData}
						/>
						<Modal
							footer={
								<>
									<Button
										kind="success"
										onClick={onDeleteGradeHandler}
										text="Επιβεβαίωση"
										type="button"
									/>
									<Button
										kind="secondary"
										text="Κλείσιμο"
										type="button"
										onClick={() => setIsDeleteGradeModalOpen(false)}
									/>
								</>
							}
							header={
								<>
									<em className="fas fa-trash float-left fa-1_2x mr-3" />
									Επιβεβαίωση Διαγραφής
								</>
							}
							headerBg="danger"
							isOpen={isDeleteGradeModalOpen}
							loading={loading}
							onClose={() => setIsDeleteGradeModalOpen(false)}
						>
							<div>
								<strong>Είστε σίγουρος ότι επιθυμείτε να προβείτε στη διαγραφή της βαθμολογίας;</strong>
								<p className="mt-2">
									{" "}
									<i className="fas fa-exclamation-circle mr-1 text-primary fa-120x"></i>Προσοχή: Η ενέργεια δεν θα μπορεί να αναιρεθεί.
								</p>
							</div>
						</Modal>
						<Modal
							footer={
								<Button
									kind="secondary"
									text="Κλείσιμο"
									type="button"
									onClick={() => setIsCommentsModalOpen(false)}
								/>
							}
							header={
								<>
									<em className="far fa-comments float-left fa-1_2x mr-3" />
									Σχόλια Επιλεγμένης Βαθμολογίας
								</>
							}
							isOpen={isCommentsModalOpen}
							onClose={() => setIsCommentsModalOpen(false)}
						>
							<Row>
								<Col>
									<Tag>
										{selectedRow[0].student.lastname +
											" " +
											selectedRow[0].student.firstname +
											" | " +
											selectedRow[0].exam_kind.description +
											" (" +
											moment(selectedRow[0].exam_date).format("ddd, DD/MM/YYYY") +
											")"}
									</Tag>
								</Col>
								<Col classes={["mt-2"]}>
									{selectedRow[0].comments ?? <p className="text-danger mb-0 text-center">Δε βρέθηκαν σχόλια στην επιλεγμένη βαθμολογία</p>}
								</Col>
							</Row>
						</Modal>
					</>
				)}
				<ReactTooltip effect="solid" />
			</Card>
			{!isEmpty(allGrades) && (
				<Row>
					<Col
						lg={6}
						xl={6}
					>
						<Card
							extraOnHeader={
								<i
									className="fas fa-chart-bar"
									style={{ fontSize: "250%" }}
								></i>
							}
							headerSpannig={[8, 4]}
							subTitle={<small>Αφορά μόνο σε παρόντες στις εξετάσεις</small>}
							title="Μ.Ο. Βαθμών / Μάθημα"
						>
							<div
								className="mb-3"
								style={{ height: "350px", width: "100%" }}
							>
								<CustomChart
									data={{
										labels: gradeAvgPerLesson.map((stat) => stat.lesson),
										datasets: [
											{
												data: gradeAvgPerLesson.map((stat) => stat.avg),
											},
										],
									}}
									id="gradeAvgPerLessonChart"
									type="bar"
								/>
							</div>
						</Card>
					</Col>
					<Col
						xl={6}
						lg={6}
					>
						<Card
							extraOnHeader={
								<i
									className="fas fa-chart-pie"
									style={{ fontSize: "250%" }}
								></i>
							}
							headerSpannig={[8, 4]}
							subTitle={<small>Αφορά μόνο σε μαθήματα με τουλάχιστον μία βαθμολογία</small>}
							title="Πλήθος Βαθμολογιών"
						>
							<div
								className="mb-3"
								style={{ height: "350px", width: "100%" }}
							>
								<CustomChart
									id="gradesCountPerLessonChart"
									data={{
										labels: gradesCountPerLesson.map((stat) => stat.lesson),
										datasets: [
											{
												data: gradesCountPerLesson.map((stat) => stat.cnt),
											},
										],
									}}
									type="pie"
								/>
							</div>
						</Card>
					</Col>
				</Row>
			)}
			<Row>
				<Col>
					<Card
						title="Στατιστικά"
						subTitle={<small>Τα στατιστικά βαθμολογιών αφορούν μόνο σε παρόντες</small>}
					>
						<Datatable
							columns={gradeGeneralStatsPerLessonColumns}
							defaultSortFieldId={1}
							exportCsv
							exportFilename="Πίνακας Στατιστικών Βαθμολογιών Μαθητολογίου Πολύτροπο"
							filterData={filteredStatisticsData}
							filterText={filterStatisticsText}
							onClear={() => {
								setFilterStatisticsText("");
								setFilteredStatisticsData([]);
								clearSelectedRows();
							}}
							onFilter={(e) => {
								setStatisticsTableFilter(e.target.value);
								clearSelectedRows();
							}}
							rowData={isEmpty(filterStatisticsText) ? gradeGeneralStatsPerLesson : filteredStatisticsData}
							selectable={false}
						/>
					</Card>
				</Col>
			</Row>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		allFetchingFinished: allFetchingFinished(state),
		allGrades: allGrades(state),
		gradeAvgPerLesson: gradeAvgPerLesson(state),
		gradeGeneralStatsPerLesson: gradeGeneralStatsPerLesson(state),
		gradesCountPerLesson: gradesCountPerLesson(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		deleteGrade: (gradeId) => dispatch(deleteGrade(gradeId)),
		deleteGradeFail: (error) => dispatch(deleteGradeFail(error)),
		deleteGradeSuccess: (gradeData) => dispatch(deleteGradeSuccess(gradeData)),
		setApiResponse: (theApiResponse) => dispatch(apiResponse(theApiResponse)),
	};
};

GradeManagement.propTypes = {
	allFetchingFinished: PropTypes.bool,
	allGrades: PropTypes.arrayOf(PropTypes.exact(GradeShape)),
	deleteGrade: PropTypes.func,
	deleteGradeFail: PropTypes.func,
	deleteGradeSuccess: PropTypes.func,
	gradeAvgPerLesson: PropTypes.arrayOf(PropTypes.object),
	gradeGeneralStatsPerLesson: PropTypes.arrayOf(PropTypes.object),
	gradesCountPerLesson: PropTypes.arrayOf(PropTypes.object),
	setApiResponse: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(GradeManagement);
