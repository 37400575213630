import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { GradeShape } from "../../../../Models/GradeShape";
import { absenceKindIds } from "../../../../Utils/ParametricTablesBinds";
import { getTeacherViewStudentPath } from "../../../../navigation";
import { PathEntry } from "../../../../navigation";
import Card from "../../../UI/Card/Card";
import Button from "../../../UI/Button/Button";
import { Row, Col } from "../../../UI/Grid/Grid";

const LatestGradesView = ({ grades, title }) => {
	const history = useHistory();

	return (
		<Card
			bodyClasses={["p-0"]}
			extraOnHeader={
				<i
					className="fas fa-list-ul"
					style={{ fontSize: "200%" }}
				/>
			}
			footer={
				!isEmpty(grades) ? (
					<Button
						classes={["p-0"]}
						disabled={isEmpty(grades)}
						kind="link"
						onClick={() => history.push(PathEntry.teacherGradesManagement.path)}
						text={
							<span>
								Προβολή Όλων<em className="fa fa-chevron-circle-right ml-3"></em>
							</span>
						}
						type="button"
					/>
				) : null
			}
			headerSpannig={[9, 3]}
			title={title}
		>
			{isEmpty(grades) ? (
				<Row
					align="center"
					classes={["border-bottom", "text-bold", "text-danger", "text-center"]}
				>
					<Col
						lg={12}
						md={12}
						sm={12}
						xl={12}
					>
						Δεν βρέθηκαν δεδομένα
					</Col>
				</Row>
			) : (
				grades.map((grade, idx) => {
					return (
						<Row
							align="center"
							classes={[`${idx !== grade.length - 1 && "border-bottom"}`, "py-2", "w-100", "mx-0"]}
							key={idx}
						>
							<Col
								lg={8}
								md={8}
								sm={8}
								xl={8}
							>
								<a
									href={getTeacherViewStudentPath(grade.student.id)}
									onClick={(e) => {
										e.preventDefault();
										history.push(getTeacherViewStudentPath(grade.student.id));
									}}
								>
									{grade.student.lastname + " " + grade.student.firstname}
								</a>
								<br />
								<small>
									{moment(grade.created_at).format("DD/MM/YYYY")} | {grade.lesson.description}
								</small>
							</Col>
							<Col
								classes={["text-right"]}
								lg={4}
								md={4}
								sm={4}
								xl={4}
							>
								{grade.absence.id === absenceKindIds.NOT_PRESENT ? (
									<span className="text-danger text-bold">ΑΠΩΝ</span>
								) : (
									<span className={`${grade.grade >= 50 ? "text-green" : "text-danger"} text-bold`}>{grade.grade}%</span>
								)}
							</Col>
						</Row>
					);
				})
			)}
		</Card>
	);
};

LatestGradesView.propTypes = {
	grades: PropTypes.arrayOf(PropTypes.exact(GradeShape)),
	title: PropTypes.string.isRequired,
};

export default LatestGradesView;
