import { deleteResource, postResource } from "../../BackendAPI/AbstractCRUDActions";
import { ActionKinds } from "../../BackendAPI/ActionKinds";
import { getTeacherLessonsUrl } from "../../BackendAPI/BackendAPI";
import {
	ADD_NEW_TEACHER_LESSONS_FAIL,
	ADD_NEW_TEACHER_LESSONS_START,
	ADD_NEW_TEACHER_LESSONS_SUCCESS,
	DELETE_TEACHER_LESSONS_FAIL,
	DELETE_TEACHER_LESSONS_START,
	DELETE_TEACHER_LESSONS_SUCCESS,
} from "./actionTypes";

export const addNewTeacherLessonsStart = () => {
	return {
		type: ADD_NEW_TEACHER_LESSONS_START,
	};
};

export const addNewTeacherLessonsSuccess = (newTeacherLessonsData) => {
	return {
		type: ADD_NEW_TEACHER_LESSONS_SUCCESS,
		payload: newTeacherLessonsData,
	};
};

export const addNewTeacherLessonsFail = (error) => {
	return {
		type: ADD_NEW_TEACHER_LESSONS_FAIL,
		payload: error,
	};
};

export const addNewTeacherLessons = (newTeacherLessonsData) => {
	return (dispatch) => {
		dispatch(addNewTeacherLessonsStart());
		const url = getTeacherLessonsUrl(ActionKinds.POST);
		return postResource(url, newTeacherLessonsData);
	};
};

export const deleteTeacherLessonsStart = () => {
	return {
		type: DELETE_TEACHER_LESSONS_START,
	};
};

export const deleteTeacherLessonsSuccess = (teacherLessonsData) => {
	return {
		type: DELETE_TEACHER_LESSONS_SUCCESS,
		payload: teacherLessonsData,
	};
};

export const deleteTeacherLessonsFail = (error) => {
	return {
		type: DELETE_TEACHER_LESSONS_FAIL,
		payload: error,
	};
};

export const deleteTeacherLessons = (teacherId) => {
	return (dispatch) => {
		dispatch(deleteTeacherLessonsStart());
		const url = getTeacherLessonsUrl(ActionKinds.DELETE, teacherId);
		return deleteResource(url);
	};
};
