import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import PropTypes from "prop-types";

import Card from "../../../UI/Card/Card";

class GradesStatsCard extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const studentStats = this.props.gradeGeneralStatsPerStudent(
			Number(this.props.match.params.student_id),
			this.props.lessonSelectedId,
			this.props.examKindSelectedId,
		);

		const gradeHeader = (() => {
			if (studentStats.avg20 === "-") {
				return <p className="text-center text-danger text-bold">Δεν βρέθηκε βαθμολογία</p>;
			} else if (Number(studentStats.avg20) >= 10) {
				return (
					<p className="display-4 text-center text-green">
						{studentStats.avg100} <span style={{ fontSize: "80%" }}>{studentStats.avg20}</span>
					</p>
				);
			} else {
				return (
					<p className="display-4 text-center text-danger">
						{studentStats.avg100} <span style={{ fontSize: "80%" }}>{studentStats.avg20}</span>
					</p>
				);
			}
		})();

		return (
			<Card
				title="Στατιστικά"
				id="studentGradesStatsCard"
				headerSpannig={[8, 4]}
				extraOnHeader={<i className="fas fa-calculator fa-2x"></i>}
			>
				{gradeHeader}
				<div>
					<table className="table">
						<tbody>
							<tr>
								<td>
									<b>Αριθμός Βαθμολογιών</b>
								</td>
								<td>{studentStats.cnt}</td>
							</tr>
							<tr>
								<td>
									<b>Απουσίες</b>
								</td>
								<td>{studentStats.absence}</td>
							</tr>
							<tr>
								<td>
									<b>Μέγιστη</b>
								</td>
								<td>{studentStats.maxGrade}</td>
							</tr>
							<tr>
								<td>
									<b>Ελάχιστη</b>
								</td>
								<td>{studentStats.minGrade}</td>
							</tr>
							<tr>
								<td>
									<b>Τελευταία</b>
								</td>
								<td>{studentStats.latest !== "-" ? moment(studentStats.latest).format("ddd, DD/MM/YYYY") : "-"}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</Card>
		);
	}
}

GradesStatsCard.propTypes = {
	lessonSelectedId: PropTypes.number,
	examKindSelectedId: PropTypes.number,
	gradeGeneralStatsPerStudent: PropTypes.func,
};

export default withRouter(GradesStatsCard);
