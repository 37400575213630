import { listConstants } from "../../globalStore";
import { globalStore } from "../../globalStore";
import { allDepartments } from "./departmentsSelectors";
import { permissions } from "../../Utils/ParametricTablesBinds";

export const allUsers = (state) => {
	if (!state.parametrics.finished) {
		return [];
	}

	const newUsers = state.users.allUsers.map((user) => {
		const updatedUser = { ...user };
		const updatedLessons = [];
		const updatedDepartments = [];
		for (let i in updatedUser.lessons) {
			updatedLessons.push(globalStore?.lessons?.filter((obj) => obj.id === updatedUser.lessons[i])[0]);
		}
		for (let i in updatedUser.departments) {
			updatedDepartments.push(allDepartments(state).filter((obj) => obj.id === updatedUser.departments[i])[0]);
		}
		updatedUser.lessons = updatedLessons;
		updatedUser.departments = updatedDepartments;
		updatedUser.status = listConstants.status.filter((obj) => obj.id === user.status)[0];
		updatedUser.permission = listConstants.permissions.filter((obj) => obj.id === user.permission)[0];
		return updatedUser;
	});
	return newUsers;
};

export const allTeacherUsers = (state) => {
	return allUsers(state).filter((user) => user.permission.id !== permissions.STUDENT);
};

export const allStudentUsers = (state) => {
	return allUsers(state).filter((user) => user.permission.id === permissions.STUDENT);
};

export const allAdminUsers = (state) => {
	return allUsers(state).filter((user) => user.permission.id === permissions.ADMIN);
};
