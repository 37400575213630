import React, { Component } from "react";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";

import { globalStore } from "../../../../../../../globalStore";
import { clone } from "../../../../../../../Utils/Utils";
import BaseInput from "../../../../../../UI/BaseInput/BaseInput";
import { Col } from "../../../../../../UI/Grid/Grid";

class DesignLessonsForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			designLessonsForm: null,
		};
	}

	componentDidMount() {
		this.getLessonFields();
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.executeReset && this.props.executeReset) {
			this.getLessonFields();
			this.props.resetExecuteResetToggle();
		}
	}

	getLessonFields = () => {
		const output = {};
		globalStore.design_lessons.forEach((lesson) => {
			output[lesson.description] = {
				label: lesson.description,
				name: lesson.description,
				placeholder: "Εισάγετε βαθμό",
				lesson_id: lesson.id,
				required: true,
				isNumber: true,
				value: !isEmpty(this.props.selectedResult) ? this.props.selectedResult.find((grade) => grade.lesson.id === lesson.id).grade : "",
				inputType: "number",
				colSpan: 6,
			};
		});
		this.setState({ designLessonsForm: output });
	};

	onChangeHandler = (event, inputId) => {
		const updatedForm = clone(this.state.designLessonsForm);
		const updatedFormElement = clone(updatedForm[inputId]);

		if (updatedFormElement.isNumber) {
			updatedFormElement.value = isEmpty(event.target.value) ? event.target.value : Number(event.target.value);
		} else {
			updatedFormElement.value = event.target.value;
		}
		updatedForm[inputId] = updatedFormElement;
		this.setState({ designLessonsForm: updatedForm }, () => {
			this.calculateResult();
			this.props.setIsFormInvalid(this.isFormInvalid(), this.constructor.name);
		});
	};

	isFormInvalid = () => {
		let allFalse = false;
		for (let key in this.state.designLessonsForm) {
			if (this.state.designLessonsForm[key].required) {
				if (this.state.designLessonsForm[key].value === "") {
					allFalse = true;
				}
			}
		}
		return allFalse;
	};

	areGradesValid = (grades) => {
		let output = true;
		grades.forEach((grade) => {
			if (grade > 20) {
				this.props.setGradesError("Βρέθηκε βαθμός > 20 σε ειδικό μάθημα. Παρακαλώ διορθώστε.");
				output = false;
			} else if (grade < 0) {
				this.props.setGradesError("Βρέθηκε βαθμός < 0 σε ειδικό μάθημα. Παρακαλώ διορθώστε.");
				output = false;
			}
		});
		if (output) {
			this.props.setGradesError(null);
		}
		return output;
	};

	calculateResult = () => {
		let counter = 0;
		const tmp = [];
		for (let key in this.state.designLessonsForm) {
			if (this.state.designLessonsForm[key].value !== "") {
				tmp.push({
					lesson: {
						value: this.state.designLessonsForm[key].lesson_id,
						label: globalStore.design_lessons.find((lesson) => lesson.id === this.state.designLessonsForm[key].lesson_id).description,
					},
					grade: this.state.designLessonsForm[key].value,
				});
				counter++;
			}
		}
		if (counter === 2) {
			if (this.areGradesValid(tmp.map((item) => item.grade))) {
				this.props.setSpecialLessons([tmp[0].grade, tmp[1].grade], tmp);
			}
		}
	};

	render() {
		const formElementsArray = [];
		for (let key in this.state.designLessonsForm) {
			formElementsArray.push({
				id: key,
				config: this.state.designLessonsForm[key],
			});
		}

		return (
			<>
				{formElementsArray.map((element, idx) => {
					return (
						<Col
							xl={element.config.colSpan}
							lg={element.config.colSpan}
							md={element.config.colSpan}
							sm={element.config.colSpan}
							classes={["mb-2", "px-2"]}
							key={idx}
						>
							<label>
								{element.config.label} {element.config.required ? <span className="reqField">(*)</span> : null}
							</label>
							<BaseInput
								key={element.config.name + element.config.id}
								name={element.config.name}
								placeholder={element.config.placeholder}
								options={element.config.options}
								type={element.config.inputType}
								value={element.config.value}
								disabled={element.config.disabled || this.props.modalReadonly}
								onChange={(event) => {
									this.onChangeHandler(event, element.id);
								}}
							/>
						</Col>
					);
				})}
			</>
		);
	}
}

DesignLessonsForm.propTypes = {
	setSpecialLessons: PropTypes.func,
	setGradesError: PropTypes.func,
	setIsFormInvalid: PropTypes.func,
	resetExecuteResetToggle: PropTypes.func,
	selectedResult: PropTypes.array,
	modalReadonly: PropTypes.bool,
};

export default DesignLessonsForm;
