import { updateObject } from "../../Utils/Utils";
import { AUTH_LOGOUT, UPDATE_USER_PASSWORD_FAIL, UPDATE_USER_PASSWORD_START, UPDATE_USER_PASSWORD_SUCCESS } from "../Actions/actionTypes";

const initialState = {
	error: null,
	loading: false,
	success: false,
};

const updateUserPasswordStart = (state) => {
	return updateObject(state, {
		error: null,
		loading: true,
		passwordChanged: false,
		teacherLessonsError: null,
		teacherDepartmentsError: null,
	});
};

const updateUserPasswordSuccess = (state) => {
	return updateObject(state, {
		error: null,
		loading: false,
		passwordChanged: true,
		teacherLessonsError: null,
		teacherDepartmentsError: null,
	});
};

const updateUserPasswordFail = (state, action) => {
	return updateObject(state, {
		error: action.payload,
		loading: false,
		passwordChanged: false,
		teacherLessonsError: null,
		teacherDepartmentsError: null,
	});
};

const authLogout = (state) => {
	return updateObject(state, initialState);
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_USER_PASSWORD_START:
			return updateUserPasswordStart(state);
		case UPDATE_USER_PASSWORD_FAIL:
			return updateUserPasswordFail(state, action);
		case UPDATE_USER_PASSWORD_SUCCESS:
			return updateUserPasswordSuccess(state);
		case AUTH_LOGOUT:
			return authLogout(state);
		default:
			return state;
	}
};

export default reducer;
