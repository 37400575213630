import PropTypes from "prop-types";

import { copyToClipboard } from "../../../Utils/Utils";
import { Col } from "../../UI/Grid/Grid";
import Button from "../../UI/Button/Button";
import BaseInput from "../../UI/BaseInput/BaseInput";
import { NotificationManager } from "react-notifications";

const studentDetailsRow = (props) => {
	return (
		<div className="form-group row">
			<Col
				xl={2}
				lg={2}
				md={2}
				sm={2}
				classes={["m-auto", "text-right"]}
			>
				<label className="m-auto">{props.label}</label>
			</Col>
			<Col
				xl={10}
				lg={10}
				md={10}
				sm={10}
				classes={["d-flex"]}
			>
				<BaseInput
					name={props.name}
					disabled={true}
					value={props?.value ?? ""}
				/>
				{props.extraButtons}
				<Button
					classes={["ml-1", "btn-sm"]}
					type="button"
					iconClass="far fa-copy"
					kind="purple"
					disabled={props.value === null}
					onClick={() => {
						NotificationManager.success(props.notificationText, "Success", 3000);
						copyToClipboard(props.value);
					}}
					data-tip="Αντιγραφή"
				/>
			</Col>
		</div>
	);
};

studentDetailsRow.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	notificationText: PropTypes.string,
	extraButtons: PropTypes.element,
};

export default studentDetailsRow;
