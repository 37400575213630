import { isEmpty, uniqBy } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import { StudentFromSelectorShape } from "../../../Models/StudentShape";
import { getEditStudentPath, getViewStudentPath, PathEntry } from "../../../navigation";
import { apiResponse, deleteStudent, deleteStudentFail, deleteStudentSuccess, fetchAllUsers } from "../../../Redux/Actions/index";
import {
	studentsPerClass,
	studentsPerCounty,
	studentsPerDepartment,
	studentsPerInformedBy,
	studentsPerLesson,
} from "../../../Redux/Selectors/statsSelectors";
import { allStudents } from "../../../Redux/Selectors/studentsSelectors";
import { allFetchingFinished } from "../../../Redux/Selectors/universalSelectors";
import Badge from "../../UI/Badge/Badge";
import Button from "../../UI/Button/Button";
import Card from "../../UI/Card/Card";
import Datatable from "../../UI/Datatable/Datatable";
import { Col, Row } from "../../UI/Grid/Grid";
import Modal from "../../UI/Modal/Modal";
import StudentsPerClassCard from "./ChartCards/StudentsPerClassCard";
import StudentsPerCountyCard from "./ChartCards/StudentsPerCountyCard";
import StudentsPerInformedByCard from "./ChartCards/StudentsPerInformedByCard";
import StudentsPerLeesonCard from "./ChartCards/StudentsPerLessonCard";
import FilterStudentsForm from "./Forms/FilterStudentsForm";

const columns = [
	{
		name: "Α/Α",
		selector: (row) => row.id,
		sortable: true,
		width: "75px",
	},
	{
		name: "Επίθετο",
		selector: (row) => row.lastname,
		sortable: true,
		wrap: true,
	},
	{
		name: "Όνομα",
		selector: (row) => row.firstname,
		sortable: true,
		wrap: true,
	},
	{
		name: "Σταθερό Τηλ.",
		selector: (row) => row.landline,
		wrap: true,
	},
	{
		name: "Κινητό Τηλ.",
		selector: (row) => row.mobile_phone,
		wrap: true,
	},
	{
		name: "Email",
		selector: (row) => row.email,
		format: (row) => <a href={`mailto:${row.email}`}>{row.email}</a>,
		sortable: true,
		wrap: true,
		grow: 2,
	},
	{
		name: "Τμήμα",
		selector: (row) => row.department.name,
		sortable: true,
		wrap: true,
	},
	{
		name: "Διεύθυνση",
		selector: (row) => row.fullAddress,
		sortable: true,
		wrap: true,
		grow: 2,
	},
	{
		name: "Κατάσταση",
		selector: (row) => row.status.description,
		format: (row) => (
			<Badge
				kind={row.status.id === 1 ? "danger" : "success"}
				text={row.status.description}
			/>
		),
		sortable: true,
	},
	{
		name: "Εγγράφηκε",
		selector: (row) => row.submission_date,
		format: (row) => (row.submission_date ? moment(row.submission_date).format("ddd, DD/MM/YYYY") : ""),
		sortable: true,
		wrap: true,
	},
	{
		name: "Τάξη",
		selector: (row) => row.department.class.description,
		omit: true,
	},
	{
		name: "Όνομα Μητέρας",
		selector: (row) => row.mother_name,
		omit: true,
	},
	{
		name: "Όνομα Πατέρα",
		selector: (row) => row.father_name,
		omit: true,
	},
	{
		name: "Τηλέφωνο Μητέρας",
		selector: (row) => row.mother_phone,
		omit: true,
	},
	{
		name: "Τηλέφωνο Πατέρα",
		selector: (row) => row.father_phone,
		omit: true,
	},
	{
		name: "Όνομα Κηδεμόνα",
		selector: (row) => row.caretaker_name,
		omit: true,
	},
	{
		name: "Τηλέφωνο Κηδεμόνα",
		selector: (row) => row.caretaker_phone,
		omit: true,
	},
	{
		name: "Email Κηδεμόνα",
		selector: (row) => row.caretaker_email,
		omit: true,
	},
	{
		name: "Δίδακτρα",
		selector: (row) => row.fee,
		omit: true,
	},
	{
		name: "Μαθήματα",
		selector: (row) => row.lessons,
		format: (row) => row.lessons.map((lesson) => lesson.description).join(", "),
		omit: true,
	},
	{
		name: "Ενημερώθηκε Από",
		selector: (row) => row.informed_by?.description ?? null,
		format: (row) => row.informed_by?.description ?? null,
		omit: true,
	},
];

class StudentManagement extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterText: "",
			filteredData: [],
			isDataFiltered: false,
			isDeleteStudentModalOpen: false,
			isFilterModalOpen: false,
			loading: false,
			selectedRow: [],
		};
		this.toggledClearRows = false;
	}

	componentDidMount() {
		document.title = process.env.REACT_APP_NAME + " :: Διαχείριση Μαθητών";
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.allFetchingFinished && this.props.allFetchingFinished) {
			this.resetAllFilters();
		}
	}

	selectedRow = (data) => {
		this.setState({ selectedRow: data.selectedRows });
	};

	clearSelectedRows = () => {
		this.toggledClearRows = !this.toggledClearRows;
		this.setState({ selectedRow: [] });
	};

	setFilter = (filterString) => {
		const filteredItems = this.props.allStudents.filter((student) => {
			return (
				`${student.lastname} ${student.firstname}`.toLowerCase().includes(filterString.toLowerCase()) ||
				`${student.firstname} ${student.lastname}`.toLowerCase().includes(filterString.toLowerCase())
			);
		});
		const filteredItems2 = this.props.allStudents.filter((student) => student.email?.toLowerCase().includes(filterString.toLowerCase()));
		const filteredItems3 = this.props.allStudents.filter((student) => student.department.name?.toLowerCase().includes(filterString.toLowerCase()));
		const filteredItems4 = this.props.allStudents.filter((student) => student.landline?.toLowerCase().includes(filterString.toLowerCase()));
		const filteredItems5 = this.props.allStudents.filter((student) => student.mobile_phone?.toLowerCase().includes(filterString.toLowerCase()));
		const filteredItems6 = this.props.allStudents.filter((student) => student.fullAddress?.toLowerCase().includes(filterString.toLowerCase()));
		this.setState({
			filterText: filterString,
			filteredData: uniqBy(filteredItems.concat(filteredItems2, filteredItems3, filteredItems4, filteredItems5, filteredItems6), "id"),
		});
	};

	resetAllFilters = () => {
		this.setState({
			filterText: "",
			filteredData: [],
			isDataFiltered: false,
		});
		this.clearSelectedRows();
	};

	setFilteredData = (filteredData, isDataFiltered) => {
		isEmpty(filteredData) ? this.setState({ filteredData: [], isDataFiltered }) : this.setState({ filteredData, isDataFiltered });
	};

	onDeleteHandler = () => {
		this.setState({ loading: true });
		this.props
			.deleteStudent(!isEmpty(this.state.selectedRow) ? this.state.selectedRow[0].id : null)
			.then((response) => {
				this.props.setApiResponse({
					error: null,
					info: {
						message: (
							<>
								Ο μαθητής{" "}
								<b>
									{this.state.selectedRow[0].lastname} {this.state.selectedRow[0].firstname}
								</b>{" "}
								διαγράφηκε με επιτυχία.
							</>
						),
					},
				});
				this.props.deleteStudentSuccess(response.data);
				this.props.fetchAllUsers();
				this.resetAllFilters();
			})
			.catch((error) => {
				this.props.setApiResponse({
					error: error,
					info: null,
				});
				this.props.deleteStudentFail(error);
			})
			.finally(() => {
				this.setState({ isDeleteStudentModalOpen: false, loading: false });
			});
	};

	render() {
		const tableData = (() => {
			if (this.state.isDataFiltered) {
				return this.state.filteredData;
			} else {
				return this.props.allStudents;
			}
		})();

		return (
			<>
				<Card
					extraOnHeader={
						<>
							<Button
								classes={["m-1"]}
								data-tip="Προσθήκη Μαθητή"
								iconClass="fas fa-plus"
								id="btn"
								kind="primary"
								onClick={() => this.props.history.push(PathEntry.addStudent.path)}
								oval
								type="button"
							/>
							<Button
								classes={["m-1"]}
								data-tip="Επεξεργασία Μαθητή"
								disabled={this.state.selectedRow.length === 0}
								iconClass="fas fa-pencil-alt"
								id="btn"
								kind="info"
								onClick={() => this.props.history.push(getEditStudentPath(this.state.selectedRow[0].id))}
								oval
								type="button"
							/>
							<Button
								classes={["m-1"]}
								data-tip="Προβολή Μαθητή"
								disabled={this.state.selectedRow.length === 0}
								iconClass="fas fa-eye"
								id="btn"
								kind="green"
								onClick={() => this.props.history.push(getViewStudentPath(this.state.selectedRow[0].id))}
								oval
								type="button"
							/>
							<Button
								classes={["m-1"]}
								data-tip="Διαγραφή Μαθητή"
								disabled={this.state.selectedRow.length === 0}
								iconClass="fas fa-trash"
								id="btn"
								kind="danger"
								onClick={() => this.setState({ isDeleteStudentModalOpen: true })}
								oval
								type="button"
							/>
							<Button
								classes={["m-1"]}
								data-place="left"
								data-tip="Φίλτρα Αναζήτησης"
								disabled={isEmpty(this.props.allStudents) || !isEmpty(this.state.filterText)}
								iconClass="fas fa-filter"
								id="btn"
								kind="purple"
								onClick={() => {
									this.clearSelectedRows();
									this.setState({ isFilterModalOpen: true });
								}}
								oval
								type="button"
							/>
							{
								<Button
									classes={["m-1", this.state.isDataFiltered ? "d-inline-block" : "d-none"]}
									data-place="left"
									data-tip="Απαλοιφή Φίλτρων"
									iconClass="fas fa-eraser"
									id="btn"
									kind="warning"
									oval
									type="button"
									onClick={() => this.resetAllFilters()}
								/>
							}
						</>
					}
					headerSpannig={[6, 6]}
					id="studentsCard"
					subTitle={<div className="text-sm">Αριθμός Καταχωρήσεων: {this.props.allStudents.length}</div>}
					title="Προβολή Καταχωρημένων Μαθητών"
				>
					<Datatable
						columns={columns}
						defaultSortFieldId={2}
						exportCsv
						exportFilename="Πίνακας Μαθητών Μαθητολογίου Πολύτροπο"
						filterText={this.state.filterText}
						filteredData={this.state.filteredData}
						onClear={() => {
							this.setState({ filterText: "", filteredData: [] });
							this.clearSelectedRows();
						}}
						onFilter={(e) => {
							this.setFilter(e.target.value);
							this.clearSelectedRows();
						}}
						rowData={isEmpty(this.state.filterText) ? tableData : this.state.filteredData}
						searchDisabled={this.state.isDataFiltered}
						selectable
						selectedRows={this.state.selectedRow}
						setSelectedRow={this.selectedRow}
						toggledClearRows={this.toggledClearRows}
					/>
					{!isEmpty(this.state.selectedRow) && (
						<>
							<Modal
								footer={
									<>
										<Button
											text="Επιβεβαίωση"
											type="button"
											kind="success"
											onClick={this.onDeleteHandler}
										/>
										<Button
											text="Κλείσιμο"
											kind="secondary"
											type="button"
											onClick={() => this.setState({ isDeleteStudentModalOpen: false })}
										/>
									</>
								}
								header={
									<>
										<em className="fas fa-trash float-left fa-1_2x mr-3" />
										Επιβεβαίωση Διαγραφής
									</>
								}
								headerBg="danger"
								isOpen={this.state.isDeleteStudentModalOpen}
								loading={this.state.loading}
								onClose={() => this.setState({ isDeleteStudentModalOpen: false })}
							>
								<div>
									<strong>Είστε σίγουρος ότι επιθυμείτε να προβείτε στη διαγραφή του/της μαθητή/τριας;</strong>
									<p className="mt-2">
										{" "}
										<i className="fas fa-exclamation-circle mr-1 text-primary fa-120x"></i>
										<b>Προσοχή:</b> Σε περίπτωση που ο μαθητής έχει αντιστοιχηθεί σε κάποιο χρήστη της εφαρμογής, τότε ο χρήστης αυτός θα{" "}
										<b>απενεργοποιηθεί</b>. Η ενέργεια δεν θα μπορεί να αναιρεθεί.
									</p>
								</div>
							</Modal>
						</>
					)}
				</Card>
				<FilterStudentsForm
					setFilteredData={this.setFilteredData}
					isDataFiltered={this.state.isDataFiltered}
					isModalOpen={this.state.isFilterModalOpen}
					closeModal={() => this.setState({ isFilterModalOpen: false })}
				/>
				<ReactTooltip effect="solid" />
				{!isEmpty(this.props.allStudents) && (
					<Row>
						<Col
							lg={6}
							md={12}
							sm={12}
							xl={6}
						>
							<StudentsPerClassCard studentStats={this.props.studentStats} />
						</Col>
						<Col
							lg={6}
							md={12}
							sm={12}
							xl={6}
						>
							<StudentsPerCountyCard studentCounties={this.props.studentCounties} />
						</Col>
					</Row>
				)}

				<Row>
					<Col
						lg={6}
						md={12}
						sm={12}
						xl={6}
					>
						<StudentsPerLeesonCard studentsPerLesson={this.props.studentsPerLesson} />
					</Col>
					<Col
						lg={6}
						md={12}
						sm={12}
						xl={6}
					>
						<StudentsPerInformedByCard studentsPerInformedBy={this.props.studentsPerInformedBy} />
					</Col>
				</Row>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		allFetchingFinished: allFetchingFinished(state),
		allStudents: allStudents(state),
		studentCounties: studentsPerCounty(state),
		studentStats: studentsPerClass(state),
		studentsPerDepartment: studentsPerDepartment(state),
		studentsPerInformedBy: studentsPerInformedBy(state),
		studentsPerLesson: studentsPerLesson(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		deleteStudent: (student_id) => dispatch(deleteStudent(student_id)),
		deleteStudentFail: (error) => dispatch(deleteStudentFail(error)),
		deleteStudentSuccess: (students_data) => dispatch(deleteStudentSuccess(students_data)),
		fetchAllUsers: () => dispatch(fetchAllUsers()),
		setApiResponse: (theApiResponse) => dispatch(apiResponse(theApiResponse)),
	};
};

StudentManagement.propTypes = {
	allFetchingFinished: PropTypes.bool,
	allStudents: PropTypes.arrayOf(PropTypes.exact(StudentFromSelectorShape)),
	deleteStudent: PropTypes.func,
	deleteStudentFail: PropTypes.func,
	deleteStudentSuccess: PropTypes.func,
	fetchAllUsers: PropTypes.func,
	setApiResponse: PropTypes.func,
	studentCounties: PropTypes.array,
	studentStats: PropTypes.array,
	studentsPerDepartment: PropTypes.array,
	studentsPerInformedBy: PropTypes.array,
	studentsPerLesson: PropTypes.array,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StudentManagement));
