import PropTypes from "prop-types";
import { ParametricShape } from "./ParametricShape";
import { DepartmentShape } from "./DepartmentShape";

export const StudentFromSelectorShape = {
	address: PropTypes.string,
	caretaker_email: PropTypes.string,
	caretaker_name: PropTypes.string,
	caretaker_phone: PropTypes.string,
	county: PropTypes.string,
	created_at: PropTypes.string,
	updated_at: PropTypes.string,
	department: PropTypes.shape(DepartmentShape).isRequired,
	email: PropTypes.string,
	father_name: PropTypes.string,
	father_phone: PropTypes.string,
	fee: PropTypes.number,
	firstname: PropTypes.string.isRequired,
	fullAddress: PropTypes.string,
	id: PropTypes.number,
	informed_by: PropTypes.shape(ParametricShape),
	landline: PropTypes.string,
	lastname: PropTypes.string.isRequired,
	lesson_period: PropTypes.string,
	lessons: PropTypes.arrayOf(PropTypes.shape(ParametricShape)).isRequired,
	mobile_phone: PropTypes.string,
	mother_name: PropTypes.string,
	mother_phone: PropTypes.string,
	school_year: PropTypes.string,
	status: PropTypes.shape(ParametricShape).isRequired,
	educational_institution: PropTypes.shape(ParametricShape),
	submission_date: PropTypes.string.isRequired,
	zip: PropTypes.string,
};

export const StudentFromReduxShape = {
	address: PropTypes.string,
	caretaker_email: PropTypes.string,
	caretaker_name: PropTypes.string,
	caretaker_phone: PropTypes.string,
	county: PropTypes.string,
	created_at: PropTypes.string,
	updated_at: PropTypes.string,
	department: PropTypes.number,
	email: PropTypes.string,
	father_name: PropTypes.string,
	father_phone: PropTypes.string,
	fee: PropTypes.number,
	firstname: PropTypes.string.isRequired,
	fullAddress: PropTypes.string,
	id: PropTypes.number,
	informed_by: PropTypes.number,
	landline: PropTypes.string,
	lastname: PropTypes.string.isRequired,
	lesson_period: PropTypes.string,
	lessons: PropTypes.arrayOf(PropTypes.number).isRequired,
	mobile_phone: PropTypes.string,
	mother_name: PropTypes.string,
	mother_phone: PropTypes.string,
	school_year: PropTypes.string,
	status: PropTypes.number.isRequired,
	educational_institution: PropTypes.number,
	submission_date: PropTypes.string.isRequired,
	zip: PropTypes.string,
};
