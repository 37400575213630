import { isEmpty } from "lodash";
import { globalStore } from "../../globalStore";

export const allPanhellenicExaminationsResults = (state) => {
	if (isEmpty(state.students.allStudents) || !state.parametrics.finished || !state.scientificFieldLessons.finished) {
		return [];
	}

	const newResults = state.panhellenicExaminations.allPanhellenicExaminationsResults.map((result) => {
		const updatedResult = { ...result };
		const updatedResultGrades = [];
		const updatedDesignGrades = [];
		const updatedFLGrades = [];
		for (let i in updatedResult.grades) {
			updatedResultGrades.push({
				lesson: globalStore?.lessons?.find((lesson) => lesson.id === updatedResult.grades[i].lesson_id),
				grade: updatedResult.grades[i].grade,
				calculation_weight: state.scientificFieldLessons.allScientificFieldLessons.find(
					(sfl) => sfl.scientific_field_id === updatedResult.scientific_field_id && sfl.lesson_id === updatedResult.grades[i].lesson_id,
				)?.calculation_weight,
			});
		}
		for (let i in updatedResult.designGrades) {
			updatedDesignGrades.push({
				lesson: globalStore?.design_lessons?.find((lesson) => lesson.id === updatedResult.designGrades[i].lesson_id),
				grade: updatedResult.designGrades[i].grade,
			});
		}
		for (let i in updatedResult.flGrades) {
			updatedFLGrades.push({
				lesson: globalStore?.foreign_language_lessons?.find((lesson) => lesson.id === updatedResult.flGrades[i].lesson_id),
				grade: updatedResult.flGrades[i].grade,
			});
		}
		updatedResult.grades = updatedResultGrades;
		updatedResult.designGrades = updatedDesignGrades;
		updatedResult.flGrades = updatedFLGrades;
		updatedResult.student = state.students.allStudents.find((student) => student.id === updatedResult.student_id);
		updatedResult.scientific_field = globalStore?.scientific_fields?.find((sf) => sf.id === updatedResult.scientific_field_id);
		return updatedResult;
	});
	return newResults;
};
