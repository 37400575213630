import { isEmpty } from "lodash";
import { globalStore } from "../../globalStore";
import { allStudents } from "./studentsSelectors";

export const allGrades = (state) => {
	if (isEmpty(state.departments.allDepartments) || isEmpty(state.users.allUsers) || !state.parametrics.finished) {
		return [];
	}

	const allStateStudents = allStudents(state);

	const output = state.grades.allGrades.map((grade, idx) => {
		const updatedGrade = { ...grade };
		updatedGrade.absence = globalStore?.absence_kind?.find((absence) => absence.id === grade.absence) ?? null;
		updatedGrade.exam_kind = globalStore?.exam_kind?.find((exam_kind) => exam_kind.id === grade.exam_kind) ?? null;
		updatedGrade.lesson = globalStore?.lessons?.find((lesson) => lesson.id === grade.lesson_id) ?? null;
		updatedGrade.student = allStateStudents.find((student) => student.id === grade.student_id) ?? null;
		updatedGrade.teacher = state.users?.allUsers?.find((user) => user.id === grade.teacher_id) ?? null;
		return updatedGrade;
	});

	return output;
};
