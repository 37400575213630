import { clone, sortArrayOfObjectsByString, updateObject } from "../../Utils/Utils";
import {
	ADD_NEW_TEACHER_DEPARTMENTS_FAIL,
	ADD_NEW_TEACHER_DEPARTMENTS_START,
	ADD_NEW_TEACHER_DEPARTMENTS_SUCCESS,
	ADD_NEW_TEACHER_LESSONS_FAIL,
	ADD_NEW_TEACHER_LESSONS_START,
	ADD_NEW_TEACHER_LESSONS_SUCCESS,
	ADD_NEW_USER_FAIL,
	ADD_NEW_USER_START,
	ADD_NEW_USER_SUCCESS,
	AUTH_LOGOUT,
	DELETE_TEACHER_DEPARTMENTS_FAIL,
	DELETE_TEACHER_DEPARTMENTS_START,
	DELETE_TEACHER_DEPARTMENTS_SUCCESS,
	DELETE_TEACHER_LESSONS_FAIL,
	DELETE_TEACHER_LESSONS_START,
	DELETE_TEACHER_LESSONS_SUCCESS,
	DELETE_USER_FAIL,
	DELETE_USER_START,
	DELETE_USER_SUCCESS,
	FETCH_ALL_USERS_FAIL,
	FETCH_ALL_USERS_START,
	FETCH_ALL_USERS_SUCCESS,
	FETCH_STUDENT_USER_FAIL,
	FETCH_STUDENT_USER_START,
	FETCH_STUDENT_USER_SUCCESS,
	FETCH_TEACHER_USER_FAIL,
	FETCH_TEACHER_USER_START,
	FETCH_TEACHER_USER_SUCCESS,
	UPDATE_USER_FAIL,
	UPDATE_USER_START,
	UPDATE_USER_SUCCESS,
} from "../Actions/actionTypes";

const initialState = {
	allUsers: [],
	error: null,
	finished: false,
	loading: false,
	passwordChanged: false,
	success: false,
	teacherLessonsError: null,
	teacherDepartmentsError: null,
};

export const fetchAllUsersStart = (state) => {
	return updateObject(state, {
		error: null,
		finished: false,
		loading: true,
		success: false,
		teacherLessonsError: null,
		teacherDepartmentsError: null,
	});
};

const fetchAllUsersSuccess = (state, action) => {
	const updatedUsers = action.payload.users.map((user, idx) => {
		const updatedUser = { ...user };
		updatedUser.lessons = [];
		updatedUser.departments = [];
		updatedUser.student_id = action.payload.userToStudent.filter((obj) => obj.user_id === updatedUser.id)[0]?.student_id ?? null;
		const updatedTeacherLessons = action.payload.teacherLessons.filter((obj) => obj.teacher_id === updatedUser.id);
		const updatedTeacherDepartments = action.payload.teacherDepartments.filter((obj) => obj.teacher_id === updatedUser.id);
		for (let i in updatedTeacherLessons) {
			if (updatedTeacherLessons[i].teacher_id === updatedUser.id) {
				updatedUser.lessons.push(updatedTeacherLessons[i].lesson_id);
			}
		}
		for (let i in updatedTeacherDepartments) {
			if (updatedTeacherDepartments[i].teacher_id === updatedUser.id) {
				updatedUser.departments.push(updatedTeacherDepartments[i].department_id);
			}
		}
		return updatedUser;
	});
	return updateObject(state, {
		allUsers: updatedUsers,
		error: null,
		finished: true,
		loading: false,
		passwordChanged: false,
		success: false,
		teacherLessonsError: null,
		teacherDepartmentsError: null,
	});
};

const fetchAllUsersFail = (state, action) => {
	return updateObject(state, {
		allUsers: [],
		error: action.payload,
		finished: false,
		loading: false,
		success: false,
		teacherLessonsError: null,
		teacherDepartmentsError: null,
	});
};

export const fetchTeacherUserStart = (state) => {
	return updateObject(state, {
		error: null,
		finished: false,
		loading: true,
		success: false,
		teacherLessonsError: null,
		teacherDepartmentsError: null,
	});
};

const fetchTeacherUserSuccess = (state, action) => {
	const updatedUser = action.payload.user;
	updatedUser.lessons = [];
	updatedUser.departments = [];
	const updatedTeacherLessons = action.payload.teacherLessons.filter((obj) => obj.teacher_id === updatedUser.id);
	const updatedTeacherDepartments = action.payload.teacherDepartments.filter((obj) => obj.teacher_id === updatedUser.id);
	for (let i in updatedTeacherLessons) {
		if (updatedTeacherLessons[i].teacher_id === updatedUser.id) {
			updatedUser.lessons.push(updatedTeacherLessons[i].lesson_id);
		}
	}
	for (let i in updatedTeacherDepartments) {
		if (updatedTeacherDepartments[i].teacher_id === updatedUser.id) {
			updatedUser.departments.push(updatedTeacherDepartments[i].department_id);
		}
	}
	return updateObject(state, {
		allUsers: [updatedUser],
		error: null,
		finished: true,
		loading: false,
		passwordChanged: false,
		success: false,
		teacherLessonsError: null,
		teacherDepartmentsError: null,
	});
};

const fetchTeacherUserFail = (state, action) => {
	return updateObject(state, {
		allUsers: [],
		error: action.payload,
		finished: false,
		loading: false,
		success: false,
		teacherLessonsError: null,
		teacherDepartmentsError: null,
	});
};

export const fetchStudentUserStart = (state) => {
	return updateObject(state, {
		error: null,
		finished: false,
		loading: true,
		success: false,
		teacherLessonsError: null,
		teacherDepartmentsError: null,
	});
};

const fetchStudentUserSuccess = (state, action) => {
	return updateObject(state, {
		allUsers: [action.payload.data],
		error: null,
		finished: true,
		loading: false,
		passwordChanged: false,
		success: false,
		teacherLessonsError: null,
		teacherDepartmentsError: null,
	});
};

const fetchStudentUserFail = (state, action) => {
	return updateObject(state, {
		allUsers: [],
		error: action.payload,
		finished: false,
		loading: false,
		success: false,
		teacherLessonsError: null,
		teacherDepartmentsError: null,
	});
};

const addNewUserStart = (state) => {
	return updateObject(state, {
		error: null,
		loading: true,
		success: false,
		teacherLessonsError: null,
		teacherDepartmentsError: null,
	});
};

const addNewUserSuccess = (state, action) => {
	const updatedUsers = [...state.allUsers];
	const newUser = { ...action.payload };
	updatedUsers.push(newUser);
	sortArrayOfObjectsByString(updatedUsers, "lastname");
	return updateObject(state, {
		allUsers: updatedUsers,
		error: null,
		loading: false,
		success: true,
		teacherLessonsError: null,
		teacherDepartmentsError: null,
	});
};

const addNewUserFail = (state, action) => {
	return updateObject(state, {
		error: action.payload,
		loading: false,
		success: false,
		teacherLessonsError: null,
		teacherDepartmentsError: null,
	});
};

const addNewTeacherLessonsStart = (state) => {
	return updateObject(state, {
		error: null,
		loading: false,
		success: false,
		teacherLessonsError: null,
		teacherDepartmentsError: null,
	});
};

const addNewTeacherLessonsSuccess = (state, action) => {
	const updatedUsers = clone(state.allUsers);
	const newTeacherLessons = action.payload.data.map((row) => row.lesson_id);
	updatedUsers.forEach((user) => {
		if (user.id === action.payload.teacher_id) {
			user.lessons = newTeacherLessons;
		}
	});
	sortArrayOfObjectsByString(updatedUsers, "lastname");
	return updateObject(state, {
		allUsers: updatedUsers,
		error: null,
		loading: false,
		success: false,
		teacherLessonsError: null,
		teacherDepartmentsError: null,
	});
};

const addNewTeacherLessonsFail = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		success: false,
		teacherLessonsError: action.payload,
		teacherDepartmentsError: null,
	});
};

const addNewTeacherDepartmentsStart = (state) => {
	return updateObject(state, {
		error: null,
		loading: false,
		success: false,
		teacherLessonsError: null,
		teacherDepartmentsError: null,
	});
};

const addNewTeacherDepartmentsSuccess = (state, action) => {
	const updatedUsers = clone(state.allUsers);
	const newTeacherDepartments = action.payload.data.map((row) => row.department_id);
	updatedUsers.forEach((user) => {
		if (user.id === action.payload.teacher_id) {
			user.departments = newTeacherDepartments;
		}
	});
	sortArrayOfObjectsByString(updatedUsers, "lastname");
	return updateObject(state, {
		allUsers: updatedUsers,
		error: null,
		loading: false,
		success: false,
		teacherLessonsError: null,
		teacherDepartmentsError: null,
	});
};

const addNewTeacherDepartmentsFail = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		success: false,
		teacherLessonsError: null,
		teacherDepartmentsError: action.payload,
	});
};

const updateUserStart = (state) => {
	return updateObject(state, {
		error: null,
		loading: true,
		passwordChanged: false,
		teacherLessonsError: null,
		teacherDepartmentsError: null,
	});
};

const updateUserSuccess = (state, action) => {
	const updatedUsers = state.allUsers.filter((user) => user.id !== action.payload.id);
	const updatedUser = { ...action.payload };
	// updatedUser.student_id = action.payload.userToStudent.filter(obj => obj.user_id === updatedUser.id)[0]?.student_id ?? null;
	updatedUsers.push(updatedUser);
	sortArrayOfObjectsByString(updatedUsers, "lastname");
	return updateObject(state, {
		allUsers: updatedUsers,
		error: null,
		loading: false,
		passwordChanged: true,
		teacherLessonsError: null,
		teacherDepartmentsError: null,
	});
};

const updateUserFail = (state, action) => {
	return updateObject(state, {
		error: action.payload,
		loading: false,
		passwordChanged: false,
		teacherLessonsError: null,
		teacherDepartmentsError: null,
	});
};

const deleteUserStart = (state) => {
	return updateObject(state, {
		error: null,
		loading: true,
		passwordChanged: false,
		teacherLessonsError: null,
		teacherDepartmentsError: null,
	});
};

const deleteUserSuccess = (state, action) => {
	const updatedUsers = [...state.allUsers].filter((obj) => obj.id !== action.payload.id);
	return updateObject(state, {
		allUsers: updatedUsers,
		error: null,
		loading: false,
		passwordChanged: false,
		teacherLessonsError: null,
		teacherDepartmentsError: null,
		success: true,
	});
};

const deleteUserFail = (state, action) => {
	return updateObject(state, {
		error: action.payload,
		loading: false,
		passwordChanged: false,
		teacherLessonsError: null,
		teacherDepartmentsError: null,
	});
};

const deleteTeacherLessonsStart = (state) => {
	return updateObject(state, {
		error: null,
		loading: false,
		success: false,
		teacherLessonsError: null,
		teacherDepartmentsError: null,
	});
};

const deleteTeacherLessonsSuccess = (state, action) => {
	const updatedUsers = clone(state.allUsers);
	updatedUsers.forEach((user) => {
		if (user.id === action.payload.id) {
			user.lessons = [];
		}
	});
	sortArrayOfObjectsByString(updatedUsers, "lastname");
	return updateObject(state, {
		allUsers: updatedUsers,
		error: null,
		loading: false,
		success: false,
		teacherLessonsError: null,
		teacherDepartmentsError: null,
	});
};

const deleteTeacherLessonsFail = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		success: false,
		teacherLessonsError: action.payload,
		teacherDepartmentsError: null,
	});
};

const deleteTeacherDepartmentsStart = (state) => {
	return updateObject(state, {
		error: null,
		loading: false,
		success: false,
		teacherLessonsError: null,
		teacherDepartmentsError: null,
	});
};

const deleteTeacherDepartmentsSuccess = (state, action) => {
	const updatedUsers = clone(state.allUsers);
	updatedUsers.forEach((user) => {
		if (user.id === action.payload.id) {
			user.departments = [];
		}
	});
	sortArrayOfObjectsByString(updatedUsers, "lastname");
	return updateObject(state, {
		allUsers: updatedUsers,
		error: null,
		loading: false,
		success: false,
		teacherLessonsError: null,
		teacherDepartmentsError: null,
	});
};

const deleteTeacherDepartmentsFail = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		success: false,
		teacherLessonsError: null,
		teacherDepartmentsError: action.payload,
	});
};

const authLogout = (state) => {
	return updateObject(state, initialState);
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_ALL_USERS_START:
			return fetchAllUsersStart(state);
		case FETCH_ALL_USERS_FAIL:
			return fetchAllUsersFail(state, action);
		case FETCH_ALL_USERS_SUCCESS:
			return fetchAllUsersSuccess(state, action);
		case FETCH_TEACHER_USER_START:
			return fetchTeacherUserStart(state);
		case FETCH_TEACHER_USER_FAIL:
			return fetchTeacherUserFail(state, action);
		case FETCH_TEACHER_USER_SUCCESS:
			return fetchTeacherUserSuccess(state, action);
		case FETCH_STUDENT_USER_START:
			return fetchStudentUserStart(state);
		case FETCH_STUDENT_USER_FAIL:
			return fetchStudentUserFail(state, action);
		case FETCH_STUDENT_USER_SUCCESS:
			return fetchStudentUserSuccess(state, action);
		case ADD_NEW_USER_START:
			return addNewUserStart(state);
		case ADD_NEW_USER_FAIL:
			return addNewUserFail(state, action);
		case ADD_NEW_USER_SUCCESS:
			return addNewUserSuccess(state, action);
		case UPDATE_USER_START:
			return updateUserStart(state);
		case UPDATE_USER_FAIL:
			return updateUserFail(state, action);
		case UPDATE_USER_SUCCESS:
			return updateUserSuccess(state, action);
		case DELETE_USER_START:
			return deleteUserStart(state);
		case DELETE_USER_FAIL:
			return deleteUserFail(state, action);
		case DELETE_USER_SUCCESS:
			return deleteUserSuccess(state, action);
		case ADD_NEW_TEACHER_DEPARTMENTS_START:
			return addNewTeacherDepartmentsStart(state);
		case ADD_NEW_TEACHER_DEPARTMENTS_FAIL:
			return addNewTeacherDepartmentsFail(state, action);
		case ADD_NEW_TEACHER_DEPARTMENTS_SUCCESS:
			return addNewTeacherDepartmentsSuccess(state, action);
		case ADD_NEW_TEACHER_LESSONS_START:
			return addNewTeacherLessonsStart(state);
		case ADD_NEW_TEACHER_LESSONS_FAIL:
			return addNewTeacherLessonsFail(state, action);
		case ADD_NEW_TEACHER_LESSONS_SUCCESS:
			return addNewTeacherLessonsSuccess(state, action);
		case DELETE_TEACHER_DEPARTMENTS_START:
			return deleteTeacherDepartmentsStart(state);
		case DELETE_TEACHER_DEPARTMENTS_FAIL:
			return deleteTeacherDepartmentsFail(state, action);
		case DELETE_TEACHER_DEPARTMENTS_SUCCESS:
			return deleteTeacherDepartmentsSuccess(state, action);
		case DELETE_TEACHER_LESSONS_START:
			return deleteTeacherLessonsStart(state);
		case DELETE_TEACHER_LESSONS_FAIL:
			return deleteTeacherLessonsFail(state, action);
		case DELETE_TEACHER_LESSONS_SUCCESS:
			return deleteTeacherLessonsSuccess(state, action);
		case AUTH_LOGOUT:
			return authLogout(state);
		default:
			return state;
	}
};

export default reducer;
