import { globalStore } from "../../globalStore";
import { updateObject } from "../../Utils/Utils";
import {
	ADD_NEW_PARAMETRIC_FAIL,
	ADD_NEW_PARAMETRIC_START,
	ADD_NEW_PARAMETRIC_SUCCESS,
	AUTH_LOGOUT,
	FETCH_PARAMETRICS_FAIL,
	FETCH_PARAMETRICS_START,
	FETCH_PARAMETRICS_SUCCESS,
	UPDATE_PARAMETRIC_FAIL,
	UPDATE_PARAMETRIC_START,
	UPDATE_PARAMETRIC_SUCCESS,
} from "../Actions/actionTypes";

const initialState = {
	loading: false,
	finished: false,
	error: null,
};

export const fetchParametricsStart = (state) => {
	return updateObject(state, {
		loading: true,
		finished: false,
		error: null,
	});
};

const fetchParametricsSuccess = (state, action) => {
	return updateObject(state, {
		loading: false,
		finished: true,
		error: null,
	});
};

const fetchParametricsFail = (state, action) => {
	return updateObject(state, {
		error: action.payload,
		loading: false,
		finished: false,
	});
};

const addNewParametricStart = (state) => {
	return updateObject(state, {
		loading: true,
		error: null,
	});
};

const addNewParametricSuccess = (state, action) => {
	return updateObject(state, {
		loading: false,
		error: null,
	});
};

const addNewParametricFail = (state, action) => {
	return updateObject(state, {
		loading: false,
		error: action.payload,
	});
};

const updateParametricStart = (state) => {
	return updateObject(state, {
		loading: true,
		error: null,
	});
};

const updateParametricSuccess = (state, action) => {
	const updatedParametricTbl = globalStore[action.payload.tbl].filter((parametric) => parametric.id !== action.payload.data.id);
	updatedParametricTbl.push(action.payload.data);
	globalStore[action.payload.tbl] = updatedParametricTbl;
	return updateObject(state, {
		loading: false,
		error: null,
	});
};

const updateParametricFail = (state, action) => {
	return updateObject(state, {
		loading: false,
		error: action.payload,
	});
};

const authLogout = (state) => {
	return updateObject(state, initialState);
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_PARAMETRICS_START:
			return fetchParametricsStart(state);
		case FETCH_PARAMETRICS_FAIL:
			return fetchParametricsFail(state, action);
		case FETCH_PARAMETRICS_SUCCESS:
			return fetchParametricsSuccess(state, action);
		case ADD_NEW_PARAMETRIC_START:
			return addNewParametricStart(state);
		case ADD_NEW_PARAMETRIC_FAIL:
			return addNewParametricFail(state, action);
		case ADD_NEW_PARAMETRIC_SUCCESS:
			return addNewParametricSuccess(state, action);
		case UPDATE_PARAMETRIC_START:
			return updateParametricStart(state);
		case UPDATE_PARAMETRIC_FAIL:
			return updateParametricFail(state, action);
		case UPDATE_PARAMETRIC_SUCCESS:
			return updateParametricSuccess(state, action);
		case AUTH_LOGOUT:
			return authLogout(state);
		default:
			return state;
	}
};

export default reducer;
