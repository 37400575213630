import React, { Component } from "react";
import moment from "moment";
import PropTypes from "prop-types";

import { GradeShape } from "../../../../Models/GradeShape";
import { absenceKindIds } from "../../../../Utils/ParametricTablesBinds";
import { sortArrayOfObjectsByDate } from "../../../../Utils/Utils";
import Card from "../../../UI/Card/Card";
import CustomChart from "../../../UI/Chart/CustomChart";

class ProgressChartCard extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		let allGrades = sortArrayOfObjectsByDate(this.props.allGrades, "exam_date", "asc").filter((grade) => grade.absence.id === absenceKindIds.PRESENT);
		if (this.props.examKindSelectedId !== -1) {
			allGrades = allGrades.filter((grade) => grade.exam_kind.id === this.props.examKindSelectedId);
		}
		if (this.props.lessonSelectedId !== -1) {
			allGrades = allGrades.filter((grade) => grade.lesson_id === this.props.lessonSelectedId);
		}

		return (
			<Card
				title="Πρόοδος Μαθητή"
				id="gradeProgressCard"
				headerSpannig={[8, 4]}
				extraOnHeader={<i className="fas fa-chart-line fa-2x"></i>}
			>
				<div style={{ height: "300px" }}>
					<CustomChart
						id={"gradeProgressChart"}
						type="line"
						data={{
							labels: allGrades.map((grade) => moment(grade.exam_date).format("DD/MM/YYYY")),
							datasets: [
								{
									data: allGrades.map((grade) => grade.grade),
									backgroundColor: "rgba(114,102,186,0.2)",
									borderColor: "rgba(114,102,186,1)",
									pointBorderColor: "#fff",
									pointRadius: 5,
									pointBackgroundColor: "#443d71",
									fill: true,
									lineTension: 0.3,
								},
							],
						}}
						scales={{
							y: {
								suggestedMin: 0,
								suggestedMax: 100,
								ticks: {
									stepSize: 20,
								},
							},
						}}
					/>
				</div>
			</Card>
		);
	}
}

ProgressChartCard.propTypes = {
	lessonSelectedId: PropTypes.number,
	examKindSelectedId: PropTypes.number,
	allGrades: PropTypes.arrayOf(PropTypes.exact(GradeShape)),
};

export default ProgressChartCard;
