import { has, isEmpty, sortBy, uniqBy } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { DepartmentShape } from "../../../../Models/DepartmentShape";
import { GradeShape } from "../../../../Models/GradeShape";
import { SelfTestSelectorShape } from "../../../../Models/SelfTestShape";
import { StudentFromSelectorShape } from "../../../../Models/StudentShape";
import { UserFromSelectorShape } from "../../../../Models/UserShape";
import { allDepartments } from "../../../../Redux/Selectors/departmentsSelectors";
import { allGrades } from "../../../../Redux/Selectors/gradesSelectors";
import { allSelfTest } from "../../../../Redux/Selectors/selfTestSelectors";
import { allStudents } from "../../../../Redux/Selectors/studentsSelectors";
import { allTeacherUsers } from "../../../../Redux/Selectors/userSelectors";
import { getParametricAsOptions } from "../../../../Utils/GuiUtils";
import { clone } from "../../../../Utils/Utils";
import BaseInput from "../../../UI/BaseInput/BaseInput";
import Button from "../../../UI/Button/Button";
import { Col, Row } from "../../../UI/Grid/Grid";
import { MODAL_SIZE } from "../../../UI/Modal/constants/ModalSize";
import Modal from "../../../UI/Modal/Modal";

class FilterSelfTestForm extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			filterSelfTestForm: {
				lesson: {
					label: "Μάθημα",
					name: "lesson",
					options: this.getLessonDropdown(),
					placeholder: "Μάθημα",
					searchKind: "dropdown",
					value: null,
					colSpan: 4,
				},
				chapter: {
					label: "Ενότητα",
					name: "chapter",
					options: this.getSelfTestChapters(),
					placeholder: "Ενότητα",
					searchKind: "dropdown",
					value: null,
					colSpan: 4,
				},
				user: {
					label: "Καθηγητής/τρια",
					name: "user",
					options: this.getUserDropdown(),
					placeholder: "Καθηγητής/τρια",
					searchKind: "dropdown",
					value: null,
					colSpan: 4,
				},
				class: {
					label: "Τάξη",
					name: "class",
					options: getParametricAsOptions("classes"),
					placeholder: "Τάξη",
					searchKind: "dropdown",
					value: null,
					colSpan: 4,
				},
				status: {
					label: "Κατάσταση",
					name: "status",
					options: getParametricAsOptions("status", null, true),
					placeholder: "Κατάσταση",
					searchKind: "dropdown",
					value: null,
					colSpan: 4,
				},
			},
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isDataFiltered && !this.props.isDataFiltered) {
			this.onReset();
		}
	}

	onChangeHandler = (selectedOption, inputId) => {
		const updatedForm = clone(this.state.filterSelfTestForm);
		const updatedFormElement = clone(updatedForm[inputId]);
		//the below code is for the React Datepicker, so it has to be js Date object
		const updatedFormDateKeys = Object.values(updatedForm)
			.filter((item) => item.datepicker && item.selected !== null)
			.map((item) => item.name);
		updatedFormDateKeys.forEach((element) => {
			updatedForm[element].selected = new Date(updatedForm[element].selected);
		});

		if (has(updatedFormElement, "options")) {
			updatedFormElement.value = selectedOption;
		} else {
			if (updatedFormElement.isNumber) {
				updatedFormElement.value = Number(selectedOption.target.value);
			} else if (updatedFormElement.datepicker) updatedFormElement.selected = selectedOption;
			else updatedFormElement.value = selectedOption.target.value;
		}

		updatedForm[inputId] = updatedFormElement;
		this.setState({ filterSelfTestForm: updatedForm });
	};

	isFilterEntered = () => {
		let entered = false;
		for (let key in this.state.filterSelfTestForm) {
			if (this.state.filterSelfTestForm[key].isNumber && Number(this.state.filterSelfTestForm[key].value !== 0)) entered = true;
			else if (this.state.filterSelfTestForm[key].datepicker && !isEmpty(this.state.filterSelfTestForm[key].selected?.toString())) entered = true;
			else if (!isEmpty(this.state.filterSelfTestForm[key].value)) entered = true;
		}
		return entered;
	};

	onFormSubmit = () => {
		let filteredSelfTests = clone(this.props.allSelfTests).map((sft) => {
			sft.lesson = sft.chapter.lesson;
			return sft;
		});
		for (let key in this.state.filterSelfTestForm) {
			if (
				(!isEmpty(this.state.filterSelfTestForm[key].value) && this.state.filterSelfTestForm[key].value !== null) ||
				!isEmpty(this.state.filterSelfTestForm[key].selected?.toString()) ||
				(this.state.filterSelfTestForm[key].isNumber && Number(this.state.filterSelfTestForm[key].value !== 0))
			) {
				switch (this.state.filterSelfTestForm[key].searchKind) {
					//This will add a field called 'lesson' and a Warning for prop types will appear.
					//Dont pay attention to it!
					// TODO: fix this shit
					case "dropdown":
						filteredSelfTests = filteredSelfTests.filter((selfTest) => {
							return selfTest[key].id === Number(this.state.filterSelfTestForm[key].value.value);
						});
						break;
					case "date_from":
						filteredSelfTests = filteredSelfTests.filter((selfTest) =>
							moment(selfTest.exam_date).isSameOrAfter(moment(this.state.filterSelfTestForm[key].selected, "DD/MM/YYYY")),
						);
						break;
					case "date_until":
						filteredSelfTests = filteredSelfTests.filter((selfTest) =>
							moment(selfTest.exam_date).isSameOrBefore(moment(this.state.filterSelfTestForm[key].selected, "DD/MM/YYYY")),
						);
						break;
					default:
						break;
				}
			}
		}
		this.props.setFilteredData(filteredSelfTests, true);
		this.props.closeModal();
	};

	onReset = () => {
		this.emptyAllFormFields();
		this.props.setFilteredData([], false);
	};

	emptyAllFormFields = () => {
		const updatedForm = clone(this.state.filterSelfTestForm);
		for (let key in updatedForm) {
			const updatedFormElement = clone(updatedForm[key]);
			if (updatedFormElement.isNumber) updatedFormElement.value = 0;
			else if (updatedFormElement.datepicker) updatedFormElement.selected = null;
			else if (has(updatedFormElement, "options")) updatedFormElement.value = null;
			else updatedFormElement.value = "";

			updatedForm[key] = updatedFormElement;
			this.setState({ filterSelfTestForm: updatedForm });
		}
	};

	getUserDropdown = () => {
		const updatedUsers = clone(this.props.allUsers);
		const output = updatedUsers.map((user) => {
			return {
				value: user.id,
				label: user.lastname + " " + user.firstname,
			};
		});
		return output;
	};

	getLessonDropdown = () => {
		const updatedSelfTestLessons = sortBy(
			uniqBy(
				this.props.allSelfTests.map((selfTest) => selfTest.chapter.lesson),
				"id",
			),
			"description",
		);
		const output = updatedSelfTestLessons.map((selfTestLesson) => {
			return {
				value: selfTestLesson.id,
				label: selfTestLesson.description,
			};
		});
		return output;
	};

	getSelfTestChapters = () => {
		const selfTestChapters = sortBy(
			uniqBy(
				this.props.allSelfTests.map((selfTest) => selfTest.chapter),
				"id",
			),
			"name",
		);
		const output = selfTestChapters.map((selfTestChapter) => {
			return {
				value: selfTestChapter.id,
				label: selfTestChapter.name + " | " + selfTestChapter.lesson.description,
			};
		});
		return output;
	};

	render() {
		const formElementsArray = [];
		for (let key in this.state.filterSelfTestForm) {
			formElementsArray.push({
				id: key,
				config: this.state.filterSelfTestForm[key],
			});
		}

		return (
			<Modal
				isOpen={this.props.isModalOpen}
				header={
					<>
						<em className="fas fa-filter float-left fa-1_2x mr-3" />
						Φίλτρα Αναζήτησης Test
					</>
				}
				headerBg="purple"
				size={MODAL_SIZE.lg}
				footer={
					<>
						<Button
							type="button"
							kind="success"
							onClick={this.onFormSubmit}
							disabled={!this.isFilterEntered()}
							text="Αναζήτηση"
						/>
						<Button
							type="button"
							kind="primary"
							onClick={this.onReset}
							id="reset-filters"
							text="Επαναφορά"
						/>
						<Button
							type="button"
							kind="secondary"
							text="Κλείσιμο"
							onClick={this.props.closeModal}
						/>
					</>
				}
				onClose={this.props.closeModal}
			>
				<form>
					<Row classes={["px-2"]}>
						{formElementsArray.map((element, idx) => {
							return (
								<Col
									xl={element.config.colSpan}
									lg={element.config.colSpan}
									md={6}
									sm={6}
									classes={["mb-4", "px-2"]}
									key={idx}
								>
									<label>
										{element.config.label} {element.config.required ? <span className="reqField">(*)</span> : null}
									</label>
									{element.config.datepicker ? (
										<BaseInput
											key={element.config.name + element.config.id}
											name={element.config.name}
											onChange={(selectedOption) => {
												this.onChangeHandler(selectedOption, element.id);
											}}
											datepicker={element.config.datepicker}
											selected={element.config.selected}
										/>
									) : (
										<>
											<BaseInput
												key={element.config.name + element.config.id}
												name={element.config.name}
												placeholder={element.config.placeholder}
												type={element.config.inputType}
												value={element.config.value}
												options={element.config.options}
												searchable={element.config.searchable}
												onChange={(selectedOption) => {
													this.onChangeHandler(selectedOption, element.id);
												}}
											/>
											{element.config.note != null ? (
												<small>
													<i className="fas fa-exclamation-circle text-info mr-1"></i>
													{element.config.note}
												</small>
											) : null}
										</>
									)}
								</Col>
							);
						})}
					</Row>
				</form>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		allDepartments: allDepartments(state),
		allGrades: allGrades(state),
		allSelfTests: allSelfTest(state),
		allStudents: allStudents(state),
		allUsers: allTeacherUsers(state),
	};
};

FilterSelfTestForm.propTypes = {
	allDepartments: PropTypes.arrayOf(PropTypes.exact(DepartmentShape)),
	allGrades: PropTypes.arrayOf(PropTypes.exact(GradeShape)),
	allSelfTests: PropTypes.arrayOf(PropTypes.exact(SelfTestSelectorShape)),
	allStudents: PropTypes.arrayOf(PropTypes.exact(StudentFromSelectorShape)),
	allUsers: PropTypes.arrayOf(PropTypes.exact(UserFromSelectorShape)),
	closeModal: PropTypes.func,
	setFilteredData: PropTypes.func,
	isDataFiltered: PropTypes.bool,
	isModalOpen: PropTypes.bool,
};

export default connect(mapStateToProps)(FilterSelfTestForm);
