import PropTypes from "prop-types";

const Spinner = ({ type }) => {
	switch (type) {
		case SpinnerTypes.BALL_SPIN_FADE_LOAD:
			return (
				<div className={SpinnerTypes.BALL_SPIN_FADE_LOAD}>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			);
		case SpinnerTypes.BALL_PULSE_SYNC:
			return (
				<div className={SpinnerTypes.BALL_PULSE_SYNC}>
					<div></div>
					<div></div>
					<div></div>
				</div>
			);
		default:
			console.error(
				`UI/Spinner/Spinner.js | line 23 :: Wrong prop type of spinner. Given "${type}" and it should be one of: `,
				Object.values(SpinnerTypes),
			);
			return null;
	}
};

export const SpinnerTypes = {
	BALL_SPIN_FADE_LOAD: "ball-spin-fade-loader",
	BALL_PULSE_SYNC: "ball-pulse-sync",
};

Spinner.propTypes = {
	type: PropTypes.oneOf(Object.values(SpinnerTypes)),
};

export default Spinner;
