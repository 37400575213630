import { StatusCodes } from "http-status-codes";
import { includes, isEmpty, uniq } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";

import { apiResponse } from "../../../../Redux/Actions/index";
import { allGrades } from "../../../../Redux/Selectors/gradesSelectors";
import { allStudents } from "../../../../Redux/Selectors/studentsSelectors";
import { getParametricAsOptions } from "../../../../Utils/GuiUtils";
import { PARAMETRIC_TABLES } from "../../../../Utils/ParametricTablesBinds";
import { clone, roundNumber, sortArrayOfObjectsByNumbers } from "../../../../Utils/Utils";
import BaseInput from "../../../UI/BaseInput/BaseInput";
import Button from "../../../UI/Button/Button";
import { Col, Row } from "../../../UI/Grid/Grid";
import { MODAL_SIZE } from "../../../UI/Modal/constants/ModalSize";
import Modal from "../../../UI/Modal/Modal";
import GradeReportPreview from "./GradeReportPreview";

class PrintReportForm extends Component {
	formRef = React.createRef();

	constructor(props) {
		super(props);
		this.state = {
			outputToRender: null,
			doExportStats: false,
			printReportForm: {
				grade_report_date_from: {
					label: "Ημ. Εξέτασης Από",
					name: "grade_report_date_from",
					placeholder: "Ημ. Εξέτασης Από",
					datepicker: true,
					colSpan: 3,
					selected: null,
					required: true,
				},
				grade_report_date_to: {
					label: "Ημ. Εξέτασης Έως",
					name: "grade_report_date_to",
					placeholder: "Ημ. Εξέτασης Έως",
					datepicker: true,
					colSpan: 3,
					selected: null,
					required: true,
				},
				grade_report_exam_kind: {
					label: "Είδη Εξέτασης",
					name: "grade_report_exam_kind",
					placeholder: "Επιλέξτε Είδη Εξέτασης",
					options: getParametricAsOptions(PARAMETRIC_TABLES.EXAM_KIND),
					isNumber: true,
					value: [],
					colSpan: 6,
				},
				grade_report_lesson: {
					label: "Μαθήματα",
					name: "grade_report_lesson",
					placeholder: "Επιλέξτε Μαθήματα",
					options: getParametricAsOptions(PARAMETRIC_TABLES.LESSONS),
					isNumber: true,
					value: [],
					colSpan: 6,
				},
				grade_report_class: {
					label: "Τάξεις",
					name: "grade_report_class",
					placeholder: "Επιλέξτε Τάξεις",
					options: getParametricAsOptions(PARAMETRIC_TABLES.CLASSES),
					required: true,
					isNumber: true,
					value: [],
					colSpan: 6,
				},
			},
			loading: false,
		};
	}

	onChangeHandler = (event, inputId) => {
		const updatedForm = clone(this.state.printReportForm);
		const updatedFormElement = clone(updatedForm[inputId]);
		//the below code is for the React Datepicker, so it has to be js Date object
		const updatedFormDateKeys = Object.values(updatedForm)
			.filter((item) => item.datepicker && item.selected !== null)
			.map((item) => item.name);
		updatedFormDateKeys.forEach((element) => {
			updatedForm[element].selected = new Date(updatedForm[element].selected);
		});

		if (updatedFormElement.isNumber) updatedFormElement.value = isEmpty(event.target.value) ? event.target.value : Number(event.target.value);
		else if (updatedFormElement.datepicker) {
			updatedFormElement.selected = event;
		} else updatedFormElement.value = event.target.value;

		updatedForm[inputId] = updatedFormElement;
		this.setState({ printReportForm: updatedForm });
	};

	onChangeClassesHandler = (selectedOption, name) => {
		const updatedForm = clone(this.state.printReportForm);
		const updatedFormElement = clone(updatedForm[name]);
		//the below code is for the React Datepicker, so it has to be js Date object
		const updatedFormDateKeys = Object.values(updatedForm)
			.filter((item) => item.datepicker && item.selected !== null)
			.map((item) => item.name);
		updatedFormDateKeys.forEach((element) => {
			updatedForm[element].selected = new Date(updatedForm[element].selected);
		});
		updatedFormElement.value = selectedOption;
		updatedForm[name] = updatedFormElement;
		this.setState({ printReportForm: updatedForm });
	};

	onFormSubmit = () => {
		this.setState({ loading: true });
		const output = {};
		for (let key in this.state.printReportForm) {
			if (key === "grade_report_date_to" || key === "grade_report_date_from")
				output[key] = moment(this.state.printReportForm[key].selected).format("YYYY-MM-DD");
			else if (this.state.printReportForm[key].options) {
				if (!isEmpty(this.state.printReportForm[key].value)) output[key] = this.state.printReportForm[key].value?.map((val) => val.value);
				else output[key] = [];
			} else {
				output[key] = this.state.printReportForm[key].value;
			}
		}
		let hasGrades = false;
		const outputToRender = [];
		this.props.allStudents.forEach((student) => {
			const studentGrades = sortArrayOfObjectsByNumbers(
				this.props.allGrades.filter((grade) => {
					return (
						grade.student.id === student.id &&
						moment(grade.exam_date).isSameOrAfter(moment(output.grade_report_date_from, "YYYY-MM-DD")) &&
						moment(grade.exam_date).isSameOrBefore(moment(output.grade_report_date_to, "YYYY-MM-DD")) &&
						(!isEmpty(output.grade_report_exam_kind) ? includes(output.grade_report_exam_kind, grade.exam_kind.id) : true) &&
						(!isEmpty(output.grade_report_lesson) ? includes(output.grade_report_lesson, grade.lesson.id) : true) &&
						includes(output.grade_report_class, grade.student.department.class.id)
					);
				}),
				"lesson_id",
			);
			const outputObj = {};
			outputObj.student = student;
			outputObj.lessonGrades = [];
			if (!isEmpty(studentGrades)) {
				hasGrades = true;
				//get uniq lesson id show i can seperate the grades per lesson
				const allLessonIds = uniq(studentGrades.map((grade) => grade.lesson_id));
				//forEach id do stuff with the grades of this lesson
				allLessonIds.forEach((lesson_id) => {
					const lessonGrades = studentGrades.filter((grade) => grade.lesson.id === lesson_id);
					let sum = 0;
					let cnt = 0;
					lessonGrades.forEach((lessonGrade) => {
						if (lessonGrade.grade !== null && lessonGrade.grade !== undefined) {
							sum += lessonGrade.grade;
							cnt++;
						}
					});
					const avg100 = sum !== 0 && cnt !== 0 ? roundNumber(sum / cnt, 1) : "0";
					const avg20 = sum !== 0 && cnt !== 0 ? roundNumber(((sum / cnt) * 20) / 100, 2) : "0";
					sum = 0;
					cnt = 0;
					outputObj.lessonGrades.push({
						lesson: lessonGrades[0].lesson,
						grades: lessonGrades,
						avg100: avg100,
						avg20: avg20,
					});
				});
			}
			outputToRender.push(outputObj);
		});

		this.setState({ outputToRender: outputToRender.filter((obj) => !isEmpty(obj.lessonGrades)) });
		if (!hasGrades) {
			const apiResponseMsg = {
				error: null,
				info: {
					message: (
						<>
							Δε βρέθηκε <b>καμία βαθμολογία</b> για το συνδυασμό τάξεων και ημερομηνιών που επιλέξατε.
						</>
					),
					code: StatusCodes.NO_CONTENT,
					timestamp: moment(),
				},
			};
			this.terminateExportMechanism();
			this.props.setApiResponse(apiResponseMsg);
		} else {
			this.disableAllFormFields();
		}
		this.setState({ loading: false });
	};

	emptyAllFormFields = () => {
		const updatedForm = clone(this.state.printReportForm);
		for (let key in updatedForm) {
			const updatedFormElement = clone(updatedForm[key]);
			if (!updatedFormElement.datepicker) updatedFormElement.value = "";

			if (updatedFormElement.name === "grade_report_date_to" || updatedFormElement.name === "grade_report_date_from")
				updatedFormElement.selected = null;

			updatedFormElement.disabled = false;
			updatedForm[key] = updatedFormElement;
		}
		this.setState({
			printReportForm: updatedForm,
		});
	};

	disableAllFormFields = () => {
		const updatedForm = clone(this.state.printReportForm);
		for (let key in updatedForm) {
			const updatedFormElement = clone(updatedForm[key]);
			const updatedFormDateKeys = Object.values(updatedForm)
				.filter((item) => item.datepicker && item.selected !== null)
				.map((item) => item.name);
			updatedFormDateKeys.forEach((element) => {
				updatedForm[element].selected = new Date(updatedForm[element].selected);
			});
			updatedFormElement.disabled = true;
			updatedForm[key] = updatedFormElement;
			this.setState({
				printReportForm: updatedForm,
			});
		}
	};

	terminateExportMechanism = () => {
		this.emptyAllFormFields();
		this.setState({
			outputToRender: null,
			doExportStats: false,
		});
		this.props.closeModal();
	};

	render() {
		const formElementsArray = [];
		for (let key in this.state.printReportForm) {
			formElementsArray.push({
				id: key,
				config: this.state.printReportForm[key],
			});
		}

		return (
			<Modal
				isOpen={this.props.isModalOpen}
				header={
					<>
						<em className="fas fa-print float-left fa-1_2x mr-3" />
						Εκτύπωση Αναφοράς Βαθμολογιών
					</>
				}
				headerBg="green"
				onClose={this.terminateExportMechanism}
				loading={this.state.loading}
				size={MODAL_SIZE.lg}
				footer={
					<>
						{this.state.outputToRender === null ? (
							<Button
								type="button"
								kind="info"
								onClick={this.onFormSubmit}
								disabled={
									isEmpty(this.state.printReportForm.grade_report_class.value) ||
									this.state.printReportForm.grade_report_date_from.selected === null ||
									this.state.printReportForm.grade_report_date_to.selected === null
								}
								text="Προετοιμασία Δεδομένων"
							/>
						) : (
							<Button
								type="button"
								kind="success"
								onClick={() => this.setState({ doExportStats: true })}
								disabled={
									isEmpty(this.state.printReportForm.grade_report_class.value) ||
									this.state.printReportForm.grade_report_date_from.selected === null ||
									this.state.printReportForm.grade_report_date_to.selected === null
								}
								text="Εξαγωγή Στατιστικών"
							/>
						)}
						<Button
							type="button"
							kind="secondary"
							onClick={this.terminateExportMechanism}
							text="Κλείσιμο"
						/>
					</>
				}
			>
				<form ref={this.formRef}>
					{this.state.outputToRender !== null && (
						<GradeReportPreview
							outputToRender={this.state.outputToRender}
							doExportStats={this.state.doExportStats}
							terminateExportMechanism={this.terminateExportMechanism}
						/>
					)}
					<Row classes={["px-2"]}>
						{formElementsArray.map((element, idx) => {
							return (
								<Col
									xl={element.config.colSpan}
									lg={element.config.colSpan}
									classes={["mb-4", "px-2"]}
									key={idx}
								>
									<label>
										{element.config.label} {element.config.required ? <span className="reqField">(*)</span> : null}
									</label>
									{element.config.datepicker ? (
										<BaseInput
											key={element.config.name + element.config.id}
											name={element.config.name}
											onChange={(event) => {
												this.onChangeHandler(event, element.id);
											}}
											datepicker={element.config.datepicker}
											selected={element.config.selected}
											required={element.config.required}
											disabled={element.config.disabled}
										/>
									) : element.config.options ? (
										<Select
											value={element.config.value}
											onChange={(selected) => this.onChangeClassesHandler(selected, element.config.name)}
											isMulti
											options={element.config.options}
											placeholder={element.config.placeholder}
											isDisabled={element.config.disabled}
										/>
									) : (
										<BaseInput
											key={element.config.name + element.config.id}
											maxLength={element.config.maxLength}
											name={element.config.name}
											placeholder={element.config.placeholder}
											disabled={element.config.disabled}
											type={element.config.inputType}
											value={element.config.value}
											onChange={(event) => {
												this.onChangeHandler(event, element.id);
											}}
											required={element.config.required}
										/>
									)}
								</Col>
							);
						})}
					</Row>
					{this.state.outputToRender !== null && (
						<Row classes={["px-2", "mt-3"]}>
							<Col classes={["bg-light", "p-2", "shadow-sm", "border", "border-success"]}>
								<i className="fas fa-info-circle mr-2 text-success"></i>Τα δεδομένα είναι έτοιμα προς εξαγωγή. Παρακαλώ επιλέξτε "Εξαγωγή
								Στατιστικών".
							</Col>
						</Row>
					)}
				</form>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		allGrades: allGrades(state),
		allStudents: allStudents(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setApiResponse: (theApiResponse) => dispatch(apiResponse(theApiResponse)),
	};
};

PrintReportForm.propTypes = {
	closeModal: PropTypes.func,
	fetchGradesReport: PropTypes.func,
	isModalOpen: PropTypes.bool,
	setApiResponse: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrintReportForm);
