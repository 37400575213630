import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import { StudentFromSelectorShape } from "../../../Models/StudentShape";
import { allStudents } from "../../../Redux/Selectors/studentsSelectors";
import { Col, Row } from "../../UI/Grid/Grid";
import GradesTab from "./TabsContent/GradesTab/GradesTab";
import StatisticsTab from "./TabsContent/StatisticsTab.js/StatisticsTab";

class GradesView extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		document.title = process.env.REACT_APP_NAME;
	}

	render() {
		if (isEmpty(this.props.allStudents)) {
			return (
				<Row>
					<Col>
						<p className="text-danger text-bold text-center">
							Δε βρέθηκαν στοιχεία που αφορούν στο προφίλ σας για το συγκεκριμένο σχολικό έτος και περίοδο μαθημάτων.
						</p>
					</Col>
				</Row>
			);
		} else {
			return (
				<>
					<Tabs>
						<TabList>
							<Tab>Βαθμολογίες</Tab>
							<Tab>Στατιστικά</Tab>
						</TabList>
						<TabPanel>
							<GradesTab />
						</TabPanel>
						<TabPanel>
							<Row>
								<StatisticsTab />
							</Row>
						</TabPanel>
					</Tabs>
				</>
			);
		}
	}
}

const mapStateToProps = (state) => {
	return {
		allStudents: allStudents(state),
	};
};

GradesView.propTypes = {
	allStudents: PropTypes.arrayOf(PropTypes.exact(StudentFromSelectorShape)),
};

export default connect(mapStateToProps)(GradesView);
