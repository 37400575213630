import PropTypes from "prop-types";
import { StudentFromReduxShape } from "./StudentShape";
import { LessonShape } from "./LessonShape";
import { ParametricShape } from "./ParametricShape";

export const PanhellenicExaminationsResultGradesShape = {
	lesson: PropTypes.shape(LessonShape),
	grade: PropTypes.number,
};

export const PanhellenicExaminationsShape = {
	created_at: PropTypes.string.isRequired,
	final_result: PropTypes.number.isRequired,
	final_result_with_special_lessons: PropTypes.number,
	final_result_with_factor_2: PropTypes.number,
	id: PropTypes.number.isRequired,
	student_id: PropTypes.number.isRequired,
	student: PropTypes.shape(StudentFromReduxShape).isRequired,
	scientific_field: PropTypes.shape(ParametricShape),
	scientific_field_id: PropTypes.number,
	grades: PropTypes.arrayOf(PropTypes.shape(PanhellenicExaminationsResultGradesShape)).isRequired,
	average: PropTypes.number.isRequired,
	flGrades: PropTypes.arrayOf(PropTypes.shape(PanhellenicExaminationsResultGradesShape)),
	designGrades: PropTypes.arrayOf(PropTypes.shape(PanhellenicExaminationsResultGradesShape)),
	updated_at: PropTypes.string,
};
