import PropTypes from "prop-types";

const Badge = ({ classes, kind, text }) => {
	return <span className={`badge badge-${kind} ${classes ? classes.join(" ") : ""}`}>{text}</span>;
};

Badge.propTypes = {
	classes: PropTypes.array,
	kind: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
};

export default Badge;
