import { getResource, postResource, putResource } from "../../BackendAPI/AbstractCRUDActions";
import { ActionKinds } from "../../BackendAPI/ActionKinds";
import { getSelfTestsUrl, getStudentSelfTestsUrl } from "../../BackendAPI/BackendAPI";
import {
	ADD_NEW_SELF_TEST_FAIL,
	ADD_NEW_SELF_TEST_START,
	ADD_NEW_SELF_TEST_SUCCESS,
	FETCH_ALL_SELF_TESTS_FAIL,
	FETCH_ALL_SELF_TESTS_START,
	FETCH_ALL_SELF_TESTS_SUCCESS,
	FETCH_CURRENT_STUDENT_SELF_TESTS_FAIL,
	FETCH_CURRENT_STUDENT_SELF_TESTS_START,
	FETCH_CURRENT_STUDENT_SELF_TESTS_SUCCESS,
	UPDATE_SELF_TEST_FAIL,
	UPDATE_SELF_TEST_START,
	UPDATE_SELF_TEST_SUCCESS,
} from "./actionTypes";

export const fetchAllSelfTestsStart = () => {
	return {
		type: FETCH_ALL_SELF_TESTS_START,
	};
};

export const fetchAllSelfTestsSuccess = (selfTestsData) => {
	return {
		type: FETCH_ALL_SELF_TESTS_SUCCESS,
		payload: selfTestsData,
	};
};

export const fetchAllSelfTestsFail = (error) => {
	return {
		type: FETCH_ALL_SELF_TESTS_FAIL,
		payload: error,
	};
};

export const fetchAllSelfTests = () => {
	return (dispatch) => {
		dispatch(fetchAllSelfTestsStart());
		const url = getSelfTestsUrl(ActionKinds.GET);
		getResource(url)
			.then((response) => {
				dispatch(fetchAllSelfTestsSuccess(response.data));
			})
			.catch((error) => {
				dispatch(fetchAllSelfTestsFail(error));
			});
	};
};

export const fetchCurrentStudentSelfTestsStart = () => {
	return {
		type: FETCH_CURRENT_STUDENT_SELF_TESTS_START,
	};
};

export const fetchCurrentStudentSelfTestsSuccess = (selfTestsData) => {
	return {
		type: FETCH_CURRENT_STUDENT_SELF_TESTS_SUCCESS,
		payload: selfTestsData,
	};
};

export const fetchCurrentStudentSelfTestsFail = (error) => {
	return {
		type: FETCH_CURRENT_STUDENT_SELF_TESTS_FAIL,
		payload: error,
	};
};

export const fetchCurrentStudentSelfTests = () => {
	return (dispatch) => {
		dispatch(fetchCurrentStudentSelfTestsStart());
		const url = getStudentSelfTestsUrl(ActionKinds.GET_ONE);
		getResource(url)
			.then((response) => {
				dispatch(fetchCurrentStudentSelfTestsSuccess(response.data));
			})
			.catch((error) => {
				dispatch(fetchCurrentStudentSelfTestsFail(error));
			});
	};
};

export const addNewSelfTestStart = () => {
	return {
		type: ADD_NEW_SELF_TEST_START,
	};
};
export const addNewSelfTestSuccess = (newSelfTestData) => {
	return {
		type: ADD_NEW_SELF_TEST_SUCCESS,
		payload: newSelfTestData,
	};
};

export const addNewSelfTestFail = (error) => {
	return {
		type: ADD_NEW_SELF_TEST_FAIL,
		payload: error,
	};
};

export const addNewSelfTest = (newSelfTestData) => {
	return (dispatch) => {
		dispatch(addNewSelfTestStart());
		const url = getSelfTestsUrl(ActionKinds.POST);
		return postResource(url, newSelfTestData);
	};
};

export const updateSelfTestStart = () => {
	return {
		type: UPDATE_SELF_TEST_START,
	};
};

export const updateSelfTestSuccess = (selfTestData) => {
	return {
		type: UPDATE_SELF_TEST_SUCCESS,
		payload: selfTestData,
	};
};

export const updateSelfTestFail = (error) => {
	return {
		type: UPDATE_SELF_TEST_FAIL,
		payload: error,
	};
};

export const updateSelfTest = (selfTestData, selfTestId) => {
	return (dispatch) => {
		dispatch(updateSelfTestStart());
		const url = getSelfTestsUrl(ActionKinds.UPDATE, selfTestId);
		return putResource(url, selfTestData);
	};
};
