import { SET_CHANGE_PASSWORD_MODAL_CLOSE, SET_CHANGE_PASSWORD_MODAL_OPEN } from "./actionTypes";

export const setChangePasswordModalOpen = (userId) => {
	return {
		type: SET_CHANGE_PASSWORD_MODAL_OPEN,
		payload: { userId },
	};
};

export const setChangePasswordModalClose = () => {
	return {
		type: SET_CHANGE_PASSWORD_MODAL_CLOSE,
	};
};
