import $ from "jquery";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Logo from "../../assets/images/students_app_logo.png";
import LogoSm from "../../assets/images/students_app_logo_sm.png";

import Button from "../../Components/UI/Button/Button";
import { PathEntry } from "../../navigation";
import { fetchUniverseCheckingPermissions } from "../../Redux/Actions/index";
import { getAuthData } from "../../Utils/AuthUtils";
import { permissions } from "../../Utils/ParametricTablesBinds";
import UserDataBox from "./UserDataBox/UserDataBox";

const getRedirectUrl = () => {
	if (Number(getAuthData().permission) === permissions.ADMIN) {
		return "https://drive.google.com/file/d/1J_STiTXyea7mCgeiRfGpJ6vcQ_pEqZuZ/view?usp=sharing";
	} else if (Number(getAuthData().permission) === permissions.STUDENT) {
		return "https://drive.google.com/file/d/1tsDU4XE_rxYD9ZGn-43LTjcoLKqVPfUH/view?usp=sharing";
	} else {
		return "https://drive.google.com/file/d/10wzx3lAbSn0nUlcVcOTmnwVtbGSWc0E1/view?usp=sharing";
	}
};

const TopBar = (props) => {
	useEffect(() => {
		const topBar = $(".topnavbar-wrapper");
		$(window).on("scroll", function () {
			if ($(window).scrollTop() > 50) {
				topBar.addClass("shadow");
			} else {
				topBar.removeClass("shadow");
			}
		});
	}, []);

	return (
		<header className="topnavbar-wrapper">
			<nav className="navbar topnavbar">
				<div className="navbar-header">
					<a
						className="navbar-brand"
						href="#/"
					>
						<div className="brand-logo">
							<img
								src={Logo}
								alt=" AppLogo"
								style={{ maxWidth: "100%" }}
							/>
						</div>
						<div className="brand-logo-collapsed">
							<img
								src={LogoSm}
								alt=" AppLogo"
							/>
						</div>
					</a>
				</div>
				<ul className="navbar-nav mr-auto flex-row">
					<li className="nav-item">
						<Button
							onClick={(event) => hideSidebar(event)}
							classes={["nav-link", "d-none", "d-md-block", "d-lg-block", "d-xl-block"]}
							kind="link"
							type="button"
							style={{ borderRadius: "0" }}
							iconClass="fas fa-bars"
							id="sidebarController"
						/>
						{
							<Button
								onClick={hideSidebarSm}
								classes={["nav-link", "d-md-none", "sidebar-toggle"]}
								kind="link"
								type="button"
								style={{ borderRadius: "0" }}
								iconClass="fas fa-bars"
							/>
						}
					</li>
				</ul>
				<ul className="navbar-nav flex-row">
					<li className="nav-item dropdown dropdown-list">
						<Button
							classes={["nav-link", "dropdown-toggle", "dropdown-toggle-nocaret"]}
							type="button"
							kind="link"
							style={{ borderRadius: "0" }}
							iconClass="icon-people mr-1"
							text={getAuthData().username}
							data-toggle="dropdown"
						/>
						<UserDataBox />
					</li>
					<li
						className="nav-item"
						data-tip="Εγχειρίδιο Εφαρμογής"
						data-place="bottom"
						data-for="top-bar-tooltip"
					>
						<Button
							onClick={() => window.open(getRedirectUrl(), "_blank")}
							classes={["nav-link", "d-none", "d-md-block", "d-lg-block", "d-xl-block"]}
							kind="link"
							type="button"
							style={{ borderRadius: "0" }}
							iconClass="fas fa-book-open"
							id="viewAppManuel"
						/>
					</li>
					<li
						className="nav-item"
						data-tip="Επικαιροποίηση Δεδομένων"
						data-for="top-bar-refresh-tooltip"
						data-place="left"
					>
						<Button
							onClick={() => props.fetchUniverseCheckingPermissions()}
							classes={["nav-link", "d-none", "d-md-block", "d-lg-block", "d-xl-block"]}
							kind="link"
							type="button"
							style={{ borderRadius: "0" }}
							iconClass="fas fa-sync-alt"
							id="updateDataBtn"
						/>
					</li>
					<li
						className="nav-item"
						data-tip="Αποσύνδεση"
						data-for="top-bar-tooltip"
						data-place="left"
					>
						<NavLink
							className="nav-link btn btn-link"
							to={PathEntry.logout.path}
							exact
						>
							<em className="fas fa-sign-out-alt"></em>
						</NavLink>
					</li>
					<ReactTooltip
						id="top-bar-tooltip"
						effect="solid"
					/>
					<ReactTooltip
						id="top-bar-refresh-tooltip"
						effect="solid"
					/>
				</ul>
			</nav>
		</header>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchUniverseCheckingPermissions: () => dispatch(fetchUniverseCheckingPermissions()),
	};
};

const hideSidebar = (e) => {
	$("body").toggleClass("aside-collapsed");
	if ($("body").hasClass("aside-collapsed")) {
		$(".aside-container").removeClass("lowZindex");
	} else {
		$(".aside-container").addClass("lowZindex");
	}
};

const hideSidebarSm = () => {
	$("body").toggleClass("aside-toggled");
};

export default connect(null, mapDispatchToProps)(TopBar);
