import React, { Component } from "react";
import { isEmpty, isEqual } from "lodash";
import PropTypes from "prop-types";

import { SelfTestChapterSelectorShape } from "../../../../../Models/SelfTestChapterShape";
import { clone } from "../../../../../Utils/Utils";
import { Row, Col } from "../../../../UI/Grid/Grid";
import BaseInput from "../../../../UI/BaseInput/BaseInput";

class SelfTestChapterFilterPanel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			chapterFilterForm: {},
		};
	}

	componentDidMount() {
		this.setState({
			chapterFilterForm: this.initData(),
		});
	}

	componentDidUpdate(prevProps) {
		if (!isEqual(this.props.chaptersSelected, prevProps.chaptersSelected) && !isEmpty(this.props.chaptersSelected)) {
			this.setState({
				chapterFilterForm: this.initData(),
			});
		}
	}

	onChangeCheckboxHandler = (selectedOption, inputId) => {
		const updatedForm = clone(this.state.chapterFilterForm);
		const updatedFormElement = clone(updatedForm[inputId]);

		if (this.countCheckedChaptersInState() > 1 || !updatedFormElement.isChecked) {
			if (this.state.chapterFilterForm[inputId].value === Number(selectedOption.target.value)) {
				updatedFormElement.isChecked = !updatedFormElement.isChecked;
				updatedForm[inputId] = updatedFormElement;
				this.setState({ chapterFilterForm: updatedForm }, () => {
					const chapterIds = [];
					for (let property in this.state.chapterFilterForm) {
						this.state.chapterFilterForm[property].isChecked && chapterIds.push(this.state.chapterFilterForm[property].value);
					}
					this.props.setChapterSelected(chapterIds);
				});
			}
		}
	};

	countCheckedChaptersInState = () => {
		let count = 0;
		for (let i in this.state.chapterFilterForm) {
			if (this.state.chapterFilterForm[i].isChecked) count++;
		}
		return count;
	};

	initData = () => {
		const stateData = {};
		this.props.chaptersSelected.forEach((elem) => {
			stateData[elem.name] = {
				label: elem.name,
				name: elem.name,
				inputType: "checkbox",
				value: elem.id,
				isChecked: true,
			};
		});
		return stateData;
	};

	render() {
		const formElementsArray = [];
		for (let key in this.state.chapterFilterForm) {
			formElementsArray.push({
				id: key,
				config: this.state.chapterFilterForm[key],
			});
		}

		return (
			<Row classes={["px-2", "bt", "pt-3"]}>
				<Col classes={["px-2", "mb-1"]}>
					<label>Ενότητα</label>
				</Col>
				{formElementsArray.map((element, idx) => {
					return (
						<Col
							classes={idx !== formElementsArray.length - 1 ? ["mb-3"] : []}
							key={"SelfTestChapterFilterPanel" + idx}
						>
							<BaseInput
								label={element.config.label}
								checked={element.config.isChecked}
								name={element.config.name}
								placeholder={element.config.placeholder}
								searchable={element.config.searchable}
								options={element.config.options}
								type={element.config.inputType}
								value={element.config.value}
								onChange={(selectedOption) => {
									this.onChangeCheckboxHandler(selectedOption, element.id);
								}}
							/>
						</Col>
					);
				})}
			</Row>
		);
	}
}

SelfTestChapterFilterPanel.propTypes = {
	chaptersSelected: PropTypes.arrayOf(PropTypes.exact(SelfTestChapterSelectorShape)),
	setChapterSelected: PropTypes.func,
};

export default SelfTestChapterFilterPanel;
