import { isEmpty, startsWith, uniqBy } from "lodash";
import moment from "moment";
import "moment/locale/el";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import Switch from "react-switch";
import ReactTooltip from "react-tooltip";

import { UserFromSelectorShape } from "../../../Models/UserShape";
import { setChangePasswordModalOpen } from "../../../Redux/Actions";
import { allUsers } from "../../../Redux/Selectors/userSelectors";
import { getParametricAsOptions, openModal } from "../../../Utils/GuiUtils";
import { permissions, STATUSES } from "../../../Utils/ParametricTablesBinds";
import { clone } from "../../../Utils/Utils";
import Badge from "../../UI/Badge/Badge";
import BaseInput from "../../UI/BaseInput/BaseInput";
import Button from "../../UI/Button/Button";
import Card from "../../UI/Card/Card";
import Datatable from "../../UI/Datatable/Datatable";
import { Col, Row } from "../../UI/Grid/Grid";
import ManageUserForm from "./Forms/ManageUserForm";

const columns = [
	{
		name: "Α/Α",
		selector: (row) => row.id,
		sortable: true,
		width: "75px",
	},
	{
		name: "Όνομα",
		selector: (row) => row.firstname,
		sortable: true,
		wrap: true,
	},
	{
		name: "Επίθετο",
		selector: (row) => row.lastname,
		sortable: true,
		wrap: true,
	},
	{
		name: "Ψευδώνυμο",
		selector: (row) => row.username,
		sortable: true,
		wrap: true,
	},
	{
		name: "Email",
		selector: (row) => row.email,
		format: (row) => <a href={`mailto:${row.email}`}>{row.email}</a>,
		sortable: true,
		wrap: true,
		grow: 2,
	},
	{
		name: "Κατάσταση",
		selector: (row) => row.status.description,
		format: (row) => (
			<Badge
				kind={row.status.id === STATUSES.DISABLED ? "danger" : "success"}
				text={row.status.description}
			/>
		),
		sortable: true,
		width: "135px",
	},
	{
		name: "Ρόλος",
		selector: (row) => row.permission.description,
		format: (row) => {
			let kind = "primary";
			if (row.permission.id === permissions.TEACHER) {
				kind = "green";
			} else if (row.permission.id === permissions.STUDENT) {
				kind = "pink";
			}
			return (
				<Badge
					kind={kind}
					text={row.permission.description}
				/>
			);
		},
		sortable: true,
		width: "130px",
	},
	{
		name: "Τελ. Δραστηριότητα",
		selector: (row) => row.last_activity,
		format: (row) => <>{row.last_activity ? moment(row.last_activity).format("ddd, DD/MM/YYYY HH:mm:ss") : ""}</>,
		sortable: true,
		wrap: true,
	},
	{
		name: "Ημ. Εγγραφής",
		selector: (row) => row.created_at,
		render: (row) => <>{row.created_at ? moment(row.created_at).format("ddd, DD/MM/YYYY") : ""}</>,
		omit: true,
	},
	{
		name: "Σταθερό Τηλέφωνο",
		selector: (row) => row.phone,
		omit: true,
	},
	{
		name: "Κινητό Τηλέφωνο",
		selector: (row) => row.mobile_phone,
		omit: true,
	},
	{
		name: "Διεύθυνση",
		selector: (row) => row.address,
		omit: true,
	},
	{
		name: "Πόλη",
		selector: (row) => row.city,
		omit: true,
	},
	{
		name: "Ταχ. Κώδικας",
		selector: (row) => row.zip,
		omit: true,
	},
];

class UserManagement extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterText: "",
			filteredData: [],
			isModalOpen: false,
			isUserEnabledFilter: false,
			loading: false,
			modalReadonly: false,
			permissionFilter: null,
			selectedRow: [],
		};
		this.toggledClearRows = false;
	}

	componentDidMount() {
		document.title = process.env.REACT_APP_NAME + " :: Διαχείριση Χρηστών";
	}

	selectedRow = (data) => {
		this.setState({ selectedRow: data.selectedRows });
	};

	clearSelectedRows = () => {
		this.toggledClearRows = !this.toggledClearRows;
		this.setState({ selectedRow: [] });
	};

	resetAllFilters = () => {
		this.setState({
			filterText: "",
			filteredData: [],
			isUserEnabledFilter: false,
			permissionFilter: null,
		});
		this.clearSelectedRows();
	};

	setFilter = (filterString) => {
		const filteredItems = this.props.allUsers.filter((user) => {
			return (
				`${user.lastname} ${user.firstname}`.toLowerCase().includes(filterString.toLowerCase()) ||
				`${user.firstname} ${user.lastname}`.toLowerCase().includes(filterString.toLowerCase())
			);
		});
		const filteredItems2 = this.props.allUsers.filter((user) => user.username.toLowerCase().includes(filterString.toLowerCase()));
		const filteredItems3 = this.props.allUsers.filter((user) => user.email?.toLowerCase().includes(filterString.toLowerCase()));
		const filteredItems4 = this.props.allUsers.filter((user) => user.permission.description.toLowerCase().includes(filterString.toLowerCase()));
		const filteredItems5 = this.props.allUsers.filter((user) => startsWith(user.status.description.toLowerCase(), filterString.toLowerCase()));

		this.setState({
			filterText: filterString,
			filteredData: uniqBy(filteredItems.concat(filteredItems2, filteredItems3, filteredItems4, filteredItems5), "username"),
		});
	};

	openModal = (id, readonly = false) => {
		openModal(id, "usersCard");
		this.setState({ modalReadonly: readonly });
	};

	onIsUserEnabledFilterChange = (isUserEnabledFilter) => {
		this.setState({ isUserEnabledFilter }, () => this.clearSelectedRows());
	};

	onUserPermissionChange = (selectedOption) => {
		this.setState({ permissionFilter: selectedOption }, () => this.clearSelectedRows());
	};

	render() {
		const tableData = (() => {
			let output = clone(this.props.allUsers);
			if (!isEmpty(this.state.filteredData)) {
				output = this.state.filteredData;
			}
			if (this.state.isUserEnabledFilter) output = output.filter((user) => user.status.id === STATUSES.ENABLED);
			if (this.state.permissionFilter !== null) output = output.filter((user) => user.permission.id === this.state.permissionFilter.value);
			return output;
		})();

		return (
			<>
				<Card
					title="Πίνακας Χρηστών Εφαρμογής"
					headerSpannig={[8, 4]}
					subTitle={
						<>
							<div className="d-flex align-items-center justify-content-center justify-content-lg-start my-2">
								<label className="mr-3 mb-0 d-flex">
									<span className="mr-1 font-weight-600 unselectable">Ενεργός</span>
									<Switch
										onChange={this.onIsUserEnabledFilterChange}
										checked={this.state.isUserEnabledFilter}
										height={20}
										width={40}
										uncheckedIcon={false}
										checkedIcon={false}
									/>
								</label>
								<BaseInput
									placeholder="Επιλογή ρόλου"
									name="permission"
									value={this.state.permissionFilter}
									options={getParametricAsOptions("permissions")}
									searchable={false}
									clearable
									onChange={(selectedOption) => {
										this.onUserPermissionChange(selectedOption);
									}}
									styles={{ container: (base) => ({ ...base, width: 200 }) }}
								/>
							</div>
						</>
					}
					extraOnHeader={
						<>
							<Row>
								<Col classes={["mb-2", "px-2", "text-center", "text-lg-right"]}>
									<Button
										oval
										type="button"
										iconClass="fas fa-user-plus"
										kind="primary"
										onClick={() => this.setState({ isModalOpen: true, modalReadonly: false })}
										disabled={!isEmpty(this.state.selectedRow)}
										id="btn"
										classes={["m-1"]}
										data-tip="Προθήκη Χρήστη"
									/>
									<Button
										oval
										type="button"
										iconClass="fas fa-user-edit"
										kind="info"
										onClick={() => this.setState({ isModalOpen: true, modalReadonly: false })}
										id="btn"
										classes={["m-1"]}
										disabled={this.state.selectedRow.length === 0}
										data-tip="Επεξεργασία Χρήστη"
									/>
									<Button
										oval
										type="button"
										iconClass="fas fa-eye"
										kind="green"
										onClick={() => this.setState({ isModalOpen: true, modalReadonly: true })}
										id="btn"
										classes={["m-1"]}
										disabled={this.state.selectedRow.length === 0}
										data-multiline={true}
										data-tip="Προβολή Στοιχείων<br/>Χρήστη"
									/>
									<Button
										oval
										type="button"
										iconClass="fas fa-key"
										kind="purple"
										onClick={() => this.props.setChangePasswordModalOpen(this.state.selectedRow[0].id)}
										id="btn"
										classes={["m-1"]}
										disabled={this.state.selectedRow.length === 0}
										data-place="left"
										data-tip="Αλλαγή Συνθηματικού"
									/>
								</Col>
							</Row>
						</>
					}
					id="usersCard"
				>
					<Datatable
						columns={columns}
						defaultSortFieldId={3}
						exportCsv
						exportFilename="Πίνακας Χρηστών Μαθητολογίου Πολύτροπο"
						filteredData={this.state.filteredData}
						filterText={this.state.filterText}
						onClear={() => {
							this.setState({ filterText: "", filteredData: [] });
							this.clearSelectedRows();
						}}
						onFilter={(e) => {
							this.setFilter(e.target.value);
							this.clearSelectedRows();
						}}
						rowData={tableData}
						selectable
						selectedRows={this.state.selectedRow}
						setSelectedRow={this.selectedRow}
						toggledClearRows={this.toggledClearRows}
					/>
					<ManageUserForm
						user={!isEmpty(this.state.selectedRow) ? this.state.selectedRow[0] : null}
						resetGridFilters={this.resetAllFilters}
						readonly={this.state.modalReadonly}
						isModalOpen={this.state.isModalOpen}
						closeModal={() => this.setState({ isModalOpen: false })}
					/>
					<ReactTooltip effect="solid" />
				</Card>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		allUsers: allUsers(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setChangePasswordModalOpen: (userId) => dispatch(setChangePasswordModalOpen(userId)),
	};
};

UserManagement.propTypes = {
	allUsers: PropTypes.arrayOf(PropTypes.exact(UserFromSelectorShape)),
	fetchParametrics: PropTypes.func,
	setChangePasswordModalOpen: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
