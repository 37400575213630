import { deleteResource, getResource, postResource, putResource } from "../../BackendAPI/AbstractCRUDActions";
import { ActionKinds } from "../../BackendAPI/ActionKinds";
import { getCurrentStudentUserDepartmentsUrl, getCurrentTeacherDepartmentsUrl, getDepartmentsUrl } from "../../BackendAPI/BackendAPI";
import {
	ADD_NEW_DEPARTMENT_FAIL,
	ADD_NEW_DEPARTMENT_START,
	ADD_NEW_DEPARTMENT_SUCCESS,
	DELETE_DEPARTMENT_FAIL,
	DELETE_DEPARTMENT_START,
	DELETE_DEPARTMENT_SUCCESS,
	FETCH_ALL_DEPARTMENTS_FAIL,
	FETCH_ALL_DEPARTMENTS_START,
	FETCH_ALL_DEPARTMENTS_SUCCESS,
	FETCH_CURRENT_STUDENT_USER_DEPARTMENTS_FAIL,
	FETCH_CURRENT_STUDENT_USER_DEPARTMENTS_START,
	FETCH_CURRENT_STUDENT_USER_DEPARTMENTS_SUCCESS,
	FETCH_CURRENT_TEACHER_DEPARTMENTS_FAIL,
	FETCH_CURRENT_TEACHER_DEPARTMENTS_START,
	FETCH_CURRENT_TEACHER_DEPARTMENTS_SUCCESS,
	UPDATE_DEPARTMENT_FAIL,
	UPDATE_DEPARTMENT_START,
	UPDATE_DEPARTMENT_SUCCESS,
} from "./actionTypes";

export const fetchAllDepartmentsStart = () => {
	return {
		type: FETCH_ALL_DEPARTMENTS_START,
	};
};

export const fetchAllDepartmentsSuccess = (departmentsData) => {
	return {
		type: FETCH_ALL_DEPARTMENTS_SUCCESS,
		payload: departmentsData,
	};
};

export const fetchAllDepartmentsFail = (error) => {
	return {
		type: FETCH_ALL_DEPARTMENTS_FAIL,
		payload: error,
	};
};

export const fetchAllDepartments = () => {
	return (dispatch) => {
		dispatch(fetchAllDepartmentsStart());
		const url = getDepartmentsUrl(ActionKinds.GET);
		getResource(url)
			.then((response) => {
				dispatch(fetchAllDepartmentsSuccess(response.data));
			})
			.catch((error) => {
				dispatch(fetchAllDepartmentsFail(error));
			});
	};
};

export const fetchCurrentTeacherDepartmentsStart = () => {
	return {
		type: FETCH_CURRENT_TEACHER_DEPARTMENTS_START,
	};
};

export const fetchCurrentTeacherDepartmentsSuccess = (departmentsData) => {
	return {
		type: FETCH_CURRENT_TEACHER_DEPARTMENTS_SUCCESS,
		payload: departmentsData,
	};
};

export const fetchCurrentTeacherDepartmentsFail = (error) => {
	return {
		type: FETCH_CURRENT_TEACHER_DEPARTMENTS_FAIL,
		payload: error,
	};
};

export const fetchCurrentTeacherDepartments = () => {
	return (dispatch) => {
		dispatch(fetchCurrentTeacherDepartmentsStart());
		const url = getCurrentTeacherDepartmentsUrl(ActionKinds.GET);
		getResource(url)
			.then((response) => {
				dispatch(fetchCurrentTeacherDepartmentsSuccess(response.data));
			})
			.catch((error) => {
				dispatch(fetchCurrentTeacherDepartmentsFail(error));
			});
	};
};

export const fetchCurrentStudentUserDepartmentsStart = () => {
	return {
		type: FETCH_CURRENT_STUDENT_USER_DEPARTMENTS_START,
	};
};

export const fetchCurrentStudentUserDepartmentsSuccess = (departmentsData) => {
	return {
		type: FETCH_CURRENT_STUDENT_USER_DEPARTMENTS_SUCCESS,
		payload: departmentsData,
	};
};

export const fetchCurrentStudentUserDepartmentsFail = (error) => {
	return {
		type: FETCH_CURRENT_STUDENT_USER_DEPARTMENTS_FAIL,
		payload: error,
	};
};

export const fetchCurrentStudentUserDepartments = () => {
	return (dispatch) => {
		dispatch(fetchCurrentStudentUserDepartmentsStart());
		const url = getCurrentStudentUserDepartmentsUrl(ActionKinds.GET_ONE);
		getResource(url)
			.then((response) => {
				dispatch(fetchCurrentStudentUserDepartmentsSuccess(response.data));
			})
			.catch((error) => {
				dispatch(fetchCurrentStudentUserDepartmentsFail(error));
			});
	};
};

export const addNewDepartmentStart = () => {
	return {
		type: ADD_NEW_DEPARTMENT_START,
	};
};
export const addNewDepartmentSuccess = (newDepartmentData) => {
	return {
		type: ADD_NEW_DEPARTMENT_SUCCESS,
		payload: newDepartmentData,
	};
};

export const addNewDepartmentFail = (error) => {
	return {
		type: ADD_NEW_DEPARTMENT_FAIL,
		payload: error,
	};
};

export const addNewDepartment = (newDepartmentData) => {
	return (dispatch) => {
		dispatch(addNewDepartmentStart());
		const url = getDepartmentsUrl(ActionKinds.POST);
		return postResource(url, newDepartmentData);
	};
};

export const updateDepartmentStart = () => {
	return {
		type: UPDATE_DEPARTMENT_START,
	};
};

export const updateDepartmentSuccess = (departmentData) => {
	return {
		type: UPDATE_DEPARTMENT_SUCCESS,
		payload: departmentData,
	};
};

export const updateDepartmentFail = (error) => {
	return {
		type: UPDATE_DEPARTMENT_FAIL,
		payload: error,
	};
};

export const updateDepartment = (departmentData, departmentId) => {
	return (dispatch) => {
		dispatch(updateDepartmentStart());
		const url = getDepartmentsUrl(ActionKinds.UPDATE, departmentId);
		return putResource(url, departmentData);
	};
};

export const deleteDepartmentStart = () => {
	return {
		type: DELETE_DEPARTMENT_START,
	};
};

export const deleteDepartmentSuccess = (departmentData) => {
	return {
		type: DELETE_DEPARTMENT_SUCCESS,
		payload: departmentData,
	};
};

export const deleteDepartmentFail = (error) => {
	return {
		type: DELETE_DEPARTMENT_FAIL,
		payload: error,
	};
};

export const deleteDepartment = (departmentId) => {
	return (dispatch) => {
		dispatch(deleteDepartmentStart());
		const url = getDepartmentsUrl(ActionKinds.DELETE, departmentId);
		return deleteResource(url);
	};
};
