import axios from "axios";
import { createAxiosAuthHeader } from "./AuthUtils";

const axiosAuth = axios.create({ baseURL: process.env.REACT_APP_BASEURL });
const axiosPlain = axios.create({ baseURL: process.env.REACT_APP_BASEURL });

const DISABLE_CACHING = {
	"Cache-Control": "no-cache",
	Pragma: "no-cache",
	Expires: "0",
};

axiosPlain.defaults.headers = DISABLE_CACHING;
axiosAuth.defaults.headers = DISABLE_CACHING;

axiosAuth.interceptors.request.use(
	(config) => {
		Object.assign(config.headers, createAxiosAuthHeader());
		return config;
	},
	(error) => {
		return Promise.reject(`error: [${error}] while assigning auth header to axios request`);
	},
);

axiosPlain.interceptors.request.use(
	(config) => {
		Object.assign(config.headers);
		return config;
	},
	(error) => {
		return Promise.reject(`error: [${error}] while assigning auth header to axios request`);
	},
);

export { axiosAuth, axiosPlain };
