import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { isEmpty, uniq, sum } from "lodash";

import { absenceKindIds, examKindIds } from "../../../../Utils/ParametricTablesBinds";
import { GradeShape } from "../../../../Models/GradeShape";
import { ParametricShape } from "../../../../Models/ParametricShape";
import { sortArrayOfObjectsByDate } from "../../../../Utils/Utils";
import Card from "../../../UI/Card/Card";
import { Row, Col } from "../../../UI/Grid/Grid";
import BaseInput from "../../../UI/BaseInput/BaseInput";
import CustomChart from "../../../UI/Chart/CustomChart";

class LessonGradesStats extends Component {
	constructor(props) {
		super(props);
		this.state = {
			examKindSelectedId: -1,
			lessonSelectedId: -1,
		};
	}

	render() {
		const dates = uniq(sortArrayOfObjectsByDate(this.props.allGrades, "exam_date", "asc").map((grade) => grade.exam_date));
		const output = [];
		dates.forEach((date) => {
			const temp = this.props.allGrades
				.filter((grade) => grade.exam_date === date && grade.absence.id === absenceKindIds.PRESENT)
				.filter((grade) => (this.state.examKindSelectedId !== -1 ? grade.exam_kind.id === this.state.examKindSelectedId : grade))
				.filter((grade) => (this.state.lessonSelectedId !== -1 ? grade.lesson_id === this.state.lessonSelectedId : grade))
				.map((grade) => grade.grade);

			if (!isEmpty(temp))
				output.push({
					date: date,
					avg: (sum(temp) / temp.length).toFixed(1),
				});
		});

		return (
			<Card
				title="Μέσος Όρος / Είδος Εξέτασης"
				id="lessonGradesProgressCard"
				headerSpannig={[4, 8]}
				extraOnHeader={
					<Row align="center">
						<Col
							xl={6}
							lg={6}
							classes={["d-flex", "align-items-center", "mt-2", "mt-lg-0", "px-lg-2"]}
						>
							<label className="mb-0">Είδος:</label>
							<BaseInput
								name="chartExamKind"
								disabled={isEmpty(this.props.allGrades)}
								onChange={(selectedOption) => this.setState({ examKindSelectedId: Number(selectedOption.value) })}
								options={[
									{ label: "ΟΛΑ", value: -1 },
									{ label: "TEST", value: examKindIds.TEST },
									{ label: "ΔΙΑΓΩΝΙΣΜΑ", value: examKindIds.EXAM },
									{ label: "ΕΡΓΑΣΙΑ", value: examKindIds.STUDY },
								]}
								placeholder="Είδος Εξέτασης"
								searchable={false}
								classes={["ml-1", "w-100", "text-left"]}
							/>
						</Col>
						<Col
							xl={6}
							lg={6}
							classes={["d-flex", "align-items-center", "mt-2", "mt-lg-0", "px-lg-2"]}
						>
							<label className="mb-0">Μάθημα:</label>
							<BaseInput
								name="chartLesson"
								disabled={this.props.allLessons.length === 1}
								onChange={(selectedOption) => this.setState({ lessonSelectedId: Number(selectedOption.value) })}
								options={
									this.props.allLessons.length > 1
										? [{ label: "ΟΛΑ", value: -1 }].concat(
												this.props.allLessons.map((lesson) => {
													return {
														label: lesson.description,
														value: lesson.id,
													};
												}),
										  )
										: this.props.allLessons.map((lesson) => {
												return {
													label: lesson.description,
													value: lesson.id,
												};
										  })
								}
								placeholder={this.props.allLessons.length === 1 ? this.props.allLessons[0].description : "Μάθημα"}
								searchable={false}
								classes={["ml-1", "w-100", "text-left"]}
							/>
						</Col>
					</Row>
				}
			>
				{!isEmpty(this.props.allGrades) ? (
					<div style={{ height: "400px" }}>
						<CustomChart
							id={"lessonGradesProgressChart"}
							type="line"
							data={{
								labels: output.map((stat) => moment(stat.date).format("DD/MM/YYYY")),
								datasets: [
									{
										data: output.map((stat) => stat.avg),
										backgroundColor: "rgba(114,102,186,0.2)",
										borderColor: "rgba(114,102,186,1)",
										pointBorderColor: "#fff",
										pointRadius: 5,
										pointBackgroundColor: "#443d71",
										fill: true,
										lineTension: 0.3,
									},
								],
							}}
							scales={{
								y: {
									suggestedMin: 0,
									suggestedMax: 100,
									ticks: {
										stepSize: 20,
									},
								},
							}}
						/>
					</div>
				) : (
					<p className="text-danger text-bold text-center my-2">Δεν έχουν καταχωρηθεί βαθμολογίες</p>
				)}
			</Card>
		);
	}
}

LessonGradesStats.propTypes = {
	allGrades: PropTypes.arrayOf(PropTypes.exact(GradeShape)),
	allLessons: PropTypes.arrayOf(PropTypes.exact(ParametricShape)),
};

export default LessonGradesStats;
