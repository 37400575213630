import { has, isEmpty, isEqual } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import { globalStore } from "../../../../../globalStore";
import { ScientificFieldLessonsFromSelectorShape } from "../../../../../Models/ScientificFieldLessonsShape";
import {
	addNewScientificFieldLessons,
	addNewScientificFieldLessonsFail,
	addNewScientificFieldLessonsSuccess,
	apiResponse,
} from "../../../../../Redux/Actions/index";
import { getParametricAsOptions } from "../../../../../Utils/GuiUtils";
import { PARAMETRIC_TABLES } from "../../../../../Utils/ParametricTablesBinds";
import { clone, isFormInvalid } from "../../../../../Utils/Utils";
import BaseInput from "../../../../UI/BaseInput/BaseInput";
import { Col, Row } from "../../../../UI/Grid/Grid";
import Modal from "../../../../UI/Modal/Modal";
import Tag from "../../../../UI/Tag/Tag";

class AddScientificFieldLessonsForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			addScientificFieldLessonsForm: null,
			loading: false,
		};
	}

	componentDidMount() {
		this.setEmptyLessonFields();
	}

	componentDidUpdate(prevProps, prevState) {
		if (!isEqual(prevProps, this.props)) {
			const output = {};
			if (!isEmpty(this.props.selectedScientificFieldLessons)) {
				for (let i = 1; i <= 4; i++) {
					output["grade" + i] = {
						label: "Μάθημα " + i,
						name: "grade" + i,
						options: getParametricAsOptions(PARAMETRIC_TABLES.LESSONS),
						value: {
							value: this.props.selectedScientificFieldLessons[i - 1].lesson.id,
							label: this.props.selectedScientificFieldLessons[i - 1].lesson.description,
						},
						required: true,
						isNumber: true,
						colSpan: 8,
					};
					output["calculation_weight" + i] = {
						label: "Συντ. Βαρύτητας",
						name: "calculation_weight" + i,
						value: this.props.selectedScientificFieldLessons[i - 1].calculation_weight,
						required: true,
						isNumber: true,
						inputType: "number",
						colSpan: 4,
					};
				}
				this.setState({ addScientificFieldLessonsForm: output });
			} else {
				this.setEmptyLessonFields();
			}
		}
	}

	setEmptyLessonFields = () => {
		const output = {};
		for (let i = 1; i <= 4; i++) {
			output["grade" + i] = {
				label: "Μάθημα " + i,
				name: "grade" + i,
				options: getParametricAsOptions(PARAMETRIC_TABLES.LESSONS),
				value: null,
				required: true,
				isNumber: true,
				colSpan: 8,
			};
			output["calculation_weight" + i] = {
				label: "Συντ. Βαρύτητας " + i,
				name: "calculation_weight" + i,
				value: "",
				required: true,
				isNumber: true,
				inputType: "number",
				colSpan: 4,
			};
		}
		this.setState({ addScientificFieldLessonsForm: output });
	};

	onChangeHandler = (selectedOption, inputId) => {
		const updatedForm = clone(this.state.addScientificFieldLessonsForm);
		const updatedFormElement = clone(updatedForm[inputId]);

		if (has(updatedFormElement, "options")) {
			updatedFormElement.value = selectedOption;
		} else {
			updatedFormElement.value = isEmpty(selectedOption.target.value) ? selectedOption.target.value : Number(selectedOption.target.value);
		}

		updatedForm[inputId] = updatedFormElement;
		this.setState({ addScientificFieldLessonsForm: updatedForm });
	};

	onFormSubmit = () => {
		this.setState({ loading: true });
		const results = {};
		const output = {};
		output.scientific_field_id = this.props.selectedSFId;
		for (let key in this.state.addScientificFieldLessonsForm) {
			results[key] = this.state.addScientificFieldLessonsForm[key].value;
		}
		output.scientificFieldLessons = [
			{
				lesson_id: results.grade1.value,
				calculation_weight: results.calculation_weight1,
			},
			{
				lesson_id: results.grade2.value,
				calculation_weight: results.calculation_weight2,
			},
			{
				lesson_id: results.grade3.value,
				calculation_weight: results.calculation_weight3,
			},
			{
				lesson_id: results.grade4.value,
				calculation_weight: results.calculation_weight4,
			},
		];

		this.props
			.addNewScientificFieldLessons(output)
			.then((response) => {
				const apiResponseMsg = {
					error: null,
					info: {
						message: (
							<>
								Οι αναθέσεις στο επιστημονικό πεδίο <b>{globalStore.scientific_fields.find((sf) => sf.id === this.props.selectedSFId).description}</b>{" "}
								προστέθηκαν με επιτυχία.
							</>
						),
					},
				};
				this.props.setApiResponse(apiResponseMsg);
				this.props.addNewScientificFieldLessonsSuccess(response.data);
			})
			.catch((error) => {
				const apiResponseMsg = {
					error: error,
					info: null,
				};
				this.props.setApiResponse(apiResponseMsg);
				this.props.addNewScientificFieldLessonsFail(error);
			})
			.finally(() => {
				this.setState({ loading: false });
				this.props.closeModal();
				this.setEmptyLessonFields();
			});
	};

	isFormInvalid = () => {
		let allFalse = false;

		for (let key in this.state.addScientificFieldLessonsForm) {
			if (this.state.addScientificFieldLessonsForm[key].required) {
				if (this.state.addScientificFieldLessonsForm[key].value === "" || this.state.addScientificFieldLessonsForm[key].value === null) {
					allFalse = true;
				}
			}
		}
		return allFalse;
	};

	render() {
		const formElementsArray = [];
		for (let key in this.state.addScientificFieldLessonsForm) {
			formElementsArray.push({
				id: key,
				config: this.state.addScientificFieldLessonsForm[key],
			});
		}
		return (
			<Modal
				isOpen={this.props.isModalOpen}
				onClose={() => this.props.closeModal()}
				header={
					<>
						<em className={`fas ${isEmpty(this.props.selectedScientificFieldLessons) ? "fa-plus" : "fa-pencil-alt"} float-left fa-1_2x mr-3`} />
						Ανάθεση Μαθημάτων σε Επιστημονικά Πεδία
					</>
				}
				headerBg="primary"
				onSubmit={this.onFormSubmit}
				submitDisabled={isFormInvalid(this.state.addScientificFieldLessonsForm)}
				loading={this.state.loading}
			>
				<form>
					<Row classes={["px-2", "mb-2"]}>
						<Tag>{globalStore?.scientific_fields?.find((sf) => sf.id === this.props.selectedSFId)?.description}</Tag>
					</Row>
					<Row classes={["px-2"]}>
						{formElementsArray.map((element, idx) => {
							return (
								<Col
									xl={element.config.colSpan}
									lg={element.config.colSpan}
									md={element.config.colSpan}
									sm={6}
									classes={["mb-4", "px-2"]}
									key={idx}
								>
									<label>
										{element.config.label} {element.config.required ? <span className="reqField">(*)</span> : null}
									</label>
									<BaseInput
										key={element.config.name + element.config.id}
										name={element.config.name}
										placeholder={element.config.placeholder}
										options={element.config.options}
										type={element.config.inputType}
										value={element.config.value}
										onChange={(selectedOption) => {
											this.onChangeHandler(selectedOption, element.id);
										}}
									/>
								</Col>
							);
						})}
					</Row>
				</form>
			</Modal>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		addNewScientificFieldLessons: (newScientificFieldData) => dispatch(addNewScientificFieldLessons(newScientificFieldData)),
		addNewScientificFieldLessonsSuccess: (newScientificFieldData) => dispatch(addNewScientificFieldLessonsSuccess(newScientificFieldData)),
		addNewScientificFieldLessonsFail: (error) => dispatch(addNewScientificFieldLessonsFail(error)),
		setApiResponse: (theApiResponse) => dispatch(apiResponse(theApiResponse)),
	};
};

AddScientificFieldLessonsForm.propTypes = {
	addNewScientificFieldLessons: PropTypes.func,
	addNewScientificFieldLessonsFail: PropTypes.func,
	addNewScientificFieldLessonsSuccess: PropTypes.func,
	isModalOpen: PropTypes.bool,
	selectedSFId: PropTypes.number,
	selectedScientificFieldLessons: PropTypes.arrayOf(PropTypes.exact(ScientificFieldLessonsFromSelectorShape)),
	setApiResponse: PropTypes.func,
	closeModal: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(AddScientificFieldLessonsForm);
