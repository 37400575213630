import PropTypes from "prop-types";

const Divider = ({ text, textPostition }) => {
	return (
		<div className={`divider divider-text-${textPostition} unselectable`}>
			<span className="divider-inner-text">{text}</span>
		</div>
	);
};

Divider.defaultProps = {
	textPostition: "center",
};

Divider.propTypes = {
	text: PropTypes.string.isRequired,
	textPostition: PropTypes.oneOf(["left", "center", "right"]),
};

export default Divider;
