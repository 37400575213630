import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { examKindIds } from "../../../../Utils/ParametricTablesBinds";
import Card from "../../../UI/Card/Card";
import { Row, Col } from "../../../UI/Grid/Grid";
import CustomChart from "../../../UI/Chart/CustomChart";
import BaseInput from "../../../UI/BaseInput/BaseInput";

class LessonGradesStats extends Component {
	constructor(props) {
		super(props);
		this.state = {
			examKindIdForCharts: true,
		};
	}

	onSwitchExamKindChart = (selectedOption) => {
		if (Number(selectedOption.value) === -1) {
			this.setState({
				examKindIdForCharts: true,
			});
		} else {
			this.setState({
				examKindIdForCharts: selectedOption,
			});
		}
	};

	render() {
		return (
			<Col
				xl={4}
				lg={6}
				md={12}
				sm={12}
			>
				<Card
					title={this.props.stats.lesson.description}
					subTitle={
						<small>
							<b>Τελευταία Καταχώρηση: </b>
							{this.props.stats.latestCreatedAt}
						</small>
					}
					id={`studentGradeStatsCard${this.props.cardIndex}`}
				>
					{Number(this.props.stats.totalAvg) >= 50 ? (
						<p className="display-4 text-center text-green">
							{this.props.stats.totalAvg}% <span style={{ fontSize: "80%" }}>({((20 * Number(this.props.stats.totalAvg)) / 100).toFixed(1)})</span>
						</p>
					) : (
						<p className="display-4 text-center text-danger">
							{this.props.stats.totalAvg}% <span style={{ fontSize: "80%" }}>({((20 * Number(this.props.stats.totalAvg)) / 100).toFixed(1)})</span>
						</p>
					)}
					<div>
						<table className="table">
							<tbody>
								<tr>
									<td>
										<b>Αριθμός Βαθμολογιών</b>
									</td>
									<td>{this.props.stats.gradeCount}</td>
								</tr>
								<tr>
									<td>
										<b>Μέγιστη</b>
									</td>
									<td>{this.props.stats.maxGrade}</td>
								</tr>
								<tr>
									<td>
										<b>Ελάχιστη</b>
									</td>
									<td>{this.props.stats.minGrade}</td>
								</tr>
								<tr>
									<td>
										<b>Μ.Ο. Test</b>
									</td>
									<td>{this.props.stats.testAvg}</td>
								</tr>
								<tr>
									<td>
										<b>M.O. Διαγωνισμάτων</b>
									</td>
									<td>{this.props.stats.examAvg}</td>
								</tr>
								<tr>
									<td>
										<b>Μ.Ο. Εργασιών</b>
									</td>
									<td>{this.props.stats.studiesAvg}</td>
								</tr>
								<tr>
									<td style={{ borderBottom: "1px solid #eee" }}>
										<b>Απουσίες</b>
									</td>
									<td style={{ borderBottom: "1px solid #eee" }}>{this.props.stats.notPresent}</td>
								</tr>
							</tbody>
						</table>
					</div>

					<div>
						<Row>
							<Col classes={["mt-4", "mb-3", "d-flex", "ml-auto", "w-100"]}>
								<BaseInput
									name="chartExamKind"
									onChange={(selectedOption) => this.onSwitchExamKindChart(selectedOption)}
									options={[
										{ label: "ΟΛΑ", value: -1 },
										{ label: "TEST", value: examKindIds.TEST },
										{ label: "ΔΙΑΓΩΝΙΣΜΑ", value: examKindIds.EXAM },
										{ label: "ΕΡΓΑΣΙΑ", value: examKindIds.STUDY },
									]}
									placeholder="Είδος Εξέτασης"
									classes={["lesson-grades-states-select-container"]}
									searchable={false}
								/>
							</Col>
							<Col style={{ height: "250px" }}>
								<CustomChart
									id={this.props.stats.lesson.description + "studentGradeProgressChart"}
									type="line"
									data={{
										labels: this.props.stats.lessonGrades
											.filter((stat) => {
												if (typeof this.state.examKindIdForCharts !== "boolean") {
													return stat.exam_kind.id === this.state.examKindIdForCharts.value;
												} else {
													return true;
												}
											})
											.map((stat) => moment(stat.exam_date).format("DD/MM/YYYY")),
										datasets: [
											{
												data: this.props.stats.lessonGrades
													.filter((stat) => {
														if (typeof this.state.examKindIdForCharts !== "boolean") {
															return stat.exam_kind.id === this.state.examKindIdForCharts.value;
														} else {
															return true;
														}
													})
													.map((stat) => stat.grade),
												backgroundColor: "rgba(114,102,186,0.2)",
												borderColor: "rgba(114,102,186,1)",
												pointBorderColor: "#fff",
												pointRadius: 5,
												pointBackgroundColor: "#443d71",
												fill: true,
												lineTension: 0.3,
											},
										],
									}}
									scales={{
										y: {
											suggestedMin: 0,
											suggestedMax: 100,
											ticks: {
												stepSize: 20,
											},
										},
									}}
								/>
							</Col>
						</Row>
					</div>
				</Card>
			</Col>
		);
	}
}

LessonGradesStats.propTypes = {
	cardIndex: PropTypes.number,
	stats: PropTypes.object,
};

export default LessonGradesStats;
