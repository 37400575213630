import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { GradeShape } from "../../../Models/GradeShape";
import { StudentFromSelectorShape } from "../../../Models/StudentShape";
import { UserFromSelectorShape } from "../../../Models/UserShape";
import { allGrades } from "../../../Redux/Selectors/gradesSelectors";
import { gradeGeneralStatsPerStudent } from "../../../Redux/Selectors/statsSelectors";
import { allStudents } from "../../../Redux/Selectors/studentsSelectors";
import { allUsers } from "../../../Redux/Selectors/userSelectors";
import { examKindIds } from "../../../Utils/ParametricTablesBinds";
import BaseInput from "../../UI/BaseInput/BaseInput";
import Card from "../../UI/Card/Card";
import { Col, Row } from "../../UI/Grid/Grid";
import GradesStatsCard from "./GradesStatsCard/GradesStatsCard";
import GradesTableCard from "./GradesTableCard/GradesTableCard";
import ProgressChartCard from "./ProgressChartCard/ProgressChartCard";

class StudentView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			examKindSelectedId: -1,
			lessonSelectedId: -1,
		};
	}

	componentDidMount() {
		document.title = process.env.REACT_APP_NAME + " :: Προβολή Μαθητή";
	}

	render() {
		const student = this.props.allStudents.filter((student) => student.id === Number(this.props.match.params.student_id))[0];
		return (
			<>
				<Row>
					<Col>
						<Card>
							<Row align="center">
								<Col
									xl={5}
									lg={5}
									classes={["my-1", "text-center", "text-lg-left"]}
								>
									<h4 className="mb-2">{student.lastname + " " + student.firstname}</h4>
									<div>
										<span className="br pr-2 mr-2">{student.department.name}</span>
										<span>{student.department.class.description}</span>
									</div>
								</Col>
								<Col
									xl={7}
									lg={7}
									classes={["my-1", "mt-3", "mt-lg-0"]}
								>
									<h4 className="mb-2">Φίλτρα Προβολής</h4>
									<Row align="center">
										<Col
											xl={6}
											lg={6}
											classes={["d-flex", "align-items-center", "mt-1", "mt-lg-0"]}
										>
											<label className="mb-0">Είδος:</label>
											<BaseInput
												name="chartExamKind"
												onChange={(selectedOption) => this.setState({ examKindSelectedId: Number(selectedOption.value) })}
												options={[
													{ label: "ΟΛΑ", value: -1 },
													{ label: "TEST", value: examKindIds.TEST },
													{ label: "ΔΙΑΓΩΝΙΣΜΑ", value: examKindIds.EXAM },
													{ label: "ΕΡΓΑΣΙΑ", value: examKindIds.STUDY },
												]}
												placeholder="Είδος Εξέτασης"
												searchable={false}
												classes={["ml-1", "w-100", "text-left"]}
											/>
										</Col>
										<Col
											xl={6}
											lg={6}
											classes={["d-flex", "align-items-center", "mt-2", "mt-lg-0"]}
										>
											<label className="mb-0">Μάθημα:</label>
											<BaseInput
												name="chartLesson"
												disabled={this.props.currentUser.lessons.length === 1}
												onChange={(selectedOption) => this.setState({ lessonSelectedId: Number(selectedOption.value) })}
												options={
													this.props.currentUser.lessons.length > 1
														? [{ label: "ΟΛΑ", value: -1 }].concat(
																this.props.currentUser.lessons.map((lesson) => {
																	return {
																		label: lesson.description,
																		value: lesson.id,
																	};
																}),
														  )
														: this.props.currentUser.lessons.map((lesson) => {
																return {
																	label: lesson.description,
																	value: lesson.id,
																};
														  })
												}
												placeholder={this.props.currentUser.lessons.length === 1 ? this.props.currentUser.lessons[0].description : "Μάθημα"}
												searchable={false}
												classes={["ml-1", "w-100", "text-left"]}
											/>
										</Col>
									</Row>
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col
						xl={5}
						lg={5}
					>
						<GradesStatsCard
							allLessons={this.props.currentUser.lessons}
							gradeGeneralStatsPerStudent={this.props.gradeGeneralStatsPerStudent}
							lessonSelectedId={this.state.lessonSelectedId}
							examKindSelectedId={this.state.examKindSelectedId}
						/>
					</Col>
					<Col
						xl={7}
						lg={7}
					>
						<ProgressChartCard
							allLessons={this.props.currentUser.lessons}
							allGrades={this.props.allGrades.filter((grade) => grade.student_id === student.id)}
							lessonSelectedId={this.state.lessonSelectedId}
							examKindSelectedId={this.state.examKindSelectedId}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GradesTableCard
							allGrades={this.props.allGrades.filter((grade) => grade.student_id === student.id)}
							student={student}
							lessonSelectedId={this.state.lessonSelectedId}
							examKindSelectedId={this.state.examKindSelectedId}
						/>
					</Col>
				</Row>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		allStudents: allStudents(state),
		allGrades: allGrades(state),
		currentUser: allUsers(state)[0],
		gradeGeneralStatsPerStudent: (student_id, lesson_id, exam_kind_id) => gradeGeneralStatsPerStudent(state, student_id, lesson_id, exam_kind_id),
	};
};

StudentView.propTypes = {
	allStudents: PropTypes.arrayOf(PropTypes.exact(StudentFromSelectorShape)),
	allGrades: PropTypes.arrayOf(PropTypes.exact(GradeShape)),
	currentUser: PropTypes.exact(UserFromSelectorShape),
	gradeGeneralStatsPerStudent: PropTypes.func,
};

export default withRouter(connect(mapStateToProps)(StudentView));
