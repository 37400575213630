import axios from "axios";
import { deleteResource, getResource, postResource, putResource } from "../../BackendAPI/AbstractCRUDActions";
import { ActionKinds } from "../../BackendAPI/ActionKinds";
import {
	getCurrentTeacherTeacherDepartmentMappingsUrl,
	getCurrentTeacherTeacherLessonMappingsUrl,
	getTeacherDepartmentsUrl,
	getTeacherLessonsUrl,
	getUsersUrl,
	getUserToStudentUrl,
} from "../../BackendAPI/BackendAPI";
import {
	ADD_NEW_USER_FAIL,
	ADD_NEW_USER_START,
	ADD_NEW_USER_SUCCESS,
	DELETE_USER_FAIL,
	DELETE_USER_START,
	DELETE_USER_SUCCESS,
	FETCH_ALL_USERS_FAIL,
	FETCH_ALL_USERS_START,
	FETCH_ALL_USERS_SUCCESS,
	FETCH_STUDENT_USER_FAIL,
	FETCH_STUDENT_USER_START,
	FETCH_STUDENT_USER_SUCCESS,
	FETCH_TEACHER_USER_FAIL,
	FETCH_TEACHER_USER_START,
	FETCH_TEACHER_USER_SUCCESS,
	UPDATE_USER_FAIL,
	UPDATE_USER_PASSWORD_FAIL,
	UPDATE_USER_PASSWORD_START,
	UPDATE_USER_PASSWORD_SUCCESS,
	UPDATE_USER_START,
	UPDATE_USER_SUCCESS,
} from "./actionTypes";

export const fetchAllUsersStart = () => {
	return {
		type: FETCH_ALL_USERS_START,
	};
};

export const fetchAllUsersSuccess = (userData) => {
	return {
		type: FETCH_ALL_USERS_SUCCESS,
		payload: userData,
	};
};

export const fetchAllUsersFail = (error) => {
	return {
		type: FETCH_ALL_USERS_FAIL,
		payload: error,
	};
};

export const fetchAllUsers = () => {
	return (dispatch) => {
		dispatch(fetchAllUsersStart());
		axios
			.all([
				getResource(getUsersUrl(ActionKinds.GET)),
				getResource(getTeacherLessonsUrl(ActionKinds.GET)),
				getResource(getTeacherDepartmentsUrl(ActionKinds.GET)),
				getResource(getUserToStudentUrl(ActionKinds.GET)),
			])
			.then(
				axios.spread((usersData, teacherLessonsData, teacherDepartmentsData, userToStudentData) => {
					dispatch(
						fetchAllUsersSuccess({
							users: usersData.data,
							teacherLessons: teacherLessonsData.data,
							teacherDepartments: teacherDepartmentsData.data,
							userToStudent: userToStudentData.data,
						}),
					);
				}),
			)
			.catch((error) => {
				dispatch(fetchAllUsersFail(error.response));
			});
	};
};

export const fetchTeacherUserStart = () => {
	return {
		type: FETCH_TEACHER_USER_START,
	};
};

export const fetchTeacherUserSuccess = (userData) => {
	return {
		type: FETCH_TEACHER_USER_SUCCESS,
		payload: userData,
	};
};

export const fetchTeacherUserFail = (error) => {
	return {
		type: FETCH_TEACHER_USER_FAIL,
		payload: error,
	};
};

export const fetchTeacherUser = (teacher_id) => {
	return (dispatch) => {
		dispatch(fetchTeacherUserStart());
		axios
			.all([
				getResource(getUsersUrl(ActionKinds.GET_ONE, teacher_id)),
				getResource(getCurrentTeacherTeacherLessonMappingsUrl(ActionKinds.GET)),
				getResource(getCurrentTeacherTeacherDepartmentMappingsUrl(ActionKinds.GET)),
			])
			.then(
				axios.spread((userData, teacherLessonsData, teacherDepartmentsData) => {
					dispatch(
						fetchTeacherUserSuccess({
							user: userData.data,
							teacherLessons: teacherLessonsData.data,
							teacherDepartments: teacherDepartmentsData.data,
						}),
					);
				}),
			)
			.catch((error) => {
				dispatch(fetchTeacherUserFail(error));
			});
	};
};

export const fetchStudentUserStart = () => {
	return {
		type: FETCH_STUDENT_USER_START,
	};
};

export const fetchStudentUserSuccess = (userData) => {
	return {
		type: FETCH_STUDENT_USER_SUCCESS,
		payload: userData,
	};
};

export const fetchStudentUserFail = (error) => {
	return {
		type: FETCH_STUDENT_USER_FAIL,
		payload: error,
	};
};

export const fetchStudentUser = (student_id) => {
	return (dispatch) => {
		dispatch(fetchStudentUserStart());
		getResource(getUsersUrl(ActionKinds.GET_ONE, student_id))
			.then((response) => {
				dispatch(fetchStudentUserSuccess(response.data));
			})
			.catch((error) => {
				dispatch(fetchStudentUserFail(error));
			});
	};
};

export const addNewUserStart = () => {
	return {
		type: ADD_NEW_USER_START,
	};
};

export const addNewUserSuccess = (newUserData) => {
	return {
		type: ADD_NEW_USER_SUCCESS,
		payload: newUserData,
	};
};

export const addNewUserFail = (error) => {
	return {
		type: ADD_NEW_USER_FAIL,
		payload: error,
	};
};

export const addNewUser = (newUserData) => {
	return (dispatch) => {
		dispatch(addNewUserStart());
		const url = getUsersUrl(ActionKinds.POST);
		return postResource(url, newUserData);
	};
};

export const updateUserStart = () => {
	return {
		type: UPDATE_USER_START,
	};
};

export const updateUserSuccess = (userData) => {
	return {
		type: UPDATE_USER_SUCCESS,
		payload: userData,
	};
};

export const updateUserFail = (error) => {
	return {
		type: UPDATE_USER_FAIL,
		payload: error,
	};
};

export const updateUser = (userData, userId) => {
	return (dispatch) => {
		dispatch(updateUserStart());
		const url = getUsersUrl(ActionKinds.UPDATE, userId);
		return putResource(url, userData);
	};
};

export const updateUserPasswordStart = () => {
	return {
		type: UPDATE_USER_PASSWORD_START,
	};
};

export const updateUserPasswordSuccess = () => {
	return {
		type: UPDATE_USER_PASSWORD_SUCCESS,
	};
};

export const updateUserPasswordFail = (error) => {
	return {
		type: UPDATE_USER_PASSWORD_FAIL,
		payload: error,
	};
};

export const updateUserPassword = (userPasswordData, userId) => {
	return (dispatch) => {
		dispatch(updateUserPasswordStart());
		const url = getUsersUrl("reset-password", userId);
		return putResource(url, userPasswordData);
	};
};

export const deleteUserStart = () => {
	return {
		type: DELETE_USER_START,
	};
};

export const deleteUserSuccess = (userData) => {
	return {
		type: DELETE_USER_SUCCESS,
		payload: userData,
	};
};

export const deleteUserFail = (error) => {
	return {
		type: DELETE_USER_FAIL,
		payload: error,
	};
};

export const deleteUser = (userId) => {
	return (dispatch) => {
		dispatch(deleteUserStart());
		const url = getUsersUrl(ActionKinds.DELETE, userId);
		return deleteResource(url);
	};
};
