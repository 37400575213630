import PropTypes from "prop-types";
import { SelfTestSelectorShape } from "./SelfTestShape";
import { StudentFromReduxShape } from "./StudentShape";

export const SelfTestAnswersReduxShape = {
	id: PropTypes.number.isRequired,
	self_test_id: PropTypes.number.isRequired,
	student_id: PropTypes.number.isRequired,
	self_test_answers: PropTypes.array.isRequired,
	self_test_grade: PropTypes.number.isRequired,
	created_at: PropTypes.string.isRequired,
};

export const SelfTestAnswersSelectorShape = {
	id: PropTypes.number.isRequired,
	self_test_id: PropTypes.number.isRequired,
	self_test: PropTypes.exact(SelfTestSelectorShape),
	student_id: PropTypes.number.isRequired,
	student: PropTypes.exact(StudentFromReduxShape),
	self_test_answers: PropTypes.array.isRequired,
	self_test_grade: PropTypes.number.isRequired,
	created_at: PropTypes.string.isRequired,
};
