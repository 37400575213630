import PropTypes from "prop-types";

import { isEmpty } from "lodash";
import ReactTooltip from "react-tooltip";
import BaseInput from "../BaseInput/BaseInput";
import Button from "../Button/Button";

const Filter = ({ disabled, filterText, onClear, onFilter, placement }) => {
	const updatedPlacement = (() => {
		switch (placement) {
			case "center":
				return "mx-auto";
			case "right":
				return "ml-auto";
			default:
				return "mr-auto";
		}
	})();
	return (
		<div
			className={`w-100 ${updatedPlacement}`}
			style={{ position: "relative", maxWidth: "450px" }}
		>
			<i
				className="fas fa-search mr-2"
				style={{ fontSize: "1.1rem", color: "#ccc", position: "absolute", top: "11px", bottom: 0, left: "10px" }}
			></i>
			<BaseInput
				type="text"
				placeholder="Αναζήτηση"
				onChange={(e) => {
					if (isEmpty(e.target.value)) ReactTooltip.hide();
					onFilter(e);
				}}
				value={filterText}
				disabled={disabled}
				name="filter"
				classes={["filter-field"]}
				style={{ paddingLeft: "2.3rem" }}
			/>
			{onClear && (
				<Button
					type="button"
					iconClass="far fa-times-circle"
					kind="link"
					style={{ fontSize: "1.15rem", position: "absolute", top: 0, bottom: 0, right: "-6px" }}
					classes={["pl-1"]}
					onClick={() => {
						onClear();
						ReactTooltip.hide();
					}}
					disabled={filterText === "" || disabled}
					data-tip="Εκκαθάριση Πεδίου"
				/>
			)}
			<ReactTooltip effect="solid" />
		</div>
	);
};

Filter.propTypes = {
	disabled: PropTypes.bool,
	filterText: PropTypes.string.isRequired,
	onClear: PropTypes.func,
	onFilter: PropTypes.func.isRequired,
	placement: PropTypes.string,
};

export default Filter;
