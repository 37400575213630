import { useLocation } from "react-router-dom";

import { PathEntry } from "../../navigation";
import { getAuthData } from "../../Utils/AuthUtils";

const PageHeader = () => {
	const location = useLocation();

	const getPageHeaderFromPath = (() => {
		let pageHeader = null;
		for (let key in PathEntry) {
			if (PathEntry[key].pathToMatch) {
				const splittedCurrentPath = location.pathname.split("/");
				const updatedSplittedCurrentPath = splittedCurrentPath.filter((_, idx) => idx !== splittedCurrentPath.length - 2);
				if (updatedSplittedCurrentPath.join("/") === PathEntry[key].pathToMatch) {
					pageHeader = PathEntry[key].pageHeader;
					break;
				}
			} else if (PathEntry[key].path === location.pathname) {
				pageHeader = PathEntry[key].pageHeader;
				break;
			}
		}
		return pageHeader;
	})();

	return (
		<div className="content-heading">
			<div>
				{getPageHeaderFromPath}
				{getAuthData().token != null ? (
					<div className="d-flex mt-2">
						<small className="pr-2 mr-2 br">
							<i className="fas fa-calendar-alt"></i> {getAuthData().schoolYear.display}
						</small>
						<small className="">
							<i className="far fa-calendar-alt"></i> {getAuthData().period.display}
						</small>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default PageHeader;
