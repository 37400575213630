import { has, isEmpty, isEqual } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import { ParametricShape } from "../../../../../Models/ParametricShape";
import { ScientificFieldLessonsFromSelectorShape } from "../../../../../Models/ScientificFieldLessonsShape";
import { StudentFromSelectorShape } from "../../../../../Models/StudentShape";
import {
	apiResponse,
	updatePanhellenicExaminationResult,
	updatePanhellenicExaminationResultFail,
	updatePanhellenicExaminationResultSuccess,
} from "../../../../../Redux/Actions/index";
import { CUSTOM_ERRORS } from "../../../../../Utils/CustomErrors";
import { clone, roundNumber, sortArrayOfObjectsByNumbers } from "../../../../../Utils/Utils";
import Alert from "../../../../UI/Alert/Alert";
import Button from "../../../../UI/Button/Button";
import { Col, Row } from "../../../../UI/Grid/Grid";
import { MODAL_SIZE } from "../../../../UI/Modal/constants/ModalSize";
import Modal from "../../../../UI/Modal/Modal";
import Tag from "../../../../UI/Tag/Tag";
import SpecialLessonFormsWrapper from "./LessonForms/SpecialLessonFormsWrapper";
import StandardLessonsForm from "./LessonForms/StandardLessonsForm";

const LessonFormComponentName = {
	STANDARD_LESSONS: "StandardLessonsForm",
	SPECIAL_LESSONS: "SpecialLessonFormsWrapper",
};

class EditPanhellenicExaminationForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			finalResultWithSpecialLessons: null,
			finalResultWithFactor2: null,
			standardLessons: {
				result: null,
				student_id: null,
				grades: [],
				average: null,
			},
			specialLessons: {
				result: [],
				grades: [],
			},
			isStandardLessonFormInvalid: false,
			isSpecialLessonFormInvalid: false,
			standardLessonFormError: null,
			specialLessonFormError: null,
			executeReset: false,
			loading: false,
		};
		this.showStandardLessonErrorAlert = false;
		this.showSpecialLessonErrorAlert = false;
	}

	componentDidMount() {
		this.initDataWhenEdit();
	}

	componentDidUpdate(prevProps, prevState) {
		if (!isEqual(prevProps.selectedResult, this.props.selectedResult)) {
			this.initDataWhenEdit();
		}
	}

	initDataWhenEdit = () => {
		if (this.props.selectedResult) {
			const updatedStandardLessons = clone(this.state.standardLessons);
			updatedStandardLessons.result = this.props.selectedResult.final_result;
			updatedStandardLessons.grades = this.props.selectedResult.grades.map((grade) => {
				return { lesson_id: grade.lesson.id, grade: grade.grade };
			});
			updatedStandardLessons.student_id = {
				value: this.props.selectedResult.student.id,
				label: this.props.selectedResult.student.lastname + " " + this.props.selectedResult.student.firstname,
			};
			updatedStandardLessons.average = this.props.selectedResult.average;
			this.setState({ standardLessons: updatedStandardLessons });

			const updatedSpecialLessons = clone(this.state.specialLessons);
			if (!isEmpty(this.props.selectedResult.flGrades)) {
				const updatedFLGrades = clone(this.props.selectedResult.flGrades[0]);
				updatedSpecialLessons.grades = [
					{
						grade: updatedFLGrades.grade,
						lesson: {
							value: updatedFLGrades.lesson.id,
							label: updatedFLGrades.lesson.description,
						},
					},
				];
				updatedSpecialLessons.result = [this.props.selectedResult.flGrades[0].grade];
				this.setState({
					specialLessons: updatedSpecialLessons,
					finalResultWithSpecialLessons: this.props.selectedResult.final_result_with_special_lessons,
					finalResultWithFactor2: this.props.selectedResult.final_result_with_factor_2,
				});
			} else if (!isEmpty(this.props.selectedResult.designGrades)) {
				const updatedDLGrades = clone(this.props.selectedResult.designGrades);
				updatedSpecialLessons.grades = updatedDLGrades.map((grade) => {
					return {
						grade: grade.grade,
						lesson: {
							value: grade.lesson.id,
							label: grade.lesson.description,
						},
					};
				});
				updatedSpecialLessons.result = this.props.selectedResult.designGrades.map((grade) => grade.grade);
				this.setState({
					specialLessons: updatedSpecialLessons,
					finalResultWithSpecialLessons: this.props.selectedResult.final_result_with_special_lessons,
					finalResultWithFactor2: null,
				});
			}
		}
	};

	getGrade = (gradesFromState) => {
		const grades = [];
		if (has(gradesFromState, "student_id")) delete gradesFromState.student_id;
		Object.keys(gradesFromState).forEach((key) => {
			if (gradesFromState[key].value !== "")
				grades.push({
					lesson_id: gradesFromState[key].lesson_id,
					grade: gradesFromState[key].value,
				});
		});
		return grades;
	};

	isDirty = () => {
		const propsGradesTransformed = this.props.selectedResult.grades.map((grade) => {
			return { lesson_id: grade.lesson.id, grade: grade.grade };
		});
		const sortedPropsGradesTransformed = sortArrayOfObjectsByNumbers(propsGradesTransformed, "lesson_id");
		const sortedStateGrades = sortArrayOfObjectsByNumbers(this.state.standardLessons.grades, "lesson_id");
		const flGrades = (() => {
			if (this.props.selectedResult.flGrades) {
				return this.props.selectedResult.flGrades.map((flGrade) => {
					return {
						grade: flGrade.grade,
						lesson: {
							value: flGrade.lesson.id,
							label: flGrade.lesson.description,
						},
					};
				});
			} else {
				return [];
			}
		})();
		const designGrades = (() => {
			if (this.props.selectedResult.designGrades) {
				return this.props.selectedResult.designGrades.map((dlGrade) => {
					return {
						grade: dlGrade.grade,
						lesson: {
							value: dlGrade.lesson.id,
							label: dlGrade.lesson.description,
						},
					};
				});
			} else {
				return [];
			}
		})();

		return (
			!isEqual(sortedPropsGradesTransformed, sortedStateGrades) ||
			this.props.selectedResult.student_id !== this.state.standardLessons.student_id.value ||
			(!isEmpty(flGrades) && !isEqual(flGrades, this.state.specialLessons.grades)) ||
			(!isEmpty(designGrades) && !isEqual(designGrades, this.state.specialLessons.grades)) ||
			(isEmpty(flGrades) && !isEmpty(this.state.specialLessons.grades) && isEmpty(designGrades) && !isEmpty(this.state.specialLessons.grades))
		);
	};

	resetSpecialLessonsGrade = () => {
		this.setState({
			finalResultWithSpecialLessons: null,
			finalResultWithFactor2: null,
		});
	};

	setStandardLessons = (result, student_id, grades) => {
		const updatedStandardLessons = clone(this.state.standardLessons);
		updatedStandardLessons.result = result;
		updatedStandardLessons.grades = grades;
		updatedStandardLessons.student_id = student_id;
		const sum = grades.map((grade) => grade.grade).reduce((a, b) => a + b, 0);
		updatedStandardLessons.average = roundNumber(sum / 4, 2);
		this.setState({ standardLessons: updatedStandardLessons }, () => this.calculateSpecialLessonsGrade());
	};

	setSpecialLessons = (result, grades) => {
		const updatedFLLessons = clone(this.state.specialLessons);
		updatedFLLessons.result = result;
		updatedFLLessons.grades = grades;
		this.calculateSpecialLessonsGrade(result);
		this.setState({ specialLessons: updatedFLLessons });
	};

	calculateSpecialLessonsGrade = (result = null) => {
		// Be super careful: result here is trie-state (null, empty, or with data)
		const data = (() => {
			if (result === null) return clone(this.state.specialLessons.result);
			else if (isEmpty(result)) return [];
			else return result;
		})();
		switch (data.length) {
			case 1:
				if (data[0] >= 10) {
					this.setState({
						finalResultWithSpecialLessons: this.state.standardLessons.result + data[0] * 100,
						finalResultWithFactor2: this.state.standardLessons.result + data[0] * 200,
					});
				} else {
					this.resetSpecialLessonsGrade();
				}
				break;
			case 2:
				if (data[0] >= 10) {
					const result = ((data[0] + data[1]) / 2) * 200;
					this.setState({
						finalResultWithSpecialLessons: this.state.standardLessons.result + result,
						finalResultWithFactor2: null,
					});
				} else {
					this.resetSpecialLessonsGrade();
				}
				break;
			default:
				this.resetSpecialLessonsGrade();
				break;
		}
	};

	setIsFormInvalid = (isInvalid, formName) => {
		if (formName === LessonFormComponentName.STANDARD_LESSONS) this.setState({ isStandardLessonFormInvalid: isInvalid });
		else if (formName === LessonFormComponentName.SPECIAL_LESSONS) this.setState({ isSpecialLessonFormInvalid: isInvalid });
		else
			CUSTOM_ERRORS.throwError(
				`PanhellenicExaminations/Forms/${this.constructor.name}.js | line 159`,
				CUSTOM_ERRORS.UNEXPECTED_IF_STATEMENT,
				formName,
			);
	};

	setGradesError = (error, formName) => {
		if (formName === LessonFormComponentName.STANDARD_LESSONS) {
			this.setState({ standardLessonFormError: error });
			if (error !== null) {
				this.showStandardLessonErrorAlert = true;
			} else {
				this.showStandardLessonErrorAlert = false;
			}
		} else if (formName === LessonFormComponentName.SPECIAL_LESSONS) {
			this.setState({ specialLessonFormError: error });
			if (error !== null) {
				this.showSpecialLessonErrorAlert = true;
			} else {
				this.showSpecialLessonErrorAlert = false;
			}
		} else
			CUSTOM_ERRORS.throwError(
				`PanhellenicExaminations/Forms/${this.constructor.name}.js | line 168`,
				CUSTOM_ERRORS.UNEXPECTED_IF_STATEMENT,
				formName,
			);
	};

	resetExecuteResetToggle = () => {
		this.setState({ executeReset: false });
	};

	onReset = () => {
		this.initDataWhenEdit();
		this.setState({
			executeReset: true,
			standardLessonFormError: null,
			specialLessonFormError: null,
		});
		this.showStandardLessonErrorAlert = false;
		this.showSpecialLessonErrorAlert = false;
	};

	onCloseModal = () => {
		this.onReset();
		this.props.closeModal();
	};

	onFormSubmit = () => {
		this.setState({ loading: true });
		const output = {};
		output.scientific_field_id = this.props.scientificFieldSelected.id;
		output.finalResult = this.state.standardLessons.result;
		output.student_id = this.state.standardLessons.student_id.value;
		output.grades = this.state.standardLessons.grades;
		output.average = this.state.standardLessons.average;
		output.finalResultWithSpecialLessons = this.state.finalResultWithSpecialLessons;
		output.finalResultWithFactor2 = this.state.finalResultWithFactor2;
		output.design_grades = (() => {
			if (!isEmpty(this.state.specialLessons.result) && this.state.specialLessons.result.length === 2) {
				return this.state.specialLessons.grades.map((grade) => {
					return {
						grade: grade.grade,
						lesson_id: grade.lesson.value,
					};
				});
			} else {
				return [];
			}
		})();
		output.foreign_language_grades = (() => {
			if (!isEmpty(this.state.specialLessons.result) && this.state.specialLessons.result.length === 1) {
				return this.state.specialLessons.grades.map((grade) => {
					return {
						grade: grade.grade,
						lesson_id: grade.lesson.value,
					};
				});
			} else {
				return [];
			}
		})();

		this.props
			.updatePanhellenicExaminationResult(output, this.props.selectedResult.id)
			.then((response) => {
				const result = {
					...response.data,
					grades: output.grades,
					flGrades: output.foreign_language_grades,
					designGrades: output.design_grades,
				};
				const student = this.props.allStudents.filter((student) => student.id === response.data.student_id)[0];
				const apiResponseMsg = {
					error: null,
					info: {
						message: (
							<>
								Η βαθμολογία του μαθητή{" "}
								<b>
									{student.lastname} {student.firstname}
								</b>{" "}
								ενημερώθηκε με επιτυχία.
							</>
						),
					},
				};
				this.props.setApiResponse(apiResponseMsg);
				this.props.updatePanhellenicExaminationResultSuccess(result);
			})
			.catch((error) => {
				const apiResponseMsg = {
					error: error,
					info: null,
				};
				this.props.setApiResponse(apiResponseMsg);
				this.props.updatePanhellenicExaminationResultFail(error);
			})
			.finally(() => {
				this.setState({ loading: false });
				this.props.resetAllFilters();
				this.onCloseModal();
			});
	};

	render() {
		return (
			<Modal
				isOpen={this.props.isModalOpen}
				header={
					this.props.modalReadonly ? (
						<>
							<em className="fas fa-eye float-left fa-1_2x mr-3" />
							Προβολή Βαθμολογίας Πανελλαδικών Εξετάσεων
						</>
					) : (
						<>
							<em className="fas fa-pencil-alt float-left fa-1_2x mr-3" />
							Επεξεργασία Βαθμολογίας Πανελλαδικών Εξετάσεων
						</>
					)
				}
				headerBg={this.props.modalReadonly ? "green" : "info"}
				onSubmit={this.onFormSubmit}
				onClose={this.onCloseModal}
				size={MODAL_SIZE.lg}
				loading={this.state.loading}
				footer={
					this.props.modalReadonly ? (
						<Button
							type="button"
							kind="secondary"
							text="Κλείσιμο"
							onClick={this.onCloseModal}
						/>
					) : (
						<>
							<Button
								type="button"
								kind="success"
								onClick={this.onFormSubmit}
								disabled={
									this.state.isStandardLessonFormInvalid ||
									this.state.isSpecialLessonFormInvalid ||
									this.state.specialLessonFormError !== null ||
									this.state.standardLessonFormError !== null ||
									!this.isDirty()
								}
								text="Αποθήκευση"
							/>
							<Button
								type="button"
								kind="primary"
								onClick={this.onReset}
								disabled={!this.isDirty()}
								text="Επαναφορά"
							/>
							<Button
								type="button"
								kind="secondary"
								onClick={this.onCloseModal}
								text="Κλείσιμο"
							/>
						</>
					)
				}
			>
				<form>
					<Row classes={["mb-3"]}>
						<Col>
							<Tag>{this.props.scientificFieldSelected.description}</Tag>
						</Col>
					</Row>
					<Row classes={["px-2"]}>
						<StandardLessonsForm
							scientificFieldLessons={this.props.scientificFieldLessons}
							allStudents={this.props.allStudents}
							setStandardLessons={this.setStandardLessons}
							setGradesError={this.setGradesError}
							setIsFormInvalid={this.setIsFormInvalid}
							selectedResult={this.props.selectedResult}
							executeReset={this.state.executeReset}
							resetExecuteResetToggle={this.resetExecuteResetToggle}
							modalReadonly={this.props.modalReadonly}
						/>
					</Row>

					<SpecialLessonFormsWrapper
						setIsFormInvalid={this.setIsFormInvalid}
						setGradesError={this.setGradesError}
						setSpecialLessons={this.setSpecialLessons}
						selectedResult={this.props.selectedResult}
						executeReset={this.state.executeReset}
						resetExecuteResetToggle={this.resetExecuteResetToggle}
						modalReadonly={this.props.modalReadonly}
					/>
					{this.state.standardLessons.result && this.state.standardLessonFormError === null && (
						<Row classes={["mb-1"]}>
							<Col>
								Αριθμός Μορίων:{" "}
								<span
									style={{ fontSize: "120%" }}
									className="text-green ml-1 text-bold"
								>
									{this.state.standardLessons.result}
								</span>
							</Col>
						</Row>
					)}
					{this.state.standardLessons.average && this.state.standardLessonFormError === null && (
						<Row classes={["mb-1"]}>
							<Col>
								Μέσος Όρος:{" "}
								<span
									style={{ fontSize: "120%" }}
									className="text-green ml-1 text-bold"
								>
									{this.state.standardLessons.average}
								</span>
							</Col>
						</Row>
					)}
					{this.state.finalResultWithSpecialLessons && (
						<Row classes={["mb-1"]}>
							<Col>
								Αριθμός Μορίων με Ειδικό Μάθημα:{" "}
								<span
									style={{ fontSize: "120%" }}
									className="text-green ml-1 text-bold"
								>
									{this.state.finalResultWithSpecialLessons}
								</span>
							</Col>
						</Row>
					)}
					{this.state.finalResultWithFactor2 && (
						<Row classes={["mb-1"]}>
							<Col>
								Αριθμός Μορίων με Ειδικό Μάθημα και Συντελεστή 2:{" "}
								<span
									style={{ fontSize: "120%" }}
									className="text-green ml-1 text-bold"
								>
									{this.state.finalResultWithFactor2}
								</span>
							</Col>
						</Row>
					)}
					{this.showStandardLessonErrorAlert && (
						<Alert
							dismissible
							type="danger"
							onClose={() => (this.showStandardLessonErrorAlert = false)}
							message={<span>Σφάλμα: {this.state.standardLessonFormError}</span>}
						/>
					)}
					{this.showSpecialLessonErrorAlert && (
						<Alert
							dismissible
							type="danger"
							onClose={() => (this.showSpecialLessonErrorAlert = false)}
							message={<span>Σφάλμα: {this.state.specialLessonFormError}</span>}
						/>
					)}
					{!this.props.modalReadonly && (
						<Row classes={["px-2", "mt-3"]}>
							<Col classes={["bg-light", "p-2", "shadow-sm", "border"]}>
								<i className="fas fa-info-circle mr-2 text-info"></i>Η υποδιαστολή δηλώνεται με τον χαρακτήρα "." και όχι με τον χαρακτήρα ","
							</Col>
						</Row>
					)}
				</form>
			</Modal>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setApiResponse: (theApiResponse) => dispatch(apiResponse(theApiResponse)),
		updatePanhellenicExaminationResult: (panhellenicExaminationsData, panhellenicExaminationsId) =>
			dispatch(updatePanhellenicExaminationResult(panhellenicExaminationsData, panhellenicExaminationsId)),
		updatePanhellenicExaminationResultSuccess: (panhellenicExaminationsData) =>
			dispatch(updatePanhellenicExaminationResultSuccess(panhellenicExaminationsData)),
		updatePanhellenicExaminationResultFail: (error) => dispatch(updatePanhellenicExaminationResultFail(error)),
	};
};

EditPanhellenicExaminationForm.propTypes = {
	allStudents: PropTypes.arrayOf(PropTypes.exact(StudentFromSelectorShape)),
	closeModal: PropTypes.func,
	isModalOpen: PropTypes.bool,
	modalReadonly: PropTypes.bool,
	resetAllFilters: PropTypes.func,
	scientificFieldLessons: PropTypes.arrayOf(PropTypes.exact(ScientificFieldLessonsFromSelectorShape)),
	scientificFieldSelected: PropTypes.exact(ParametricShape),
	selectedResult: PropTypes.object,
	setApiResponse: PropTypes.func,
	updatePanhellenicExaminationResult: PropTypes.func,
	updatePanhellenicExaminationResultFail: PropTypes.func,
	updatePanhellenicExaminationResultSuccess: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(EditPanhellenicExaminationForm);
