import { includes, isEmpty, isEqual, uniqBy } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";

import { PanhellenicExaminationsShape } from "../../../../Models/PanhellenicExaminationsShape";
import { StudentFromSelectorShape } from "../../../../Models/StudentShape";
import {
	apiResponse,
	deletePanhellenicExaminationResult,
	deletePanhellenicExaminationResultFail,
	deletePanhellenicExaminationResultSuccess,
} from "../../../../Redux/Actions/index";
import { allPanhellenicExaminationsResults } from "../../../../Redux/Selectors/panhellenicExaminationsSelector";
import { allStudents } from "../../../../Redux/Selectors/studentsSelectors";
import { allFetchingFinished } from "../../../../Redux/Selectors/universalSelectors";
import { EPAL_STANDARD_LESSONS_IDS, PANHELLENIC_EXAMINATIONS_ENABLE_EPAL_CLASSES_ID } from "../../../../Utils/ParametricTablesBinds";
import Button from "../../../UI/Button/Button";
import Card from "../../../UI/Card/Card";
import Datatable from "../../../UI/Datatable/Datatable";
import Modal from "../../../UI/Modal/Modal";
import AddPanhellenicExaminationForm from "./Forms/AddPanhellenicExaminationForm";
import EditPanhellenicExaminationForm from "./Forms/EditPanhellenicExaminationForm";

const columns = [
	{
		name: "Α/Α",
		selector: (row) => row.id,
		sortable: true,
		width: "75px",
	},
	{
		name: "Ονοματεπώνυμο Μαθητή",
		selector: (row) => row.student.lastname + " " + row.student.firstname,
		sortable: true,
		wrap: true,
		grow: 2,
	},
	{
		name: "Κοινή Ομάδα",
		selector: (row) => row.final_result,
		format: (row) => <span style={{ fontSize: "120%" }}>{row.final_result}</span>,
		sortable: true,
		wrap: true,
		maxWidth: "142px",
	},
	{
		name: "Τομέας",
		selector: (row) => row.final_result_with_special_lessons,
		format: (row) => <span style={{ fontSize: "120%" }}>{row.final_result_with_special_lessons}</span>,
		sortable: true,
		wrap: true,
		maxWidth: "125px",
	},
	{
		name: "Μέσος Όρος",
		selector: (row) => row.average,
		format: (row) => <span style={{ fontSize: "120%" }}>{row.average}</span>,
		sortable: true,
		wrap: true,
		maxWidth: "142px",
	},
];

class EpalPanhellenicExaminations extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterText: "",
			filteredData: [],
			isAddModalOpen: false,
			isDeleteModalOpen: false,
			isEditModalOpen: false,
			loading: false,
			modalReadonly: false,
			selectedRow: [],
		};
		this.toggledClearRows = false;
	}

	componentDidMount() {
		document.title = process.env.REACT_APP_NAME + " :: Πανελλαδικές Εξετάσεις";
	}

	componentDidUpdate(prevProps) {
		if (!isEqual(prevProps.allPanhellenicExaminationsResults, this.props.allPanhellenicExaminationsResults)) {
			this.setState({ shouldAddGradeRender: false });
		}

		if (!prevProps.allFetchingFinished && this.props.allFetchingFinished) {
			this.resetAllFilters();
		}
	}

	selectedRow = (data) => {
		this.setState({
			selectedRow: data.selectedRows,
		});
	};

	clearSelectedRows = () => {
		this.toggledClearRows = !this.toggledClearRows;
		this.setState({ selectedRow: [] });
	};

	resetAllFilters = () => {
		this.setState({
			filterText: "",
			filteredData: [],
		});
		this.clearSelectedRows();
	};

	setFilter = (filterString, tableData) => {
		const filteredItems = tableData.filter((row) => {
			return (
				`${row.student.lastname} ${row.student.firstname}`.toLowerCase().includes(filterString.toLowerCase()) ||
				`${row.student.firstname} ${row.student.lastname}`.toLowerCase().includes(filterString.toLowerCase())
			);
		});

		this.setState({
			filterText: filterString,
			filteredData: uniqBy(filteredItems, "id"),
		});
	};

	onDeleteGradeHandler = () => {
		this.setState({ loading: true });
		this.props
			.deletePanhellenicExaminationResult(!isEmpty(this.state.selectedRow) ? this.state.selectedRow[0].id : null)
			.then((response) => {
				this.props.setApiResponse({
					error: null,
					info: {
						message: (
							<>
								Η βαθμολογία του μαθητή{" "}
								<b>
									{this.state.selectedRow[0].student.lastname} {this.state.selectedRow[0].student.firstname}
								</b>{" "}
								διαγράφηκε με επιτυχία.
							</>
						),
					},
				});
				this.props.deletePanhellenicExaminationResultSuccess(response.data);
			})
			.catch((error) => {
				this.props.setApiResponse({
					error: error,
					info: null,
				});
				this.props.deletePanhellenicExaminationResultFail(error);
			})
			.finally(() => {
				this.resetAllFilters();
				this.setState({ loading: false, isDeleteModalOpen: false });
			});
	};

	selectedStudentsForEditForm = () => {
		const currStudent = this.props.allStudents.find((student) => student.id === this.state.selectedRow[0].student_id);
		const allStudents = this.props.allStudents
			.filter((student) => includes(PANHELLENIC_EXAMINATIONS_ENABLE_EPAL_CLASSES_ID, student.department.class.id))
			.filter(
				(student) =>
					!includes(
						this.props.allPanhellenicExaminationsResults.map((per) => per.student_id),
						student.id,
					),
			);
		allStudents.push(currStudent);
		return allStudents;
	};

	render() {
		const tableData = this.props.allPanhellenicExaminationsResults.filter((res) => res.scientific_field_id === null);
		const colsFromTableData = tableData.map((data) => {
			return data.grades;
		});
		const flattenColsFromTableData = uniqBy(colsFromTableData.flat(1), "lesson.id");

		const basicLessonsCol = flattenColsFromTableData.map((item, idx) => {
			return {
				name: item.lesson.description,
				selector: (row) => {
					const gradeItem = row.grades.find((grade) => item.lesson.id === grade.lesson.id);
					return gradeItem ? gradeItem.grade : "";
				},
				sortable: true,
				hide: "md",
				omit: !includes(EPAL_STANDARD_LESSONS_IDS, item.lesson.id),
			};
		});

		return (
			<>
				<Card
					title="Βαθμολογίες Πανελλαδικών Εξετάσεων ΕΠΑ.Λ"
					subTitle={<div className="text-sm">Αριθμός Καταχωρήσεων: {tableData.length}</div>}
					id="epalPanhellenicExaminationsResultTable"
					headerSpannig={[8, 4]}
					extraOnHeader={
						<>
							<Button
								oval
								type="button"
								iconClass="fas fa-plus"
								kind="primary"
								onClick={() => this.setState({ isAddModalOpen: true })}
								id="btn_add"
								classes={["m-1"]}
								data-tip="Προσθήκη Βαθμολογίας"
							/>
							<Button
								oval
								type="button"
								iconClass="fas fa-pencil-alt"
								kind="info"
								onClick={() => this.setState({ isEditModalOpen: true, modalReadonly: false })}
								id="btn_edit"
								classes={["m-1"]}
								disabled={this.state.selectedRow.length === 0}
								data-tip="Επεξεργασία Βαθμολογίας"
							/>
							<Button
								oval
								type="button"
								iconClass="fas fa-eye"
								kind="green"
								onClick={() => this.setState({ isEditModalOpen: true, modalReadonly: true })}
								id="btn"
								classes={["m-1"]}
								disabled={this.state.selectedRow.length === 0}
								data-tip="Προβολή Βαθμολογίας"
							/>
							<Button
								oval
								type="button"
								iconClass="fas fa-trash"
								kind="danger"
								onClick={() => this.setState({ isDeleteModalOpen: true })}
								id="btn"
								classes={["m-1"]}
								disabled={this.state.selectedRow.length === 0}
								data-place="left"
								data-tip="Διαγραφή Βαθμολογίας"
							/>
						</>
					}
				>
					<Datatable
						columns={columns.concat(basicLessonsCol)}
						defaultSortFieldId={2}
						exportCsv
						exportFilename="Πίνακας Αποτελεσμάτων Πανελλαδικών Μαθητολογίου Πολύτροπο"
						filteredData={this.state.filteredData}
						filterText={this.state.filterText}
						onClear={() => {
							this.resetAllFilters();
						}}
						onFilter={(e) => {
							this.setFilter(e.target.value, tableData);
							this.clearSelectedRows();
						}}
						rowData={isEmpty(this.state.filterText) ? tableData : this.state.filteredData}
						selectable
						selectedRows={this.state.selectedRow}
						setSelectedRow={this.selectedRow}
						toggledClearRows={this.toggledClearRows}
					/>
					<ReactTooltip effect="solid" />
				</Card>
				<AddPanhellenicExaminationForm
					allStudents={this.props.allStudents
						.filter((student) => includes(PANHELLENIC_EXAMINATIONS_ENABLE_EPAL_CLASSES_ID, student.department.class.id))
						.filter(
							(student) =>
								!includes(
									this.props.allPanhellenicExaminationsResults.map((per) => per.student_id),
									student.id,
								),
						)}
					closeModal={() => this.setState({ isAddModalOpen: false })}
					isModalOpen={this.state.isAddModalOpen}
					resetAllFilters={this.resetAllFilters}
				/>
				{!isEmpty(this.state.selectedRow) && (
					<>
						<EditPanhellenicExaminationForm
							allStudents={this.selectedStudentsForEditForm()}
							closeModal={() => this.setState({ isEditModalOpen: false })}
							isModalOpen={this.state.isEditModalOpen}
							modalReadonly={this.state.modalReadonly}
							resetAllFilters={this.resetAllFilters}
							selectedResult={!isEmpty(this.state.selectedRow) ? this.state.selectedRow[0] : null}
						/>
					</>
				)}
				<Modal
					isOpen={this.state.isDeleteModalOpen}
					header={
						<>
							<em className="fas fa-trash float-left fa-1_2x mr-3" />
							Επιβεβαίωση Διαγραφής
						</>
					}
					headerBg="danger"
					loading={this.state.loading}
					footer={
						<>
							<Button
								text="Επιβεβαίωση"
								type="button"
								kind="success"
								onClick={this.onDeleteGradeHandler}
							/>
							<Button
								text="Κλείσιμο"
								kind="secondary"
								type="button"
								onClick={() => this.setState({ isDeleteModalOpen: false })}
							/>
						</>
					}
					onClose={() => this.setState({ isDeleteModalOpen: false })}
				>
					<div>
						<strong>Είστε σίγουρος ότι επιθυμείτε να προβείτε στη διαγραφή της βαθμολογίας;</strong>
						<p className="mt-2">
							{" "}
							<i className="fas fa-exclamation-circle mr-1 text-primary fa-120x"></i>Προσοχή: Η ενέργεια δεν θα μπορεί να αναιρεθεί.
						</p>
					</div>
				</Modal>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		allFetchingFinished: allFetchingFinished(state),
		allPanhellenicExaminationsResults: allPanhellenicExaminationsResults(state),
		allStudents: allStudents(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		deletePanhellenicExaminationResult: (perId) => dispatch(deletePanhellenicExaminationResult(perId)),
		deletePanhellenicExaminationResultFail: (error) => dispatch(deletePanhellenicExaminationResultFail(error)),
		deletePanhellenicExaminationResultSuccess: (perData) => dispatch(deletePanhellenicExaminationResultSuccess(perData)),
		setApiResponse: (theApiResponse) => dispatch(apiResponse(theApiResponse)),
	};
};

EpalPanhellenicExaminations.propTypes = {
	allFetchingFinished: PropTypes.bool,
	allPanhellenicExaminationsResults: PropTypes.arrayOf(PropTypes.exact(PanhellenicExaminationsShape)),
	allStudents: PropTypes.arrayOf(PropTypes.exact(StudentFromSelectorShape)),
	deletePanhellenicExaminationResult: PropTypes.func,
	deletePanhellenicExaminationResultFail: PropTypes.func,
	deletePanhellenicExaminationResultSuccess: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(EpalPanhellenicExaminations);
