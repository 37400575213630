import { deleteResource, postResource } from "../../BackendAPI/AbstractCRUDActions";
import { ActionKinds } from "../../BackendAPI/ActionKinds";
import { getTeacherDepartmentsUrl } from "../../BackendAPI/BackendAPI";
import {
	ADD_NEW_TEACHER_DEPARTMENTS_FAIL,
	ADD_NEW_TEACHER_DEPARTMENTS_START,
	ADD_NEW_TEACHER_DEPARTMENTS_SUCCESS,
	DELETE_TEACHER_DEPARTMENTS_FAIL,
	DELETE_TEACHER_DEPARTMENTS_START,
	DELETE_TEACHER_DEPARTMENTS_SUCCESS,
} from "./actionTypes";

export const addNewTeacherDepartmentsStart = () => {
	return {
		type: ADD_NEW_TEACHER_DEPARTMENTS_START,
	};
};

export const addNewTeacherDepartmentsSuccess = (newTeacherDepartmentsData) => {
	return {
		type: ADD_NEW_TEACHER_DEPARTMENTS_SUCCESS,
		payload: newTeacherDepartmentsData,
	};
};

export const addNewTeacherDepartmentsFail = (error) => {
	return {
		type: ADD_NEW_TEACHER_DEPARTMENTS_FAIL,
		payload: error,
	};
};

export const addNewTeacherDepartments = (newTeacherDepartmentsData) => {
	return (dispatch) => {
		dispatch(addNewTeacherDepartmentsStart());
		const url = getTeacherDepartmentsUrl(ActionKinds.POST);
		return postResource(url, newTeacherDepartmentsData);
	};
};

export const deleteTeacherDepartmentsStart = () => {
	return {
		type: DELETE_TEACHER_DEPARTMENTS_START,
	};
};

export const deleteTeacherDepartmentsSuccess = (teacherDepartmentsData) => {
	return {
		type: DELETE_TEACHER_DEPARTMENTS_SUCCESS,
		payload: teacherDepartmentsData,
	};
};

export const deleteTeacherDepartmentsFail = (error) => {
	return {
		type: DELETE_TEACHER_DEPARTMENTS_FAIL,
		payload: error,
	};
};

export const deleteTeacherDepartments = (teacherId) => {
	return (dispatch) => {
		dispatch(deleteTeacherDepartmentsStart());
		const url = getTeacherDepartmentsUrl(ActionKinds.DELETE, teacherId);
		return deleteResource(url);
	};
};
