import { isEmpty, reverse } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { DepartmentShape } from "../../../Models/DepartmentShape";
import { GradeShape } from "../../../Models/GradeShape";
import { StudentFromSelectorShape } from "../../../Models/StudentShape";
import { UserFromSelectorShape } from "../../../Models/UserShape";
import { allDepartments } from "../../../Redux/Selectors/departmentsSelectors";
import { allGrades } from "../../../Redux/Selectors/gradesSelectors";
import { allStudents } from "../../../Redux/Selectors/studentsSelectors";
import { allUsers } from "../../../Redux/Selectors/userSelectors";
import { STATUSES } from "../../../Utils/ParametricTablesBinds";
import Badge from "../../UI/Badge/Badge";
import Card from "../../UI/Card/Card";
import CustomChart from "../../UI/Chart/CustomChart";
import { Col, Row } from "../../UI/Grid/Grid";

class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		document.title = process.env.REACT_APP_NAME;
	}

	render() {
		if (isEmpty(this.props.allStudents)) {
			return (
				<Row>
					<Col
						span={12}
						size="lg"
					>
						<p className="text-danger text-bold text-center">
							Δε βρέθηκαν στοιχεία που αφορούν στο προφίλ σας για το συγκεκριμένο σχολικό έτος και περίοδο μαθημάτων.
						</p>
					</Col>
				</Row>
			);
		} else {
			const student = this.props.allStudents[0];
			return (
				<>
					<Row>
						<Col
							xl={6}
							lg={6}
						>
							<Card
								title="Προσωπικά Στοιχεία"
								id="personalInfoCard"
								headerSpannig={[8, 4]}
								extraOnHeader={<i className="fas fa-user-tag fa-2x"></i>}
							>
								<div className="table-responsive">
									<table className="table">
										<tbody>
											<tr>
												<td style={{ borderTop: 0 }}>
													<b>Όνομα</b>
												</td>
												<td style={{ borderTop: 0 }}>{student.firstname}</td>
											</tr>
											<tr>
												<td>
													<b>Επίθετο</b>
												</td>
												<td>{student.lastname}</td>
											</tr>
											<tr>
												<td>
													<b>Email</b>
												</td>
												<td>{student.email}</td>
											</tr>
											<tr>
												<td>
													<b>Κινητό Τηλ.</b>
												</td>
												<td>{student.mobile_phone}</td>
											</tr>
											<tr>
												<td>
													<b>Σταθερό Τηλ.</b>
												</td>
												<td>{student.landline}</td>
											</tr>
											<tr>
												<td>
													<b>Διεύθυνση</b>
												</td>
												<td>{student.fullAddress}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</Card>
						</Col>
						<Col
							xl={6}
							lg={6}
						>
							<Card
								title="Στοιχεία Φοίτησης"
								id="studiesInfoCard"
								headerSpannig={[8, 4]}
								extraOnHeader={<i className="fas fa-user-graduate fa-2x"></i>}
							>
								<div className="table-responsive">
									<table className="table">
										<tbody>
											<tr>
												<td style={{ borderTop: 0 }}>
													<b>Τάξη</b>
												</td>
												<td style={{ borderTop: 0 }}>{student.department.class.description}</td>
											</tr>
											<tr>
												<td>
													<b>Τμήμα</b>
												</td>
												<td>{student.department.name}</td>
											</tr>
											<tr>
												<td>
													<b>Ημ. Εγγραφής</b>
												</td>
												<td>{moment(student.submission_date).format("ddd, DD/MM/YYYY")}</td>
											</tr>
											<tr>
												<td>
													<b>Κατάσταση</b>
												</td>
												<td>
													{
														<Badge
															kind={student.status.id === STATUSES.DISABLED ? "danger" : "success"}
															text={student.status.description}
														/>
													}
												</td>
											</tr>
											<tr>
												<td>
													<b>Αριθμός Βαθμολογιών</b>
												</td>
												<td>{this.props.allGrades.length}</td>
											</tr>
											<tr>
												<td>
													<b>Τελ. Βαθμολογία</b>
												</td>
												<td>{!isEmpty(this.props.allGrades) ? moment(this.props.allGrades[0].exam_date).format("ddd, DD/MM/YYYY") : "-"}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</Card>
						</Col>
					</Row>
					<Row>
						<Col>
							<Card
								title="Η Πρόοδός σας"
								id="studentProgressCard"
								headerSpannig={[8, 4]}
								extraOnHeader={<i className="fas fa-chart-line fa-2x"></i>}
							>
								{!isEmpty(this.props.allGrades) ? (
									<div style={{ height: "350px" }}>
										<CustomChart
											id={"lessonGradesProgressChart"}
											type="line"
											data={{
												labels: reverse(
													this.props.allGrades
														.filter((grade) => grade.grade !== null)
														.map((grade) => {
															return moment(grade.exam_date).format("DD/MM/YYYY") + " (" + grade.lesson.description + ")";
														}),
												),
												datasets: [
													{
														data: reverse(this.props.allGrades.filter((grade) => grade.grade !== null).map((grade) => grade.grade)),
														backgroundColor: "rgba(114,102,186,0.2)",
														borderColor: "rgba(114,102,186,1)",
														pointBorderColor: "#fff",
														pointRadius: 5,
														pointBackgroundColor: "#443d71",
														fill: true,
														lineTension: 0.3,
													},
												],
											}}
											scales={{
												y: {
													suggestedMin: 0,
													suggestedMax: 100,
													ticks: {
														stepSize: 20,
													},
												},
											}}
										/>
									</div>
								) : (
									<p className="text-danger text-bold text-center my-2">Δεν έχουν καταχωρηθεί βαθμολογίες</p>
								)}
							</Card>
						</Col>
					</Row>
				</>
			);
		}
	}
}

const mapStateToProps = (state) => {
	return {
		allStudents: allStudents(state),
		allDepartments: allDepartments(state),
		allGrades: allGrades(state),
		allUsers: allUsers(state),
	};
};

Dashboard.propTypes = {
	allDepartments: PropTypes.arrayOf(PropTypes.exact(DepartmentShape)),
	allStudents: PropTypes.arrayOf(PropTypes.exact(StudentFromSelectorShape)),
	allUsers: PropTypes.arrayOf(PropTypes.exact(UserFromSelectorShape)),
	allGrades: PropTypes.arrayOf(PropTypes.exact(GradeShape)),
};

export default withRouter(connect(mapStateToProps)(Dashboard));
