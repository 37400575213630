import { has, includes, isEmpty } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import { DepartmentShape } from "../../../../Models/DepartmentShape";
import { StudentFromSelectorShape } from "../../../../Models/StudentShape";
import { allDepartments } from "../../../../Redux/Selectors/departmentsSelectors";
import { allStudents } from "../../../../Redux/Selectors/studentsSelectors";
import { getParametricAsOptions } from "../../../../Utils/GuiUtils";
import { clone } from "../../../../Utils/Utils";
import BaseInput from "../../../UI/BaseInput/BaseInput";
import Button from "../../../UI/Button/Button";
import { Col, Row } from "../../../UI/Grid/Grid";
import { MODAL_SIZE } from "../../../UI/Modal/constants/ModalSize";
import Modal from "../../../UI/Modal/Modal";

class FilterStudentsForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterStudentsForm: {
				firstname: {
					label: "Όνομα Μαθητή",
					name: "firstname",
					placeholder: "Όνομα Μαθητή",
					searchKind: "text",
					value: "",
					colSpan: 4,
				},
				lastname: {
					label: "Επίθετο Μαθητή",
					name: "lastname",
					placeholder: "Επίθετο Μαθητή",
					searchKind: "text",
					value: "",
					colSpan: 4,
				},
				class: {
					label: "Τάξη",
					name: "class",
					options: getParametricAsOptions("classes"),
					placeholder: "Τάξη",
					searchKind: "dropdown",
					value: null,
					colSpan: 4,
				},
				department: {
					label: "Τμήμα",
					name: "department",
					options: this.getDepartmentDropdown(),
					placeholder: "Τμήμα",
					searchKind: "dropdown",
					value: null,
					colSpan: 4,
				},
				submission_date_from: {
					label: "Ημ. Εγγραφής Από",
					name: "submission_date_from",
					datepicker: true,
					searchKind: "date_from",
					selected: null,
					colSpan: 4,
				},
				submission_date_until: {
					label: "Ημ. Εγγραφής Έως",
					name: "submission_date_until",
					datepicker: true,
					searchKind: "date_until",
					selected: null,
					colSpan: 4,
				},
			},
		};
	}

	componentDidUpdate(prevProps, prevState) {
		// The following is introduced so to reset all filters when the reset filter button clicked in StudentManagement component
		if (prevProps.isDataFiltered && !this.props.isDataFiltered) {
			this.onReset();
		}
	}

	onChangeHandler = (selectedOption, inputId) => {
		const updatedForm = clone(this.state.filterStudentsForm);
		const updatedFormElement = clone(updatedForm[inputId]);
		//the below code is for the React Datepicker, so it has to be js Date object
		const updatedFormDateKeys = Object.values(updatedForm)
			.filter((item) => item.datepicker && item.selected !== null)
			.map((item) => item.name);
		updatedFormDateKeys.forEach((element) => {
			updatedForm[element].selected = new Date(updatedForm[element].selected);
		});

		if (has(updatedFormElement, "options")) {
			updatedFormElement.value = selectedOption;
		} else {
			if (updatedFormElement.isNumber) {
				updatedFormElement.value = Number(selectedOption.target.value);
			} else if (updatedFormElement.datepicker) updatedFormElement.selected = selectedOption;
			else updatedFormElement.value = selectedOption.target.value;
		}

		updatedForm[inputId] = updatedFormElement;
		this.setState({ filterStudentsForm: updatedForm });
	};

	isFilterEntered = () => {
		let entered = false;
		for (let key in this.state.filterStudentsForm) {
			if (this.state.filterStudentsForm[key].isNumber && Number(this.state.filterStudentsForm[key].value !== 0)) entered = true;
			else if (this.state.filterStudentsForm[key].datepicker && !isEmpty(this.state.filterStudentsForm[key].selected?.toString())) entered = true;
			else if (!isEmpty(this.state.filterStudentsForm[key].value)) entered = true;
		}
		return entered;
	};

	onFormSubmit = () => {
		let filteredStudents = clone(this.props.allStudents);
		for (let key in this.state.filterStudentsForm) {
			if (
				(!isEmpty(this.state.filterStudentsForm[key].value) && this.state.filterStudentsForm[key].value !== null) ||
				!isEmpty(this.state.filterStudentsForm[key].selected?.toString()) ||
				(this.state.filterStudentsForm[key].isNumber && Number(this.state.filterStudentsForm[key].value !== 0))
			) {
				switch (this.state.filterStudentsForm[key].searchKind) {
					case "text":
						filteredStudents = filteredStudents.filter((student) =>
							includes(student[key].toLowerCase(), this.state.filterStudentsForm[key].value.toLowerCase()),
						);
						break;
					case "dropdown":
						if (key === "class")
							filteredStudents = filteredStudents.filter(
								(student) => student.department.class.id === Number(this.state.filterStudentsForm[key].value.value),
							);
						else
							filteredStudents = filteredStudents.filter(
								(student) => student.department.id === Number(this.state.filterStudentsForm[key].value.value),
							);
						break;
					case "date_from":
						filteredStudents = filteredStudents.filter((student) =>
							moment(student.submission_date).isSameOrAfter(moment(this.state.filterStudentsForm[key].selected, "DD/MM/YYYY")),
						);
						break;
					case "date_until":
						filteredStudents = filteredStudents.filter((student) =>
							moment(student.submission_date).isSameOrBefore(moment(this.state.filterStudentsForm[key].selected, "DD/MM/YYYY")),
						);
						break;
					default:
						break;
				}
			}
		}
		this.props.setFilteredData(filteredStudents, true);
		this.props.closeModal();
	};

	onReset = () => {
		this.emptyAllFormFields();
		this.props.setFilteredData(null, false);
	};

	emptyAllFormFields = () => {
		const updatedForm = clone(this.state.filterStudentsForm);
		for (let key in updatedForm) {
			const updatedFormElement = clone(updatedForm[key]);
			if (updatedFormElement.isNumber) updatedFormElement.value = 0;
			else if (updatedFormElement.datepicker) updatedFormElement.selected = null;
			else if (has(updatedFormElement, "options")) updatedFormElement.value = null;
			else updatedFormElement.value = "";
			updatedForm[key] = updatedFormElement;
			this.setState({ filterStudentsForm: updatedForm });
		}
	};

	getDepartmentDropdown = () => {
		const updatedDepartments = clone(this.props.allDepartments);
		return updatedDepartments.map((department) => {
			return {
				value: department.id,
				label: department.name,
			};
		});
	};

	render() {
		const formElementsArray = [];
		for (let key in this.state.filterStudentsForm) {
			formElementsArray.push({
				id: key,
				config: this.state.filterStudentsForm[key],
			});
		}

		const footer = (() => {
			return (
				<>
					<Button
						type="button"
						kind="success"
						onClick={this.onFormSubmit}
						disabled={!this.isFilterEntered()}
						text="Αποθήκευση"
					/>
					<Button
						type="button"
						kind="primary"
						onClick={this.onReset}
						id="reset-filters"
						text="Επαναφορά"
					/>
					<Button
						type="button"
						kind="secondary"
						text="Κλείσιμο"
						onClick={() => this.props.closeModal()}
					/>
				</>
			);
		})();

		return (
			<Modal
				isOpen={this.props.isModalOpen}
				header={
					<>
						<em className="fas fa-filter float-left fa-1_2x mr-3" />
						Φίλτρα Αναζήτησης Μαθητή
					</>
				}
				headerBg="purple"
				size={MODAL_SIZE.lg}
				footer={footer}
				onClose={() => this.props.closeModal()}
			>
				<form>
					<Row classes={["px-2"]}>
						{formElementsArray.map((element, idx) => {
							return (
								<Col
									xl={element.config.colSpan}
									lg={element.config.colSpan}
									md={6}
									sm={6}
									classes={["mb-4", "px-2"]}
									key={idx}
								>
									<label>
										{element.config.label} {element.config.required ? <span className="reqField">(*)</span> : null}
									</label>
									{element.config.datepicker ? (
										<BaseInput
											key={element.config.name + element.config.id}
											name={element.config.name}
											onChange={(selectedOption) => {
												this.onChangeHandler(selectedOption, element.id);
											}}
											datepicker={element.config.datepicker}
											selected={element.config.selected}
										/>
									) : (
										<BaseInput
											key={element.config.name + element.config.id}
											name={element.config.name}
											placeholder={element.config.placeholder}
											type={element.config.inputType}
											value={element.config.value}
											options={element.config.options}
											onChange={(selectedOption) => {
												this.onChangeHandler(selectedOption, element.id);
											}}
										/>
									)}
								</Col>
							);
						})}
					</Row>
				</form>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		allDepartments: allDepartments(state),
		allStudents: allStudents(state),
	};
};

FilterStudentsForm.propTypes = {
	allDepartments: PropTypes.arrayOf(PropTypes.exact(DepartmentShape)),
	allStudents: PropTypes.arrayOf(PropTypes.exact(StudentFromSelectorShape)),
	isModalOpen: PropTypes.bool,
	isDataFiltered: PropTypes.bool,
	setFilteredData: PropTypes.func,
	closeModal: PropTypes.func,
};

export default connect(mapStateToProps, null)(FilterStudentsForm);
