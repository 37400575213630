import React, { Component } from "react";
import $ from "jquery";
import PropTypes from "prop-types";
import { isEmpty, isEqual } from "lodash";

import ForeignLanguageLessonsForm from "./SpecialLessonForms/ForeignLanguageLessonsForm";
import DesignLessonsForm from "./SpecialLessonForms/DesignLessonsForm";
import { Row } from "../../../../../UI/Grid/Grid";
import Button from "../../../../../UI/Button/Button";
import BaseInput from "../../../../../UI/BaseInput/BaseInput";

class SpecialLessonFormsWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasForeignLanguageLesson: false,
			hasDesignLesson: false,
		};
	}

	componentDidMount() {
		this.initDataWhenEdit();
	}

	componentDidUpdate(prevProps, prevState) {
		if (!isEqual(prevProps.selectedResult, this.props.selectedResult)) {
			this.initDataWhenEdit();
		}
		if (!prevProps.executeReset && this.props.executeReset) {
			this.initDataWhenEdit();
			this.props.resetExecuteResetToggle();
		}
	}

	initDataWhenEdit = () => {
		/* the following if to ensure that the Edit button clicked and not the Add.
		 * If we havent place this if, Cannot read properties of undefined (reading 'flGrades') error should come up
		 */
		if (this.props.selectedResult) {
			if (!isEmpty(this.props.selectedResult.flGrades)) {
				this.setState({
					hasForeignLanguageLesson: true,
					hasDesignLesson: false,
				});
				$("input:radio#flRadio").prop("checked", true);
				$("input:radio#designRadio").prop("checked", false);
			} else if (!isEmpty(this.props.selectedResult.designGrades)) {
				this.setState({
					hasForeignLanguageLesson: false,
					hasDesignLesson: true,
				});
				$("input:radio#designRadio").prop("checked", true);
				$("input:radio#flRadio").prop("checked", false);
			} else {
				this.resetSelection();
			}
		}
	};

	setIsFormInvalid = (isInvalid) => {
		this.props.setIsFormInvalid(isInvalid, "SpecialLessonFormsWrapper");
	};

	setGradesError = (error) => {
		this.props.setGradesError(error, "SpecialLessonFormsWrapper");
	};

	resetSelection = () => {
		$("input:radio").prop("checked", false);
		$("input:radio").closest("label").removeClass("active");

		this.setState({
			hasDesignLesson: false,
			hasForeignLanguageLesson: false,
		});

		this.props.setIsFormInvalid(false, "SpecialLessonFormsWrapper");
		this.props.setGradesError(null, "SpecialLessonFormsWrapper");
		this.props.setSpecialLessons([], []);
	};

	render() {
		return (
			<>
				<Row
					align="center"
					classes={["mb-3", "px-3"]}
				>
					<span
						className="mr-2"
						style={{ fontWeight: 500 }}
					>
						Ειδικό Μάθημα:
					</span>
					<BaseInput
						id="flRadio"
						name="extraLesson"
						type="radio"
						value=""
						label="Ξένη Γλώσσα"
						classes={["mr-4"]}
						disabled={this.props.modalReadonly}
						onChange={(event) => {
							this.setState({
								hasForeignLanguageLesson: event.target.checked,
								hasDesignLesson: false,
							});
							this.props.setIsFormInvalid(true, "SpecialLessonFormsWrapper");
							if (!this.props.selectedResult) this.props.setSpecialLessons([], []);
							else {
								if (!isEmpty(this.props.selectedResult.flGrades)) {
									this.props.setSpecialLessons([this.props.selectedResult.flGrades[0].grade], this.props.selectedResult.flGrades);
								}
							}
						}}
					/>
					<BaseInput
						id="designRadio"
						name="extraLesson"
						type="radio"
						value=""
						label="Σχέδιο"
						classes={["mr-4"]}
						disabled={this.props.modalReadonly}
						onChange={(event) => {
							this.setState({
								hasDesignLesson: event.target.checked,
								hasForeignLanguageLesson: false,
							});
							this.props.setIsFormInvalid(true, "SpecialLessonFormsWrapper");
							if (!this.props.selectedResult) this.props.setSpecialLessons([], []);
							else {
								if (!isEmpty(this.props.selectedResult.designGrades)) {
									this.props.setSpecialLessons(
										this.props.selectedResult.designGrades.map((grade) => grade.grade),
										this.props.selectedResult.designGrades,
									);
								}
							}
						}}
					/>
					<Button
						kind="primary"
						type="button"
						classes={["btn-sm", "mt-2", "mt-lg-0"]}
						text="Εκκαθάριση Επιλογής"
						disabled={(!this.state.hasDesignLesson && !this.state.hasForeignLanguageLesson) || this.props.modalReadonly}
						onClick={this.resetSelection}
					/>
				</Row>
				{this.state.hasForeignLanguageLesson && (
					<Row classes={["px-2"]}>
						<ForeignLanguageLessonsForm
							setIsFormInvalid={this.setIsFormInvalid}
							setGradesError={this.setGradesError}
							setSpecialLessons={this.props.setSpecialLessons}
							selectedResult={this.props.selectedResult && !isEmpty(this.props.selectedResult.flGrades) ? this.props.selectedResult.flGrades : []}
							executeReset={this.props.executeReset}
							resetExecuteResetToggle={this.props.resetExecuteResetToggle}
							modalReadonly={this.props.modalReadonly}
						/>
					</Row>
				)}
				{this.state.hasDesignLesson && (
					<Row classes={["px-2"]}>
						<DesignLessonsForm
							setIsFormInvalid={this.setIsFormInvalid}
							setGradesError={this.setGradesError}
							setSpecialLessons={this.props.setSpecialLessons}
							selectedResult={
								this.props.selectedResult && !isEmpty(this.props.selectedResult.designGrades) ? this.props.selectedResult.designGrades : []
							}
							executeReset={this.props.executeReset}
							resetExecuteResetToggle={this.props.resetExecuteResetToggle}
							modalReadonly={this.props.modalReadonly}
						/>
					</Row>
				)}
			</>
		);
	}
}

SpecialLessonFormsWrapper.propTypes = {
	selectedResult: PropTypes.object,
	setSpecialLessons: PropTypes.func,
	setGradesError: PropTypes.func,
	setIsFormInvalid: PropTypes.func,
	executeReset: PropTypes.bool,
	resetExecuteResetToggle: PropTypes.func,
	modalReadonly: PropTypes.bool,
};

export default SpecialLessonFormsWrapper;
