import { useFormik } from "formik";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { flattenFormData } from "../../../Utils/Utils";

import { ActionKinds } from "../../../BackendAPI/ActionKinds";
import { getAuthUrl } from "../../../BackendAPI/BackendAPI";
import { PathEntry } from "../../../navigation";
import { removeAuthData, storeAuthData } from "../../../Utils/AuthUtils";
import { axiosPlain } from "../../../Utils/AxiosSetup";
import { permissions } from "../../../Utils/ParametricTablesBinds";
import { toFormData } from "../../../Utils/Utils";
import Alert from "../../UI/Alert/Alert";
import Button from "../../UI/Button/Button";
import { inputGroup as InputGroup } from "../../UI/InputGroup/InputGroup";
import Spinner from "../../UI/Spinner/Spinner";

const generateSchoolYearDropdownOptions = () => {
	const yearStarted = 2019;
	const currentYear = moment().year();
	const currentMonth = moment().month() + 1; //+1 because it is starting from 0
	const options = [];
	for (let i = yearStarted; i <= currentYear; i++) {
		if (i === currentYear && currentMonth < 6) {
			continue;
		}
		// curiously if I use .substring() on the following line in crush on production
		options.push({ label: `${i}-${i + 1}`, value: `${i.toString().substr(-2)}_${(i + 1).toString().substr(-2)}` });
	}
	return options;
};

const field_names = {
	USERNAME: "username",
	PASSWORD: "password",
	SCHOOL_YEAR: "school_year",
	LESSON_PERIOD: "lesson_period",
};

const username_password =
	process.env.NODE_ENV !== "development"
		? { username: "", password: "", lesson_period: null, school_year: null }
		: {
				username: "k_laskaris",
				password: "282856_kL!",
				lesson_period: { label: "Χειμερινή", value: "xeim" },
				school_year: { label: "2022-2023", value: "22_23" },
		  };
// { username: 'g_vordoni', password: '#g1ordoni@', lesson_period: { label: "Χειμερινή", value: "xeim" }, school_year: { label: "2022-2023", value: "22_23" } };

const LoginForm = (props) => {
	const history = useHistory();

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		document.title = process.env.REACT_APP_NAME + "::Είσοδος";
	}, []);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: username_password,
		onSubmit: (values) => {
			onLogin(flattenFormData(values));
		},
	});

	const onLogin = (formData) => {
		setLoading(true);
		axiosPlain
			.post(getAuthUrl(ActionKinds.POST), toFormData(formData))
			.then((response) => {
				const updatedResponse = {
					...response.data,
					data: {
						...response.data,
						period: formData.lesson_period,
						schoolYear: formData.school_year,
					},
				};
				storeAuthData(updatedResponse);
				if (Number(response.data.user.permission) === permissions.ADMIN) history.push(PathEntry.dashboard.path);
				else if (Number(response.data.user.permission) === permissions.TEACHER) history.push(PathEntry.teacherDashboard.path);
				else if (Number(response.data.user.permission) === permissions.STUDENT) history.push(PathEntry.studentDashboard.path);
			})
			.catch((error) => {
				removeAuthData();
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<>
			<form
				className="mb-3"
				id="loginForm"
				noValidate
				onSubmit={formik.handleSubmit}
			>
				<div className="form-group">
					<InputGroup
						content={
							<span className="input-group-text text-muted bg-transparent border-left-0">
								<em className="fas fa-user"></em>
							</span>
						}
						name={field_names.USERNAME}
						onChangeValues={formik.handleChange}
						placeholder="Ψευδώνυμο Χρήστη"
						placement="append"
						value={formik.values[field_names.USERNAME]}
					/>
				</div>
				<div className="form-group">
					<InputGroup
						content={
							<span className="input-group-text text-muted bg-transparent border-left-0">
								<em className="fas fa-lock"></em>
							</span>
						}
						inputType="password"
						name={field_names.PASSWORD}
						onChangeValues={formik.handleChange}
						placeholder="Συνθηματικό Χρήστη"
						placement="append"
						value={formik.values[field_names.PASSWORD]}
					/>
				</div>
				<div className="form-group">
					<InputGroup
						classes={["login-select-container"]}
						content={
							<span className="input-group-text text-muted bg-transparent border-left-0">
								<em className="fas fa-calendar-alt"></em>
							</span>
						}
						isSearchable={false}
						name={field_names.SCHOOL_YEAR}
						onChangeValues={(e) => {
							formik.handleChange(e.value.toString());
							formik.setFieldValue(field_names.SCHOOL_YEAR, e);
						}}
						options={generateSchoolYearDropdownOptions()}
						placeholder="Σχολικό Έτος"
						placement="append"
						value={formik.values[field_names.SCHOOL_YEAR]}
					/>
				</div>
				<div className="form-group">
					<InputGroup
						classes={["login-select-container"]}
						content={
							<span className="input-group-text text-muted bg-transparent border-left-0">
								<em className="fas fa-book"></em>
							</span>
						}
						isSearchable={false}
						name={field_names.LESSON_PERIOD}
						onChangeValues={(e) => {
							formik.handleChange(e.value.toString());
							formik.setFieldValue(field_names.LESSON_PERIOD, e);
						}}
						options={[
							{ label: "Θερινή", value: "ther" },
							{ label: "Χειμερινή", value: "xeim" },
						]}
						placement="append"
						placeholder="Περίοδος Μαθημάτων"
						value={formik.values[field_names.LESSON_PERIOD]}
					/>
				</div>
				<Button
					block
					classes={["mt-3"]}
					disabled={loading}
					kind="primary"
					text="Υποβολή"
					type="submit"
				/>
				{loading && (
					<div className="mt-3 d-flex">
						<span className="mr-2">Εξακρίβωση Στοιχείων</span> <Spinner type="ball-pulse-sync" />
					</div>
				)}
			</form>
			{error != null ? (
				<Alert
					dismissible
					statusCode={error.response.status}
					message={error.response.data.message !== null ? error.response.data.message : error.response.data.generic_message}
					type="danger"
					timestamp={error.response.data.timestamp}
				/>
			) : null}
		</>
	);
};

export default withRouter(LoginForm);
