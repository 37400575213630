import PropTypes from "prop-types";
import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import PdfController from "./PdfController/PdfController";

export const pdfPageChangeDirection = {
	PREVIOUS: "prev",
	NEXT: "next",
	LAST: "last",
	FIRST: "first",
};

export const pdfPageZoomLevel = {
	ZOOM_IN: "zoom_in",
	ZOOM_OUT: "zoom_out",
	RESET_ZOOM: "reset",
};

const zoomStep = 0.4;

const PdfViewer = ({ file, filename, hideFileName }) => {
	const [pageScale, setPageScale] = useState(1);
	const [pageNumber, setPageNumber] = useState(1);
	const [pageCount, setPageCount] = useState(0);

	const onDocumentLoadSuccess = ({ numPages }) => {
		setPageCount(numPages);
	};

	const onPdfPageChangeHandler = (direction) => {
		if (direction === pdfPageChangeDirection.PREVIOUS && pageNumber > 1) {
			setPageNumber(pageNumber - 1);
		} else if (direction === pdfPageChangeDirection.NEXT && pageNumber < pageCount) {
			setPageNumber(pageNumber + 1);
		} else if (direction === pdfPageChangeDirection.LAST && pageCount > 1) {
			setPageNumber(pageCount);
		} else if (direction === pdfPageChangeDirection.FIRST && pageNumber > 1) {
			setPageNumber(1);
		}
	};

	const onPdfPageZoomLevelChangeHandler = (zoomLevel) => {
		if (zoomLevel === pdfPageZoomLevel.ZOOM_IN) {
			setPageScale(pageScale + zoomStep);
		} else if (zoomLevel === pdfPageZoomLevel.ZOOM_OUT && pageScale > 1) {
			setPageScale(pageScale - zoomStep);
		} else if (zoomLevel === pdfPageZoomLevel.RESET_ZOOM && pageScale > 1) {
			setPageScale(1);
		}
	};

	const onDownloadPdfHandler = () => {
		const linkSource = file;
		const downloadLink = document.createElement("a");
		downloadLink.href = linkSource;
		downloadLink.download = filename;
		downloadLink.click();
	};

	const onPopupPdfHandler = () => {
		let pdfWindow = window.open("");
		pdfWindow.document.write("<iframe width='100%' height='99%' src='" + encodeURI(file) + "'></iframe>");
	};

	return (
		<>
			<PdfController
				onPdfPageZoomLevelChangeHandler={onPdfPageZoomLevelChangeHandler}
				onPdfPageChangeHandler={onPdfPageChangeHandler}
				onDownloadPdfHandler={onDownloadPdfHandler}
				pageNumber={pageNumber}
				pageCount={pageCount}
				pageScale={pageScale}
				pdfFilename={filename}
				onPopupPdfHandler={onPopupPdfHandler}
				hideFileName={hideFileName}
			/>
			<div
				className="border shadow-sm"
				style={{ overflow: "auto" }}
			>
				<Document
					file={file}
					onLoadSuccess={onDocumentLoadSuccess}
					className="pdf-document"
				>
					<Page
						pageNumber={pageNumber}
						scale={pageScale}
						className="pdf-page"
					/>
				</Document>
			</div>
		</>
	);
};

PdfViewer.propTypes = {
	file: PropTypes.string.isRequired,
	filename: PropTypes.string.isRequired,
	hideFileName: PropTypes.bool,
};

export default PdfViewer;
