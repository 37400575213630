import React, { Component } from "react";
import moment from "moment";

import Logo from "../../../../assets/images/politropo_logo.png";
import { EKTHESI_ID, MATH_OP_ID } from "../../../../Utils/ParametricTablesBinds";
import CustomChart from "../../../UI/Chart/CustomChart";

class GradeReportPreview extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.chartBgColors = [
			"rgb(0, 128, 0)",
			"rgb(0, 0, 255)",
			"rgb(255, 0, 255)",
			"rgb(169, 169, 169)",
			"rgb(255, 225, 25)",
			"rgb(210, 245, 60)",
			"rgb(127, 255, 0)",
			"rgb(139, 0, 139)",
			"rgb(255, 215, 0)",
		];
		this.chartBorderColors = [
			"rgba(0, 128, 0, 0.7)",
			"rgba(0, 0, 255, 0.7)",
			"rgba(255, 0, 255, 0.7)",
			"rgba(169, 169, 169, 0.7)",
			"rgba(255, 225, 25, 1, 0.7)",
			"rgba(210, 245, 60, 1, 0.7)",
			"rgba(127, 255, 0, 0.7)",
			"rgba(139, 0, 139, 0.7)",
			"rgba(255, 215, 0, 0.7)",
		];
		this.mathBgColor = "rgb(255, 165, 0)";
		this.mathBorderColor = "rgba(255, 165, 0, 0.7)";
		this.ekthesiBgColor = "rgb(139, 0, 0)";
		this.ekthesiBorderColor = "rgba(139, 0, 0, 0.7)";
	}

	exportHtml = () => {
		let output = `<html><head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <title>Αναφορά Βαθμολογιών Πολύτροπο ${moment().format("DD_MM_YYYY HH_mm_ss")}</title>
        <style>
            table {
                border: 1px solid #dee2e6;
                width: 100%;
                max-width: 100%;
                margin-top: .75rem;
                border-collapse: collapse;
                font-size: 14px;
            }
            table th {
                vertical-align: middle;
                border-bottom: 2px solid #dee2e6;
                padding: .75rem;
                text-align: left;
                font-size: 14.5px;
                -webkit-print-color-adjust: exact;
            }
            table td {
                vertical-align: middle;
                border: 1px solid #dee2e6;
                padding: .55rem;
                font-size: 13px;
            }
            table td.td-header {
                font-weight: 600;
                padding-left: .9rem;
                font-size: 14px;
            }
            table td:last-child {
                text-align: center;
            }
            html,
            body {
                width: 210mm;
                height: 297mm;
                font-family: Calibri, serif;
                font-size: 14px;
            }
            footer {
                position: fixed;
                bottom: 0;
                left: 0;
                right: 0;
                text-align: center;
                font-size: 13px;
            }
            footer p {
                margin: 0;

            }
            .average-td {
                text-align: center;
                background-color: #f2f2f2;
                -webkit-print-color-adjust: exact;
            }
            .header-logo {
                display: flex;
                display: -webkit-flex;
                justify-content: space-between;
                -webkit-justify-content: space-between;
                align-items: center;
            }
            .footer-letter-header{
                color: #2E3192;
                font-weight: 600;
            }
            @media print {
                table, .graph {
                    page-break-after: always;
                }
                .header-logo-container {
                    position: fixed;
                    top: 0;
                    font-size: 13px;
                    width: 97%;
                }
                .student-table-container {
                    margin-top: 7.5rem;
                }
                .graph{
                    margin-top: 7.5rem;
                }
            }
        </style>
        </head><body>
        <div class="header-logo-container">
        <div class="header-logo">
        <img src="${Logo}" alt="AppLogo" width="170" />
        <div>
            <span><strong>Συνολική Αναφορά Βαθμολογιών</strong></span><br>
            <span><strong>Ημ. Έκδοσης:</strong> ${moment().format("ddd, DD/MM/YYYY")}</span>
        </div>
        </div>
        </div>`;
		this.props.outputToRender.forEach((item) => {
			const url_base64 = document.getElementById("reportChart" + item.student.id).toDataURL("image/png");
			const link = document.createElement("a");
			link.setAttribute("href", url_base64);

			output += `<div style="text-align: center;" class="student-table-container"><h2 style="margin-bottom: 2px;">${item.student.lastname} ${item.student.firstname}</h2><span>${item.student.department.class.description} (${item.student.department.name})</span>
            <table style="width: 100%;max-width: 100%;margin-bottom: 1rem;">
                <thead>
                    <tr><th>Μάθημα</th><th>Ημερομηνία</th><th>Είδος</th><th>Βαθμός</th></tr>
                </thead>
                <tbody>`;
			item.lessonGrades.forEach((lessonGrade) => {
				lessonGrade.grades.forEach((grade, idx) => {
					if (idx === 0) {
						output += `<tr><td class="td-header" rowspan="${lessonGrade.grades.length + 1}">${lessonGrade.lesson.description}</td><td>${moment(
							grade.exam_date,
						).format("DD/MM/YYYY")}</td><td>${grade.exam_kind.description}</td><td>${grade.grade === null ? "" : grade.grade}</td></tr>`;
					} else {
						output += `<tr><td>${moment(grade.exam_date).format("DD/MM/YYYY")}</td><td>${grade.exam_kind.description}</td><td>${
							grade.grade === null ? "" : grade.grade
						}</td></tr>`;
					}
				});
				output += `<tr><td colspan="3" class="average-td"><strong>Μέσος Όρος: ${lessonGrade.avg100
					.toString()
					.replace(".", ",")}% &nbsp ${lessonGrade.avg20.toString().replace(".", ",")}/20</strong></td></tr>`;
			});
			output += `</tbody></table></div><div class="graph" style="text-align:center;"><h2 style="margin-bottom: 2px">Διάγραμμα Προόδου</h2><p style="margin-top: 0px; margin-bottom: 15px;">${item.student.lastname} ${item.student.firstname}</p><img src="${url_base64}"/></div>`;
		});
		output += `<footer>
        <p class="footer-letter-header" style="margin-bottom: 3px; font-size: 115%; letter-spacing: 1.5px;">
            ΦΡΟΝΤΙΣΤΗΡΙΑ ΠΟΛΥΤΡΟΠΟ</p>
        <p style="font-weight :600;">ΞΑΝΘΟΥ 7 &amp; 25ης ΜΑΡΤΙΟΥ, 12 244, ΑΙΓΑΛΕΩ, ΑΘΗΝΑ</p>
        <p><span class="footer-letter-header">T:</span> (+30) 210 5900444 | <span class="footer-letter-header">E:</span> info@politropo.gr | <span class="footer-letter-header">W:</span> www.politropo.gr</p>
        </footer>
        </body></html>`;
		const wnd = window.open("about:blank", "", "_blank");
		wnd.document.write(output);
		this.props.terminateExportMechanism();
	};

	componentDidUpdate(prevProps) {
		if (!prevProps.doExportStats && this.props.doExportStats) {
			this.exportHtml();
		}
	}

	render() {
		return (
			<>
				<div style={{ position: "absolute", top: "-500000px" }}>
					{this.props.outputToRender !== null
						? this.props.outputToRender.map((item, idx) => {
								let colorCounter = 0;
								const datasets = [];
								const labels = [];
								let maxLength = 0;
								const baseLineData = [];
								item.lessonGrades.forEach((gradeItem) => {
									if (gradeItem.grades.length > maxLength) {
										maxLength = gradeItem.grades.length;
									}
									const bgColor = (() => {
										if (gradeItem.lesson.id === EKTHESI_ID) return this.ekthesiBgColor;
										else if (gradeItem.lesson.id === MATH_OP_ID) return this.mathBgColor;
										else return this.chartBgColors[colorCounter];
									})();
									const borderColor = (() => {
										if (gradeItem.lesson.id === EKTHESI_ID) return this.ekthesiBorderColor;
										else if (gradeItem.lesson.id === MATH_OP_ID) return this.mathBorderColor;
										else return this.chartBorderColors[colorCounter];
									})();
									datasets.push({
										data: gradeItem.grades.map((grade) => grade.grade).filter((grade) => grade !== null && grade !== undefined),
										label: gradeItem.lesson.description,
										borderColor: borderColor,
										backgroundColor: bgColor,
									});
									colorCounter++;
								});
								for (let i = 0; i < maxLength; i++) {
									labels.push(i.toString());
									baseLineData.push(50);
								}
								if (maxLength === 0) {
									return null;
								} else {
									datasets.push({
										data: baseLineData,
										borderColor: "#444444",
										backgroundColor: "#555555",
										label: "Βάση",
										pointRadius: 0,
									});
									return (
										<div
											style={{ height: "400px", width: "700px" }}
											className="mb-3"
											key={item.student.id}
										>
											<CustomChart
												id={`reportChart${item.student.id}`}
												type="line"
												legend={{
													legend: {
														display: true,
														position: "bottom",
														labels: {
															fontSize: 10,
															boxWidth: 28,
														},
													},
												}}
												data={{
													labels: labels,
													datasets: datasets,
												}}
												scales={{
													y: {
														suggestedMin: 0,
														suggestedMax: 100,
														ticks: {
															stepSize: 10,
														},
													},
												}}
											/>
										</div>
									);
								}
						  })
						: null}
				</div>
			</>
		);
	}
}

export default GradeReportPreview;
