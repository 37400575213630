import { globalStore } from "../../globalStore";
import { isEmpty, uniqBy, includes } from "lodash";
import { absenceKindIds } from "../../Utils/ParametricTablesBinds";
import { sortArrayOfObjectsByDate } from "../../Utils/Utils";
import { allStudents } from "./studentsSelectors";

export const studentsPerClass = (state) => {
	if (isEmpty(state.students.allStudents) || isEmpty(globalStore.classes)) {
		return [];
	}
	const output = [];
	const studentsCount = state.students.allStudents.length;
	globalStore.classes.forEach((a_class) => {
		const classStudents = allStudents(state).filter((student) => student.department.class.id === a_class.id);
		output.push({
			class: a_class,
			students: classStudents,
			percentage: ((classStudents.length / studentsCount) * 100).toFixed(1) + "%",
		});
	});
	return output;
};

export const studentsPerInformedBy = (state) => {
	if (isEmpty(state.students.allStudents) || isEmpty(globalStore.informed_by)) {
		return [];
	}
	const output = [];
	const studentsCount = state.students.allStudents.length;
	globalStore.informed_by.forEach((item) => {
		const classStudents = state.students.allStudents.filter((student) => student.informed_by === item.id);
		output.push({
			item: item,
			students: classStudents,
			percentage: ((classStudents.length / studentsCount) * 100).toFixed(1) + "%",
		});
	});
	return output;
};

export const studentsPerCounty = (state) => {
	if (isEmpty(state.students.allStudents)) {
		return [];
	}
	const allCounties = uniqBy(state.students.allStudents.map((student) => student.county));
	const output = [];
	const studentsCount = state.students.allStudents.length;
	allCounties.forEach((county) => {
		const countyStudents = state.students.allStudents.filter((student) => student.county === county);
		output.push({
			county: county,
			students: countyStudents,
			percentage: ((countyStudents.length / studentsCount) * 100).toFixed(1) + "%",
		});
	});
	return output;
};

export const studentsPerLesson = (state) => {
	if (isEmpty(state.students.allStudents) || !state.parametrics.finished) {
		return [];
	}
	const output = [];
	const studentsCount = state.students.allStudents.length;
	globalStore?.lessons?.forEach((lesson) => {
		const lessonStudents = state.students.allStudents.filter((student) => includes(student.lessons, lesson.id));
		output.push({
			lesson: lesson,
			students: lessonStudents,
			percentage: ((lessonStudents.length / studentsCount) * 100).toFixed(1) + "%",
		});
	});
	return output;
};

export const studentsPerDepartment = (state) => {
	if (isEmpty(state.students.allStudents) || isEmpty(state.departments.allDepartments)) {
		return [];
	}
	const output = [];
	const studentsCount = state.students.allStudents.length;
	state.departments.allDepartments.forEach((department) => {
		const departmentStudents = state.students.allStudents.filter((student) => student.department === department.id);
		output.push({
			department: department,
			students: departmentStudents,
			percentage: ((departmentStudents.length / studentsCount) * 100).toFixed(1) + "%",
		});
	});
	return output;
};

export const gradeAvgPerLesson = (state) => {
	const output = [];
	globalStore?.lessons?.forEach((lesson) => {
		const lessonGrades = state.grades.allGrades.filter((grade) => grade.lesson_id === lesson.id && grade.absence === absenceKindIds.PRESENT);
		let sum = 0;
		lessonGrades.forEach((grade) => {
			sum += grade.grade;
		});
		if (lessonGrades.length > 0)
			output.push({
				lesson: lesson.description,
				avg: (sum / lessonGrades.length).toFixed(1),
			});
	});
	return output;
};

export const gradesCountPerLesson = (state) => {
	const output = [];
	globalStore?.lessons?.forEach((lesson) => {
		const lessonGrades = state.grades.allGrades.filter((grade) => grade.lesson_id === lesson.id);
		if (lessonGrades.length > 0)
			output.push({
				lesson: lesson.description,
				cnt: lessonGrades.length,
			});
	});
	return output;
};

export const gradesCountPerUser = (state) => {
	const output = [];
	state.users.allUsers.forEach((user) => {
		const userGrades = state.grades.allGrades.filter((grade) => grade.teacher_id === user.id);
		if (userGrades.length > 0)
			output.push({
				user: user.lastname + " " + user.firstname,
				cnt: userGrades.length,
			});
	});
	return output;
};

export const gradeGeneralStatsPerStudent = (state, student_id, lesson_id, exam_kind_id) => {
	const allStudentGrades = state.grades.allGrades.filter((grade) => grade.student_id === student_id);
	const studentGrades = (() => {
		if (lesson_id !== -1 && exam_kind_id !== -1) {
			return allStudentGrades.filter((grade) => grade.lesson_id === lesson_id && grade.exam_kind === exam_kind_id);
		} else if (lesson_id === -1 && exam_kind_id !== -1) {
			return allStudentGrades.filter((grade) => grade.exam_kind === exam_kind_id);
		} else if (lesson_id !== -1 && exam_kind_id === -1) {
			return allStudentGrades.filter((grade) => grade.lesson_id === lesson_id);
		} else {
			return allStudentGrades;
		}
	})();
	const output = gradeGeneralStats(studentGrades);
	if (output === undefined) {
		return {
			cnt: "-",
			absence: "-",
			avg100: "-",
			avg20: "-",
			maxGrade: "-",
			minGrade: "-",
			latest: "-",
		};
	} else {
		return gradeGeneralStats(studentGrades);
	}
};

export const gradeGeneralStatsPerLesson = (state) => {
	const output = [];
	globalStore?.lessons?.forEach((lesson) => {
		const lessonGrades = state.grades.allGrades.filter((grade) => grade.lesson_id === lesson.id);
		const stat = gradeGeneralStats(lessonGrades);
		if (stat) {
			stat.lesson = lesson.description;
		}
		output.push(stat);
	});
	return output.filter((item) => item !== undefined);
};

export const gradeGeneralStatsPerStudentByTeacherId = (state, lesson_id, exam_kind_id) => {
	const output = [];
	state.students.allStudents.forEach((student, idx) => {
		const studentGrades = (() => {
			if (lesson_id !== -1 && exam_kind_id !== -1) {
				return state.grades.allGrades.filter(
					(grade) => grade.student_id === student.id && grade.lesson_id === lesson_id && grade.exam_kind === exam_kind_id,
				);
			} else if (lesson_id === -1 && exam_kind_id !== -1) {
				return state.grades.allGrades.filter((grade) => grade.student_id === student.id && grade.exam_kind === exam_kind_id);
			} else if (lesson_id !== -1 && exam_kind_id === -1) {
				return state.grades.allGrades.filter((grade) => grade.student_id === student.id && grade.lesson_id === lesson_id);
			} else {
				return state.grades.allGrades.filter((grade) => grade.student_id === student.id);
			}
		})();
		const stat = gradeGeneralStats(studentGrades);
		if (stat) {
			stat.firstname = student.firstname;
			stat.lastname = student.lastname;
			stat.student_id = student.id;
			stat.id = idx;
		}
		output.push(stat);
	});
	return output.filter((item) => item !== undefined);
};

const gradeGeneralStats = (allGrades) => {
	const gradesForPresents = allGrades.filter((grade) => grade.absence === absenceKindIds.PRESENT);
	const absenceCount = allGrades.filter((grade) => grade.absence === absenceKindIds.NOT_PRESENT).length;
	const avg100 = (() => {
		if (allGrades) {
			let sum = 0;
			gradesForPresents.forEach((grade) => {
				if (grade.absence === absenceKindIds.PRESENT) sum += grade.grade;
			});
			return sum / gradesForPresents.length;
		} else {
			return 0;
		}
	})();
	const avg20 = (avg100 * 20) / 100;
	const maxGrade = (() => {
		let max = 0;
		gradesForPresents.forEach((grade) => {
			if (grade.grade > max && grade.grade !== null) max = grade.grade;
		});
		return max;
	})();
	const minGrade = (() => {
		let min = 100;
		gradesForPresents.forEach((grade) => {
			if (grade.grade < min && grade.grade !== null) min = grade.grade;
		});
		return min;
	})();

	const latest = { ...sortArrayOfObjectsByDate(gradesForPresents, "created_at", "desc")[0] };
	if (allGrades.length > 0) {
		return {
			cnt: gradesForPresents.length,
			absence: absenceCount,
			avg100: avg100 ? avg100.toFixed(1) + "%" : "-",
			avg20: avg20 ? avg20.toFixed(1) : "-",
			maxGrade: avg100 ? maxGrade + "%" : "-",
			minGrade: avg100 ? minGrade + "%" : "-",
			latest: !isEmpty(latest) ? latest.created_at : "-",
		};
	}
};
