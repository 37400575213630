import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Button from "../../../Components/UI/Button/Button";
import { PathEntry } from "../../../navigation";
import { setChangePasswordModalOpen } from "../../../Redux/Actions";
import { getAuthData } from "../../../Utils/AuthUtils";

class UserDataBox extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div className="dropdown-menu dropdown-menu-right animated flipInX">
				<div className="dropdown-item">
					<div className="list-group">
						<div className="list-group-item list-group-item-action py-3">
							<span className="d-flex align-items-center">
								<Button
									classes={["w-100"]}
									type="button"
									onClick={() => this.props.setChangePasswordModalOpen(Number(getAuthData().user_id))}
									kind="purple"
									text="Αλλαγή Κωδικού"
									iconClass="fas fa-key mr-1"
								/>
							</span>
						</div>
						<div className="list-group-item list-group-item-action text-center py-3">
							<Button
								classes={["w-100"]}
								type="button"
								onClick={() => this.props.history.push(PathEntry.logout.path)}
								kind="danger"
								text="Αποσύνδεση"
								iconClass="fas fa-sign-out-alt mr-1"
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setChangePasswordModalOpen: (userId) => dispatch(setChangePasswordModalOpen(userId)),
	};
};

export default withRouter(connect(null, mapDispatchToProps)(UserDataBox));
