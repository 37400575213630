import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { memo } from "react";

const Button = memo(({ iconClass, text, tooltip, classes, block, disabled, kind, onClick, oval, style, type, ...rest }) => {
	const allClasses = `btn btn-${kind}${oval ? " btn-oval" : ""}${block ? " btn-block" : ""} ${!isEmpty(classes) ? classes.join(" ") : ""}`;
	return (
		<button
			className={allClasses}
			type={type}
			onClick={onClick}
			disabled={disabled}
			style={style}
			title={tooltip}
			{...rest}
		>
			<em className={iconClass}></em> {text}
		</button>
	);
});

Button.propTypes = {
	block: PropTypes.bool,
	classes: PropTypes.array,
	disabled: PropTypes.bool,
	iconClass: PropTypes.string,
	kind: PropTypes.oneOf(["primary", "secondary", "info", "warning", "danger", "success", "purple", "green", "link", "muted", "inverse"]),
	onClick: PropTypes.func,
	oval: PropTypes.bool,
	style: PropTypes.object,
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	tooltip: PropTypes.string,
	type: PropTypes.oneOf(["submit", "button"]).isRequired,
};

export default Button;
