import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import App from "./App";
import ScrollToTop from "./Components/UI/ScrollToTop/ScrollToTop";
import "./index.css";
import apiResponseReducer from "./Redux/Reducers/apiResponseReducer";
import modalsReducer from "./Redux/Reducers/changePasswordModalReducer";
import changePasswordReducer from "./Redux/Reducers/changePasswordReducer";
import departmentsReducer from "./Redux/Reducers/departmentsReducer";
import gradesReducer from "./Redux/Reducers/gradesReducer";
import panhellenicExaminationsReducer from "./Redux/Reducers/panhellenicExaminationsReducer";
import parametricsReducer from "./Redux/Reducers/parametricsReducer";
import scientificFieldLessonsReducer from "./Redux/Reducers/scientificFieldLessonsReducer";
import selfTestAnswersReducer from "./Redux/Reducers/selfTestAnswersReducer";
import selfTestChaptersReducer from "./Redux/Reducers/selfTestChaptersReducer";
import selfTestsReducer from "./Redux/Reducers/selfTestsReducer";
import shortcutsReducer from "./Redux/Reducers/shortcutsReducer";
import studentReducer from "./Redux/Reducers/studentsReducer";
import userReducer from "./Redux/Reducers/userReducer";
// JS Vendors
import "bootstrap/dist/js/bootstrap";
// Fontawesome icons
import "./assets/vendors/@fortawesome/fontawesome-free-webfonts/css/fa-brands.css";
import "./assets/vendors/@fortawesome/fontawesome-free-webfonts/css/fa-regular.css";
import "./assets/vendors/@fortawesome/fontawesome-free-webfonts/css/fa-solid.css";
import "./assets/vendors/@fortawesome/fontawesome-free-webfonts/css/fontawesome.css";
//css style files
/* prettier-ignore */
import "./assets/css/loaders.css";
/* prettier-ignore */
import "./assets/css/bootstrap.css";
/* prettier-ignore */
import "./assets/css/app.css";
/* prettier-ignore */
import "./assets/css/theme.css";

const composeEnhancers =
	(process.env.NODE_ENV !== "production" &&
		typeof window !== "undefined" &&
		//@ts-expect-error
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
	compose;

const rootReducer = combineReducers({
	departments: departmentsReducer,
	parametrics: parametricsReducer,
	users: userReducer,
	shortcuts: shortcutsReducer,
	students: studentReducer,
	grades: gradesReducer,
	panhellenicExaminations: panhellenicExaminationsReducer,
	changePassword: changePasswordReducer,
	scientificFieldLessons: scientificFieldLessonsReducer,
	selfTestChapters: selfTestChaptersReducer,
	selfTests: selfTestsReducer,
	selfTestAnswers: selfTestAnswersReducer,
	apiResponse: apiResponseReducer,
	changePasswordModal: modalsReducer,
});

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

const app = (
	<Provider store={store}>
		<BrowserRouter>
			<ScrollToTop />
			<App />
		</BrowserRouter>
	</Provider>
);

if (process.env.NODE_ENV !== "development") {
	console.log = () => {};
}

ReactDOM.render(app, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
