import { isEmpty } from "lodash";

const AUTH_TOKEN_KEY = "access_token";
const USER_ID = "user_id";
const USERNAME = "username";
const PERMISSION = "permission";
const PERIOD = "period";
const SCHOOL_YEAR = "school_year";

export const storeAuthData = (authData) => {
	removeAuthData();
	sessionStorage.setItem(AUTH_TOKEN_KEY, authData.data.token);
	sessionStorage.setItem(USER_ID, authData.data.user.id);
	sessionStorage.setItem(USERNAME, authData.data.user.username);
	sessionStorage.setItem(PERMISSION, authData.data.user.permission);
	sessionStorage.setItem(PERIOD, authData.data.period);
	sessionStorage.setItem(SCHOOL_YEAR, authData.data.schoolYear);
};

export const removeAuthData = () => {
	sessionStorage.removeItem(AUTH_TOKEN_KEY);
	sessionStorage.removeItem(USER_ID);
	sessionStorage.removeItem(USERNAME);
	sessionStorage.removeItem(PERMISSION);
	sessionStorage.removeItem(PERIOD);
	sessionStorage.removeItem(SCHOOL_YEAR);
};

export const getAuthData = () => {
	let displayPeriod = null;
	let displaySchoolYear = null;

	if (sessionStorage.getItem(PERIOD) != null) {
		displayPeriod = sessionStorage.getItem(PERIOD) === "xeim" ? "Χειμερινή" : "Θερινή";
		const tmp = sessionStorage.getItem(SCHOOL_YEAR).split("_");
		displaySchoolYear = `20${tmp[0]}-20${tmp[1]}`;
	}

	return {
		token: sessionStorage.getItem(AUTH_TOKEN_KEY),
		user_id: sessionStorage.getItem(USER_ID),
		username: sessionStorage.getItem(USERNAME),
		permission: sessionStorage.getItem(PERMISSION),
		period: {
			original: sessionStorage.getItem(PERIOD),
			display: displayPeriod,
		},
		schoolYear: {
			original: sessionStorage.getItem(SCHOOL_YEAR),
			display: displaySchoolYear,
		},
	};
};

export const isAuthenticated = () => {
	return !isEmpty(getAuthData().token);
};

export function createAxiosAuthHeader() {
	const authData = getAuthData();
	const headers = {
		Authtoken: authData.token,
	};
	return headers;
}
