import $ from "jquery";
import { merge } from "lodash";

import Alert from "../Components/UI/Alert/Alert";
import Badge from "../Components/UI/Badge/Badge";
import { Col, Row } from "../Components/UI/Grid/Grid";
import {
	absenceKindIds,
	CHEMISTRY_ID,
	EKTHESI_ID,
	examKindIds,
	FINANCE_ID,
	HISTORY_ID,
	INFORMATICS_ID,
	MATH_OP_ID,
	PHYSICS_ID,
} from "./ParametricTablesBinds";

import Spinner from "../Components/UI/Spinner/Spinner";
import { globalStore, listConstants } from "../globalStore";

export const getParametricAsOptions = (which, firstField = null, substringed) => {
	let output = [];
	const parametricsArray = merge(globalStore, listConstants);
	if (firstField) {
		output = [firstField];
	}
	for (let i in parametricsArray[which]) {
		output.push({
			label: substringed
				? parametricsArray[which][i].description.substring(0, parametricsArray[which][i].description.length - 1)
				: parametricsArray[which][i].description,
			value: parametricsArray[which][i].id,
		});
	}
	return output;
};

export const getListConstantsAsOptions = (firstField) => {
	let output = [];
	if (firstField) {
		output = [firstField];
	}
	for (let key in globalStore.parametricsGreekNameMapping) {
		output.push({
			label: globalStore.parametricsGreekNameMapping[key],
			value: key,
		});
	}
	return output;
};

export const getLessonsAsCheckboxesGroup = (lessons, isChecked) => {
	const lessonCheckboxes = {};
	for (let i in lessons) {
		const lesson = lessons[i];
		lessonCheckboxes[lesson.description] = {
			label: lesson.description,
			name: lesson.description,
			value: lesson.id,
			inputType: "checkbox",
			colSpan: 3,
			isChecked: isChecked !== undefined ? isChecked(lesson) : false,
		};
	}
	return lessonCheckboxes;
};

export const getDepartmentsAsCheckboxesGroup = (departments, isChecked) => {
	const departmentCheckboxes = {};
	for (let i in departments) {
		const department = departments[i];
		departmentCheckboxes[department.name] = {
			label: department.name,
			name: department.name,
			value: department.id,
			inputType: "checkbox",
			extraClass: "col-md-4",
			colSpan: 3,
			isChecked: isChecked !== undefined ? isChecked(department) : false,
		};
	}
	return departmentCheckboxes;
};

export const renderWait = (message) => {
	return (
		<div className="d-flex">
			<h5 className="mr-2">{message}</h5>
			<Spinner type="ball-pulse-sync" />
		</div>
	);
};

export const renderAPIMessages = (APIMessages, callback) => {
	if (APIMessages.error !== null) {
		const theMessage = (() => {
			if (APIMessages.error.data !== null && APIMessages.error.data !== undefined) {
				return APIMessages.error.data.message !== null ? APIMessages.error.data.message : APIMessages.error.data.generic_message;
			} else {
				return APIMessages.error.statusText;
			}
		})();
		const timestamp = (() => {
			if (APIMessages.error.data !== null && APIMessages.error.data !== undefined) {
				return APIMessages.error.data.timestamp;
			}
			return null;
		})();
		return (
			<Alert
				dismissible
				type="danger"
				statusCode={APIMessages.error.status}
				message={theMessage}
				onClose={() => {
					if (callback) callback();
				}}
				timestamp={timestamp}
			/>
		);
	} else if (APIMessages.info !== null) {
		return (
			<Alert
				dismissible
				type="success"
				statusCode={APIMessages.info.code ? APIMessages.info.code : 200}
				message={APIMessages.info.message}
				onClose={() => {
					if (callback) callback();
				}}
			/>
		);
	}
};

export const getGradeCountersFooter = (grades) => {
	return (
		<Row>
			<Col
				xl={7}
				lg={7}
				md={7}
				classes={["text-bold", "mb-2", "mb-md-0"]}
			>
				<Row
					justify="center"
					classes={["m-0", "justify-content-md-start"]}
				>
					<div className="mr-3">
						<Badge
							kind="purple"
							text="ΔΙΑΓΩΝΙΣΜΑ"
						/>
						<span> : {grades?.filter((grade) => grade.exam_kind.id === examKindIds.EXAM).length ?? "0"}</span>
					</div>
					<div className="mr-3">
						<Badge
							kind="primary"
							text="TEST"
						/>
						<span> : {grades?.filter((grade) => grade.exam_kind.id === examKindIds.TEST).length ?? "0"}</span>
					</div>
					<div>
						<Badge
							kind="green"
							text="ΕΡΓΑΣΙΑ"
						/>
						<span> : {grades?.filter((grade) => grade.exam_kind.id === examKindIds.STUDY).length ?? "0"}</span>
					</div>
				</Row>
			</Col>
			<Col
				xl={5}
				lg={5}
				md={5}
				classes={["text-bold", "mb-2", "mb-md-0"]}
			>
				<Row
					justify="center"
					classes={["m-0", "justify-content-md-end"]}
				>
					<div className="mr-3">
						<Badge
							kind="success"
							text="ΠΑΡΩΝ"
						/>
						<span> : {grades?.filter((grade) => grade.absence.id === absenceKindIds.PRESENT).length ?? "0"}</span>
					</div>
					<div>
						<Badge
							kind="danger"
							text="ΑΠΩΝ"
						/>
						<span> : {grades?.filter((grade) => grade.absence.id === absenceKindIds.NOT_PRESENT).length ?? "0"}</span>
					</div>
				</Row>
			</Col>
		</Row>
	);
};

export const getSelfTestKind = (selfTestAnswers) => {
	let countTrueFalse = 0;
	let countMultipleChoice = 0;
	selfTestAnswers.forEach((answer) => {
		if (answer.length > 1) {
			countTrueFalse++;
		} else {
			countMultipleChoice++;
		}
	});
	if (countTrueFalse === 0) return "Πολλαπλής Επιλογής";
	else if (countMultipleChoice === 0) return "Σωστό - Λάθος";
	else return "Μεικτό";
};

export const getSelfTestLessonIcon = (lesson_id) => {
	switch (lesson_id) {
		case MATH_OP_ID:
			return "fas fa-square-root-alt";
		case INFORMATICS_ID:
			return "fas fa-code";
		case FINANCE_ID:
			return "fas fa-chart-line";
		case CHEMISTRY_ID:
			return "fas fa-flask";
		case HISTORY_ID:
			return "fas fa-landmark";
		case EKTHESI_ID:
			return "fas fa-pen-fancy";
		case PHYSICS_ID:
			return "fas fa-drafting-compass";
		default:
			return "fas fa-file-contract";
	}
};

export const getSelfTestLessonColor = (lesson_id) => {
	switch (lesson_id) {
		case MATH_OP_ID:
			return "warning";
		case INFORMATICS_ID:
			return "purple";
		case FINANCE_ID:
			return "danger";
		case CHEMISTRY_ID:
			return "info";
		case HISTORY_ID:
			return "inverse";
		case EKTHESI_ID:
			return "green";
		case PHYSICS_ID:
			return "success";
		default:
			return "primary";
	}
};

export const renderFormLoading = () => {
	return (
		<div className="d-flex m-auto align-items-center">
			<h5 className="mr-2 mb-0">Φόρτωση, παρακαλώ περιμένετε</h5>
			<Spinner type="ball-pulse-sync" />
		</div>
	);
};

export const openModal = (id, parent) => {
	$("#" + id).modal("show");
	$(".modal-backdrop").appendTo($("#" + parent));
	$("body").removeClass("modal-open");
	$("body").css("padding-right", "");
};

export const hideModals = () => {
	$(".modal").modal("hide");
};
