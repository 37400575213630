import { removeAuthData } from "../../Utils/AuthUtils";
import { AUTH_LOGOUT, RESET_API_RESPONSE, SET_API_RESPONSE } from "./actionTypes";

export const apiResponse = (apiResponse) => {
	return {
		type: SET_API_RESPONSE,
		payload: apiResponse,
	};
};

export const resetApiResponse = () => {
	return {
		type: RESET_API_RESPONSE,
	};
};

export const logout = () => {
	removeAuthData();
	return {
		type: AUTH_LOGOUT,
	};
};
