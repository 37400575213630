import { includes, isEmpty, isEqual, uniqBy } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import ReactTooltip from "react-tooltip";

import { globalStore } from "../../../../globalStore";
import { PanhellenicExaminationsShape } from "../../../../Models/PanhellenicExaminationsShape";
import { ScientificFieldLessonsFromSelectorShape } from "../../../../Models/ScientificFieldLessonsShape";
import { StudentFromSelectorShape } from "../../../../Models/StudentShape";
import {
	apiResponse,
	deletePanhellenicExaminationResult,
	deletePanhellenicExaminationResultFail,
	deletePanhellenicExaminationResultSuccess,
} from "../../../../Redux/Actions/index";
import { allPanhellenicExaminationsResults } from "../../../../Redux/Selectors/panhellenicExaminationsSelector";
import { allScientificFieldLessons } from "../../../../Redux/Selectors/scientificFieldLessonsSelectors";
import { allStudents } from "../../../../Redux/Selectors/studentsSelectors";
import { allFetchingFinished } from "../../../../Redux/Selectors/universalSelectors";
import { PANHELLENIC_EXAMINATIONS_ENABLE_GEL_CLASSES_ID } from "../../../../Utils/ParametricTablesBinds";
import Button from "../../../UI/Button/Button";
import Card from "../../../UI/Card/Card";
import Datatable from "../../../UI/Datatable/Datatable";
import Modal from "../../../UI/Modal/Modal";
import AddPanhellenicExaminationForm from "./Forms/AddPanhellenicExaminationForm";
import EditPanhellenicExaminationForm from "./Forms/EditPanhellenicExaminationForm";

class PanhellenicExaminations extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterText: "",
			filteredData: [],
			isAddModalOpen: false,
			isDeleteModalOpen: false,
			isEditModalOpen: false,
			loading: false,
			modalReadonly: false,
			scientificFieldSelected: globalStore.scientific_fields[0],
			selectedRow: [],
		};
		this.toggledClearRows = false;
	}

	componentDidMount() {
		document.title = process.env.REACT_APP_NAME + " :: Πανελλαδικές Εξετάσεις";
	}

	componentDidUpdate(prevProps) {
		if (!isEqual(prevProps.allPanhellenicExaminationsResults, this.props.allPanhellenicExaminationsResults)) {
			this.setState({ shouldAddGradeRender: false });
		}

		if (!prevProps.allFetchingFinished && this.props.allFetchingFinished) {
			this.resetAllFilters();
		}
	}

	selectedRow = (data) => {
		this.setState({ selectedRow: data.selectedRows });
	};

	clearSelectedRows = () => {
		this.toggledClearRows = !this.toggledClearRows;
		this.setState({ selectedRow: [] });
	};

	resetAllFilters = () => {
		this.setState({
			filterText: "",
			filteredData: [],
		});
		this.clearSelectedRows();
	};

	setFilter = (filterString, tableData) => {
		const filteredItems = tableData.filter((row) => {
			return (
				`${row.student.lastname} ${row.student.firstname}`.toLowerCase().includes(filterString.toLowerCase()) ||
				`${row.student.firstname} ${row.student.lastname}`.toLowerCase().includes(filterString.toLowerCase())
			);
		});

		this.setState({
			filterText: filterString,
			filteredData: uniqBy(filteredItems, "id"),
		});
	};

	onDeleteGradeHandler = () => {
		this.setState({ loading: true });
		this.props
			.deletePanhellenicExaminationResult(!isEmpty(this.state.selectedRow) ? this.state.selectedRow[0].id : null)
			.then((response) => {
				this.props.setApiResponse({
					error: null,
					info: {
						message: (
							<>
								Η βαθμολογία της/του μαθήτριας/μαθητή{" "}
								<b>
									{this.state.selectedRow[0].student.lastname} {this.state.selectedRow[0].student.firstname}
								</b>{" "}
								διαγράφηκε με επιτυχία.
							</>
						),
					},
				});
				this.props.deletePanhellenicExaminationResultSuccess(response.data);
				this.resetAllFilters();
			})
			.catch((error) => {
				this.props.setApiResponse({
					error: error,
					info: null,
				});
				this.props.deletePanhellenicExaminationResultFail(error);
			})
			.finally(() => {
				this.resetAllFilters();
				this.setState({
					isDeleteModalOpen: false,
					loading: false,
				});
			});
	};

	selectedStudentsForEditForm = () => {
		const currStudent = this.props.allStudents.find((student) => student.id === this.state.selectedRow[0].student_id);
		const allStudents = this.props.allStudents
			.filter((student) => includes(PANHELLENIC_EXAMINATIONS_ENABLE_GEL_CLASSES_ID, student.department.class.id))
			.filter(
				(student) =>
					!includes(
						this.props.allPanhellenicExaminationsResults.map((per) => per.student_id),
						student.id,
					),
			);
		allStudents.push(currStudent);
		return allStudents;
	};

	render() {
		return (
			<>
				<Tabs
					onSelect={(index) => {
						this.resetAllFilters();
						this.setState({ scientificFieldSelected: globalStore.scientific_fields[index] });
					}}
				>
					<TabList>
						{globalStore.scientific_fields.map((field, idx) => (
							<Tab key={`tab${idx}`}>{field.description}</Tab>
						))}
					</TabList>
					{globalStore.scientific_fields.map((sf, idx) => {
						const sfLessons = this.props.allScientificFieldLessons.filter((item) => item.scientific_field_id === sf.id).map((item) => item.lesson);

						const lessonCols = sfLessons.map((item, idx) => {
							return {
								name: item.description,
								selector: (row) => {
									const gradeItem = row.grades.find((grade) => grade.lesson.id === item.id);
									return gradeItem?.grade;
								},
								sortable: true,
								hide: "md",
							};
						});
						const flLessonCols = globalStore.foreign_language_lessons.map((item, idx) => {
							return {
								name: item.description,
								selector: (row) => {
									const gradeItem = row.flGrades?.find((grade) => grade.lesson.id === item.id);
									return gradeItem ? gradeItem.grade : "";
								},
								omit: true,
							};
						});
						const designLessonCols = globalStore.design_lessons.map((item, idx) => {
							return {
								name: item.description,
								selector: (row) => {
									const gradeItem = row.designGrades?.find((grade) => grade.lesson.id === item.id);
									return gradeItem ? gradeItem.grade : "";
								},
								omit: true,
							};
						});
						const columns = [
							{
								name: "Α/Α",
								selector: (row) => row.id,
								sortable: true,
								width: "75px",
							},
							{
								name: "Ονοματεπώνυμο Μαθητή",
								selector: (row) => row.student.lastname + " " + row.student.firstname,
								sortable: true,
								wrap: true,
								grow: 2,
							},
							{
								name: "Μόρια",
								selector: (row) => row.final_result,
								format: (row) => <span style={{ fontSize: "120%" }}>{row.final_result}</span>,
								sortable: true,
								width: "100px",
							},
							{
								name: "Ειδικό Μάθημα",
								selector: (row) => row.final_result_with_special_lessons,
								format: (row) => <span style={{ fontSize: "120%" }}>{row.final_result_with_special_lessons}</span>,
								sortable: true,
								maxWidth: "153px",
							},
							{
								name: "Συντ. 2",
								selector: (row) => row.final_result_with_factor_2,
								format: (row) => <span style={{ fontSize: "120%" }}>{row.final_result_with_factor_2}</span>,
								sortable: true,
								width: "100px",
							},
							{
								name: "Μέσος Όρος",
								selector: (row) => row.average,
								format: (row) => <span style={{ fontSize: "120%" }}>{row.average}</span>,
								sortable: true,
								wrap: true,
								maxWidth: "140px",
							},
						].concat(lessonCols, flLessonCols, designLessonCols);
						const data = this.props.allPanhellenicExaminationsResults.filter((result) => result.scientific_field_id === sf.id);

						return (
							<TabPanel key={`tabPanel${idx}`}>
								<Card
									title={sf.description}
									subTitle={<div className="text-sm">Αριθμός Καταχωρήσεων: {data.length}</div>}
									id={`panhellenicExaminationsResultTable${idx}`}
									headerSpannig={[8, 4]}
									extraOnHeader={
										<>
											<Button
												oval
												type="button"
												iconClass="fas fa-plus"
												kind="primary"
												onClick={() => {
													this.setState({ isAddModalOpen: true });
												}}
												id="btn_add"
												classes={["m-1"]}
												data-tip="Προσθήκη Βαθμολογίας"
											/>
											<Button
												oval
												type="button"
												iconClass="fas fa-pencil-alt"
												kind="info"
												onClick={() => this.setState({ modalReadonly: false, isEditModalOpen: true })}
												id="btn_edit"
												classes={["m-1"]}
												disabled={this.state.selectedRow.length === 0}
												data-tip="Επεξεργασία Βαθμολογίας"
											/>
											<Button
												oval
												type="button"
												iconClass="fas fa-eye"
												kind="green"
												onClick={() => this.setState({ modalReadonly: true, isEditModalOpen: true })}
												id="btn"
												classes={["m-1"]}
												disabled={this.state.selectedRow.length === 0}
												data-tip="Προβολή Βαθμολογίας"
											/>
											<Button
												oval
												type="button"
												iconClass="fas fa-trash"
												kind="danger"
												onClick={() => this.setState({ isDeleteModalOpen: true })}
												id="btn"
												classes={["m-1"]}
												disabled={this.state.selectedRow.length === 0}
												data-place="left"
												data-tip="Διαγραφή Βαθμολογίας"
											/>
										</>
									}
								>
									<Datatable
										columns={columns}
										defaultSortFieldId={2}
										exportCsv
										exportFilename="Πίνακας Αποτελεσμάτων Πανελλαδικών Μαθητολογίου Πολύτροπο"
										filteredData={this.state.filteredData}
										filterText={this.state.filterText}
										onClear={() => {
											this.resetAllFilters();
										}}
										onFilter={(e) => {
											this.setFilter(e.target.value, data);
											this.clearSelectedRows();
										}}
										rowData={isEmpty(this.state.filterText) ? data : this.state.filteredData}
										selectable
										selectedRows={this.state.selectedRow}
										setSelectedRow={this.selectedRow}
										toggledClearRows={this.toggledClearRows}
									/>
									<ReactTooltip effect="solid" />
								</Card>
							</TabPanel>
						);
					})}
				</Tabs>
				<AddPanhellenicExaminationForm
					allStudents={this.props.allStudents
						.filter((student) => includes(PANHELLENIC_EXAMINATIONS_ENABLE_GEL_CLASSES_ID, student.department.class.id))
						.filter(
							(student) =>
								!includes(
									this.props.allPanhellenicExaminationsResults.map((per) => per.student_id),
									student.id,
								),
						)}
					scientificFieldLessons={this.props.allScientificFieldLessons.filter(
						(sfItem) => sfItem.scientific_field_id === this.state.scientificFieldSelected.id,
					)}
					scientificFieldSelected={this.state.scientificFieldSelected}
					resetShouldAddGradeRender={this.resetShouldAddGradeRender}
					isModalOpen={this.state.isAddModalOpen}
					closeModal={() => this.setState({ isAddModalOpen: false })}
					resetAllFilters={this.resetAllFilters}
				/>
				{!isEmpty(this.state.selectedRow) && (
					<>
						<EditPanhellenicExaminationForm
							allStudents={this.selectedStudentsForEditForm()}
							scientificFieldLessons={this.props.allScientificFieldLessons.filter(
								(sfItem) => sfItem.scientific_field_id === this.state.scientificFieldSelected.id,
							)}
							scientificFieldSelected={this.state.scientificFieldSelected}
							selectedResult={!isEmpty(this.state.selectedRow) ? this.state.selectedRow[0] : null}
							modalReadonly={this.state.modalReadonly}
							isModalOpen={this.state.isEditModalOpen}
							closeModal={() => this.setState({ isEditModalOpen: false })}
							resetAllFilters={this.resetAllFilters}
						/>
					</>
				)}
				<Modal
					isOpen={this.state.isDeleteModalOpen}
					header={
						<>
							<em className="fas fa-trash float-left fa-1_2x mr-3" />
							Επιβεβαίωση Διαγραφής
						</>
					}
					headerBg="danger"
					loading={this.state.loading}
					footer={
						<>
							<Button
								text="Επιβεβαίωση"
								type="button"
								kind="success"
								onClick={this.onDeleteGradeHandler}
							/>
							<Button
								text="Κλείσιμο"
								kind="secondary"
								type="button"
								onClick={() => this.setState({ isDeleteModalOpen: false })}
							/>
						</>
					}
					onClose={() => this.setState({ isDeleteModalOpen: false })}
				>
					<div>
						<strong>Είστε σίγουρος ότι επιθυμείτε να προβείτε στη διαγραφή της βαθμολογίας;</strong>
						<p className="mt-2">
							{" "}
							<i className="fas fa-exclamation-circle mr-1 text-primary fa-120x"></i>Προσοχή: Η ενέργεια δεν θα μπορεί να αναιρεθεί.
						</p>
					</div>
				</Modal>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		allStudents: allStudents(state),
		allScientificFieldLessons: allScientificFieldLessons(state),
		allFetchingFinished: allFetchingFinished(state),
		allPanhellenicExaminationsResults: allPanhellenicExaminationsResults(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		deletePanhellenicExaminationResult: (perId) => dispatch(deletePanhellenicExaminationResult(perId)),
		deletePanhellenicExaminationResultSuccess: (perData) => dispatch(deletePanhellenicExaminationResultSuccess(perData)),
		deletePanhellenicExaminationResultFail: (error) => dispatch(deletePanhellenicExaminationResultFail(error)),
		setApiResponse: (theApiResponse) => dispatch(apiResponse(theApiResponse)),
	};
};

PanhellenicExaminations.propTypes = {
	allPanhellenicExaminationsResults: PropTypes.arrayOf(PropTypes.exact(PanhellenicExaminationsShape)),
	allStudents: PropTypes.arrayOf(PropTypes.exact(StudentFromSelectorShape)),
	allScientificFieldLessons: PropTypes.arrayOf(PropTypes.exact(ScientificFieldLessonsFromSelectorShape)),
	allFetchingFinished: PropTypes.bool,
	deletePanhellenicExaminationResult: PropTypes.func,
	deletePanhellenicExaminationResultSuccess: PropTypes.func,
	deletePanhellenicExaminationResultFail: PropTypes.func,
	setApiResponse: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(PanhellenicExaminations);
