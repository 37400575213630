import React, { Component } from "react";
import PropTypes from "prop-types";
import { has } from "lodash";

import Divider from "../../../../UI/Divider/Divider";
import BaseInput from "../../../../UI/BaseInput/BaseInput";
import { Row, Col } from "../../../../UI/Grid/Grid";

class GeneralCard extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const formElementsArray = [];
		for (let key in this.props.formElements) {
			formElementsArray.push({
				id: key,
				config: this.props.formElements[key],
			});
		}

		return (
			<>
				<Divider
					text={this.props.cardTitle}
					textPostition="left"
				/>
				<Row classes={["px-2"]}>
					{formElementsArray.map((element, idx) => {
						return (
							<Col
								xl={element.config.colSpan}
								lg={3}
								md={4}
								sm={6}
								classes={["my-2", "px-2"]}
								key={idx}
							>
								<label>
									{element.config.label} {element.config.required ? <span className="reqField">(*)</span> : null}
								</label>
								{element.config.datepicker ? (
									<BaseInput
										key={element.config.name + element.config.id}
										name={element.config.name}
										onChange={(selectedOption) => {
											this.props.onChangeHandler(selectedOption, element.id, this.props.cardId);
										}}
										datepicker={element.config.datepicker}
										selected={element.config.selected}
										required={this.props.required}
									/>
								) : has(element.config, "menuPlacement") ? (
									<BaseInput
										key={element.config.name + element.config.id}
										maxLength={element.config.maxLength}
										name={element.config.name}
										placeholder={element.config.placeholder}
										type={element.config.inputType}
										value={element.config.value}
										options={element.config.options}
										searchable={element.config.searchable}
										menuPlacement={element.config.menuPlacement}
										onChange={(selectedOption) => {
											this.props.onChangeHandler(selectedOption, element.id, this.props.cardId);
										}}
										required={this.props.required}
									/>
								) : (
									<BaseInput
										key={element.config.name + element.config.id}
										maxLength={element.config.maxLength}
										name={element.config.name}
										placeholder={element.config.placeholder}
										type={element.config.inputType}
										value={element.config.value}
										options={element.config.options}
										searchable={element.config.searchable}
										onChange={(selectedOption) => {
											this.props.onChangeHandler(selectedOption, element.id, this.props.cardId);
										}}
										required={this.props.required}
									/>
								)}
							</Col>
						);
					})}
				</Row>
			</>
		);
	}
}

GeneralCard.propTypes = {
	cardTitle: PropTypes.string,
	cardId: PropTypes.string,
	formElements: PropTypes.object,
	onChangeHandler: PropTypes.func,
};

export default GeneralCard;
