import PropTypes from "prop-types";
import BaseInput from "../BaseInput/BaseInput";

const inputGroup = ({
	classes,
	content,
	defaultValue,
	disabled,
	inputType,
	isSearchable,
	name,
	onChangeValues,
	options,
	placeholder,
	placement,
	required,
	value,
}) => {
	return (
		<div className="input-group">
			{placement === "prepend" && <div className="input-group-prepend">{content}</div>}
			<BaseInput
				defaultValue={defaultValue}
				classes={classes}
				disabled={disabled}
				name={name}
				type={inputType}
				placeholder={placeholder}
				required={required}
				searchable={isSearchable}
				onChange={onChangeValues}
				options={options}
				value={value}
			/>
			{placement === "append" && <div className="input-group-append">{content}</div>}
		</div>
	);
};

inputGroup.propTypes = {
	classes: PropTypes.array,
	content: PropTypes.element.isRequired,
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	disabled: PropTypes.bool,
	inputType: PropTypes.string,
	isSearchable: PropTypes.bool,
	name: PropTypes.string.isRequired,
	onChangeValues: PropTypes.func,
	options: PropTypes.array,
	placeholder: PropTypes.string,
	placement: PropTypes.oneOf(["prepend", "append"]).isRequired,
	required: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
};

export { inputGroup };
