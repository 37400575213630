export const parametricsGreekNameMapping = {
	classes: "Τάξη",
	informed_by: "Ενημερώθηκε Από",
	exam_kind: "Είδος Εξέτασης",
	absence_kind: "Είδη Παρουσίας",
	scientific_fields: "Επιστημονικά Πεδία",
	lessons: "Μαθήματα",
	design_lessons: "Ειδικά Μαθήματα Σχεδίου",
	foreign_language_lessons: "Ειδικά Μαθήματα Ξένης Γλώσσας",
	educational_institutions: "Εκπαιδευτικά Ιδρύματα",
};

export const MINUTES_TO_AUTO_LOGOUT = 15;
