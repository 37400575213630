import { sortArrayOfObjectsByNumbers, updateObject } from "../../Utils/Utils";
import {
	ADD_NEW_SCIENTIFIC_FIELD_LESSONS_FAIL,
	ADD_NEW_SCIENTIFIC_FIELD_LESSONS_START,
	ADD_NEW_SCIENTIFIC_FIELD_LESSONS_SUCCESS,
	AUTH_LOGOUT,
	DELETE_SCIENTIFIC_FIELD_LESSONS_FAIL,
	DELETE_SCIENTIFIC_FIELD_LESSONS_START,
	DELETE_SCIENTIFIC_FIELD_LESSONS_SUCCESS,
	FETCH_ALL_SCIENTIFIC_FIELD_LESSONS_FAIL,
	FETCH_ALL_SCIENTIFIC_FIELD_LESSONS_START,
	FETCH_ALL_SCIENTIFIC_FIELD_LESSONS_SUCCESS,
} from "../Actions/actionTypes";

const initialState = {
	allScientificFieldLessons: [],
	error: null,
	finished: false,
	loading: false,
	success: false,
};

export const fetchScientificFieldLessonStart = (state) => {
	return updateObject(state, {
		error: null,
		finished: false,
		loading: true,
		success: false,
	});
};

const fetchScientificFieldLessonSuccess = (state, action) => {
	return updateObject(state, {
		allScientificFieldLessons: action.payload,
		error: null,
		finished: true,
		loading: false,
		success: false,
	});
};

const fetchScientificFieldLessonFail = (state, action) => {
	return updateObject(state, {
		allScientificFieldLessons: [],
		error: action.payload,
		finished: false,
		loading: false,
		success: false,
	});
};

const addNewScientificFieldLessonStart = (state) => {
	return updateObject(state, {
		error: null,
		loading: true,
		success: false,
	});
};

const addNewScientificFieldLessonSuccess = (state, action) => {
	const updatedScientificFieldLessons = state.allScientificFieldLessons.filter(
		(sf) => sf.scientific_field_id !== action.payload[0].scientific_field_id,
	);
	action.payload.forEach((sf) => {
		updatedScientificFieldLessons.push(sf);
	});
	sortArrayOfObjectsByNumbers(updatedScientificFieldLessons, "calculation_weight", "desc");
	return updateObject(state, {
		allScientificFieldLessons: updatedScientificFieldLessons,
		error: null,
		loading: false,
		success: true,
	});
};

const addNewScientificFieldLessonFail = (state, action) => {
	return updateObject(state, {
		error: action.payload,
		loading: false,
		success: false,
	});
};

const deleteScientificFieldLessonStart = (state) => {
	return updateObject(state, {
		error: null,
		loading: true,
		success: false,
	});
};

const deleteScientificFieldLessonSuccess = (state, action) => {
	const updatedScientificFieldLessons = state.allScientificFieldLessons.filter((sf) => sf.scientific_field_id !== action.payload.id);
	return updateObject(state, {
		allScientificFieldLessons: updatedScientificFieldLessons,
		error: null,
		loading: false,
		success: true,
	});
};

const deleteScientificFieldLessonFail = (state, action) => {
	return updateObject(state, {
		error: action.payload,
		loading: false,
		success: false,
	});
};

const authLogout = (state) => {
	return updateObject(state, initialState);
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_ALL_SCIENTIFIC_FIELD_LESSONS_START:
			return fetchScientificFieldLessonStart(state);
		case FETCH_ALL_SCIENTIFIC_FIELD_LESSONS_FAIL:
			return fetchScientificFieldLessonFail(state, action);
		case FETCH_ALL_SCIENTIFIC_FIELD_LESSONS_SUCCESS:
			return fetchScientificFieldLessonSuccess(state, action);
		case ADD_NEW_SCIENTIFIC_FIELD_LESSONS_START:
			return addNewScientificFieldLessonStart(state);
		case ADD_NEW_SCIENTIFIC_FIELD_LESSONS_FAIL:
			return addNewScientificFieldLessonFail(state, action);
		case ADD_NEW_SCIENTIFIC_FIELD_LESSONS_SUCCESS:
			return addNewScientificFieldLessonSuccess(state, action);
		case DELETE_SCIENTIFIC_FIELD_LESSONS_START:
			return deleteScientificFieldLessonStart(state);
		case DELETE_SCIENTIFIC_FIELD_LESSONS_FAIL:
			return deleteScientificFieldLessonFail(state, action);
		case DELETE_SCIENTIFIC_FIELD_LESSONS_SUCCESS:
			return deleteScientificFieldLessonSuccess(state, action);
		case AUTH_LOGOUT:
			return authLogout(state);
		default:
			return state;
	}
};

export default reducer;
