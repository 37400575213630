import { globalStore } from "../../globalStore";

export const allDepartments = (state) => {
	if (!state.parametrics.finished) {
		return [];
	}

	const output = state.departments.allDepartments.map((department, idx) => {
		const updatedDepartment = { ...department };
		updatedDepartment.class = globalStore.classes.filter((theClass) => theClass.id === department.class)[0];
		updatedDepartment.students_count = state.students.allStudents.filter((student) => student.department === updatedDepartment.id).length;
		return updatedDepartment;
	});

	return output;
};
