import { useFormik } from "formik";
import moment from "moment";
import PropTypes from "prop-types";
import { memo, useCallback, useEffect } from "react";
import { connect } from "react-redux";

import { usePrevious } from "../../../../CustomHooks/usePrevious";
import { DepartmentShape } from "../../../../Models/DepartmentShape";
import { GradeShape } from "../../../../Models/GradeShape";
import { StudentFromSelectorShape } from "../../../../Models/StudentShape";
import { UserFromSelectorShape } from "../../../../Models/UserShape";
import { allDepartments } from "../../../../Redux/Selectors/departmentsSelectors";
import { allGrades } from "../../../../Redux/Selectors/gradesSelectors";
import { allStudents } from "../../../../Redux/Selectors/studentsSelectors";
import { allTeacherUsers } from "../../../../Redux/Selectors/userSelectors";
import { getParametricAsOptions } from "../../../../Utils/GuiUtils";
import { clone } from "../../../../Utils/Utils";
import Button from "../../../UI/Button/Button";
import FormColumnField from "../../../UI/FormColumnField/FormColumnField";
import { Row } from "../../../UI/Grid/Grid";
import { MODAL_SIZE } from "../../../UI/Modal/constants/ModalSize";
import Modal from "../../../UI/Modal/Modal";

const fieldNames = {
	ABSENCE: "absence",
	CLASS: "class",
	DEPARTMENT: "department",
	EXAM_KIND: "exam_kind",
	GRADE: "grade",
	GRADE_DATE_FROM: "grade_date_from",
	GRADE_DATE_TO: "grade_date_to",
	GRADE_OPERATOR: "grade_operator",
	STUDENT: "student",
	TEACHER: "teacher",
};

const initialFormValues = {
	absence: null,
	class: null,
	department: null,
	exam_kind: null,
	grade: "",
	grade_date_from: null,
	grade_date_to: null,
	grade_operator: null,
	student: null,
	teacher: null,
};

const GRADE_OPERATORS = [
	{ value: 1, label: "Ίσο (=)" },
	{ value: 2, label: "Διάφορο (!=)" },
	{ value: 3, label: "Μεγαλύτερο (>)" },
	{ value: 4, label: "Μεγαλύτερο ή ίσο (>=)" },
	{ value: 5, label: "Μικρότερο (<)" },
	{ value: 6, label: "Μικρότερο ή ίσο (<=)" },
];

const FilterGradesForm = memo(({ allDepartments, allGrades, allStudents, allUsers, setFilteredData, closeModal, isModalOpen, isDataFiltered }) => {
	const prevIsDataFiltered = usePrevious(isDataFiltered);

	const formik = useFormik({
		// enableReinitialize: true,
		initialValues: initialFormValues,
		onSubmit: () => {
			onFilterSubmit();
		},
	});

	const onReset = useCallback(() => {
		formik.resetForm();
		setFilteredData([], false);
	}, [formik, setFilteredData]);

	useEffect(() => {
		if (prevIsDataFiltered && !isDataFiltered) onReset();
	}, [isDataFiltered, prevIsDataFiltered, onReset]);

	const getDepartmentDropdown = () => {
		const updatedDepartments = clone(allDepartments);
		return updatedDepartments.map((department) => {
			return {
				value: department.id,
				label: department.name,
			};
		});
	};

	const getUserDropdown = () => {
		const updatedUsers = clone(allUsers);
		const output = updatedUsers.map((user) => {
			return {
				value: user.id,
				label: user.lastname + " " + user.firstname,
			};
		});
		return output;
	};

	const getStudentDropdown = () => {
		const updatedStudents = clone(allStudents);
		const output = updatedStudents.map((student) => {
			return {
				value: student.id,
				label: student.lastname + " " + student.firstname,
			};
		});
		return output;
	};

	const onFilterSubmit = () => {
		let filteredGrades = clone(allGrades);
		Object.keys(formik.values).forEach((formikKey) => {
			switch (formikKey) {
				case fieldNames.STUDENT:
					if (formik.values[fieldNames.STUDENT] === null) break;
					filteredGrades = filteredGrades.filter((grade) => grade.student.id === Number(formik.values[fieldNames.STUDENT].value));
					break;

				case fieldNames.TEACHER:
					if (formik.values[fieldNames.TEACHER] === null) break;
					filteredGrades = filteredGrades.filter((grade) => grade.teacher.id === Number(formik.values[fieldNames.TEACHER].value));
					break;

				case fieldNames.CLASS:
					if (formik.values[fieldNames.CLASS] === null) break;
					filteredGrades = filteredGrades.filter((grade) => grade.student.department.class.id === Number(formik.values[fieldNames.CLASS].value));
					break;

				case fieldNames.DEPARTMENT:
					if (formik.values[fieldNames.DEPARTMENT] === null) break;
					filteredGrades = filteredGrades.filter((grade) => grade.student.department.id === Number(formik.values[fieldNames.DEPARTMENT].value));
					break;

				case fieldNames.EXAM_KIND:
					if (formik.values[fieldNames.EXAM_KIND] === null) break;
					filteredGrades = filteredGrades.filter((grade) => grade.exam_kind.id === Number(formik.values[fieldNames.EXAM_KIND].value));
					break;

				case fieldNames.ABSENCE:
					if (formik.values[fieldNames.ABSENCE] === null) break;
					filteredGrades = filteredGrades.filter((grade) => grade.absence.id === Number(formik.values[fieldNames.ABSENCE].value));
					break;

				case fieldNames.GRADE_DATE_FROM:
					if (formik.values[fieldNames.GRADE_DATE_FROM] === null) break;
					filteredGrades = filteredGrades.filter((grade) =>
						moment(grade.exam_date).isSameOrAfter(moment(formik.values[fieldNames.GRADE_DATE_FROM], "DD/MM/YYYY")),
					);
					break;

				case fieldNames.GRADE_DATE_TO:
					if (formik.values[fieldNames.GRADE_DATE_TO] === null) break;
					filteredGrades = filteredGrades.filter((grade) =>
						moment(grade.exam_date).isSameOrBefore(moment(formik.values[fieldNames.GRADE_DATE_TO], "DD/MM/YYYY")),
					);
					break;

				case fieldNames.GRADE_OPERATOR:
					if (formik.values[fieldNames.GRADE_OPERATOR] === null || formik.values[fieldNames.GRADE] === "") break;
					switch (formik.values[fieldNames.GRADE_OPERATOR].value) {
						case 1:
							filteredGrades = filteredGrades.filter((grade) => grade.grade === Number(formik.values[fieldNames.GRADE]));
							break;
						case 2:
							filteredGrades = filteredGrades.filter((grade) => grade.grade !== Number(formik.values[fieldNames.GRADE]));
							break;
						case 3:
							filteredGrades = filteredGrades.filter((grade) => grade.grade > Number(formik.values[fieldNames.GRADE]));
							break;
						case 4:
							filteredGrades = filteredGrades.filter((grade) => grade.grade >= Number(formik.values[fieldNames.GRADE]));
							break;
						case 5:
							filteredGrades = filteredGrades.filter((grade) => grade.grade < Number(formik.values[fieldNames.GRADE]));
							break;
						case 6:
							filteredGrades = filteredGrades.filter((grade) => grade.grade <= Number(formik.values[fieldNames.GRADE]));
							break;
						default:
							break;
					}
					break;

				default:
					break;
			}
		});
		setFilteredData(filteredGrades, true);
		closeModal();
	};

	return (
		<Modal
			isOpen={isModalOpen}
			header={
				<>
					<em className="fas fa-filter float-left fa-1_2x mr-3" />
					Φίλτρα Αναζήτησης Μαθητή
				</>
			}
			headerBg="purple"
			size={MODAL_SIZE.lg}
			footer={
				<>
					<Button
						type="button"
						kind="success"
						onClick={formik.handleSubmit}
						disabled={!formik.dirty}
						text="Αναζήτηση"
					/>
					<Button
						type="button"
						kind="primary"
						onClick={() => onReset()}
						disabled={!formik.dirty}
						id="reset-filters"
						text="Επαναφορά"
					/>
					<Button
						type="button"
						kind="secondary"
						text="Κλείσιμο"
						onClick={closeModal}
					/>
				</>
			}
			onClose={() => closeModal()}
		>
			<Row classes={["px-2"]}>
				<FormColumnField
					classes={["px-2", "mb-3"]}
					label="Μαθητής/τρια"
					name={fieldNames.STUDENT}
					onChange={(e) => {
						formik.handleChange(e.value.toString());
						formik.setFieldValue(fieldNames.STUDENT, e);
					}}
					options={getStudentDropdown()}
					placeholder="Μαθητής/τρια"
					searchable
					span={{
						xl: 4,
						lg: 4,
						md: 6,
						sm: 6,
					}}
					value={formik.values[fieldNames.STUDENT]}
				/>
				<FormColumnField
					classes={["px-2", "mb-3"]}
					label="Καθηγητής/τρια"
					name={fieldNames.TEACHER}
					onChange={(e) => {
						formik.handleChange(e.value.toString());
						formik.setFieldValue(fieldNames.TEACHER, e);
					}}
					options={getUserDropdown()}
					placeholder="Καθηγητής/τρια"
					searchable
					span={{
						xl: 4,
						lg: 4,
						md: 6,
						sm: 6,
					}}
					value={formik.values[fieldNames.TEACHER]}
				/>
				<FormColumnField
					classes={["px-2", "mb-3"]}
					label="Τάξη"
					name={fieldNames.CLASS}
					onChange={(e) => {
						formik.handleChange(e.value.toString());
						formik.setFieldValue(fieldNames.CLASS, e);
					}}
					options={getParametricAsOptions("classes")}
					placeholder="Τάξη"
					searchable
					span={{
						xl: 4,
						lg: 4,
						md: 6,
						sm: 6,
					}}
					value={formik.values[fieldNames.CLASS]}
				/>
				<FormColumnField
					classes={["px-2", "mb-3"]}
					label="Τμήμα"
					name={fieldNames.DEPARTMENT}
					onChange={(e) => {
						formik.handleChange(e.value.toString());
						formik.setFieldValue(fieldNames.DEPARTMENT, e);
					}}
					options={getDepartmentDropdown()}
					placeholder="Τμήμα"
					searchable
					span={{
						xl: 4,
						lg: 4,
						md: 6,
						sm: 6,
					}}
					value={formik.values[fieldNames.DEPARTMENT]}
				/>
				<FormColumnField
					classes={["px-2", "mb-3"]}
					label="Παρουσία"
					name={fieldNames.ABSENCE}
					onChange={(e) => {
						formik.handleChange(e.value.toString());
						formik.setFieldValue(fieldNames.ABSENCE, e);
					}}
					options={getParametricAsOptions("absence_kind")}
					placeholder="Παρουσία"
					searchable
					span={{
						xl: 4,
						lg: 4,
						md: 6,
						sm: 6,
					}}
					value={formik.values[fieldNames.ABSENCE]}
				/>
				<FormColumnField
					classes={["px-2", "mb-3"]}
					label="Είδος Εξέτασης"
					name={fieldNames.EXAM_KIND}
					onChange={(e) => {
						formik.handleChange(e.value.toString());
						formik.setFieldValue(fieldNames.EXAM_KIND, e);
					}}
					options={getParametricAsOptions("exam_kind")}
					placeholder="Είδος Εξέτασης"
					searchable
					span={{
						xl: 4,
						lg: 4,
						md: 6,
						sm: 6,
					}}
					value={formik.values[fieldNames.EXAM_KIND]}
				/>
				<FormColumnField
					classes={["px-2", "mb-3"]}
					help_text="Το πεδίο συνδυάζεται με τον βαθμό"
					label="Τελεστής"
					name={fieldNames.GRADE_OPERATOR}
					onChange={(e) => {
						formik.handleChange(e.value.toString());
						formik.setFieldValue(fieldNames.GRADE_OPERATOR, e);
					}}
					options={GRADE_OPERATORS}
					placeholder="Τελεστής"
					searchable
					span={{
						xl: 4,
						lg: 4,
						md: 6,
						sm: 6,
					}}
					value={formik.values[fieldNames.GRADE_OPERATOR]}
				/>
				<FormColumnField
					classes={["px-2", "mb-3"]}
					inputType="number"
					label="Βαθμός (%)"
					name={fieldNames.GRADE}
					onChange={formik.handleChange}
					placeholder="Βαθμός"
					span={{
						xl: 2,
						lg: 2,
						md: 6,
						sm: 6,
					}}
					value={formik.values[fieldNames.GRADE]}
				/>
				<FormColumnField
					classes={["px-2", "mb-3"]}
					datepicker
					label="Ημ. Εξέτασης Από"
					name={fieldNames.GRADE_DATE_FROM}
					onChange={(e) => {
						formik.handleChange(moment(e).format("DD/MM/YYYY"));
						formik.setFieldValue(fieldNames.GRADE_DATE_FROM, moment(e).format("DD/MM/YYYY"));
					}}
					placeholder="Ημ. Εξέτασης Από"
					span={{
						xl: 3,
						lg: 3,
						md: 6,
						sm: 6,
					}}
					value={formik.values[fieldNames.GRADE_DATE_FROM]}
				/>
				<FormColumnField
					classes={["px-2", "mb-3"]}
					datepicker
					label="Ημ. Εξέτασης Έως"
					name={fieldNames.GRADE_DATE_TO}
					onChange={(e) => {
						formik.handleChange(moment(e).format("DD/MM/YYYY"));
						formik.setFieldValue(fieldNames.GRADE_DATE_TO, moment(e).format("DD/MM/YYYY"));
					}}
					placeholder="Ημ. Εξέτασης Έως"
					span={{
						xl: 3,
						lg: 3,
						md: 6,
						sm: 6,
					}}
					value={formik.values[fieldNames.GRADE_DATE_TO]}
				/>
			</Row>
		</Modal>
	);
});

const mapStateToProps = (state) => {
	return {
		allDepartments: allDepartments(state),
		allGrades: allGrades(state),
		allStudents: allStudents(state),
		allUsers: allTeacherUsers(state),
	};
};

FilterGradesForm.propTypes = {
	allDepartments: PropTypes.arrayOf(PropTypes.exact(DepartmentShape)),
	allGrades: PropTypes.arrayOf(PropTypes.exact(GradeShape)),
	allStudents: PropTypes.arrayOf(PropTypes.exact(StudentFromSelectorShape)),
	allUsers: PropTypes.arrayOf(PropTypes.exact(UserFromSelectorShape)),
	closeModal: PropTypes.func,
	isDataFiltered: PropTypes.bool,
	isModalOpen: PropTypes.bool,
	setFilteredData: PropTypes.func,
};

export default connect(mapStateToProps)(FilterGradesForm);
