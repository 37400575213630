import { useFormik } from "formik";
import { includes } from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";

import { StudentFromReduxShape } from "../../../../Models/StudentShape";
import { UserFromReduxShape, UserFromSelectorShape } from "../../../../Models/UserShape";
import {
	addNewUser,
	addNewUserFail,
	addNewUserSuccess,
	apiResponse,
	updateUser,
	updateUserFail,
	updateUserSuccess,
} from "../../../../Redux/Actions/index";
import { getParametricAsOptions } from "../../../../Utils/GuiUtils";
import { nullifyEmptyStrings } from "../../../../Utils/NullConversionUtils";
import { PARAMETRIC_TABLES, permissions, USER_STATUS_DISABLE } from "../../../../Utils/ParametricTablesBinds";
import { copyToClipboard, flattenFormData, isFormikFormInvalid } from "../../../../Utils/Utils";
import BaseInput from "../../../UI/BaseInput/BaseInput";
import Button from "../../../UI/Button/Button";
import FormColumnField from "../../../UI/FormColumnField/FormColumnField";
import { Col, Row } from "../../../UI/Grid/Grid";
import { MODAL_SIZE } from "../../../UI/Modal/constants/ModalSize";
import Modal from "../../../UI/Modal/Modal";
import Tag from "../../../UI/Tag/Tag";
const generator = require("generate-password");

const field_names = {
	FIRSTNAME: "firstname",
	LASTNAME: "lastname",
	EMAIL: "email",
	USERNAME: "username",
	PERMISSION: "permission",
	STUDENT_ID: "student_id",
	STATUS: "status",
	PASSWORD: "password",
	REPASSWORD: "repassword",
	MOBILE_PHONE: "mobile_phone",
	PHONE: "phone",
	ADDRESS: "address",
	ZIP: "zip",
	CITY: "city",
};

const initialFormValues = {
	firstname: "",
	lastname: "",
	email: "",
	username: "",
	permission: null,
	student_id: null,
	status: null,
	password: "",
	repassword: "",
	mobile_phone: "",
	phone: "",
	address: "",
	zip: "",
	city: "",
};

const ManageUserForm = ({
	addNewUser,
	addNewUserFail,
	addNewUserSuccess,
	allStudents,
	allUsers,
	closeModal,
	isModalOpen,
	readonly,
	resetGridFilters,
	setApiResponse,
	updateUser,
	updateUserFail,
	updateUserSuccess,
	user,
}) => {
	const [loading, setLoading] = useState(false);
	const [generatedPassword, setGeneratedPassword] = useState(null);

	const getInitialValues = () => {
		if (user === null) return initialFormValues;
		const currentStudent = allStudents.find((student) => student.id === user.student_id);
		return {
			firstname: user[field_names.FIRSTNAME],
			lastname: user[field_names.LASTNAME],
			email: user[field_names.EMAIL] ?? "",
			username: user[field_names.USERNAME],
			permission: {
				label: user[field_names.PERMISSION].description,
				value: user[field_names.PERMISSION].id,
			},
			status: {
				label: user[field_names.STATUS].description,
				value: user[field_names.STATUS].id,
			},
			student_id:
				user[field_names.STUDENT_ID] !== null && currentStudent !== undefined
					? {
							label: currentStudent.lastname + " " + currentStudent.firstname,
							value: user[field_names.STUDENT_ID],
					  }
					: null,
			password: user[field_names.PASSWORD] ?? "",
			repassword: user[field_names.REPASSWORD] ?? "",
			mobile_phone: user[field_names.MOBILE_PHONE] ?? "",
			phone: user[field_names.PHONE] ?? "",
			address: user[field_names.ADDRESS] ?? "",
			zip: user[field_names.ZIP] ?? "",
			city: user[field_names.CITY] ?? "",
		};
	};

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: getInitialValues(),
		onSubmit: (values) => {
			onFormSubmit(flattenFormData(values));
		},
	});

	const getStudentDropdown = () => {
		const usedStudentIds = allUsers.filter((user) => user.student_id !== null).map((user) => user.student_id);
		const studentsToShow = allStudents
			.filter((student) => student.status.id !== USER_STATUS_DISABLE && !includes(usedStudentIds, student.id))
			.map((student) => {
				return {
					label: student.lastname + " " + student.firstname,
					value: student.id,
				};
			});
		return studentsToShow;
	};

	const generatePassword = () => {
		const password = generator.generate({
			length: 12,
			numbers: true,
			symbols: true,
			excludeSimilarCharacters: true,
			exclude: `,./+=[]{}()|<>:;'_~"\\`,
			strict: true,
		});
		return password;
	};

	const onCloseModal = () => {
		formik.resetForm();
		setGeneratedPassword(null);
		closeModal();
	};

	const isFormInvalid = () => {
		return isFormikFormInvalid([
			{
				value: formik.values[field_names.FIRSTNAME],
				required: true,
			},
			{
				value: formik.values[field_names.LASTNAME],
				required: true,
			},
			{
				value: formik.values[field_names.USERNAME],
				required: true,
			},
			{
				value: formik.values[field_names.PERMISSION] ?? null,
				required: true,
			},
			{
				value: formik.values[field_names.STATUS] ?? null,
				required: true,
			},
			{
				value: formik.values[field_names.STUDENT_ID] ?? null,
				required: formik.values[field_names.PERMISSION]?.value === permissions.STUDENT ? true : false,
			},
			{
				value: formik.values[field_names.PASSWORD],
				required: user === null,
			},
			{
				value: formik.values[field_names.REPASSWORD],
				required: user === null,
			},
		]);
	};

	const onFormSubmit = (formData) => {
		setLoading(true);
		const formSubmissionPromise = (() => {
			if (user === null) {
				return addNewUser(nullifyEmptyStrings(formData));
			} else {
				return updateUser(nullifyEmptyStrings(formData), user.id);
			}
		})();

		formSubmissionPromise
			.then((response) => {
				const apiResponseMsg = {
					error: null,
					info: {
						message: (
							<>
								Ο χρήστης με ψευδώνυμο <b>{response.data.username}</b> {user === null ? "προστέθηκε " : "ενημερώθηκε"} με επιτυχία.
							</>
						),
					},
				};
				setApiResponse(apiResponseMsg);
				if (user === null) addNewUserSuccess(response.data);
				else updateUserSuccess(response.data);
			})
			.catch((error) => {
				const apiResponseMsg = {
					error: error,
					info: null,
				};
				setApiResponse(apiResponseMsg);
				if (user === null) addNewUserFail(error);
				else updateUserFail(error);
			})
			.finally(() => {
				setLoading(false);
				resetGridFilters();
				onCloseModal();
			});
	};

	const footer = (() => {
		if (user === null) {
			return (
				<>
					<Button
						type="button"
						kind="success"
						onClick={formik.handleSubmit}
						disabled={isFormInvalid()}
						text="Αποθήκευση"
					/>
					<Button
						type="button"
						kind="secondary"
						onClick={onCloseModal}
						data-dismiss="modal"
						text="Κλείσιμο"
					/>
				</>
			);
		} else {
			return (
				<>
					<Button
						type="button"
						kind="success"
						onClick={formik.handleSubmit}
						disabled={isFormInvalid() || !formik.dirty || readonly}
						text="Αποθήκευση"
					/>
					<Button
						type="button"
						kind="primary"
						onClick={formik.resetForm}
						disabled={!formik.dirty || readonly}
						text="Επαναφορά"
					/>
					<Button
						type="button"
						kind="secondary"
						onClick={onCloseModal}
						data-dismiss="modal"
						text="Κλείσιμο"
					/>
				</>
			);
		}
	})();

	const headerBg = () => {
		if (user === null) return "primary";
		if (readonly) return "green";
		else return "info";
	};

	const headerText = () => {
		if (user === null) return "Δημιουργία Νέου Χρήστη";
		if (readonly) return "Προβολή Χρήστη";
		else return "Επεξεργασία Χρήστη";
	};

	const headerIcon = () => {
		if (user === null) return "fa-user-plus";
		if (readonly) return "fa-eye";
		else return "fa-user-edit";
	};

	return (
		<Modal
			footer={footer}
			header={
				<>
					<em className={`fas ${headerIcon()} float-left fa-1_2x mr-3`} />
					{headerText()}
				</>
			}
			headerBg={headerBg()}
			isOpen={isModalOpen}
			loading={loading}
			onClose={onCloseModal}
			size={MODAL_SIZE.xl}
		>
			<form>
				<Row classes={["px-2"]}>
					<FormColumnField
						span={{ sm: 6, md: 6, lg: 4, xl: 3 }}
						classes={["px-2", "mb-3"]}
						label="Όνομα Χρήστη"
						required
						name={field_names.FIRSTNAME}
						disabled={readonly}
						placeholder="Όνομα Χρήστη"
						value={formik.values[field_names.FIRSTNAME]}
						onChange={formik.handleChange}
					/>
					<FormColumnField
						span={{ sm: 6, md: 6, lg: 4, xl: 3 }}
						classes={["px-2", "mb-3"]}
						label="Επίθετο Χρήστη"
						required
						name={field_names.LASTNAME}
						disabled={readonly}
						placeholder="Επίθετο Χρήστη"
						value={formik.values[field_names.LASTNAME]}
						onChange={formik.handleChange}
					/>
					<FormColumnField
						span={{ sm: 6, md: 6, lg: 4, xl: 3 }}
						classes={["px-2", "mb-3"]}
						label="Email"
						name={field_names.EMAIL}
						disabled={readonly}
						placeholder="Email"
						value={formik.values[field_names.EMAIL]}
						onChange={formik.handleChange}
					/>
					<FormColumnField
						span={{ sm: 6, md: 6, lg: 4, xl: 3 }}
						classes={["px-2", "mb-3"]}
						label="Ψευδώνυμο Χρήστη"
						required
						name={field_names.USERNAME}
						disabled={readonly}
						placeholder="Ψευδώνυμο Χρήστη"
						value={formik.values[field_names.USERNAME]}
						onChange={formik.handleChange}
					/>
					<FormColumnField
						span={{ sm: 6, md: 6, lg: 4, xl: 3 }}
						classes={["px-2", "mb-3"]}
						label="Ρόλος"
						required
						name={field_names.PERMISSION}
						options={getParametricAsOptions(PARAMETRIC_TABLES.PERMISSIONS)}
						searchable={false}
						disabled={readonly}
						placeholder="Ρόλος"
						value={formik.values[field_names.PERMISSION]}
						onChange={(e) => {
							if (e.value !== permissions.STUDENT) formik.setFieldValue(field_names.STUDENT_ID, null);
							formik.handleChange(e.value.toString());
							formik.setFieldValue(field_names.PERMISSION, e);
						}}
					/>
					{formik.values[field_names.PERMISSION]?.value === permissions.STUDENT && (
						<FormColumnField
							span={{ sm: 6, md: 6, lg: 4, xl: 3 }}
							classes={["px-2", "mb-3"]}
							label="Αντιστοίχιση σε Μαθητή"
							required
							name={field_names.STUDENT_ID}
							options={getStudentDropdown()}
							disabled={readonly}
							placeholder="Αντιστοίχιση σε Μαθητή"
							value={formik.values[field_names.STUDENT_ID]}
							onChange={(e) => {
								formik.handleChange(e.value.toString());
								formik.setFieldValue(field_names.STUDENT_ID, e);
							}}
						/>
					)}
					<FormColumnField
						span={{ sm: 6, md: 6, lg: 4, xl: 3 }}
						classes={["px-2", "mb-3"]}
						label="Κατάσταση"
						required
						name={field_names.STATUS}
						options={getParametricAsOptions(PARAMETRIC_TABLES.STATUS)}
						searchable={false}
						disabled={readonly}
						placeholder="Κατάσταση"
						value={formik.values[field_names.STATUS]}
						onChange={(e) => {
							formik.handleChange(e.value.toString());
							formik.setFieldValue(field_names.STATUS, e);
						}}
					/>
					{user === null && (
						<>
							<Col
								sm={6}
								md={6}
								lg={4}
								xl={3}
								classes={["px-2", "mb-3"]}
							>
								<label>
									Συνθηματικό Χρήστη <span className="reqField">(*)</span>
								</label>
								<div style={{ position: "relative" }}>
									<BaseInput
										name={field_names.PASSWORD}
										disabled={readonly}
										placeholder="Συνθηματικό Χρήστη"
										type="password"
										value={formik.values[field_names.PASSWORD]}
										onChange={(e) => {
											formik.handleChange(e.target.value);
											formik.setFieldValue(field_names.PASSWORD, e.target.value);
											if (e.target.value === "") {
												setGeneratedPassword(null);
											}
										}}
									/>
									<Button
										type="button"
										disabled={readonly}
										iconClass="fas fa-key"
										kind="link"
										style={{ fontSize: "1.15rem", position: "absolute", top: "-1px", bottom: 0, right: "-2px" }}
										classes={["pl-1"]}
										onClick={() => {
											const pass = generatePassword();
											setGeneratedPassword(pass);
											formik.setFieldValue(field_names.PASSWORD, pass);
										}}
										data-tip="Αυτόματη Δημιουργία Κωδικού"
										data-for="generate-password-tooltip"
									/>
								</div>
								{generatedPassword !== null && (
									<div
										className="mt-1"
										style={{ fontSize: "90%" }}
									>
										<strong>Κωδικός: </strong> <Tag>{generatedPassword}</Tag>
										<Button
											type="button"
											disabled={readonly}
											text="Copy"
											kind="link"
											classes={["px-0"]}
											onClick={() => {
												NotificationManager.success("Επιτυχής Αντιγραφή", "Success", 2500);
												copyToClipboard(generatedPassword);
											}}
										/>
									</div>
								)}
								<ReactTooltip
									id="generate-password-tooltip"
									effect="solid"
								/>
							</Col>
							<FormColumnField
								span={{ sm: 6, md: 6, lg: 4, xl: 3 }}
								classes={["px-2", "mb-3"]}
								label="Επιβεβαίωση Συνθηματικού"
								required
								name={field_names.REPASSWORD}
								disabled={readonly}
								placeholder="Επιβεβαίωση Συνθηματικού"
								value={formik.values[field_names.REPASSWORD]}
								inputType="password"
								onChange={formik.handleChange}
							/>
						</>
					)}
					<FormColumnField
						span={{ sm: 6, md: 6, lg: 4, xl: 3 }}
						classes={["px-2", "mb-3"]}
						label="Κινητό Τηλέφωνο"
						name={field_names.MOBILE_PHONE}
						disabled={readonly}
						placeholder="Κινητό Τηλέφωνο"
						value={formik.values[field_names.MOBILE_PHONE]}
						onChange={formik.handleChange}
					/>
					<FormColumnField
						span={{ sm: 6, md: 6, lg: 4, xl: 3 }}
						classes={["px-2", "mb-3"]}
						label="Σταθερό Τηλέφωνο"
						name={field_names.PHONE}
						disabled={readonly}
						placeholder="Σταθερό Τηλέφωνο"
						value={formik.values[field_names.PHONE]}
						onChange={formik.handleChange}
					/>
					<FormColumnField
						span={{ sm: 6, md: 6, lg: 4, xl: 3 }}
						classes={["px-2", "mb-3"]}
						label="Διεύθυνση"
						name={field_names.ADDRESS}
						disabled={readonly}
						placeholder="Διεύθυνση"
						value={formik.values[field_names.ADDRESS]}
						onChange={formik.handleChange}
					/>
					<FormColumnField
						span={{ sm: 6, md: 6, lg: 4, xl: 3 }}
						classes={["px-2", "mb-3"]}
						label="Ταχ. Κωδικός"
						name={field_names.ZIP}
						disabled={readonly}
						placeholder="Ταχ. Κωδικός"
						value={formik.values[field_names.ZIP]}
						onChange={formik.handleChange}
					/>
					<FormColumnField
						span={{ sm: 6, md: 6, lg: 4, xl: 3 }}
						classes={["px-2", "mb-3"]}
						label="Πόλη"
						name={field_names.CITY}
						disabled={readonly}
						placeholder="Πόλη"
						value={formik.values[field_names.CITY]}
						onChange={formik.handleChange}
					/>
				</Row>
			</form>
		</Modal>
	);
};

const mapStateToProps = (state) => {
	return {
		allStudents: state.students.allStudents,
		allUsers: state.users.allUsers,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		addNewUser: (newUserData) => dispatch(addNewUser(newUserData)),
		addNewUserFail: (error) => dispatch(addNewUserFail(error)),
		addNewUserSuccess: (newUserData) => dispatch(addNewUserSuccess(newUserData)),
		setApiResponse: (theApiResponse) => dispatch(apiResponse(theApiResponse)),
		updateUser: (newUserData, userId) => dispatch(updateUser(newUserData, userId)),
		updateUserFail: (error) => dispatch(updateUserFail(error)),
		updateUserSuccess: (newUserData) => dispatch(updateUserSuccess(newUserData)),
	};
};

ManageUserForm.propTypes = {
	addNewUser: PropTypes.func,
	addNewUserFail: PropTypes.func,
	addNewUserSuccess: PropTypes.func,
	allStudents: PropTypes.arrayOf(PropTypes.exact(StudentFromReduxShape)),
	allUsers: PropTypes.arrayOf(PropTypes.exact(UserFromReduxShape)),
	closeModal: PropTypes.func,
	isModalOpen: PropTypes.bool,
	readonly: PropTypes.bool,
	resetGridFilters: PropTypes.func,
	setApiResponse: PropTypes.func,
	updateUser: PropTypes.func,
	updateUserFail: PropTypes.func,
	updateUserSuccess: PropTypes.func,
	user: PropTypes.exact(UserFromSelectorShape),
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageUserForm);
