import PropTypes from "prop-types";
import React from "react";
import ReactModal from "react-modal";

import { renderFormLoading } from "../../../Utils/GuiUtils";
import Button from "../Button/Button";

const Modal = React.memo(
	({ children, closable, footer, hasFooter, hasHeader, header, headerBg, isOpen, loading, onClose, onSubmit, size, submitDisabled }) => {
		const modalSize = (() => {
			if (size) {
				return `modal-dialog ${size} mt-5`;
			} else {
				return `modal-dialog mt-5`;
			}
		})();

		return (
			<ReactModal
				isOpen={isOpen}
				className={modalSize}
				style={{
					overlay: {
						zIndex: 999,
						backgroundColor: "rgba(0, 0, 0, 0.5)",
					},
				}}
			>
				<div className={`modal-content`}>
					{hasHeader && (
						<div className={`modal-header unselectable ${headerBg ? "bg-" + headerBg : ""}`}>
							<h4 className="modal-title">{header}</h4>
							{closable && (
								<button
									className="close"
									type="button"
									aria-label="Close"
									onClick={onClose ? () => onClose() : null}
								>
									<span aria-hidden="true">&times;</span>
								</button>
							)}
						</div>
					)}
					<div className={`modal-body`}>{children}</div>
					{hasFooter && (
						<div className="modal-footer bg-gray-lighter bt">
							{footer ? (
								loading ? (
									renderFormLoading()
								) : (
									footer
								)
							) : loading ? (
								renderFormLoading()
							) : (
								<>
									<Button
										type="button"
										kind="success"
										onClick={onSubmit}
										disabled={submitDisabled}
										text="Αποθήκευση"
									/>
									<Button
										type="button"
										kind="secondary"
										onClick={onClose ? () => onClose() : null}
										text="Κλείσιμο"
									/>
								</>
							)}
						</div>
					)}
				</div>
			</ReactModal>
		);
	},
);

Modal.defaultProps = {
	closable: true,
	hasFooter: true,
	hasHeader: true,
};

Modal.propTypes = {
	children: PropTypes.element.isRequired,
	closable: PropTypes.bool,
	footer: PropTypes.element,
	hasFooter: PropTypes.bool,
	hasHeader: PropTypes.bool,
	header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
	headerBg: PropTypes.oneOf(["primary", "success", "danger", "warning", "purple", "info", "green", null]),
	isOpen: PropTypes.bool.isRequired,
	loading: PropTypes.bool,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	size: PropTypes.oneOf(["modal-sm", "modal-lg", "modal-xl"]),
	submitDisabled: PropTypes.bool,
};

export default Modal;
