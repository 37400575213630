import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";

import { DepartmentShape } from "../../../../Models/DepartmentShape";
import {
	addNewDepartment,
	addNewDepartmentFail,
	addNewDepartmentSuccess,
	apiResponse,
	updateDepartment,
	updateDepartmentFail,
	updateDepartmentSuccess,
} from "../../../../Redux/Actions/index";
import { getParametricAsOptions } from "../../../../Utils/GuiUtils";
import { PARAMETRIC_TABLES } from "../../../../Utils/ParametricTablesBinds";
import { flattenFormData, isFormikFormInvalid } from "../../../../Utils/Utils";
import Button from "../../../UI/Button/Button";
import FormColumnField from "../../../UI/FormColumnField/FormColumnField";
import { Row } from "../../../UI/Grid/Grid";
import Modal from "../../../UI/Modal/Modal";

const field_names = {
	DEPARTMENT_NAME: "departmentName",
	DEPARTMENT_CLASS: "departmentClass",
};

const initialFormValues = {
	departmentName: "",
	departmentClass: null,
};

const ManageDepartmentForm = ({
	addNewDepartment,
	addNewDepartmentFail,
	addNewDepartmentSuccess,
	closeModal,
	department,
	isModalOpen,
	resetGridFilters,
	setApiResponse,
	updateDepartment,
	updateDepartmentFail,
	updateDepartmentSuccess,
}) => {
	const [loading, setLoading] = useState(false);

	const getInitialValues = () => {
		if (department === null) return initialFormValues;
		return {
			departmentName: department.name,
			departmentClass: {
				label: department.class.description,
				value: department.class.id,
			},
		};
	};

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: getInitialValues(),
		onSubmit: (values) => {
			onFormSubmit(flattenFormData(values));
		},
	});

	const onCloseModal = () => {
		formik.resetForm();
		closeModal();
	};

	const onFormSubmit = (formData) => {
		setLoading(true);
		const formSubmissionPromise = (() => {
			if (department === null) {
				return addNewDepartment(formData);
			} else {
				return updateDepartment(formData, department.id);
			}
		})();
		formSubmissionPromise
			.then((response) => {
				const apiResponseMsg = {
					error: null,
					info: {
						message: (
							<>
								Το τμήμα <b>{response.data.name}</b> {department === null ? "προστέθηκε " : "ενημερώθηκε"} με επιτυχία.
							</>
						),
						code: response.status,
					},
				};
				setApiResponse(apiResponseMsg);
				if (department === null) addNewDepartmentSuccess(response.data);
				else updateDepartmentSuccess(response.data);
			})
			.catch((error) => {
				const apiResponseMsg = {
					error: error,
					info: null,
				};
				setApiResponse(apiResponseMsg);
				if (department === null) addNewDepartmentFail(error);
				else updateDepartmentFail(error);
			})
			.finally(() => {
				setLoading(false);
				onCloseModal();
				resetGridFilters();
			});
	};

	const isFormInvalid = () => {
		return isFormikFormInvalid([
			{
				value: formik.values[field_names.DEPARTMENT_NAME],
				required: true,
			},
			{
				value: formik.values[field_names.DEPARTMENT_CLASS] ?? null,
				required: true,
			},
		]);
	};

	const footer = (() => {
		if (department === null) {
			return (
				<>
					<Button
						type="button"
						kind="success"
						onClick={formik.handleSubmit}
						disabled={isFormInvalid()}
						text="Αποθήκευση"
					/>
					<Button
						type="button"
						kind="secondary"
						onClick={() => onCloseModal()}
						text="Κλείσιμο"
					/>
				</>
			);
		}
		return (
			<>
				<Button
					type="button"
					kind="success"
					onClick={formik.handleSubmit}
					disabled={isFormInvalid() || !formik.dirty}
					text="Αποθήκευση"
				/>
				<Button
					type="button"
					kind="primary"
					onClick={formik.resetForm}
					disabled={!formik.dirty}
					text="Επαναφορά"
				/>
				<Button
					type="button"
					kind="secondary"
					onClick={() => onCloseModal()}
					text="Κλείσιμο"
				/>
			</>
		);
	})();

	return (
		<Modal
			isOpen={isModalOpen}
			header={
				<>
					<em className={`fas ${department === null ? "fa-plus" : "fa-pencil-alt"} float-left fa-1_2x mr-3`} />
					{department === null ? "Δημιουργία Νέου Τμήματος" : "Επεξεργασία Τμήματος"}
				</>
			}
			headerBg={department === null ? "primary" : "info"}
			loading={loading}
			footer={footer}
			onClose={onCloseModal}
		>
			<form>
				<Row classes={["p-2"]}>
					<FormColumnField
						span={{ xl: 6, lg: 6 }}
						classes={["px-2", "mb-3", "mb-lg-0"]}
						placeholder="Όνομα Τμήματος"
						label="Όνομα Τμήματος"
						required
						name={field_names.DEPARTMENT_NAME}
						value={formik.values[field_names.DEPARTMENT_NAME]}
						onChange={formik.handleChange}
					/>
					<FormColumnField
						span={{ xl: 6, lg: 6 }}
						classes={["px-2"]}
						placeholder="Τάξη"
						label="Τάξη"
						required
						name={field_names.DEPARTMENT_CLASS}
						options={getParametricAsOptions(PARAMETRIC_TABLES.CLASSES)}
						value={formik.values[field_names.DEPARTMENT_CLASS]}
						onChange={(e) => {
							formik.handleChange(e.value.toString());
							formik.setFieldValue(field_names.DEPARTMENT_CLASS, e);
						}}
					/>
				</Row>
			</form>
		</Modal>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		addNewDepartment: (newDepartmentData) => dispatch(addNewDepartment(newDepartmentData)),
		addNewDepartmentFail: (error) => dispatch(addNewDepartmentFail(error)),
		addNewDepartmentSuccess: (newDepartmentData) => dispatch(addNewDepartmentSuccess(newDepartmentData)),
		setApiResponse: (theApiResponse) => dispatch(apiResponse(theApiResponse)),
		updateDepartment: (departmentData, department_id) => dispatch(updateDepartment(departmentData, department_id)),
		updateDepartmentFail: (error) => dispatch(updateDepartmentFail(error)),
		updateDepartmentSuccess: (departmentData) => dispatch(updateDepartmentSuccess(departmentData)),
	};
};

ManageDepartmentForm.propTypes = {
	addNewDepartment: PropTypes.func,
	addNewDepartmentFail: PropTypes.func,
	addNewDepartmentSuccess: PropTypes.func,
	closeModal: PropTypes.func,
	department: PropTypes.exact(DepartmentShape),
	isModalOpen: PropTypes.bool,
	resetGridFilters: PropTypes.func,
	setApiResponse: PropTypes.func,
	updateDepartment: PropTypes.func,
	updateDepartmentFail: PropTypes.func,
	updateDepartmentSuccess: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(ManageDepartmentForm);
