import { useFormik } from "formik";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";

import { ShortcutShape } from "../../../../../Models/ShortcutShape";
import {
	addNewShortcut,
	addNewShortcutFail,
	addNewShortcutSuccess,
	apiResponse,
	updateShortcut,
	updateShortcutFail,
	updateShortcutSuccess,
} from "../../../../../Redux/Actions/index";
import { getParametricAsOptions } from "../../../../../Utils/GuiUtils";
import { PARAMETRIC_TABLES } from "../../../../../Utils/ParametricTablesBinds";
import { isFormikFormInvalid } from "../../../../../Utils/Utils";
import Button from "../../../../UI/Button/Button";
import FormColumnField from "../../../../UI/FormColumnField/FormColumnField";
import { Row } from "../../../../UI/Grid/Grid";
import { MODAL_SIZE } from "../../../../UI/Modal/constants/ModalSize";
import Modal from "../../../../UI/Modal/Modal";

const field_names = {
	SHORTCUT_LESSONS: "shortcutLessons",
	SHORTCUT_NAME: "shortcutName",
};

const initialFormValues = {
	shortcutLessons: null,
	shortcutName: "",
};

const ManageShortcutForm = ({
	addNewShortcut,
	addNewShortcutFail,
	addNewShortcutSuccess,
	closeModal,
	isModalOpen,
	resetGridFilters,
	setApiResponse,
	shortcut,
	updateShortcut,
	updateShortcutFail,
	updateShortcutSuccess,
}) => {
	const [loading, setLoading] = useState(false);

	const getInitialValues = () => {
		if (shortcut === null) return initialFormValues;
		return {
			shortcutName: shortcut.name,
			shortcutLessons: shortcut.lessons.map((lesson) => {
				return {
					value: lesson.id,
					label: lesson.description,
				};
			}),
		};
	};

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: getInitialValues(),
		onSubmit: (values) => {
			const data = {
				...values,
				shortcutLessons: values[field_names.SHORTCUT_LESSONS].map((x) => x.value),
			};
			onFormSubmit(data);
		},
	});

	const onFormSubmit = (formData) => {
		setLoading(true);
		const formSubmissionPromise = (() => {
			if (shortcut === null) {
				return addNewShortcut(formData);
			} else {
				return updateShortcut(formData, shortcut.id);
			}
		})();
		formSubmissionPromise
			.then((response) => {
				const apiResponseMsg = {
					error: null,
					info: {
						message: (
							<>
								Η συντόμευση <b>{response.data.name}</b> {shortcut === null ? "προστέθηκε " : "ενημερώθηκε"} με επιτυχία.
							</>
						),
						code: response.status,
					},
				};
				setApiResponse(apiResponseMsg);
				const shortcutForRedux = {
					...response.data,
					lessons: formData.shortcutLessons,
				};
				if (shortcut === null) addNewShortcutSuccess(shortcutForRedux);
				else updateShortcutSuccess(shortcutForRedux);
			})
			.catch((error) => {
				const apiResponseMsg = {
					error: error,
					info: null,
				};
				setApiResponse(apiResponseMsg);
				if (shortcut === null) addNewShortcutFail(error);
				else updateShortcutFail(error);
			})
			.finally(() => {
				setLoading(false);
				formik.resetForm();
				resetGridFilters();
				closeModal();
			});
	};

	const isFormInvalid = () => {
		return isFormikFormInvalid([
			{
				value: formik.values[field_names.SHORTCUT_NAME],
				required: true,
			},
			{
				value: formik.values[field_names.SHORTCUT_LESSONS],
				required: true,
			},
		]);
	};

	const footer = (() => {
		if (shortcut === null) {
			return (
				<>
					<Button
						type="button"
						kind="success"
						onClick={formik.handleSubmit}
						disabled={isFormInvalid()}
						text="Αποθήκευση"
					/>
					<Button
						type="button"
						kind="secondary"
						onClick={() => {
							formik.resetForm();
							closeModal();
						}}
						text="Κλείσιμο"
					/>
				</>
			);
		} else {
			return (
				<>
					<Button
						type="button"
						kind="success"
						onClick={formik.handleSubmit}
						disabled={isFormInvalid() || !formik.dirty}
						text="Αποθήκευση"
					/>
					<Button
						type="button"
						kind="primary"
						onClick={formik.resetForm}
						disabled={!formik.dirty}
						text="Επαναφορά"
					/>
					<Button
						type="button"
						kind="secondary"
						onClick={() => {
							formik.resetForm();
							closeModal();
						}}
						text="Κλείσιμο"
					/>
				</>
			);
		}
	})();

	const headerBg = () => {
		if (shortcut === null) return "primary";
		else return "info";
	};

	const headerText = () => {
		if (shortcut === null) return "Δημιουργία Νέας Συντόμευσης";
		else return "Επεξεργασία Συντόμευσης";
	};

	const headerIcon = () => {
		if (shortcut === null) return "fas fa-plus";
		else return "fas fa-pencil-alt";
	};

	return (
		<Modal
			isOpen={isModalOpen}
			header={
				<>
					<em className={`fas ${headerIcon()} float-left fa-1_2x mr-3`} />
					{headerText()}
				</>
			}
			headerBg={headerBg()}
			loading={loading}
			footer={footer}
			onClose={() => closeModal()}
			size={MODAL_SIZE.lg}
		>
			<form>
				<Row classes={["px-2"]}>
					<FormColumnField
						classes={["px-2", "mb-3"]}
						label="Όνομα Συντόμευσης"
						required
						name={field_names.SHORTCUT_NAME}
						value={formik.values[field_names.SHORTCUT_NAME]}
						onChange={formik.handleChange}
					/>
					<FormColumnField
						classes={["px-2", "mb-3"]}
						required
						label="Επιλογή Μαθημάτων"
						help_text="Μπορείτε να επιλέξετε περισσότερα από ένα μαθήματα"
						name={field_names.SHORTCUT_LESSONS}
						placeholder="Όνομα νέου στοιχείου"
						value={formik.values[field_names.SHORTCUT_LESSONS]}
						options={getParametricAsOptions(PARAMETRIC_TABLES.LESSONS)}
						searchable
						select_multiple
						onChange={(e) => {
							formik.handleChange(isEmpty(e) ? "" : e[0].value.toString());
							formik.setFieldValue(field_names.SHORTCUT_LESSONS, isEmpty(e) ? "" : e);
						}}
					/>
				</Row>
			</form>
		</Modal>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		addNewShortcut: (newShortcutData) => dispatch(addNewShortcut(newShortcutData)),
		addNewShortcutFail: (error) => dispatch(addNewShortcutFail(error)),
		addNewShortcutSuccess: (newShortcutData) => dispatch(addNewShortcutSuccess(newShortcutData)),
		setApiResponse: (theApiResponse) => dispatch(apiResponse(theApiResponse)),
		updateShortcut: (newShortcutData, shortcutId) => dispatch(updateShortcut(newShortcutData, shortcutId)),
		updateShortcutFail: (error) => dispatch(updateShortcutFail(error)),
		updateShortcutSuccess: (newShortcutData) => dispatch(updateShortcutSuccess(newShortcutData)),
	};
};

ManageShortcutForm.propTypes = {
	addNewShortcut: PropTypes.func,
	addNewShortcutFail: PropTypes.func,
	addNewShortcutSuccess: PropTypes.func,
	closeModal: PropTypes.func,
	isModalOpen: PropTypes.bool,
	resetGridFilters: PropTypes.func,
	setApiResponse: PropTypes.func,
	shortcut: PropTypes.exact(ShortcutShape),
	updateShortcut: PropTypes.func,
	updateShortcutFail: PropTypes.func,
	updateShortcutSuccess: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(ManageShortcutForm);
