import { StatusCodes } from "http-status-codes";
import { axiosAuth } from "../Utils/AxiosSetup";
import { logout } from "../Redux/Actions";

export const deleteResource = (url) => {
	return manageReturningPromise(axiosAuth.delete(url));
};

export const getResource = (url, payload) => {
	return manageReturningPromise(axiosAuth.get(url, payload));
};

export const postResource = (url, payload) => {
	return manageReturningPromise(axiosAuth.post(url, payload));
};

export const putResource = (url, payload) => {
	return manageReturningPromise(axiosAuth.put(url, payload));
};

const manageReturningPromise = (promise) => {
	return promise
		.then((response) => {
			return Promise.resolve(response);
		})
		.catch((error) => {
			checkErrorAccessStatus(error);
			return Promise.reject(error.response);
		});
};

const checkErrorAccessStatus = (error) => {
	if (error.response.status === StatusCodes.UNAUTHORIZED) {
		logout();
	}
};
