import $ from "jquery";
import { includes, isEmpty, uniqBy } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { SelfTestSelectorShape } from "../../../Models/SelfTestShape";
import { getStudentSelfTestPath } from "../../../navigation";
import { allSelfTest } from "../../../Redux/Selectors/selfTestSelectors";
import { getSelfTestKind, getSelfTestLessonColor, getSelfTestLessonIcon } from "../../../Utils/GuiUtils";
import { sortArrayOfObjectsByNumbers } from "../../../Utils/Utils";
import Button from "../../UI/Button/Button";
import Card from "../../UI/Card/Card";
import { Col, Row } from "../../UI/Grid/Grid";
import SelfTestListFilterPanel from "./SelfTestListFilterPanel/SelfTestListFilterPanel";

class SelfTestList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			chaptersSelected: [],
		};
	}

	componentDidMount() {
		document.title = process.env.REACT_APP_NAME + " :: Προβολη Test Αυτοαξιολόγησης";
	}

	setChapterSelected = (chaptersSelected) => {
		this.setState({ chaptersSelected });
	};

	render() {
		const selfTestAnswersSelfTestIds = this.props.allSelfTestAnswers.map((sta) => sta.self_test_id);
		const allSelfTestChapters = uniqBy(
			this.props.allSelfTest
				.filter((st) => !includes(selfTestAnswersSelfTestIds, st.id))
				.map((st) => {
					return st.chapter;
				}),
			"id",
		);

		const selfTestToShow = (() => {
			if (!isEmpty(this.state.chaptersSelected)) {
				return sortArrayOfObjectsByNumbers(
					this.props.allSelfTest.filter((st) => includes(this.state.chaptersSelected, st.chapter.id)),
					"chapter_id",
				);
			}
			return sortArrayOfObjectsByNumbers(this.props.allSelfTest, "chapter_id");
		})()?.filter((st) => !includes(selfTestAnswersSelfTestIds, st.id));

		return (
			<Row>
				<Col
					xl={3}
					lg={4}
					md={4}
					sm={6}
				>
					<SelfTestListFilterPanel
						allSelfTestChapters={allSelfTestChapters}
						setChapterSelected={this.setChapterSelected}
					/>
				</Col>
				<Col
					xl={9}
					lg={8}
					md={8}
					sm={6}
				>
					<Row>
						<Col>
							<Card
								id="self-test-info-card"
								title="Χρήσιμες Οδηγίες"
								headerSpannig={[7, 5]}
								extraOnHeader={
									<i
										className="fas fa-minus cursor-pointer"
										onClick={() => {
											$("#self-test-info-card").find(".card-body").fadeToggle("hide", "linear");
											if ($("#self-test-info-card").find("i.fa-minus").length > 0)
												setTimeout(function () {
													$("#self-test-info-card").find("i.fa-minus").removeClass("fa-minus").addClass("fa-plus");
												}, 400);
											else
												setTimeout(function () {
													$("#self-test-info-card").find("i.fa-plus").removeClass("fa-plus").addClass("fa-minus");
												}, 100);
										}}
									></i>
								}
							>
								<ul>
									<li>
										Τα test αυτοαξιολόγησης χωρίζονται σε τρείς τύπους: <em>Σωστό - Λάθος</em>, <em>Πολλαπλής Επιλογής</em> και <em>Μεικτά</em>{" "}
										(δηλαδή περιέχουν και τους δύο τύπους ερωτήσεων)
									</li>
									<li>
										Η τελική βαθμολογία υπολογίζεται και εμφανίζεται <b>αυτόματα</b>, μετά την ολοκλήρωση του κάθε test. Όλες οι ερωτήσεις μετρούν{" "}
										<b>ισόποσα</b> στην τελική βαθμολογία.
									</li>
									<li>
										Μπορείτε να ξεκινήσετε την εξέταση όποτε θέλετε, πατώντας το κουμπί "<em>Ξεκινήστε</em>".
									</li>
									<li>
										Όλα τα test έχουν <b>χρονικό όριο</b>, το οποίο αναγράφεται στο επάνω αριστερά μέρος του κάθε test από τα παρακάτω, και{" "}
										<b>δεν έχετε τη δυνατότητα να εξεταστείτε δεύτερη φορά</b> στο ίδιο test.
									</li>
									<li>Μπορείτε να φιλτράρετε τα test χρησιμοποιώντας την περιοχή στα αριστερά της οθόνης.</li>
								</ul>
							</Card>
						</Col>
					</Row>
					<Row>
						{selfTestToShow.map((st, idx) => {
							return (
								<Col
									xl={4}
									lg={6}
									classes={["p-3", "text-center"]}
									key={idx}
								>
									<Col
										classes={[
											"shadow-sm",
											"pt-2",
											"pb-4",
											"self-test-entry-box",
											`self-text-entry-box-${getSelfTestLessonColor(st.chapter.lesson_id)}`,
										]}
									>
										<Row
											justify="between"
											classes={["px-2", "pb-3"]}
										>
											<div>
												<i className="far fa-clock mr-1"></i>
												{st.duration + "'"}
											</div>
											<div>
												<i className="fas fa-user-graduate mr-1"></i>
												{st.user.lastname + " " + st.user.firstname}
											</div>
										</Row>
										<div>
											<i
												className={`${getSelfTestLessonIcon(st.chapter.lesson_id)} fa-3x mb-3 text-${getSelfTestLessonColor(st.chapter.lesson_id)}`}
											></i>
										</div>
										<p className="mb-1 text-bold">{st.chapter.lesson.description}</p>
										<h6 className="mb-1">
											<i>{st.chapter.name}</i>
										</h6>
										<p className="mb-3 small">{getSelfTestKind(st.correct_answers)}</p>
										<Button
											text="Ξεκινήστε"
											type="button"
											kind={getSelfTestLessonColor(st.chapter.lesson_id)}
											oval
											onClick={() => this.props.history.push(getStudentSelfTestPath(st.id))}
										/>
									</Col>
								</Col>
							);
						})}
					</Row>
				</Col>
			</Row>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		allSelfTest: allSelfTest(state),
		allSelfTestAnswers: state.selfTestAnswers.allSelfTestAnswers,
	};
};

SelfTestList.propTypes = {
	allSelfTest: PropTypes.arrayOf(PropTypes.exact(SelfTestSelectorShape)),
};

export default withRouter(connect(mapStateToProps)(SelfTestList));
