import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import { isEmpty, uniqBy } from "lodash";

import { allSelfTestAnswers } from "../../../Redux/Selectors/selfTestSelectors";
import { allFetchingFinished } from "../../../Redux/Selectors/universalSelectors";
import { SelfTestAnswersSelectorShape } from "../../../Models/SelfTestAnswersShape";
import SelfTestAnswers from "./SelfTestAnswers";
import Card from "../../UI/Card/Card";
import Datatable from "../../UI/Datatable/Datatable";

const columns = [
	{
		name: "Α/Α",
		selector: (row) => row.id,
		sortable: true,
		width: "75px",
	},
	{
		name: "Μάθημα",
		selector: (row) => row.self_test.chapter.lesson.description,
		sortable: true,
		wrap: true,
	},
	{
		name: "Ενότητα",
		selector: (row) => row.self_test.chapter.name,
		sortable: true,
		wrap: true,
	},
	{
		name: "Τάξη",
		selector: (row) => row.self_test.class.description,
		sortable: true,
		wrap: true,
	},
	{
		name: "Μαθητής",
		selector: (row) => row.student.lastname + " " + row.student.firstname,
		sortable: true,
		wrap: true,
	},
	{
		name: "Καθηγητής",
		selector: (row) => row.self_test.user.lastname + " " + row.self_test.user.firstname,
		sortable: true,
		wrap: true,
	},
	{
		name: "Βαθμός",
		selector: (row) => row.self_test_grade,
		format: (row) => {
			const classNames = (() => {
				if (row.self_test_grade >= 50) return "text-success text-bold";
				else return "text-danger text-bold";
			})();
			return <span className={classNames}>{row.self_test_grade}%</span>;
		},
		sortable: true,
		center: true,
	},
	{
		name: "Εξετάστηκε",
		selector: (row) => row.created_at,
		format: (row) => (row.created_at ? moment(row.created_at).format("ddd, DD/MM/YYYY HH:mm:ss") : ""),
		sortable: true,
		wrap: true,
	},
];

class SelfTestResultsView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedRow: [],
			filterText: "",
			filteredData: [],
		};
		this.toggledClearRows = false;
	}

	componentDidMount() {
		document.title = process.env.REACT_APP_NAME + " :: Αποτελέσματα Test Αυτοαξιολόγησης";
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.allFetchingFinished && this.props.allFetchingFinished) {
			this.resetAllFilters();
		}
	}

	selectedRow = (data) => {
		this.setState({ selectedRow: data.selectedRows });
	};

	clearSelectedRows = () => {
		this.toggledClearRows = !this.toggledClearRows;
		this.setState({ selectedRow: [] });
	};

	setFilter = (filterString) => {
		const filteredItems = this.props.allSelfTestAnswers.filter((sta) =>
			sta.self_test.chapter.lesson.description.toLowerCase().includes(filterString.toLowerCase()),
		);
		const filteredItems2 = this.props.allSelfTestAnswers.filter((sta) =>
			sta.self_test.chapter.name.toLowerCase().includes(filterString.toLowerCase()),
		);
		const filteredItems3 = this.props.allSelfTestAnswers.filter((sta) => {
			return (
				`${sta.self_test.user.lastname} ${sta.self_test.user.firstname}`.toLowerCase().includes(filterString.toLowerCase()) ||
				`${sta.self_test.user.firstname} ${sta.self_test.user.lastname}`.toLowerCase().includes(filterString.toLowerCase())
			);
		});
		const filteredItems4 = this.props.allSelfTestAnswers.filter((sta) => {
			return (
				`${sta.student.lastname} ${sta.student.firstname}`.toLowerCase().includes(filterString.toLowerCase()) ||
				`${sta.student.firstname} ${sta.student.lastname}`.toLowerCase().includes(filterString.toLowerCase())
			);
		});
		const filteredItems5 = this.props.allSelfTestAnswers.filter((sta) =>
			sta.self_test.class.description.toLowerCase().includes(filterString.toLowerCase()),
		);
		this.setState({
			filterText: filterString,
			filteredData: uniqBy(filteredItems.concat(filteredItems2, filteredItems3, filteredItems4, filteredItems5), "id"),
		});
	};

	resetAllFilters = () => {
		this.setState({
			filterText: "",
			filteredData: [],
		});
		this.clearSelectedRows();
	};

	render() {
		return (
			<>
				<Card
					title="Προβολή Αποτελεσμάτων Test Αυτοαξιολόγησης"
					headerSpannig={[8, 4]}
					subTitle={<div className="text-sm">Αριθμός Καταχωρήσεων: {this.props.allSelfTestAnswers.length}</div>}
					extraOnHeader={<i className="fas fa-eye fa-2x" />}
					id="selfTestAnswersCard"
				>
					<Datatable
						columns={columns}
						defaultSortFieldId={8}
						defaultSortAsc={false}
						exportCsv
						exportFilename="Πίνακας Αποτελεσμάτων Test Αυτοαξιολόγησης Πολύτροπο"
						filteredData={this.state.filteredData}
						filterText={this.state.filterText}
						onClear={() => {
							this.setState({ filterText: "", filteredData: [] });
							this.clearSelectedRows();
						}}
						onFilter={(e) => {
							this.setFilter(e.target.value);
							this.clearSelectedRows();
						}}
						rowData={isEmpty(this.state.filterText) ? this.props.allSelfTestAnswers : this.state.filteredData}
						selectable
						selectedRows={this.state.selectedRow}
						setSelectedRow={this.selectedRow}
						toggledClearRows={this.toggledClearRows}
					/>
					<ReactTooltip effect="solid" />
				</Card>
				{!isEmpty(this.state.selectedRow) && (
					<>
						<SelfTestAnswers selfTestAnswer={!isEmpty(this.state.selectedRow) ? this.state.selectedRow[0] : null} />
					</>
				)}
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		allSelfTestAnswers: allSelfTestAnswers(state),
		allFetchingFinished: allFetchingFinished(state),
	};
};

SelfTestResultsView.propTypes = {
	allSelfTestAnswers: PropTypes.arrayOf(PropTypes.exact(SelfTestAnswersSelectorShape)),
	allFetchingFinished: PropTypes.bool,
};

export default connect(mapStateToProps)(SelfTestResultsView);
