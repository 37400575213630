import { has, isEqual } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";

import { StudentFromSelectorShape } from "../../../../../Models/StudentShape";
import {
	apiResponse,
	updatePanhellenicExaminationResult,
	updatePanhellenicExaminationResultFail,
	updatePanhellenicExaminationResultSuccess,
} from "../../../../../Redux/Actions/index";
import { CUSTOM_ERRORS } from "../../../../../Utils/CustomErrors";
import { clone, roundNumber, sortArrayOfObjectsByNumbers } from "../../../../../Utils/Utils";
import Alert from "../../../../UI/Alert/Alert";
import Button from "../../../../UI/Button/Button";
import { Col, Row } from "../../../../UI/Grid/Grid";
import { MODAL_SIZE } from "../../../../UI/Modal/constants/ModalSize";
import Modal from "../../../../UI/Modal/Modal";
import StandardLessonsForm from "./LessonForms/StandardLessonsForm";

const LessonFormComponentName = {
	STANDARD_LESSONS: "StandardLessonsForm",
};

class EditPanhellenicExaminationForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			executeReset: false,
			finalResultWithSpecialLessons: null,
			isStandardLessonFormInvalid: false,
			loading: false,
			standardLessons: {
				result: null,
				student_id: null,
				grades: [],
				average: null,
			},
			standardLessonFormError: null,
		};
		this.showStandardLessonErrorAlert = false;
	}

	componentDidMount() {
		this.initDataWhenEdit();
	}

	componentDidUpdate(prevProps, prevState) {
		if (!isEqual(prevProps.selectedResult, this.props.selectedResult)) {
			this.initDataWhenEdit();
		}
	}

	initDataWhenEdit = () => {
		if (this.props.selectedResult) {
			const updatedStandardLessons = clone(this.state.standardLessons);
			updatedStandardLessons.result = {
				team: this.props.selectedResult.final_result,
				sector: this.props.selectedResult.final_result_with_special_lessons,
			};
			updatedStandardLessons.grades = this.props.selectedResult.grades.map((grade) => {
				return { lesson_id: grade.lesson.id, grade: grade.grade };
			});
			updatedStandardLessons.student_id = {
				value: this.props.selectedResult.student.id,
				label: this.props.selectedResult.student.lastname + " " + this.props.selectedResult.student.firstname,
			};
			updatedStandardLessons.average = this.props.selectedResult.average;
			this.setState({
				standardLessons: updatedStandardLessons,
				finalResultWithSpecialLessons: this.props.selectedResult.final_result_with_special_lessons,
			});
		}
	};

	getGrade = (gradesFromState) => {
		const grades = [];
		if (has(gradesFromState, "student_id")) delete gradesFromState.student_id;
		Object.keys(gradesFromState).forEach((key) => {
			if (gradesFromState[key].value !== "")
				grades.push({
					lesson_id: gradesFromState[key].lesson_id,
					grade: gradesFromState[key].value,
				});
		});
		return grades;
	};

	isDirty = () => {
		const propsGradesTransformed = this.props.selectedResult.grades.map((grade) => {
			return { lesson_id: grade.lesson.id, grade: grade.grade };
		});
		const sortedPropsGradesTransformed = sortArrayOfObjectsByNumbers(propsGradesTransformed, "lesson_id");
		const sortedStateGrades = sortArrayOfObjectsByNumbers(this.state.standardLessons.grades, "lesson_id");
		return (
			!isEqual(sortedPropsGradesTransformed, sortedStateGrades) ||
			this.props.selectedResult.student_id !== this.state.standardLessons.student_id.value
		);
	};

	resetSpecialLessonsGrade = () => {
		this.setState({
			finalResultWithSpecialLessons: null,
		});
	};

	setStandardLessons = (result, student_id, grades) => {
		const updatedStandardLessons = clone(this.state.standardLessons);
		updatedStandardLessons.result = result;
		updatedStandardLessons.grades = grades;
		updatedStandardLessons.student_id = student_id;
		const sum = grades.map((grade) => grade.grade).reduce((a, b) => a + b, 0);
		updatedStandardLessons.average = roundNumber(sum / 4, 2);
		this.setState({
			standardLessons: updatedStandardLessons,
			finalResultWithSpecialLessons: result.sector,
		});
	};

	setIsFormInvalid = (isInvalid, formName) => {
		if (formName === LessonFormComponentName.STANDARD_LESSONS) this.setState({ isStandardLessonFormInvalid: isInvalid });
		else
			CUSTOM_ERRORS.throwError(
				`PanhellenicExaminations/Forms/${this.constructor.name}.js | line 159`,
				CUSTOM_ERRORS.UNEXPECTED_IF_STATEMENT,
				formName,
			);
	};

	setGradesError = (error, formName) => {
		if (formName === LessonFormComponentName.STANDARD_LESSONS) {
			this.setState({ standardLessonFormError: error });
			if (error !== null) {
				this.showStandardLessonErrorAlert = true;
			} else {
				this.showStandardLessonErrorAlert = false;
			}
		} else
			CUSTOM_ERRORS.throwError(
				`PanhellenicExaminations/Forms/${this.constructor.name}.js | line 168`,
				CUSTOM_ERRORS.UNEXPECTED_IF_STATEMENT,
				formName,
			);
	};

	resetExecuteResetToggle = () => {
		this.setState({ executeReset: false });
	};

	onReset = () => {
		this.initDataWhenEdit();
		this.setState({
			executeReset: true,
			standardLessonFormError: null,
		});
		this.showStandardLessonErrorAlert = false;
	};

	onFormSubmit = () => {
		this.setState({ loading: true });
		const output = {};
		output.scientific_field_id = null;
		output.finalResult = this.state.standardLessons.result.team;
		output.student_id = this.state.standardLessons.student_id.value;
		output.grades = this.state.standardLessons.grades;
		output.average = this.state.standardLessons.average;
		output.finalResultWithSpecialLessons = this.state.finalResultWithSpecialLessons;
		output.finalResultWithFactor2 = null;
		output.design_grades = [];
		output.foreign_language_grades = [];
		this.props
			.updatePanhellenicExaminationResult(output, this.props.selectedResult.id)
			.then((response) => {
				const result = {
					...response.data,
					grades: output.grades,
				};
				const student = this.props.allStudents.filter((student) => student.id === response.data.student_id)[0];
				const apiResponseMsg = {
					error: null,
					info: {
						message: (
							<>
								Η βαθμολογία του μαθητή{" "}
								<b>
									{student.lastname} {student.firstname}
								</b>{" "}
								ενημερώθηκε με επιτυχία.
							</>
						),
					},
				};
				this.props.setApiResponse(apiResponseMsg);
				this.props.updatePanhellenicExaminationResultSuccess(result);
			})
			.catch((error) => {
				const apiResponseMsg = {
					error: error,
					info: null,
				};
				this.props.setApiResponse(apiResponseMsg);
				this.props.updatePanhellenicExaminationResultFail(error);
			})
			.finally(() => {
				this.setState({ loading: false });
				this.props.resetAllFilters();
				this.props.closeModal();
				ReactTooltip.hide();
			});
	};

	render() {
		return (
			<Modal
				isOpen={this.props.isModalOpen}
				header={
					this.props.modalReadonly ? (
						<>
							<em className="fas fa-eye float-left fa-1_2x mr-3" />
							Προβολή Βαθμολογίας Πανελλαδικών Εξετάσεων
						</>
					) : (
						<>
							<em className="fas fa-pencil-alt float-left fa-1_2x mr-3" />
							Επεξεργασία Βαθμολογίας Πανελλαδικών Εξετάσεων
						</>
					)
				}
				headerBg={this.props.modalReadonly ? "green" : "info"}
				onSubmit={this.onFormSubmit}
				onClose={() => {
					this.props.closeModal();
					this.onReset();
				}}
				size={MODAL_SIZE.lg}
				loading={this.state.loading}
				footer={
					this.props.modalReadonly ? (
						<Button
							type="button"
							kind="secondary"
							text="Κλείσιμο"
							onClick={this.props.closeModal}
						/>
					) : (
						<>
							<Button
								type="button"
								kind="success"
								onClick={this.onFormSubmit}
								disabled={this.state.isStandardLessonFormInvalid || this.state.standardLessonFormError !== null || !this.isDirty()}
								text="Αποθήκευση"
							/>
							<Button
								type="button"
								kind="primary"
								onClick={this.onReset}
								disabled={!this.isDirty()}
								text="Επαναφορά"
							/>
							<Button
								type="button"
								kind="secondary"
								text="Κλείσιμο"
								onClick={() => {
									this.props.closeModal();
									this.onReset();
								}}
							/>
						</>
					)
				}
			>
				<form>
					<Row classes={["px-2"]}>
						<StandardLessonsForm
							allStudents={this.props.allStudents}
							setStandardLessons={this.setStandardLessons}
							setGradesError={this.setGradesError}
							setIsFormInvalid={this.setIsFormInvalid}
							selectedResult={this.props.selectedResult}
							executeReset={this.state.executeReset}
							resetExecuteResetToggle={this.resetExecuteResetToggle}
							modalReadonly={this.props.modalReadonly}
						/>
					</Row>
					{this.state.standardLessons.result && this.state.standardLessonFormError === null && (
						<Row classes={["mb-1"]}>
							<Col>
								Κοινή Ομάδα:{" "}
								<span
									style={{ fontSize: "120%" }}
									className="text-green ml-1 text-bold"
								>
									{this.state.standardLessons.result.team}
								</span>
							</Col>
						</Row>
					)}
					{this.state.finalResultWithSpecialLessons && this.state.standardLessonFormError === null && (
						<Row classes={["mb-1"]}>
							<Col>
								Τομέας:{" "}
								<span
									style={{ fontSize: "120%" }}
									className="text-green ml-1 text-bold"
								>
									{this.state.finalResultWithSpecialLessons}
								</span>
							</Col>
						</Row>
					)}
					{this.state.standardLessons.average && this.state.standardLessonFormError === null && (
						<Row classes={["mb-1"]}>
							<Col>
								Μέσος Όρος:{" "}
								<span
									style={{ fontSize: "120%" }}
									className="text-green ml-1 text-bold"
								>
									{this.state.standardLessons.average}
								</span>
							</Col>
						</Row>
					)}
					{this.showStandardLessonErrorAlert && (
						<Alert
							dismissible
							type="danger"
							onClose={() => (this.showStandardLessonErrorAlert = false)}
							message={<span>Σφάλμα: {this.state.standardLessonFormError}</span>}
						/>
					)}
					{!this.props.modalReadonly && (
						<Row classes={["px-2", "mt-3"]}>
							<Col classes={["bg-light", "p-2", "shadow-sm", "border"]}>
								<i className="fas fa-info-circle mr-2 text-info"></i>Η υποδιαστολή δηλώνεται με τον χαρακτήρα "." και όχι με τον χαρακτήρα ","
							</Col>
						</Row>
					)}
				</form>
			</Modal>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		updatePanhellenicExaminationResult: (panhellenicExaminationsData, panhellenicExaminationsId) =>
			dispatch(updatePanhellenicExaminationResult(panhellenicExaminationsData, panhellenicExaminationsId)),
		updatePanhellenicExaminationResultSuccess: (panhellenicExaminationsData) =>
			dispatch(updatePanhellenicExaminationResultSuccess(panhellenicExaminationsData)),
		updatePanhellenicExaminationResultFail: (error) => dispatch(updatePanhellenicExaminationResultFail(error)),
		setApiResponse: (theApiResponse) => dispatch(apiResponse(theApiResponse)),
	};
};

EditPanhellenicExaminationForm.propTypes = {
	allStudents: PropTypes.arrayOf(PropTypes.exact(StudentFromSelectorShape)),
	closeModal: PropTypes.func,
	modalReadonly: PropTypes.bool,
	selectedResult: PropTypes.object,
	setApiResponse: PropTypes.func,
	updatePanhellenicExaminationResult: PropTypes.func,
	updatePanhellenicExaminationResultFail: PropTypes.func,
	updatePanhellenicExaminationResultSuccess: PropTypes.func,
	isModalOpen: PropTypes.bool,
	resetAllFilters: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(EditPanhellenicExaminationForm);
