import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

import { SelfTestAnswersSelectorShape } from "../../../Models/SelfTestAnswersShape";
import { Row, Col } from "../../UI/Grid/Grid";
import PdfViewer from "../../UI/PdfViewer/PdfViewer";
import Card from "../../UI/Card/Card";
import Divider from "../../UI/Divider/Divider";

class SelfTestAnswers extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		return (
			<Card
				title="Προβολή Απαντήσεων Μαθητή"
				headerSpannig={[8, 4]}
				extraOnHeader={<i className="fas fa-file-signature fa-2x"></i>}
				id="selfTestStudentAnswersCard"
			>
				<Row>
					<Col xl={8}>
						<Divider text="Εκφωνήσεις" />
						<PdfViewer
							file={this.props.selfTestAnswer.self_test.self_test_file}
							filename={this.props.selfTestAnswer.self_test.self_test_filename}
							hideFileName={true}
						/>
					</Col>
					<Col xl={4}>
						<Divider
							textPostition="left"
							text="Απαντήσεις Μαθητή"
						/>
						<Row>
							{this.props.selfTestAnswer.self_test_answers.map((answer, idx) => {
								return (
									<Col
										key={`a${idx}`}
										size="sm"
										span={6}
										classes={["mb-3"]}
									>
										{answer === this.props.selfTestAnswer.self_test.correct_answers[idx] ? (
											<span className="text-success">
												<i className="fas fa-check"></i>
											</span>
										) : (
											<span className="text-danger">
												<i className="fas fa-times"></i>
											</span>
										)}
										<span className="ml-1">
											Ερώτηση {idx + 1}: <strong>{isEmpty(answer) ? "-" : answer}</strong>
										</span>
									</Col>
								);
							})}
						</Row>
						<Divider
							textPostition="left"
							text="Σωστές Απαντήσεις"
						/>
						<Row>
							{this.props.selfTestAnswer.self_test.correct_answers.map((correctAnswer, idx) => {
								return (
									<Col
										key={`ca${idx}`}
										xl={6}
										lg={6}
										md={6}
										sm={6}
										classes={["mb-3"]}
									>
										Ερώτηση {idx + 1}: <strong>{correctAnswer}</strong>
									</Col>
								);
							})}
						</Row>
					</Col>
				</Row>
			</Card>
		);
	}
}

SelfTestAnswers.propTypes = {
	selfTestAnswer: PropTypes.exact(SelfTestAnswersSelectorShape),
};

export default SelfTestAnswers;
