import { isEmpty, uniqBy } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";

import { DepartmentShape } from "../../../Models/DepartmentShape";
import { apiResponse, deleteDepartment, deleteDepartmentFail, deleteDepartmentSuccess } from "../../../Redux/Actions/index";
import { allDepartments } from "../../../Redux/Selectors/departmentsSelectors";
import { allFetchingFinished } from "../../../Redux/Selectors/universalSelectors";
import { CUSTOM_ERRORS } from "../../../Utils/CustomErrors";
import Button from "../../UI/Button/Button";
import Card from "../../UI/Card/Card";
import Datatable from "../../UI/Datatable/Datatable";
import Modal from "../../UI/Modal/Modal";
import ManageDepartmentForm from "./Forms/ManageDepartmentForm";

const columns = [
	{
		name: "Α/Α",
		selector: (row) => row.id,
		sortable: true,
		width: "75px",
	},
	{
		name: "Όνομα Τμήματος",
		selector: (row) => row.name,
		sortable: true,
	},
	{
		name: "Τάξη",
		selector: (row) => row.class.description,
		sortable: true,
	},
	{
		name: "Αριθμός Μαθητών",
		selector: (row) => row.students_count,
		sortable: true,
	},
	{
		name: "Δημιουργήθηκε",
		selector: (row) => row.created_at,
		format: (row) => (row.created_at ? moment(row.created_at).format("ddd, DD/MM/YYYY HH:mm:ss") : ""),
		sortable: true,
		wrap: true,
	},
	{
		name: "Τροποποιήθηκε",
		selector: (row) => row.updated_at,
		format: (row) => (row.updated_at ? moment(row.updated_at).format("ddd, DD/MM/YYYY HH:mm:ss") : ""),
		sortable: true,
		wrap: true,
	},
];

class DepartmentManagement extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterText: "",
			filteredData: [],
			isDeleteDepartmentModalOpen: false,
			isManageDepartmentModalOpen: false,
			loading: false,
			selectedRow: [],
		};
		this.toggledClearRows = false;
	}

	componentDidMount() {
		document.title = process.env.REACT_APP_NAME + " :: Διαχείριση Τμημάτων";
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.allFetchingFinished && this.props.allFetchingFinished) {
			this.resetAllFilters();
		}
	}

	selectedRow = (data) => {
		this.setState({ selectedRow: data.selectedRows });
	};

	clearSelectedRows = () => {
		this.toggledClearRows = !this.toggledClearRows;
		this.setState({ selectedRow: [] });
	};

	resetAllFilters = () => {
		this.setState({
			filterText: "",
			filteredData: [],
		});
		this.clearSelectedRows();
	};

	onDeleteHandler = () => {
		this.setState({ loading: true });
		const department_id = !isEmpty(this.state.selectedRow) ? this.state.selectedRow[0].id : null;
		if (department_id === null) {
			CUSTOM_ERRORS.throwError("DepartmentManagement/DepartmentsManagement.js | line 101", CUSTOM_ERRORS.UNEXPECTED_IF_STATEMENT, department_id);
			return;
		}

		this.props
			.deleteDepartment(department_id)
			.then((response) => {
				this.setState({ loading: false });
				this.props.setApiResponse({
					error: null,
					info: {
						message: (
							<>
								Το τμήμα <b>{this.state.selectedRow[0].name}</b> διαγράφηκε με επιτυχία.
							</>
						),
						code: response.status,
					},
				});
				this.props.deleteDepartmentSuccess({ id: department_id });
				this.resetAllFilters();
			})
			.catch((error) => {
				this.setState({ loading: false });
				this.props.setApiResponse({
					error: error,
					info: null,
				});
				this.props.deleteDepartmentFail(error);
			})
			.finally(() => {
				this.setState({ isDeleteDepartmentModalOpen: false });
			});
	};

	setFilter = (filterString) => {
		const filteredItems = this.props.allDepartments.filter((department) => department.name.toLowerCase().includes(filterString.toLowerCase()));
		const filteredItems2 = this.props.allDepartments.filter((department) =>
			department.class.description.toLowerCase().includes(filterString.toLowerCase()),
		);
		this.setState({
			filterText: filterString,
			filteredData: uniqBy(filteredItems.concat(filteredItems2), "name"),
		});
	};

	render() {
		return (
			<>
				<Card
					title="Προβολή Καταχωρημένων Τμημάτων"
					headerSpannig={[8, 4]}
					subTitle={<div className="text-sm">Αριθμός Καταχωρήσεων: {this.props.allDepartments.length}</div>}
					extraOnHeader={
						<>
							<Button
								oval
								type="button"
								iconClass="fas fa-plus"
								kind="primary"
								onClick={() => this.setState({ isManageDepartmentModalOpen: true })}
								id="btn"
								classes={["m-1"]}
								data-tip="Προσθήκη Τμήματος"
								disabled={!isEmpty(this.state.selectedRow)}
							/>
							<Button
								oval
								type="button"
								iconClass="fas fa-pencil-alt"
								kind="info"
								onClick={() => this.setState({ isManageDepartmentModalOpen: true })}
								id="btn"
								classes={["m-1"]}
								disabled={this.state.selectedRow.length !== 1}
								data-tip="Επεξεργασία Τμήματος"
							/>
							<Button
								oval
								type="button"
								iconClass="fas fa-trash"
								kind="danger"
								onClick={() => this.setState({ isDeleteDepartmentModalOpen: true })}
								id="btn"
								classes={["m-1"]}
								disabled={this.state.selectedRow.length === 0}
								data-place="left"
								data-tip="Διαγραφή Τμήματος"
							/>
						</>
					}
					id="departmentsCard"
				>
					<Datatable
						columns={columns}
						defaultSortFieldId={3}
						exportCsv
						exportFilename="Πίνακας Τμημάτων Μαθητολογίου Πολύτροπο"
						filteredData={this.state.filteredData}
						filterText={this.state.filterText}
						onClear={() => {
							this.setState({ filterText: "", filteredData: [] });
							this.clearSelectedRows();
						}}
						onFilter={(e) => {
							this.setFilter(e.target.value);
							this.clearSelectedRows();
						}}
						rowData={isEmpty(this.state.filterText) ? this.props.allDepartments : this.state.filteredData}
						selectable
						selectedRows={this.state.selectedRow}
						setSelectedRow={this.selectedRow}
						toggledClearRows={this.toggledClearRows}
					/>
					<ManageDepartmentForm
						department={!isEmpty(this.state.selectedRow) ? this.state.selectedRow[0] : null}
						resetGridFilters={this.resetAllFilters}
						isModalOpen={this.state.isManageDepartmentModalOpen}
						closeModal={() => this.setState({ isManageDepartmentModalOpen: false })}
					/>
					{!isEmpty(this.state.selectedRow) && (
						<>
							<Modal
								isOpen={this.state.isDeleteDepartmentModalOpen}
								header={
									<>
										<em className="fas fa-trash float-left fa-1_2x mr-3" />
										Επιβεβαίωση Διαγραφής
									</>
								}
								headerBg="danger"
								loading={this.state.loading}
								footer={
									<>
										<Button
											text="Επιβεβαίωση"
											type="button"
											kind="success"
											onClick={this.onDeleteHandler}
										/>
										<Button
											text="Κλείσιμο"
											kind="secondary"
											type="button"
											onClick={() => this.setState({ isDeleteDepartmentModalOpen: false })}
										/>
									</>
								}
								onClose={() => this.setState({ isDeleteDepartmentModalOpen: false })}
							>
								<div>
									<strong>Είστε σίγουρος ότι επιθυμείτε να προβείτε στη διαγραφή του τμήματος;</strong>
									<p className="mt-2">
										{" "}
										<i className="fas fa-exclamation-circle mr-1 text-primary fa-120x"></i>Προσοχή: Η ενέργεια δεν θα μπορεί να αναιρεθεί.
									</p>
								</div>
							</Modal>
						</>
					)}
					<ReactTooltip effect="solid" />
				</Card>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		allDepartments: allDepartments(state),
		allFetchingFinished: allFetchingFinished(state),
		errors: [state.departments.error, state.parametrics.error],
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		deleteDepartment: (departmentId) => dispatch(deleteDepartment(departmentId)),
		deleteDepartmentSuccess: (departmentData) => dispatch(deleteDepartmentSuccess(departmentData)),
		deleteDepartmentFail: (error) => dispatch(deleteDepartmentFail(error)),
		setApiResponse: (theApiResponse) => dispatch(apiResponse(theApiResponse)),
	};
};

DepartmentManagement.propTypes = {
	allDepartments: PropTypes.arrayOf(PropTypes.exact(DepartmentShape)),
	allFetchingFinished: PropTypes.bool,
	errors: PropTypes.arrayOf(PropTypes.object),
	deleteDepartment: PropTypes.func,
	setApiResponse: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentManagement);
