import React, { Component } from "react";
import MenuItem from "./MenuItem/MenuItem";
import PropTypes from "prop-types";
import { SideMenuItemsShape } from "../../../../Models/SideMenuItems";

class MenuItems extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<ul
				className={`sidebar-nav sidebar-subnav collapse ${this.props.classes ? this.props.classes.join(" ") : ""}`}
				id={this.props.id}
				onMouseEnter={this.props.onMouseEnter}
				onMouseLeave={this.props.onMouseLeave}
				style={this.props.style}
			>
				<li className="sidebar-subnav-header">{this.props.headerTitle}</li>
				{this.props.items.map((item, idx) => {
					return (
						<MenuItem
							item={item}
							key={idx}
						/>
					);
				})}
			</ul>
		);
	}
}

MenuItems.propTypes = {
	classes: PropTypes.array,
	headerTitle: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	items: PropTypes.arrayOf(PropTypes.shape(SideMenuItemsShape)).isRequired,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
	style: PropTypes.object,
};

export default MenuItems;
