import $ from "jquery";
import React, { Component } from "react";
import IdleTimer from "react-idle-timer";
import ReactModal from "react-modal";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { connect } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import "./App.css";

import Login from "./Components/Login/Login";
import Logout from "./Components/Logout/Logout";
import { MINUTES_TO_AUTO_LOGOUT } from "./constants";
import MainLayout from "./Containers/AdminLayout/MainLayout";
import StudentMainLayout from "./Containers/StudentLayout/StudentMainLayout";
import TeacherMainLayout from "./Containers/TeacherLayout/TeacherMainLayout";
import { APP_ADMIN_PATHS, APP_STUDENT_PATHS, APP_TEACHER_PATHS, PathEntry } from "./navigation";
import { logout } from "./Redux/Actions";
import { isAuthenticated } from "./Utils/AuthUtils";
ReactModal.setAppElement("#root");

class App extends Component {
	componentDidMount() {
		const btn = $("#toTopBtn");
		$(window).on("scroll", function () {
			if ($(window).scrollTop() > 350) {
				btn.show();
			} else {
				btn.hide();
			}
		});
	}

	onIdle = () => {
		this.props.history.replace(PathEntry.login.path);
		this.props.onLogout();
	};

	render() {
		let routes = (
			<Switch>
				<Route
					path={PathEntry.login.path}
					exact
					component={Login}
				/>
				<Redirect to={PathEntry.login.path} />
			</Switch>
		);
		if (isAuthenticated()) {
			routes = (
				<Switch>
					<Route
						path={APP_ADMIN_PATHS}
						exact
					>
						<MainLayout />
					</Route>
					<Route
						path={APP_TEACHER_PATHS}
						exact
					>
						<TeacherMainLayout />
					</Route>
					<Route
						path={APP_STUDENT_PATHS}
						exact
					>
						<StudentMainLayout />
					</Route>
					<Route
						path={PathEntry.login.path}
						exact
					>
						<Login />
					</Route>
					<Route
						path={PathEntry.logout.path}
						exact
						component={Logout}
					/>
					<Redirect to={PathEntry.logout.path} />
				</Switch>
			);
		}
		return (
			<>
				<IdleTimer
					timeout={1000 * 60 * MINUTES_TO_AUTO_LOGOUT}
					onIdle={this.onIdle}
					debounce={250}
				/>
				{routes}
				<button
					onClick={() => $("html, body").animate({ scrollTop: 0 }, "350")}
					id="toTopBtn"
					title="Κορυφή"
				>
					<i className="fas fa-chevron-up fa-150x"></i>
				</button>
				<NotificationContainer />
			</>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		onLogout: () => dispatch(logout()),
	};
};

export default withRouter(connect(null, mapDispatchToProps)(App));
