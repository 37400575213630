import { isEmpty, uniqBy } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";

import { GradeShape } from "../../../../../Models/GradeShape";
import { StudentFromSelectorShape } from "../../../../../Models/StudentShape";
import { allGrades } from "../../../../../Redux/Selectors/gradesSelectors";
import { allStudents } from "../../../../../Redux/Selectors/studentsSelectors";
import { CUSTOM_ERRORS } from "../../../../../Utils/CustomErrors";
import { getGradeCountersFooter } from "../../../../../Utils/GuiUtils";
import { absenceKindIds, examKindIds } from "../../../../../Utils/ParametricTablesBinds";
import Badge from "../../../../UI/Badge/Badge";
import Button from "../../../../UI/Button/Button";
import Card from "../../../../UI/Card/Card";
import Datatable from "../../../../UI/Datatable/Datatable";
import FilterGradesForm from "../../Forms/FilterGradesForm";

const GradesTab = ({ allGrades, student }) => {
	const [filterText, setFilterText] = useState("");
	const [filteredData, setFilteredData] = useState([]);
	const [isDataFiltered, setIsDataFiltered] = useState(false);
	const [isFilterGradesModalOpen, setIsFilterGradesModalOpen] = useState(false);
	const columns = [
		{
			name: "Α/Α",
			selector: (row) => row.id,
			sortable: true,
			width: "75px",
		},
		{
			name: "Ημ. Εξέτασης",
			selector: (row) => row.exam_date,
			format: (row) => (row.exam_date ? moment(row.exam_date).format("ddd, DD/MM/YYYY") : ""),
			sortable: true,
			wrap: true,
		},
		{
			name: "Μάθημα",
			selector: (row) => row.lesson.description,
			sortable: true,
			wrap: true,
		},
		{
			name: "Τμήμα",
			selector: (row) => row.student.department.name,
			omit: true,
		},
		{
			name: "Τάξη",
			selector: (row) => row.student.department.class.description,
			omit: true,
		},
		{
			name: "Είδος Εξέτασης",
			selector: (row) => row.exam_kind.description,
			format: (row) => {
				const badgeKind = (() => {
					if (row.exam_kind.id === examKindIds.EXAM) {
						return "purple";
					} else if (row.exam_kind.id === examKindIds.TEST) {
						return "primary";
					} else if (row.exam_kind.id === examKindIds.STUDY) {
						return "green";
					} else {
						CUSTOM_ERRORS.throwError("GradeManagement/GradeManagement.js | line 82", CUSTOM_ERRORS.UNEXPECTED_IF_STATEMENT, row.exam_kind);
					}
				})();
				return (
					<Badge
						kind={badgeKind}
						text={row.exam_kind.description}
					/>
				);
			},
			sortable: true,
			minWidth: "123px",
		},
		{
			name: "Παρουσία",
			selector: (row) => row.absence.description,
			format: (row) => (
				<Badge
					kind={row.absence.id === absenceKindIds.NOT_PRESENT ? "danger" : "success"}
					text={row.absence.description}
				/>
			),
			sortable: true,
			minWidth: "125px",
		},
		{
			name: "Βαθμός",
			selector: (row) => row.grade,
			sortable: true,
			maxWidth: "105px",
		},
		{
			name: "Σχόλια",
			selector: (row) => row.comments,
			sortable: true,
			grow: 2,
			wrap: true,
		},
	];

	useEffect(() => {
		document.title = process.env.REACT_APP_NAME + " :: Προβολή Βαθμολογιών";
	}, []);

	const resetAllFilters = useCallback(() => {
		setFilterText("");
		setFilteredData([]);
		setIsDataFiltered(false);
	}, [setFilterText, setFilteredData, setIsDataFiltered]);

	const setFilter = (filterString) => {
		const filteredItems = allGrades.filter((grade) => grade.lesson.description.toLowerCase().includes(filterString.toLowerCase()));
		const filteredItems2 = allGrades.filter((grade) => grade.absence.description.toLowerCase().includes(filterString.toLowerCase()));
		const filteredItems3 = allGrades.filter((grade) => grade.exam_kind.description.toLowerCase().includes(filterString.toLowerCase()));
		setFilterText(filterString);
		setFilteredData(uniqBy(filteredItems.concat(filteredItems2, filteredItems3), "id"));
	};

	const setGridFilteredData = (filteredData, isDataFiltered) => {
		if (isEmpty(filteredData)) {
			setFilteredData([]);
		} else {
			setFilteredData(filteredData);
		}
		setIsDataFiltered(isDataFiltered);
	};

	const tableData = (() => {
		if (isDataFiltered) {
			return filteredData;
		} else {
			return allGrades;
		}
	})();

	return (
		<>
			<Card
				title="Προβολή Καταχωρημένων Βαθμολογιών"
				headerSpannig={[7, 5]}
				id="studentGradesCard"
				footer={isDataFiltered || !isEmpty(filterText) ? getGradeCountersFooter(filteredData) : getGradeCountersFooter(allGrades)}
				subTitle={<div className="text-sm">Αριθμός Καταχωρήσεων: {allGrades.length}</div>}
				extraOnHeader={
					<>
						<Button
							oval
							type="button"
							iconClass="fas fa-filter"
							kind="purple"
							onClick={() => setIsFilterGradesModalOpen(true)}
							id="btn"
							classes={["m-1"]}
							disabled={isEmpty(allGrades) || !isEmpty(filterText)}
							data-place="left"
							data-tip="Φίλτρα Αναζήτησης"
						/>
						<Button
							oval
							type="button"
							iconClass="fas fa-eraser"
							kind="warning"
							onClick={() => resetAllFilters()}
							id="btn"
							classes={["m-1", isDataFiltered ? "d-inline-block" : "d-none"]}
							data-place="left"
							data-tip="Απαλοιφή Φίλτρων"
						/>
					</>
				}
			>
				<Datatable
					columns={columns}
					defaultSortFieldId={2}
					defaultSortAsc={false}
					exportCsv
					exportFilename={`Πίνακας Βαθμολογιών ${student.lastname} ${student.firstname}`}
					filteredData={filteredData}
					filterText={filterText}
					onClear={() => {
						setFilterText("");
						setFilteredData([]);
					}}
					onFilter={(e) => {
						setFilter(e.target.value);
					}}
					rowData={isEmpty(filterText) ? tableData : filteredData}
					searchDisabled={isDataFiltered}
				/>
			</Card>
			<FilterGradesForm
				setFilteredData={setGridFilteredData}
				closeModal={() => setIsFilterGradesModalOpen(false)}
				isModalOpen={isFilterGradesModalOpen}
				isDataFiltered={isDataFiltered}
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		student: allStudents(state)[0],
		allGrades: allGrades(state),
	};
};

GradesTab.propTypes = {
	student: PropTypes.exact(StudentFromSelectorShape),
	allGrades: PropTypes.arrayOf(PropTypes.exact(GradeShape)),
};

export default connect(mapStateToProps)(GradesTab);
