//Auth
export const AUTH_LOGOUT = "AUTH_LOGOUT";

//Departments
export const FETCH_ALL_DEPARTMENTS_START = "FETCH_ALL_DEPARTMENTS_START";
export const FETCH_ALL_DEPARTMENTS_SUCCESS = "FETCH_ALL_DEPARTMENTS_SUCCESS";
export const FETCH_ALL_DEPARTMENTS_FAIL = "FETCH_ALL_DEPARTMENTS_FAIL";

export const FETCH_CURRENT_TEACHER_DEPARTMENTS_START = "FETCH_CURRENT_TEACHER_DEPARTMENTS_START";
export const FETCH_CURRENT_TEACHER_DEPARTMENTS_SUCCESS = "FETCH_CURRENT_TEACHER_DEPARTMENTS_SUCCESS";
export const FETCH_CURRENT_TEACHER_DEPARTMENTS_FAIL = "FETCH_CURRENT_TEACHER_DEPARTMENTS_FAIL";

export const FETCH_CURRENT_STUDENT_USER_DEPARTMENTS_START = "FETCH_CURRENT_STUDENT_USER_DEPARTMENTS_START";
export const FETCH_CURRENT_STUDENT_USER_DEPARTMENTS_SUCCESS = "FETCH_CURRENT_STUDENT_USER_DEPARTMENTS_SUCCESS";
export const FETCH_CURRENT_STUDENT_USER_DEPARTMENTS_FAIL = "FETCH_CURRENT_STUDENT_USER_DEPARTMENTS_FAIL";

export const ADD_NEW_DEPARTMENT_START = "ADD_NEW_DEPARTMENT_START";
export const ADD_NEW_DEPARTMENT_SUCCESS = "ADD_NEW_DEPARTMENT_SUCCESS";
export const ADD_NEW_DEPARTMENT_FAIL = "ADD_NEW_DEPARTMENT_FAIL";

export const UPDATE_DEPARTMENT_START = "UPDATE_DEPARTMENT_START";
export const UPDATE_DEPARTMENT_SUCCESS = "UPDATE_DEPARTMENT_SUCCESS";
export const UPDATE_DEPARTMENT_FAIL = "UPDATE_DEPARTMENT_FAIL";

export const DELETE_DEPARTMENT_START = "DELETE_DEPARTMENT_START";
export const DELETE_DEPARTMENT_SUCCESS = "DELETE_DEPARTMENT_SUCCESS";
export const DELETE_DEPARTMENT_FAIL = "DELETE_DEPARTMENT_FAIL";

//Grades
export const FETCH_ALL_GRADES_START = "FETCH_ALL_GRADES_START";
export const FETCH_ALL_GRADES_SUCCESS = "FETCH_ALL_GRADES_SUCCESS";
export const FETCH_ALL_GRADES_FAIL = "FETCH_ALL_GRADES_FAIL";

export const FETCH_CURRENT_TEACHER_GRADES_START = "FETCH_CURRENT_TEACHER_GRADES_START";
export const FETCH_CURRENT_TEACHER_GRADES_SUCCESS = "FETCH_CURRENT_TEACHER_GRADES_SUCCESS";
export const FETCH_CURRENT_TEACHER_GRADES_FAIL = "FETCH_CURRENT_TEACHER_GRADES_FAIL";

export const FETCH_CURRENT_STUDENT_USER_GRADES_START = "FETCH_CURRENT_STUDENT_USER_GRADES_START";
export const FETCH_CURRENT_STUDENT_USER_GRADES_SUCCESS = "FETCH_CURRENT_STUDENT_USER_GRADES_SUCCESS";
export const FETCH_CURRENT_STUDENT_USER_GRADES_FAIL = "FETCH_CURRENT_STUDENT_USER_GRADES_FAIL";

export const ADD_NEW_GRADES_START = "ADD_NEW_GRADES_START";
export const ADD_NEW_GRADES_SUCCESS = "ADD_NEW_GRADES_SUCCESS";
export const ADD_NEW_GRADES_FAIL = "ADD_NEW_GRADES_FAIL";

export const UPDATE_GRADE_START = "UPDATE_GRADE_START";
export const UPDATE_GRADE_SUCCESS = "UPDATE_GRADE_SUCCESS";
export const UPDATE_GRADE_FAIL = "UPDATE_GRADE_FAIL";

export const DELETE_GRADE_START = "DELETE_GRADE_START";
export const DELETE_GRADE_SUCCESS = "DELETE_GRADE_SUCCESS";
export const DELETE_GRADE_FAIL = "DELETE_GRADE_FAIL";

//Parametrics
export const FETCH_PARAMETRICS_START = "PARAMETRICS_START";
export const FETCH_PARAMETRICS_SUCCESS = "PARAMETRICS_SUCCESS";
export const FETCH_PARAMETRICS_FAIL = "PARAMETRICS_FAIL";

export const ADD_NEW_PARAMETRIC_START = "ADD_NEW_PARAMETRIC_START";
export const ADD_NEW_PARAMETRIC_SUCCESS = "ADD_NEW_PARAMETRIC_SUCCESS";
export const ADD_NEW_PARAMETRIC_FAIL = "ADD_NEW_PARAMETRIC_FAIL";

export const UPDATE_PARAMETRIC_START = "UPDATE_PARAMETRIC_START";
export const UPDATE_PARAMETRIC_SUCCESS = "UPDATE_PARAMETRIC_SUCCESS";
export const UPDATE_PARAMETRIC_FAIL = "UPDATE_PARAMETRIC_FAIL";

//Shortcuts
export const FETCH_ALL_SHORTCUTS_START = "FETCH_ALL_SHORTCUTS_START";
export const FETCH_ALL_SHORTCUTS_SUCCESS = "FETCH_ALL_SHORTCUTS_SUCCESS";
export const FETCH_ALL_SHORTCUTS_FAIL = "FETCH_ALL_SHORTCUTS_FAIL";

export const ADD_NEW_SHORTCUT_START = "ADD_NEW_SHORTCUT_START";
export const ADD_NEW_SHORTCUT_SUCCESS = "ADD_NEW_SHORTCUT_SUCCESS";
export const ADD_NEW_SHORTCUT_FAIL = "ADD_NEW_SHORTCUT_FAIL";

export const UPDATE_SHORTCUT_START = "UPDATE_SHORTCUT_START";
export const UPDATE_SHORTCUT_SUCCESS = "UPDATE_SHORTCUT_SUCCESS";
export const UPDATE_SHORTCUT_FAIL = "UPDATE_SHORTCUT_FAIL";

export const DELETE_SHORTCUT_START = "DELETE_SHORTCUT_START";
export const DELETE_SHORTCUT_SUCCESS = "DELETE_SHORTCUT_SUCCESS";
export const DELETE_SHORTCUT_FAIL = "DELETE_SHORTCUT_FAIL";

//Students
export const FETCH_STUDENT_START = "FETCH_STUDENT_START";
export const FETCH_STUDENT_SUCCESS = "FETCH_STUDENT_SUCCESS";
export const FETCH_STUDENT_FAIL = "FETCH_STUDENT_FAIL";

export const FETCH_ALL_STUDENTS_START = "FETCH_ALL_STUDENTS_START";
export const FETCH_ALL_STUDENTS_SUCCESS = "FETCH_ALL_STUDENTS_SUCCESS";
export const FETCH_ALL_STUDENTS_FAIL = "FETCH_ALL_STUDENTS_FAIL";

export const FETCH_CURRENT_TEACHER_STUDENTS_START = "FETCH_CURRENT_TEACHER_STUDENTS_START";
export const FETCH_CURRENT_TEACHER_STUDENTS_SUCCESS = "FETCH_CURRENT_TEACHER_STUDENTS_SUCCESS";
export const FETCH_CURRENT_TEACHER_STUDENTS_FAIL = "FETCH_CURRENT_TEACHER_STUDENTS_FAIL";

export const ADD_NEW_STUDENT_START = "ADD_NEW_STUDENT_START";
export const ADD_NEW_STUDENT_SUCCESS = "ADD_NEW_STUDENT_SUCCESS";
export const ADD_NEW_STUDENT_FAIL = "ADD_NEW_STUDENT_FAIL";

export const UPDATE_STUDENT_START = "UPDATE_STUDENT_START";
export const UPDATE_STUDENT_SUCCESS = "UPDATE_STUDENT_SUCCESS";
export const UPDATE_STUDENT_FAIL = "UPDATE_STUDENT_FAIL";

export const DELETE_STUDENT_START = "DELETE_STUDENT_START";
export const DELETE_STUDENT_SUCCESS = "DELETE_STUDENT_SUCCESS";
export const DELETE_STUDENT_FAIL = "DELETE_STUDENT_FAIL";

//Teacher_Departments
export const ADD_NEW_TEACHER_DEPARTMENTS_START = "ADD_NEW_TEACHER_DEPARTMENTS_START";
export const ADD_NEW_TEACHER_DEPARTMENTS_SUCCESS = "ADD_NEW_TEACHER_DEPARTMENTS_SUCCESS";
export const ADD_NEW_TEACHER_DEPARTMENTS_FAIL = "ADD_NEW_TEACHER_DEPARTMENTS_FAIL";

export const DELETE_TEACHER_DEPARTMENTS_START = "DELETE_TEACHER_DEPARTMENTS_START";
export const DELETE_TEACHER_DEPARTMENTS_SUCCESS = "DELETE_TEACHER_DEPARTMENTS_SUCCESS";
export const DELETE_TEACHER_DEPARTMENTS_FAIL = "DELETE_TEACHER_DEPARTMENTS_FAIL";

//Teacher_Lessons
export const ADD_NEW_TEACHER_LESSONS_START = "ADD_NEW_TEACHER_LESSONS_START";
export const ADD_NEW_TEACHER_LESSONS_SUCCESS = "ADD_NEW_TEACHER_LESSONS_SUCCESS";
export const ADD_NEW_TEACHER_LESSONS_FAIL = "ADD_NEW_TEACHER_LESSONS_FAIL";

export const DELETE_TEACHER_LESSONS_START = "DELETE_TEACHER_LESSONS_START";
export const DELETE_TEACHER_LESSONS_SUCCESS = "DELETE_TEACHER_LESSONS_SUCCESS";
export const DELETE_TEACHER_LESSONS_FAIL = "DELETE_TEACHER_LESSONS_FAIL";

//Universe
export const FETCH_UNIVERSE_START = "FETCH_UNIVERSE_START";
export const FETCH_UNIVERSE_FINISHED = "FETCH_UNIVERSE_FINISHED";

//Users
export const FETCH_ALL_USERS_START = "FETCH_ALL_USERS_START";
export const FETCH_ALL_USERS_SUCCESS = "FETCH_ALL_USERS_SUCCESS";
export const FETCH_ALL_USERS_FAIL = "FETCH_ALL_USERS_FAIL";

export const FETCH_TEACHER_USER_START = "FETCH_TEACHER_USER_START";
export const FETCH_TEACHER_USER_SUCCESS = "FETCH_TEACHER_USER_SUCCESS";
export const FETCH_TEACHER_USER_FAIL = "FETCH_TEACHER_USER_FAIL";

export const FETCH_STUDENT_USER_START = "FETCH_STUDENT_USER_START";
export const FETCH_STUDENT_USER_SUCCESS = "FETCH_STUDENT_USER_SUCCESS";
export const FETCH_STUDENT_USER_FAIL = "FETCH_STUDENT_USER_FAIL";

export const ADD_NEW_USER_START = "ADD_NEW_USER_START";
export const ADD_NEW_USER_SUCCESS = "ADD_NEW_USER_SUCCESS";
export const ADD_NEW_USER_FAIL = "ADD_NEW_USER_FAIL";

export const UPDATE_USER_START = "UPDATE_USER_START";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const UPDATE_USER_PASSWORD_START = "UPDATE_USER_PASSWORD_START";
export const UPDATE_USER_PASSWORD_SUCCESS = "UPDATE_USER_PASSWORD_SUCCESS";
export const UPDATE_USER_PASSWORD_FAIL = "UPDATE_USER_PASSWORD_FAIL";

export const DELETE_USER_START = "DELETE_USER_START";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

//Panhellenic_Examinations_Results
export const FETCH_ALL_PANHELLENIC_EXAMINATIONS_RESULTS_START = "FETCH_ALL_PANHELLENIC_EXAMINATIONS_RESULTS_START";
export const FETCH_ALL_PANHELLENIC_EXAMINATIONS_RESULTS_SUCCESS = "FETCH_ALL_PANHELLENIC_EXAMINATIONS_RESULTS_SUCCESS";
export const FETCH_ALL_PANHELLENIC_EXAMINATIONS_RESULTS_FAIL = "FETCH_ALL_PANHELLENIC_EXAMINATIONS_RESULTS_FAIL";

export const ADD_NEW_PANHELLENIC_EXAMINATION_RESULT_START = "ADD_NEW_PANHELLENIC_EXAMINATION_RESULT_START";
export const ADD_NEW_PANHELLENIC_EXAMINATION_RESULT_SUCCESS = "ADD_NEW_PANHELLENIC_EXAMINATION_RESULT_SUCCESS";
export const ADD_NEW_PANHELLENIC_EXAMINATION_RESULT_FAIL = "ADD_NEW_PANHELLENIC_EXAMINATION_RESULT_FAIL";

export const UPDATE_PANHELLENIC_EXAMINATION_RESULT_START = "UPDATE_PANHELLENIC_EXAMINATION_RESULT_START";
export const UPDATE_PANHELLENIC_EXAMINATION_RESULT_SUCCESS = "UPDATE_PANHELLENIC_EXAMINATION_RESULT_SUCCESS";
export const UPDATE_PANHELLENIC_EXAMINATION_RESULT_FAIL = "UPDATE_PANHELLENIC_EXAMINATION_RESULT_FAIL";

export const DELETE_PANHELLENIC_EXAMINATION_RESULT_START = "DELETE_PANHELLENIC_EXAMINATION_RESULT_START";
export const DELETE_PANHELLENIC_EXAMINATION_RESULT_SUCCESS = "DELETE_PANHELLENIC_EXAMINATION_RESULT_SUCCESS";
export const DELETE_PANHELLENIC_EXAMINATION_RESULT_FAIL = "DELETE_PANHELLENIC_EXAMINATION_RESULT_FAIL";

//Scientific_Field_Lessons
export const FETCH_ALL_SCIENTIFIC_FIELD_LESSONS_START = "FETCH_ALL_SCIENTIFIC_FIELD_LESSONS_START";
export const FETCH_ALL_SCIENTIFIC_FIELD_LESSONS_SUCCESS = "FETCH_ALL_SCIENTIFIC_FIELD_LESSONS_SUCCESS";
export const FETCH_ALL_SCIENTIFIC_FIELD_LESSONS_FAIL = "FETCH_ALL_SCIENTIFIC_FIELD_LESSONS_FAIL";

export const ADD_NEW_SCIENTIFIC_FIELD_LESSONS_START = "ADD_NEW_SCIENTIFIC_FIELD_LESSONS_START";
export const ADD_NEW_SCIENTIFIC_FIELD_LESSONS_SUCCESS = "ADD_NEW_SCIENTIFIC_FIELD_LESSONS_SUCCESS";
export const ADD_NEW_SCIENTIFIC_FIELD_LESSONS_FAIL = "ADD_NEW_SCIENTIFIC_FIELD_LESSONS_FAIL";

export const DELETE_SCIENTIFIC_FIELD_LESSONS_START = "DELETE_SCIENTIFIC_FIELD_LESSONS_START";
export const DELETE_SCIENTIFIC_FIELD_LESSONS_SUCCESS = "DELETE_SCIENTIFIC_FIELD_LESSONS_SUCCESS";
export const DELETE_SCIENTIFIC_FIELD_LESSONS_FAIL = "DELETE_SCIENTIFIC_FIELD_LESSONS_FAIL";

//Self_Test_Chapters
export const FETCH_ALL_SELF_TEST_CHAPTERS_START = "FETCH_ALL_SELF_TEST_CHAPTERS_START";
export const FETCH_ALL_SELF_TEST_CHAPTERS_SUCCESS = "FETCH_ALL_SELF_TEST_CHAPTERS_SUCCESS";
export const FETCH_ALL_SELF_TEST_CHAPTERS_FAIL = "FETCH_ALL_SELF_TEST_CHAPTERS_FAIL";

export const ADD_NEW_SELF_TEST_CHAPTER_START = "ADD_NEW_SELF_TEST_CHAPTER_START";
export const ADD_NEW_SELF_TEST_CHAPTER_SUCCESS = "ADD_NEW_SELF_TEST_CHAPTER_SUCCESS";
export const ADD_NEW_SELF_TEST_CHAPTER_FAIL = "ADD_NEW_SELF_TEST_CHAPTER_FAIL";

export const UPDATE_SELF_TEST_CHAPTERS_START = "UPDATE_SELF_TEST_CHAPTERS_START";
export const UPDATE_SELF_TEST_CHAPTERS_SUCCESS = "UPDATE_SELF_TEST_CHAPTERS_SUCCESS";
export const UPDATE_SELF_TEST_CHAPTERS_FAIL = "UPDATE_SELF_TEST_CHAPTERS_FAIL";

export const DELETE_SELF_TEST_CHAPTER_START = "DELETE_SELF_TEST_CHAPTER_START";
export const DELETE_SELF_TEST_CHAPTER_SUCCESS = "DELETE_SELF_TEST_CHAPTER_SUCCESS";
export const DELETE_SELF_TEST_CHAPTER_FAIL = "DELETE_SELF_TEST_CHAPTER_FAIL";

//Self_Tests
export const FETCH_ALL_SELF_TESTS_START = "FETCH_ALL_SELF_TESTS_START";
export const FETCH_ALL_SELF_TESTS_SUCCESS = "FETCH_ALL_SELF_TESTS_SUCCESS";
export const FETCH_ALL_SELF_TESTS_FAIL = "FETCH_ALL_SELF_TESTS_FAIL";

export const FETCH_CURRENT_STUDENT_SELF_TESTS_START = "FETCH_CURRENT_STUDENT_SELF_TESTS_START";
export const FETCH_CURRENT_STUDENT_SELF_TESTS_SUCCESS = "FETCH_CURRENT_STUDENT_SELF_TESTS_SUCCESS";
export const FETCH_CURRENT_STUDENT_SELF_TESTS_FAIL = "FETCH_CURRENT_STUDENT_SELF_TESTS_FAIL";

export const ADD_NEW_SELF_TEST_START = "ADD_NEW_SELF_TEST_START";
export const ADD_NEW_SELF_TEST_SUCCESS = "ADD_NEW_SELF_TEST_SUCCESS";
export const ADD_NEW_SELF_TEST_FAIL = "ADD_NEW_SELF_TEST_FAIL";

export const UPDATE_SELF_TEST_START = "UPDATE_SELF_TEST_START";
export const UPDATE_SELF_TEST_SUCCESS = "UPDATE_SELF_TEST_SUCCESS";
export const UPDATE_SELF_TEST_FAIL = "UPDATE_SELF_TEST_FAIL";

//Self_Tests_Answers
export const FETCH_ALL_SELF_TEST_ANSWERS_START = "FETCH_ALL_SELF_TEST_ANSWERS_START";
export const FETCH_ALL_SELF_TEST_ANSWERS_SUCCESS = "FETCH_ALL_SELF_TEST_ANSWERS_SUCCESS";
export const FETCH_ALL_SELF_TEST_ANSWERS_FAIL = "FETCH_ALL_SELF_TEST_ANSWERS_FAIL";

export const FETCH_CURRENT_STUDENT_SELF_TEST_ANSWERS_START = "FETCH_CURRENT_STUDENT_SELF_TEST_ANSWERS_START";
export const FETCH_CURRENT_STUDENT_SELF_TEST_ANSWERS_SUCCESS = "FETCH_CURRENT_STUDENT_SELF_TEST_ANSWERS_SUCCESS";
export const FETCH_CURRENT_STUDENT_SELF_TEST_ANSWERS_FAIL = "FETCH_CURRENT_STUDENT_SELF_TEST_ANSWERS_FAIL";

export const ADD_NEW_SELF_TEST_ANSWER_START = "ADD_NEW_SELF_TEST_ANSWER_START";
export const ADD_NEW_SELF_TEST_ANSWER_SUCCESS = "ADD_NEW_SELF_TEST_ANSWER_SUCCESS";
export const ADD_NEW_SELF_TEST_ANSWER_FAIL = "ADD_NEW_SELF_TEST_ANSWER_FAIL";

//API_RESPONSE
export const SET_API_RESPONSE = "SET_API_RESPONSE";
export const RESET_API_RESPONSE = "RESET_API_RESPONSE";

//MODALS
export const SET_CHANGE_PASSWORD_MODAL_CLOSE = "SET_CHANGE_PASSWORD_MODAL_CLOSE";
export const SET_CHANGE_PASSWORD_MODAL_OPEN = "SET_CHANGE_PASSWORD_MODAL_OPEN";

export const CLEAR_INFO_MESSAGE = "CLEAR_INFO_MESSAGE";
