import { isEmpty, isEqual, uniqBy } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import { getResource } from "../../../BackendAPI/AbstractCRUDActions";
import { ActionKinds } from "../../../BackendAPI/ActionKinds";
import { getStudentResubscriptionUrl } from "../../../BackendAPI/BackendAPI";
import { StudentFromSelectorShape } from "../../../Models/StudentShape";
import { apiResponse } from "../../../Redux/Actions/index";
import { allStudents } from "../../../Redux/Selectors/studentsSelectors";
import { allFetchingFinished } from "../../../Redux/Selectors/universalSelectors";
import { clone, getDbSuffixesForStudentResubscription } from "../../../Utils/Utils";
import BaseInput from "../../UI/BaseInput/BaseInput";
import Button from "../../UI/Button/Button";
import Card from "../../UI/Card/Card";
import Datatable from "../../UI/Datatable/Datatable";
import { Col, Row } from "../../UI/Grid/Grid";
import InfoBox from "../../UI/InfoBox/InfoBox";
import Spinner from "../../UI/Spinner/Spinner";
import StudentResubscriptionForm from "./Forms/StudentResubscriptionForm";

const columns = [
	{
		name: "Α/Α",
		selector: (row) => row.id,
		sortable: true,
		width: "75px",
	},
	{
		name: "Επίθετο",
		selector: (row) => row.lastname,
		sortable: true,
		wrap: true,
	},
	{
		name: "Όνομα",
		selector: (row) => row.firstname,
		sortable: true,
		wrap: true,
	},
	{
		name: "Κινητό Τηλ.",
		selector: (row) => row.mobile_phone,
		wrap: true,
	},
	{
		name: "Email",
		selector: (row) => row.email,
		format: (row) => <a href={`mailto:${row.email}`}>{row.email}</a>,
		sortable: true,
		wrap: true,
		grow: 2,
	},
	{
		name: "Διεύθυνση",
		selector: (row) => row.address,
		sortable: true,
		wrap: true,
	},
];

class ResubscribeStudent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedRow: [],
			selectDb: {
				label: "Από Σχολικό Έτος",
				name: "dbSelect",
				options: getDbSuffixesForStudentResubscription(),
				placeholder: "Σχολικό Έτος",
				searchable: false,
				value: null,
				colSpan: 2,
				required: true,
			},
			tableData: [],
			loading: false,
			filterText: "",
			filteredData: [],
			isModalOpen: false,
		};
	}

	componentDidMount() {
		document.title = process.env.REACT_APP_NAME + " :: Επανεγγραφή Μαθητή";
	}

	componentDidUpdate(prevProps, prevState) {
		if (!isEqual(prevProps.allStudents, this.props.allStudents)) {
			const updatedFormElement = clone(this.state.selectDb);
			updatedFormElement.value = null;
			this.setState({
				selectDb: updatedFormElement,
				tableData: [],
			});
		}

		if (!prevProps.allFetchingFinished && this.props.allFetchingFinished) {
			this.resetAllFilters();
		}
	}

	selectedRow = (data) => {
		this.setState({ selectedRow: data.selectedRows });
	};

	clearSelectedRows = () => {
		this.toggledClearRows = !this.toggledClearRows;
		this.setState({ selectedRow: [] });
	};

	resetAllFilters = () => {
		this.setState({
			filterText: "",
			filteredData: [],
		});
		this.clearSelectedRows();
	};

	setFilter = (filterString) => {
		const filteredItems = this.state.tableData.filter((student) => student.lastname.toLowerCase().includes(filterString.toLowerCase()));
		const filteredItems2 = this.state.tableData.filter((student) => student.firstname.toLowerCase().includes(filterString.toLowerCase()));
		const filteredItems3 = this.state.tableData.filter((student) => student.email?.toLowerCase().includes(filterString.toLowerCase()));
		this.setState({
			filterText: filterString,
			filteredData: uniqBy(filteredItems.concat(filteredItems2, filteredItems3), "id"),
		});
	};

	onChangeHandler = (selectedOption) => {
		const updatedFormElement = clone(this.state.selectDb);
		updatedFormElement.value = selectedOption;
		this.setState({
			selectDb: updatedFormElement,
			loading: true,
		});
		const url = getStudentResubscriptionUrl(ActionKinds.GET);
		getResource(url, {
			params: {
				db_tbl: selectedOption.value,
			},
		})
			.then((response) => {
				const data = (() => {
					if (response.data !== null) {
						const temp = response.data.filter((oldStudent) => {
							if (
								this.props.allStudents.find((student) => {
									return (
										student.firstname === oldStudent.firstname &&
										student.lastname === oldStudent.lastname &&
										student.father_name === oldStudent.father_name
									);
								})
							) {
								return false;
							} else {
								return true;
							}
						});
						return temp;
					} else return [];
				})();
				this.setState({
					tableData: data,
					loading: false,
				});
			})
			.catch((error) => {
				this.setState({
					tableData: [],
					loading: false,
				});
			});
	};

	render() {
		return (
			<>
				<Row>
					<Col
						xl={9}
						lg={9}
						md={12}
						sm={12}
					>
						<Card
							title="Προβολή Μαθητών"
							headerSpannig={[8, 4]}
							id="resubStudentCard"
							extraOnHeader={
								<Button
									disabled={isEmpty(this.state.selectedRow)}
									kind="success"
									onClick={() => this.setState({ isModalOpen: true })}
									text="Ολοκλήρωση"
									type="button"
								/>
							}
						>
							<Datatable
								columns={columns}
								defaultSortFieldId={2}
								filterText={this.state.filterText}
								filteredData={this.state.filteredData}
								onClear={() => {
									this.setState({ filterText: "", filteredData: [] });
									this.clearSelectedRows();
								}}
								onFilter={(e) => {
									this.setFilter(e.target.value);
									this.clearSelectedRows();
								}}
								rowData={isEmpty(this.state.filterText) ? this.state.tableData : this.state.filteredData}
								selectable
								selectedRows={this.state.selectedRow}
								setSelectedRow={this.selectedRow}
								toggledClearRows={this.toggledClearRows}
							/>
						</Card>
					</Col>
					<Col
						xl={3}
						lg={3}
						md={12}
						sm={12}
					>
						<Card
							title="Βάση Αντιγραφής"
							id="copyDbSelect"
						>
							<label>{this.state.selectDb.label}</label>
							<BaseInput
								disabled={this.state.loading}
								key={this.state.selectDb.name}
								name={this.state.selectDb.name}
								onChange={(selectedOption) => this.onChangeHandler(selectedOption)}
								options={this.state.selectDb.options}
								placeholder={this.state.selectDb.placeholder}
								searchable={this.state.selectDb.searchable}
								value={this.state.selectDb.value}
							/>
							{this.state.loading && (
								<div className="mt-3 d-flex">
									<span className="mr-1">Φόρτωση</span> <Spinner type="ball-pulse-sync" />
								</div>
							)}
							<InfoBox classes={["mt-2"]}>Επιλέξτε το σχολικό έτος και την περίοδο μαθημάτων από τα οποία θέλετε να επανεγγράψετε μαθητές.</InfoBox>
							{!isEmpty(this.state.selectedRow) && (
								<div
									className="mt-3 bg-light p-2 border shadow-sm"
									style={{ fontSize: "93%" }}
								>
									<strong>Επιλεγμένο: </strong> {this.state.selectedRow[0].lastname + " " + this.state.selectedRow[0].firstname}
								</div>
							)}
						</Card>
					</Col>
				</Row>
				<StudentResubscriptionForm
					student={this.state.selectedRow[0]}
					isModalOpen={this.state.isModalOpen}
					closeModal={() => this.setState({ isModalOpen: false })}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		allFetchingFinished: allFetchingFinished(state),
		allStudents: allStudents(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setApiResponse: (theApiResponse) => dispatch(apiResponse(theApiResponse)),
	};
};

ResubscribeStudent.propTypes = {
	allFetchingFinished: PropTypes.bool,
	allStudents: PropTypes.arrayOf(PropTypes.exact(StudentFromSelectorShape)),
	setApiResponse: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResubscribeStudent);
