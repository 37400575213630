import { has } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import { addNewSelfTestChapter, addNewSelfTestChapterFail, addNewSelfTestChapterSuccess, apiResponse } from "../../../../Redux/Actions/index";
import { prepareDataBeforeSend } from "../../../../Utils/ComponentsUtils";
import { getParametricAsOptions } from "../../../../Utils/GuiUtils";
import { PARAMETRIC_TABLES } from "../../../../Utils/ParametricTablesBinds";
import { clone, isFormInvalid } from "../../../../Utils/Utils";
import BaseInput from "../../../UI/BaseInput/BaseInput";
import { Col, Row } from "../../../UI/Grid/Grid";
import Modal from "../../../UI/Modal/Modal";

class AddSelfTestChapterForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			addSelfTestChapterForm: {
				self_test_chapter_name: {
					label: "Ονομασία Ενότητας",
					name: "self_test_chapter_name",
					placeholder: "Ονομασία Ενότητας",
					required: true,
					value: "",
					colSpan: 12,
				},
				lesson_id: {
					label: "Μάθημα",
					name: "lesson_id",
					options: getParametricAsOptions(PARAMETRIC_TABLES.LESSONS),
					placeholder: "Μάθημα",
					required: true,
					value: null,
					colSpan: 12,
				},
			},
			loading: false,
		};
	}

	onChangeHandler = (selectedOption, inputId) => {
		const updatedForm = clone(this.state.addSelfTestChapterForm);
		const updatedFormElement = clone(updatedForm[inputId]);

		if (has(updatedFormElement, "options")) {
			updatedFormElement.value = selectedOption;
		} else {
			updatedFormElement.value = selectedOption.target.value;
		}

		updatedForm[inputId] = updatedFormElement;
		this.setState({ addSelfTestChapterForm: updatedForm });
	};

	onFormSubmit = () => {
		this.setState({ loading: true });
		const dataToSend = prepareDataBeforeSend(this.state.addSelfTestChapterForm);
		this.props
			.addNewSelfTestChapter(dataToSend)
			.then((response) => {
				const apiResponseMsg = {
					error: null,
					info: {
						message: (
							<>
								Η ενότητα <b>{response.data.name}</b> προστέθηκε με επιτυχία.
							</>
						),
					},
				};
				this.props.setApiResponse(apiResponseMsg);
				this.props.addNewSelfTestChapterSuccess(response.data);
			})
			.catch((error) => {
				const apiResponseMsg = {
					error: error,
					info: null,
				};
				this.props.setApiResponse(apiResponseMsg);
				this.props.addNewSelfTestChapterFail(error);
			})
			.finally(() => {
				this.setState({ loading: false });
				this.onCloseModal();
			});
	};

	emptyAllFormFields = () => {
		const updatedForm = clone(this.state.addSelfTestChapterForm);
		for (let key in updatedForm) {
			const updatedFormElement = clone(updatedForm[key]);
			updatedFormElement.value = "";
			updatedForm[key] = updatedFormElement;
			this.setState({ addSelfTestChapterForm: updatedForm });
		}
	};

	onCloseModal = () => {
		this.emptyAllFormFields();
		this.props.resetAllFilters();
		this.props.closeModal();
	};

	render() {
		const formElementsArray = [];
		for (let key in this.state.addSelfTestChapterForm) {
			formElementsArray.push({
				id: key,
				config: this.state.addSelfTestChapterForm[key],
			});
		}
		return (
			<Modal
				isOpen={this.props.isModalOpen}
				header={
					<>
						<em className="fas fa-plus float-left fa-1_2x mr-3" />
						Δημιουργία Ενότητας Test Αυτοαξιολόγησης
					</>
				}
				headerBg="primary"
				onSubmit={this.onFormSubmit}
				submitDisabled={isFormInvalid(this.state.addSelfTestChapterForm)}
				onClose={this.onCloseModal}
				loading={this.state.loading}
			>
				<form>
					<Row classes={["px-2"]}>
						{formElementsArray.map((element, idx) => {
							return (
								<Col
									classes={["mb-4", "px-2"]}
									key={idx}
								>
									<label>
										{element.config.label} {element.config.required ? <span className="reqField">(*)</span> : null}
									</label>
									<BaseInput
										key={element.config.name + element.config.id}
										name={element.config.name}
										placeholder={element.config.placeholder}
										options={element.config.options}
										type={element.config.inputType}
										value={element.config.value}
										onChange={(selectedOption) => {
											this.onChangeHandler(selectedOption, element.id);
										}}
									/>
								</Col>
							);
						})}
					</Row>
				</form>
			</Modal>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		addNewSelfTestChapter: (newSelfTestChapterData) => dispatch(addNewSelfTestChapter(newSelfTestChapterData)),
		addNewSelfTestChapterFail: (error) => dispatch(addNewSelfTestChapterFail(error)),
		addNewSelfTestChapterSuccess: (newSelfTestChapterData) => dispatch(addNewSelfTestChapterSuccess(newSelfTestChapterData)),
		setApiResponse: (theApiResponse) => dispatch(apiResponse(theApiResponse)),
	};
};

AddSelfTestChapterForm.propTypes = {
	addNewSelfTestChapter: PropTypes.func,
	addNewSelfTestChapterFail: PropTypes.func,
	addNewSelfTestChapterSuccess: PropTypes.func,
	isModalOpen: PropTypes.bool,
	setApiResponse: PropTypes.func,
	closeModal: PropTypes.func,
	resetAllFilters: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(AddSelfTestChapterForm);
