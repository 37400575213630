import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { isEmpty, uniqBy } from "lodash";
import ReactTooltip from "react-tooltip";

import { getTeacherViewStudentPath } from "../../../../navigation";
import { examKindIds } from "../../../../Utils/ParametricTablesBinds";
import { ParametricShape } from "../../../../Models/ParametricShape";
import Card from "../../../UI/Card/Card";
import BaseInput from "../../../UI/BaseInput/BaseInput";
import { Row, Col } from "../../../UI/Grid/Grid";
import Button from "../../../UI/Button/Button";
import Datatable from "../../../UI/Datatable/Datatable";

class StudentGradesStatsTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			examKindSelectedId: -1,
			lessonSelectedId: -1,
			filterText: "",
			filteredData: [],
		};
		this.gradeGeneralStatsPerStudentColumns = [
			{
				name: "Επίθετο",
				selector: (row) => row.lastname,
				sortable: true,
				wrap: true,
			},
			{
				name: "Όνομα",
				selector: (row) => row.firstname,
				sortable: true,
				wrap: true,
			},
			{
				name: "Απουσίες",
				selector: (row) => row.absence,
				sortable: true,
				maxWidth: "115px",
			},
			{
				name: "Πλ. Βαθμολογιών",
				selector: (row) => row.cnt,
				sortable: true,
			},
			{
				name: "Μ.Ο. /100",
				selector: (row) => Number(row.avg100.replace("%", "")),
				format: (row) => row.avg100,
				sortable: true,
				maxWidth: "117px",
			},
			{
				name: "Μ.Ο. /20",
				selector: (row) => Number(row.avg20),
				format: (row) => row.avg20,
				sortable: true,
				maxWidth: "110px",
			},
			{
				name: "Υψηλότερη",
				selector: (row) => Number(row.maxGrade.replace("%", "")),
				format: (row) => row.maxGrade,
				sortable: true,
			},
			{
				name: "Χαμηλότερη",
				selector: (row) => Number(row.minGrade.replace("%", "")),
				format: (row) => row.minGrade,
				sortable: true,
			},
			{
				name: "Τελευταία",
				selector: (row) => row.latest,
				format: (row) => (row.created_at !== "-" ? moment(row.created_at).format("ddd, DD/MM/YYYY") : "-"),
				sortable: true,
				wrap: true,
			},
			{
				name: "Προβολή",
				selector: (row) => row.student_id,
				format: (row) => (
					<a
						href={getTeacherViewStudentPath(row.student_id)}
						onClick={(e) => {
							e.preventDefault();
							this.props.history.push(getTeacherViewStudentPath(row.student_id));
						}}
					>
						<Button
							type="button"
							kind="primary"
							iconClass="fas fa-external-link-alt"
							classes={["btn-sm"]}
							data-tip={`Προβολή ${row.lastname + " " + row.firstname}`}
						/>
					</a>
				),
				maxWidth: "100px",
				center: true,
			},
		];
	}

	setFilter = (filterString, tableData) => {
		const filteredItems = tableData.filter((row) => {
			return (
				`${row.lastname} ${row.firstname}`.toLowerCase().includes(filterString.toLowerCase()) ||
				`${row.firstname} ${row.lastname}`.toLowerCase().includes(filterString.toLowerCase())
			);
		});

		this.setState({
			filterText: filterString,
			filteredData: uniqBy(filteredItems, "id"),
		});
	};

	render() {
		const tableData = this.props.gradeGeneralStatsPerStudentByTeacherId(this.state.lessonSelectedId, this.state.examKindSelectedId);
		return (
			<Card
				title="Στατιστικά / Μαθητή"
				id="studentGradesProgressCard"
				headerSpannig={[4, 8]}
				extraOnHeader={
					<Row align="center">
						<Col
							xl={6}
							lg={6}
							classes={["d-flex", "align-items-center", "mt-2", "mt-lg-0", "px-lg-2"]}
						>
							<label className="mb-0">Είδος:</label>
							<BaseInput
								name="chartExamKind"
								onChange={(selectedOption) => this.setState({ examKindSelectedId: Number(selectedOption.value) })}
								disabled={this.props.disabled}
								options={[
									{ label: "ΟΛΑ", value: -1 },
									{ label: "TEST", value: examKindIds.TEST },
									{ label: "ΔΙΑΓΩΝΙΣΜΑ", value: examKindIds.EXAM },
									{ label: "ΕΡΓΑΣΙΑ", value: examKindIds.STUDY },
								]}
								placeholder="Είδος Εξέτασης"
								searchable={false}
								classes={["ml-1", "w-100", "text-left"]}
							/>
						</Col>
						<Col
							xl={6}
							lg={6}
							classes={["d-flex", "align-items-center", "mt-2", "mt-lg-0", "px-lg-2"]}
						>
							<label className="mb-0">Μάθημα:</label>
							<BaseInput
								name="chartLesson"
								disabled={this.props.allLessons.length === 1}
								onChange={(selectedOption) => this.setState({ lessonSelectedId: Number(selectedOption.value) })}
								options={
									this.props.allLessons.length > 1
										? [{ label: "ΟΛΑ", value: -1 }].concat(
												this.props.allLessons.map((lesson) => {
													return {
														label: lesson.description,
														value: lesson.id,
													};
												}),
										  )
										: this.props.allLessons.map((lesson) => {
												return {
													label: lesson.description,
													value: lesson.id,
												};
										  })
								}
								placeholder={this.props.allLessons.length === 1 ? this.props.allLessons[0].description : "Μάθημα"}
								searchable={false}
								classes={["ml-1", "w-100", "text-left"]}
							/>
						</Col>
					</Row>
				}
			>
				<Datatable
					columns={this.gradeGeneralStatsPerStudentColumns}
					defaultSortFieldId={1}
					exportCsv
					exportFilename="Πίνακας Στατιστικών Βαθμολογιών Μαθητολογίου Πολύτροπο"
					filteredData={this.state.filteredData}
					filterText={this.state.filterText}
					onClear={() => {
						this.setState({ filterText: "", filteredData: [] });
					}}
					onFilter={(e) => {
						this.setFilter(e.target.value, tableData);
					}}
					rowData={isEmpty(this.state.filterText) ? tableData : this.state.filteredData}
				/>
				<ReactTooltip effect="solid" />
			</Card>
		);
	}
}

StudentGradesStatsTable.propTypes = {
	gradeGeneralStatsPerStudentByTeacherId: PropTypes.func,
	allLessons: PropTypes.arrayOf(PropTypes.exact(ParametricShape)),
	disabled: PropTypes.bool,
};

export default withRouter(StudentGradesStatsTable);
