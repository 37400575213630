import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React from "react";

const InfoBox = ({ children, classes }) => {
	return (
		<div className={`bg-gray-lighter p-3 border rounded ${!isEmpty(classes) ? classes.join(" ") : ""}`}>
			<i className="fas fa-exclamation-circle mr-1 text-primary"></i>
			{children}
		</div>
	);
};

InfoBox.propTypes = {
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	classes: PropTypes.arrayOf(PropTypes.string),
};

export default InfoBox;
