import { sortArrayOfObjectsByString, updateObject } from "../../Utils/Utils";
import {
	ADD_NEW_SHORTCUT_FAIL,
	ADD_NEW_SHORTCUT_START,
	ADD_NEW_SHORTCUT_SUCCESS,
	AUTH_LOGOUT,
	DELETE_SHORTCUT_FAIL,
	DELETE_SHORTCUT_START,
	DELETE_SHORTCUT_SUCCESS,
	FETCH_ALL_SHORTCUTS_FAIL,
	FETCH_ALL_SHORTCUTS_START,
	FETCH_ALL_SHORTCUTS_SUCCESS,
	UPDATE_SHORTCUT_FAIL,
	UPDATE_SHORTCUT_START,
	UPDATE_SHORTCUT_SUCCESS,
} from "../Actions/actionTypes";

const initialState = {
	allShortcuts: [],
	error: null,
	finished: false,
	loading: false,
};

export const fetchShortcutsStart = (state) => {
	return updateObject(state, {
		error: null,
		finished: false,
		loading: true,
	});
};

const fetchShortcutsSuccess = (state, action) => {
	const updatedShortcuts = action.payload.shortcuts.map((shortcut, idx) => {
		const updatedShortcut = { ...shortcut };
		updatedShortcut.lessons = [];
		const updatedShortcutLessons = action.payload.shortcutLessons.filter((obj) => obj.shortcut_id === updatedShortcut.id);
		for (let i in updatedShortcutLessons) {
			if (updatedShortcutLessons[i].shortcut_id === updatedShortcut.id) {
				updatedShortcut.lessons.push(updatedShortcutLessons[i].lesson_id);
			}
		}
		return updatedShortcut;
	});
	return updateObject(state, {
		allShortcuts: updatedShortcuts,
		error: null,
		finished: true,
		loading: false,
	});
};

const fetchShortcutsFail = (state, action) => {
	return updateObject(state, {
		allShortcuts: [],
		error: action.payload,
		finished: false,
		loading: false,
	});
};

const addNewShortcutStart = (state) => {
	return updateObject(state, {
		error: null,
		loading: true,
	});
};

const addNewShortcutSuccess = (state, action) => {
	const updatedShortcuts = [...state.allShortcuts];
	const newShortcut = { ...action.payload };
	updatedShortcuts.push(newShortcut);
	sortArrayOfObjectsByString(updatedShortcuts, "name");
	return updateObject(state, {
		allShortcuts: updatedShortcuts,
		error: null,
		loading: false,
	});
};

const addNewShortcutFail = (state, action) => {
	return updateObject(state, {
		error: action.payload,
		loading: false,
	});
};

const updateShortcutStart = (state) => {
	return updateObject(state, {
		error: null,
		loading: true,
		success: false,
	});
};

const updateShortcutSuccess = (state, action) => {
	const updatedShortcuts = [...state.allShortcuts].filter((obj) => obj.id !== action.payload.id);
	const newShortcut = { ...action.payload };
	updatedShortcuts.push(newShortcut);
	sortArrayOfObjectsByString(updatedShortcuts, "name");
	return updateObject(state, {
		allShortcuts: updatedShortcuts,
		error: null,
		loading: false,
		success: true,
	});
};

const updateShortcutFail = (state, action) => {
	return updateObject(state, {
		error: action.payload,
		loading: false,
		success: false,
	});
};

const deleteShortcutStart = (state) => {
	return updateObject(state, {
		error: null,
		loading: true,
		success: false,
	});
};

const deleteShortcutSuccess = (state, action) => {
	const updatedShortcuts = [...state.allShortcuts].filter((obj) => obj.id !== action.payload.id);
	return updateObject(state, {
		allShortcuts: updatedShortcuts,
		error: null,
		loading: false,
		success: true,
	});
};

const deleteShortcutFail = (state, action) => {
	return updateObject(state, {
		error: action.payload,
		loading: false,
		success: false,
	});
};

const authLogout = (state) => {
	return updateObject(state, initialState);
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_ALL_SHORTCUTS_START:
			return fetchShortcutsStart(state);
		case FETCH_ALL_SHORTCUTS_FAIL:
			return fetchShortcutsFail(state, action);
		case FETCH_ALL_SHORTCUTS_SUCCESS:
			return fetchShortcutsSuccess(state, action);
		case ADD_NEW_SHORTCUT_START:
			return addNewShortcutStart(state);
		case ADD_NEW_SHORTCUT_FAIL:
			return addNewShortcutFail(state, action);
		case ADD_NEW_SHORTCUT_SUCCESS:
			return addNewShortcutSuccess(state, action);
		case UPDATE_SHORTCUT_START:
			return updateShortcutStart(state);
		case UPDATE_SHORTCUT_FAIL:
			return updateShortcutFail(state, action);
		case UPDATE_SHORTCUT_SUCCESS:
			return updateShortcutSuccess(state, action);
		case DELETE_SHORTCUT_START:
			return deleteShortcutStart(state);
		case DELETE_SHORTCUT_FAIL:
			return deleteShortcutFail(state, action);
		case DELETE_SHORTCUT_SUCCESS:
			return deleteShortcutSuccess(state, action);
		case AUTH_LOGOUT:
			return authLogout(state);
		default:
			return state;
	}
};

export default reducer;
