import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import { StudentFromSelectorShape } from "../../../../../Models/StudentShape";
import {
	addNewPanhellenicExaminationResult,
	addNewPanhellenicExaminationResultFail,
	addNewPanhellenicExaminationResultSuccess,
	apiResponse,
} from "../../../../../Redux/Actions/index";
import { CUSTOM_ERRORS } from "../../../../../Utils/CustomErrors";
import { clone, roundNumber } from "../../../../../Utils/Utils";
import Alert from "../../../../UI/Alert/Alert";
import { Col, Row } from "../../../../UI/Grid/Grid";
import { MODAL_SIZE } from "../../../../UI/Modal/constants/ModalSize";
import Modal from "../../../../UI/Modal/Modal";
import StandardLessonsForm from "./LessonForms/StandardLessonsForm";

const LessonFormComponentName = {
	STANDARD_LESSONS: "StandardLessonsForm",
};

class AddPanhellenicExaminationForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			finalResultWithSpecialLessons: null,
			gradesError: null,
			standardLessons: {
				result: null,
				student_id: null,
				grades: [],
				average: null,
			},
			isStandardLessonFormInvalid: true,
			standardLessonFormError: null,
			loading: false,
		};
		this.showStandardLessonErrorAlert = false;
	}

	areGradesValid = (grades) => {
		let output = true;
		grades.forEach((grade) => {
			if (grade > 20) {
				this.setState({
					finalResult: null,
					gradesError: "Βρέθηκε βαθμός > 20. Παρακαλώ διορθώστε.",
				});
				output = false;
			} else if (grade < 0) {
				this.setState({
					finalResult: null,
					gradesError: "Βρέθηκε βαθμός < 0. Παρακαλώ διορθώστε.",
				});
				output = false;
			}
		});
		if (output) {
			this.setState({ gradesError: null });
		}
		return output;
	};

	setStandardLessons = (result, student_id, grades) => {
		const updatedStandardLessons = clone(this.state.standardLessons);
		updatedStandardLessons.result = result;
		updatedStandardLessons.grades = grades;
		updatedStandardLessons.student_id = student_id;
		const sum = grades.map((grade) => grade.grade).reduce((a, b) => a + b, 0);
		updatedStandardLessons.average = roundNumber(sum / 4, 2);
		this.setState({
			standardLessons: updatedStandardLessons,
			finalResultWithSpecialLessons: result.sector,
		});
	};

	setIsFormInvalid = (isInvalid, formName) => {
		if (formName === LessonFormComponentName.STANDARD_LESSONS) this.setState({ isStandardLessonFormInvalid: isInvalid });
		else
			CUSTOM_ERRORS.throwError(
				`PanhellenicExaminations/Forms/${this.constructor.name}.js | line 159`,
				CUSTOM_ERRORS.UNEXPECTED_IF_STATEMENT,
				formName,
			);
	};

	setGradesError = (error, formName) => {
		if (formName === LessonFormComponentName.STANDARD_LESSONS) {
			this.setState({ standardLessonFormError: error });
			if (error !== null) {
				this.showStandardLessonErrorAlert = true;
			} else {
				this.showStandardLessonErrorAlert = false;
			}
		} else
			CUSTOM_ERRORS.throwError(
				`PanhellenicExaminations/Forms/${this.constructor.name}.js | line 168`,
				CUSTOM_ERRORS.UNEXPECTED_IF_STATEMENT,
				formName,
			);
	};

	onFormSubmit = () => {
		this.setState({ loading: true });
		const output = {};
		output.grades = [];
		output.scientific_field_id = null;
		output.finalResult = this.state.standardLessons.result.team;
		output.student_id = this.state.standardLessons.student_id.value;
		output.grades = this.state.standardLessons.grades;
		output.average = this.state.standardLessons.average;
		output.finalResultWithSpecialLessons = this.state.finalResultWithSpecialLessons;
		output.finalResultWithFactor2 = null;
		output.design_grades = [];
		output.foreign_language_grades = [];

		this.props
			.addNewPanhellenicExaminationResult(output)
			.then((response) => {
				const result = {
					...response.data,
					grades: output.grades,
				};
				const student = this.props.allStudents.filter((student) => student.id === response.data.student_id)[0];
				const apiResponseMsg = {
					error: null,
					info: {
						message: (
							<>
								Η βαθμολογία του μαθητή{" "}
								<b>
									{student.lastname} {student.firstname}
								</b>{" "}
								προστέθηκε με επιτυχία.
							</>
						),
					},
				};
				this.props.setApiResponse(apiResponseMsg);
				this.props.addNewPanhellenicExaminationResultSuccess(result);
			})
			.catch((error) => {
				const apiResponseMsg = {
					error: error,
					info: null,
				};
				this.props.setApiResponse(apiResponseMsg);
				this.props.addNewPanhellenicExaminationResultFail(error);
			})
			.finally(() => {
				this.setState({ loading: false });
				this.props.resetAllFilters();
				this.props.closeModal();
			});
	};

	render() {
		return (
			<Modal
				isOpen={this.props.isModalOpen}
				header={
					<>
						<em className="fas fa-plus float-left fa-1_2x mr-3" />
						Προσθήκη Βαθμολογίας Πανελλαδικών Εξετάσεων
					</>
				}
				headerBg="primary"
				onSubmit={this.onFormSubmit}
				submitDisabled={this.state.isStandardLessonFormInvalid || this.state.standardLessonFormError !== null}
				onClose={this.props.closeModal}
				size={MODAL_SIZE.lg}
				loading={this.state.loading}
			>
				<form>
					<Row classes={["px-2"]}>
						<StandardLessonsForm
							allStudents={this.props.allStudents}
							setStandardLessons={this.setStandardLessons}
							setGradesError={this.setGradesError}
							setIsFormInvalid={this.setIsFormInvalid}
						/>
					</Row>
					{this.state.standardLessons.result && this.state.standardLessonFormError === null && (
						<Row classes={["mb-1"]}>
							<Col>
								Κοινή Ομάδα:{" "}
								<span
									style={{ fontSize: "120%" }}
									className="text-green ml-1 text-bold"
								>
									{this.state.standardLessons.result.team}
								</span>
							</Col>
						</Row>
					)}
					{this.state.finalResultWithSpecialLessons && this.state.standardLessonFormError === null && (
						<Row classes={["mb-1"]}>
							<Col>
								Τομέας:{" "}
								<span
									style={{ fontSize: "120%" }}
									className="text-green ml-1 text-bold"
								>
									{this.state.finalResultWithSpecialLessons}
								</span>
							</Col>
						</Row>
					)}
					{this.state.standardLessons.average && this.state.standardLessonFormError === null && (
						<Row classes={["mb-1"]}>
							<Col>
								Μέσος Όρος:{" "}
								<span
									style={{ fontSize: "120%" }}
									className="text-green ml-1 text-bold"
								>
									{this.state.standardLessons.average}
								</span>
							</Col>
						</Row>
					)}
					{this.showStandardLessonErrorAlert && (
						<Alert
							dismissible
							type="danger"
							onClose={() => (this.showStandardLessonErrorAlert = false)}
							message={<span>Σφάλμα: {this.state.standardLessonFormError}</span>}
						/>
					)}
					<Row classes={["px-2", "mt-3"]}>
						<Col classes={["bg-light", "p-2", "shadow-sm", "border"]}>
							<i className="fas fa-info-circle mr-2 text-info"></i>Η υποδιαστολή δηλώνεται με τον χαρακτήρα "." και όχι με τον χαρακτήρα ","
						</Col>
					</Row>
				</form>
			</Modal>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		addNewPanhellenicExaminationResult: (newResultsData) => dispatch(addNewPanhellenicExaminationResult(newResultsData)),
		addNewPanhellenicExaminationResultFail: (error) => dispatch(addNewPanhellenicExaminationResultFail(error)),
		addNewPanhellenicExaminationResultSuccess: (newResultsData) => dispatch(addNewPanhellenicExaminationResultSuccess(newResultsData)),
		setApiResponse: (theApiResponse) => dispatch(apiResponse(theApiResponse)),
	};
};

AddPanhellenicExaminationForm.propTypes = {
	addNewPanhellenicExaminationResult: PropTypes.func,
	addNewPanhellenicExaminationResultFail: PropTypes.func,
	addNewPanhellenicExaminationResultSuccess: PropTypes.func,
	allStudents: PropTypes.arrayOf(PropTypes.exact(StudentFromSelectorShape)),
	closeModal: PropTypes.func,
	isModalOpen: PropTypes.bool,
	resetAllFilters: PropTypes.func,
	setApiResponse: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(AddPanhellenicExaminationForm);
