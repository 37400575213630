import { updateObject } from "../../Utils/Utils";
import { AUTH_LOGOUT, SET_CHANGE_PASSWORD_MODAL_CLOSE, SET_CHANGE_PASSWORD_MODAL_OPEN } from "../Actions/actionTypes";

const initialState = {
	isModalOpen: false,
	userId: null,
};

const setModalOpen = (state, action) => {
	const updatedObj = {
		isModalOpen: true,
		userId: action.payload.userId,
	};
	return updateObject(state, updatedObj);
};

const setModalClose = (state) => {
	return updateObject(state, initialState);
};

const authLogout = (state) => {
	return updateObject(state, initialState);
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_CHANGE_PASSWORD_MODAL_OPEN:
			return setModalOpen(state, action);
		case SET_CHANGE_PASSWORD_MODAL_CLOSE:
			return setModalClose(state);
		case AUTH_LOGOUT:
			return authLogout(state);
		default:
			return state;
	}
};

export default reducer;
