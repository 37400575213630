import PropTypes from "prop-types";
import { ParametricShape } from "./ParametricShape";

export const DepartmentShape = {
	created_at: PropTypes.string,
	class: PropTypes.oneOfType([PropTypes.exact(ParametricShape), PropTypes.number]),
	id: PropTypes.number,
	name: PropTypes.string,
	students_count: PropTypes.number,
	updated_at: PropTypes.string,
};
