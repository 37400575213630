import $ from "jquery";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { APP_CONFIG_PATHS, APP_PANHELLENIC_EXAMINATIONS_PATHS, APP_SELF_TEST_PATHS, APP_STUDENT_MNG_PATHS, PathEntry } from "../../../navigation";

import { resetApiResponse } from "../../../Redux/Actions";
import { getAuthData } from "../../../Utils/AuthUtils";
import MenuItem from "./MenuItems/MenuItem/MenuItem";
import MenuItems from "./MenuItems/MenuItems";

class LeftSideBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activePath: this.props.location.pathname,
			hoverLink: null,
			menuItems: {
				dashboard: {
					items: [
						{
							active: this.props.location.pathname,
							icon: <em className="fas fa-tachometer-alt" />,
							onClick: () => this.setActivePath(PathEntry.dashboard.path),
							title: "Dashboard",
							to: PathEntry.dashboard.path,
						},
					],
				},
				userManagement: {
					items: [
						{
							active: this.props.location.pathname,
							icon: <em className="fas fa-users-cog" />,
							onClick: () => this.setActivePath(PathEntry.userManagement.path),
							title: "Διαχείριση Χρηστών",
							to: PathEntry.userManagement.path,
						},
					],
				},
				appConfig: {
					headerTitle: "Ρυθμίσεις Εφαρμογής",
					containerId: "appConfigContainer",
					items: [
						{
							active: this.props.location.pathname,
							onClick: () => this.setActivePath(PathEntry.shortcutsManagement.path, "appConfigContainer"),
							title: "Διαχείριση Συντομεύσεων",
							to: PathEntry.shortcutsManagement.path,
						},
						{
							active: this.props.location.pathname,
							onClick: () => this.setActivePath(PathEntry.parametricsManagement.path, "appConfigContainer"),
							title: "Διαχείριση Παραμετρικών",
							to: PathEntry.parametricsManagement.path,
						},
						{
							active: this.props.location.pathname,
							onClick: () => this.setActivePath(PathEntry.lessonAssignments.path, "appConfigContainer"),
							title: "Ανάθεση Μαθημάτων",
							to: PathEntry.lessonAssignments.path,
						},
						{
							active: this.props.location.pathname,
							onClick: () => this.setActivePath(PathEntry.departmentAssignments.path, "appConfigContainer"),
							title: "Ανάθεση Τμημάτων",
							to: PathEntry.departmentAssignments.path,
						},
						{
							active: this.props.location.pathname,
							onClick: () => this.setActivePath(PathEntry.scientificFieldLessonsAssignments.path, "appConfigContainer"),
							title: "Ανάθεση Επ. Πεδίων",
							to: PathEntry.scientificFieldLessonsAssignments.path,
						},
					],
				},
				departmentsManagement: {
					items: [
						{
							active: this.props.location.pathname,
							icon: <em className="fas fa-chalkboard" />,
							onClick: () => this.setActivePath(PathEntry.departmentsManagement.path),
							title: "Διαχείριση Τμημάτων",
							to: PathEntry.departmentsManagement.path,
						},
					],
				},
				studentsManagement: {
					headerTitle: "Διαχείριση Μαθητών",
					containerId: "studentManagementContainer",
					items: [
						{
							active: this.props.location.pathname,
							onClick: () => this.setActivePath(PathEntry.addStudent.path, "studentManagementContainer"),
							title: "Προσθήκη Μαθητή",
							to: PathEntry.addStudent.path,
						},
						{
							active: this.props.location.pathname,
							onClick: () => this.setActivePath(PathEntry.studentsManagement.path, "studentManagementContainer"),
							title: "Προβολή Μαθητών",
							to: PathEntry.studentsManagement.path,
						},
						{
							active: this.props.location.pathname,
							onClick: () => this.setActivePath(PathEntry.resubscribeStudent.path, "studentManagementContainer"),
							title: "Επανεγγραφή Μαθητή",
							to: PathEntry.resubscribeStudent.path,
						},
					],
				},
				gradesManagement: {
					items: [
						{
							active: this.props.location.pathname,
							icon: <em className="far fa-star" />,
							onClick: () => this.setActivePath(PathEntry.gradesManagement.path),
							title: "Διαχείριση Βαθμολογιών",
							to: PathEntry.gradesManagement.path,
						},
					],
				},
				panhellenicExaminations: {
					headerTitle: "Πανελλαδικές Εξετάσεις",
					containerId: "panhellenicExaminationContainer",
					items: [
						{
							active: this.props.location.pathname,
							onClick: () => this.setActivePath(PathEntry.gelPanhellenicExaminations.path, "panhellenicExaminationContainer"),
							title: "Βαθμολογίες Γ.Ε.Λ",
							to: PathEntry.gelPanhellenicExaminations.path,
						},
						{
							active: this.props.location.pathname,
							onClick: () => this.setActivePath(PathEntry.epalPanhellenicExaminations.path, "panhellenicExaminationContainer"),
							title: "Βαθμολογίες ΕΠΑ.Λ",
							to: PathEntry.epalPanhellenicExaminations.path,
						},
						{
							active: this.props.location.pathname,
							onClick: () => this.setActivePath(PathEntry.educationalInstitutionsPanhellenicExaminations.path, "panhellenicExaminationContainer"),
							title: "Εκπαιδευτικό Ίδρυμα",
							to: PathEntry.educationalInstitutionsPanhellenicExaminations.path,
						},
					],
				},
				selfTestManagement: {
					headerTitle: "Test Αυτοαξιολόγησης",
					containerId: "selfTestManagementContainer",
					items: [
						{
							active: this.props.location.pathname,
							onClick: () => this.setActivePath(PathEntry.selfTestManagement.path, "selfTestManagementContainer"),
							title: "Διαχείριση Test",
							to: PathEntry.selfTestManagement.path,
						},
						{
							active: this.props.location.pathname,
							onClick: () => this.setActivePath(PathEntry.selfTestChaptersManagement.path, "selfTestManagementContainer"),
							title: "Διαχείριση Ενοτήτων Test",
							to: PathEntry.selfTestChaptersManagement.path,
						},
						{
							active: this.props.location.pathname,
							onClick: () => this.setActivePath(PathEntry.selfTestResultsView.path, "selfTestManagementContainer"),
							title: "Αποτελέσματα Test",
							to: PathEntry.selfTestResultsView.path,
						},
					],
				},
				logsView: {
					items: [
						{
							active: this.props.location.pathname,
							icon: <em className="far fa-file-alt" />,
							onClick: () => this.setActivePath(PathEntry.logsView.path),
							title: "Αρχεία Καταγραφών",
							to: PathEntry.logsView.path,
						},
					],
				},
			},
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.location.pathname !== this.props.location.pathname) {
			const updatedMenuItems = { ...this.state.menuItems };
			for (let key in updatedMenuItems) {
				const items = [...updatedMenuItems[key].items];
				for (let itemKey in items) {
					const updatedItem = { ...items[itemKey] };
					updatedItem.active = this.props.location.pathname;
					items[itemKey] = updatedItem;
				}
				updatedMenuItems[key].items = items;
			}
			this.setState({ menuItems: updatedMenuItems });
			if (this.props.apiResponse.info !== null || this.props.apiResponse.error !== null) {
				this.props.resetApiResponse();
			}
		}
	}

	setActivePath = (path, currentElementId) => {
		if (path !== this.state.activePath) {
			$("ul.sidebar-nav li").each(function (index, value) {
				const a = $(value).find("a");
				if (a && $(a).attr("data-toggle")) {
					if (a.attr("id") !== currentElementId || currentElementId === undefined) {
						$(a).attr("class", "collapsed");
						$(a).attr("aria-expanded", false);
						$(a).parent().find("ul").removeClass("show");
					}
				}
			});
		}
		this.setState({ activePath: path });
	};

	remainOpen = (id) => {
		this.setState({ hoverLink: id });
	};

	onHover = (id) => {
		if ($("body").hasClass("aside-collapsed")) {
			this.setState({ hoverLink: id });
		}
	};

	onLeave = () => {
		if ($("body").hasClass("aside-collapsed")) {
			this.setState({ hoverLink: null });
		}
	};

	renderPopup = () => {
		if ($("body").hasClass("aside-collapsed")) {
			const coord = document.querySelector("#" + this.state.menuItems[this.state.hoverLink].containerId).getBoundingClientRect();
			return (
				<MenuItems
					headerTitle={this.state.menuItems[this.state.hoverLink].headerTitle}
					classes={["show", "popupMenu", "nav-floating"]}
					id={this.state.hoverLink}
					onMouseEnter={() => this.remainOpen(this.state.hoverLink)}
					onMouseLeave={this.onLeave}
					style={{ position: "absolute", top: coord.top, bottom: "auto", minWidth: "210px" }}
					items={this.state.menuItems[this.state.hoverLink].items}
				/>
			);
		}
	};

	render() {
		return (
			<aside className="aside-container lowZindex">
				<div className="aside-inner">
					<nav
						className="sidebar"
						data-sidebar-anyclick-close=""
					>
						<ul className="sidebar-nav">
							<li className="nav-heading ">
								<span data-localize="sidebar.heading.HEADER">Configurations</span>
							</li>
							<MenuItem item={this.state.menuItems.userManagement.items[0]} />
							<li
								className={APP_CONFIG_PATHS.includes(this.state.activePath) ? "active" : null}
								onMouseEnter={() => this.onHover("appConfig")}
								onMouseLeave={this.onLeave}
							>
								<a
									href="#appConfig"
									title={this.state.menuItems.appConfig.headerTitle}
									id={this.state.menuItems.appConfig.containerId}
									data-toggle="collapse"
								>
									<em className="fas fa-cogs"></em>
									<span>{this.state.menuItems.appConfig.headerTitle}</span>
								</a>
								<MenuItems
									headerTitle={this.state.menuItems.appConfig.headerTitle}
									id="appConfig"
									items={this.state.menuItems.appConfig.items}
								/>
							</li>
							<li className="nav-heading ">
								<span data-localize="sidebar.heading.COMPONENTS">Main Navigation</span>
							</li>
							<MenuItem item={this.state.menuItems.dashboard.items[0]} />
							<li
								className={APP_STUDENT_MNG_PATHS.includes(this.state.activePath) ? "active" : null}
								onMouseEnter={() => this.onHover("studentsManagement")}
								onMouseLeave={this.onLeave}
							>
								<a
									href="#studentsManagement"
									title={this.state.menuItems.studentsManagement.headerTitle}
									id={this.state.menuItems.studentsManagement.containerId}
									data-toggle="collapse"
								>
									<em className="fas fa-user-cog"></em>
									<span>{this.state.menuItems.studentsManagement.headerTitle}</span>
								</a>
								<MenuItems
									headerTitle={this.state.menuItems.studentsManagement.headerTitle}
									id="studentsManagement"
									items={this.state.menuItems.studentsManagement.items}
								/>
							</li>
							<MenuItem item={this.state.menuItems.departmentsManagement.items[0]} />
							<MenuItem item={this.state.menuItems.gradesManagement.items[0]} />
							{getAuthData().period.original !== "ther" && (
								<li
									className={APP_PANHELLENIC_EXAMINATIONS_PATHS.includes(this.state.activePath) ? "active" : null}
									onMouseEnter={() => this.onHover("panhellenicExaminations")}
									onMouseLeave={this.onLeave}
								>
									<a
										href="#panhellenicExaminations"
										title={this.state.menuItems.panhellenicExaminations.headerTitle}
										id={this.state.menuItems.panhellenicExaminations.containerId}
										data-toggle="collapse"
									>
										<em className="far fa-edit"></em>
										<span>{this.state.menuItems.panhellenicExaminations.headerTitle}</span>
									</a>
									<MenuItems
										headerTitle={this.state.menuItems.panhellenicExaminations.headerTitle}
										id="panhellenicExaminations"
										items={this.state.menuItems.panhellenicExaminations.items}
									/>
								</li>
							)}
							{getAuthData().period.original !== "ther" && (
								<li
									className={APP_SELF_TEST_PATHS.includes(this.state.activePath) ? "active" : null}
									onMouseEnter={() => this.onHover("selfTestManagement")}
									onMouseLeave={this.onLeave}
								>
									<a
										href="#selfTestManagement"
										title={this.state.menuItems.selfTestManagement.headerTitle}
										id={this.state.menuItems.selfTestManagement.containerId}
										data-toggle="collapse"
									>
										<em className="fas fa-tasks"></em>
										<span>{this.state.menuItems.selfTestManagement.headerTitle}</span>
									</a>
									<MenuItems
										headerTitle={this.state.menuItems.selfTestManagement.headerTitle}
										id="selfTestManagement"
										items={this.state.menuItems.selfTestManagement.items}
									/>
								</li>
							)}
							<MenuItem item={this.state.menuItems.logsView.items[0]} />
						</ul>
					</nav>
				</div>
				{this.state.hoverLink != null ? this.renderPopup() : null}
			</aside>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		apiResponse: state.apiResponse,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		resetApiResponse: () => dispatch(resetApiResponse()),
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeftSideBar));
