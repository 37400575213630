import { StatusCodes } from "http-status-codes";
import { has, isEmpty, isEqual } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import { globalStore } from "../../../../globalStore";
import { DepartmentShape } from "../../../../Models/DepartmentShape";
import { ShortcutShape } from "../../../../Models/ShortcutShape";
import {
	addNewStudent,
	addNewStudentFail,
	addNewStudentSuccess,
	addNewUser,
	addNewUserFail,
	addNewUserSuccess,
	apiResponse,
	updateStudent,
	updateStudentFail,
	updateStudentSuccess,
} from "../../../../Redux/Actions/index";
import { allDepartments } from "../../../../Redux/Selectors/departmentsSelectors";
import { allShortcuts } from "../../../../Redux/Selectors/shortcutsSelectors";
import { allStudents } from "../../../../Redux/Selectors/studentsSelectors";
import { allFetchingFinished } from "../../../../Redux/Selectors/universalSelectors";
import { DATE_DATA_TYPES, getPropsToStateData, getPropsToStudentLessonsData, prepareDataBeforeSend } from "../../../../Utils/ComponentsUtils";
import { CUSTOM_ERRORS } from "../../../../Utils/CustomErrors";
import { getLessonsAsCheckboxesGroup, getParametricAsOptions } from "../../../../Utils/GuiUtils";
import { nullifyEmptyStrings } from "../../../../Utils/NullConversionUtils";
import { PARAMETRIC_TABLES, permissions, STATUSES } from "../../../../Utils/ParametricTablesBinds";
import { clone } from "../../../../Utils/Utils";
import BaseInput from "../../../UI/BaseInput/BaseInput";
import Button from "../../../UI/Button/Button";
import Card from "../../../UI/Card/Card";
import Divider from "../../../UI/Divider/Divider";
import { Col, Row } from "../../../UI/Grid/Grid";
import { MODAL_SIZE } from "../../../UI/Modal/constants/ModalSize";
import Modal from "../../../UI/Modal/Modal";
import Spinner from "../../../UI/Spinner/Spinner";
import Tag from "../../../UI/Tag/Tag";
import { STUDENT_FORM_MODES } from "../Forms/ManageStudentFormWrapper";
import GeneralCard from "./GeneralCard/GeneralCard";
const greekUtils = require("greek-utils");
const generator = require("generate-password");

class ManageStudentForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isModalOpen: false,
			loading: false,
			studentPersonalData: {
				firstname: {
					label: "Όνομα",
					name: "firstname",
					placeholder: "Όνομα",
					value: "",
					colSpan: 3,
					required: true,
				},
				lastname: {
					label: "Επίθετο",
					name: "lastname",
					placeholder: "Επίθετο",
					value: "",
					colSpan: 3,
					required: true,
				},
				email: {
					label: "Email Μαθητή",
					name: "email",
					placeholder: "Email Μαθητή",
					value: "",
					colSpan: 4,
				},
				mobile_phone: {
					label: "Κινητό Τηλ.",
					name: "mobile_phone",
					placeholder: "Κινητό Τηλ.",
					maxLength: 10,
					value: "",
					colSpan: 2,
				},
				landline: {
					label: "Σταθερό Τηλ.",
					name: "landline",
					placeholder: "Σταθερό Τηλ.",
					maxLength: 10,
					value: "",
					colSpan: 2,
				},
				address: {
					label: "Διεύθυνση",
					name: "address",
					placeholder: "Διεύθυνση",
					value: "",
					colSpan: 5,
				},
				county: {
					label: "Περιοχή",
					name: "county",
					placeholder: "Περιοχή",
					value: "",
					colSpan: 3,
				},
				zip: {
					label: "Ταχ. Κωδικός",
					name: "zip",
					placeholder: "Ταχ. Κωδικός",
					value: "",
					maxLength: 5,
					colSpan: 2,
				},
			},
			studentParentsData: {
				mother_name: {
					label: "Όνομα Μητέρας",
					name: "mother_name",
					placeholder: "Όνομα Μητέρας",
					value: "",
					colSpan: 3,
				},
				father_name: {
					label: "Όνομα Πατέρα",
					name: "father_name",
					placeholder: "Όνομα Πατέρα",
					value: "",
					colSpan: 3,
				},
				mother_phone: {
					label: "Τηλ. Μητέρας",
					name: "mother_phone",
					placeholder: "Τηλ. Μητέρας",
					maxLength: 10,
					value: "",
					colSpan: 3,
				},
				father_phone: {
					label: "Τηλ. Πατέρα",
					name: "father_phone",
					placeholder: "Τηλ. Πατέρα",
					maxLength: 10,
					value: "",
					colSpan: 3,
				},
				caretaker_name: {
					label: "Όνομα Κηδεμόνα",
					name: "caretaker_name",
					placeholder: "Όνομα Κηδεμόνα",
					value: "",
					colSpan: 4,
				},
				caretaker_phone: {
					label: "Τηλ. Κηδεμόνα",
					name: "caretaker_phone",
					placeholder: "Τηλ. Κηδεμόνα",
					maxLength: 10,
					value: "",
					colSpan: 4,
				},
				caretaker_email: {
					label: "Email Κηδεμόνα",
					name: "caretaker_email",
					placeholder: "Email Κηδεμόνα",
					value: "",
					colSpan: 4,
				},
			},
			studentStudiesData: {
				submission_date: {
					label: "Ημ. Εγγραφής",
					name: "submission_date",
					placeholder: "Ημ. Εγγραφής",
					datepicker: true,
					colSpan: 3,
					selected: new Date(),
					required: true,
				},
				status: {
					label: "Κατάσταση",
					name: "status",
					options: getParametricAsOptions(PARAMETRIC_TABLES.STATUS),
					searchable: false,
					placeholder: "Κατάσταση",
					value: { value: STATUSES.ENABLED, label: "Ενεργός" },
					colSpan: 2,
					isNumber: true,
					required: true,
				},
				fee: {
					label: "Δίδακτρα",
					name: "fee",
					placeholder: "Δίδακτρα",
					value: "",
					colSpan: 2,
					inputType: "number",
					isNumber: true,
				},
				department: {
					label: "Τμήμα",
					name: "department",
					options: this.getDepartmentDropdown(),
					placeholder: "Τμήμα",
					value: null,
					colSpan: 2,
					menuPlacement: "top",
					required: true,
					isNumber: true,
				},
				informed_by: {
					label: "Ενημερώθηκε Από",
					name: "informed_by",
					options: getParametricAsOptions(PARAMETRIC_TABLES.INFORMED_BY),
					placeholder: "Τρ. Ενημέρωσης",
					value: "",
					menuPlacement: "top",
					colSpan: 3,
					isNumber: true,
				},
			},
			studentLessonsData: null,
			studentReaded: false,
		};
	}

	componentDidMount() {
		document.title = process.env.REACT_APP_NAME + " :: Προσθήκη Μαθητή";
		this.setState({
			studentLessonsData: getLessonsAsCheckboxesGroup(globalStore.lessons),
		});
	}

	componentDidUpdate(prevProps) {
		const updatedStudiesData = clone(this.state.studentStudiesData);
		updatedStudiesData.submission_date.selected = new Date();
		// Initialize all parametric lists when they have been loaded
		if (!prevProps.parametricsFinished && this.props.parametricsFinished) {
			updatedStudiesData.informed_by = { ...updatedStudiesData.informed_by, options: getParametricAsOptions("informed_by") };
			updatedStudiesData.status = { ...updatedStudiesData.status, options: getParametricAsOptions("status") };
			this.setState({ studentStudiesData: updatedStudiesData });
		}
		// Initialize department list when it has been loaded
		if (isEmpty(prevProps.allDepartments) && !isEmpty(this.props.allDepartments)) {
			updatedStudiesData.department = { ...updatedStudiesData.department, options: this.getDepartmentDropdown() };
			this.setState({ studentStudiesData: updatedStudiesData });
		}
		// Initialize lessons checkboxes when they have been loaded
		if (!prevProps.parametricsFinished && this.props.parametricsFinished) {
			this.setState({ studentLessonsData: getLessonsAsCheckboxesGroup(globalStore.lessons) });
		}
		// When the mode is EDIT I have to fetch all student data that is being passed from the parent Component
		if (this.props.mode === STUDENT_FORM_MODES.EDIT && this.props.studentsFinished && this.props.parametricsFinished && !this.state.studentReaded) {
			this.initializeFormData();
		}
		// When the mode switch from EDIT TO ADD i have to clear the form
		if (prevProps.mode === STUDENT_FORM_MODES.EDIT && this.props.mode === STUDENT_FORM_MODES.ADD) {
			this.emptyAllFormFields();
			this.setState({ studentReaded: false });
		}
	}

	onChangeHandler = (selectedOption, inputId, stateElement) => {
		const updatedForm = clone(this.state[stateElement]);
		const updatedFormElement = clone(updatedForm[inputId]);
		//the below code is for the React Datepicker, so it has to be js Date object
		const updatedFormDateKeys = Object.values(updatedForm)
			.filter((item) => item.datepicker)
			.map((item) => item.name);
		updatedFormDateKeys.forEach((element) => {
			updatedForm[element].selected = new Date(updatedForm[element].selected);
		});

		if (has(updatedFormElement, "options")) {
			updatedFormElement.value = selectedOption;
		} else {
			if (updatedFormElement.isNumber)
				updatedFormElement.value = isEmpty(selectedOption.target.value) ? selectedOption.target.value : Number(selectedOption.target.value);
			else if (updatedFormElement.datepicker) updatedFormElement.selected = selectedOption;
			else updatedFormElement.value = selectedOption.target.value;
		}

		updatedForm[inputId] = updatedFormElement;
		this.setState({ [stateElement]: updatedForm });
	};

	onChangeCheckboxHandler = (event, inputId) => {
		const updatedForm = clone(this.state.studentLessonsData);
		const updatedFormElement = clone(updatedForm[inputId]);

		if (this.state.studentLessonsData[inputId].value === Number(event.target.value)) {
			updatedFormElement.isChecked = !updatedFormElement.isChecked;
			updatedForm[inputId] = updatedFormElement;
			this.setState({ studentLessonsData: updatedForm });
		}
	};

	onFormSubmit = () => {
		this.setState({ loading: true });
		const output = {};
		const lessons = [];
		for (let outerKey in this.state) {
			for (let key in this.state[outerKey]) {
				if (outerKey === "studentLessonsData") {
					if (this.state[outerKey][key].isChecked) lessons.push(this.state[outerKey][key].value);
				} else {
					Object.assign(output, prepareDataBeforeSend(this.state[outerKey], DATE_DATA_TYPES.DATE));
				}
			}
		}
		output.studentLessons = lessons;

		switch (this.props.mode) {
			case STUDENT_FORM_MODES.ADD:
				this.props
					.addNewStudent(nullifyEmptyStrings(output))
					.then((studentResponse) => {
						const updatedStudent = {
							...studentResponse.data,
							lessons: output.studentLessons,
						};
						const password = generator.generate({
							length: 10,
							numbers: true,
							symbols: true,
							excludeSimilarCharacters: true,
							exclude: `,./+=[]{}()|<>:;'_~"\\`,
							strict: true,
						});
						const userToAdd = {
							email: output.email,
							username:
								greekUtils.toGreeklish(output.firstname.substring(0, 2)).toLowerCase() + "_" + greekUtils.toGreeklish(output.lastname).toLowerCase(),
							status: STATUSES.ENABLED,
							permission: permissions.STUDENT,
							student_id: studentResponse.data.id,
							password: password,
							repassword: password,
							firstname: output.firstname,
							lastname: output.lastname,
						};
						this.props
							.addNewUser(nullifyEmptyStrings(userToAdd))
							.then((UserResponse) => {
								this.setState({ loading: false });
								this.props.setApiResponse({
									error: null,
									info: {
										message: (
											<>
												Ο μαθητής{" "}
												<b>
													{UserResponse.data.lastname} {UserResponse.data.firstname}
												</b>{" "}
												προστέθηκε με επιτυχία. <br />
												Username: <b>{userToAdd.username}</b>
												<br /> Password: <b>{userToAdd.password}</b>
											</>
										),
									},
								});
								this.props.addNewUserSuccess(UserResponse.data);
							})
							.catch((error) => {
								if (error.status === StatusCodes.CONFLICT) {
									this.setState({ loading: false });
									this.props.setApiResponse({
										error: null,
										info: {
											message: (
												<>
													Ο μαθητής{" "}
													<b>
														{output.lastname} {output.firstname}
													</b>{" "}
													προστέθηκε με επιτυχία. <br />
													<b>ΠΡΟΣΟΧΗ:</b> Δε δημιουργήθηκε χρήστης, καθώς το όνομα χρήστη, ή το email υπήρχαν ήδη καταχωρημένα. Παρακαλώ προχωρήστε
													στη δημιουργία χρήστη χειροκίνητα.
												</>
											),
										},
									});
								} else {
									this.props.setApiResponse({
										error: error,
										info: null,
									});
									this.setState({ loading: false });
									this.props.addNewUserFail(error);
								}
							});
						this.emptyAllFormFields();
						this.props.addNewStudentSuccess(updatedStudent);
					})
					.catch((error) => {
						this.setState({ loading: false });
						this.props.setApiResponse({
							error: error,
							info: null,
						});
						this.props.addNewStudentFail(error);
					});
				break;
			case STUDENT_FORM_MODES.EDIT:
				this.props
					.updateStudent(nullifyEmptyStrings(output), Number(this.props.match.params.student_id))
					.then((response) => {
						const student = {
							...response.data,
							lessons: output.studentLessons,
						};
						this.setState({ loading: false });
						this.props.setApiResponse({
							error: null,
							info: {
								message: (
									<>
										Ο μαθητής{" "}
										<b>
											{response.data.lastname} {response.data.firstname}
										</b>{" "}
										ενημερώθηκε με επιτυχία.
									</>
								),
							},
						});
						this.props.updateStudentSuccess(student);
					})
					.catch((error) => {
						this.setState({ loading: false });
						this.props.setApiResponse({
							error: error,
							info: null,
						});
						this.props.updateStudentFail(error);
					});
				break;
			default:
				CUSTOM_ERRORS.throwError(
					"StudentManagement/ManageStudentForm/ManageStudentForm.js | line 338",
					CUSTOM_ERRORS.UNEXPECTED_IF_STATEMENT,
					this.props.mode,
				);
				break;
		}
	};

	onLessonCheckboxesReset = () => {
		this.setState({ studentLessonsData: getLessonsAsCheckboxesGroup(globalStore.lessons) });
		ReactTooltip.hide();
	};

	onFormReset = () => {
		this.initializeFormData();
	};

	initializeFormData = () => {
		const student = this.props?.allStudents?.find((student) => student.id === Number(this.props.match.params.student_id)) ?? null;
		this.setState({
			studentPersonalData: getPropsToStateData(this.state.studentPersonalData, student),
			studentParentsData: getPropsToStateData(this.state.studentParentsData, student),
			studentStudiesData: getPropsToStateData(this.state.studentStudiesData, student),
			studentLessonsData: getPropsToStudentLessonsData(
				this.state.studentLessonsData,
				student.lessons.map((lesson) => lesson.id),
			),
			studentReaded: true,
		});
	};

	getDepartmentDropdown = () => {
		const updatedDepartments = clone(this.props.allDepartments);
		return updatedDepartments.map((department) => {
			return {
				value: department.id,
				label: department.name,
			};
		});
	};

	getShortcutsButton = () => {
		const addSelectedLessonsToState = (lessons) => {
			const updatedStateLessons = clone(this.state.studentLessonsData);
			const keys = Object.keys(updatedStateLessons);
			keys.forEach((state_key) => {
				const lesson = lessons.find((less) => less.description === state_key);
				if (lesson) updatedStateLessons[state_key].isChecked = true;
				else updatedStateLessons[state_key].isChecked = false;
			});
			this.setState({ studentLessonsData: updatedStateLessons });
		};
		return (
			<>
				{this.props.allShortcuts.map((shortcut, idx) => {
					return (
						<Button
							type="button"
							text={shortcut.name}
							kind="purple"
							classes={["mr-1 my-1 btn-sm"]}
							onClick={() => addSelectedLessonsToState(shortcut.lessons)}
							key={idx}
						/>
					);
				})}
			</>
		);
	};

	isFormDirty = () => {
		const student = this.props?.allStudents?.find((student) => student.id === Number(this.props.match.params.student_id)) ?? null;
		return !(
			isEqual(this.state.studentPersonalData, getPropsToStateData(this.state.studentPersonalData, student)) &&
			isEqual(this.state.studentParentsData, getPropsToStateData(this.state.studentParentsData, student)) &&
			isEqual(this.state.studentStudiesData, getPropsToStateData(this.state.studentStudiesData, student)) &&
			isEqual(
				this.state.studentLessonsData,
				getPropsToStudentLessonsData(
					this.state.studentLessonsData,
					student.lessons.map((lesson) => lesson.id),
				),
			)
		);
	};

	isLessonCheckboxesDirty = () => {
		return !isEqual(this.state.studentLessonsData, getLessonsAsCheckboxesGroup(globalStore.lessons));
	};

	isLessonsFormInvalid = () => {
		let allFalse = true;
		for (let key in this.state.studentLessonsData) {
			if (this.state.studentLessonsData[key].isChecked) {
				allFalse = false;
				break;
			}
		}
		return allFalse;
	};

	isFormValid = () => {
		let valid = true;
		const tmp = {
			...this.state.studentParentsData,
			...this.state.studentPersonalData,
			...this.state.studentStudiesData,
		};
		const keys = Object.keys(tmp);
		for (let i = 0; i < keys.length; i++) {
			if (tmp[keys[i]].required) {
				if (has(tmp[keys[i]], "selected") && isEmpty(tmp[keys[i]].selected?.toString())) {
					valid = false;
					break;
				}
				if (has(tmp[keys[i]], "options") && tmp[keys[i]].value === null) {
					valid = false;
					break;
				} else if (has(tmp[keys[i]], "value") && isEmpty(tmp[keys[i]].value.toString())) {
					valid = false;
					break;
				}
			}
		}
		return valid && !this.isLessonsFormInvalid();
	};

	emptyAllFormFields = () => {
		Object.keys(this.state).forEach((state_key) => {
			if (state_key !== "studentReaded" && state_key !== "apiMessages") this.emptyField(state_key, clone(this.state[state_key]));
		});
	};

	emptyField = (keyToUpdate, field) => {
		for (let key in field) {
			const updatedFormElement = clone(field[key]);
			if (has(updatedFormElement, "isChecked")) {
				if (updatedFormElement.isChecked === true) updatedFormElement.isChecked = false;
			} else {
				if (updatedFormElement.name !== "submission_date" && updatedFormElement.name !== "status") updatedFormElement.value = "";
			}
			if (updatedFormElement.name === "submission_date") updatedFormElement.selected = new Date();
			field[key] = updatedFormElement;
		}
		this.setState({ [keyToUpdate]: field });
	};

	render() {
		const lessonsElementsArray = [];
		for (let key in this.state.studentLessonsData) {
			lessonsElementsArray.push({
				id: key,
				config: this.state.studentLessonsData[key],
			});
		}

		return (
			<form>
				<Card
					title="Φόρμα Εγγραφής Μαθητή"
					subTitle={<div className="text-sm">Η Αποθήκευση θα ενεργοποιηθεί όταν συμπληρωθούν τα υποχρεωτικά πεδία</div>}
					id="add_student_card"
					headerSpannig={[7, 5]}
					extraOnHeader={
						this.state.loading ? (
							<div className="d-flex align-items-center justify-content-lg-end justify-content-center mt-lg-0 mt-2">
								<h5 className="mr-2 mb-0">Φόρτωση, παρακαλώ περιμένετε</h5>
								<Spinner type="ball-pulse-sync" />
							</div>
						) : (
							<div className="text-center text-lg-right mt-2 mt-lg-0">
								{this.props.mode === STUDENT_FORM_MODES.EDIT && (
									<Button
										type="button"
										kind="primary"
										text="Επαναφορά"
										disabled={!this.isFormDirty()}
										onClick={this.onFormReset}
										classes={["mr-1"]}
									/>
								)}
								<Button
									type="button"
									kind="success"
									text="Αποθήκευση"
									disabled={
										(this.props.mode === STUDENT_FORM_MODES.EDIT ? !this.isFormValid() || !this.isFormDirty() : !this.isFormValid()) ||
										this.state.loading
									}
									onClick={this.onFormSubmit}
								/>
							</div>
						)
					}
				>
					<GeneralCard
						cardId="studentPersonalData"
						cardTitle="Στοιχεία Μαθητή"
						onChangeHandler={this.onChangeHandler}
						formElements={this.state.studentPersonalData}
					/>

					<GeneralCard
						cardId="studentParentsData"
						cardTitle="Στοιχεία Κηδεμόνων"
						onChangeHandler={this.onChangeHandler}
						formElements={this.state.studentParentsData}
					/>

					<GeneralCard
						cardId="studentStudiesData"
						cardTitle="Στοιχεία Φοίτησης"
						onChangeHandler={this.onChangeHandler}
						formElements={this.state.studentStudiesData}
					/>
					<Divider
						text="Μαθήματα που παρακολουθεί"
						textPostition="left"
					/>
					<label>
						Μαθήματα <span className="reqField">(*)</span>
					</label>
					<table className="table table-sm">
						<tbody>
							<tr>
								<td
									className="p-0"
									style={{ width: "76px", border: "none" }}
								>
									<Button
										classes={["mr-1", "btn-sm"]}
										type="button"
										iconClass={this.props.mode === STUDENT_FORM_MODES.ADD ? "fas fa-plus" : "fas fa-pencil-alt"}
										kind="primary"
										onClick={() => this.setState({ isModalOpen: true })}
										data-tip={this.props.mode === STUDENT_FORM_MODES.ADD ? "Προσθήκη Μαθημάτων" : "Επεξεργασία Μαθημάτων"}
									/>
									<Button
										classes={["btn-sm"]}
										type="button"
										iconClass="fas fa-eraser"
										kind="warning"
										data-tip="Εκκαθάριση Μαθημάτων"
										onClick={this.onLessonCheckboxesReset}
										disabled={this.isLessonsFormInvalid()}
									/>
								</td>
								<td
									className="bg-light pl-2"
									style={{ border: "1px solid #eee" }}
								>
									{lessonsElementsArray.map((element, idx) => {
										return element.config.isChecked ? <Tag key={idx}>{element.config.name}</Tag> : null;
									})}
								</td>
							</tr>
						</tbody>
					</table>
				</Card>
				<Modal
					footer={
						<>
							<Button
								text="Αποθήκευση & Κλείσιμο"
								type="button"
								kind="success"
								data-dismiss="modal"
								onClick={() => this.setState({ isModalOpen: false })}
							/>
							<Button
								type="button"
								kind="primary"
								onClick={this.onLessonCheckboxesReset}
								disabled={!this.isLessonCheckboxesDirty()}
								text="Εκκαθάριση"
							/>
						</>
					}
					header={
						this.props.mode === STUDENT_FORM_MODES.ADD ? (
							<>
								<em className="fas fa-plus float-left fa-1_2x mr-3" />
								Προσθήκη Μαθημάτων
							</>
						) : (
							<>
								<em className="fas fa-pencil-alt float-left fa-1_2x mr-3" />
								Επεξεργασία Μαθημάτων
							</>
						)
					}
					headerBg="primary"
					isOpen={this.state.isModalOpen}
					onClose={() => this.setState({ isModalOpen: false })}
					size={MODAL_SIZE.xl}
				>
					<Row classes={["px-2"]}>
						<Col classes={["mb-2", "px-2"]}>
							<h5 className="mb-0">
								Επιλογή Μαθημάτων <span className="text-warning text-bold">(*)</span>
							</h5>
							<small>Πρέπει να επιλεχθεί τουλάχιστον ένα μάθημα</small>
						</Col>
						<Col classes={["mb-3", "px-2", "text-center"]}>{this.getShortcutsButton()}</Col>
						{lessonsElementsArray.map((element, idx) => {
							return (
								<Col
									xl={element.config.colSpan}
									lg={element.config.colSpan}
									md={4}
									sm={6}
									classes={["mb-3", "px-2"]}
									key={idx}
								>
									<BaseInput
										key={element.config.name + element.config.id}
										name={element.config.name}
										placeholder={element.config.placeholder}
										type={element.config.inputType}
										value={element.config.value}
										label={element.config.label}
										checked={element.config.isChecked}
										onChange={(event) => {
											this.onChangeCheckboxHandler(event, element.id);
										}}
									/>
								</Col>
							);
						})}
					</Row>
				</Modal>
				<ReactTooltip effect="solid" />
			</form>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		allDepartments: allDepartments(state),
		allShortcuts: allShortcuts(state),
		allStudents: allStudents(state),
		allFetchingFinished: allFetchingFinished(state),
		departmentsFinished: state.departments.finished,
		shortcutsFinished: state.shortcuts.finished,
		studentsFinished: state.students.finished,
		parametricsFinished: state.parametrics.finished,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		addNewStudent: (studentData) => dispatch(addNewStudent(studentData)),
		addNewStudentSuccess: (studentData) => dispatch(addNewStudentSuccess(studentData)),
		addNewStudentFail: (error) => dispatch(addNewStudentFail(error)),
		updateStudent: (studentData, studentId) => dispatch(updateStudent(studentData, studentId)),
		updateStudentSuccess: (studentData) => dispatch(updateStudentSuccess(studentData)),
		updateStudentFail: (error) => dispatch(updateStudentFail(error)),
		addNewUser: (newUserData) => dispatch(addNewUser(newUserData)),
		addNewUserSuccess: (newUserData) => dispatch(addNewUserSuccess(newUserData)),
		addNewUserFail: (error) => dispatch(addNewUserFail(error)),
		setApiResponse: (theApiResponse) => dispatch(apiResponse(theApiResponse)),
	};
};

ManageStudentForm.propTypes = {
	allDepartments: PropTypes.arrayOf(PropTypes.exact(DepartmentShape)),
	allShortcuts: PropTypes.arrayOf(PropTypes.exact(ShortcutShape)),
	allFetchingFinished: PropTypes.bool,
	departmentsFinished: PropTypes.bool,
	shortcutsFinished: PropTypes.bool,
	studentsFinished: PropTypes.bool,
	parametricsFinished: PropTypes.bool,
	addNewStudent: PropTypes.func,
	addNewStudentSuccess: PropTypes.func,
	addNewStudentFail: PropTypes.func,
	mode: PropTypes.string,
	addNewUser: PropTypes.func,
	addNewUserSuccess: PropTypes.func,
	addNewUserFail: PropTypes.func,
	updateStudent: PropTypes.func,
	updateStudentSuccess: PropTypes.func,
	setApiResponse: PropTypes.func,
	updateStudentFail: PropTypes.func,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageStudentForm));
