import { isEmpty, uniqBy } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import { GradeShape } from "../../../Models/GradeShape";
import { getTeacherViewStudentPath } from "../../../navigation";
import { apiResponse, deleteGrade, deleteGradeFail, deleteGradeSuccess } from "../../../Redux/Actions/index";
import { allGrades } from "../../../Redux/Selectors/gradesSelectors";
import { CUSTOM_ERRORS } from "../../../Utils/CustomErrors";
import { getGradeCountersFooter } from "../../../Utils/GuiUtils";
import { absenceKindIds, examKindIds } from "../../../Utils/ParametricTablesBinds";
import Badge from "../../UI/Badge/Badge";
import Button from "../../UI/Button/Button";
import Card from "../../UI/Card/Card";
import Datatable from "../../UI/Datatable/Datatable";
import { Col, Row } from "../../UI/Grid/Grid";
import Modal from "../../UI/Modal/Modal";
import Tag from "../../UI/Tag/Tag";
import AddGradeForm from "./Forms/AddGradeForm";
import EditGradeForm from "./Forms/EditGradeForm";
import FilterGradesForm from "./Forms/FilterGradesForm";

const GradesManagement = ({ allGrades, deleteGrade, deleteGradeFail, deleteGradeSuccess, setApiResponse }) => {
	const [filterText, setFilterText] = useState("");
	const [filteredData, setFilteredData] = useState([]);
	const [isDataFiltered, setIsDataFiltered] = useState(false);
	const [loading, setLoading] = useState(false);
	const [selectedRow, setSelectedRow] = useState([]);
	const [isDeleteGradeModalOpen, setIsDeleteGradeModalOpen] = useState(false);
	const [isCommentsModalOpen, setIsCommentsModalOpen] = useState(false);
	const [isAddGradeModalOpen, setIsAddGradeModalOpen] = useState(false);
	const [isEditGradeModalOpen, setIsEditGradeModalOpen] = useState(false);
	const [isFilterGradesModalOpen, setIsFilterGradesModalOpen] = useState(false);
	const toggledClearRows = useRef(false);
	const history = useHistory();
	const columns = [
		{
			name: "Α/Α",
			selector: (row) => row.id,
			sortable: true,
			width: "75px",
		},
		{
			name: "Ημ. Εξέτασης",
			selector: (row) => row.exam_date,
			format: (row) => (row.exam_date ? moment(row.exam_date).format("ddd, DD/MM/YYYY") : ""),
			sortable: true,
			wrap: true,
		},
		{
			name: "Μαθητής",
			selector: (row) => row.student.lastname + " " + row.student.firstname,
			format: (row) => (
				<a
					href={getTeacherViewStudentPath(row.student.id)}
					onClick={(e) => {
						e.preventDefault();
						history.push(getTeacherViewStudentPath(row.student.id));
					}}
				>
					{row.student.lastname + " " + row.student.firstname}
				</a>
			),
			sortable: true,
			wrap: true,
		},
		{
			name: "Μάθημα",
			selector: (row) => row.lesson.description,
			sortable: true,
			wrap: true,
		},
		{
			name: "Τμήμα",
			selector: (row) => row.student.department.name,
			sortable: true,
			wrap: true,
		},
		{
			name: "Τάξη",
			selector: (row) => row.student.department.class.description,
			omit: true,
		},
		{
			name: "Είδος Εξέτασης",
			selector: (row) => row.exam_kind.description,
			format: (row) => {
				const badgeKind = (() => {
					if (row.exam_kind.id === examKindIds.EXAM) {
						return "purple";
					} else if (row.exam_kind.id === examKindIds.TEST) {
						return "primary";
					} else if (row.exam_kind.id === examKindIds.STUDY) {
						return "green";
					} else {
						CUSTOM_ERRORS.throwError("GradeManagement/GradeManagement.js | line 82", CUSTOM_ERRORS.UNEXPECTED_IF_STATEMENT, row.exam_kind);
					}
				})();
				return (
					<Badge
						kind={badgeKind}
						text={row.exam_kind.description}
					/>
				);
			},
			sortable: true,
		},
		{
			name: "Παρουσία",
			selector: (row) => row.absence.description,
			format: (row) => (
				<Badge
					kind={row.absence.id === absenceKindIds.NOT_PRESENT ? "danger" : "success"}
					text={row.absence.description}
				/>
			),
			sortable: true,
		},
		{
			name: "Βαθμός",
			selector: (row) => row.grade,
			sortable: true,
			maxWidth: "105px",
		},
		{
			name: "Σχόλια",
			selector: (row) => row.comments,
			format: (row) => (!isEmpty(row.comments) ? <i className="fas fa-check"></i> : ""),
			sortable: true,
			maxWidth: "100px",
		},
		{
			name: "Καταχωρήθηκε",
			selector: (row) => row.created_at,
			format: (row) => (row.created_at ? moment(row.created_at).format("ddd, DD/MM/YYYY HH:mm:ss") : ""),
			sortable: true,
			wrap: true,
		},
	];

	useEffect(() => {
		document.title = process.env.REACT_APP_NAME + " :: Διαχείριση Βαθμολογιών";
	}, []);

	const clearSelectedRows = useCallback(() => {
		setSelectedRow([]);
		toggledClearRows.current = !toggledClearRows.current;
	}, [toggledClearRows]);

	const resetAllFilters = useCallback(() => {
		setFilterText("");
		setFilteredData([]);
		setIsDataFiltered(false);
		clearSelectedRows();
	}, [setFilterText, setFilteredData, setIsDataFiltered, clearSelectedRows]);

	const setFilter = (filterString) => {
		const filteredItems = allGrades.filter((grade) => {
			return (
				`${grade.student.lastname} ${grade.student.firstname}`.toLowerCase().includes(filterString.toLowerCase()) ||
				`${grade.student.firstname} ${grade.student.lastname}`.toLowerCase().includes(filterString.toLowerCase())
			);
		});
		const filteredItems2 = allGrades.filter((grade) => grade.lesson.description.toLowerCase().includes(filterString.toLowerCase()));
		const filteredItems3 = allGrades.filter((grade) => grade.student.department.name.toLowerCase().includes(filterString.toLowerCase()));
		const filteredItems4 = allGrades.filter((grade) => grade.absence.description.toLowerCase().includes(filterString.toLowerCase()));
		const filteredItems5 = allGrades.filter((grade) => grade.exam_kind.description.toLowerCase().includes(filterString.toLowerCase()));
		setFilterText(filterString);
		setFilteredData(uniqBy(filteredItems.concat(filteredItems2, filteredItems3, filteredItems4, filteredItems5), "id"));
	};

	const selectedGridRow = (data) => {
		setSelectedRow(data.selectedRows);
	};

	const setGridFilteredData = (filteredData, isDataFiltered) => {
		if (isEmpty(filteredData)) {
			setFilteredData([]);
		} else {
			setFilteredData(filteredData);
		}
		setIsDataFiltered(isDataFiltered);
	};

	const onDeleteGradeHandler = () => {
		setLoading(true);
		deleteGrade(!isEmpty(selectedRow) ? selectedRow[0].id : null)
			.then((response) => {
				setApiResponse({
					error: null,
					info: {
						message: (
							<>
								Η βαθμολογία του μαθητή{" "}
								<b>
									{selectedRow[0].student.lastname} {selectedRow[0].student.firstname}
								</b>{" "}
								στις <b>{moment(selectedRow[0].exam_date).format("DD/MM/YYYY")}</b> διαγράφηκε με επιτυχία.
							</>
						),
					},
				});
				deleteGradeSuccess(response.data);
			})
			.catch((error) => {
				setApiResponse({
					error: error,
					info: null,
				});
				deleteGradeFail(error);
			})
			.finally(() => {
				setLoading(false);
				setIsDeleteGradeModalOpen(false);
				resetAllFilters();
			});
	};

	const tableData = (() => {
		if (isDataFiltered) {
			return filteredData;
		} else {
			return allGrades;
		}
	})();

	return (
		<>
			<Card
				title="Προβολή Καταχωρημένων Βαθμολογιών"
				headerSpannig={[7, 5]}
				id="teacherGradesCard"
				footer={isDataFiltered || !isEmpty(filterText) ? getGradeCountersFooter(filteredData) : getGradeCountersFooter(allGrades)}
				subTitle={<div className="text-sm">Αριθμός Καταχωρήσεων: {allGrades.length}</div>}
				extraOnHeader={
					<>
						<Button
							oval
							type="button"
							iconClass="fas fa-plus"
							kind="primary"
							onClick={() => setIsAddGradeModalOpen(true)}
							id="btn"
							classes={["m-1"]}
							data-tip="Προσθήκη Βαθμολογίας"
						/>
						<Button
							oval
							type="button"
							iconClass="fas fa-pencil-alt"
							kind="info"
							onClick={() => setIsEditGradeModalOpen(true)}
							id="btn"
							classes={["m-1"]}
							disabled={selectedRow.length === 0}
							data-tip="Επεξεργασία Βαθμολογίας"
						/>
						<Button
							oval
							type="button"
							iconClass="fas fa-trash"
							kind="danger"
							onClick={() => setIsDeleteGradeModalOpen(true)}
							id="btn"
							classes={["m-1"]}
							disabled={selectedRow.length === 0}
							data-tip="Διαγραφή Βαθμολογίας"
						/>
						<Button
							oval
							type="button"
							iconClass="far fa-comments"
							kind="muted"
							onClick={() => setIsCommentsModalOpen(true)}
							id="btn"
							classes={["m-1"]}
							disabled={selectedRow.length === 0 || isEmpty(selectedRow[0].comments)}
							data-multiline={true}
							data-tip="Προβολή Σχολίων<br/>Βαθμολογίας"
						/>
						<Button
							oval
							type="button"
							iconClass="fas fa-filter"
							kind="purple"
							onClick={() => setIsFilterGradesModalOpen(true)}
							id="btn"
							classes={["m-1"]}
							disabled={isEmpty(allGrades) || !isEmpty(filterText)}
							data-place="left"
							data-tip="Φίλτρα Αναζήτησης"
						/>
						<Button
							oval
							type="button"
							iconClass="fas fa-eraser"
							kind="warning"
							onClick={() => resetAllFilters()}
							id="btn"
							classes={["m-1", isDataFiltered ? "d-inline-block" : "d-none"]}
							data-place="left"
							data-tip="Απαλοιφή Φίλτρων"
						/>
					</>
				}
			>
				<Datatable
					columns={columns}
					defaultSortFieldId={2}
					defaultSortAsc={false}
					exportCsv
					exportFilename="Πίνακας Βαθμολογιών Μαθητολογίου Πολύτροπο"
					filteredData={filteredData}
					filterText={filterText}
					onClear={() => {
						setFilterText("");
						setFilteredData([]);
						clearSelectedRows();
					}}
					onFilter={(e) => {
						setFilter(e.target.value);
						clearSelectedRows();
					}}
					rowData={isEmpty(filterText) ? tableData : filteredData}
					searchDisabled={isDataFiltered}
					selectable
					selectedRows={selectedRow}
					setSelectedRow={selectedGridRow}
					toggledClearRows={toggledClearRows.current}
				/>
			</Card>
			<AddGradeForm
				closeModal={() => setIsAddGradeModalOpen(false)}
				isModalOpen={isAddGradeModalOpen}
				resetGradeFiltersAndClearSelection={resetAllFilters}
				setFilteredData={setGridFilteredData}
			/>
			<FilterGradesForm
				setFilteredData={setGridFilteredData}
				closeModal={() => setIsFilterGradesModalOpen(false)}
				isModalOpen={isFilterGradesModalOpen}
				isDataFiltered={isDataFiltered}
			/>
			{!isEmpty(selectedRow) && (
				<>
					<EditGradeForm
						closeModal={() => setIsEditGradeModalOpen(false)}
						grade={!isEmpty(selectedRow) ? selectedRow[0] : null}
						isModalOpen={isEditGradeModalOpen}
						resetGradeFiltersAndClearSelection={resetAllFilters}
						setFilteredData={setGridFilteredData}
					/>
					<Modal
						isOpen={isDeleteGradeModalOpen}
						header={
							<>
								<em className="fas fa-trash float-left fa-1_2x mr-3" />
								Επιβεβαίωση Διαγραφής
							</>
						}
						headerBg="danger"
						loading={loading}
						footer={
							<>
								<Button
									text="Επιβεβαίωση"
									type="button"
									kind="success"
									onClick={onDeleteGradeHandler}
								/>
								<Button
									text="Κλείσιμο"
									kind="secondary"
									type="button"
									onClick={() => setIsDeleteGradeModalOpen(false)}
								/>
							</>
						}
						onClose={() => setIsDeleteGradeModalOpen(false)}
					>
						<div>
							<strong>Είστε σίγουρος ότι επιθυμείτε να προβείτε στη διαγραφή της βαθμολογίας;</strong>
							<p className="mt-2">
								{" "}
								<i className="fas fa-exclamation-circle mr-1 text-primary fa-120x"></i>Προσοχή: Η ενέργεια δεν θα μπορεί να αναιρεθεί.
							</p>
						</div>
					</Modal>
					<Modal
						footer={
							<Button
								kind="secondary"
								text="Κλείσιμο"
								type="button"
								onClick={() => setIsCommentsModalOpen(false)}
							/>
						}
						header={
							<>
								<em className="far fa-comments float-left fa-1_2x mr-3" />
								Σχόλια Επιλεγμένης Βαθμολογίας
							</>
						}
						isOpen={isCommentsModalOpen}
						onClose={() => setIsCommentsModalOpen(false)}
					>
						<Row>
							<Col>
								<Tag>
									{selectedRow[0].student.lastname +
										" " +
										selectedRow[0].student.firstname +
										" | " +
										selectedRow[0].exam_kind.description +
										" (" +
										moment(selectedRow[0].exam_date).format("ddd, DD/MM/YYYY") +
										")"}
								</Tag>
							</Col>
							<Col classes={["mt-2"]}>
								{selectedRow[0].comments ?? <p className="text-danger mb-0 text-center">Δε βρέθηκαν σχόλια στην επιλεγμένη βαθμολογία</p>}
							</Col>
						</Row>
					</Modal>
				</>
			)}
			<ReactTooltip effect="solid" />
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		allGrades: allGrades(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		deleteGrade: (gradeId) => dispatch(deleteGrade(gradeId)),
		deleteGradeSuccess: (gradeData) => dispatch(deleteGradeSuccess(gradeData)),
		deleteGradeFail: (error) => dispatch(deleteGradeFail(error)),
		setApiResponse: (theApiResponse) => dispatch(apiResponse(theApiResponse)),
	};
};

GradesManagement.propTypes = {
	allGrades: PropTypes.arrayOf(PropTypes.exact(GradeShape)),
	deleteGrade: PropTypes.func,
	deleteGradeSuccess: PropTypes.func,
	deleteGradeFail: PropTypes.func,
	setApiResponse: PropTypes.func,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GradesManagement));
