import PropTypes from "prop-types";
import { LessonShape } from "./LessonShape";

export const ShortcutShape = {
	created_at: PropTypes.string,
	id: PropTypes.number,
	lessons: PropTypes.arrayOf(PropTypes.shape(LessonShape)),
	name: PropTypes.string,
	updated_at: PropTypes.string,
};
