import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { memo } from "react";

const Row = memo(({ align, justify, direction, classes, style, children }) => {
	return (
		<div
			className={`row${align ? ` align-items-${align}` : ""}${justify ? ` justify-content-${justify}` : ""}${direction ? ` flex-${direction}` : ""} ${
				!isEmpty(classes) ? classes.join(" ") : ""
			}`}
			style={style}
		>
			{children}
		</div>
	);
});

const Col = memo(({ style, classes, sm, md, lg, xl, children }) => {
	return (
		<div
			className={`col-sm-${sm ? sm : "12"} col-md-${md ? md : "12"} col-lg-${lg ? lg : "12"} col-xl-${xl ? xl : "12"} ${
				!isEmpty(classes) ? classes.join(" ") : ""
			}`}
			style={style}
		>
			{children}
		</div>
	);
});

Row.propTypes = {
	align: PropTypes.oneOf(["start", "end", "center", "baseline", "stretch"]),
	children: PropTypes.any.isRequired,
	classes: PropTypes.array,
	direction: PropTypes.oneOf(["column", "column-reverse", "row", "row-reverse"]),
	justify: PropTypes.oneOf(["start", "end", "center", "between", "around"]),
	style: PropTypes.object,
};

Col.propTypes = {
	children: PropTypes.any.isRequired,
	classes: PropTypes.array,
	style: PropTypes.object,
	sm: PropTypes.number,
	md: PropTypes.number,
	lg: PropTypes.number,
	xl: PropTypes.number,
};

export { Row, Col };
