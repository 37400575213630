import { getResource, postResource, putResource } from "../../BackendAPI/AbstractCRUDActions";
import { ActionKinds } from "../../BackendAPI/ActionKinds";
import { getParametricsSummaryUrl, getParametricsUrl } from "../../BackendAPI/BackendAPI";
import { parametricsGreekNameMapping } from "../../constants";
import { globalStore, listConstants } from "../../globalStore";
import {
	ADD_NEW_PARAMETRIC_FAIL,
	ADD_NEW_PARAMETRIC_START,
	ADD_NEW_PARAMETRIC_SUCCESS,
	FETCH_PARAMETRICS_FAIL,
	FETCH_PARAMETRICS_START,
	FETCH_PARAMETRICS_SUCCESS,
	UPDATE_PARAMETRIC_FAIL,
	UPDATE_PARAMETRIC_START,
	UPDATE_PARAMETRIC_SUCCESS,
} from "./actionTypes";

export const parametricsStart = () => {
	return {
		type: FETCH_PARAMETRICS_START,
	};
};

export const parametricsSuccess = () => {
	return {
		type: FETCH_PARAMETRICS_SUCCESS,
	};
};

export const parametricsFail = (error) => {
	return {
		type: FETCH_PARAMETRICS_FAIL,
		payload: error,
	};
};

export const fetchParametrics = () => {
	return (dispatch) => {
		dispatch(parametricsStart());
		const url = getParametricsSummaryUrl(ActionKinds.GET);
		getResource(url)
			.then((response) => {
				listConstants.status = response.data.status;
				listConstants.permissions = response.data.permissions;
				globalStore.classes = response.data.classes;
				globalStore.informed_by = response.data.informed_by;
				globalStore.exam_kind = response.data.exam_kind;
				globalStore.absence_kind = response.data.absence_kind;
				globalStore.scientific_fields = response.data.scientific_fields;
				globalStore.lessons = response.data.lessons;
				globalStore.design_lessons = response.data.design_lessons;
				globalStore.foreign_language_lessons = response.data.foreign_language_lessons;
				globalStore.educational_institutions = response.data.educational_institutions;
				globalStore.parametricsGreekNameMapping = parametricsGreekNameMapping;
				dispatch(parametricsSuccess(response.data));
			})
			.catch((error) => {
				dispatch(parametricsFail(error.response));
			});
	};
};

export const addNewParametricStart = () => {
	return {
		type: ADD_NEW_PARAMETRIC_START,
	};
};

export const addNewParametricSuccess = (newParametricData, dbTable) => {
	return {
		type: ADD_NEW_PARAMETRIC_SUCCESS,
		payload: { data: newParametricData, tbl: dbTable },
	};
};

export const addNewParametricFail = (error) => {
	return {
		type: ADD_NEW_PARAMETRIC_FAIL,
		payload: error,
	};
};

export const addNewParametric = (newParametricData, dbTable) => {
	return (dispatch) => {
		dispatch(addNewParametricStart());
		const url = getParametricsUrl(ActionKinds.POST, null, dbTable);
		return postResource(url, newParametricData);
	};
};

export const updateParametricStart = () => {
	return {
		type: UPDATE_PARAMETRIC_START,
	};
};

export const updateParametricSuccess = (parametricData, dbTable) => {
	return {
		type: UPDATE_PARAMETRIC_SUCCESS,
		payload: { data: parametricData, tbl: dbTable },
	};
};

export const updateParametricFail = (error) => {
	return {
		type: UPDATE_PARAMETRIC_FAIL,
		payload: error,
	};
};

export const updateParametric = (parametricData, dbTable, parametricId) => {
	return (dispatch) => {
		dispatch(updateParametricStart());
		const url = getParametricsUrl(ActionKinds.UPDATE, parametricId, dbTable);
		return putResource(url, parametricData);
	};
};
