import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import { StudentFromSelectorShape } from "../../../../Models/StudentShape";
import { getViewStudentPath } from "../../../../navigation";
import { getDateDiffInDays } from "../../../../Utils/Utils";
import { PathEntry } from "../../../../navigation";
import Card from "../../../UI/Card/Card";
import Button from "../../../UI/Button/Button";
import { Row, Col } from "../../../UI/Grid/Grid";

const LatestSubscriptionView = ({ students, title }) => {
	const history = useHistory();

	return (
		<Card
			bodyClasses={["p-0"]}
			extraOnHeader={
				<i
					className="fas fa-list-ul"
					style={{ fontSize: "200%" }}
				/>
			}
			headerSpannig={[9, 3]}
			title={title}
			footer={
				!isEmpty(students) ? (
					<Button
						classes={["p-0"]}
						disabled={isEmpty(students)}
						kind="link"
						onClick={() => history.push(PathEntry.studentsManagement.path)}
						text={
							<span>
								Προβολή Όλων<em className="fa fa-chevron-circle-right ml-3"></em>
							</span>
						}
						type="button"
					/>
				) : null
			}
		>
			{isEmpty(students) ? (
				<Row
					align="center"
					classes={["border-bottom", "text-bold", "text-danger", "text-center"]}
				>
					<Col
						lg={12}
						md={12}
						sm={12}
						xl={12}
					>
						Δεν βρέθηκαν δεδομένα
					</Col>
				</Row>
			) : (
				students.map((student, idx) => {
					return (
						<Row
							align="center"
							classes={[`${idx !== student.length - 1 && "border-bottom"}`, "py-2", "w-100", "mx-0"]}
							key={idx}
						>
							<Col
								lg={8}
								md={8}
								sm={8}
								xl={8}
							>
								<div>{student.lastname + " " + student.firstname}</div>
								<a
									className="small"
									href={getViewStudentPath(student.id)}
									onClick={(e) => {
										e.preventDefault();
										history.push(getViewStudentPath(student.id));
									}}
								>
									Προβολή Μαθητή
								</a>
							</Col>
							<Col
								classes={["text-right"]}
								lg={4}
								md={4}
								sm={4}
								xl={4}
							>
								<small>{getDateDiffInDays(student.submission_date) + " ημέρες πριν"} </small>
							</Col>
						</Row>
					);
				})
			)}
		</Card>
	);
};

LatestSubscriptionView.propTypes = {
	students: PropTypes.arrayOf(PropTypes.exact(StudentFromSelectorShape)),
	title: PropTypes.string.isRequired,
};

export default LatestSubscriptionView;
