import React, { Component } from "react";
import { isEmpty, has } from "lodash";
import PropTypes from "prop-types";

import { globalStore } from "../../../../../../../globalStore";
import { PARAMETRIC_TABLES } from "../../../../../../../Utils/ParametricTablesBinds";
import { clone } from "../../../../../../../Utils/Utils";
import { getParametricAsOptions } from "../../../../../../../Utils/GuiUtils";
import BaseInput from "../../../../../../UI/BaseInput/BaseInput";
import { Col } from "../../../../../../UI/Grid/Grid";

class ForeignLanguageLessonsForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			foreignLanguageLessonsForm: null,
		};
	}

	componentDidMount() {
		this.getLessonFields();
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.executeReset && this.props.executeReset) {
			this.getLessonFields();
			this.props.resetExecuteResetToggle();
		}
	}

	getLessonFields = () => {
		const output = {};
		const lessonValue = (() => {
			if (!isEmpty(this.props.selectedResult)) {
				const selectedLesson = globalStore.foreign_language_lessons.find((lesson) => lesson.id === this.props.selectedResult[0].lesson.id);
				return {
					value: selectedLesson.id,
					label: selectedLesson.description,
				};
			} else {
				return "";
			}
		})();
		output.lesson = {
			label: "Μάθημα",
			name: "foreign_lanuage_lesson",
			options: getParametricAsOptions(PARAMETRIC_TABLES.FOREIGN_LANGUAGE_LESSONS),
			value: lessonValue,
			placeholder: "Μάθημα",
			required: true,
			colSpan: 6,
		};
		output.grade = {
			label: "Βαθμός",
			name: "grade",
			placeholder: "Εισάγετε βαθμό",
			value: !isEmpty(this.props.selectedResult) ? this.props.selectedResult[0].grade : "",
			required: true,
			inputType: "number",
			isNumber: true,
			colSpan: 6,
		};
		this.setState({ foreignLanguageLessonsForm: output });
	};

	onChangeHandler = (selectedOption, inputId) => {
		const updatedForm = clone(this.state.foreignLanguageLessonsForm);
		const updatedFormElement = clone(updatedForm[inputId]);

		if (has(updatedFormElement, "options")) {
			updatedFormElement.value = selectedOption;
		} else {
			updatedFormElement.value = isEmpty(selectedOption.target.value) ? selectedOption.target.value : Number(selectedOption.target.value);
		}

		updatedForm[inputId] = updatedFormElement;
		this.setState({ foreignLanguageLessonsForm: updatedForm }, () => {
			this.calculateResult();
			this.props.setIsFormInvalid(this.isFormInvalid(), this.constructor.name);
		});
	};

	isFormInvalid = () => {
		let allFalse = false;
		for (let key in this.state.foreignLanguageLessonsForm) {
			if (this.state.foreignLanguageLessonsForm[key].required) {
				if (this.state.foreignLanguageLessonsForm[key].value === "" || this.state.foreignLanguageLessonsForm[key].value === null) {
					allFalse = true;
				}
			}
		}
		return allFalse;
	};

	areGradesValid = (grades) => {
		let output = true;
		grades.forEach((grade) => {
			if (grade > 20) {
				this.props.setGradesError("Βρέθηκε βαθμός > 20 σε ειδικό μάθημα. Παρακαλώ διορθώστε.");
				output = false;
			} else if (grade < 0) {
				this.props.setGradesError("Βρέθηκε βαθμός < 0 σε ειδικό μάθημα. Παρακαλώ διορθώστε.");
				output = false;
			}
		});
		if (output) {
			this.props.setGradesError(null);
		}
		return output;
	};

	calculateResult = () => {
		const grade = this.state.foreignLanguageLessonsForm.grade.value;
		if (this.areGradesValid([grade])) {
			const gradesArray = [
				{
					lesson: this.state.foreignLanguageLessonsForm.lesson.value,
					grade: grade,
				},
			];
			this.props.setSpecialLessons([grade], gradesArray);
		}
	};

	render() {
		const formElementsArray = [];
		for (let key in this.state.foreignLanguageLessonsForm) {
			formElementsArray.push({
				id: key,
				config: this.state.foreignLanguageLessonsForm[key],
			});
		}

		return (
			<>
				{formElementsArray.map((element, idx) => {
					return (
						<Col
							xl={element.config.colSpan}
							lg={element.config.colSpan}
							md={element.config.colSpan}
							sm={element.config.colSpan}
							classes={["mb-2", "px-2"]}
							key={idx}
						>
							<label>
								{element.config.label} {element.config.required ? <span className="reqField">(*)</span> : null}
							</label>
							<BaseInput
								key={element.config.name + element.config.id}
								name={element.config.name}
								placeholder={element.config.placeholder}
								options={element.config.options}
								type={element.config.inputType}
								value={element.config.value}
								disabled={element.config.disabled || this.props.modalReadonly}
								onChange={(selectedOption) => {
									this.onChangeHandler(selectedOption, element.id);
								}}
							/>
						</Col>
					);
				})}
			</>
		);
	}
}

ForeignLanguageLessonsForm.propTypes = {
	setSpecialLessons: PropTypes.func,
	setGradesError: PropTypes.func,
	setIsFormInvalid: PropTypes.func,
	selectedResult: PropTypes.array,
	modalReadonly: PropTypes.bool,
};

export default ForeignLanguageLessonsForm;
