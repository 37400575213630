import { useFormik } from "formik";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";

import { UserFromSelectorShape } from "../../../../../Models/UserShape";
import { addNewTeacherLessons, addNewTeacherLessonsFail, addNewTeacherLessonsSuccess, apiResponse } from "../../../../../Redux/Actions/index";
import { getParametricAsOptions } from "../../../../../Utils/GuiUtils";
import { PARAMETRIC_TABLES } from "../../../../../Utils/ParametricTablesBinds";
import { isFormikFormInvalid } from "../../../../../Utils/Utils";
import Button from "../../../../UI/Button/Button";
import FormColumnField from "../../../../UI/FormColumnField/FormColumnField";
import { Row } from "../../../../UI/Grid/Grid";
import { MODAL_SIZE } from "../../../../UI/Modal/constants/ModalSize";
import Modal from "../../../../UI/Modal/Modal";

const field_names = {
	TEACHER_ID: "teacher_id",
	TEACHER_LESSONS: "teacherLessons",
};

const initialFormValues = {
	teacher_id: "",
	teacherLessons: null,
};

const AddLessonAssignments = ({
	addNewTeacherLessons,
	addNewTeacherLessonsFail,
	addNewTeacherLessonsSuccess,
	closeModal,
	isModalOpen,
	selectedUser,
	setApiResponse,
}) => {
	const [loading, setLoading] = useState(false);

	const getInitialValues = () => {
		if (selectedUser == null || isEmpty(selectedUser.lessons))
			return {
				...initialFormValues,
				teacher_id: selectedUser?.id,
			};
		return {
			teacher_id: selectedUser.id,
			teacherLessons: selectedUser.lessons.map((lesson) => {
				return {
					value: lesson.id,
					label: lesson.description,
				};
			}),
		};
	};

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: getInitialValues(),
		onSubmit: (values) => {
			const data = {
				...values,
				teacherLessons: values[field_names.TEACHER_LESSONS].map((x) => x.value),
			};
			onFormSubmit(data);
		},
	});

	const onModalClose = () => {
		formik.resetForm();
		closeModal();
	};

	const onFormSubmit = (formData) => {
		setLoading(true);
		addNewTeacherLessons(formData)
			.then((response) => {
				const apiResponseMsg = {
					error: null,
					info: {
						message: (
							<>
								Η ανάθεση μαθημάτων στον καθηγητή{" "}
								<b>
									{selectedUser.lastname} {selectedUser.firstname}
								</b>{" "}
								ολοκληρώθηκε με επιτυχία.
							</>
						),
						code: response.status,
					},
				};
				setApiResponse(apiResponseMsg);
				const responseData = {
					teacher_id: selectedUser.id,
					data: response.data,
				};
				addNewTeacherLessonsSuccess(responseData);
			})
			.catch((error) => {
				const apiResponseMsg = {
					error: error,
					info: null,
				};
				setApiResponse(apiResponseMsg);
				addNewTeacherLessonsFail(error);
			})
			.finally(() => {
				setLoading(false);
				onModalClose();
			});
	};

	const isFormInvalid = () => {
		return isFormikFormInvalid([
			{
				value: formik.values[field_names.TEACHER_ID],
				required: true,
			},
			{
				value: formik.values[field_names.TEACHER_LESSONS],
				required: true,
			},
		]);
	};

	const footer = (() => {
		if (selectedUser == null || isEmpty(selectedUser.lessons)) {
			return (
				<>
					<Button
						type="button"
						kind="success"
						onClick={formik.handleSubmit}
						disabled={isFormInvalid()}
						text="Αποθήκευση"
					/>
					<Button
						type="button"
						kind="secondary"
						onClick={() => onModalClose()}
						text="Κλείσιμο"
					/>
				</>
			);
		} else {
			return (
				<>
					<Button
						type="button"
						kind="success"
						onClick={formik.handleSubmit}
						disabled={isFormInvalid() || !formik.dirty}
						text="Αποθήκευση"
					/>
					<Button
						type="button"
						kind="primary"
						onClick={formik.resetForm}
						disabled={!formik.dirty}
						text="Επαναφορά"
					/>
					<Button
						type="button"
						kind="secondary"
						onClick={() => onModalClose()}
						text="Κλείσιμο"
					/>
				</>
			);
		}
	})();

	const headerBg = () => {
		if (selectedUser == null || isEmpty(selectedUser.lessons)) return "primary";
		else return "info";
	};

	const headerText = () => {
		if (selectedUser == null || isEmpty(selectedUser.lessons)) return "Προσθήκη μαθημάτων σε καθηγητή";
		else return "Επεξεργασία αναθέσεων καθηγητή";
	};

	const headerIcon = () => {
		if (selectedUser == null || isEmpty(selectedUser.lessons)) return "fas fa-plus";
		else return "fas fa-pencil-alt";
	};

	return (
		<Modal
			isOpen={isModalOpen}
			size={MODAL_SIZE.xl}
			header={
				<>
					<em className={`fas ${headerIcon()} float-left fa-1_2x mr-3`} />
					{headerText()}
				</>
			}
			headerBg={headerBg()}
			loading={loading}
			footer={footer}
			onClose={onModalClose}
		>
			<form>
				<Row classes={["px-2"]}>
					<FormColumnField
						classes={["px-2", "mb-3"]}
						disabled
						label="Όνομα Καθηγητή"
						required
						name={field_names.TEACHER_ID}
						value={selectedUser?.lastname + " " + selectedUser?.firstname}
					/>
					<FormColumnField
						classes={["px-2", "mb-3"]}
						required
						label="Επιλογή Μαθημάτων"
						help_text="Πρέπει να επιλεχθεί τουλάχιστον ένα μάθημα"
						name={field_names.TEACHER_LESSONS}
						placeholder="Επιλογή Μαθημάτων"
						value={formik.values[field_names.TEACHER_LESSONS]}
						options={getParametricAsOptions(PARAMETRIC_TABLES.LESSONS)}
						searchable
						select_multiple
						onChange={(e) => {
							formik.handleChange(isEmpty(e) ? "" : e[0].value.toString());
							formik.setFieldValue(field_names.TEACHER_LESSONS, isEmpty(e) ? "" : e);
						}}
					/>
				</Row>
			</form>
		</Modal>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		addNewTeacherLessons: (newTeacherLessonsData) => dispatch(addNewTeacherLessons(newTeacherLessonsData)),
		addNewTeacherLessonsSuccess: (newTeacherLessonsData) => dispatch(addNewTeacherLessonsSuccess(newTeacherLessonsData)),
		addNewTeacherLessonsFail: (error) => dispatch(addNewTeacherLessonsFail(error)),
		setApiResponse: (theApiResponse) => dispatch(apiResponse(theApiResponse)),
	};
};

AddLessonAssignments.propTypes = {
	addNewTeacherLessons: PropTypes.func,
	addNewTeacherLessonsFail: PropTypes.func,
	addNewTeacherLessonsSuccess: PropTypes.func,
	isModalOpen: PropTypes.bool,
	selectedUser: PropTypes.exact(UserFromSelectorShape),
	setApiResponse: PropTypes.func,
	closeModal: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(AddLessonAssignments);
