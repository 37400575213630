import Chart from "chart.js/auto";
import { isEmpty, isEqual } from "lodash";
import PropTypes from "prop-types";
import React, { memo, useEffect } from "react";
import { usePrevious } from "../../../CustomHooks/usePrevious";

const CustomChart = memo(({ data, id, legend, type, indexAxis, scales }) => {
	const previousDataset = usePrevious(data?.datasets[0]?.data);

	useEffect(() => {
		const ctx = document.getElementById(id).getContext("2d");
		const updatedData = defaultDataProps(data);
		new Chart(ctx, {
			type: type,
			data: updatedData,
			options: {
				plugins: legend
					? legend
					: {
							legend: {
								display: false,
							},
					  },
				maintainAspectRatio: false,
				scales: scales ?? null,
				indexAxis: indexAxis ?? "x",
			},
		});
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (!isEqual(previousDataset, data?.datasets[0]?.data)) {
			const parent = document.getElementById(id).parentNode;
			document.getElementById(id).remove();
			const x = document.createElement("CANVAS");
			x.id = id;
			parent.appendChild(x);
			const ctx = x.getContext("2d");
			const updatedData = defaultDataProps(data);
			new Chart(ctx, {
				type: type,
				data: updatedData,
				options: {
					plugins: legend
						? legend
						: {
								legend: {
									display: false,
								},
						  },
					maintainAspectRatio: false,
					scales: scales ?? null,
					indexAxis: indexAxis ?? "x",
				},
			});
		}
		// eslint-disable-next-line
	}, [previousDataset, data?.datasets[0]?.data]);

	return <canvas id={id} />;
});

function defaultDataProps(data) {
	if (isEmpty(data.datasets[0].backgroundColor))
		data.datasets[0].backgroundColor = [
			"rgba(128, 0, 0, 0.7)",
			"rgba(170, 110, 40, 0.7)",
			"rgba(128, 128, 0, 0.7)",
			"rgba(0, 128, 128, 0.7)",
			"rgba(230, 25, 75, 0.7)",
			"rgba(245, 130, 48, 0.7)",
			"rgba(255, 225, 25, 0.7)",
			"rgba(210, 245, 60, 0.7)",
			"rgba(60, 180, 75, 0.7)",
			"rgba(0, 130, 200, 0.7)",
			"rgba(145, 30, 180, 0.7)",
			"rgba(240, 50, 230, 0.7)",
			"rgba(128, 128, 128, 0.7)",
			"rgba(255, 215, 180, 0.7)",
			"rgba(170, 255, 195, 0.7)",
			"rgba(230, 190, 255, 0.7)",
		];
	if (isEmpty(data.datasets[0].borderColor))
		data.datasets[0].borderColor = [
			"rgba(128, 0, 0, 1)",
			"rgba(170, 110, 40, 1)",
			"rgba(128, 128, 0, 1)",
			"rgba(0, 128, 128, 1)",
			"rgba(230, 25, 75, 1)",
			"rgba(245, 130, 48, 1)",
			"rgba(255, 225, 25, 1)",
			"rgba(210, 245, 60, 1)",
			"rgba(60, 180, 75, 1)",
			"rgba(0, 130, 200, 1)",
			"rgba(145, 30, 180, 1)",
			"rgba(240, 50, 230, 1)",
			"rgba(128, 128, 128, 1)",
			"rgba(255, 215, 180, 1)",
			"rgba(170, 255, 195, 1)",
			"rgba(230, 190, 255, 1)",
		];
	return data;
}

CustomChart.propTypes = {
	//https://stackoverflow.com/questions/48007326/what-is-the-correct-proptype-for-a-ref-in-react
	chartRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
	datasets: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			data: PropTypes.array,
		}),
	),
	scales: PropTypes.object,
	id: PropTypes.string.isRequired,
	labels: PropTypes.arrayOf(PropTypes.string),
	indexAxis: PropTypes.string,
	legend: PropTypes.object,
	type: PropTypes.oneOf(["pie", "bar", "line"]).isRequired,
};

export default CustomChart;
