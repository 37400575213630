const PATH_ROOT = {
	ADMIN: "/admin/app",
	TEACHER: "/teacher/app",
	STUDENT: "/student/app",
};

export const PathEntry = {
	login: {
		path: "/login",
	},
	logout: {
		path: "/logout",
	},
	userManagement: {
		path: PATH_ROOT.ADMIN + "/user-management",
		pageHeader: "Διαχείριση Χρηστών",
	},
	shortcutsManagement: {
		path: PATH_ROOT.ADMIN + "/app-configuration/shortcuts-management",
		pageHeader: "Διαχείριση Συντομεύσεων",
	},
	parametricsManagement: {
		path: PATH_ROOT.ADMIN + "/app-configuration/parametrics-management",
		pageHeader: "Διαχείριση Παραμετρικών",
	},
	lessonAssignments: {
		path: PATH_ROOT.ADMIN + "/app-configuration/lesson-assignments",
		pageHeader: "Ανάθεση Μαθημάτων",
	},
	departmentAssignments: {
		path: PATH_ROOT.ADMIN + "/app-configuration/department-assignments",
		pageHeader: "Ανάθεση Τμημάτων",
	},
	scientificFieldLessonsAssignments: {
		path: PATH_ROOT.ADMIN + "/app-configuration/scientific-field-lessons-assignments",
		pageHeader: "Ανάθεση Μαθημάτων σε Επιστημονικά Πεδία",
	},
	dashboard: {
		path: PATH_ROOT.ADMIN + "/dashboard",
		pageHeader: "Dashboard",
	},
	departmentsManagement: {
		path: PATH_ROOT.ADMIN + "/departments-management",
		pageHeader: "Διαχείριση Τμημάτων",
	},
	studentsManagement: {
		path: PATH_ROOT.ADMIN + "/students-management/view",
		pageHeader: "Διαχείριση Μαθητών",
	},
	addStudent: {
		path: PATH_ROOT.ADMIN + "/students-management/add",
		pageHeader: "Προσθήκη Μαθητή",
	},
	gradesManagement: {
		path: PATH_ROOT.ADMIN + "/grades-management",
		pageHeader: "Διαχείριση Βαθμολογιών",
	},
	studentView: {
		path: PATH_ROOT.ADMIN + "/students-management/:student_id/view",
		pathToMatch: PATH_ROOT.ADMIN + "/students-management/view",
		pageHeader: "Προβολή Μαθητή",
	},
	editStudent: {
		path: PATH_ROOT.ADMIN + "/students-management/:student_id/edit",
		pathToMatch: PATH_ROOT.ADMIN + "/students-management/edit",
		pageHeader: "Επεξεργασία Μαθητή",
	},
	resubscribeStudent: {
		path: PATH_ROOT.ADMIN + "/resubscribe-student",
		pageHeader: "Επανεγγραφή Μαθητή",
	},
	gelPanhellenicExaminations: {
		path: PATH_ROOT.ADMIN + "/gel-panhellenic-examinations",
		pageHeader: "Βαθμολογίες Πανελλαδικών Εξετάσεων Γ.Ε.Λ",
	},
	epalPanhellenicExaminations: {
		path: PATH_ROOT.ADMIN + "/epal-panhellenic-examinations",
		pageHeader: "Βαθμολογίες Πανελλαδικών Εξετάσεων ΕΠΑ.Λ",
	},
	educationalInstitutionsPanhellenicExaminations: {
		path: PATH_ROOT.ADMIN + "/edu-intitutions-panhellenic-examinations",
		pageHeader: "Εισαγωγή σε Εκπαιδευτικό Ίδρυμα",
	},
	selfTestManagement: {
		path: PATH_ROOT.ADMIN + "/self-test-management",
		pageHeader: "Test Αυτοαξιολόγησης",
	},
	selfTestChaptersManagement: {
		path: PATH_ROOT.ADMIN + "/self-test-chapters-management",
		pageHeader: "Διαχείριση Ενοτήτων Test Αυτοαξιολόγησης",
	},
	selfTestView: {
		path: PATH_ROOT.ADMIN + "/self-test-management/:self_test_id/view",
		pathToMatch: PATH_ROOT.ADMIN + "/self-test-management/view",
		pageHeader: "Προβολή Test Αυτοαξιολόγησης",
	},
	selfTestResultsView: {
		path: PATH_ROOT.ADMIN + "/self-test-results/view",
		pageHeader: "Προβολή Αποτελεσμάτων Test Αυτοαξιολόγησης",
	},
	logsView: {
		path: PATH_ROOT.ADMIN + "/logs-view",
		pageHeader: "Αρχεία Καταγραφών",
	},
	teacherDashboard: {
		path: PATH_ROOT.TEACHER + "/dashboard",
		pageHeader: "Dashboard",
	},
	teacherStudentView: {
		path: PATH_ROOT.TEACHER + "/student/:student_id/view",
		pathToMatch: PATH_ROOT.TEACHER + "/student/view",
		pageHeader: "Προβολή Μαθητή",
	},
	teacherGradesManagement: {
		path: PATH_ROOT.TEACHER + "/grades-management",
		pageHeader: "Διαχείριση Βαθμολογιών",
	},
	studentDashboard: {
		path: PATH_ROOT.STUDENT + "/dashboard",
		pageHeader: "Dashboard",
	},
	studentGradesView: {
		path: PATH_ROOT.STUDENT + "/grades-view",
		pageHeader: "Προβολή Βαθμολογιών",
	},
	studentSelfTestList: {
		path: PATH_ROOT.STUDENT + "/self-test-list",
		pageHeader: "Λίστα Test Αυτοαξιολόγησης",
	},
	studentSelfTestResults: {
		path: PATH_ROOT.STUDENT + "/self-test-results",
		pageHeader: "Αποτελέσματα Test Αυτοαξιολόγησης",
	},
	studentSelfTestView: {
		path: PATH_ROOT.STUDENT + "/self-test/:self_test_id/view",
		pathToMatch: PATH_ROOT.STUDENT + "/self-test/view",
		pageHeader: "Εξέταση Test Αυτοαξιολόγησης",
	},
};

export const APP_GENERAL_PATHS = [PathEntry.dashboard.path, PathEntry.userManagement.path, PathEntry.logsView.path];

export const APP_DEPARTMENT_MNG_PATHS = [PathEntry.departmentsManagement.path];

export const APP_PANHELLENIC_EXAMINATIONS_PATHS = [
	PathEntry.gelPanhellenicExaminations.path,
	PathEntry.epalPanhellenicExaminations.path,
	PathEntry.educationalInstitutionsPanhellenicExaminations.path,
];

export const APP_SELF_TEST_PATHS = [
	PathEntry.selfTestManagement.path,
	PathEntry.selfTestChaptersManagement.path,
	PathEntry.selfTestView.path,
	PathEntry.selfTestResultsView.path,
];

export const APP_STUDENT_MNG_PATHS = [
	PathEntry.studentsManagement.path,
	PathEntry.addStudent.path,
	PathEntry.editStudent.path,
	PathEntry.studentView.path,
	PathEntry.resubscribeStudent.path,
];

export const APP_GRADE_MNG_PATHS = [PathEntry.gradesManagement.path];

export const APP_CONFIG_PATHS = [
	PathEntry.shortcutsManagement.path,
	PathEntry.parametricsManagement.path,
	PathEntry.lessonAssignments.path,
	PathEntry.departmentAssignments.path,
	PathEntry.scientificFieldLessonsAssignments.path,
];

export const STUDENT_APP_SELF_TEST_PATHS = [
	PathEntry.studentSelfTestList.path,
	PathEntry.studentSelfTestView.path,
	PathEntry.studentSelfTestResults.path,
];

export const APP_ADMIN_PATHS = APP_GENERAL_PATHS.concat(
	APP_CONFIG_PATHS,
	APP_DEPARTMENT_MNG_PATHS,
	APP_STUDENT_MNG_PATHS,
	APP_GRADE_MNG_PATHS,
	APP_PANHELLENIC_EXAMINATIONS_PATHS,
	APP_SELF_TEST_PATHS,
);

export const APP_TEACHER_PATHS = [PathEntry.teacherDashboard.path, PathEntry.teacherStudentView.path, PathEntry.teacherGradesManagement.path];

export const APP_STUDENT_PATHS = [PathEntry.studentDashboard.path, PathEntry.studentGradesView.path].concat(STUDENT_APP_SELF_TEST_PATHS);

export const getEditStudentPath = (student_id) => {
	return `${PATH_ROOT.ADMIN}/students-management/${student_id}/edit`;
};

export const getViewStudentPath = (student_id) => {
	return `${PATH_ROOT.ADMIN}/students-management/${student_id}/view`;
};

export const getViewSelfTestPath = (self_test_id) => {
	return `${PATH_ROOT.ADMIN}/self-test-management/${self_test_id}/view`;
};

export const getTeacherViewStudentPath = (student_id) => {
	return `${PATH_ROOT.TEACHER}/student/${student_id}/view`;
};

export const getStudentSelfTestPath = (self_test_id) => {
	return `${PATH_ROOT.STUDENT}/self-test/${self_test_id}/view`;
};
