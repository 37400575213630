import { deleteResource, getResource, postResource, putResource } from "../../BackendAPI/AbstractCRUDActions";
import { ActionKinds } from "../../BackendAPI/ActionKinds";
import { getPanhelleniceExaminationsResultsUrl } from "../../BackendAPI/BackendAPI";
import {
	ADD_NEW_PANHELLENIC_EXAMINATION_RESULT_FAIL,
	ADD_NEW_PANHELLENIC_EXAMINATION_RESULT_START,
	ADD_NEW_PANHELLENIC_EXAMINATION_RESULT_SUCCESS,
	DELETE_PANHELLENIC_EXAMINATION_RESULT_FAIL,
	DELETE_PANHELLENIC_EXAMINATION_RESULT_START,
	DELETE_PANHELLENIC_EXAMINATION_RESULT_SUCCESS,
	FETCH_ALL_PANHELLENIC_EXAMINATIONS_RESULTS_FAIL,
	FETCH_ALL_PANHELLENIC_EXAMINATIONS_RESULTS_START,
	FETCH_ALL_PANHELLENIC_EXAMINATIONS_RESULTS_SUCCESS,
	UPDATE_PANHELLENIC_EXAMINATION_RESULT_FAIL,
	UPDATE_PANHELLENIC_EXAMINATION_RESULT_START,
	UPDATE_PANHELLENIC_EXAMINATION_RESULT_SUCCESS,
} from "./actionTypes";

export const fetchAllPanhellenicExaminationsResultsStart = () => {
	return {
		type: FETCH_ALL_PANHELLENIC_EXAMINATIONS_RESULTS_START,
	};
};

export const fetchAllPanhellenicExaminationsResultsSuccess = (panhellenicExaminationsResultsData) => {
	return {
		type: FETCH_ALL_PANHELLENIC_EXAMINATIONS_RESULTS_SUCCESS,
		payload: panhellenicExaminationsResultsData,
	};
};

export const fetchAllPanhellenicExaminationsResultsFail = (error) => {
	return {
		type: FETCH_ALL_PANHELLENIC_EXAMINATIONS_RESULTS_FAIL,
		payload: error,
	};
};

export const fetchAllPanhellenicExaminationsResults = () => {
	return (dispatch) => {
		dispatch(fetchAllPanhellenicExaminationsResultsStart());
		const url = getPanhelleniceExaminationsResultsUrl(ActionKinds.GET);
		getResource(url)
			.then((response) => {
				dispatch(
					fetchAllPanhellenicExaminationsResultsSuccess({
						results: response.data.results,
						resultGrades: response.data.result_grades,
						resultFLGrades: response.data.result_fl_grades,
						resultDesignGradesData: response.data.result_design_grades,
					}),
				);
			})
			.catch((error) => {
				dispatch(fetchAllPanhellenicExaminationsResultsFail(error));
			});
	};
};

export const addNewPanhellenicExaminationResultStart = () => {
	return {
		type: ADD_NEW_PANHELLENIC_EXAMINATION_RESULT_START,
	};
};
export const addNewPanhellenicExaminationResultSuccess = (newResultData) => {
	return {
		type: ADD_NEW_PANHELLENIC_EXAMINATION_RESULT_SUCCESS,
		payload: newResultData,
	};
};

export const addNewPanhellenicExaminationResultFail = (error) => {
	return {
		type: ADD_NEW_PANHELLENIC_EXAMINATION_RESULT_FAIL,
		payload: error,
	};
};

export const addNewPanhellenicExaminationResult = (newResultData) => {
	return (dispatch) => {
		dispatch(addNewPanhellenicExaminationResultStart());
		const url = getPanhelleniceExaminationsResultsUrl(ActionKinds.POST);
		return postResource(url, newResultData);
	};
};

export const updatePanhellenicExaminationResultStart = () => {
	return {
		type: UPDATE_PANHELLENIC_EXAMINATION_RESULT_START,
	};
};

export const updatePanhellenicExaminationResultSuccess = (panhellenicExaminationsData) => {
	return {
		type: UPDATE_PANHELLENIC_EXAMINATION_RESULT_SUCCESS,
		payload: panhellenicExaminationsData,
	};
};

export const updatePanhellenicExaminationResultFail = (error) => {
	return {
		type: UPDATE_PANHELLENIC_EXAMINATION_RESULT_FAIL,
		payload: error,
	};
};

export const updatePanhellenicExaminationResult = (panhellenicExaminationsData, panhellenicExaminationsId) => {
	return (dispatch) => {
		dispatch(updatePanhellenicExaminationResultStart());
		const url = getPanhelleniceExaminationsResultsUrl(ActionKinds.UPDATE, panhellenicExaminationsId);
		return putResource(url, panhellenicExaminationsData);
	};
};

export const deletePanhellenicExaminationResultStart = () => {
	return {
		type: DELETE_PANHELLENIC_EXAMINATION_RESULT_START,
	};
};

export const deletePanhellenicExaminationResultSuccess = (panhellenicExaminationsData) => {
	return {
		type: DELETE_PANHELLENIC_EXAMINATION_RESULT_SUCCESS,
		payload: panhellenicExaminationsData,
	};
};

export const deletePanhellenicExaminationResultFail = (error) => {
	return {
		type: DELETE_PANHELLENIC_EXAMINATION_RESULT_FAIL,
		payload: error,
	};
};

export const deletePanhellenicExaminationResult = (panhellenicExaminationsId) => {
	return (dispatch) => {
		dispatch(deletePanhellenicExaminationResultStart());
		const url = getPanhelleniceExaminationsResultsUrl(ActionKinds.DELETE, panhellenicExaminationsId);
		return deleteResource(url);
	};
};
