export const allFetchingFinished = (state) => {
	return (
		state.students.finished &&
		state.grades.finished &&
		state.departments.finished &&
		state.parametrics.finished &&
		state.shortcuts.finished &&
		state.scientificFieldLessons.finished &&
		state.panhellenicExaminations.finished &&
		state.users.finished &&
		state.selfTestChapters.finished &&
		state.selfTests.finished &&
		state.selfTestAnswers.finished
	);
};

export const allFetchingFinishedForTeacher = (state) => {
	return state.students.finished && state.grades.finished && state.departments.finished && state.parametrics.finished && state.users.finished; /*&&
            state.selfTests.finished*/
};

export const allFetchingFinishedForStudent = (state) => {
	return (
		state.students.finished &&
		state.grades.finished &&
		state.departments.finished &&
		state.parametrics.finished &&
		state.users.finished &&
		state.selfTestChapters.finished &&
		state.selfTests.finished &&
		state.selfTestAnswers.finished
	);
};
