import PropTypes from "prop-types";
import { ParametricShape } from "./ParametricShape";

export const SelfTestChapterSelectorShape = {
	created_at: PropTypes.string.isRequired,
	lesson: PropTypes.shape(ParametricShape).isRequired,
	id: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	lesson_id: PropTypes.number.isRequired,
	self_test_count: PropTypes.number,
	updated_at: PropTypes.string,
};

export const SelfTestChapterReduxShape = {
	created_at: PropTypes.string.isRequired,
	id: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	lesson_id: PropTypes.number.isRequired,
	self_test_count: PropTypes.number,
	updated_at: PropTypes.string,
};
