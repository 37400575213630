import { updateObject } from "../../Utils/Utils";
import {
	ADD_NEW_SELF_TEST_ANSWER_FAIL,
	ADD_NEW_SELF_TEST_ANSWER_START,
	ADD_NEW_SELF_TEST_ANSWER_SUCCESS,
	AUTH_LOGOUT,
	FETCH_ALL_SELF_TEST_ANSWERS_FAIL,
	FETCH_ALL_SELF_TEST_ANSWERS_START,
	FETCH_ALL_SELF_TEST_ANSWERS_SUCCESS,
	FETCH_CURRENT_STUDENT_SELF_TEST_ANSWERS_FAIL,
	FETCH_CURRENT_STUDENT_SELF_TEST_ANSWERS_START,
	FETCH_CURRENT_STUDENT_SELF_TEST_ANSWERS_SUCCESS,
} from "../Actions/actionTypes";

const initialState = {
	allSelfTestAnswers: [],
	error: null,
	finished: false,
	loading: false,
	success: false,
};

export const fetchSelfTestAnswersStart = (state) => {
	return updateObject(state, {
		error: null,
		finished: false,
		loading: true,
		success: false,
	});
};

const fetchSelfTestAnswersSuccess = (state, action) => {
	return updateObject(state, {
		allSelfTestAnswers: action.payload,
		error: null,
		finished: true,
		loading: false,
		success: false,
	});
};

const fetchSelfTestAnswersFail = (state, action) => {
	return updateObject(state, {
		allSelfTestAnswers: [],
		error: action.payload,
		finished: false,
		loading: false,
		success: false,
	});
};

const addNewSelfTestAnswerStart = (state) => {
	return updateObject(state, {
		error: null,
		loading: true,
		success: false,
	});
};

const addNewSelfTestAnswerSuccess = (state, action) => {
	const updatedSTA = [...state.allSelfTestAnswers];
	const newSTA = { ...action.payload };
	updatedSTA.push(newSTA);
	return updateObject(state, {
		allSelfTestAnswers: updatedSTA,
		error: null,
		loading: false,
		success: true,
	});
};

const addNewSelfTestAnswerFail = (state, action) => {
	return updateObject(state, {
		error: action.payload,
		loading: false,
		success: false,
	});
};

const authLogout = (state) => {
	return updateObject(state, initialState);
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_ALL_SELF_TEST_ANSWERS_START:
			return fetchSelfTestAnswersStart(state);
		case FETCH_ALL_SELF_TEST_ANSWERS_FAIL:
			return fetchSelfTestAnswersFail(state, action);
		case FETCH_ALL_SELF_TEST_ANSWERS_SUCCESS:
			return fetchSelfTestAnswersSuccess(state, action);
		case FETCH_CURRENT_STUDENT_SELF_TEST_ANSWERS_START:
			return fetchSelfTestAnswersStart(state);
		case FETCH_CURRENT_STUDENT_SELF_TEST_ANSWERS_FAIL:
			return fetchSelfTestAnswersFail(state, action);
		case FETCH_CURRENT_STUDENT_SELF_TEST_ANSWERS_SUCCESS:
			return fetchSelfTestAnswersSuccess(state, action);
		case ADD_NEW_SELF_TEST_ANSWER_START:
			return addNewSelfTestAnswerStart(state);
		case ADD_NEW_SELF_TEST_ANSWER_FAIL:
			return addNewSelfTestAnswerFail(state, action);
		case ADD_NEW_SELF_TEST_ANSWER_SUCCESS:
			return addNewSelfTestAnswerSuccess(state, action);
		case AUTH_LOGOUT:
			return authLogout(state);
		default:
			return state;
	}
};

export default reducer;
