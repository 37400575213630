import { sortArrayOfObjectsByString, updateObject } from "../../Utils/Utils";
import {
	ADD_NEW_DEPARTMENT_FAIL,
	ADD_NEW_DEPARTMENT_START,
	ADD_NEW_DEPARTMENT_SUCCESS,
	AUTH_LOGOUT,
	DELETE_DEPARTMENT_FAIL,
	DELETE_DEPARTMENT_START,
	DELETE_DEPARTMENT_SUCCESS,
	FETCH_ALL_DEPARTMENTS_FAIL,
	FETCH_ALL_DEPARTMENTS_START,
	FETCH_ALL_DEPARTMENTS_SUCCESS,
	FETCH_CURRENT_STUDENT_USER_DEPARTMENTS_FAIL,
	FETCH_CURRENT_STUDENT_USER_DEPARTMENTS_START,
	FETCH_CURRENT_STUDENT_USER_DEPARTMENTS_SUCCESS,
	FETCH_CURRENT_TEACHER_DEPARTMENTS_FAIL,
	FETCH_CURRENT_TEACHER_DEPARTMENTS_START,
	FETCH_CURRENT_TEACHER_DEPARTMENTS_SUCCESS,
	UPDATE_DEPARTMENT_FAIL,
	UPDATE_DEPARTMENT_START,
	UPDATE_DEPARTMENT_SUCCESS,
} from "../Actions/actionTypes";

const initialState = {
	allDepartments: [],
	error: null,
	finished: false,
	loading: false,
	success: false,
};

export const fetchDepartmentsStart = (state) => {
	return updateObject(state, {
		error: null,
		finished: false,
		loading: true,
		success: false,
	});
};

const fetchDepartmentsSuccess = (state, action) => {
	return updateObject(state, {
		allDepartments: action.payload,
		error: null,
		finished: true,
		loading: false,
		success: false,
	});
};

const fetchDepartmentsFail = (state, action) => {
	return updateObject(state, {
		allDepartments: [],
		error: action.payload,
		finished: false,
		loading: false,
		success: false,
	});
};

const addNewDepartmentStart = (state) => {
	return updateObject(state, {
		error: null,
		loading: true,
		success: false,
	});
};

const addNewDepartmentSuccess = (state, action) => {
	const updatedDepartments = [...state.allDepartments];
	const newDepartment = { ...action.payload };
	updatedDepartments.push(newDepartment);
	sortArrayOfObjectsByString(updatedDepartments, "name");
	return updateObject(state, {
		allDepartments: updatedDepartments,
		error: null,
		loading: false,
		success: true,
	});
};

const addNewDepartmentFail = (state, action) => {
	return updateObject(state, {
		error: action.payload,
		loading: false,
		success: false,
	});
};

const updateDepartmentStart = (state) => {
	return updateObject(state, {
		error: null,
		loading: true,
		success: false,
	});
};

const updateDepartmentSuccess = (state, action) => {
	const updatedDepartments = state.allDepartments.filter((department) => department.id !== action.payload.id);
	const updatedDepartment = { ...action.payload };
	updatedDepartments.push(updatedDepartment);
	sortArrayOfObjectsByString(updatedDepartments, "name");
	return updateObject(state, {
		allDepartments: updatedDepartments,
		error: null,
		loading: false,
		success: true,
	});
};

const updateDepartmentFail = (state, action) => {
	return updateObject(state, {
		error: action.payload,
		loading: false,
		success: false,
	});
};

const deleteDepartmentStart = (state) => {
	return updateObject(state, {
		error: null,
		loading: true,
		success: false,
	});
};

const deleteDepartmentSuccess = (state, action) => {
	const updatedDepartments = [...state.allDepartments].filter((obj) => obj.id !== action.payload.id);
	return updateObject(state, {
		allDepartments: updatedDepartments,
		error: null,
		loading: false,
		success: true,
	});
};

const deleteDepartmentFail = (state, action) => {
	return updateObject(state, {
		error: action.payload,
		loading: false,
		success: false,
	});
};

const authLogout = (state) => {
	return updateObject(state, initialState);
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_ALL_DEPARTMENTS_START:
			return fetchDepartmentsStart(state);
		case FETCH_ALL_DEPARTMENTS_FAIL:
			return fetchDepartmentsFail(state, action);
		case FETCH_ALL_DEPARTMENTS_SUCCESS:
			return fetchDepartmentsSuccess(state, action);
		case FETCH_CURRENT_TEACHER_DEPARTMENTS_START:
			return fetchDepartmentsStart(state);
		case FETCH_CURRENT_TEACHER_DEPARTMENTS_FAIL:
			return fetchDepartmentsFail(state, action);
		case FETCH_CURRENT_TEACHER_DEPARTMENTS_SUCCESS:
			return fetchDepartmentsSuccess(state, action);
		case FETCH_CURRENT_STUDENT_USER_DEPARTMENTS_START:
			return fetchDepartmentsStart(state);
		case FETCH_CURRENT_STUDENT_USER_DEPARTMENTS_FAIL:
			return fetchDepartmentsFail(state, action);
		case FETCH_CURRENT_STUDENT_USER_DEPARTMENTS_SUCCESS:
			return fetchDepartmentsSuccess(state, action);
		case ADD_NEW_DEPARTMENT_START:
			return addNewDepartmentStart(state);
		case ADD_NEW_DEPARTMENT_FAIL:
			return addNewDepartmentFail(state, action);
		case ADD_NEW_DEPARTMENT_SUCCESS:
			return addNewDepartmentSuccess(state, action);
		case UPDATE_DEPARTMENT_START:
			return updateDepartmentStart(state);
		case UPDATE_DEPARTMENT_FAIL:
			return updateDepartmentFail(state, action);
		case UPDATE_DEPARTMENT_SUCCESS:
			return updateDepartmentSuccess(state, action);
		case DELETE_DEPARTMENT_START:
			return deleteDepartmentStart(state);
		case DELETE_DEPARTMENT_FAIL:
			return deleteDepartmentFail(state, action);
		case DELETE_DEPARTMENT_SUCCESS:
			return deleteDepartmentSuccess(state, action);
		case AUTH_LOGOUT:
			return authLogout(state);
		default:
			return state;
	}
};

export default reducer;
