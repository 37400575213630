import PropTypes from "prop-types";
import { memo, useEffect, useState } from "react";
import { usePrevious } from "../../../CustomHooks/usePrevious";

const Alert = memo(({ dismissible, message, onClose, statusCode, timestamp, type }) => {
	const [isActive, setIsActive] = useState(false);
	const previousTimestamp = usePrevious(timestamp);
	const previousMessage = usePrevious(message);
	const previousStatusCode = usePrevious(statusCode);

	useEffect(() => {
		if (previousTimestamp !== timestamp || previousMessage !== message || previousStatusCode !== statusCode) {
			setIsActive(true);
		}
	}, [previousTimestamp, timestamp, previousMessage, message, previousStatusCode, statusCode]);

	const pickIcon = () => {
		switch (type) {
			case "danger":
				return "fas fa-exclamation-circle";
			case "success":
				return "fas fa-check-circle";
			case "warning":
				return "fas fa-exclamation-triangle";
			case "info":
				return "fas fa-info-circle";
			default:
				break;
		}
	};

	if (!isActive) {
		return null;
	}

	return (
		<div
			className={`alert alert-${type} ${dismissible ? "alert-dismissible" : null}`}
			role="alert"
		>
			{dismissible ? (
				<button
					style={{ fontSize: "1.2rem" }}
					type="button"
					className="close"
					data-dismiss="alert"
					aria-label="Close"
					onClick={() => {
						setIsActive(false);
						if (onClose) onClose();
					}}
				>
					<i className="fas fa-times"></i>
				</button>
			) : null}
			{statusCode && (
				<>
					<span style={{ fontSize: "1.5rem" }}>
						<i className={`${pickIcon()} mr-1`}></i> {statusCode}
					</span>
					<br />
				</>
			)}
			{message}
		</div>
	);
});

Alert.propTypes = {
	dismissible: PropTypes.bool,
	statusCode: PropTypes.number,
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
	onClose: PropTypes.func,
	timestamp: PropTypes.number,
	type: PropTypes.oneOf(["success", "info", "warning", "danger"]).isRequired,
};

export default Alert;
