import PropTypes from "prop-types";
import { ParametricShape } from "./ParametricShape";

export const ScientificFieldLessonsFromSelectorShape = {
	id: PropTypes.number.isRequired,
	calculation_weight: PropTypes.number.isRequired,
	lesson_id: PropTypes.number.isRequired,
	scientific_field_id: PropTypes.number.isRequired,
	lesson: PropTypes.shape(ParametricShape).isRequired,
	scientific_field: PropTypes.shape(ParametricShape).isRequired,
};
