import { isEmpty } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { DepartmentShape } from "../../../Models/DepartmentShape";
import { GradeShape } from "../../../Models/GradeShape";
import { StudentFromSelectorShape } from "../../../Models/StudentShape";
import { UserFromSelectorShape } from "../../../Models/UserShape";
import { PathEntry } from "../../../navigation";
import { allDepartments } from "../../../Redux/Selectors/departmentsSelectors";
import { allGrades } from "../../../Redux/Selectors/gradesSelectors";
import {
	gradeGeneralStatsPerLesson,
	gradesCountPerUser,
	studentsPerClass,
	studentsPerCounty,
	studentsPerInformedBy,
} from "../../../Redux/Selectors/statsSelectors";
import { allStudents } from "../../../Redux/Selectors/studentsSelectors";
import { allFetchingFinished } from "../../../Redux/Selectors/universalSelectors";
import { allUsers } from "../../../Redux/Selectors/userSelectors";
import { sortArrayOfObjectsByDate } from "../../../Utils/Utils";
import Button from "../../UI/Button/Button";
import Card from "../../UI/Card/Card";
import CustomChart from "../../UI/Chart/CustomChart";
import CounterCard from "../../UI/CounterCard/CounterCard";
import Datatable from "../../UI/Datatable/Datatable";
import { Col, Row } from "../../UI/Grid/Grid";
import LatestGradesView from "./LatestGradesView/LatestGradesView";
import LatestActionsView from "./LatestSubscriptionView/LatestSubscriptionView";

const gradeGeneralStatsPerLessonColumns = [
	{
		name: "Μάθημα",
		selector: (row) => row.lesson,
		sortable: true,
		wrap: true,
	},
	{
		name: "Απουσίες",
		selector: (row) => row.absence,
		sortable: true,
	},
	{
		name: "Πλ. Βαθμολογιών",
		selector: (row) => row.cnt,
		sortable: true,
	},
	{
		name: "Μ.Ο. /100",
		selector: (row) => Number(row.avg100.replace("%", "")),
		format: (row) => row.avg100,
		sortable: true,
	},
	{
		name: "Μ.Ο. /20",
		selector: (row) => Number(row.avg20),
		format: (row) => row.avg20,
		sortable: true,
	},
	{
		name: "Υψηλότερη",
		selector: (row) => Number(row.maxGrade.replace("%", "")),
		format: (row) => row.maxGrade,
		sortable: true,
	},
	{
		name: "Χαμηλότερη",
		selector: (row) => Number(row.minGrade.replace("%", "")),
		format: (row) => row.minGrade,
		sortable: true,
	},
	{
		name: "Τελευταία",
		selector: (row) => row.latest,
		format: (row) => (row.created_at !== "-" ? moment(row.created_at).format("ddd, DD/MM/YYYY") : "-"),
		sortable: true,
		wrap: true,
	},
];

class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterText: "",
			filteredData: [],
		};
		this.toggledClearRows = false;
	}

	componentDidMount() {
		document.title = process.env.REACT_APP_NAME;
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.allFetchingFinished && this.props.allFetchingFinished) {
			this.resetAllFilters();
		}
	}

	resetAllFilters = () => {
		this.setState({
			filterText: "",
			filteredData: [],
		});
	};

	setFilter = (filterString) => {
		const filteredItems = this.props.gradeGeneralStatsPerLesson.filter((row) => row.lesson.toLowerCase().includes(filterString.toLowerCase()));
		this.setState({
			filterText: filterString,
			filteredData: filteredItems,
		});
	};

	render() {
		return (
			<>
				<Row>
					<Col
						lg={6}
						xl={3}
					>
						<CounterCard
							bgColor="bg-green"
							iconClass="fas fa-user-graduate"
							subtitle="ΜΑΘΗΤΕΣ"
							title={this.props.allStudents.length}
						/>
					</Col>
					<Col
						lg={6}
						xl={3}
					>
						<CounterCard
							bgColor="bg-primary"
							iconClass="fas fa-chalkboard"
							subtitle="ΤΜΗΜΑΤΑ"
							title={this.props.allDepartments.length}
						/>
					</Col>
					<Col
						lg={6}
						xl={3}
					>
						<CounterCard
							bgColor="bg-purple"
							iconClass="far fa-star"
							subtitle="ΒΑΘΜΟΛΟΓΙΕΣ"
							title={this.props.allGrades.length}
						/>
					</Col>
					<Col
						lg={6}
						xl={3}
					>
						<CounterCard
							bgColor="bg-info"
							iconClass="fas fa-user"
							subtitle="ΕΝΕΡΓΟΙ ΧΡΗΣΤΕΣ"
							title={this.props.allUsers.filter((x) => x.status.id === 2).length}
						/>
					</Col>
				</Row>

				<Row>
					<Col xl={9}>
						<Row>
							<Col
								lg={6}
								xl={6}
							>
								<Card
									extraOnHeader={
										<i
											className="fas fa-chart-bar fa-2x"
											style={{ fontSize: "200%" }}
										></i>
									}
									footer={
										<div className="text-center">
											<Button
												oval
												type="button"
												kind="primary"
												text="Προβολή Όλων"
												id="showGradesBtn"
												disabled={isEmpty(this.props.gradesCountPerUser)}
												onClick={() => this.props.history.push(PathEntry.gradesManagement.path)}
											/>
										</div>
									}
									headerSpannig={[9, 3]}
									title="Πλήθος Βαθμολογιών / Καθηγητή"
								>
									{!isEmpty(this.props.gradesCountPerUser) ? (
										<div
											className="mb-3"
											style={{ height: "300px", width: "100%" }}
										>
											<CustomChart
												data={{
													labels: this.props.gradesCountPerUser.map((stat) => stat.user),
													datasets: [
														{
															data: this.props.gradesCountPerUser.map((stat) => stat.cnt),
														},
													],
												}}
												id="gradesCountPerTeacherChart"
												indexAxis="y"
												type="bar"
											/>
										</div>
									) : (
										<p className="text-danger text-bold text-center my-2">Δεν έχουν καταχωρηθεί βαθμολογίες</p>
									)}
								</Card>
							</Col>
							<Col
								xl={6}
								lg={6}
							>
								<Card
									extraOnHeader={
										<i
											className="fas fa-chart-pie"
											style={{ fontSize: "200%" }}
										></i>
									}
									footer={
										<div className="text-center">
											<Button
												oval
												type="button"
												kind="primary"
												text="Προβολή Όλων"
												id="showStudentsBtn"
												disabled={isEmpty(this.props.studentsPerClass)}
												onClick={() => this.props.history.push(PathEntry.studentsManagement.path)}
											/>
										</div>
									}
									headerSpannig={[9, 3]}
									title="Μαθητές / Τάξη"
								>
									{!isEmpty(this.props.studentsPerClass) ? (
										<div
											className="mb-3"
											style={{ height: "300px", width: "100%" }}
										>
											<CustomChart
												data={{
													labels: this.props.studentsPerClass.map((stat) => stat.class.description),
													datasets: [
														{
															data: this.props.studentsPerClass.map((stat) => stat.students.length),
														},
													],
												}}
												id="studentsPerClassChart"
												legend={{
													legend: {
														display: true,
														position: "right",
														labels: {
															fontSize: 10,
															boxWidth: 28,
														},
													},
												}}
												type="pie"
											/>
										</div>
									) : (
										<p className="text-danger text-bold text-center my-2">Δεν έχουν καταχωρηθεί μαθητές</p>
									)}
								</Card>
							</Col>
						</Row>
						<Row>
							<Col
								xl={6}
								lg={6}
							>
								<Card
									extraOnHeader={
										<i
											className="fas fa-map-marked-alt"
											style={{ fontSize: "250%" }}
										></i>
									}
									headerSpannig={[8, 4]}
									title="Μαθητές / Περιοχή"
								>
									{!isEmpty(this.props.studentCounties) ? (
										<div
											className="mb-3"
											style={{ height: "350px", width: "100%" }}
										>
											<CustomChart
												data={{
													labels: this.props.studentCounties.map((stat) => stat.county),
													datasets: [
														{
															data: this.props.studentCounties.map((stat) => stat.students.length),
														},
													],
												}}
												id="studentsPerCountyChart"
												legend={{
													legend: {
														display: true,
														position: "right",
														labels: {
															fontSize: 10,
															boxWidth: 28,
														},
													},
												}}
												type="pie"
											/>
										</div>
									) : (
										<p className="text-danger text-bold text-center my-2">Δεν έχουν καταχωρηθεί μαθητές</p>
									)}
								</Card>
							</Col>
							<Col
								lg={6}
								xl={6}
							>
								<Card
									extraOnHeader={
										<i
											className="fas fa-chalkboard-teacher"
											style={{ fontSize: "250%" }}
										></i>
									}
									headerSpannig={[8, 4]}
									title="Πλήθος Μαθητών / Τρόπο Ενημέρωσης"
								>
									{!isEmpty(this.props.studentCounties) ? (
										<div
											className="mb-3"
											style={{ height: "350px", width: "100%" }}
										>
											<CustomChart
												data={{
													labels: this.props.studentsPerInformedBy.map((stat) => stat.item.description),
													datasets: [
														{
															data: this.props.studentsPerInformedBy.map((stat) => stat.students.length),
														},
													],
												}}
												id="studentsPerInformedByChart"
												legend={{
													legend: {
														display: true,
														position: "right",
														labels: {
															fontSize: 10,
															boxWidth: 28,
														},
													},
												}}
												type="pie"
											/>
										</div>
									) : (
										<p className="text-danger text-bold text-center my-2">Δεν έχουν καταχωρηθεί μαθητές</p>
									)}
								</Card>
							</Col>
						</Row>
						<Row>
							<Col
								xl={12}
								lg={12}
								md={12}
								sm={12}
							>
								<Card
									footer={
										<div className="text-center">
											<Button
												oval
												type="button"
												kind="primary"
												text="Προβολή Όλων"
												id="showGradesBtn"
												disabled={isEmpty(this.props.gradeGeneralStatsPerLesson)}
												onClick={() => this.props.history.push(PathEntry.gradesManagement.path)}
											/>
										</div>
									}
									subTitle={<small>Τα στατιστικά βαθμολογιών αφορούν μόνο σε παρόντες</small>}
									title="Στατιστικά Βαθμολογιών / Μάθημα"
								>
									{!isEmpty(this.props.gradeGeneralStatsPerLesson) && (
										<div
											className="mb-3"
											style={{ height: "350px", width: "100%" }}
										>
											<CustomChart
												data={{
													labels: this.props.gradeGeneralStatsPerLesson.filter((stat) => stat.avg100 !== "-").map((stat) => stat.lesson),
													datasets: [
														{
															data: this.props.gradeGeneralStatsPerLesson
																.filter((stat) => stat.avg100 !== "-")
																.map((stat) => stat.avg100.replace("%", "")),
														},
													],
												}}
												id="gradesAvgPerLessonChart"
												scales={{
													y: {
														suggestedMin: 0,
														suggestedMax: 100,
														ticks: {
															stepSize: 20,
														},
													},
												}}
												type="bar"
											/>
										</div>
									)}

									<Datatable
										columns={gradeGeneralStatsPerLessonColumns}
										defaultSortFieldId={1}
										exportCsv
										exportFilename="Πίνακας Στατιστικών Βαθμολογιών ανά Μάθημα Μαθητολογίου Πολύτροπο"
										filterText={this.state.filterText}
										filteredData={this.state.filteredData}
										onClear={() => {
											this.setState({ filterText: "", filteredData: [] });
											this.toggledClearRows = !this.toggledClearRows;
										}}
										onFilter={(e) => {
											this.setFilter(e.target.value);
											this.toggledClearRows = !this.toggledClearRows;
										}}
										rowData={isEmpty(this.state.filterText) ? this.props.gradeGeneralStatsPerLesson : this.state.filteredData}
										toggledClearRows={this.toggledClearRows}
									/>
								</Card>
							</Col>
						</Row>
					</Col>
					<Col xl={3}>
						<LatestActionsView
							students={sortArrayOfObjectsByDate(this.props.allStudents, "submission_date", "desc").filter((_, idx) => idx <= 6)}
							title="Τελευταίες Εγγραφές"
						/>
						<LatestGradesView
							grades={sortArrayOfObjectsByDate(this.props.allGrades, "created_at", "desc").filter((_, idx) => idx <= 6)}
							title="Τελευταίες Βαθμολογίες"
						/>
					</Col>
				</Row>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		allDepartments: allDepartments(state),
		allFetchingFinished: allFetchingFinished(state),
		allGrades: allGrades(state),
		allStudents: allStudents(state),
		allUsers: allUsers(state),
		gradeGeneralStatsPerLesson: gradeGeneralStatsPerLesson(state),
		gradesCountPerUser: gradesCountPerUser(state),
		studentCounties: studentsPerCounty(state),
		studentsPerClass: studentsPerClass(state),
		studentsPerInformedBy: studentsPerInformedBy(state),
	};
};

Dashboard.propTypes = {
	allDepartments: PropTypes.arrayOf(PropTypes.exact(DepartmentShape)),
	allFetchingFinished: PropTypes.bool,
	allGrades: PropTypes.arrayOf(PropTypes.exact(GradeShape)),
	allStudents: PropTypes.arrayOf(PropTypes.exact(StudentFromSelectorShape)),
	allUsers: PropTypes.arrayOf(PropTypes.exact(UserFromSelectorShape)),
	gradeGeneralStatsPerLesson: PropTypes.arrayOf(PropTypes.object),
	gradesCountPerUser: PropTypes.arrayOf(PropTypes.object),
	studentsPerClass: PropTypes.arrayOf(PropTypes.object),
};

export default withRouter(connect(mapStateToProps)(Dashboard));
