import { has, isEmpty, isEqual } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import { ParametricShape } from "../../../../../Models/ParametricShape";
import { ScientificFieldLessonsFromSelectorShape } from "../../../../../Models/ScientificFieldLessonsShape";
import { StudentFromSelectorShape } from "../../../../../Models/StudentShape";
import {
	addNewPanhellenicExaminationResult,
	addNewPanhellenicExaminationResultFail,
	addNewPanhellenicExaminationResultSuccess,
	apiResponse,
} from "../../../../../Redux/Actions/index";
import { CUSTOM_ERRORS } from "../../../../../Utils/CustomErrors";
import { clone, roundNumber } from "../../../../../Utils/Utils";
import Alert from "../../../../UI/Alert/Alert";
import { Col, Row } from "../../../../UI/Grid/Grid";
import { MODAL_SIZE } from "../../../../UI/Modal/constants/ModalSize";
import Modal from "../../../../UI/Modal/Modal";
import Tag from "../../../../UI/Tag/Tag";
import SpecialLessonFormsWrapper from "./LessonForms/SpecialLessonFormsWrapper";
import StandardLessonsForm from "./LessonForms/StandardLessonsForm";

const LessonFormComponentName = {
	STANDARD_LESSONS: "StandardLessonsForm",
	SPECIAL_LESSONS: "SpecialLessonFormsWrapper",
};

class AddPanhellenicExaminationForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			finalResultWithSpecialLessons: null,
			finalResultWithFactor2: null,
			gradesError: null,
			standardLessons: {
				result: null,
				student_id: null,
				grades: [],
				average: null,
			},
			specialLessons: {
				result: [],
				grades: [],
			},
			isStandardLessonFormInvalid: true,
			isSpecialLessonFormInvalid: false,
			standardLessonFormError: null,
			specialLessonFormError: null,
			loading: false,
		};
		this.showStandardLessonErrorAlert = false;
		this.showSpecialLessonErrorAlert = false;
	}

	componentDidUpdate(prevProps, prevState) {
		if (!isEqual(prevProps.scientificFieldSelected, this.props.scientificFieldSelected)) {
			this.setState({ gradesError: null });
		}
	}

	areGradesValid = (grades) => {
		let output = true;
		grades.forEach((grade) => {
			if (grade > 20) {
				this.setState({
					finalResult: null,
					gradesError: "Βρέθηκε βαθμός > 20. Παρακαλώ διορθώστε.",
				});
				output = false;
			} else if (grade < 0) {
				this.setState({
					finalResult: null,
					gradesError: "Βρέθηκε βαθμός < 0. Παρακαλώ διορθώστε.",
				});
				output = false;
			}
		});
		if (output) {
			this.setState({ gradesError: null });
		}
		return output;
	};

	getGrade = (gradesFromState) => {
		const grades = [];
		if (has(gradesFromState, "student_id")) delete gradesFromState.student_id;
		Object.keys(gradesFromState).forEach((key) => {
			if (gradesFromState[key].value !== "")
				grades.push({
					lesson_id: gradesFromState[key].lesson_id,
					grade: gradesFromState[key].value,
				});
		});
		return grades;
	};

	resetSpecialLessonsGrade = () => {
		this.setState({
			finalResultWithSpecialLessons: null,
			finalResultWithFactor2: null,
		});
	};

	setStandardLessons = (result, student_id, grades) => {
		const updatedStandardLessons = clone(this.state.standardLessons);
		updatedStandardLessons.result = result;
		updatedStandardLessons.grades = grades;
		updatedStandardLessons.student_id = student_id;
		const sum = grades.map((grade) => grade.grade).reduce((a, b) => a + b, 0);
		updatedStandardLessons.average = roundNumber(sum / 4, 2);
		this.setState({ standardLessons: updatedStandardLessons }, () => this.calculateSpecialLessonsGrade());
	};

	setSpecialLessons = (result, grades) => {
		const updatedFLLessons = clone(this.state.specialLessons);
		updatedFLLessons.result = result;
		updatedFLLessons.grades = grades;
		this.calculateSpecialLessonsGrade(result);
		this.setState({ specialLessons: updatedFLLessons });
	};

	calculateSpecialLessonsGrade = (result = null) => {
		// Be super carefull: result here is trie-state (null, empty, or with data)
		const data = (() => {
			if (result === null) return clone(this.state.specialLessons.result);
			else if (isEmpty(result)) return [];
			else return result;
		})();
		switch (data.length) {
			case 1:
				if (data[0] >= 10) {
					this.setState({
						finalResultWithSpecialLessons: this.state.standardLessons.result + data[0] * 100,
						finalResultWithFactor2: this.state.standardLessons.result + data[0] * 200,
					});
				} else {
					this.resetSpecialLessonsGrade();
				}
				break;
			case 2:
				if (data[0] >= 10) {
					const result = ((data[0] + data[1]) / 2) * 200;
					this.setState({
						finalResultWithSpecialLessons: this.state.standardLessons.result + result,
						finalResultWithFactor2: null,
					});
				} else {
					this.resetSpecialLessonsGrade();
				}
				break;
			default:
				this.resetSpecialLessonsGrade();
				break;
		}
	};

	setIsFormInvalid = (isInvalid, formName) => {
		if (formName === LessonFormComponentName.STANDARD_LESSONS) this.setState({ isStandardLessonFormInvalid: isInvalid });
		else if (formName === LessonFormComponentName.SPECIAL_LESSONS) this.setState({ isSpecialLessonFormInvalid: isInvalid });
		else
			CUSTOM_ERRORS.throwError(
				`PanhellenicExaminations/Forms/${this.constructor.name}.js | line 159`,
				CUSTOM_ERRORS.UNEXPECTED_IF_STATEMENT,
				formName,
			);
	};

	setGradesError = (error, formName) => {
		if (formName === LessonFormComponentName.STANDARD_LESSONS) {
			this.setState({ standardLessonFormError: error });
			if (error !== null) {
				this.showStandardLessonErrorAlert = true;
			} else {
				this.showStandardLessonErrorAlert = false;
			}
		} else if (formName === LessonFormComponentName.SPECIAL_LESSONS) {
			this.setState({ specialLessonFormError: error });
			if (error !== null) {
				this.showSpecialLessonErrorAlert = true;
			} else {
				this.showSpecialLessonErrorAlert = false;
			}
		} else
			CUSTOM_ERRORS.throwError(
				`PanhellenicExaminations/Forms/${this.constructor.name}.js | line 168`,
				CUSTOM_ERRORS.UNEXPECTED_IF_STATEMENT,
				formName,
			);
	};

	onFormSubmit = () => {
		this.setState({ loading: true });
		const output = {};
		output.grades = [];
		output.scientific_field_id = this.props.scientificFieldSelected.id;
		output.finalResult = this.state.standardLessons.result;
		output.student_id = this.state.standardLessons.student_id.value;
		output.grades = this.state.standardLessons.grades;
		output.average = this.state.standardLessons.average;
		output.finalResultWithSpecialLessons = this.state.finalResultWithSpecialLessons;
		output.finalResultWithFactor2 = this.state.finalResultWithFactor2;
		output.design_grades = (() => {
			if (!isEmpty(this.state.specialLessons.result) && this.state.specialLessons.result.length === 2) {
				return this.state.specialLessons.grades.map((grade) => {
					return {
						grade: grade.grade,
						lesson_id: grade.lesson.value,
					};
				});
			} else {
				return [];
			}
		})();
		output.foreign_language_grades = (() => {
			if (!isEmpty(this.state.specialLessons.result) && this.state.specialLessons.result.length === 1) {
				return this.state.specialLessons.grades.map((grade) => {
					return {
						grade: grade.grade,
						lesson_id: grade.lesson.value,
					};
				});
			} else {
				return [];
			}
		})();
		this.props
			.addNewPanhellenicExaminationResult(output)
			.then((response) => {
				const result = {
					...response.data,
					grades: output.grades,
					flGrades: output.foreign_language_grades,
					designGrades: output.design_grades,
				};
				const student = this.props.allStudents.filter((student) => student.id === response.data.student_id)[0];
				const apiResponseMsg = {
					error: null,
					info: {
						message: (
							<>
								Η βαθμολογία του μαθητή{" "}
								<b>
									{student.lastname} {student.firstname}
								</b>{" "}
								προστέθηκε με επιτυχία.
							</>
						),
					},
				};
				this.props.setApiResponse(apiResponseMsg);
				this.props.addNewPanhellenicExaminationResultSuccess(result);
			})
			.catch((error) => {
				const apiResponseMsg = {
					error: error,
					info: null,
				};
				this.props.setApiResponse(apiResponseMsg);
				this.props.addNewPanhellenicExaminationResultFail(error);
			})
			.finally(() => {
				this.setState({ loading: false });
				this.props.closeModal();
				this.props.resetAllFilters();
			});
	};

	render() {
		return (
			<Modal
				isOpen={this.props.isModalOpen}
				header={
					<>
						<em className="fas fa-plus float-left fa-1_2x mr-3" />
						Προσθήκη Βαθμολογίας Πανελλαδικών Εξετάσεων
					</>
				}
				headerBg="primary"
				onSubmit={this.onFormSubmit}
				submitDisabled={
					this.state.isStandardLessonFormInvalid ||
					this.state.isSpecialLessonFormInvalid ||
					this.state.specialLessonFormError !== null ||
					this.state.standardLessonFormError !== null
				}
				onClose={this.props.closeModal}
				size={MODAL_SIZE.lg}
				loading={this.state.loading}
			>
				<form>
					<Row classes={["mb-3"]}>
						<Col>
							<Tag>{this.props.scientificFieldSelected.description}</Tag>
						</Col>
					</Row>
					<Row classes={["px-2"]}>
						<StandardLessonsForm
							scientificFieldLessons={this.props.scientificFieldLessons}
							allStudents={this.props.allStudents}
							setStandardLessons={this.setStandardLessons}
							setGradesError={this.setGradesError}
							setIsFormInvalid={this.setIsFormInvalid}
						/>
					</Row>

					<SpecialLessonFormsWrapper
						setIsFormInvalid={this.setIsFormInvalid}
						setGradesError={this.setGradesError}
						setSpecialLessons={this.setSpecialLessons}
					/>
					{this.state.standardLessons.result && this.state.standardLessonFormError === null && (
						<Row classes={["mb-1"]}>
							<Col>
								Αριθμός Μορίων:{" "}
								<span
									style={{ fontSize: "120%" }}
									className="text-green ml-1 text-bold"
								>
									{this.state.standardLessons.result}
								</span>
							</Col>
						</Row>
					)}
					{this.state.standardLessons.average && this.state.standardLessonFormError === null && (
						<Row classes={["mb-1"]}>
							<Col>
								Μέσος Όρος:{" "}
								<span
									style={{ fontSize: "120%" }}
									className="text-green ml-1 text-bold"
								>
									{this.state.standardLessons.average}
								</span>
							</Col>
						</Row>
					)}
					{this.state.finalResultWithSpecialLessons && (
						<Row classes={["mb-1"]}>
							<Col>
								Αριθμός Μορίων με Ειδικό Μάθημα:{" "}
								<span
									style={{ fontSize: "120%" }}
									className="text-green ml-1 text-bold"
								>
									{this.state.finalResultWithSpecialLessons}
								</span>
							</Col>
						</Row>
					)}
					{this.state.finalResultWithFactor2 && (
						<Row classes={["mb-1"]}>
							<Col>
								Αριθμός Μορίων με Συντελεστή 2:{" "}
								<span
									style={{ fontSize: "120%" }}
									className="text-green ml-1 text-bold"
								>
									{this.state.finalResultWithFactor2}
								</span>
							</Col>
						</Row>
					)}
					{this.showStandardLessonErrorAlert && (
						<Alert
							dismissible
							type="danger"
							onClose={() => (this.showStandardLessonErrorAlert = false)}
							message={<span>Σφάλμα: {this.state.standardLessonFormError}</span>}
						/>
					)}
					{this.showSpecialLessonErrorAlert && (
						<Alert
							dismissible
							type="danger"
							onClose={() => (this.showSpecialLessonErrorAlert = false)}
							message={<span>Σφάλμα: {this.state.specialLessonFormError}</span>}
						/>
					)}
					<Row classes={["px-2", "mt-3"]}>
						<Col classes={["bg-light", "p-2", "shadow-sm", "border"]}>
							<i className="fas fa-info-circle mr-2 text-info"></i>Η υποδιαστολή δηλώνεται με τον χαρακτήρα "." και όχι με τον χαρακτήρα ","
						</Col>
					</Row>
				</form>
			</Modal>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		addNewPanhellenicExaminationResult: (newResultsData) => dispatch(addNewPanhellenicExaminationResult(newResultsData)),
		addNewPanhellenicExaminationResultFail: (error) => dispatch(addNewPanhellenicExaminationResultFail(error)),
		addNewPanhellenicExaminationResultSuccess: (newResultsData) => dispatch(addNewPanhellenicExaminationResultSuccess(newResultsData)),
		setApiResponse: (theApiResponse) => dispatch(apiResponse(theApiResponse)),
	};
};

AddPanhellenicExaminationForm.propTypes = {
	addNewPanhellenicExaminationResult: PropTypes.func,
	addNewPanhellenicExaminationResultFail: PropTypes.func,
	addNewPanhellenicExaminationResultSuccess: PropTypes.func,
	allStudents: PropTypes.arrayOf(PropTypes.exact(StudentFromSelectorShape)),
	closeModal: PropTypes.func,
	resetShouldAddGradeRender: PropTypes.func,
	scientificFieldLessons: PropTypes.arrayOf(PropTypes.exact(ScientificFieldLessonsFromSelectorShape)),
	scientificFieldSelected: PropTypes.exact(ParametricShape),
	setApiResponse: PropTypes.func,
	isModalOpen: PropTypes.bool,
	resetAllFilters: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(AddPanhellenicExaminationForm);
