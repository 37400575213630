import { globalStore } from "../../globalStore";

export const allShortcuts = (state) => {
	if (!state.parametrics.finished) {
		return [];
	}

	const output = state.shortcuts.allShortcuts.map((shortcut, idx) => {
		const updatedShortcut = { ...shortcut };
		const updatedLessons = [];
		for (let i in updatedShortcut.lessons) {
			updatedLessons.push(globalStore?.lessons?.filter((obj) => obj.id === updatedShortcut.lessons[i])[0]);
		}
		updatedShortcut.lessons = updatedLessons;
		return updatedShortcut;
	});
	return output;
};
