import PropTypes from "prop-types";
import { memo } from "react";

const CounterCard = memo(({ bgColor, iconClass, subtitle, title }) => {
	return (
		<div className="card flex-row align-items-center align-items-stretch border-0">
			<div className={`col-4 d-flex align-items-center ${bgColor}-dark justify-content-center rounded-left`}>
				<em className="fa-3x">
					<i className={iconClass}></i>
				</em>
			</div>
			<div className={`col-8 py-3 ${bgColor} rounded-right`}>
				<div className="h2 mt-0">{title}</div>
				<div className="text-uppercase">{subtitle}</div>
			</div>
		</div>
	);
});

CounterCard.propTypes = {
	bgColor: PropTypes.string.isRequired,
	iconClass: PropTypes.string.isRequired,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	subtitle: PropTypes.string.isRequired,
};

export default CounterCard;
