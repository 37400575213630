import moment from "moment";
import { clone } from "./Utils";
import { includes, has } from "lodash";

export const getPropsToStateData = (stateData, propsData) => {
	const updatedStateForm = clone(stateData);
	for (let key in updatedStateForm) {
		const updatedElement = updatedStateForm[key];
		if (typeof propsData[updatedElement.name] === "object" && propsData[updatedElement.name] !== null) {
			if (has(updatedElement, "options")) {
				updatedElement.value = { value: propsData[updatedElement.name].id };
				if (has(propsData[updatedElement.name], "description")) {
					updatedElement.value.label = propsData[updatedElement.name].description;
				} else if (has(propsData[updatedElement.name], "name")) {
					updatedElement.value.label = propsData[updatedElement.name].name;
				}
			} else {
				updatedElement.value = propsData[updatedElement.name].id;
			}
		} else {
			if (has(updatedElement, "selected")) {
				updatedElement.selected = new Date(propsData[updatedElement.name]);
				if (has(updatedElement, "maxDate")) {
					updatedElement.maxDate = new Date();
				}
			} else if (has(updatedElement, "options")) {
				propsData[updatedElement.name] !== null
					? (updatedElement.value = updatedElement.options.find((elem) => elem.value === propsData[updatedElement.name]))
					: (updatedElement.value = null);
			} else if (updatedElement.inputType !== "file") {
				updatedElement.value = propsData[updatedElement.name] || "";
			}
		}
		updatedStateForm[key] = updatedElement;
	}
	return updatedStateForm;
};

export const getPropsToStudentLessonsData = (stateData, lessons) => {
	const updatedStateForm = clone(stateData);
	for (let key in updatedStateForm) {
		const updatedElement = updatedStateForm[key];
		if (includes(lessons, updatedElement.value)) {
			updatedElement.isChecked = true;
		} else {
			updatedElement.isChecked = false;
		}
		updatedStateForm[key] = updatedElement;
	}
	return updatedStateForm;
};

export const prepareDataBeforeSend = (data, dataProp = null) => {
	const output = {};
	for (let key in data) {
		if (has(data[key], "options")) {
			if (data[key].value !== null && data[key].value !== undefined) output[key] = data[key].value.value;
			else output[key] = null;
		} else if (has(data[key], "selected")) {
			if (dataProp === DATE_DATA_TYPES.DATE) output[key] = moment(data[key].selected).format("YYYY-MM-DD");
			else if (dataProp === DATE_DATA_TYPES.DATE_TIME) output[key] = moment(data[key].selected).format("YYYY-MM-DD HH:mm:ss");
		} else {
			output[key] = data[key].value;
		}
	}
	return output;
};

export const DATE_DATA_TYPES = {
	DATE_TIME: "datetime",
	DATE: "date",
};
