import { isEmpty } from "lodash";
import "moment/locale/el";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";

import { UserFromSelectorShape } from "../../../../Models/UserShape";
import {
	apiResponse,
	deleteTeacherDepartments,
	deleteTeacherDepartmentsFail,
	deleteTeacherDepartmentsSuccess,
} from "../../../../Redux/Actions/index";
import { allTeacherUsers } from "../../../../Redux/Selectors/userSelectors";
import { CUSTOM_ERRORS } from "../../../../Utils/CustomErrors";
import { openModal } from "../../../../Utils/GuiUtils";
import { USER_STATUS_DISABLE } from "../../../../Utils/ParametricTablesBinds";
import Button from "../../../UI/Button/Button";
import Card from "../../../UI/Card/Card";
import { MODAL_SIZE } from "../../../UI/Modal/constants/ModalSize";
import Modal from "../../../UI/Modal/Modal";
import Tag from "../../../UI/Tag/Tag";
import AddDepartmentAssignments from "./Forms/AddDepartmentAssignment";

class DepartmentAssignments extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedUser: null,
			loading: false,
			isAddDepartmentAssignmentsModalOpen: false,
			isDeleteTeacherDepartmentsModalOpen: false,
			isHelpModalOpen: false,
		};
	}

	componentDidMount() {
		document.title = process.env.REACT_APP_NAME + " :: Διαχείριση Αναθέσεων Τμημάτων";
	}

	onDeleteHandler = () => {
		this.setState({ loading: true });
		const teacher_id = !isEmpty(this.state.selectedUser) ? this.state.selectedUser.id : null;
		if (teacher_id === null) {
			CUSTOM_ERRORS.throwError(
				"AppConfiguration/DepartmentsAssignments/DepartmentsAssignments.js | line 37",
				CUSTOM_ERRORS.UNEXPECTED_IF_STATEMENT,
				this.state.selectedUser,
			);
			return;
		}

		this.props
			.deleteTeacherDepartments(teacher_id)
			.then((response) => {
				this.props.setApiResponse({
					error: null,
					info: {
						message: (
							<>
								Οι αναθέσεις τμημάτων του καθηγητή{" "}
								<b>
									{this.state.selectedUser.lastname} {this.state.selectedUser.firstname}
								</b>{" "}
								διαγράφηκαν με επιτυχία.
							</>
						),
						code: response.status,
					},
				});
				this.props.deleteTeacherDepartmentsSuccess({ id: teacher_id });
			})
			.catch((error) => {
				this.props.setApiResponse({
					error: error,
					info: null,
				});
				this.props.deleteTeacherDepartmentsFail(error);
			})
			.finally(() => {
				this.setState({ loading: false, isDeleteTeacherDepartmentsModalOpen: false });
				window.scrollTo(0, 0);
			});
	};

	handleSelectUser = (user, modalId) => {
		openModal(modalId, "departmentAssignmentCard");
		this.setState({ selectedUser: user });
	};

	render() {
		return (
			<>
				<Card
					title="Προβολή Καταχωρημένων Αναθέσεων Τμημάτων"
					headerSpannig={[10, 2]}
					extraOnHeader={
						<>
							<Button
								classes={["m-1"]}
								data-tip="Βοήθεια"
								iconClass="fas fa-question"
								id="btn"
								kind="secondary"
								onClick={() => this.setState({ isHelpModalOpen: true })}
								type="button"
							/>
						</>
					}
					id="departmentAssignmentCard"
				>
					{this.props.allUsers.map((user, idx) => {
						return (
							<div
								className="row py-2 align-items-center"
								key={idx}
							>
								<div className="col-xl-2 col-lg-3 col-md-4 my-auto">
									<i className={`fas ${user.status.id === USER_STATUS_DISABLE ? "fa-user-times text-danger" : "fa-user"} fa-150x mr-2`}></i>{" "}
									{user.lastname} {user.firstname}
								</div>
								<div className="col-xl-10 col-lg-9 col-md-8">
									<table className="table table-sm">
										<tbody>
											<tr>
												<td
													className="p-0"
													style={{ width: "76px", border: "none" }}
												>
													<Button
														classes={["mr-1", "btn-sm"]}
														type="button"
														iconClass={"fas " + (!isEmpty(user.departments) ? "fa-pencil-alt" : "fa-plus")}
														kind="primary"
														disabled={user.status.id === USER_STATUS_DISABLE}
														data-tip={getButtonTooltipText(user, "add")}
														onClick={() => this.setState({ selectedUser: user, isAddDepartmentAssignmentsModalOpen: true })}
													/>
													<Button
														classes={["btn-sm"]}
														type="button"
														iconClass="fas fa-trash"
														kind="danger"
														disabled={user.status.id === USER_STATUS_DISABLE || isEmpty(user.departments)}
														data-tip={getButtonTooltipText(user, "delete")}
														onClick={() => this.setState({ selectedUser: user, isDeleteTeacherDepartmentsModalOpen: true })}
													/>
												</td>
												<td
													className="bg-light pl-2"
													style={{ border: "1px solid #eee" }}
												>
													{user.departments.map((department, idx) => (
														<Tag
															classes={["my-1", "my-lg-0"]}
															key={idx}
														>
															{department.name}
														</Tag>
													))}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						);
					})}
					<ReactTooltip effect="solid" />
				</Card>
				<AddDepartmentAssignments
					selectedUser={this.state.selectedUser}
					isModalOpen={this.state.isAddDepartmentAssignmentsModalOpen}
					closeModal={() => this.setState({ isAddDepartmentAssignmentsModalOpen: false })}
				/>
				<Modal
					footer={
						<>
							<Button
								text="Επιβεβαίωση"
								type="button"
								kind="success"
								onClick={this.onDeleteHandler}
							/>
							<Button
								text="Κλείσιμο"
								kind="secondary"
								type="button"
								onClick={() => this.setState({ isDeleteTeacherDepartmentsModalOpen: false })}
							/>
						</>
					}
					header={
						<>
							<em className="fas fa-trash float-left fa-1_2x mr-3" />
							Επιβεβαίωση Διαγραφής
						</>
					}
					headerBg="danger"
					isOpen={this.state.isDeleteTeacherDepartmentsModalOpen}
					loading={this.state.loading}
					onClose={() => this.setState({ isDeleteTeacherDepartmentsModalOpen: false })}
				>
					<div>
						<strong>Είστε σίγουρος ότι επιθυμείτε να προβείτε στη διαγραφή των αναθέσεων του καθηγητή;</strong>
						<p className="mt-2">
							{" "}
							<i className="fas fa-exclamation-circle mr-1 text-primary fa-120x"></i>Προσοχή: Η ενέργεια δεν θα μπορεί να αναιρεθεί.
						</p>
					</div>
				</Modal>
				<Modal
					isOpen={this.state.isHelpModalOpen}
					onClose={() => this.setState({ isHelpModalOpen: false })}
					header={
						<>
							<em className="fas fa-question float-left fa-1_2x mr-3" />
							Πληροφορίες
						</>
					}
					size={MODAL_SIZE.lg}
					footer={
						<Button
							text="Κλείσιμο"
							kind="secondary"
							type="button"
							onClick={() => this.setState({ isHelpModalOpen: false })}
						/>
					}
				>
					<ul className="mb-0">
						<li className="mb-2">
							Σε αυτή τη σελίδα παρέχεται η δυνατότητα ανάθεσης τμημάτων σε όλους τους καθηγητές που έχουν αποθηκευτεί στο σύστημα. Έτσι, ο κάθε
							καθηγητής θα μπορεί να βαθμολογεί μόνο για τα τμήματα που τον αφορούν.
						</li>
						<li className="mb-2">
							Με τη χρήση του εικονιδίου <i className="far fa-plus-square text-primary ml-1"></i> εμφανίζεται ένα νέο παράθυρο που περιέχει όλα τα
							τμήματα. Από εκεί μπορείτε να τα επιλέξετε ώστε να ολοκληρωθεί η ανάθεση. Τέλος, πατώντας το κουμπί <b>"Αποθήκευση"</b>, ολοκληρώνεται η
							διαδικασία.
						</li>
						<li>
							Με τη χρήση του εικονιδίου <i className="fas fa-trash text-danger ml-1"></i> μπορείτε να διαγράψετε όλες τις αναθέσεις που έχουν γίνει
							στον καθηγήτη. Πατώντας το κουμπί <b>"Επιβεβαίωση"</b>, ολοκληρώνεται η διαδικασία
						</li>
					</ul>
				</Modal>
			</>
		);
	}
}

const getButtonTooltipText = (user, action) => {
	if (user == null) {
		return "";
	}

	if (action === "add") {
		if (user.status.id === USER_STATUS_DISABLE) {
			return "Ανενεργός Χρήστης";
		} else if (isEmpty(user.departments)) {
			return `Προσθήκη Αναθέσεων (${user.lastname} ${user.firstname})`;
		} else if (!isEmpty(user.departments)) {
			return `Επεξεργασία Αναθέσεων (${user.lastname} ${user.firstname})`;
		} else {
			CUSTOM_ERRORS.throwError(
				"AppConfiguration/DepartmentsAssignments/DepartmentsAssignments.js | line 222",
				CUSTOM_ERRORS.UNEXPECTED_IF_STATEMENT,
				user,
			);
			return null;
		}
	} else if (action === "delete") {
		if (user.status.id === USER_STATUS_DISABLE) {
			return "Ανενεργός Χρήστης";
		} else if (isEmpty(user.departments)) {
			return `Καμία ανάθεση (${user.lastname} ${user.firstname})`;
		} else if (!isEmpty(user.departments)) {
			return `Διαγραφή Αναθέσεων (${user.lastname} ${user.firstname})`;
		} else {
			CUSTOM_ERRORS.throwError(
				"AppConfiguration/DepartmentsAssignments/DepartmentsAssignments.js | line 233",
				CUSTOM_ERRORS.UNEXPECTED_IF_STATEMENT,
				user,
			);
			return null;
		}
	} else {
		CUSTOM_ERRORS.throwError(
			"AppConfiguration/DepartmentsAssignments/DepartmentsAssignments.js | line 237",
			CUSTOM_ERRORS.UNEXPECTED_IF_STATEMENT,
			getButtonTooltipText,
		);
		return null;
	}
};

const mapStateToProps = (state) => {
	return {
		allUsers: allTeacherUsers(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		deleteTeacherDepartments: (teacherId) => dispatch(deleteTeacherDepartments(teacherId)),
		deleteTeacherDepartmentsFail: (error) => dispatch(deleteTeacherDepartmentsFail(error)),
		deleteTeacherDepartmentsSuccess: (teacherDepartmentsData) => dispatch(deleteTeacherDepartmentsSuccess(teacherDepartmentsData)),
		setApiResponse: (theApiResponse) => dispatch(apiResponse(theApiResponse)),
	};
};

DepartmentAssignments.propTypes = {
	deleteTeacherDepartmentsFail: PropTypes.func,
	deleteTeacherDepartmentsSuccess: PropTypes.func,
	deleteTeacherLessons: PropTypes.func,
	setApiResponse: PropTypes.func,
	usersWithLessonsAndDepartmentsShape: PropTypes.arrayOf(PropTypes.exact(UserFromSelectorShape)),
};

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentAssignments);
