import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";

import {
	apiResponse,
	setChangePasswordModalClose,
	updateUserPassword,
	updateUserPasswordFail,
	updateUserPasswordSuccess,
} from "../../Redux/Actions/index";
import { copyToClipboard, isFormikFormInvalid } from "../../Utils/Utils";
import BaseInput from "../UI/BaseInput/BaseInput";
import Button from "../UI/Button/Button";
import FormColumnField from "../UI/FormColumnField/FormColumnField";
import { Col, Row } from "../UI/Grid/Grid";
import InfoBox from "../UI/InfoBox/InfoBox";
import Modal from "../UI/Modal/Modal";
import Tag from "../UI/Tag/Tag";
const generator = require("generate-password");

const fieldNames = {
	PASSWORD: "password",
	REPASSWORD: "repassword",
};

const initialFormValues = {
	password: "",
	repassword: "",
};

const ChangePasswordForm = ({
	isModalOpen,
	updateUserPassword,
	updateUserPasswordSuccess,
	updateUserPasswordFail,
	setApiResponse,
	setChangePasswordModalClose,
	userId,
}) => {
	const [loading, setLoading] = useState(false);
	const [passwordText, setPasswordText] = useState(null);

	const formik = useFormik({
		initialValues: initialFormValues,
		onSubmit: (values) => {
			onFormSubmit(values);
		},
	});

	const generatePassword = () => {
		const password = generator.generate({
			length: 12,
			numbers: true,
			symbols: true,
			excludeSimilarCharacters: true,
			exclude: `,./+=[]{}()|<>:;'_~"\\`,
			strict: true,
		});
		formik.setFieldValue(fieldNames.PASSWORD, password);
		setPasswordText(password);
	};

	const isFormInvalid = () => {
		return isFormikFormInvalid([
			{
				value: formik.values[fieldNames.PASSWORD],
				required: true,
			},
			{
				value: formik.values[fieldNames.REPASSWORD],
				required: true,
			},
		]);
	};

	const onFormSubmit = (output) => {
		setLoading(true);
		updateUserPassword(output, userId)
			.then((response) => {
				const apiResponseMsg = {
					error: null,
					info: {
						message: <>Το συνθηματικό σας ενημερώθηκε με επιτυχία.</>,
					},
				};
				setApiResponse(apiResponseMsg);
				updateUserPasswordSuccess(response.data);
			})
			.catch((error) => {
				const apiResponseMsg = {
					error: error,
					info: null,
				};
				setApiResponse(apiResponseMsg);
				updateUserPasswordFail(error);
			})
			.finally(() => {
				setChangePasswordModalClose();
				setLoading(false);
				setPasswordText(null);
			});
	};

	return (
		<Modal
			header={
				<>
					<em className="fas fa-key float-left fa-1_2x mr-3" />
					Αλλαγή Συνθηματικού Χρήστη
				</>
			}
			headerBg="purple"
			isOpen={isModalOpen}
			loading={loading}
			onClose={() => {
				formik.resetForm();
				setChangePasswordModalClose();
				setPasswordText(null);
			}}
			onSubmit={formik.handleSubmit}
			submitDisabled={isFormInvalid()}
		>
			<form>
				<Row classes={["px-2"]}>
					<Col classes={["mb-4", "px-2"]}>
						<label>
							Συνθηματικό Χρήστη <span className="reqField">(*)</span>
						</label>
						<div style={{ position: "relative" }}>
							<BaseInput
								name={fieldNames.PASSWORD}
								placeholder="Συνθηματικό Χρήστη"
								type="password"
								value={formik.values[fieldNames.PASSWORD]}
								onChange={formik.handleChange}
							/>
							<Button
								type="button"
								iconClass="fas fa-key"
								kind="link"
								style={{ fontSize: "1.15rem", position: "absolute", top: "-1px", bottom: 0, right: "-2px" }}
								classes={["pl-1"]}
								onClick={() => generatePassword()}
								data-tip="Αυτόματη Δημιουργία Κωδικού"
								data-for="generate-password-tooltip-ch-pass"
								data-place="left"
							/>
						</div>
						{passwordText !== null && (
							<div style={{ fontSize: "90%" }}>
								<strong>Κωδικός: </strong> <Tag>{passwordText}</Tag>
								<Button
									type="button"
									text="Copy"
									kind="link"
									classes={["pl-2"]}
									onClick={() => {
										NotificationManager.success("Επιτυχής Αντιγραφή", "Success", 2500);
										copyToClipboard(passwordText);
									}}
								/>
							</div>
						)}
					</Col>
					<FormColumnField
						classes={["px-2", "mb-3"]}
						inputType="password"
						label="Επιβεβαίωση Συνθηματικού"
						name={fieldNames.REPASSWORD}
						onChange={formik.handleChange}
						placeholder="Επιβεβαίωση Συνθηματικού"
						required
						span={{
							xl: 12,
							lg: 12,
							md: 12,
							sm: 12,
						}}
						value={formik.values[fieldNames.REPASSWORD]}
					/>
				</Row>
				<Row classes={["px-1"]}>
					<Col>
						<InfoBox>
							<span
								className="mb-0"
								style={{ fontSize: "95%" }}
							>
								<b>Προσοχή: </b> Το συνθηματικό πρέπει να είναι τουλάχιστον 8 λατινικών χαρακτήρων, να περιέχει τουλάχιστον έναν αριθμό και ένα
								σύμβολο από τα #?!@$%^&amp;*-
							</span>
						</InfoBox>
					</Col>
				</Row>
				<ReactTooltip
					id="generate-password-tooltip-ch-pass"
					effect="solid"
				/>
			</form>
		</Modal>
	);
};

const mapStateToProps = (state) => {
	return {
		isModalOpen: state.changePasswordModal.isModalOpen,
		userId: state.changePasswordModal.userId,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setApiResponse: (theApiResponse) => dispatch(apiResponse(theApiResponse)),
		setChangePasswordModalClose: () => dispatch(setChangePasswordModalClose()),
		updateUserPassword: (userPasswordData, userId) => dispatch(updateUserPassword(userPasswordData, userId)),
		updateUserPasswordFail: (error) => dispatch(updateUserPasswordFail(error)),
		updateUserPasswordSuccess: (userPasswordData) => dispatch(updateUserPasswordSuccess(userPasswordData)),
	};
};

ChangePasswordForm.propTypes = {
	isModalOpen: PropTypes.bool,
	setApiResponse: PropTypes.func,
	setChangePasswordModalClose: PropTypes.func,
	updateUserPassword: PropTypes.func,
	updateUserPasswordFail: PropTypes.func,
	updateUserPasswordSuccess: PropTypes.func,
	userId: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm);
