import { isEmpty, uniqBy } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { clone } from "../../../../Utils/Utils";

import { GradeShape } from "../../../../Models/GradeShape";
import { CUSTOM_ERRORS } from "../../../../Utils/CustomErrors";
import { absenceKindIds, examKindIds } from "../../../../Utils/ParametricTablesBinds";
import Badge from "../../../UI/Badge/Badge";
import Card from "../../../UI/Card/Card";
import Datatable from "../../../UI/Datatable/Datatable";

const columns = [
	{
		name: "Ημ. Εξέτασης",
		selector: (row) => row.exam_date,
		format: (row) => (row.exam_date ? moment(row.exam_date).format("ddd, DD/MM/YYYY") : ""),
		sortable: true,
		wrap: true,
	},
	{
		name: "Μαθητής",
		selector: (row) => row.student.lastname + " " + row.student.firstname,
		sortable: true,
		wrap: true,
	},
	{
		name: "Μάθημα",
		selector: (row) => row.lesson.description,
		sortable: true,
		wrap: true,
	},
	{
		name: "Τάξη",
		selector: (row) => row.student.department.class.description,
		omit: true,
	},
	{
		name: "Τμήμα",
		selector: (row) => row.student.department.name,
		omit: true,
	},
	{
		name: "Είδος Εξέτασης",
		selector: (row) => row.exam_kind.description,
		format: (row) => {
			const badgeKind = (() => {
				if (row.exam_kind.id === examKindIds.EXAM) {
					return "purple";
				} else if (row.exam_kind.id === examKindIds.TEST) {
					return "primary";
				} else if (row.exam_kind.id === examKindIds.STUDY) {
					return "green";
				} else {
					CUSTOM_ERRORS.throwError("GradeManagement/GradeManagement.js | line 82", CUSTOM_ERRORS.UNEXPECTED_IF_STATEMENT, row.exam_kind);
				}
			})();
			return (
				<Badge
					kind={badgeKind}
					text={row.exam_kind.description}
				/>
			);
		},
		sortable: true,
	},
	{
		name: "Παρουσία",
		selector: (row) => row.absence.description,
		format: (row) => (
			<Badge
				kind={row.absence.id === absenceKindIds.NOT_PRESENT ? "danger" : "success"}
				text={row.absence.description}
			/>
		),
		sortable: true,
	},
	{
		name: "Βαθμός",
		selector: (row) => row.grade,
		sortable: true,
		maxWidth: "105px",
	},
	{
		name: "Σχόλια",
		selector: (row) => row.comments,
		format: (row) => (!isEmpty(row.comments) ? <i className="fas fa-check"></i> : ""),
		sortable: true,
		maxWidth: "100px",
	},
	{
		name: "Καταχωρήθηκε",
		selector: (row) => row.created_at,
		format: (row) => (row.created_at ? moment(row.created_at).format("ddd, DD/MM/YYYY HH:mm:ss") : ""),
		sortable: true,
		wrap: true,
	},
];

class GradesTableCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterText: "",
			filteredData: [],
		};
		this.toggledClearRows = false;
	}

	setFilter = (filterString, tableData) => {
		const filteredItems = tableData.filter((row) => row.absence.description.toLowerCase().includes(filterString.toLowerCase()));
		const filteredItems2 = tableData.filter((row) => row.exam_kind.description.toLowerCase().includes(filterString.toLowerCase()));
		this.setState({
			filterText: filterString,
			filteredData: uniqBy(filteredItems.concat(filteredItems2), "exam_date"),
		});
	};

	getFilterGrades = () => {
		let tableData = clone(this.props.allGrades);
		if (this.props.examKindSelectedId !== -1) {
			tableData = tableData.filter((grade) => grade.exam_kind.id === this.props.examKindSelectedId);
		}
		if (this.props.lessonSelectedId !== -1) {
			tableData = tableData.filter((grade) => grade.lesson_id === this.props.lessonSelectedId);
		}
		return tableData;
	};

	render() {
		return (
			<Card
				title="Πίνακας Βαθμολογιών"
				id="gradesTableCard"
				headerSpannig={[8, 4]}
				extraOnHeader={<i className="far fa-star fa-2x"></i>}
			>
				<Datatable
					columns={columns}
					defaultSortFieldId={1}
					defaultSortAsc={false}
					exportCsv
					exportFilename={`Πίνακας Βαθμολογιών ${this.props.student.lastname + " " + this.props.student.firstname} Μαθητολογίου Πολύτροπο`}
					filteredData={this.state.filteredData}
					filterText={this.state.filterText}
					onClear={() => {
						this.setState({ filterText: "", filteredData: [] });
						this.toggledClearRows = !this.toggledClearRows;
					}}
					onFilter={(e) => {
						this.setFilter(e.target.value, this.getFilterGrades());
						this.toggledClearRows = !this.toggledClearRows;
					}}
					rowData={isEmpty(this.state.filterText) ? this.getFilterGrades() : this.state.filteredData}
					toggledClearRows={this.toggledClearRows}
				/>
			</Card>
		);
	}
}

GradesTableCard.propTypes = {
	lessonSelectedId: PropTypes.number,
	examKindSelectedId: PropTypes.number,
	allGrades: PropTypes.arrayOf(PropTypes.exact(GradeShape)),
};

export default GradesTableCard;
