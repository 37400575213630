import { isEmpty, uniqBy } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";

import { SelfTestChapterSelectorShape } from "../../../Models/SelfTestChapterShape";
import { apiResponse, deleteSelfTestChapter, deleteSelfTestChapterFail, deleteSelfTestChapterSuccess } from "../../../Redux/Actions/index";
import { allSelfTest, allSelfTestChapters } from "../../../Redux/Selectors/selfTestSelectors";
import { allFetchingFinished } from "../../../Redux/Selectors/universalSelectors";
import Button from "../../UI/Button/Button";
import Card from "../../UI/Card/Card";
import Datatable from "../../UI/Datatable/Datatable";
import Modal from "../../UI/Modal/Modal";
import AddSelfTestChapterForm from "./Forms/AddSelfTestChapterForm";
import EditSelfTestChapterForm from "./Forms/EditSelfTestChapterForm";

const columns = [
	{
		name: "Α/Α",
		selector: (row) => row.id,
		sortable: true,
		width: "75px",
	},
	{
		name: "Ονομασία Ενότητας",
		selector: (row) => row.name,
		sortable: true,
	},
	{
		name: "Μάθημα",
		selector: (row) => row.lesson.description,
		sortable: true,
	},
	{
		name: "Πλήθος Test",
		selector: (row) => row.self_test_count,
		sortable: true,
	},
	{
		name: "Δημιουργήθηκε",
		selector: (row) => row.created_at,
		format: (row) => (row.created_at ? moment(row.created_at).format("ddd, DD/MM/YYYY HH:mm:ss") : ""),
		sortable: true,
		wrap: true,
	},
	{
		name: "Τροποποιήθηκε",
		selector: (row) => row.updated_at,
		format: (row) => (row.updated_at ? moment(row.updated_at).format("ddd, DD/MM/YYYY HH:mm:ss") : ""),
		sortable: true,
		wrap: true,
	},
];

class SelfTestChaptersManagement extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterText: "",
			filteredData: [],
			isAddSelfTestChapterModalOpen: false,
			isDeleteSelfTestChapterModalOpen: false,
			isEditSelfTestChapterModalOpen: false,
			loading: false,
			selectedRow: [],
		};
		this.toggledClearRows = false;
	}

	componentDidMount() {
		document.title = process.env.REACT_APP_NAME + " :: Διαχείριση Τμημάτων";
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.allFetchingFinished && this.props.allFetchingFinished) {
			this.resetAllFilters();
		}
	}

	selectedRow = (data) => {
		this.setState({ selectedRow: data.selectedRows });
	};

	clearSelectedRows = () => {
		this.toggledClearRows = !this.toggledClearRows;
		this.setState({ selectedRow: [] });
	};

	setFilter = (filterString) => {
		const filteredItems = this.props.allSelfTestChapters.filter((stc) => stc.name.toLowerCase().includes(filterString.toLowerCase()));
		const filteredItems2 = this.props.allSelfTestChapters.filter((stc) => stc.lesson.description.toLowerCase().includes(filterString.toLowerCase()));
		this.setState({
			filterText: filterString,
			filteredData: uniqBy(filteredItems.concat(filteredItems2), "name"),
		});
	};

	resetAllFilters = () => {
		this.setState({
			filterText: "",
			filteredData: [],
		});
		this.clearSelectedRows();
	};

	onDeleteHandler = () => {
		this.setState({ loading: true });
		this.props
			.deleteSelfTestChapter(!isEmpty(this.state.selectedRow) ? this.state.selectedRow[0].id : null)
			.then((response) => {
				this.props.setApiResponse({
					error: null,
					info: {
						message: (
							<>
								Η ενότητα <b>{this.state.selectedRow[0].name}</b> διαγράφηκε με επιτυχία.
							</>
						),
					},
				});
				this.props.deleteSelfTestChapterSuccess(response.data);
			})
			.catch((error) => {
				this.props.setApiResponse({
					error: error,
					info: null,
				});
				this.props.deleteSelfTestChapterFail(error);
			})
			.finally(() => {
				this.setState({ loading: false, isDeleteSelfTestChapterModalOpen: false });
				this.resetAllFilters();
			});
	};

	render() {
		return (
			<Card
				title="Προβολή Καταχωρημένων Ενοτήτων Test Αυτοαξιολόγησης"
				headerSpannig={[8, 4]}
				subTitle={<div className="text-sm">Αριθμός Καταχωρήσεων: {this.props.allSelfTestChapters.length}</div>}
				extraOnHeader={
					<>
						<Button
							oval
							type="button"
							iconClass="fas fa-plus"
							kind="primary"
							onClick={() => this.setState({ isAddSelfTestChapterModalOpen: true })}
							id="btn"
							classes={["m-1"]}
							data-tip="Προσθήκη Ενότητας"
						/>
						<Button
							oval
							type="button"
							iconClass="fas fa-pencil-alt"
							kind="info"
							onClick={() => this.setState({ isEditSelfTestChapterModalOpen: true })}
							id="btn"
							classes={["m-1"]}
							disabled={this.state.selectedRow.length !== 1}
							data-tip="Επεξεργασία Test"
						/>
						<Button
							oval
							type="button"
							iconClass="fas fa-trash"
							kind="danger"
							onClick={() => this.setState({ isDeleteSelfTestChapterModalOpen: true })}
							id="btn"
							classes={["m-1"]}
							disabled={this.state.selectedRow.length === 0}
							data-place="left"
							data-tip="Διαγραφή Test"
						/>
					</>
				}
				id="selfTestChapterCard"
			>
				<Datatable
					columns={columns}
					defaultSortFieldId={2}
					exportCsv
					exportFilename="Πίνακας Ενοτήτων Test Αυτοαξιολόγησης Μαθητολογίου Πολύτροπο"
					filteredData={this.state.filteredData}
					filterText={this.state.filterText}
					onClear={() => {
						this.setState({ filterText: "", filteredData: [] });
						this.clearSelectedRows();
					}}
					onFilter={(e) => {
						this.setFilter(e.target.value);
						this.clearSelectedRows();
					}}
					rowData={isEmpty(this.state.filterText) ? this.props.allSelfTestChapters : this.state.filteredData}
					selectable
					selectedRows={this.state.selectedRow}
					setSelectedRow={this.selectedRow}
					toggledClearRows={this.toggledClearRows}
				/>
				<AddSelfTestChapterForm
					setApiResponseMessages={this.setApiResponseMessages}
					isModalOpen={this.state.isAddSelfTestChapterModalOpen}
					closeModal={() => this.setState({ isAddSelfTestChapterModalOpen: false })}
					resetAllFilters={this.resetAllFilters}
				/>
				{!isEmpty(this.state.selectedRow) && (
					<>
						<EditSelfTestChapterForm
							selfTestChapter={!isEmpty(this.state.selectedRow) ? this.state.selectedRow[0] : null}
							setApiResponseMessages={this.setApiResponseMessages}
							isModalOpen={this.state.isEditSelfTestChapterModalOpen}
							closeModal={() => this.setState({ isEditSelfTestChapterModalOpen: false })}
							resetAllFilters={this.resetAllFilters}
						/>
						<Modal
							isOpen={this.state.isDeleteSelfTestChapterModalOpen}
							header={
								<>
									<em className="fas fa-trash float-left fa-1_2x mr-3" />
									Επιβεβαίωση Διαγραφής
								</>
							}
							headerBg="danger"
							loading={this.state.loading}
							footer={
								<>
									<Button
										text="Επιβεβαίωση"
										type="button"
										kind="success"
										onClick={this.onDeleteHandler}
									/>
									<Button
										text="Κλείσιμο"
										kind="secondary"
										type="button"
										onClick={() => this.setState({ isDeleteSelfTestChapterModalOpen: false })}
									/>
								</>
							}
							onClose={() => this.setState({ isDeleteSelfTestChapterModalOpen: false })}
						>
							<div>
								<strong>Είστε σίγουρος ότι επιθυμείτε να προβείτε στη διαγραφή της ενότητας;</strong>
								<p className="mt-2">
									{" "}
									<i className="fas fa-exclamation-circle mr-1 text-primary fa-120x"></i>Προσοχή: Η ενέργεια δεν θα μπορεί να αναιρεθεί.
								</p>
							</div>
						</Modal>
					</>
				)}
				<ReactTooltip effect="solid" />
			</Card>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		allSelfTest: allSelfTest(state),
		allSelfTestChapters: allSelfTestChapters(state),
		allFetchingFinished: allFetchingFinished(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		deleteSelfTestChapter: (selfTestChapterId) => dispatch(deleteSelfTestChapter(selfTestChapterId)),
		deleteSelfTestChapterSuccess: (selfTestChapterData) => dispatch(deleteSelfTestChapterSuccess(selfTestChapterData)),
		deleteSelfTestChapterFail: (error) => dispatch(deleteSelfTestChapterFail(error)),
		setApiResponse: (theApiResponse) => dispatch(apiResponse(theApiResponse)),
	};
};

SelfTestChaptersManagement.propTypes = {
	allSelfTestChapters: PropTypes.arrayOf(PropTypes.exact(SelfTestChapterSelectorShape)),
	allFetchingFinished: PropTypes.bool,
	deleteSelfTestChapter: PropTypes.func,
	deleteSelfTestChapterSuccess: PropTypes.func,
	deleteSelfTestChapterFail: PropTypes.func,
	setApiResponse: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelfTestChaptersManagement);
