import { updateObject } from "../../Utils/Utils";
import {
	ADD_NEW_GRADES_FAIL,
	ADD_NEW_GRADES_START,
	ADD_NEW_GRADES_SUCCESS,
	AUTH_LOGOUT,
	DELETE_GRADE_FAIL,
	DELETE_GRADE_START,
	DELETE_GRADE_SUCCESS,
	FETCH_ALL_GRADES_FAIL,
	FETCH_ALL_GRADES_START,
	FETCH_ALL_GRADES_SUCCESS,
	FETCH_CURRENT_STUDENT_USER_GRADES_FAIL,
	FETCH_CURRENT_STUDENT_USER_GRADES_START,
	FETCH_CURRENT_STUDENT_USER_GRADES_SUCCESS,
	FETCH_CURRENT_TEACHER_GRADES_FAIL,
	FETCH_CURRENT_TEACHER_GRADES_START,
	FETCH_CURRENT_TEACHER_GRADES_SUCCESS,
	UPDATE_GRADE_FAIL,
	UPDATE_GRADE_START,
	UPDATE_GRADE_SUCCESS,
} from "../Actions/actionTypes";

const initialState = {
	allGrades: [],
	error: null,
	finished: false,
	loading: false,
	success: false,
};

const fetchGradesStart = (state) => {
	return updateObject(state, {
		error: null,
		finished: false,
		loading: true,
		success: false,
	});
};

const fetchGradesSuccess = (state, action) => {
	return updateObject(state, {
		allGrades: action.payload,
		error: null,
		finished: true,
		loading: false,
		success: false,
	});
};

const fetchGradesFail = (state, action) => {
	return updateObject(state, {
		allGrades: [],
		error: action.payload,
		finished: false,
		loading: false,
		success: false,
	});
};

const addNewGradeStart = (state) => {
	return updateObject(state, {
		error: null,
		loading: true,
		success: false,
	});
};

const addNewGradeSuccess = (state, action) => {
	const updatedGrades = [...state.allGrades];
	const newGrade = { ...action.payload };
	updatedGrades.push(newGrade);
	return updateObject(state, {
		allGrades: updatedGrades,
		error: null,
		loading: false,
		success: true,
	});
};

const addNewGradeFail = (state, action) => {
	return updateObject(state, {
		error: action.payload,
		loading: false,
		success: false,
	});
};

const updateGradeStart = (state) => {
	return updateObject(state, {
		error: null,
		loading: true,
		success: false,
	});
};

const updateGradeSuccess = (state, action) => {
	const updatedGrades = state.allGrades.filter((grade) => grade.id !== action.payload.id);
	const updatedGrade = { ...action.payload };
	updatedGrades.push(updatedGrade);
	return updateObject(state, {
		allGrades: updatedGrades,
		error: null,
		loading: false,
		success: true,
	});
};

const updateGradeFail = (state, action) => {
	return updateObject(state, {
		error: action.payload,
		loading: false,
		success: false,
	});
};

const deleteGradeStart = (state) => {
	return updateObject(state, {
		error: null,
		loading: true,
		success: false,
	});
};

const deleteGradeSuccess = (state, action) => {
	const updatedGrades = [...state.allGrades].filter((obj) => obj.id !== action.payload.id);
	return updateObject(state, {
		allGrades: updatedGrades,
		error: null,
		loading: false,
		success: true,
	});
};

const deleteGradeFail = (state, action) => {
	return updateObject(state, {
		error: action.payload,
		loading: false,
		success: false,
	});
};

const authLogout = (state) => {
	return updateObject(state, initialState);
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_ALL_GRADES_START:
			return fetchGradesStart(state);
		case FETCH_ALL_GRADES_FAIL:
			return fetchGradesFail(state, action);
		case FETCH_ALL_GRADES_SUCCESS:
			return fetchGradesSuccess(state, action);
		case FETCH_CURRENT_TEACHER_GRADES_START:
			return fetchGradesStart(state);
		case FETCH_CURRENT_TEACHER_GRADES_FAIL:
			return fetchGradesFail(state, action);
		case FETCH_CURRENT_TEACHER_GRADES_SUCCESS:
			return fetchGradesSuccess(state, action);
		case FETCH_CURRENT_STUDENT_USER_GRADES_START:
			return fetchGradesStart(state);
		case FETCH_CURRENT_STUDENT_USER_GRADES_FAIL:
			return fetchGradesFail(state, action);
		case FETCH_CURRENT_STUDENT_USER_GRADES_SUCCESS:
			return fetchGradesSuccess(state, action);
		case ADD_NEW_GRADES_START:
			return addNewGradeStart(state);
		case ADD_NEW_GRADES_FAIL:
			return addNewGradeFail(state, action);
		case ADD_NEW_GRADES_SUCCESS:
			return addNewGradeSuccess(state, action);
		case DELETE_GRADE_START:
			return deleteGradeStart(state);
		case DELETE_GRADE_FAIL:
			return deleteGradeFail(state, action);
		case DELETE_GRADE_SUCCESS:
			return deleteGradeSuccess(state, action);
		case UPDATE_GRADE_START:
			return updateGradeStart(state);
		case UPDATE_GRADE_FAIL:
			return updateGradeFail(state, action);
		case UPDATE_GRADE_SUCCESS:
			return updateGradeSuccess(state, action);
		case AUTH_LOGOUT:
			return authLogout(state);
		default:
			return state;
	}
};

export default reducer;
