import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { memo, useState } from "react";
import DataTable from "react-data-table-component";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import ReactTooltip from "react-tooltip";

import { convertArrayOfObjectsToCSVTabSeperated, copyToClipboard, downloadCSV, paginationComponentOptions } from "../../../Utils/Utils";
import Button from "../Button/Button";
import Filter from "../Filter/Filter";
import { Col, Row } from "../Grid/Grid";

const Datatable = memo(
	({
		columns,
		defaultSortAsc,
		defaultSortFieldId,
		exportCsv,
		exportFilename,
		filterText,
		filteredData,
		fixedHeaderScrollHeight,
		onClear,
		onFilter,
		paginationPerPage,
		paginationRowsPerPageOptions,
		progressPending,
		rowData,
		searchDisabled,
		selectable,
		selectedRows,
		setSelectedRow,
		toggledClearRows,
	}) => {
		const [isDense, setIsDense] = useState(false);
		const [resizeIcon, setResizeIcon] = useState("fas fa-compress-alt");
		const [resizeText, setResizeText] = useState("Πύκνωμα Πίνακα");

		const selectableProps = (() => {
			if (selectable) {
				return {
					selectableRows: true,
					selectableRowsHighlight: true,
					selectableRowsSingle: true,
					selectableRowsVisibleOnly: true,
				};
			} else {
				return null;
			}
		})();

		return (
			<DataTable
				defaultSortFieldId={defaultSortFieldId ?? null}
				defaultSortAsc={defaultSortAsc ?? true}
				clearSelectedRows={toggledClearRows}
				columns={columns}
				data={rowData}
				dense={isDense}
				fixedHeader
				fixedHeaderScrollHeight={fixedHeaderScrollHeight ?? "45vh"}
				highlightOnHover
				noDataComponent={<p className="my-3 text-danger">Δεν βρέθηκαν δεδομένα</p>}
				onSelectedRowsChange={setSelectedRow}
				pagination
				paginationComponentOptions={paginationComponentOptions}
				paginationPerPage={paginationPerPage ?? 10}
				paginationRowsPerPageOptions={paginationRowsPerPageOptions ?? [10, 25, 50, 100]}
				persistTableHead
				progressPending={progressPending ?? null}
				{...selectableProps}
				striped
				subHeader
				subHeaderAlign="center"
				subHeaderComponent={
					<Row classes={["w-100", "bg-light", "border", "rounded", "py-2", "shadow-sm"]}>
						<Col
							xl={selectable ? 3 : 5}
							lg={selectable ? 3 : 5}
							md={4}
							classes={["my-md-auto", "my-1", "text-md-left", "text-center"]}
						>
							{selectable && (
								<Button
									type="button"
									iconClass="far fa-minus-square"
									data-tip="Αποεπιλογή γραμμών πίνακα"
									kind="purple"
									classes={["btn-sm"]}
									onClick={() => {
										onClear();
										ReactTooltip.hide();
									}}
									disabled={selectedRows.length === 0}
								/>
							)}
							<Button
								type="button"
								iconClass="far fa-copy"
								data-tip="Αντιγραφή γραμμών πίνακα"
								kind="purple"
								classes={selectable ? ["btn-sm", "ml-1"] : ["btn-sm"]}
								disabled={(isEmpty(filterText) && isEmpty(rowData)) || (!isEmpty(filterText) && isEmpty(filteredData))}
								onClick={() => {
									copyToClipboard(convertArrayOfObjectsToCSVTabSeperated(isEmpty(filterText) ? rowData : filteredData, columns));
									NotificationManager.success("Τα δεδομένα αντιγράφηκαν με επιτυχία.", "Success", 2500);
								}}
							/>
							<Button
								type="button"
								iconClass={resizeIcon}
								data-tip={resizeText}
								kind="purple"
								classes={["btn-sm", "ml-1"]}
								disabled={(isEmpty(filterText) && isEmpty(rowData)) || (!isEmpty(filterText) && isEmpty(filteredData))}
								onClick={() => {
									setIsDense(!isDense);
									if (!isDense) {
										setResizeIcon("fas fa-expand-alt");
										setResizeText("Αραίωση πίνακα");
									} else {
										setResizeIcon("fas fa-compress-alt");
										setResizeText("Πύκνωμα πίνακα");
									}
								}}
							/>
							{exportCsv && (
								<Button
									type="button"
									iconClass="fas fa-download"
									kind="purple"
									classes={["ml-1", "mt-md-0", "btn-sm"]}
									disabled={(isEmpty(filterText) && isEmpty(rowData)) || (!isEmpty(filterText) && isEmpty(filteredData))}
									data-tip="Λήψη δεδομένων σε csv"
									onClick={() => downloadCSV(isEmpty(filterText) ? rowData : filteredData, exportFilename, columns)}
								/>
							)}
						</Col>
						<Col
							xl={selectable ? 6 : 7}
							lg={selectable ? 6 : 7}
							md={selectable ? 5 : 8}
							classes={selectable ? ["my-md-0", "my-1"] : ["ml-auto", "my-md-0", "my-1"]}
						>
							<Filter
								onFilter={onFilter}
								filterText={filterText}
								onClear={onClear}
								disabled={searchDisabled || (isEmpty(filterText) && isEmpty(rowData))}
								placement={selectable ? "center" : "right"}
							/>
						</Col>
						{selectable && (
							<Col
								xl={3}
								lg={3}
								md={3}
								classes={["m-auto", "text-md-right", "text-center", "my-md-auto", "my-1"]}
							>
								<span>
									Επιλεγμένα: <b>{selectedRows.length}</b>
								</span>
							</Col>
						)}
						<ReactTooltip effect="solid" />
					</Row>
				}
			/>
		);
	},
);

Datatable.propTypes = {
	columns: PropTypes.array,
	defaultSortAsc: PropTypes.bool,
	defaultSortFieldId: PropTypes.number,
	exportCsv: PropTypes.bool,
	exportFilename: PropTypes.string,
	filterText: PropTypes.string,
	filteredData: PropTypes.array,
	fixedHeaderScrollHeight: PropTypes.string,
	onClear: PropTypes.func,
	onFilter: PropTypes.func,
	paginationPerPage: PropTypes.number,
	paginationRowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
	progressPending: PropTypes.bool,
	rowData: PropTypes.array,
	searchDisabled: PropTypes.bool,
	selectable: PropTypes.bool,
	selectedRows: PropTypes.array,
	setSelectedRow: PropTypes.func,
	toggledClearRows: PropTypes.bool,
};

export default Datatable;
