import moment from "moment";

import Badge from "../../Components/UI/Badge/Badge";

const footer = () => {
	return (
		<footer className="footer-container">
			<span className="float-left">
				<i className="far fa-copyright"></i> {moment().format("YYYY")} - {process.env.REACT_APP_ORGANIZATION_NAME}{" "}
				<Badge
					kind="secondary"
					text={`v${process.env.REACT_APP_VERSION}`}
				/>
			</span>
			<span className="float-right">
				<i className="fas fa-code"></i> Developed by{" "}
				<a
					href={process.env.REACT_APP_AUTHOR_WEBSITE}
					target="_blank"
					rel="noreferrer"
				>
					{process.env.REACT_APP_AUTHOR_NAME}
				</a>
			</span>
		</footer>
	);
};

export default footer;
