import { has, isEqual } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import { SelfTestChapterSelectorShape } from "../../../../Models/SelfTestChapterShape";
import { apiResponse, updateSelfTestChapter, updateSelfTestChapterFail, updateSelfTestChapterSuccess } from "../../../../Redux/Actions/index";
import { getPropsToStateData, prepareDataBeforeSend } from "../../../../Utils/ComponentsUtils";
import { getParametricAsOptions } from "../../../../Utils/GuiUtils";
import { PARAMETRIC_TABLES } from "../../../../Utils/ParametricTablesBinds";
import { clone, isFormInvalid } from "../../../../Utils/Utils";
import BaseInput from "../../../UI/BaseInput/BaseInput";
import Button from "../../../UI/Button/Button";
import { Col, Row } from "../../../UI/Grid/Grid";
import Modal from "../../../UI/Modal/Modal";

class EditSelfTestChapterForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editSelfTestChapterForm: {
				self_test_chapter_name: {
					label: "Ονομασία Ενότητας",
					name: "name",
					placeholder: "Ονομασία Ενότητας",
					required: true,
					value: "",
					colSpan: 12,
				},
				lesson_id: {
					label: "Μάθημα",
					name: "lesson_id",
					options: getParametricAsOptions(PARAMETRIC_TABLES.LESSONS),
					placeholder: "Μάθημα",
					required: true,
					value: null,
					colSpan: 12,
				},
			},
			loading: false,
		};
	}

	componentDidMount() {
		this.setState({ editSelfTestChapterForm: getPropsToStateData(this.state.editSelfTestChapterForm, this.props.selfTestChapter) });
	}

	componentDidUpdate(prevProps, prevState) {
		if (!isEqual(this.props.selfTestChapter, prevProps.selfTestChapter) && this.props.selfTestChapter !== null) {
			this.setState({ editSelfTestChapterForm: getPropsToStateData(this.state.editSelfTestChapterForm, this.props.selfTestChapter) });
		}
	}

	isDirty = () => {
		return !isEqual(this.state.editSelfTestChapterForm, getPropsToStateData(this.state.editSelfTestChapterForm, this.props.selfTestChapter));
	};

	isFormInvalid = () => {
		let allFalse = false;
		for (let key in this.state.editSelfTestChapterForm) {
			if (this.state.editSelfTestChapterForm[key].required) {
				if (this.state.editSelfTestChapterForm[key].value === "") {
					allFalse = true;
				}
			}
		}
		return allFalse;
	};

	onChangeHandler = (selectedOption, inputId) => {
		const updatedForm = clone(this.state.editSelfTestChapterForm);
		const updatedFormElement = clone(updatedForm[inputId]);

		if (has(updatedFormElement, "options")) {
			updatedFormElement.value = selectedOption;
		} else {
			updatedFormElement.value = selectedOption.target.value;
		}

		updatedForm[inputId] = updatedFormElement;
		this.setState({ editSelfTestChapterForm: updatedForm });
	};

	onFormSubmit = () => {
		this.setState({ loading: true });
		const dataToSend = prepareDataBeforeSend(this.state.editSelfTestChapterForm);

		this.props
			.updateSelfTestChapter(dataToSend, this.props.selfTestChapter.id)
			.then((response) => {
				const apiResponseMsg = {
					error: null,
					info: {
						message: (
							<>
								Η ενότητα <b>{response.data.name}</b> ενημερώθηκε με επιτυχία.
							</>
						),
					},
				};
				this.props.setApiResponse(apiResponseMsg);
				this.props.updateSelfTestChapterSuccess(response.data);
			})
			.catch((error) => {
				const apiResponseMsg = {
					error: error,
					info: null,
				};
				this.props.setApiResponse(apiResponseMsg);
				this.props.updateSelfTestChapterFail(error);
			})
			.finally(() => {
				this.setState({ loading: false });
				this.props.resetAllFilters();
				this.props.closeModal();
			});
	};

	onReset = () => {
		this.setState({ editSelfTestChapterForm: getPropsToStateData(this.state.editSelfTestChapterForm, this.props.selfTestChapter) });
	};

	render() {
		const formElementsArray = [];
		for (let key in this.state.editSelfTestChapterForm) {
			formElementsArray.push({
				id: key,
				config: this.state.editSelfTestChapterForm[key],
			});
		}

		const footer = (() => {
			if (this.props.readonly) {
				return (
					<button
						className="btn btn-secondary"
						type="button"
						data-dismiss="modal"
					>
						Κλείσιμο
					</button>
				);
			} else {
				return (
					<>
						<Button
							type="button"
							kind="success"
							onClick={this.onFormSubmit}
							disabled={isFormInvalid(this.state.editSelfTestChapterForm) || !this.isDirty()}
							text="Αποθήκευση"
						/>
						<Button
							type="button"
							kind="primary"
							onClick={this.onReset}
							disabled={!this.isDirty()}
							text="Επαναφορά"
						/>
						<Button
							type="button"
							kind="secondary"
							text="Κλείσιμο"
							onClick={this.props.closeModal}
						/>
					</>
				);
			}
		})();

		return (
			<Modal
				isOpen={this.props.isModalOpen}
				header={
					<>
						<em className="fas fa-pencil-alt float-left fa-1_2x mr-3" />
						Επεξεργασία Ενότητας Test Αυτοαξιολόγησης
					</>
				}
				headerBg="info"
				loading={this.state.loading}
				footer={footer}
				onClose={this.props.closeModal}
			>
				<form>
					<Row classes={["px-2"]}>
						{formElementsArray.map((element, idx) => {
							return (
								<Col
									classes={["mb-4", "px-2"]}
									key={idx}
								>
									<label>
										{element.config.label} {element.config.required ? <span className="reqField">(*)</span> : null}
									</label>
									<BaseInput
										key={element.config.name + element.config.id}
										name={element.config.name}
										placeholder={element.config.placeholder}
										options={element.config.options}
										type={element.config.inputType}
										value={element.config.value}
										onChange={(selectedOption) => {
											this.onChangeHandler(selectedOption, element.id);
										}}
									/>
								</Col>
							);
						})}
					</Row>
				</form>
			</Modal>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setApiResponse: (theApiResponse) => dispatch(apiResponse(theApiResponse)),
		updateSelfTestChapter: (selfTestChapterData, selfTestChapterId) => dispatch(updateSelfTestChapter(selfTestChapterData, selfTestChapterId)),
		updateSelfTestChapterFail: (error) => dispatch(updateSelfTestChapterFail(error)),
		updateSelfTestChapterSuccess: (selfTestChapterData) => dispatch(updateSelfTestChapterSuccess(selfTestChapterData)),
	};
};

EditSelfTestChapterForm.propTypes = {
	isModalOpen: PropTypes.bool,
	selfTestChapter: PropTypes.exact(SelfTestChapterSelectorShape),
	setApiResponse: PropTypes.func,
	updateSelfTestChapter: PropTypes.func,
	updateSelfTestChapterFail: PropTypes.func,
	updateSelfTestChapterSuccess: PropTypes.func,
	closeModal: PropTypes.func,
	resetAllFilters: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(EditSelfTestChapterForm);
