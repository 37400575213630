import { includes } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import Countdown from "react-countdown";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { SelfTestSelectorShape } from "../../../Models/SelfTestShape";
import { StudentFromSelectorShape } from "../../../Models/StudentShape";
import { allSelfTest } from "../../../Redux/Selectors/selfTestSelectors";
import { allStudents } from "../../../Redux/Selectors/studentsSelectors";
import Alert from "../../UI/Alert/Alert";
import Button from "../../UI/Button/Button";
import { Col, Row } from "../../UI/Grid/Grid";
import PdfViewer from "../../UI/PdfViewer/PdfViewer";
import SelfTestForm from "./Forms/SelfTestForm";

const renderer = ({ hours, minutes, completed, formatted }) => {
	if (completed) {
		return <span className="self-test-countdown text-success">Τέλος Χρόνου</span>;
	} else {
		const spanClass = (() => {
			return hours === 0 && minutes === 0 ? "self-test-countdown text-warning" : "self-test-countdown";
		})();
		return (
			<span className={spanClass}>
				{formatted.hours}:{formatted.minutes}:{formatted.seconds}
			</span>
		);
	}
};

class SelfTestView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			timerStarted: false,
		};
		this.countDownRef = React.createRef();
	}
	componentDidMount() {
		document.title = process.env.REACT_APP_NAME + " :: Εξέταση Test Αυτοαξιολόγησης";
	}

	render() {
		const selfTestAnswersSelfTestIds = this.props.allSelfTestAnswers.map((sta) => sta.self_test_id);
		const currentSelfTest = this.props.allSelfTest.find((st) => st.id === Number(this.props.match.params.self_test_id));
		const currentSelfTestAnswer = this.props.allSelfTestAnswers?.find((sta) => sta.self_test_id === currentSelfTest.id) ?? null;
		if (currentSelfTest === null || currentSelfTest === undefined) {
			return (
				<p className="text-danger text-bold text-center">
					Εμφανίστηκε κρίσιμο σφάλμα κατά την ανεύρεση του τεστ που επιλέξατε. <br />
					Παρακαλώ ενημερώστε τον διαχειριστή της εφαρμογής.
				</p>
			);
		} else {
			return (
				<>
					{!includes(selfTestAnswersSelfTestIds, currentSelfTest.id) && (
						<Alert
							type="info"
							dismissible={true}
							message={
								<span>
									<strong>Προσοχή: </strong>Σε περίπτωση ανανέωσης του φυλλομετρητή, ή εναλλαγής σε άλλο μενού, το τεστ καταχωρείτε στην υπάρχουσα
									κατάσταση και δεν θα μπορείτε να ξαναεξεταστείτε.
								</span>
							}
						/>
					)}
					<Row classes={["mx-auto", "p-3", "bg-white", "border", "shadow-sm", "mb-3"]}>
						<Col
							xl={8}
							lg={8}
						>
							<h4 className="mb-2">{currentSelfTest.chapter.name}</h4>
							<div>
								<span className="mr-3">
									{" "}
									<i className="far fa-clock mr-1"></i>
									{currentSelfTest.duration + "'"}
								</span>
								<span className="mr-3">
									{" "}
									<i className="fas fa-user-graduate mr-1"></i>
									{currentSelfTest.user.lastname + " " + currentSelfTest.user.firstname}
								</span>
								<span>
									{" "}
									<i className="fas fa-book-open mr-1"></i>
									{currentSelfTest.chapter.lesson.description}
								</span>
							</div>
							<div className="mt-3">
								<Button
									type="button"
									text="ΕΚΚΙΝΗΣΗ"
									iconClass="far fa-clock"
									kind="success"
									classes={["btn-lg", "text-bold"]}
									style={{ width: "200px" }}
									disabled={this.state.timerStarted || includes(selfTestAnswersSelfTestIds, currentSelfTest.id)}
									onClick={() => {
										this.countDownRef.current.start();
										this.setState({ timerStarted: true });
									}}
								/>
							</div>
						</Col>
						<Col
							xl={4}
							lg={4}
							classes={["my-auto", "text-center"]}
						>
							{!includes(selfTestAnswersSelfTestIds, currentSelfTest.id) ? (
								<Countdown
									date={Date.now() + currentSelfTest.duration * 60000}
									ref={this.countDownRef}
									autoStart={false}
									renderer={renderer}
								/>
							) : (
								<span style={{ fontSize: "150%" }}>Εξεταστήκατε: {moment(currentSelfTestAnswer.created_at).format("ddd, DD/MM/YYYY")}</span>
							)}
						</Col>
					</Row>
					{(this.state.timerStarted || includes(selfTestAnswersSelfTestIds, currentSelfTest.id)) && (
						<Row>
							<Col
								xl={8}
								lg={8}
							>
								<Accordion
									allowMultipleExpanded={true}
									allowZeroExpanded
									preExpanded={!includes(selfTestAnswersSelfTestIds, currentSelfTest.id) ? ["a"] : []}
									className="unselectable accordion"
								>
									<AccordionItem
										uuid="a"
										key={"acc1"}
									>
										<AccordionItemHeading>
											<AccordionItemButton>
												<span className="h4">Αρχείο Test</span>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<PdfViewer
												file={currentSelfTest.self_test_file}
												filename={currentSelfTest.self_test_filename}
												hideFileName
											/>
										</AccordionItemPanel>
									</AccordionItem>
									{includes(selfTestAnswersSelfTestIds, currentSelfTest.id) && currentSelfTest.self_test_answers_file !== null && (
										<AccordionItem
											uuid="b"
											key={"acc2"}
										>
											<AccordionItemHeading>
												<AccordionItemButton>
													<span className="h4">Αρχείο Απαντήσεων Test</span>
												</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel>
												<PdfViewer
													file={currentSelfTest.self_test_answers_file}
													filename={currentSelfTest.self_test_answers_filename}
													hideFileName
												/>
											</AccordionItemPanel>
										</AccordionItem>
									)}
								</Accordion>
							</Col>
							<Col
								xl={4}
								lg={4}
							>
								<SelfTestForm
									currentSelfTest={currentSelfTest}
									currentStudent={this.props.allStudents[0]}
									countDownRef={this.countDownRef}
									onFinish={this.onFinish}
									currentSelfTestAnswers={currentSelfTestAnswer}
								/>
							</Col>
						</Row>
					)}
				</>
			);
		}
	}
}

const mapStateToProps = (state) => {
	return {
		allSelfTest: allSelfTest(state),
		allStudents: allStudents(state),
		allSelfTestAnswers: state.selfTestAnswers.allSelfTestAnswers,
	};
};

SelfTestView.propTypes = {
	allSelfTest: PropTypes.arrayOf(PropTypes.exact(SelfTestSelectorShape)),
	allStudents: PropTypes.arrayOf(PropTypes.exact(StudentFromSelectorShape)),
};

export default withRouter(connect(mapStateToProps)(SelfTestView));
