import { has, isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import { SelfTestChapterSelectorShape } from "../../../../Models/SelfTestChapterShape";
import { UserFromSelectorShape } from "../../../../Models/UserShape";
import { addNewSelfTest, addNewSelfTestFail, addNewSelfTestSuccess, apiResponse } from "../../../../Redux/Actions/index";
import { allSelfTestChapters } from "../../../../Redux/Selectors/selfTestSelectors";
import { allUsers } from "../../../../Redux/Selectors/userSelectors";
import { prepareDataBeforeSend } from "../../../../Utils/ComponentsUtils";
import { getParametricAsOptions } from "../../../../Utils/GuiUtils";
import { substituteUndefinedWithNullAndNullifyEmptyStrings } from "../../../../Utils/NullConversionUtils";
import { PARAMETRIC_TABLES, permissions } from "../../../../Utils/ParametricTablesBinds";
import { clone, isFormInvalid, roundNumber } from "../../../../Utils/Utils";
import BaseInput from "../../../UI/BaseInput/BaseInput";
import Divider from "../../../UI/Divider/Divider";
import { Col, Row } from "../../../UI/Grid/Grid";
import { MODAL_SIZE } from "../../../UI/Modal/constants/ModalSize";
import Modal from "../../../UI/Modal/Modal";

class AddSelfTestForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			addSelfTestForm: {
				chapter_id: {
					label: "Ενότητα Test",
					name: "chapter_id",
					placeholder: "Επιλογή Ενότητας",
					options: this.getSelfTestChaptersOptions(),
					required: true,
					value: null,
					colSpan: 5,
				},
				class: {
					label: "Τάξη",
					name: "class",
					placeholder: "Επιλογή Τάξης",
					options: getParametricAsOptions(PARAMETRIC_TABLES.CLASSES),
					required: true,
					value: null,
					colSpan: 3,
				},
				user_id: {
					label: "Καθηγητής",
					name: "user_id",
					placeholder: "Επιλογή Καθηγητή",
					options: this.getUsersOptions(),
					required: true,
					value: null,
					colSpan: 4,
				},
				duration: {
					label: "Διάρκεια (σε λεπτά)",
					name: "duration",
					placeholder: "Διάρκεια (σε λεπτά)",
					required: true,
					isNumber: true,
					inputType: "number",
					value: "",
					colSpan: 3,
				},
				status: {
					label: "Κατάσταση",
					name: "status",
					placeholder: "Κατάσταση",
					options: getParametricAsOptions(PARAMETRIC_TABLES.STATUS).map((status) => {
						return { label: status.label.substring(0, status.label.length - 1), value: status.value };
					}),
					required: true,
					searchable: false,
					value: null,
					colSpan: 3,
				},
				self_test_file: {
					label: "Αρχείο",
					name: "self_test_file",
					inputType: "file",
					value: "",
					colSpan: 3,
				},
				self_test_answers_file: {
					label: "Αρχείο Απαντήσεων",
					name: "self_test_answers_file",
					inputType: "file",
					value: "",
					colSpan: 3,
				},
			},
			correctAnswers: {
				q1: {
					label: "Ερώτηση 1",
					name: "q1",
					placeholder: "Ερ. 1",
					options: this.getAnswerOptions(),
					searchable: false,
					required: true,
					value: null,
					colSpan: 3,
				},
				q2: {
					label: "Ερώτηση 2",
					name: "q2",
					placeholder: "Ερ. 2",
					options: this.getAnswerOptions(),
					searchable: false,
					required: true,
					value: null,
					colSpan: 3,
				},
				q3: {
					label: "Ερώτηση 3",
					name: "q3",
					placeholder: "Ερ. 3",
					options: this.getAnswerOptions(),
					searchable: false,
					required: true,
					value: null,
					colSpan: 3,
				},
				q4: {
					label: "Ερώτηση 4",
					name: "q4",
					placeholder: "Ερ. 4",
					options: this.getAnswerOptions(),
					searchable: false,
					required: true,
					value: null,
					colSpan: 3,
				},
				q5: {
					label: "Ερώτηση 5",
					name: "q5",
					placeholder: "Ερ. 5",
					options: this.getAnswerOptions(),
					searchable: false,
					required: true,
					value: null,
					colSpan: 3,
				},
				q6: {
					label: "Ερώτηση 6",
					name: "q6",
					placeholder: "Ερ. 6",
					options: this.getAnswerOptions(),
					searchable: false,
					required: true,
					value: null,
					colSpan: 3,
				},
				q7: {
					label: "Ερώτηση 7",
					name: "q7",
					placeholder: "Ερ. 7",
					options: this.getAnswerOptions(),
					searchable: false,
					required: true,
					value: null,
					colSpan: 3,
				},
				q8: {
					label: "Ερώτηση 8",
					name: "q8",
					placeholder: "Ερ. 8",
					options: this.getAnswerOptions(),
					searchable: false,
					required: true,
					value: null,
					colSpan: 3,
				},
				q9: {
					label: "Ερώτηση 9",
					name: "q9",
					placeholder: "Ερ. 9",
					options: this.getAnswerOptions(),
					searchable: false,
					required: true,
					value: null,
					colSpan: 3,
				},
				q10: {
					label: "Ερώτηση 10",
					name: "q10",
					placeholder: "Ερ. 10",
					options: this.getAnswerOptions(),
					searchable: false,
					required: true,
					value: null,
					colSpan: 3,
				},
			},
			loading: false,
			self_test_file: null,
			self_test_answers_file: null,
		};
	}

	getUsersOptions = () => {
		const users = this.props.allUsers
			.filter((user) => user.permission.id !== permissions.STUDENT)
			.map((user) => {
				return {
					value: user.id,
					label: user.lastname + " " + user.firstname,
				};
			});
		return users;
	};

	getSelfTestChaptersOptions = () => {
		const selfTestChapters = this.props.allSelfTestChapters.map((chapter) => {
			return {
				value: chapter.id,
				label: `${chapter.name} | ${chapter.lesson.description}`,
			};
		});
		return selfTestChapters;
	};

	getAnswerOptions = () => {
		return [
			{ value: "Σωστό", label: "Σωστό" },
			{ value: "Λάθος", label: "Λάθος" },
			{ value: "Α", label: "Α" },
			{ value: "Β", label: "Β" },
			{ value: "Γ", label: "Γ" },
			{ value: "Δ", label: "Δ" },
			{ value: "Ε", label: "Ε" },
		];
	};

	getBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader);
			reader.onerror = (error) => reject(error);
		});
	};

	onChangeHandler = (selectedOption, inputId) => {
		const updatedForm = clone(this.state.addSelfTestForm);
		const updatedFormElement = clone(updatedForm[inputId]);

		if (has(updatedFormElement, "options")) {
			updatedFormElement.value = selectedOption;
		} else if (updatedFormElement.isNumber) {
			updatedFormElement.value = isEmpty(selectedOption.target.value) ? selectedOption.target.value : Number(selectedOption.target.value);
		} else if (updatedFormElement.inputType !== "file") {
			updatedFormElement.value = selectedOption.target.value;
		} else {
			this.setState({ [inputId]: selectedOption.target.files[0] });
		}

		updatedForm[inputId] = updatedFormElement;
		this.setState({ addSelfTestForm: updatedForm });
	};

	onChangeAnswerHandler = (selectedOption, inputId) => {
		const updatedForm = clone(this.state.correctAnswers);
		const updatedFormElement = clone(updatedForm[inputId]);
		updatedFormElement.value = selectedOption;
		updatedForm[inputId] = updatedFormElement;
		this.setState({ correctAnswers: updatedForm });
	};

	onFormSubmit = () => {
		this.setState({ loading: true });
		const output = prepareDataBeforeSend(this.state.addSelfTestForm);
		output.correct_answers = [];
		for (let key in this.state.correctAnswers) {
			output.correct_answers.push(this.state.correctAnswers[key].value.value);
		}
		const reader = new FileReader();
		reader.readAsDataURL(this.state.self_test_file);
		reader.onload = (e) => {
			output.self_test_filename = this.state.self_test_file.name;
			output.self_test_file = e.target.result;
			if (this.state.self_test_answers_file !== null) {
				const reader_answers = new FileReader();
				reader_answers.readAsDataURL(this.state.self_test_answers_file);
				reader_answers.onload = (e2) => {
					output.self_test_answers_filename = this.state.self_test_answers_file.name;
					output.self_test_answers_file = e2.target.result;
					this.sendRequest(output);
				};
			} else {
				this.sendRequest(output);
			}
		};
	};

	sendRequest = (sendData) => {
		this.props
			.addNewSelfTest(substituteUndefinedWithNullAndNullifyEmptyStrings(sendData))
			.then((response) => {
				const apiResponseMsg = {
					error: null,
					info: {
						message: <>Το test προστέθηκε με επιτυχία.</>,
					},
				};
				this.props.setApiResponse(apiResponseMsg);
				this.props.addNewSelfTestSuccess(response.data);
			})
			.catch((error) => {
				const apiResponseMsg = {
					error: error,
					info: null,
				};
				this.props.setApiResponse(apiResponseMsg);
				this.props.addNewSelfTestFail(error);
			})
			.finally(() => {
				this.setState({ loading: false });
				this.onCloseModal();
			});
	};

	emptyAllFormFields = () => {
		const updatedForm = clone(this.state.addSelfTestForm);
		for (let key in updatedForm) {
			const updatedFormElement = clone(updatedForm[key]);
			has(updatedFormElement, "options") ? (updatedFormElement.value = null) : (updatedFormElement.value = "");
			updatedForm[key] = updatedFormElement;
			this.setState({
				addSelfTestForm: updatedForm,
				self_test_file: null,
				self_test_answers_file: null,
			});
		}
		const updatedCorrectAnswersForm = clone(this.state.correctAnswers);
		for (let key in updatedCorrectAnswersForm) {
			const updatedFormElement = clone(updatedCorrectAnswersForm[key]);
			updatedFormElement.value = null;
			updatedCorrectAnswersForm[key] = updatedFormElement;
			this.setState({ correctAnswers: updatedCorrectAnswersForm });
		}
	};

	onCloseModal = () => {
		this.emptyAllFormFields();
		this.props.resetAllFilters();
		this.props.closeModal();
	};

	render() {
		const formElementsArray = [];
		for (let key in this.state.addSelfTestForm) {
			formElementsArray.push({
				id: key,
				config: this.state.addSelfTestForm[key],
			});
		}

		const correctAnswersElementsArray = [];
		for (let key in this.state.correctAnswers) {
			correctAnswersElementsArray.push({
				id: key,
				config: this.state.correctAnswers[key],
			});
		}

		return (
			<Modal
				isOpen={this.props.isModalOpen}
				header={
					<>
						<em className="fas fa-plus float-left fa-1_2x mr-3" />
						Δημιουργία Νέου Test
					</>
				}
				headerBg="primary"
				size={MODAL_SIZE.lg}
				onSubmit={this.onFormSubmit}
				submitDisabled={isFormInvalid(this.state.addSelfTestForm) || isFormInvalid(this.state.correctAnswers) || this.state.self_test_file === null}
				onClose={this.onCloseModal}
				loading={this.state.loading}
			>
				<form>
					<Row classes={["px-2"]}>
						{formElementsArray.map((element, idx) => {
							return (
								<Col
									xl={element.config.colSpan}
									lg={element.config.colSpan}
									md={6}
									sm={6}
									classes={["mb-4", "px-2"]}
									key={"mainform" + idx}
								>
									<label>
										{element.config.label} {element.config.required ? <span className="reqField">(*)</span> : null}
									</label>
									<BaseInput
										key={element.config.name + element.config.id}
										name={element.config.name}
										placeholder={element.config.placeholder}
										searchable={element.config.searchable}
										options={element.config.options}
										type={element.config.inputType}
										value={element.config.value}
										accept={element.config.inputType === "file" ? "application/pdf" : null}
										onChange={(selectedOption) => {
											this.onChangeHandler(selectedOption, element.id);
										}}
									/>
									{element.config.inputType === "file" && this.state.self_test_file !== null && element.config.name === "self_test_file" && (
										<span style={{ fontSize: "88%" }}>
											{this.state.self_test_file.name + " - " + roundNumber(this.state.self_test_file.size / 1000000, 2) + " Mb"}
										</span>
									)}
									{element.config.inputType === "file" &&
										this.state.self_test_answers_file !== null &&
										element.config.name === "self_test_answers_file" && (
											<span style={{ fontSize: "88%" }}>
												{this.state.self_test_answers_file.name + " - " + roundNumber(this.state.self_test_answers_file.size / 1000000, 2) + " Mb"}
											</span>
										)}
								</Col>
							);
						})}
					</Row>
					<Divider text="Σωστές Απαντήσεις" />
					<Row classes={["px-2"]}>
						{correctAnswersElementsArray.map((element, idx) => {
							return (
								<Col
									xl={element.config.colSpan}
									lg={element.config.colSpan}
									md={4}
									sm={6}
									classes={["mb-4", "px-2"]}
									key={"ca" + idx}
								>
									<label>
										{element.config.label} {element.config.required ? <span className="reqField">(*)</span> : null}
									</label>
									<BaseInput
										key={element.config.name + element.config.id}
										name={element.config.name}
										placeholder={element.config.placeholder}
										options={element.config.options}
										value={element.config.value}
										searchable={element.config.searchable}
										onChange={(selectedOption) => {
											this.onChangeAnswerHandler(selectedOption, element.id);
										}}
									/>
								</Col>
							);
						})}
					</Row>
				</form>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		allSelfTestChapters: allSelfTestChapters(state),
		allUsers: allUsers(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		addNewSelfTest: (newSelfTestData) => dispatch(addNewSelfTest(newSelfTestData)),
		addNewSelfTestFail: (error) => dispatch(addNewSelfTestFail(error)),
		addNewSelfTestSuccess: (newSelfTestData) => dispatch(addNewSelfTestSuccess(newSelfTestData)),
		setApiResponse: (theApiResponse) => dispatch(apiResponse(theApiResponse)),
	};
};

AddSelfTestForm.propTypes = {
	addNewSelfTest: PropTypes.func,
	addNewSelfTestFail: PropTypes.func,
	addNewSelfTestSuccess: PropTypes.func,
	allSelfTestChapters: PropTypes.arrayOf(PropTypes.exact(SelfTestChapterSelectorShape)),
	allUsers: PropTypes.arrayOf(PropTypes.exact(UserFromSelectorShape)),
	closeModal: PropTypes.func,
	isModalOpen: PropTypes.bool,
	resetAllFilters: PropTypes.func,
	setApiResponse: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSelfTestForm);
