import React, { Component } from "react";
import { isEmpty } from "lodash";

import Card from "../../../UI/Card/Card";
import CustomChart from "../../../UI/Chart/CustomChart";
import Datatable from "../../../UI/Datatable/Datatable";

const studentPerLessonColumns = [
	{
		name: "Μάθημα",
		selector: (row) => row.lesson.description,
		sortable: true,
		wrap: true,
	},
	{
		name: "Αριθμός Μαθητών",
		selector: (row) => row.students,
		format: (row) => row.students.length,
		sortable: true,
	},
	{
		name: "Ποσοστό",
		selector: (row) => Number(row.percentage.replace("%", "")),
		format: (row) => row.percentage,
		sortable: true,
	},
];

class StudentsPerLeesonCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterText: "",
			filteredData: [],
		};
		this.toggledClearRows = false;
	}

	setFilter = (filterString) => {
		const filteredItems = this.props.studentsPerLesson.filter((item) => item.lesson.description.toLowerCase().includes(filterString.toLowerCase()));
		this.setState({
			filterText: filterString,
			filteredData: filteredItems,
		});
	};

	render() {
		return (
			<Card
				title="Πλήθος Μαθητών / Μάθημα"
				headerSpannig={[8, 4]}
				extraOnHeader={
					<i
						className="fas fa-chalkboard-teacher"
						style={{ fontSize: "250%" }}
					></i>
				}
			>
				{!isEmpty(this.props.studentsPerLesson) && (
					<div
						style={{ height: "350px", width: "100%" }}
						className="mb-3"
					>
						<CustomChart
							id="studentsPerLessonChart"
							type="bar"
							data={{
								labels: this.props.studentsPerLesson.map((stat) => stat.lesson.description),
								datasets: [
									{
										data: this.props.studentsPerLesson.map((stat) => stat.students.length),
									},
								],
							}}
						/>
					</div>
				)}
				<Datatable
					columns={studentPerLessonColumns}
					defaultSortFieldId={1}
					exportCsv
					exportFilename="Πίνακας Μαθητών ανά Μάθημα Μαθητολογίου Πολύτροπο"
					fixedHeaderScrollHeight="25vh"
					filteredData={this.state.filteredData}
					filterText={this.state.filterText}
					onClear={() => {
						this.setState({ filterText: "", filteredData: [] });
						this.toggledClearRows = !this.toggledClearRows;
					}}
					onFilter={(e) => {
						this.setFilter(e.target.value);
						this.toggledClearRows = !this.toggledClearRows;
					}}
					rowData={isEmpty(this.state.filterText) ? this.props.studentsPerLesson : this.state.filteredData}
					toggledClearRows={this.toggledClearRows}
				/>
			</Card>
		);
	}
}

export default StudentsPerLeesonCard;
