import { includes, isEmpty, isEqual, uniqBy } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import ReactTooltip from "react-tooltip";
import { globalStore } from "../../../../globalStore";
import { allFetchingFinished } from "../../../../Redux/Selectors/universalSelectors";
import { getListConstantsAsOptions } from "../../../../Utils/GuiUtils";
import { CHEMISTRY_ID, EKTHESI_ID, FINANCE_ID, HISTORY_ID, INFORMATICS_ID, MATH_OP_ID, PHYSICS_ID } from "../../../../Utils/ParametricTablesBinds";
import { clone } from "../../../../Utils/Utils";
import BaseInput from "../../../UI/BaseInput/BaseInput";
import Button from "../../../UI/Button/Button";
import Card from "../../../UI/Card/Card";
import Datatable from "../../../UI/Datatable/Datatable";
import ManageParametricForm from "./Forms/ManageParametricForm";

const columns = [
	{
		name: "Α/Α",
		selector: (row) => row.id,
		sortable: true,
		width: "75px",
	},
	{
		name: "Όνομα",
		selector: (row) => row.description,
		sortable: true,
	},
];

class ParametricsManagement extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterText: "",
			filteredData: [],
			gs: null,
			isModalOpen: false,
			rowData: [],
			selectedParametricTable: {
				placeholder: "Επιλογή Παραμετρικού",
				name: "selectedParametricTable",
				required: true,
				searchable: false,
				value: null,
			},
			selectedRow: [],
		};
		this.toggledClearRows = false;
	}

	componentDidMount() {
		document.title = process.env.REACT_APP_NAME + " :: Διαχείριση Παραμετρικών";
		this.setState({ gs: clone(globalStore) });
	}

	componentDidUpdate(prevProps, prevState) {
		if (!isEqual(globalStore, this.state.gs) && this.state.selectedParametricTable.value !== null) {
			const updatedGlobalStore = clone(globalStore);
			this.setState({ rowData: updatedGlobalStore[this.state.selectedParametricTable.value.value], gs: updatedGlobalStore });
		}

		if (!prevProps.allFetchingFinished && this.props.allFetchingFinished) {
			this.resetAllFilters();
		}
	}

	selectedRow = (data) => {
		this.setState({ selectedRow: data.selectedRows });
	};

	setFilter = (filterString) => {
		const filteredItems = this.state.rowData.filter((data) => data.description.toLowerCase().includes(filterString.toLowerCase()));
		this.setState({
			filterText: filterString,
			filteredData: uniqBy(filteredItems, "description"),
		});
	};

	clearSelectedRows = () => {
		this.toggledClearRows = !this.toggledClearRows;
		this.setState({ selectedRow: [] });
	};

	resetAllFilters = () => {
		this.setState({
			filterText: "",
			filteredData: [],
		});
		this.clearSelectedRows();
	};

	onChangeHandler = (selectedOption) => {
		const updatedForm = clone(this.state.selectedParametricTable);
		updatedForm.value = selectedOption;
		const updatedGlobalStore = clone(globalStore);
		this.setState({
			rowData: updatedGlobalStore[selectedOption.value],
			selectedParametricTable: updatedForm,
			selectedRow: [],
		});
		this.resetAllFilters();
	};

	render() {
		const lessonsToDisableEdit = [EKTHESI_ID, MATH_OP_ID, FINANCE_ID, INFORMATICS_ID, CHEMISTRY_ID, PHYSICS_ID, HISTORY_ID];
		const editDisabled = (() => {
			return this.state.selectedParametricTable?.value?.value === "lessons" && includes(lessonsToDisableEdit, this.state.selectedRow[0]?.id);
		})();

		return (
			<>
				<Card
					title="Προβολή Παραμετρικών Πινάκων"
					headerSpannig={[8, 4]}
					subTitle={
						<div className="mt-2 d-flex align-items-center">
							<label className="mr-2 my-auto">Επιλογή: </label>
							<div>
								<BaseInput
									key={1}
									placeholder={this.state.selectedParametricTable.placeholder}
									name={this.state.selectedParametricTable.name}
									value={this.state.selectedParametricTable.value}
									searchable={this.state.selectedParametricTable.searchable}
									options={getListConstantsAsOptions()}
									onChange={(selectedOption) => {
										this.onChangeHandler(selectedOption);
									}}
								/>
							</div>
						</div>
					}
					extraOnHeader={
						<>
							<Button
								oval
								type="button"
								iconClass="fas fa-plus"
								kind="primary"
								onClick={() => this.setState({ isModalOpen: true })}
								id="btn"
								classes={["m-1"]}
								disabled={
									this.state.selectedParametricTable.value === "" ||
									this.state.selectedParametricTable.value === null ||
									!isEmpty(this.state.selectedRow)
								}
								data-tip="Προθήκη Στοιχείου"
							/>
							<Button
								oval
								type="button"
								iconClass="fas fa-pencil-alt"
								kind="info"
								onClick={() => this.setState({ isModalOpen: true })}
								id="btn"
								classes={["m-1"]}
								disabled={this.state.selectedRow.length === 0 || editDisabled}
								data-place="left"
								data-tip="Επεξεργασία Στοιχείου"
							/>
						</>
					}
					id="parametricsCard"
				>
					{this.state.rowData.length === 0 && (
						<div>
							<p className="my-3">Επιλέξτε τον παραμετρικό πίνακα που επιθυμείτε να επεξεργαστείτε.</p>
						</div>
					)}
					{this.state.rowData.length > 0 && (
						<Datatable
							columns={columns}
							defaultSortFieldId={2}
							filteredData={this.state.filteredData}
							filterText={this.state.filterText}
							onClear={() => {
								this.setState({ filterText: "", filteredData: [] });
								this.clearSelectedRows();
							}}
							onFilter={(e) => {
								this.setFilter(e.target.value);
								this.clearSelectedRows();
							}}
							rowData={isEmpty(this.state.filterText) ? this.state.rowData : this.state.filteredData}
							selectable
							selectedRows={this.state.selectedRow}
							setSelectedRow={this.selectedRow}
							toggledClearRows={this.toggledClearRows}
						/>
					)}
					<ManageParametricForm
						selectedParametricTable={this.state.selectedParametricTable.value}
						parametric={!isEmpty(this.state.selectedRow) ? this.state.selectedRow[0] : null}
						resetGridFilters={this.resetAllFilters}
						isModalOpen={this.state.isModalOpen}
						closeModal={() => this.setState({ isModalOpen: false })}
					/>
					<ReactTooltip effect="solid" />
				</Card>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		allFetchingFinished: allFetchingFinished(state),
		loading: state.parametrics.loading,
	};
};

ParametricsManagement.propTypes = {
	allFetchingFinished: PropTypes.bool,
	loading: PropTypes.bool,
};

export default connect(mapStateToProps)(ParametricsManagement);
