import PropTypes from "prop-types";
import { SelfTestChapterSelectorShape } from "./SelfTestChapterShape";
import { UserFromReduxShape } from "./UserShape";
import { ParametricShape } from "./ParametricShape";

export const SelfTestSelectorShape = {
	chapter: PropTypes.shape(SelfTestChapterSelectorShape).isRequired,
	chapter_id: PropTypes.number.isRequired,
	class: PropTypes.shape(ParametricShape).isRequired,
	correct_answers: PropTypes.arrayOf(PropTypes.string).isRequired,
	created_at: PropTypes.string.isRequired,
	duration: PropTypes.number.isRequired,
	id: PropTypes.number.isRequired,
	self_test_file: PropTypes.string.isRequired,
	self_test_filename: PropTypes.string.isRequired,
	self_test_answers_filename: PropTypes.string,
	self_test_answers_file: PropTypes.string,
	status: PropTypes.shape(ParametricShape).isRequired,
	updated_at: PropTypes.string,
	user: PropTypes.shape(UserFromReduxShape).isRequired,
	user_id: PropTypes.number.isRequired,
};

export const SelfTestReduxShape = {
	chapter_id: PropTypes.number.isRequired,
	class: PropTypes.number.isRequired,
	correct_answers: PropTypes.arrayOf(PropTypes.string).isRequired,
	created_at: PropTypes.string.isRequired,
	duration: PropTypes.number.isRequired,
	id: PropTypes.number.isRequired,
	self_test_file: PropTypes.string.isRequired,
	self_test_filename: PropTypes.string.isRequired,
	self_test_answers_filename: PropTypes.string,
	self_test_answers_file: PropTypes.string,
	status: PropTypes.number.isRequired,
	updated_at: PropTypes.string,
	user_id: PropTypes.number.isRequired,
};
