import { getResource, postResource } from "../../BackendAPI/AbstractCRUDActions";
import { ActionKinds } from "../../BackendAPI/ActionKinds";
import { getSelfTestAnswersUrl, getSelfTestStudentAnswersUrl } from "../../BackendAPI/BackendAPI";
import {
	ADD_NEW_SELF_TEST_ANSWER_FAIL,
	ADD_NEW_SELF_TEST_ANSWER_START,
	ADD_NEW_SELF_TEST_ANSWER_SUCCESS,
	FETCH_ALL_SELF_TEST_ANSWERS_FAIL,
	FETCH_ALL_SELF_TEST_ANSWERS_START,
	FETCH_ALL_SELF_TEST_ANSWERS_SUCCESS,
	FETCH_CURRENT_STUDENT_SELF_TEST_ANSWERS_FAIL,
	FETCH_CURRENT_STUDENT_SELF_TEST_ANSWERS_START,
	FETCH_CURRENT_STUDENT_SELF_TEST_ANSWERS_SUCCESS,
} from "./actionTypes";

export const fetchAllSelfTestAnswersStart = () => {
	return {
		type: FETCH_ALL_SELF_TEST_ANSWERS_START,
	};
};

export const fetchAllSelfTestAnswersSuccess = (selfTestAnswersData) => {
	return {
		type: FETCH_ALL_SELF_TEST_ANSWERS_SUCCESS,
		payload: selfTestAnswersData,
	};
};

export const fetchAllSelfTestAnswersFail = (error) => {
	return {
		type: FETCH_ALL_SELF_TEST_ANSWERS_FAIL,
		payload: error,
	};
};

export const fetchAllSelfTestAnswers = () => {
	return (dispatch) => {
		dispatch(fetchAllSelfTestAnswersStart());
		const url = getSelfTestAnswersUrl(ActionKinds.GET);
		getResource(url)
			.then((response) => {
				dispatch(fetchAllSelfTestAnswersSuccess(response.data));
			})
			.catch((error) => {
				dispatch(fetchAllSelfTestAnswersFail(error));
			});
	};
};

export const fetchCurrentStudentSelfTestAnswersStart = () => {
	return {
		type: FETCH_CURRENT_STUDENT_SELF_TEST_ANSWERS_START,
	};
};

export const fetchCurrentStudentSelfTestAnswersSuccess = (selfTestAnswersData) => {
	return {
		type: FETCH_CURRENT_STUDENT_SELF_TEST_ANSWERS_SUCCESS,
		payload: selfTestAnswersData,
	};
};

export const fetchCurrentStudentSelfTestAnswersFail = (error) => {
	return {
		type: FETCH_CURRENT_STUDENT_SELF_TEST_ANSWERS_FAIL,
		payload: error,
	};
};

export const fetchCurrentStudentSelfTestAnswers = () => {
	return (dispatch) => {
		dispatch(fetchCurrentStudentSelfTestAnswersStart());
		const url = getSelfTestStudentAnswersUrl(ActionKinds.GET);
		getResource(url)
			.then((response) => {
				dispatch(fetchCurrentStudentSelfTestAnswersSuccess(response.data));
			})
			.catch((error) => {
				dispatch(fetchCurrentStudentSelfTestAnswersFail(error));
			});
	};
};

export const addNewSelfTestAnswerStart = () => {
	return {
		type: ADD_NEW_SELF_TEST_ANSWER_START,
	};
};
export const addNewSelfTestAnswerSuccess = (newSelfTestAnswerData) => {
	return {
		type: ADD_NEW_SELF_TEST_ANSWER_SUCCESS,
		payload: newSelfTestAnswerData,
	};
};

export const addNewSelfTestAnswerFail = (error) => {
	return {
		type: ADD_NEW_SELF_TEST_ANSWER_FAIL,
		payload: error,
	};
};

export const addNewSelfTestAnswer = (newSelfTestAnswerData) => {
	return (dispatch) => {
		dispatch(addNewSelfTestAnswerStart());
		const url = getSelfTestAnswersUrl(ActionKinds.POST);
		return postResource(url, newSelfTestAnswerData);
	};
};
