import { has, isEmpty, isEqual } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import { SelfTestChapterSelectorShape } from "../../../../Models/SelfTestChapterShape";
import { SelfTestSelectorShape } from "../../../../Models/SelfTestShape";
import { UserFromSelectorShape } from "../../../../Models/UserShape";
import { addNewSelfTestFail, apiResponse, updateSelfTest, updateSelfTestSuccess } from "../../../../Redux/Actions/index";
import { allSelfTestChapters } from "../../../../Redux/Selectors/selfTestSelectors";
import { allUsers } from "../../../../Redux/Selectors/userSelectors";
import { getPropsToStateData, prepareDataBeforeSend } from "../../../../Utils/ComponentsUtils";
import { getParametricAsOptions } from "../../../../Utils/GuiUtils";
import { substituteUndefinedWithNullAndNullifyEmptyStrings } from "../../../../Utils/NullConversionUtils";
import { PARAMETRIC_TABLES, permissions } from "../../../../Utils/ParametricTablesBinds";
import { clone, dataURLtoFile, isFormInvalid, roundNumber } from "../../../../Utils/Utils";
import BaseInput from "../../../UI/BaseInput/BaseInput";
import Button from "../../../UI/Button/Button";
import Divider from "../../../UI/Divider/Divider";
import { Col, Row } from "../../../UI/Grid/Grid";
import { MODAL_SIZE } from "../../../UI/Modal/constants/ModalSize";
import Modal from "../../../UI/Modal/Modal";

class EditSelfTestForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editSelfTestForm: {
				chapter_id: {
					label: "Ενότητα Test",
					name: "chapter_id",
					placeholder: "Επιλογή Ενότητας",
					options: this.getSelfTestChaptersOptions(),
					required: true,
					value: null,
					colSpan: 5,
				},
				class: {
					label: "Τάξη",
					name: "class",
					placeholder: "Επιλογή Τάξης",
					options: getParametricAsOptions(PARAMETRIC_TABLES.CLASSES),
					required: true,
					value: null,
					colSpan: 3,
				},
				user_id: {
					label: "Καθηγητής",
					name: "user_id",
					placeholder: "Επιλογή Καθηγητή",
					options: this.getUsersOptions(),
					required: true,
					value: null,
					colSpan: 4,
				},
				duration: {
					label: "Διάρκεια (σε λεπτά)",
					name: "duration",
					placeholder: "Διάρκεια (σε λεπτά)",
					required: true,
					isNumber: true,
					inputType: "number",
					value: "",
					colSpan: 3,
				},
				status: {
					label: "Κατάσταση",
					name: "status",
					placeholder: "Κατάσταση",
					options: getParametricAsOptions(PARAMETRIC_TABLES.STATUS).map((status) => {
						return { label: status.label.substring(0, status.label.length - 1), value: status.value };
					}),
					required: true,
					searchable: false,
					value: null,
					colSpan: 3,
				},
				self_test_file: {
					label: "Αρχείο",
					name: "self_test_file",
					inputType: "file",
					value: "",
					colSpan: 3,
				},
				self_test_answers_file: {
					label: "Αρχείο Απαντήσεων",
					name: "self_test_answers_file",
					inputType: "file",
					value: "",
					colSpan: 3,
				},
			},
			correctAnswers: {
				q1: {
					label: "Ερώτηση 1",
					name: "q1",
					placeholder: "Ερώτηση 1",
					options: this.getAnswerOptions(),
					required: true,
					searchable: false,
					value: null,
					colSpan: 3,
				},
				q2: {
					label: "Ερώτηση 2",
					name: "q2",
					placeholder: "Ερώτηση 2",
					options: this.getAnswerOptions(),
					required: true,
					searchable: false,
					value: null,
					colSpan: 3,
				},
				q3: {
					label: "Ερώτηση 3",
					name: "q3",
					placeholder: "Ερώτηση 3",
					options: this.getAnswerOptions(),
					required: true,
					searchable: false,
					value: null,
					colSpan: 3,
				},
				q4: {
					label: "Ερώτηση 4",
					name: "q4",
					placeholder: "Ερώτηση 4",
					options: this.getAnswerOptions(),
					required: true,
					searchable: false,
					value: null,
					colSpan: 3,
				},
				q5: {
					label: "Ερώτηση 5",
					name: "q5",
					placeholder: "Ερώτηση 5",
					options: this.getAnswerOptions(),
					required: true,
					searchable: false,
					value: null,
					colSpan: 3,
				},
				q6: {
					label: "Ερώτηση 6",
					name: "q6",
					placeholder: "Ερώτηση 6",
					options: this.getAnswerOptions(),
					required: true,
					searchable: false,
					value: null,
					colSpan: 3,
				},
				q7: {
					label: "Ερώτηση 7",
					name: "q7",
					placeholder: "Ερώτηση 7",
					options: this.getAnswerOptions(),
					required: true,
					searchable: false,
					value: null,
					colSpan: 3,
				},
				q8: {
					label: "Ερώτηση 8",
					name: "q8",
					placeholder: "Ερώτηση 8",
					options: this.getAnswerOptions(),
					required: true,
					searchable: false,
					value: null,
					colSpan: 3,
				},
				q9: {
					label: "Ερώτηση 9",
					name: "q9",
					placeholder: "Ερώτηση 9",
					options: this.getAnswerOptions(),
					required: true,
					searchable: false,
					value: null,
					colSpan: 3,
				},
				q10: {
					label: "Ερώτηση 10",
					name: "q10",
					placeholder: "Ερώτηση 10",
					options: this.getAnswerOptions(),
					required: true,
					searchable: false,
					value: null,
					colSpan: 3,
				},
			},
			loading: false,
			self_test_file: null,
			self_test_answers_file: null,
		};
	}

	componentDidMount() {
		this.setState({
			editSelfTestForm: this.getInitData(),
			correctAnswers: getPropsToStateData(this.state.correctAnswers, this.getTransformedCorrectAnswers()),
			self_test_file: this.getInitFile(),
			self_test_answers_file: this.getInitAnswerFile(),
		});
	}

	componentDidUpdate(prevProps) {
		if (!isEqual(this.props.currentSelfTest, prevProps.currentSelfTest) && this.props.currentSelfTest !== null) {
			this.setState({
				editSelfTestForm: this.getInitData(),
				correctAnswers: getPropsToStateData(this.state.correctAnswers, this.getTransformedCorrectAnswers()),
				self_test_file: this.getInitFile(),
				self_test_answers_file: this.getInitAnswerFile(),
			});
		}
	}

	getTransformedCorrectAnswers = () => {
		const output = {};
		this.props.currentSelfTest.correct_answers.forEach((ca, idx) => {
			output["q" + (idx + 1)] = {
				name: "q" + (idx + 1),
				description: ca,
				id: ca,
			};
		});
		return output;
	};

	getInitFile = () => {
		return dataURLtoFile(this.props.currentSelfTest.self_test_file, this.props.currentSelfTest.self_test_filename);
	};

	getInitAnswerFile = () => {
		if (this.props.currentSelfTest.self_test_answers_file === null) return null;
		return dataURLtoFile(this.props.currentSelfTest.self_test_answers_file, this.props.currentSelfTest.self_test_answers_filename);
	};

	getInitData = () => {
		const updatedState = clone(this.state.editSelfTestForm);
		for (let key in updatedState) {
			switch (updatedState[key].name) {
				case "chapter_id":
					updatedState[key].value = {
						value: this.props.currentSelfTest.chapter.id,
						label: this.props.currentSelfTest.chapter.name + " | " + this.props.currentSelfTest.chapter.lesson.description,
					};
					break;
				case "class":
					updatedState[key].value = {
						value: this.props.currentSelfTest[updatedState[key].name].id,
						label: this.props.currentSelfTest[updatedState[key].name].description,
					};
					break;
				case "status":
					updatedState[key].value = {
						value: this.props.currentSelfTest[updatedState[key].name].id,
						label: this.props.currentSelfTest[updatedState[key].name].description.substring(
							0,
							this.props.currentSelfTest[updatedState[key].name].description.length - 1,
						),
					};
					break;
				case "user_id":
					updatedState[key].value = {
						value: this.props.currentSelfTest.user.id,
						label: this.props.currentSelfTest.user.lastname + " " + this.props.currentSelfTest.user.firstname,
					};
					break;
				case "duration":
					updatedState[key].value = this.props.currentSelfTest.duration;
					break;
				default:
					break;
			}
		}
		return updatedState;
	};

	getUsersOptions = () => {
		const users = this.props.allUsers
			.filter((user) => user.permission.id !== permissions.STUDENT)
			.map((user) => {
				return {
					value: user.id,
					label: user.lastname + " " + user.firstname,
				};
			});
		return users;
	};

	getSelfTestChaptersOptions = () => {
		const users = this.props.allSelfTestChapters.map((chapter) => {
			return {
				value: chapter.id,
				label: `${chapter.name} | ${chapter.lesson.description}`,
			};
		});
		return users;
	};

	getAnswerOptions = () => {
		return [
			{ value: "Σωστό", label: "Σωστό" },
			{ value: "Λάθος", label: "Λάθος" },
			{ value: "Α", label: "Α" },
			{ value: "Β", label: "Β" },
			{ value: "Γ", label: "Γ" },
			{ value: "Δ", label: "Δ" },
			{ value: "Ε", label: "Ε" },
		];
	};

	isDirty = () => {
		const isDirty =
			!isEqual(this.state.editSelfTestForm, this.getInitData()) ||
			this.isFileDirty() ||
			!isEqual(this.state.correctAnswers, getPropsToStateData(this.state.correctAnswers, this.getTransformedCorrectAnswers()));
		return isDirty;
	};

	isFileDirty = () => {
		return (
			(!isEqual(this.state.self_test_file.name, this.getInitFile().name) && this.state.self_test_file.size !== this.getInitFile().size) ||
			(!isEqual(this.state.self_test_answers_file?.name, this.getInitAnswerFile()?.name) &&
				this.state.self_test_answers_file?.size !== this.getInitAnswerFile()?.size)
		);
	};

	getBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader);
			reader.onerror = (error) => reject(error);
		});
	};

	onChangeHandler = (selectedOption, inputId) => {
		const updatedForm = clone(this.state.editSelfTestForm);
		const updatedFormElement = clone(updatedForm[inputId]);

		if (has(updatedFormElement, "options")) {
			updatedFormElement.value = selectedOption;
		} else if (updatedFormElement.isNumber) {
			updatedFormElement.value = isEmpty(selectedOption.target.value) ? selectedOption.target.value : Number(selectedOption.target.value);
		} else if (updatedFormElement.inputType !== "file") {
			updatedFormElement.value = selectedOption.target.value;
		} else {
			this.setState({ [inputId]: selectedOption.target.files[0] });
		}

		updatedForm[inputId] = updatedFormElement;
		this.setState({ editSelfTestForm: updatedForm });
	};

	onChangeAnswerHandler = (selectedOption, inputId) => {
		const updatedForm = clone(this.state.correctAnswers);
		const updatedFormElement = clone(updatedForm[inputId]);
		updatedFormElement.value = selectedOption;
		updatedForm[inputId] = updatedFormElement;
		this.setState({ correctAnswers: updatedForm });
	};

	onReset = () => {
		this.setState({
			editSelfTestForm: this.getInitData(),
			correctAnswers: getPropsToStateData(this.state.correctAnswers, this.getTransformedCorrectAnswers()),
			self_test_file: this.getInitFile(),
			self_test_answers_file: this.getInitAnswerFile(),
		});
	};

	onFormSubmit = () => {
		this.setState({ loading: true });
		const output = prepareDataBeforeSend(this.state.editSelfTestForm);
		output.correct_answers = [];
		for (let key in this.state.correctAnswers) {
			output.correct_answers.push(this.state.correctAnswers[key].value.value);
		}
		const reader = new FileReader();
		reader.readAsDataURL(this.state.self_test_file);
		reader.onload = (e) => {
			output.self_test_filename = this.state.self_test_file.name;
			output.self_test_file = e.target.result;
			if (this.state.self_test_answers_file !== null) {
				const reader_answers = new FileReader();
				reader_answers.readAsDataURL(this.state.self_test_answers_file);
				reader_answers.onload = (e2) => {
					output.self_test_answers_filename = this.state.self_test_answers_file.name;
					output.self_test_answers_file = e2.target.result;
					this.sendRequest(output);
				};
			} else {
				this.sendRequest(output);
			}
		};
	};

	sendRequest = (sendData) => {
		this.props
			.updateSelfTest(substituteUndefinedWithNullAndNullifyEmptyStrings(sendData), this.props.currentSelfTest.id)
			.then((response) => {
				const apiResponseMsg = {
					error: null,
					info: {
						message: <>Το test προστέθηκε με επιτυχία.</>,
					},
				};
				this.props.setApiResponse(apiResponseMsg);
				this.props.updateSelfTestSuccess(response.data);
			})
			.catch((error) => {
				const apiResponseMsg = {
					error: error,
					info: null,
				};
				this.props.setApiResponse(apiResponseMsg);
				this.props.updateSelfTestFail(error);
			})
			.finally(() => {
				this.setState({ loading: false });
				this.props.resetAllFilters();
				this.props.closeModal();
			});
	};

	render() {
		const formElementsArray = [];
		for (let key in this.state.editSelfTestForm) {
			formElementsArray.push({
				id: key,
				config: this.state.editSelfTestForm[key],
			});
		}

		const correctAnswersElementsArray = [];
		for (let key in this.state.correctAnswers) {
			correctAnswersElementsArray.push({
				id: key,
				config: this.state.correctAnswers[key],
			});
		}

		return (
			<Modal
				isOpen={this.props.isModalOpen}
				header={
					<>
						<em className="fas fa-pencil-alt float-left fa-1_2x mr-3" />
						Επεξεργασία Test
					</>
				}
				headerBg="info"
				size={MODAL_SIZE.lg}
				loading={this.state.loading}
				footer={
					<>
						<Button
							type="button"
							kind="success"
							onClick={this.onFormSubmit}
							disabled={
								isFormInvalid(this.state.editSelfTestForm) || isFormInvalid(this.state.correctAnswers) || this.state.file === null || !this.isDirty()
							}
							text="Αποθήκευση"
						/>
						<Button
							type="button"
							kind="primary"
							onClick={this.onReset}
							disabled={!this.isDirty()}
							text="Επαναφορά"
						/>
						<Button
							type="button"
							kind="secondary"
							text="Κλείσιμο"
							onClick={this.props.closeModal}
						/>
					</>
				}
				onClose={this.props.closeModal}
			>
				<form ref={this.formRef}>
					<Row classes={["px-2"]}>
						{formElementsArray.map((element, idx) => {
							return (
								<Col
									xl={element.config.colSpan}
									lg={element.config.colSpan}
									md={6}
									sm={6}
									classes={["mb-4", "px-2"]}
									key={"mainform" + idx}
								>
									<label>
										{element.config.label} {element.config.required ? <span className="reqField">(*)</span> : null}
									</label>
									<BaseInput
										key={element.config.name + element.config.id}
										name={element.config.name}
										placeholder={element.config.placeholder}
										type={element.config.inputType}
										value={element.config.value}
										options={element.config.options}
										searchable={element.config.searchable}
										accept={element.config.inputType === "file" ? "application/pdf" : null}
										onChange={(selectedOption) => {
											this.onChangeHandler(selectedOption, element.id);
										}}
									/>
									{element.config.inputType === "file" && this.state.self_test_file !== null && element.config.name === "self_test_file" && (
										<span style={{ fontSize: "88%" }}>
											{this.state.self_test_file.name + " - " + roundNumber(this.state.self_test_file.size / 1000000, 2) + " Mb"}
										</span>
									)}
									{element.config.inputType === "file" &&
										this.state.self_test_answers_file !== null &&
										element.config.name === "self_test_answers_file" && (
											<span style={{ fontSize: "88%" }}>
												{this.state.self_test_answers_file.name + " - " + roundNumber(this.state.self_test_answers_file.size / 1000000, 2) + " Mb"}
											</span>
										)}
								</Col>
							);
						})}
					</Row>
					<Divider text="Σωστές Απαντήσεις" />
					<Row classes={["px-2"]}>
						{correctAnswersElementsArray.map((element, idx) => {
							return (
								<Col
									xl={element.config.colSpan}
									lg={element.config.colSpan}
									md={4}
									sm={6}
									classes={["mb-4", "px-2"]}
									key={"ca" + idx}
								>
									<label>
										{element.config.label} {element.config.required ? <span className="reqField">(*)</span> : null}
									</label>
									<BaseInput
										key={element.config.name + element.config.id}
										name={element.config.name}
										placeholder={element.config.placeholder}
										options={element.config.options}
										value={element.config.value}
										searchable={element.config.searchable}
										onChange={(selectedOption) => {
											this.onChangeAnswerHandler(selectedOption, element.id);
										}}
									/>
								</Col>
							);
						})}
					</Row>
				</form>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		allSelfTestChapters: allSelfTestChapters(state),
		allUsers: allUsers(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setApiResponse: (theApiResponse) => dispatch(apiResponse(theApiResponse)),
		updateSelfTest: (selfTestData, selfTestId) => dispatch(updateSelfTest(selfTestData, selfTestId)),
		updateSelfTestFail: (error) => dispatch(addNewSelfTestFail(error)),
		updateSelfTestSuccess: (selfTestData) => dispatch(updateSelfTestSuccess(selfTestData)),
	};
};

EditSelfTestForm.propTypes = {
	allSelfTestChapters: PropTypes.arrayOf(PropTypes.exact(SelfTestChapterSelectorShape)),
	allUsers: PropTypes.arrayOf(PropTypes.exact(UserFromSelectorShape)),
	closeModal: PropTypes.func,
	currentSelfTest: PropTypes.exact(SelfTestSelectorShape),
	isModalOpen: PropTypes.bool,
	resetAllFilters: PropTypes.func,
	setApiResponse: PropTypes.func,
	updateSelfTest: PropTypes.func,
	updateSelfTestFail: PropTypes.func,
	updateSelfTestSuccess: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSelfTestForm);
