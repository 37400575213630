import PropTypes from "prop-types";
import { memo } from "react";
import ReactTooltip from "react-tooltip";

import { Col, Row } from "../../Grid/Grid";
import { pdfPageChangeDirection, pdfPageZoomLevel } from "../PdfViewer";

const zoomInThreshold = 3;

const PdfController = memo(
	({
		extraClasses,
		hideFileName,
		onDownloadPdfHandler,
		onPdfPageChangeHandler,
		onPdfPageZoomLevelChangeHandler,
		onPopupPdfHandler,
		pageCount,
		pageNumber,
		pageScale,
		pdfFilename,
	}) => {
		const nextDisabled = pageNumber === pageCount || pageCount === 1;
		const prevDisabled = pageNumber === 1 || pageCount === 1;
		const resetZoomDisabled = pageScale <= 1;
		const zoomInDisabled = pageScale >= zoomInThreshold;

		return (
			<Col>
				<Row
					align="center"
					classes={["pdf-controller-bar", "text-white", "shadow-sm", [extraClasses]]}
				>
					<Col
						xl={3}
						classes={["text-xl-left", "text-center", "mb-xl-0", "mb-1"]}
					>
						<i
							className={`fas fa-search-minus cursor-pointer pdf-controller-icon mr-3 ${resetZoomDisabled ? "cursor-not-allowed" : "cursor-pointer"}`}
							onClick={() => !resetZoomDisabled && onPdfPageZoomLevelChangeHandler(pdfPageZoomLevel.ZOOM_OUT)}
							style={{ opacity: resetZoomDisabled && 0.5 }}
							data-tip="Zoom Out"
							data-for="pdf-controller-tooltip"
						/>
						<i
							className={`fas fa-redo cursor-pointer pdf-controller-icon ml-1 mr-3 ${resetZoomDisabled ? "cursor-not-allowed" : "cursor-pointer"}`}
							onClick={() => !resetZoomDisabled && onPdfPageZoomLevelChangeHandler(pdfPageZoomLevel.RESET_ZOOM)}
							style={{ opacity: resetZoomDisabled && 0.5 }}
							data-tip="Επαναφορά"
							data-for="pdf-controller-tooltip"
						/>
						<i
							className={`fas fa-search-plus cursor-pointer pdf-controller-icon ml-1 ${zoomInDisabled ? "cursor-not-allowed" : "cursor-pointer"}`}
							onClick={() => !zoomInDisabled && onPdfPageZoomLevelChangeHandler(pdfPageZoomLevel.ZOOM_IN)}
							style={{ opacity: zoomInDisabled && 0.5 }}
							data-tip="Zoom In"
							data-for="pdf-controller-tooltip"
						/>
					</Col>
					<Col
						xl={6}
						classes={["text-center", "mt-2", "mt-xl-0", "mb-xl-0", "mb-1"]}
					>
						<i
							className={`fas fa-step-backward pdf-controller-icon mr-2 ${prevDisabled ? "cursor-not-allowed" : "cursor-pointer"}`}
							onClick={() => !prevDisabled && onPdfPageChangeHandler(pdfPageChangeDirection.FIRST)}
							style={{ opacity: prevDisabled && 0.5 }}
							data-tip="Πρώτη Σελίδα"
							data-for="pdf-controller-pages-tooltip"
						/>
						<i
							className={`fas fa-chevron-left pdf-controller-icon mr-3 cursor-pointer ${prevDisabled ? "cursor-not-allowed" : "cursor-pointer"}`}
							onClick={() => !prevDisabled && onPdfPageChangeHandler(pdfPageChangeDirection.PREVIOUS)}
							style={{ opacity: prevDisabled && 0.5 }}
							data-tip="Προηγούμενη Σελίδα"
							data-for="pdf-controller-pages-tooltip"
						/>
						<span className="unselectable">
							Σελίδα <strong>{pageNumber}</strong> από <strong>{pageCount}</strong>
						</span>
						<i
							className={`fas fa-chevron-right pdf-controller-icon ml-3 ${nextDisabled ? "cursor-not-allowed" : "cursor-pointer"}`}
							onClick={() => !nextDisabled && onPdfPageChangeHandler(pdfPageChangeDirection.NEXT)}
							style={{ opacity: nextDisabled && 0.5 }}
							data-tip="Επόμενη Σελίδα"
							data-for="pdf-controller-pages-tooltip"
						/>
						<i
							className={`fas fa-step-forward pdf-controller-icon ml-2 ${nextDisabled ? "cursor-not-allowed" : "cursor-pointer"}`}
							onClick={() => !nextDisabled && onPdfPageChangeHandler(pdfPageChangeDirection.LAST)}
							style={{ opacity: nextDisabled && 0.5 }}
							data-tip="Τελευταία Σελίδα"
							data-for="pdf-controller-pages-tooltip"
						/>
					</Col>
					<Col
						xl={3}
						classes={["text-xl-right", "text-center", "mt-2", "mt-xl-0"]}
					>
						<span
							className={`mr-2 ${hideFileName && "d-none"}`}
							title={pdfFilename}
						>
							{pdfFilename.length > 22 ? pdfFilename.substring(0, 22) + "..." : pdfFilename}
						</span>
						<i
							className="fas fa-download pdf-controller-icon cursor-pointer mr-1"
							onClick={() => onDownloadPdfHandler()}
							data-tip="Λήψη Test"
							data-for="pdf-controller-tooltip"
						/>
						<i
							className="fas fa-external-link-alt pdf-controller-icon cursor-pointer ml-3"
							onClick={() => onPopupPdfHandler()}
							data-tip="Άνοιγμα Test σε νέο Παράθυρο"
							data-for="pdf-controller-popup-tooltip"
							data-place="left"
						/>
					</Col>
					<ReactTooltip
						effect="solid"
						id="pdf-controller-tooltip"
					/>
					<ReactTooltip
						effect="solid"
						id="pdf-controller-pages-tooltip"
					/>
					<ReactTooltip
						effect="solid"
						id="pdf-controller-popup-tooltip"
					/>
				</Row>
			</Col>
		);
	},
);

PdfController.defaultProps = {
	hideFileName: false,
};

PdfController.propTypes = {
	extraClasses: PropTypes.string,
	hideFileName: PropTypes.bool,
	onDownloadPdfHandler: PropTypes.func.isRequired,
	onPdfPageChangeHandler: PropTypes.func.isRequired,
	onPdfPageZoomLevelChangeHandler: PropTypes.func.isRequired,
	onPopupPdfHandler: PropTypes.func.isRequired,
	pageCount: PropTypes.number.isRequired,
	pageNumber: PropTypes.number.isRequired,
	pageScale: PropTypes.number.isRequired,
	pdfFilename: PropTypes.string.isRequired,
};

export default PdfController;
