import { includes, isEmpty, uniqBy } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";

import { StudentFromReduxShape, StudentFromSelectorShape } from "../../../../Models/StudentShape";
import { apiResponse, updateStudent, updateStudentFail, updateStudentSuccess } from "../../../../Redux/Actions/index";
import { allStudents } from "../../../../Redux/Selectors/studentsSelectors";
import { allFetchingFinished } from "../../../../Redux/Selectors/universalSelectors";
import { nullifyEmptyStrings } from "../../../../Utils/NullConversionUtils";
import {
	PANHELLENIC_EXAMINATIONS_ENABLE_EPAL_CLASSES_ID,
	PANHELLENIC_EXAMINATIONS_ENABLE_GEL_CLASSES_ID,
} from "../../../../Utils/ParametricTablesBinds";
import { clone } from "../../../../Utils/Utils";
import Button from "../../../UI/Button/Button";
import Card from "../../../UI/Card/Card";
import Datatable from "../../../UI/Datatable/Datatable";
import Modal from "../../../UI/Modal/Modal";
import ManageEducationalInstitutionAssignments from "./Forms/ManageEducationalInstitutionAssignments";

const columns = [
	{
		name: "Α/Α",
		selector: (row) => row.id,
		sortable: true,
		width: "75px",
	},
	{
		name: "Επώνυμο Μαθητή",
		selector: (row) => row.lastname,
		sortable: true,
		wrap: true,
	},
	{
		name: "Ονομα Μαθητή",
		selector: (row) => row.firstname,
		sortable: true,
		wrap: true,
	},
	{
		name: "Τμήμα",
		selector: (row) => row.department.name,
		sortable: true,
		wrap: true,
	},
	{
		name: "Εκπαιδευτικό Ίδρυμα",
		selector: (row) => row.educational_institution?.description,
		format: (row) => (row.educational_institution ? row.educational_institution.description : ""),
		sortable: true,
		grow: 2,
		wrap: true,
	},
];

class EducationalInstitutions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterText: "",
			filteredData: [],
			isManageEducationInstitutionModalOpen: false,
			isDeleteEducationInstitutionModalOpen: false,
			loading: false,
			selectedRow: [],
		};
		this.toggledClearRows = false;
	}

	componentDidMount() {
		document.title = process.env.REACT_APP_NAME + " :: Εισαγωγή σε Εκπαιδευτικό Ίδρυμα";
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.allFetchingFinished && this.props.allFetchingFinished) {
			this.resetAllFilters();
		}
	}

	selectedRow = (data) => {
		this.setState({ selectedRow: data.selectedRows });
	};

	clearSelectedRows = () => {
		this.toggledClearRows = !this.toggledClearRows;
		this.setState({ selectedRow: [] });
	};

	resetAllFilters = () => {
		this.setState({
			filterText: "",
			filteredData: [],
		});
		this.clearSelectedRows();
	};

	onDeleteHandler = () => {
		const output = clone(this.props.allStateStudents).find((student) => student.id === this.state.selectedRow[0].id);
		output.educational_institution = null;
		output.studentLessons = clone(output.lessons);
		delete output.lessons;
		this.setState({ loading: true });
		this.props
			.updateStudent(nullifyEmptyStrings(output), output.id)
			.then((response) => {
				const student = {
					...response.data,
					lessons: output.studentLessons,
				};
				const apiResponseMsg = {
					error: null,
					info: {
						message: (
							<span>
								Η ανάθεση εκπαιδευτικού ιδρύματος του μαθητή{" "}
								<b>
									{response.data.lastname} {response.data.firstname}
								</b>{" "}
								διαγράφηκε με επιτυχία.
							</span>
						),
					},
				};
				this.props.setApiResponse(apiResponseMsg);
				this.props.updateStudentSuccess(student);
			})
			.catch((error) => {
				const apiResponseMsg = {
					error: error,
					info: null,
				};
				this.props.setApiResponse(apiResponseMsg);
				this.props.updateStudentFail(error);
			})
			.finally(() => {
				this.resetAllFilters();
				this.setState({ laoding: false, isDeleteEducationInstitutionModalOpen: false });
			});
	};

	setFilter = (filterString) => {
		const filteredItems = this.props.allStudents.filter((student) => student.firstname.toLowerCase().includes(filterString.toLowerCase()));
		const filteredItems2 = this.props.allStudents.filter((student) => student.lastname.toLowerCase().includes(filterString.toLowerCase()));
		const filteredItems3 = this.props.allStudents.filter((student) => student.department.name.toLowerCase().includes(filterString.toLowerCase()));
		const filteredItems4 = this.props.allStudents.filter((student) =>
			student.educational_institution?.description.toLowerCase().includes(filterString.toLowerCase()),
		);
		this.setState({
			filterText: filterString,
			filteredData: uniqBy(filteredItems.concat(filteredItems2, filteredItems3, filteredItems4), "id"),
		});
	};

	render() {
		const tableData = this.props.allStudents.filter((student) =>
			includes(PANHELLENIC_EXAMINATIONS_ENABLE_GEL_CLASSES_ID.concat(PANHELLENIC_EXAMINATIONS_ENABLE_EPAL_CLASSES_ID), student.department.class.id),
		);

		return (
			<>
				<Card
					title="Προβολή Καταχωρημένων Μαθητών σε Εκπαιδευτικά Ιδρύματα"
					headerSpannig={[8, 4]}
					subTitle={<div className="text-sm">Αριθμός Καταχωρήσεων: {tableData.filter((data) => data.educational_institution !== null).length}</div>}
					extraOnHeader={
						<>
							<Button
								oval
								type="button"
								iconClass={this.state.selectedRow[0]?.educational_institution ? "fas fa-pencil-alt" : "fas fa-plus"}
								kind={this.state.selectedRow[0]?.educational_institution ? "info" : "primary"}
								onClick={() => this.setState({ isManageEducationInstitutionModalOpen: true })}
								id="btn"
								classes={["m-1"]}
								disabled={this.state.selectedRow.length !== 1}
								data-place="left"
								data-tip={
									this.state.selectedRow[0]?.educational_institution ? "Επεξεργασία Εκπαιδευτικού Ιδρύματος" : "Προσθήκη Εκπαιδευτικού Ιδρύματος"
								}
							/>
							<Button
								oval
								type="button"
								iconClass="fas fa-trash"
								kind="danger"
								onClick={() => this.setState({ isDeleteEducationInstitutionModalOpen: true })}
								id="btn"
								classes={["m-1"]}
								disabled={this.state.selectedRow.length === 0}
								data-place="left"
								data-tip="Διαγραφή Εκπαιδευτικού Ιδρύματος"
							/>
						</>
					}
					id="educationalInstitutionPECard"
				>
					<Datatable
						columns={columns}
						defaultSortFieldId={2}
						exportCsv
						exportFilename="Πίνακας Ανάθεσεις Εκπαιδευτικών Ιδρυμάτων Μαθητολογίου Πολύτροπο"
						filteredData={this.state.filteredData}
						filterText={this.state.filterText}
						onClear={() => {
							this.setState({ filterText: "", filteredData: [] });
							this.clearSelectedRows();
						}}
						onFilter={(e) => {
							this.setFilter(e.target.value);
							this.clearSelectedRows();
						}}
						rowData={isEmpty(this.state.filterText) ? tableData : this.state.filteredData}
						selectable
						selectedRows={this.state.selectedRow}
						setSelectedRow={this.selectedRow}
						toggledClearRows={this.toggledClearRows}
					/>
					{!isEmpty(this.state.selectedRow) && (
						<>
							<ManageEducationalInstitutionAssignments
								student={!isEmpty(this.state.selectedRow) ? this.state.selectedRow[0] : null}
								setApiResponseMessages={this.setApiResponseMessages}
								isModalOpen={this.state.isManageEducationInstitutionModalOpen}
								closeModal={() => this.setState({ isManageEducationInstitutionModalOpen: false })}
								resetAllFilters={this.resetAllFilters}
							/>
							<Modal
								isOpen={this.state.isDeleteEducationInstitutionModalOpen}
								header={
									<>
										<em className="fas fa-trash float-left fa-1_2x mr-3" />
										Επιβεβαίωση Διαγραφής
									</>
								}
								headerBg="danger"
								loading={this.state.loading}
								footer={
									<>
										<Button
											text="Επιβεβαίωση"
											type="button"
											kind="success"
											onClick={this.onDeleteHandler}
										/>
										<Button
											text="Κλείσιμο"
											kind="secondary"
											type="button"
											onClick={() => this.setState({ isDeleteEducationInstitutionModalOpen: false })}
										/>
									</>
								}
								onClose={() => this.setState({ isDeleteEducationInstitutionModalOpen: false })}
							>
								<div>
									<strong>Είστε σίγουρος ότι επιθυμείτε να προβείτε στη διαγραφή της καταχώρησης εκπαιδευτικού ιδρύματος στο μαθητή;</strong>
									<p className="mt-2">
										{" "}
										<i className="fas fa-exclamation-circle mr-1 text-primary fa-120x"></i>Προσοχή: Η ενέργεια δεν θα μπορεί να αναιρεθεί.
									</p>
								</div>
							</Modal>
						</>
					)}
					<ReactTooltip effect="solid" />
				</Card>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		allStudents: allStudents(state),
		allStateStudents: state.students.allStudents,
		allFetchingFinished: allFetchingFinished(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateStudent: (studentData, studentId) => dispatch(updateStudent(studentData, studentId)),
		updateStudentSuccess: (studentData) => dispatch(updateStudentSuccess(studentData)),
		updateStudentFail: (error) => dispatch(updateStudentFail(error)),
		setApiResponse: (theApiResponse) => dispatch(apiResponse(theApiResponse)),
	};
};

EducationalInstitutions.propTypes = {
	allStudents: PropTypes.arrayOf(PropTypes.exact(StudentFromSelectorShape)),
	allStateStudents: PropTypes.arrayOf(PropTypes.exact(StudentFromReduxShape)),
	allFetchingFinished: PropTypes.bool,
	setApiResponse: PropTypes.func,
	updateStudent: PropTypes.func,
	updateStudentSuccess: PropTypes.func,
	updateStudentFail: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(EducationalInstitutions);
