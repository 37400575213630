import { sortArrayOfObjectsByString, updateObject } from "../../Utils/Utils";
import {
	ADD_NEW_STUDENT_FAIL,
	ADD_NEW_STUDENT_START,
	ADD_NEW_STUDENT_SUCCESS,
	AUTH_LOGOUT,
	DELETE_STUDENT_FAIL,
	DELETE_STUDENT_START,
	DELETE_STUDENT_SUCCESS,
	FETCH_ALL_STUDENTS_FAIL,
	FETCH_ALL_STUDENTS_START,
	FETCH_ALL_STUDENTS_SUCCESS,
	FETCH_CURRENT_TEACHER_STUDENTS_FAIL,
	FETCH_CURRENT_TEACHER_STUDENTS_START,
	FETCH_CURRENT_TEACHER_STUDENTS_SUCCESS,
	FETCH_STUDENT_FAIL,
	FETCH_STUDENT_START,
	FETCH_STUDENT_SUCCESS,
	UPDATE_STUDENT_FAIL,
	UPDATE_STUDENT_START,
	UPDATE_STUDENT_SUCCESS,
} from "../Actions/actionTypes";

const initialState = {
	allStudents: [],
	error: null,
	finished: false,
	loading: false,
	success: false,
};

export const fetchStudentStart = (state) => {
	return updateObject(state, {
		error: null,
		finished: false,
		loading: true,
		success: false,
	});
};

const fetchStudentSuccess = (state, action) => {
	action.payload.student.lessons = [];
	const updatedStudentLessons = action.payload.studentLessons.filter((obj) => obj.student_id === action.payload.student.id);
	for (let i in updatedStudentLessons) {
		if (updatedStudentLessons[i].student_id === action.payload.student.id) {
			action.payload.student.lessons.push(updatedStudentLessons[i].lesson_id);
		}
	}

	return updateObject(state, {
		allStudents: [action.payload.student],
		error: null,
		finished: true,
		loading: false,
		success: false,
	});
};

const fetchStudentFail = (state, action) => {
	return updateObject(state, {
		allStudents: [],
		error: action.payload,
		finished: false,
		loading: false,
		success: false,
	});
};

export const fetchStudentsStart = (state) => {
	return updateObject(state, {
		error: null,
		finished: false,
		loading: true,
		success: false,
	});
};

const fetchStudentsSuccess = (state, action) => {
	const output = [];
	if (action.payload.students === null) {
		return updateObject(state, {
			allStudents: [],
			error: null,
			finished: true,
			loading: false,
			success: false,
		});
	}
	//action.payload.students contains only one student
	if (action.payload.user_id !== undefined && action.payload.user_id !== null) {
		action.payload.students.lessons = [];
		const updatedStudentLessons = action.payload.studentLessons.filter((obj) => obj.student_id === action.payload.students.id);
		for (let i in updatedStudentLessons) {
			if (updatedStudentLessons[i].student_id === action.payload.students.id) {
				action.payload.students.lessons.push(updatedStudentLessons[i].lesson_id);
			}
		}
		output.push(action.payload.students);
	} else {
		action.payload.students.forEach((student) => {
			const updatedStudent = { ...student };
			updatedStudent.lessons = [];
			const updatedStudentLessons = action.payload.studentLessons.filter((obj) => obj.student_id === updatedStudent.id);
			for (let i in updatedStudentLessons) {
				if (updatedStudentLessons[i].student_id === updatedStudent.id) {
					updatedStudent.lessons.push(updatedStudentLessons[i].lesson_id);
				}
			}
			output.push(updatedStudent);
		});
	}
	return updateObject(state, {
		allStudents: output,
		error: null,
		finished: true,
		loading: false,
		success: false,
	});
};

const fetchStudentsFail = (state, action) => {
	return updateObject(state, {
		allStudents: [],
		error: action.payload,
		finished: false,
		loading: false,
		success: false,
	});
};

const addNewStudentStart = (state) => {
	return updateObject(state, {
		error: null,
		loading: true,
		success: false,
	});
};

const addNewStudentSuccess = (state, action) => {
	const updatedStudents = [...state.allStudents];
	const newStudent = { ...action.payload };
	updatedStudents.push(newStudent);
	sortArrayOfObjectsByString(updatedStudents, "lastname");
	return updateObject(state, {
		allStudents: updatedStudents,
		error: null,
		loading: false,
		success: true,
	});
};

const addNewStudentFail = (state, action) => {
	return updateObject(state, {
		error: action.payload,
		loading: false,
		success: false,
	});
};

const updateStudentStart = (state) => {
	return updateObject(state, {
		error: null,
		loading: true,
		success: false,
	});
};

const updateStudentSuccess = (state, action) => {
	const updatedStudents = [...state.allStudents].filter((obj) => obj.id !== action.payload.id);
	const newStudent = { ...action.payload };
	updatedStudents.push(newStudent);
	sortArrayOfObjectsByString(updatedStudents, "lastname");
	return updateObject(state, {
		allStudents: updatedStudents,
		error: null,
		loading: false,
		success: true,
	});
};

const updateStudentFail = (state, action) => {
	return updateObject(state, {
		error: action.payload,
		loading: false,
		success: false,
	});
};

const deleteStudentStart = (state) => {
	return updateObject(state, {
		error: null,
		loading: true,
		success: false,
	});
};

const deleteStudentSuccess = (state, action) => {
	const updatedStudents = [...state.allStudents].filter((obj) => obj.id !== action.payload.id);
	return updateObject(state, {
		allStudents: updatedStudents,
		error: null,
		loading: false,
		success: true,
	});
};

const deleteStudentFail = (state, action) => {
	return updateObject(state, {
		error: action.payload,
		loading: false,
		success: false,
	});
};

const authLogout = (state) => {
	return updateObject(state, initialState);
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_STUDENT_START:
			return fetchStudentStart(state);
		case FETCH_STUDENT_FAIL:
			return fetchStudentFail(state, action);
		case FETCH_STUDENT_SUCCESS:
			return fetchStudentSuccess(state, action);
		case FETCH_ALL_STUDENTS_START:
			return fetchStudentsStart(state);
		case FETCH_ALL_STUDENTS_FAIL:
			return fetchStudentsFail(state, action);
		case FETCH_ALL_STUDENTS_SUCCESS:
			return fetchStudentsSuccess(state, action);
		case FETCH_CURRENT_TEACHER_STUDENTS_START:
			return fetchStudentsStart(state);
		case FETCH_CURRENT_TEACHER_STUDENTS_FAIL:
			return fetchStudentsFail(state, action);
		case FETCH_CURRENT_TEACHER_STUDENTS_SUCCESS:
			return fetchStudentsSuccess(state, action);
		case ADD_NEW_STUDENT_START:
			return addNewStudentStart(state);
		case ADD_NEW_STUDENT_FAIL:
			return addNewStudentFail(state, action);
		case ADD_NEW_STUDENT_SUCCESS:
			return addNewStudentSuccess(state, action);
		case DELETE_STUDENT_START:
			return deleteStudentStart(state);
		case DELETE_STUDENT_FAIL:
			return deleteStudentFail(state, action);
		case DELETE_STUDENT_SUCCESS:
			return deleteStudentSuccess(state, action);
		case UPDATE_STUDENT_START:
			return updateStudentStart(state);
		case UPDATE_STUDENT_FAIL:
			return updateStudentFail(state, action);
		case UPDATE_STUDENT_SUCCESS:
			return updateStudentSuccess(state, action);
		case AUTH_LOGOUT:
			return authLogout(state);
		default:
			return state;
	}
};

export default reducer;
