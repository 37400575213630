import PropTypes from "prop-types";
import React from "react";
import ReactTooltip from "react-tooltip";

import BaseInput from "../BaseInput/BaseInput";
import { Col } from "../Grid/Grid";

const FormColumnField = React.memo(
	({
		classes,
		clearable,
		datepicker,
		disabled,
		help_text,
		inputType,
		label,
		name,
		onChange,
		options,
		placeholder,
		required,
		searchable,
		select_multiple,
		span,
		style,
		value,
	}) => {
		return (
			<Col
				classes={classes}
				lg={span?.lg ?? null}
				md={span?.md ?? null}
				sm={span?.sm ?? null}
				style={style}
				xl={span?.xl ?? null}
			>
				<label className="unselectable">
					{label} {required ? <span className="reqField">(*)</span> : null}
					{help_text && (
						<i
							className="fas fa-info-circle ml-1"
							data-for="form-column-help-text"
							data-tip={help_text}
							style={{ fontSize: "110%" }}
						/>
					)}
				</label>
				<BaseInput
					clearable={clearable}
					datepicker={datepicker}
					disabled={disabled}
					name={name}
					onChange={onChange}
					options={options}
					placeholder={placeholder}
					searchable={searchable}
					select_multiple={select_multiple}
					type={inputType ? inputType : "text"}
					value={value}
				/>
				{help_text && (
					<ReactTooltip
						effect="solid"
						id="form-column-help-text"
						place="right"
					/>
				)}
			</Col>
		);
	},
);

FormColumnField.propTypes = {
	classes: PropTypes.array,
	clearable: PropTypes.bool,
	datepicker: PropTypes.bool,
	disabled: PropTypes.bool,
	help_text: PropTypes.string,
	inputType: PropTypes.string,
	label: PropTypes.string,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	options: PropTypes.array,
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	searchable: PropTypes.bool,
	select_multiple: PropTypes.bool,
	size: PropTypes.oneOf(["sm", "md", "lg", "xl"]),
	span: PropTypes.object,
	style: PropTypes.object,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.array]),
};

export default FormColumnField;
