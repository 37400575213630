import { isEmpty, uniqBy } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import { SelfTestAnswersSelectorShape } from "../../../Models/SelfTestAnswersShape";
import { getStudentSelfTestPath } from "../../../navigation";
import { allSelfTestAnswers } from "../../../Redux/Selectors/selfTestSelectors";
import { roundNumber } from "../../../Utils/Utils";
import Button from "../../UI/Button/Button";
import Card from "../../UI/Card/Card";
import Datatable from "../../UI/Datatable/Datatable";
import { Col, Row } from "../../UI/Grid/Grid";

const columns = [
	{
		name: "Α/Α",
		selector: (row) => row.id,
		sortable: true,
		width: "75px",
	},
	{
		name: "Μάθημα",
		selector: (row) => row.self_test.chapter.lesson.description,
		sortable: true,
		wrap: true,
	},
	{
		name: "Ενότητα",
		selector: (row) => row.self_test.chapter.name,
		sortable: true,
		wrap: true,
	},
	{
		name: "Καθηγητής",
		selector: (row) => row.self_test.user.lastname + " " + row.self_test.user.firstname,
		sortable: true,
		wrap: true,
	},
	{
		name: "Βαθμός",
		selector: (row) => row.self_test_grade,
		format: (row) => {
			const classNames = (() => {
				if (row.self_test_grade >= 50) return "text-success text-bold";
				else return "text-danger text-bold";
			})();
			return <span className={classNames}>{row.self_test_grade}%</span>;
		},
		sortable: true,
		center: true,
	},
	{
		name: "Εξετάστηκε",
		selector: (row) => row.created_at,
		format: (row) => (row.created_at ? moment(row.created_at).format("ddd, DD/MM/YYYY HH:mm:ss") : ""),
		sortable: true,
		wrap: true,
	},
];

const statsColumns = [
	{
		name: "Μάθημα",
		selector: (row) => row.lesson.description,
		sortable: true,
		wrap: true,
		grow: 2,
	},
	{
		name: "Πλήθος Βαθμολογιών",
		selector: (row) => row.gradeCount,
		sortable: true,
	},
	{
		name: "Μέσος Όρος",
		selector: (row) => row.average,
		format: (row) => {
			const classNames = (() => {
				if (row.average >= 50) return "text-success text-bold";
				else return "text-danger text-bold";
			})();
			return <span className={classNames}>{row.average}%</span>;
		},
		sortable: true,
	},
	{
		name: "Μεγαλύτερος",
		selector: (row) => row.max,
		format: (row) => row.max + "%",
		sortable: true,
	},
	{
		name: "Μικρότερος",
		selector: (row) => row.min,
		format: (row) => row.min + "%",
		sortable: true,
	},
];

class SelfTestResults extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedRow: [],
			filterText: "",
			filteredData: [],
			statsFilterText: "",
			statsFilteredData: [],
		};
		this.toggledClearRows = false;
	}

	componentDidMount() {
		document.title = process.env.REACT_APP_NAME + " :: Προβολη Αποτελεσμάτων Test Αυτοαξιολόγησης";
	}

	selectedRow = (data) => {
		this.setState({ selectedRow: data.selectedRows });
	};

	clearSelectedRows = () => {
		this.toggledClearRows = !this.toggledClearRows;
		this.setState({ selectedRow: [] });
	};

	setFilter = (filterString) => {
		const filteredItems = this.props.allSelfTestAnswers.filter((sta) =>
			sta.self_test.chapter.lesson.description.toLowerCase().includes(filterString.toLowerCase()),
		);
		const filteredItems2 = this.props.allSelfTestAnswers.filter((sta) =>
			sta.self_test.chapter.name.toLowerCase().includes(filterString.toLowerCase()),
		);
		const filteredItems3 = this.props.allSelfTestAnswers.filter((sta) => {
			return (
				`${sta.self_test.user.lastname} ${sta.self_test.user.firstname}`.toLowerCase().includes(filterString.toLowerCase()) ||
				`${sta.self_test.user.firstname} ${sta.self_test.user.lastname}`.toLowerCase().includes(filterString.toLowerCase())
			);
		});
		this.setState({
			filterText: filterString,
			filteredData: uniqBy(filteredItems.concat(filteredItems2, filteredItems3), "id"),
		});
	};

	setStatsFilter = (filterString) => {
		const stats = this.getSelfTestResultPerLesson();
		const filteredItems = stats.filter((stat) => stat.lesson.description.toLowerCase().includes(filterString.toLowerCase()));
		this.setState({
			statsFilterText: filterString,
			statsFilteredData: uniqBy(filteredItems, "stat.lesson.id"),
		});
	};

	getSelfTestResultPerLesson = () => {
		if (isEmpty(this.props.allSelfTestAnswers)) return [];
		const output = [];
		const uniqueLessons = uniqBy(
			this.props.allSelfTestAnswers.map((sta) => {
				return sta.self_test;
			}),
			"chapter.lesson_id",
		).map((sta) => sta.chapter.lesson);
		uniqueLessons.forEach((lesson) => {
			let sum = 0;
			let cnt = 0;
			let max = 0;
			let min = 100;
			const filteredResults = this.props.allSelfTestAnswers.filter((sta) => sta.self_test.chapter.lesson.id === lesson.id);
			filteredResults.forEach((staResult) => {
				sum += staResult.self_test_grade;
				cnt++;
				if (staResult.self_test_grade > max) {
					max = staResult.self_test_grade;
				}
				if (staResult.self_test_grade < min) {
					min = staResult.self_test_grade;
				}
			});
			output.push({
				lesson: lesson,
				average: roundNumber(sum / cnt, 2),
				max: max,
				min: min,
				gradeCount: cnt,
			});
		});
		return output;
	};

	render() {
		this.getSelfTestResultPerLesson();
		return (
			<Row>
				<Col>
					<Card
						title="Προβολή Αποτελεσμάτων Test Αυτοαξιολόγησης"
						headerSpannig={[8, 4]}
						subTitle={<div className="text-sm">Αριθμός Καταχωρήσεων: {this.props.allSelfTestAnswers.length}</div>}
						extraOnHeader={
							<>
								<Button
									oval
									type="button"
									iconClass="fas fa-eye"
									kind="green"
									onClick={() => this.props.history.push(getStudentSelfTestPath(this.state.selectedRow[0].self_test_id))}
									id="btn"
									classes={["m-1"]}
									disabled={this.state.selectedRow.length === 0}
									data-tip="Προβολή Test"
								/>
							</>
						}
						id="selfTestCard"
					>
						<Datatable
							columns={columns}
							defaultSortFieldId={6}
							defaultSortAsc={false}
							exportCsv
							exportFilename="Πίνακας Βαθμολογιών Test Αυτοαξιολόγησης Πολύτροπο"
							filteredData={this.state.filteredData}
							filterText={this.state.filterText}
							onClear={() => {
								this.setState({ filterText: "", filteredData: [] });
								this.clearSelectedRows();
							}}
							onFilter={(e) => {
								this.setFilter(e.target.value);
								this.clearSelectedRows();
							}}
							rowData={isEmpty(this.state.filterText) ? this.props.allSelfTestAnswers : this.state.filteredData}
							selectable
							selectedRows={this.state.selectedRow}
							setSelectedRow={this.selectedRow}
							toggledClearRows={this.toggledClearRows}
						/>
						<ReactTooltip effect="solid" />
					</Card>
				</Col>
				<Col>
					<Card
						title="Μέσος Όρος / Μάθημα"
						headerSpannig={[8, 4]}
						extraOnHeader={<i className="fas fa-chart-area fa-2x"></i>}
						id="averagePerLesson"
					>
						<Datatable
							columns={statsColumns}
							defaultSortFieldId={2}
							defaultSortAsc={false}
							exportCsv
							exportFilename="Στατιστικά ανά Μάθημα Test Αυτοαξιολόγησης Πολύτροπο"
							filteredData={this.state.statsFilteredData}
							filterText={this.state.statsFilterText}
							onClear={() => {
								this.setState({ statsFilterText: "", statsFilteredData: [] });
							}}
							onFilter={(e) => {
								this.setStatsFilter(e.target.value);
							}}
							rowData={isEmpty(this.state.statsFilterText) ? this.getSelfTestResultPerLesson() : this.state.statsFilteredData}
						/>
						<ReactTooltip effect="solid" />
					</Card>
				</Col>
			</Row>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		allSelfTestAnswers: allSelfTestAnswers(state),
	};
};

SelfTestResults.propTypes = {
	allSelfTestAnswers: PropTypes.arrayOf(PropTypes.exact(SelfTestAnswersSelectorShape)),
};

export default withRouter(connect(mapStateToProps)(SelfTestResults));
