import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";

import { globalStore } from "../../../../globalStore";
import { ScientificFieldLessonsFromSelectorShape } from "../../../../Models/ScientificFieldLessonsShape";
import {
	apiResponse,
	deleteScientificFieldLessons,
	deleteScientificFieldLessonsFail,
	deleteScientificFieldLessonsSuccess,
} from "../../../../Redux/Actions/index";
import { allScientificFieldLessons } from "../../../../Redux/Selectors/scientificFieldLessonsSelectors";
import Button from "../../../UI/Button/Button";
import Card from "../../../UI/Card/Card";
import { Col, Row } from "../../../UI/Grid/Grid";
import { MODAL_SIZE } from "../../../UI/Modal/constants/ModalSize";
import Modal from "../../../UI/Modal/Modal";
import Tag from "../../../UI/Tag/Tag";
import AddScientificFieldLessonsForm from "./Forms/AddScientificFieldLessonsForm";

const ScientificFieldLessonsAssignments = ({
	allScientificFieldLessons,
	deleteScientificFieldLessons,
	deleteScientificFieldLessonsFail,
	deleteScientificFieldLessonsSuccess,
	setApiResponse,
}) => {
	const [selectedScientificFieldLessons, setSelectedScientificFieldLessons] = useState([]);
	const [selectedSFId, setSelectedSFId] = useState(null);
	const [loading, setLoading] = useState(false);
	const [isAddLessonAssignmentsModalOpen, setIsAddLessonAssignmentsModalOpen] = useState(false);
	const [isDeleteLessonsModalOpen, setIsDeleteLessonsModalOpen] = useState(false);
	const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

	useEffect(() => {
		document.title = process.env.REACT_APP_NAME + " :: Διαχείριση Αναθέσεων Μαθημάτων σε Επιστημονικά Πεδία";
	}, []);

	const onDeleteHandler = () => {
		setLoading(true);
		deleteScientificFieldLessons(selectedSFId !== null ? selectedSFId : null)
			.then((response) => {
				setApiResponse({
					error: null,
					info: {
						message: (
							<>
								Οι αναθέσεις του επιστημονικού πεδίου <b>{globalStore.scientific_fields.find((sf) => sf.id === selectedSFId).description}</b>{" "}
								διαγράφηκαν με επιτυχία.
							</>
						),
					},
				});
				deleteScientificFieldLessonsSuccess(response.data);
			})
			.catch((error) => {
				setApiResponse({
					error: error,
					info: null,
				});
				deleteScientificFieldLessonsFail(error);
			})
			.finally(() => {
				setLoading(false);
				setIsDeleteLessonsModalOpen(false);
			});
	};

	return (
		<>
			<Card
				title="Προβολή Καταχωρημένων Αναθέσεων Μαθημάτων σε Επιστημονικά Πεδία"
				headerSpannig={[10, 2]}
				extraOnHeader={
					<>
						<Button
							type="button"
							iconClass="fas fa-question"
							kind="secondary"
							onClick={() => setIsHelpModalOpen(true)}
							id="btn"
							classes={["m-1"]}
							data-tip="Βοήθεια"
						/>
					</>
				}
				id="scientificFieldLessonsAssignmentCard"
			>
				{globalStore.scientific_fields.map((sf, idx) => {
					const sfLessons = allScientificFieldLessons.filter((sfLesson) => sfLesson.scientific_field_id === sf.id);
					return (
						<Row
							classes={["py-2"]}
							align="center"
							key={idx}
						>
							<Col
								xl={2}
								lg={3}
								classes={["my-auto"]}
							>
								{sf.description}
							</Col>
							<Col
								xl={10}
								lg={9}
								classes={["my-auto"]}
							>
								<table className="table table-sm">
									<tbody>
										<tr>
											<td
												className="p-0"
												style={{ width: "76px", border: "none" }}
											>
												<Button
													classes={["mr-1", "btn-sm"]}
													type="button"
													iconClass={"fas " + (!isEmpty(sfLessons) ? "fa-pencil-alt" : "fa-plus")}
													kind="primary"
													onClick={() => {
														setSelectedScientificFieldLessons(sfLessons);
														setSelectedSFId(sf.id);
														setIsAddLessonAssignmentsModalOpen(true);
													}}
													data-tip={isEmpty(sfLessons) ? "Προσθήκη Μαθημάτων" : "Επεξεργασία Αναθέσεων"}
												/>
												<Button
													classes={["btn-sm"]}
													type="button"
													iconClass="fas fa-trash"
													kind="danger"
													disabled={isEmpty(sfLessons)}
													onClick={() => {
														setSelectedScientificFieldLessons(sfLessons);
														setSelectedSFId(sf.id);
														setIsDeleteLessonsModalOpen(true);
													}}
													data-tip={"Διαγραφή Αναθέσεων"}
												/>
											</td>
											<td
												className="bg-light pl-2"
												style={{ border: "1px solid #eee" }}
											>
												{sfLessons.map((sfLesson, sfidx) => (
													<Tag key={"tag_" + sfidx}>
														{sfLesson.lesson.description} ({sfLesson.calculation_weight})
													</Tag>
												))}
											</td>
										</tr>
									</tbody>
								</table>
							</Col>
						</Row>
					);
				})}
				<ReactTooltip effect="solid" />
			</Card>
			<Modal
				isOpen={isDeleteLessonsModalOpen}
				header={
					<>
						<em className="fas fa-trash float-left fa-1_2x mr-3" />
						Επιβεβαίωση Διαγραφής
					</>
				}
				headerBg="danger"
				loading={loading}
				onClose={() => setIsDeleteLessonsModalOpen(false)}
				footer={
					<>
						<Button
							text="Επιβεβαίωση"
							type="button"
							kind="success"
							onClick={onDeleteHandler}
						/>
						<Button
							text="Κλείσιμο"
							kind="secondary"
							type="button"
							onClick={() => setIsDeleteLessonsModalOpen(false)}
						/>
					</>
				}
			>
				<div>
					<strong>Είστε σίγουρος ότι επιθυμείτε να προβείτε στη διαγραφή των αναθέσεων επιστημονικών πεδίων;</strong>
					<p className="mt-2">
						{" "}
						<i className="fas fa-exclamation-circle mr-1 text-primary fa-120x"></i>Προσοχή: Η ενέργεια δεν θα μπορεί να αναιρεθεί.
					</p>
				</div>
			</Modal>

			<Modal
				isOpen={isHelpModalOpen}
				header={
					<>
						<em className="fas fa-question float-left fa-1_2x mr-3" />
						Πληροφορίες
					</>
				}
				size={MODAL_SIZE.lg}
				onClose={() => setIsHelpModalOpen(false)}
				footer={
					<Button
						text="Κλείσιμο"
						kind="secondary"
						type="button"
						onClick={() => setIsHelpModalOpen(false)}
					/>
				}
			>
				<ul className="mb-0">
					<li className="mb-2">
						Σε αυτή τη σελίδα παρέχεται η δυνατότητα ανάθεσης μαθημάτων σε όλα τα επιστημονικά πεδία που έχουν αποθηκευτεί στο σύστημα.
					</li>
					<li className="mb-2">
						Με τη χρήση του εικονιδίου <i className="far fa-plus-square text-primary ml-1"></i> εμφανίζεται ένα νέο παράθυρο που περιέχει όλα τα
						μαθήματα. Από εκεί μπορείτε να τα επιλέξετε και να καταχωρήσετε τον συντελεστή βαρύτητας που αντιστοιχεί στο κάθε ένα. Τέλος, πατώντας το
						κουμπί <b>"Αποθήκευση"</b>, ολοκληρώνεται η διαδικασία.
					</li>
					<li>
						Με τη χρήση του εικονιδίου <i className="fas fa-trash text-danger ml-1"></i> μπορείτε να διαγράψετε όλες τις αναθέσεις που έχουν γίνει.
						Πατώντας το κουμπί <b>"Επιβεβαίωση"</b>, ολοκληρώνεται η διαδικασία.
					</li>
				</ul>
			</Modal>
			<AddScientificFieldLessonsForm
				selectedScientificFieldLessons={selectedScientificFieldLessons}
				selectedSFId={selectedSFId}
				allScientificFieldLessons={allScientificFieldLessons}
				isModalOpen={isAddLessonAssignmentsModalOpen}
				closeModal={() => setIsAddLessonAssignmentsModalOpen(false)}
			/>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		allScientificFieldLessons: allScientificFieldLessons(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		deleteScientificFieldLessons: (scientific_field_id) => dispatch(deleteScientificFieldLessons(scientific_field_id)),
		deleteScientificFieldLessonsSuccess: (scientificFieldLessonsData) => dispatch(deleteScientificFieldLessonsSuccess(scientificFieldLessonsData)),
		deleteScientificFieldLessonsFail: (error) => dispatch(deleteScientificFieldLessonsFail(error)),
		setApiResponse: (theApiResponse) => dispatch(apiResponse(theApiResponse)),
	};
};

ScientificFieldLessonsAssignments.propTypes = {
	allScientificFieldLessons: PropTypes.arrayOf(PropTypes.exact(ScientificFieldLessonsFromSelectorShape)),
	deleteScientificFieldLessons: PropTypes.func,
	deleteScientificFieldLessonsSuccess: PropTypes.func,
	deleteScientificFieldLessonsFail: PropTypes.func,
	setApiResponse: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScientificFieldLessonsAssignments);
