import { isEqual } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";

import { globalStore } from "../../../../../globalStore";
import { StudentFromReduxShape, StudentFromSelectorShape } from "../../../../../Models/StudentShape";
import { apiResponse, updateStudent, updateStudentFail, updateStudentSuccess } from "../../../../../Redux/Actions/index";
import { nullifyEmptyStrings } from "../../../../../Utils/NullConversionUtils";
import { clone } from "../../../../../Utils/Utils";
import BaseInput from "../../../../UI/BaseInput/BaseInput";
import { Col, Row } from "../../../../UI/Grid/Grid";
import { MODAL_SIZE } from "../../../../UI/Modal/constants/ModalSize";
import Modal from "../../../../UI/Modal/Modal";

class ManageEducationalInstitutionAssignments extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			selectedEI: null,
		};
	}

	componentDidMount() {
		this.setInitData();
	}

	componentDidUpdate(prevProps) {
		if (!isEqual(prevProps.student, this.props.student)) {
			this.setInitData();
		}
	}

	setInitData = () => {
		if (this.props.student.educational_institution) {
			this.setState({
				selectedEI: {
					value: this.props.student.educational_institution.id,
					label: this.props.student.educational_institution.description,
				},
			});
		} else {
			this.setState({ selectedEI: null });
		}
	};

	onChangeHandler = (selectedOption) => {
		this.setState({ selectedEI: selectedOption });
	};

	isDirty = () => {
		return this.state.selectedEI?.value !== this.props.student.educational_institution?.id;
	};

	onFormSubmit = () => {
		this.setState({ loading: true });
		const output = clone(this.props.allStateStudents).find((student) => student.id === this.props.student.id);
		output.educational_institution = this.state.selectedEI.value;
		output.studentLessons = clone(output.lessons);
		delete output.lessons;

		this.props
			.updateStudent(nullifyEmptyStrings(output), output.id)
			.then((response) => {
				const student = {
					...response.data,
					lessons: output.studentLessons,
				};
				const apiResponseMsg = {
					error: null,
					info: {
						message: (
							<>
								Ο μαθητής{" "}
								<b>
									{response.data.lastname} {response.data.firstname}
								</b>{" "}
								ενημερώθηκε με επιτυχία.
							</>
						),
					},
				};
				this.props.setApiResponse(apiResponseMsg);
				this.props.updateStudentSuccess(student);
			})
			.catch((error) => {
				const apiResponseMsg = {
					error: error,
					info: null,
				};
				this.props.setApiResponse(apiResponseMsg);
				this.props.updateStudentFail(error);
			})
			.finally(() => {
				this.setState({ loading: false });
				this.props.resetAllFilters();
				this.props.closeModal();
			});
	};

	render() {
		const datas = globalStore.educational_institutions.map((ei) => {
			return { value: ei.id, label: ei.description };
		});

		return (
			<Modal
				isOpen={this.props.isModalOpen}
				header={
					this.props.student.educational_institution ? (
						<>
							<em className="fas fa-pencil-alt float-left fa-1_2x mr-3" />
							Επεξεργασία Ανάθεσης Εκπαιδευτικού Ιδρύματος σε Μαθητή
						</>
					) : (
						<>
							<em className="fas fa-plus float-left fa-1_2x mr-3" />
							Προσθήκη Ανάθεσης Εκπαιδευτικού Ιδρύματος σε Μαθητή
						</>
					)
				}
				headerBg={this.props.student.educational_institution ? "info" : "primary"}
				onSubmit={this.onFormSubmit}
				submitDisabled={!this.isDirty()}
				onClose={this.props.closeModal}
				size={MODAL_SIZE.lg}
				loading={this.state.loading}
			>
				<Row>
					<Col classes={["mb-3"]}>
						<label>Όνομα Μαθητή</label>
						<BaseInput
							name="studentName"
							value={`${this.props.student.lastname} ${this.props.student.firstname} (${this.props.student.department.name})`}
							disabled={true}
						/>
					</Col>
					<Col>
						<label>Επιλογή Εκπαιδευτικού Ιδρύματος</label>
						<Select
							value={
								this.state.selectedEI
									? this.state.selectedEI
									: this.props.student.educational_institution
									? {
											value: this.props.student.educational_institution.id,
											label: this.props.student.educational_institution.description,
									  }
									: null
							}
							onChange={this.onChangeHandler}
							options={datas}
							placeholder="Επιλέξτε Εκπαιδευτικό Ίδρυμα"
						/>
					</Col>
				</Row>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		allStateStudents: state.students.allStudents,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setApiResponse: (theApiResponse) => dispatch(apiResponse(theApiResponse)),
		updateStudent: (studentData, studentId) => dispatch(updateStudent(studentData, studentId)),
		updateStudentFail: (error) => dispatch(updateStudentFail(error)),
		updateStudentSuccess: (studentData) => dispatch(updateStudentSuccess(studentData)),
	};
};

ManageEducationalInstitutionAssignments.propTypes = {
	allStateStudents: PropTypes.arrayOf(PropTypes.exact(StudentFromReduxShape)),
	isModalOpen: PropTypes.bool,
	resetAllFilters: PropTypes.func,
	setApiResponse: PropTypes.func,
	setApiResponseMessages: PropTypes.func,
	student: PropTypes.exact(StudentFromSelectorShape),
	updateStudent: PropTypes.func,
	updateStudentFail: PropTypes.func,
	updateStudentSuccess: PropTypes.func,
	closeModal: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageEducationalInstitutionAssignments);
