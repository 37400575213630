import axios from "axios";
import { deleteResource, getResource, postResource, putResource } from "../../BackendAPI/AbstractCRUDActions";
import { ActionKinds } from "../../BackendAPI/ActionKinds";
import { getShortcutLessonsUrl, getShortcutsUrl } from "../../BackendAPI/BackendAPI";
import {
	ADD_NEW_SHORTCUT_FAIL,
	ADD_NEW_SHORTCUT_START,
	ADD_NEW_SHORTCUT_SUCCESS,
	DELETE_SHORTCUT_FAIL,
	DELETE_SHORTCUT_START,
	DELETE_SHORTCUT_SUCCESS,
	FETCH_ALL_SHORTCUTS_FAIL,
	FETCH_ALL_SHORTCUTS_START,
	FETCH_ALL_SHORTCUTS_SUCCESS,
	UPDATE_SHORTCUT_FAIL,
	UPDATE_SHORTCUT_START,
	UPDATE_SHORTCUT_SUCCESS,
} from "./actionTypes";

export const fetchAllShortcutsStart = () => {
	return {
		type: FETCH_ALL_SHORTCUTS_START,
	};
};

export const fetchAllShortcutsSuccess = (shortcutsData) => {
	return {
		type: FETCH_ALL_SHORTCUTS_SUCCESS,
		payload: shortcutsData,
	};
};

export const fetchAllShortcutsFail = (error) => {
	return {
		type: FETCH_ALL_SHORTCUTS_FAIL,
		payload: error,
	};
};

export const fetchAllShortcuts = () => {
	return (dispatch) => {
		dispatch(fetchAllShortcutsStart());
		axios
			.all([getResource(getShortcutsUrl(ActionKinds.GET)), getResource(getShortcutLessonsUrl(ActionKinds.GET))])
			.then(
				axios.spread((shortcutsData, shortcutLessonsData) => {
					dispatch(
						fetchAllShortcutsSuccess({
							shortcuts: shortcutsData.data,
							shortcutLessons: shortcutLessonsData.data,
						}),
					);
				}),
			)
			.catch((error) => {
				dispatch(fetchAllShortcutsFail(error));
			});
	};
};

export const addNewShortcutStart = () => {
	return {
		type: ADD_NEW_SHORTCUT_START,
	};
};

export const addNewShortcutSuccess = (newShortcutData) => {
	return {
		type: ADD_NEW_SHORTCUT_SUCCESS,
		payload: newShortcutData,
	};
};

export const addNewShortcutFail = (error) => {
	return {
		type: ADD_NEW_SHORTCUT_FAIL,
		payload: error,
	};
};

export const addNewShortcut = (newShortcutData) => {
	return (dispatch) => {
		dispatch(addNewShortcutStart());
		const url = getShortcutsUrl(ActionKinds.POST);
		return postResource(url, newShortcutData);
	};
};

export const updateShortcutStart = () => {
	return {
		type: UPDATE_SHORTCUT_START,
	};
};

export const updateShortcutSuccess = (shortcutData) => {
	return {
		type: UPDATE_SHORTCUT_SUCCESS,
		payload: shortcutData,
	};
};

export const updateShortcutFail = (error) => {
	return {
		type: UPDATE_SHORTCUT_FAIL,
		payload: error,
	};
};

export const updateShortcut = (shortcutData, shortcutId) => {
	return (dispatch) => {
		dispatch(updateShortcutStart());
		const url = getShortcutsUrl(ActionKinds.UPDATE, shortcutId);
		return putResource(url, shortcutData);
	};
};

export const deleteShortcutStart = () => {
	return {
		type: DELETE_SHORTCUT_START,
	};
};

export const deleteShortcutSuccess = (shortcutData) => {
	return {
		type: DELETE_SHORTCUT_SUCCESS,
		payload: shortcutData,
	};
};

export const deleteShortcutFail = (error) => {
	return {
		type: DELETE_SHORTCUT_FAIL,
		payload: error,
	};
};

export const deleteShortcut = (shortcutId) => {
	return (dispatch) => {
		dispatch(deleteShortcutStart());
		const url = getShortcutsUrl(ActionKinds.DELETE, shortcutId);
		return deleteResource(url);
	};
};
