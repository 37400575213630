import { isEmpty } from "lodash";
import { globalStore, listConstants } from "../../globalStore";

export const allSelfTestChapters = (state) => {
	if (!state.parametrics.finished) {
		return [];
	}

	const output = state.selfTestChapters.allSelfTestChapters.map((stc) => {
		const updatedStc = { ...stc };
		updatedStc.lesson = globalStore.lessons.filter((lesson) => lesson.id === stc.lesson_id)[0];
		updatedStc.self_test_count = state.selfTests.allSelfTests.filter((st) => st.chapter_id === stc.id).length;
		return updatedStc;
	});
	return output;
};

export const allSelfTest = (state) => {
	if (!state.parametrics.finished || isEmpty(state.selfTestChapters.allSelfTestChapters) || isEmpty(state.users.allUsers)) {
		return [];
	}

	const output = state.selfTests.allSelfTests.map((st) => {
		const updatedSt = { ...st };
		const chapter = (() => {
			const tempChapter = state.selfTestChapters.allSelfTestChapters.find((chapter) => chapter.id === updatedSt.chapter_id);
			tempChapter.lesson = globalStore.lessons.find((lesson) => lesson.id === tempChapter.lesson_id);
			return tempChapter;
		})();
		updatedSt.class = globalStore.classes.find((the_class) => the_class.id === updatedSt.class);
		updatedSt.status = listConstants.status.find((status) => status.id === updatedSt.status);
		updatedSt.chapter = chapter;
		updatedSt.user = state.users.allUsers.find((user) => user.id === st.user_id);
		return updatedSt;
	});
	return output;
};

export const allSelfTestAnswers = (state) => {
	if (
		!state.parametrics.finished ||
		isEmpty(state.selfTestChapters.allSelfTestChapters) ||
		isEmpty(state.selfTests.allSelfTests) ||
		isEmpty(state.students.allStudents)
	) {
		return [];
	}

	const allStateSelfTests = allSelfTest(state);

	const output = state.selfTestAnswers.allSelfTestAnswers.map((sta) => {
		const updatedSTA = { ...sta };
		updatedSTA.self_test = allStateSelfTests.find((selfTest) => selfTest.id === sta.self_test_id);
		updatedSTA.student = state.students.allStudents.find((student) => student.id === sta.student_id);
		return updatedSTA;
	});
	return output;
};
