export const globalStore = {
	absence_kind: [],
	classes: [],
	exam_kind: [],
	informed_by: [],
	scientific_fields: [],
	lessons: [],
	design_lessons: [],
	foreign_language_lessons: [],
	educational_institutions: [],
	parametricsGreekNameMapping: [],
};

export const listConstants = {
	permissions: [],
	status: [],
};

window.globalStore = globalStore;
window.listConstants = listConstants;
