import ManageStudentForm from "./ManageStudentForm";
import { withRouter } from "react-router-dom";

export const STUDENT_FORM_MODES = {
	ADD: "add",
	EDIT: "edit",
};

const manageStudentFormWrapper = (props) => {
	return <ManageStudentForm mode={props.match.params.student_id ? STUDENT_FORM_MODES.EDIT : STUDENT_FORM_MODES.ADD} />;
};

export default withRouter(manageStudentFormWrapper);
