export const USER_STATUS_DISABLE = 1;

export const PARAMETRIC_TABLES = {
	CLASSES: "classes",
	PERMISSIONS: "permissions",
	INFORMED_BY: "informed_by",
	STATUS: "status",
	ABSENCE_KIND: "absence_kind",
	EXAM_KIND: "exam_kind",
	SCIENTIFIC_FIELDS: "scientific_fields",
	LESSONS: "lessons",
	DESIGN_LESSONS: "design_lessons",
	FOREIGN_LANGUAGE_LESSONS: "foreign_language_lessons",
	EDUCATIONAL_INSTITUTIONS: "educational_institutions",
};

export const examKindIds = {
	TEST: 1,
	EXAM: 2,
	STUDY: 3,
};

export const absenceKindIds = {
	NOT_PRESENT: 1,
	PRESENT: 2,
};

export const permissions = {
	ADMIN: 1,
	TEACHER: 2,
	STUDENT: 3,
};

export const STATUSES = {
	DISABLED: 1,
	ENABLED: 2,
};

export const SCIENTIFIC_FIELDS_TO_IDS = {
	HUMANITIES_LAW_SOCIAL_SCIENCES: 1,
	EXACT_SCIENCES: 2,
	HEALTH_SCIENCES: 3,
	ECONOMICS_COMPUTER_SCIENCES: 4,
};

export const EPAL_STANDARD_LESSONS_IDS = [1, 2]; //Έκθεση και Μαθηματικά ΓΠ

export const PANHELLENIC_EXAMINATIONS_ENABLE_GEL_CLASSES_ID = [6, 10]; // Τα ID των τάξεων που δίνουν Πανελλαδικες στα ΓΕΛ
export const PANHELLENIC_EXAMINATIONS_ENABLE_EPAL_CLASSES_ID = [9, 10]; // Τα ID των τάξεων που δίνουν Πανελλαδικες στα ΕΠΑΛ

export const EKTHESI_ID = 1;
export const MATH_OP_ID = 25;
export const CHEMISTRY_ID = 4;
export const INFORMATICS_ID = 6;
export const FINANCE_ID = 5;
export const PHYSICS_ID = 3;
export const HISTORY_ID = 11;
